import React, { useState, useEffect } from 'react';
import { Plus, Edit, Trash } from 'lucide-react';
import { supabase } from '../utils/supabase';

const ManageDiscounts = ({ clientId, discounts, onUpdate }) => {
    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentDiscount, setCurrentDiscount] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        getUser();
    }, []);

    const getUser = async () => {
        const { data: { user } } = await supabase.auth.getUser();
        setUser(user);
    };

    const handleAdd = async (newDiscount) => {
        if (!user) return;

        const { data, error } = await supabase
            .from('client_discounts')
            .insert({ ...newDiscount, client_id: clientId })
            .select();

        if (error) {
            console.error('Error adding discount:', error);
        } else {
            onUpdate();
            setIsAdding(false);
        }
    };

    const handleEdit = async (updatedDiscount) => {
        if (!user) return;

        const { data, error } = await supabase
            .from('client_discounts')
            .update(updatedDiscount)
            .eq('id', updatedDiscount.id)
            .select();

        if (error) {
            console.error('Error updating discount:', error);
        } else {
            onUpdate();
            setIsEditing(false);
            setCurrentDiscount(null);
        }
    };

    const handleDelete = async (discountId) => {
        if (!user) return;

        const { error } = await supabase
            .from('client_discounts')
            .delete()
            .eq('id', discountId);

        if (error) {
            console.error('Error deleting discount:', error);
        } else {
            onUpdate();
        }
    };

    return (
        <div>
            <h3 className="text-xl font-semibold mb-4">Discounts</h3>
            {discounts.map((discount) => (
                <div key={discount.id} className="mb-4 p-4 border rounded-lg">
                    <h4 className="font-semibold">{discount.name}</h4>
                    <p>Type: {discount.type}</p>
                    <p>Value: {discount.type === 'Percentage' ? `${discount.value}%` : `$${discount.value}`}</p>
                    <p>Valid Until: {discount.valid_until}</p>
                    <div className="mt-2">
                        <button 
                            onClick={() => {
                                setCurrentDiscount(discount);
                                setIsEditing(true);
                            }}
                            className="mr-2 text-blue-500 hover:text-blue-700"
                        >
                            <Edit size={18} />
                        </button>
                        <button 
                            onClick={() => handleDelete(discount.id)}
                            className="text-red-500 hover:text-red-700"
                        >
                            <Trash size={18} />
                        </button>
                    </div>
                </div>
            ))}
            <button
                onClick={() => setIsAdding(true)}
                className="mt-4 bg-green-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-green-600 transition-colors"
            >
                <Plus size={20} className="mr-2" />
                Add New Discount
            </button>
            {(isAdding || isEditing) && (
                <DiscountForm
                    onSubmit={isAdding ? handleAdd : handleEdit}
                    onCancel={() => {
                        setIsAdding(false);
                        setIsEditing(false);
                        setCurrentDiscount(null);
                    }}
                    initialData={currentDiscount}
                />
            )}
        </div>
    );
};

const DiscountForm = ({ onSubmit, onCancel, initialData }) => {
    const [formData, setFormData] = useState(initialData || {
        name: '',
        type: '',
        value: '',
        valid_until: new Date().toISOString().split('T')[0]
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit} className="mt-4 p-4 border rounded-lg">
            <div className="mb-4">
                <label className="block mb-2">Name:</label>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Type:</label>
                <select
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                >
                    <option value="">Select Type</option>
                    <option value="Percentage">Percentage</option>
                    <option value="Fixed Amount">Fixed Amount</option>
                </select>
            </div>
            <div className="mb-4">
                <label className="block mb-2">Value:</label>
                <input
                    type="number"
                    name="value"
                    value={formData.value}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    step={formData.type === 'Percentage' ? '1' : '0.01'}
                    min="0"
                    max={formData.type === 'Percentage' ? '100' : ''}
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Valid Until:</label>
                <input
                    type="date"
                    name="valid_until"
                    value={formData.valid_until}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
            </div>
            <div className="flex justify-end">
                <button
                    type="button"
                    onClick={onCancel}
                    className="mr-2 px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                >
                    {initialData ? 'Update' : 'Add'} Discount
                </button>
            </div>
        </form>
    );
};

export default ManageDiscounts;