import React, { useState, useEffect } from 'react';
import { Calculator, Info, Activity, Scale, Ruler, User, ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import Navigation from './Navigation'; // Import the Navigation component

const MacroCalculator = () => {
  const [formData, setFormData] = useState({
    age: '',
    sex: 'Male',
    height: '',
    heightUnit: 'inches',
    weight: '',
    weightUnit: 'pounds',
    activityLevel: 'Not active',
    weightGoal: 'Weight loss',
    weightChangeGoal: 'Loss: 0.5lb per week',
    carbsFatPreference: 'Moderate carb/Moderate fat',
  });

  const [results, setResults] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const defaultLossValue = formData.weightUnit === 'pounds' ? '0.5lb' : '0.25kg';
    setFormData(prevData => ({
      ...prevData,
      weightChangeGoal: `Loss: ${defaultLossValue} per week`
    }));
  }, [formData.weightUnit]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.age) newErrors.age = "Age is required";
    if (!formData.height) newErrors.height = "Height is required";
    if (!formData.weight) newErrors.weight = "Weight is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const calculateMacros = () => {
    if (!validateForm()) return;

    let heightInCm = parseFloat(formData.height);
    let weightInKg = parseFloat(formData.weight);

    if (formData.heightUnit === 'inches') {
      heightInCm = heightInCm * 2.54;
    }
    if (formData.weightUnit === 'pounds') {
      weightInKg = weightInKg * 0.453592;
    }

    // Calculate BMR using Mifflin-St Jeor Equation
    let bmr;
    if (formData.sex === 'Male') {
      bmr = 10 * weightInKg + 6.25 * heightInCm - 5 * parseInt(formData.age) + 5;
    } else {
      bmr = 10 * weightInKg + 6.25 * heightInCm - 5 * parseInt(formData.age) - 161;
    }

    // Apply activity factor to get TDEE
    const activityFactors = {
      'Not active': 1.2,
      'Lightly active': 1.375,
      'Moderately active': 1.55,
      'Very active': 1.725,
      'Extra active': 1.9
    };
    const tdee = Math.round(bmr * activityFactors[formData.activityLevel]);

    // Adjust calories based on weight goal
    let calorieGoal;
    const [changeType, changeAmount, , changeUnit] = formData.weightChangeGoal.split(' ');
    const weightChangeValue = parseFloat(changeAmount);
    const calorieAdjustment = changeUnit === 'lb' ? weightChangeValue * 500 : weightChangeValue * 1100;
    
    if (changeType === 'Loss') {
      calorieGoal = Math.round(tdee - calorieAdjustment);
    } else if (changeType === 'Gain') {
      calorieGoal = Math.round(tdee + calorieAdjustment);
    } else {
      calorieGoal = tdee; // Maintain weight
    }

    // Calculate macronutrients
    const proteinGrams = Math.round(weightInKg * 1.8);
    const proteinCalories = proteinGrams * 4;

    const fatCalories = calorieGoal * 0.25;
    const fatGrams = Math.round(fatCalories / 9);

    const carbCalories = calorieGoal - proteinCalories - fatCalories;
    const carbGrams = Math.round(carbCalories / 4);

    setResults({
      calories: calorieGoal,
      protein: proteinGrams,
      carbs: carbGrams,
      fat: fatGrams
    });
  };

  const [activeTab, setActiveTab] = useState('macroCalculator');

  return (
    <div className="max-w-4xl mx-auto p-8 bg-gradient-to-br from-orange-50 to-yellow-50 rounded-xl shadow-lg">
      <Link to="/dashboard" className="flex items-center text-orange-500 hover:text-orange-600 mb-4">
        <ArrowLeft size={20} className="mr-1" /> Back to Dashboard
      </Link>
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-800 flex items-center justify-center">
        <Calculator className="mr-3 text-orange-500" size={32} />
        Macro Calculator
      </h2>
      <form onSubmit={(e) => { e.preventDefault(); calculateMacros(); }} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4 text-gray-700 flex items-center">
              <User className="mr-2 text-orange-500" /> Personal Information
            </h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Age *</label>
                <input
                  type="number"
                  name="age"
                  value={formData.age}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
                  required
                />
                {errors.age && <p className="text-red-500 text-xs mt-1">{errors.age}</p>}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Sex</label>
                <select
                  name="sex"
                  value={formData.sex}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
                >
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </div>
            </div>
          </div>
          
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4 text-gray-700 flex items-center">
              <Ruler className="mr-2 text-orange-500" /> Body Measurements
            </h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Height *</label>
                <div className="flex">
                  <input
                    type="number"
                    name="height"
                    value={formData.height}
                    onChange={handleInputChange}
                    className="mt-1 block w-2/3 rounded-l-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
                    required
                  />
                  <select
                    name="heightUnit"
                    value={formData.heightUnit}
                    onChange={handleInputChange}
                    className="mt-1 block w-1/3 rounded-r-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
                  >
                    <option value="inches">inches (in)</option>
                    <option value="centimeters">centimeters (cm)</option>
                  </select>
                </div>
                {errors.height && <p className="text-red-500 text-xs mt-1">{errors.height}</p>}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Weight *</label>
                <div className="flex">
                  <input
                    type="number"
                    name="weight"
                    value={formData.weight}
                    onChange={handleInputChange}
                    className="mt-1 block w-2/3 rounded-l-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
                    required
                  />
                  <select
                    name="weightUnit"
                    value={formData.weightUnit}
                    onChange={handleInputChange}
                    className="mt-1 block w-1/3 rounded-r-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
                  >
                    <option value="pounds">pounds (lb)</option>
                    <option value="kilograms">kilograms (kg)</option>
                  </select>
                </div>
                {errors.weight && <p className="text-red-500 text-xs mt-1">{errors.weight}</p>}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold mb-4 text-gray-700 flex items-center">
            <Activity className="mr-2 text-orange-500" /> Activity & Goals
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Activity Level</label>
              <select
                name="activityLevel"
                value={formData.activityLevel}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
              >
                <option>Not active</option>
                <option>Lightly active</option>
                <option>Moderately active</option>
                <option>Very active</option>
                <option>Extra active</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Weight Goal</label>
              <select
                name="weightGoal"
                value={formData.weightGoal}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
              >
                <option>Weight loss</option>
                <option>Maintain weight</option>
                <option>Weight gain</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Weight Change Goal</label>
              <select
                name="weightChangeGoal"
                value={formData.weightChangeGoal}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
              >
                {['Loss', 'Gain'].map(type => 
                  [0.25, 0.5, 0.75, 1].map(amount => 
                    <option key={`${type}-${amount}`} value={`${type}: ${amount}${formData.weightUnit === 'pounds' ? 'lb' : 'kg'} per week`}>
                      {`${type}: ${amount}${formData.weightUnit === 'pounds' ? 'lb' : 'kg'} per week`}
                    </option>
                  )
                )}
                <option>Maintain weight</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Carbs/Fat Preference</label>
              <select
                name="carbsFatPreference"
                value={formData.carbsFatPreference}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
              >
                <option>Low carb/High fat</option>
                <option>Moderate carb/Moderate fat</option>
                <option>High carb/Low fat</option>
              </select>
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 transition duration-150 ease-in-out"
        >
          Calculate My Macros
        </button>
      </form>

      {results && (
        <div className="mt-8 bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-2xl font-semibold mb-4 text-gray-800">Your Daily Macros</h3>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div className="bg-orange-100 p-4 rounded-lg">
              <p className="text-sm text-orange-700 font-medium">Calories</p>
              <p className="text-2xl font-bold text-orange-900">{results.calories}</p>
            </div>
            <div className="bg-green-100 p-4 rounded-lg">
              <p className="text-sm text-green-700 font-medium">Protein</p>
              <p className="text-2xl font-bold text-green-900">{results.protein}g</p>
            </div>
            <div className="bg-yellow-100 p-4 rounded-lg">
              <p className="text-sm text-yellow-700 font-medium">Carbs</p>
              <p className="text-2xl font-bold text-yellow-900">{results.carbs}g</p>
            </div>
            <div className="bg-red-100 p-4 rounded-lg">
              <p className="text-sm text-red-700 font-medium">Fat</p>
              <p className="text-2xl font-bold text-red-900">{results.fat}g</p>
            </div>
          </div>
        </div>
      )}

      <div className="mt-6 text-sm text-gray-600 flex items-center bg-yellow-50 p-4 rounded-lg">
      <Info className="mr-2 text-yellow-500" size={20} />
        <span>Results are estimates. Consult a professional for personalized advice.</span>
      </div>

      {/* Add this at the end of the component, just before the closing div */}
      <div className="md:hidden">
        <Navigation setActiveTab={setActiveTab} />
      </div>
    </div>
  );
};

export default MacroCalculator;