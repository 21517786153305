import React, { useState, useEffect } from 'react';
import { Search, Edit, Trash2, Check, X } from 'lucide-react';
import { supabase } from '../utils/supabase';

const TrainersManagement = () => {
  const [trainers, setTrainers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchTrainers();
  }, []);

  const fetchTrainers = async () => {
    try {
      const { data, error } = await supabase
        .from('user_profiles_with_email')
        .select('*')
        .eq('role', 'trainer');

      if (error) throw error;
      setTrainers(data);
    } catch (error) {
      console.error('Error fetching trainers:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this trainer?')) {
      try {
        const { error } = await supabase
          .from('user_profiles')
          .delete()
          .eq('id', id);

        if (error) throw error;
        fetchTrainers(); // Refresh the list
      } catch (error) {
        console.error('Error deleting trainer:', error);
      }
    }
  };

  const filteredTrainers = trainers.filter(trainer =>
    trainer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <div className="mb-6 flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800">Trainers Management</h2>
      </div>
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-4 flex justify-between items-center border-b">
          <div className="relative">
            <input 
              type="text" 
              placeholder="Search trainers..." 
              className="pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          </div>
        </div>
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Registration Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Certified</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredTrainers.map((trainer) => (
              <tr key={trainer.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">{trainer.name}</td>
                <td className="px-6 py-4 whitespace-nowrap">{trainer.email}</td>
                <td className="px-6 py-4 whitespace-nowrap">{new Date(trainer.join_date).toLocaleDateString()}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {trainer.is_certified ? 
                    <Check className="text-green-500" /> : 
                    <X className="text-red-500" />}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button className="text-orange-600 hover:text-orange-900 mr-3"><Edit size={18} /></button>
                  <button onClick={() => handleDelete(trainer.id)} className="text-red-600 hover:text-red-900"><Trash2 size={18} /></button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TrainersManagement;