import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Users, Calendar, Dumbbell, Trophy, TrendingUp, Heart, Activity, ChevronDown, ArrowLeft, AlertTriangle, BarChart2 } from 'lucide-react';
import { supabase } from '../utils/supabase';
import { fetchUserProgressData } from '../utils/progressUtils';
import { motion, AnimatePresence } from 'framer-motion';

const CustomAlert = ({ variant, title, description }) => (
  <motion.div
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    className={`p-4 mb-4 rounded-md ${variant === 'error' ? 'bg-red-100 text-red-700' : 'bg-blue-100 text-blue-700'}`}
  >
    <div className="flex items-center">
      <AlertTriangle className="mr-2" />
      <h3 className="font-semibold">{title}</h3>
    </div>
    <p className="mt-2">{description}</p>
  </motion.div>
);

const UserProgress = () => {
  const navigate = useNavigate();
  const [progressData, setProgressData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedSections, setExpandedSections] = useState({
    weightBreakdown: true,
    metrics: true,
    streak: true,
    achievements: true,
  });

  useEffect(() => {
    fetchUserProgress();
  }, []);

  const fetchUserProgress = async () => {
    try {
      setLoading(true);
      const { data: { user } } = await supabase.auth.getUser();

      if (!user) {
        throw new Error('User not authenticated');
      }

      const progressData = await fetchUserProgressData(user.id);
      setProgressData(progressData);
    } catch (error) {
      console.error('Error fetching user progress:', error);
      setError('Failed to load progress data');
    } finally {
      setLoading(false);
    }
  };

  const ProgressBar = ({ value, max, color = 'orange' }) => (
    <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
      <motion.div
        className={`bg-${color}-500 h-2.5 rounded-full`}
        initial={{ width: 0 }}
        animate={{ width: `${(value / max) * 100}%` }}
        transition={{ duration: 0.5, ease: "easeOut" }}
      />
    </div>
  );

  const MetricCard = ({ icon, title, value, suffix = '', color = 'orange' }) => (
    <motion.div
      whileHover={{ scale: 1.05 }}
      className={`bg-white rounded-lg shadow-md p-4 border-l-4 border-${color}-500 transition-all duration-300 ease-in-out`}
    >
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-sm font-semibold text-gray-600">{title}</h3>
        {icon}
      </div>
      <p className={`text-2xl font-bold text-${color}-500`}>{value}{suffix}</p>
    </motion.div>
  );

  const WeightBreakdown = () => (
    <div className="grid grid-cols-2 gap-4">
      <WeightCard title="Current Weight" value="67.0" unit="Kg" color="orange" subtitle="Last updated: Today" />
      <WeightCard title="Weight Change" value="+1.2" unit="Kg" color="green" subtitle="Last 30 days" />
      <WeightCard title="Average Weight" value="66.5" unit="Kg" color="blue" subtitle="Last 30 days" />
      <WeightCard title="Goal Weight" value="65.0" unit="Kg" color="purple" subtitle="2.0 Kg to go" />
    </div>
  );

  const WeightCard = ({ title, value, unit, color, subtitle }) => (
    <motion.div
      whileHover={{ scale: 1.05 }}
      className={`bg-white rounded-lg shadow-md p-4 transition-all duration-300 ease-in-out`}
    >
      <p className="text-sm text-gray-600 mb-1">{title}</p>
      <p className={`text-2xl font-bold text-${color}-500`}>{value} <span className="text-lg">{unit}</span></p>
      <p className="text-xs text-gray-500">{subtitle}</p>
    </motion.div>
  );

  const ExpandableSection = ({ title, children, isExpanded, toggleExpand, icon }) => (
    <div className="bg-white rounded-lg shadow-md p-6 mb-6">
      <div className="flex justify-between items-center cursor-pointer" onClick={toggleExpand}>
        <div className="flex items-center">
          {icon}
          <h2 className="text-xl font-semibold ml-2">{title}</h2>
        </div>
        <ChevronDown className={`transform transition-transform ${isExpanded ? 'rotate-180' : ''}`} />
      </div>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="mt-4"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );

  if (loading) return (
    <div className="flex justify-center items-center h-screen">
      <motion.div
        animate={{ rotate: 360 }}
        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
      >
        <BarChart2 className="text-orange-500" size={40} />
      </motion.div>
    </div>
  );
  
  if (error) return <CustomAlert variant="error" title="Error" description={error} />;
  if (!progressData) return <CustomAlert variant="error" title="No Data" description="No progress data available" />;

  return (
    <div className="max-w-4xl mx-auto p-4 pb-16 md:pb-4">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="flex items-center mb-6"
      >
        <button onClick={() => navigate(-1)} className="mr-4 hover:bg-gray-100 p-2 rounded-full transition-colors duration-300">
          <ArrowLeft className="text-gray-600" />
        </button>
        <h1 className="text-2xl font-bold">My Progress</h1>
      </motion.div>

      <ExpandableSection
        title="Weight Tracker"
        isExpanded={expandedSections.weightBreakdown}
        toggleExpand={() => setExpandedSections(prev => ({ ...prev, weightBreakdown: !prev.weightBreakdown }))}
        icon={<BarChart2 className="text-orange-500" size={24} />}
      >
        <WeightBreakdown />
      </ExpandableSection>

      <ExpandableSection
        title="Fitness Metrics"
        isExpanded={expandedSections.metrics}
        toggleExpand={() => setExpandedSections(prev => ({ ...prev, metrics: !prev.metrics }))}
        icon={<Activity className="text-green-500" size={24} />}
      >
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          <MetricCard icon={<Dumbbell className="text-orange-500" size={20} />} title="Workouts" value={progressData.workoutsCompleted} color="orange" />
          <MetricCard icon={<Calendar className="text-green-500" size={20} />} title="Events" value={progressData.eventsAttended} color="green" />
          <MetricCard icon={<Users className="text-blue-500" size={20} />} title="Buddies" value={progressData.buddies} color="blue" />
          <MetricCard icon={<Heart className="text-red-500" size={20} />} title="Fav Buddies" value={progressData.favoriteBuddies} color="red" />
          <MetricCard icon={<TrendingUp className="text-indigo-500" size={20} />} title="Reviews" value={progressData.reviewsReceived} color="indigo" />
          <MetricCard icon={<Activity className="text-purple-500" size={20} />} title="Activity Score" value={progressData.activityScore || '87.9%'} color="purple" />
        </div>
      </ExpandableSection>

      <ExpandableSection
        title="Membership Streak"
        isExpanded={expandedSections.streak}
        toggleExpand={() => setExpandedSections(prev => ({ ...prev, streak: !prev.streak }))}
        icon={<Calendar className="text-orange-500" size={24} />}
      >
        <div className="flex items-center mb-4">
          <Calendar className="text-orange-500 mr-4" size={32} />
          <div>
            <p className="text-3xl font-bold text-orange-500">{progressData.streakDays} days</p>
            <p className="text-sm text-gray-600">Keep up the great work!</p>
          </div>
        </div>
        <ProgressBar value={progressData.streakDays} max={90} color="orange" />
        <p className="text-sm text-gray-600">Goal: 90 days</p>
      </ExpandableSection>

      <ExpandableSection
        title="Achievements"
        isExpanded={expandedSections.achievements}
        toggleExpand={() => setExpandedSections(prev => ({ ...prev, achievements: !prev.achievements }))}
        icon={<Trophy className="text-yellow-500" size={24} />}
      >
        <div className="flex items-center mb-4">
          <Trophy className="text-yellow-500 mr-4" size={32} />
          <div>
            <p className="text-3xl font-bold text-yellow-500">{progressData.trophiesEarned}</p>
            <p className="text-sm text-gray-600">Trophies Earned</p>
          </div>
        </div>
        <ProgressBar value={progressData.trophiesEarned} max={10} color="yellow" />
        <p className="text-sm text-gray-600">{10 - progressData.trophiesEarned} more to reach the next level!</p>
      </ExpandableSection>
    </div>
  );
};

export default UserProgress;