import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { 
  ArrowLeft, Users, Calendar, Dumbbell, Star, MapPin, Clock, 
  Activity, Target, Heart, Zap, Award, ChevronRight
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import DiscoverSection from './DiscoverSection';

const ConceptCard = ({ concept, index }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      className="perspective-1000 w-full h-full"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      whileHover={{ scale: 1.05 }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      onClick={() => setIsFlipped(!isFlipped)}
    >
      <motion.div
        className="w-full h-full transition-all duration-500 preserve-3d cursor-pointer"
        animate={{ rotateY: isFlipped ? 180 : 0 }}
      >
        {/* Front of the card */}
        <div className="absolute w-full h-full backface-hidden bg-white rounded-lg shadow-md p-6">
          <div className="flex items-center justify-center w-12 h-12 bg-orange-100 rounded-md mb-4">
            {concept.icon}
          </div>
          <h3 className="text-lg font-semibold text-gray-900 mb-2">{concept.title}</h3>
          <p className="text-gray-600">{concept.description}</p>
          {isHovered && (
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="absolute bottom-2 right-2 text-sm text-orange-500"
            >
              Click to learn more
            </motion.p>
          )}
        </div>

        {/* Back of the card */}
        <div className="absolute w-full h-full backface-hidden bg-orange-500 text-white rounded-lg shadow-md p-6 transform rotate-y-180">
          <h3 className="text-lg font-semibold mb-2">How it works:</h3>
          <ul className="list-disc list-inside">
            {concept.details.map((detail, idx) => (
              <li key={idx} className="mb-2">{detail}</li>
            ))}
          </ul>
        </div>
      </motion.div>
    </motion.div>
  );
};
const AboutPage = () => {
    const navigate = useNavigate();
    const [selectedConcept, setSelectedConcept] = useState(null);
  
    const teamMembers = [
      { name: 'Sarah Johnson', role: 'Founder & CEO', image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80' },
      { name: 'Michael Chen', role: 'CTO', image: 'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80' },
      { name: 'Emma Rodriguez', role: 'Head of Community', image: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80' },
      { name: 'David Kim', role: 'Lead Developer', image: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80' },
    ];
  
    const milestones = [
      { 
        year: '2024', 
        event: (
          <>
            <p className="font-semibold mb-2">FitBuddy Founded</p>
            <ul className="list-disc list-inside text-sm">
              <li>Launched our innovative fitness connection app</li>
              <li>Established partnerships with local gyms in our first city</li>
              <li>Built our core team of passionate fitness enthusiasts</li>
            </ul>
          </>
        ), 
        icon: <Users className="text-white" /> 
      },
      { 
        year: '2025', 
        event: (
          <>
            <p className="font-semibold mb-2">Expanding Our Reach</p>
            <ul className="list-disc list-inside text-sm">
              <li>Released FitBuddy mobile app for iOS and Android</li>
              <li>Expanded to 5 major cities across the country</li>
              <li>Introduced virtual workout buddy feature</li>
            </ul>
          </>
        ), 
        icon: <Zap className="text-white" /> 
      },
      { 
        year: '2026', 
        event: (
          <>
            <p className="font-semibold mb-2">Growing Community</p>
            <ul className="list-disc list-inside text-sm">
              <li>Reached 100,000 active users milestone</li>
              <li>Launched FitBuddy for Business program</li>
              <li>Hosted our first national FitBuddy meetup event</li>
            </ul>
          </>
        ), 
        icon: <MapPin className="text-white" /> 
      },
      { 
        year: '2027', 
        event: (
          <>
            <p className="font-semibold mb-2">Innovating Further</p>
            <ul className="list-disc list-inside text-sm">
              <li>Introduced AI-powered workout recommendations</li>
              <li>Expanded internationally to 3 new countries</li>
              <li>Launched FitBuddy Trainer certification program</li>
            </ul>
          </>
        ), 
        icon: <Award className="text-white" /> 
      },
      { 
        year: '2028', 
        event: (
          <>
            <p className="font-semibold mb-2">Changing Lives</p>
            <ul className="list-disc list-inside text-sm">
              <li>Celebrated 1 million active users worldwide</li>
              <li>Released FitBuddy wearable device integration</li>
              <li>Established FitBuddy Foundation for community health initiatives</li>
            </ul>
          </>
        ), 
        icon: <Activity className="text-white" /> 
      },
    ];
  
    const concepts = [
        {
          icon: <Users className="h-8 w-8 text-white" />,
          title: 'Find Fitness Buddies',
          details: [
            'Create a detailed fitness profile',
            'Use our matching algorithm to find compatible buddies',
            'Chat and plan workouts together'
          ]
        },
        {
          icon: <Calendar className="h-8 w-8 text-white" />,
          title: 'Join Events',
          details: [
            'Browse events by type, date, or location',
            'RSVP and add events to your calendar',
            'Connect with other attendees before the event'
          ]
        },
        {
          icon: <Activity className="h-8 w-8 text-white" />,
          title: 'Track Progress',
          details: [
            'Set personalized fitness goals',
            'Log your workouts and track your progress',
            'View detailed analytics and insights'
          ]
        },
        {
          icon: <MapPin className="h-8 w-8 text-white" />,
          title: 'Explore Nearby',
          details: [
            'Discover nearby fitness locations',
            'Read and leave reviews for fitness spots',
            'Get directions and facility information'
          ]
        },
      ];
  
    return (
      <div className="font-sans">
        {/* Header */}
        <header className="bg-white shadow-sm fixed w-full z-10">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
            <div className="flex items-center">
              <img src="/fitbuddy-logo.png" alt="FitBuddy Logo" className="h-8 w-auto mr-2" />
              <span className="font-bold text-xl text-orange-500">FITBuddy</span>
            </div>
            <button onClick={() => navigate('/')} className="text-gray-600 hover:text-orange-500 transition duration-300 flex items-center">
              <ArrowLeft size={20} className="mr-2" />
              Back to Home
            </button>
          </div>
        </header>
        {/* Hero Section */}
      <section className="bg-gradient-to-r from-orange-500 to-orange-600 text-white pt-32 pb-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold sm:text-5xl md:text-6xl mb-6">
              About <span className="text-yellow-300">FitBuddy</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-xl sm:text-2xl md:mt-5 md:max-w-3xl">
              Transforming fitness journeys through community and technology.
            </p>
          </div>
        </div>
      </section>

      {/* Our Story Section */}
<section className="py-20 bg-gradient-to-r from-orange-50 to-orange-100">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      className="text-center mb-12"
    >
      <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
        Our <span className="text-orange-500">Story</span>
      </h2>
    </motion.div>
    <div className="flex flex-col lg:flex-row items-center justify-between">
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
        className="lg:w-1/2 mb-8 lg:mb-0 lg:pr-8"
      >
        <p className="text-lg text-gray-700 leading-relaxed mb-6">
          FitBuddy was born out of a simple idea: fitness is better with friends. Our founder, Fernando Mendes, struggled to find workout partners who shared his schedule and fitness goals. This challenge inspired him to create a platform that would connect fitness enthusiasts and make working out a more social, enjoyable experience.
        </p>
        <p className="text-lg text-gray-700 leading-relaxed mb-6">
          As we begin our journey, we're excited to launch FITBuddy and build a community that will soon thrive across cities and countries. Though we're just starting out, our team's passion and the potential for creating incredible stories of friendships and achievements drive us forward.
        </p>
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="inline-block"
        >
          <button className="bg-orange-500 text-white px-6 py-3 rounded-full font-semibold flex items-center hover:bg-orange-600 transition-colors duration-300">
          <Link to="/login" className="bg-orange-500 text-white px-6 py-3 rounded-full font-semibold flex items-center hover:bg-orange-600 transition-colors duration-300">
            Join Our Community
            <ChevronRight className="ml-2" size={20} />
          </Link>
          </button>
        </motion.div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, delay: 0.4 }}
        className="lg:w-1/2 relative"
      >
        <img 
          src="/FITBuddy_Team.png" 
          alt="FitBuddy Team" 
          className="rounded-lg shadow-xl"
        />
        <div className="absolute -bottom-6 -left-6 bg-orange-500 text-white p-4 rounded-lg shadow-lg">
          <p className="font-bold text-xl">1M+</p>
          <p className="text-sm">Active Users</p>
        </div>
        <div className="absolute -top-6 -right-6 bg-orange-500 text-white p-4 rounded-lg shadow-lg">
          <p className="font-bold text-xl">50+</p>
          <p className="text-sm">Cities</p>
        </div>
      </motion.div>
    </div>
  </div>
</section>
      <DiscoverSection />

      {/* Milestones Section */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-gray-900 text-center sm:text-4xl mb-16">
            Our <span className="text-orange-500">Journey</span>
          </h2>
          <div className="relative">
            <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-orange-500"></div>
            {milestones.map((milestone, index) => (
              <div key={index} className={`flex items-center mb-16 ${index % 2 === 0 ? 'md:flex-row-reverse' : 'md:flex-row'}`}>
                <div className="w-full md:w-5/12">
                <div className={`p-6 bg-white rounded-lg shadow-lg ${index % 2 === 0 ? 'md:ml-8' : 'md:mr-8'}`}>
                    <h3 className="text-2xl font-bold text-orange-500 mb-2">{milestone.year}</h3>
                    <div className="text-gray-700">{milestone.event}</div>
                  </div>
                </div>
                <div className="flex justify-center items-center w-10 h-10 bg-orange-500 rounded-full border-4 border-white shadow absolute left-1/2 transform -translate-x-1/2 md:static md:translate-x-0">
                  {milestone.icon}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-orange-500 py-16">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
            <span className="block">Ready to start your fitness journey?</span>
            <span className="block text-orange-900">Join FitBuddy today.</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
            <Link to="/login" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-orange-600 bg-white hover:bg-orange-50 transition duration-300">
              Get Started
              <ChevronRight className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
            </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8 xl:col-span-1">
              <p className="text-gray-400 text-base">
                Connecting fitness enthusiasts and making workouts more fun and social.
              </p>
            </div>
            <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Company</h3>
                  <ul className="mt-4 space-y-4">
                    <li><Link to="/about" className="text-base text-gray-300 hover:text-white">About</Link></li>
                    <li><a href="#" className="text-base text-gray-300 hover:text-white">Careers</a></li>
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Legal</h3>
                  <ul className="mt-4 space-y-4">
                    <li><a href="#" className="text-base text-gray-300 hover:text-white">Privacy</a></li>
                    <li><a href="#" className="text-base text-gray-300 hover:text-white">Terms</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 border-t border-gray-700 pt-8">
            <p className="text-base text-gray-400 xl:text-center">
              &copy; 2024 FitBuddy. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AboutPage;