import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { signIn, signUp, supabase } from '../utils/supabase';
import { Mail, Lock, User, Facebook, Chrome, UserPlus, Download, ArrowLeft } from 'lucide-react';

const LoginSignup = ({ setIsAuthenticated, setCurrentUser }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [userRole, setUserRole] = useState('buddy');
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [referralId, setReferralId] = useState(null);
  const [referralMessage, setReferralMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Force a hard reload if the page was loaded from cache
    if (performance.navigation.type === 2) {
      window.location.reload(true);
    }

    // Add a timestamp to the URL to prevent caching
    const timestamp = new Date().getTime();
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('t', timestamp);
    window.history.replaceState({}, '', currentUrl);

    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
    });

    // Check for referral parameters
    const searchParams = new URLSearchParams(location.search);
    const ref = searchParams.get('ref');
    const message = searchParams.get('message');

    if (ref) {
      setReferralId(ref);
      setIsLogin(false); // Switch to sign-up mode if there's a referral
    }
    if (message) {
      setReferralMessage(decodeURIComponent(message));
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (isLogin) {
        // Login mode
        const user = await signIn(email, password);
        console.log('User:', user);

        if (user && user.id) {
          // Record login event
          await supabase
            .from('user_logins')
            .insert({ user_id: user.id });

          // Fetch user profile
          const { data: profile, error: profileError } = await supabase
            .from('user_profiles')
            .select('*')
            .eq('id', user.id)
            .single();

          if (profileError) throw profileError;

          setIsAuthenticated(true);
          setCurrentUser({ ...user, ...profile });

          // Check if user has completed onboarding
          if (!profile.has_completed_onboarding) {
            navigate('/onboarding');
          } else {
            // Redirect based on user role and force a reload
            if (profile.role === 'admin') {
              window.location.href = '/admin';
            } else if (profile.role === 'trainer') {
              window.location.href = '/trainer-dashboard';
            } else {
              window.location.href = '/';
            }
          }
        } else {
          throw new Error('User data is incomplete');
        }
      } else {
        // Signup mode
        const user = await signUp(email, password, name, userRole, referralId);
        console.log('User:', user);

        if (user && user.id) {
          setIsAuthenticated(true);
          setCurrentUser({ ...user, role: userRole, has_completed_onboarding: false });
          navigate('/onboarding');
        } else {
          throw new Error('User data is incomplete');
        }
      }
    } catch (error) {
      console.error('Error:', error.message);
      alert(error.message);
    }
  };

  const socialLogin = (provider) => {
    // In a real app, you would handle social login here
    console.log(`Logging in with ${provider}`);
  };

  const handleDownload = async () => {
    if (deferredPrompt) {
      // Show the install prompt for Android
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      setDeferredPrompt(null);
    } else {
      // For iOS devices
      const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      if (iOS) {
        const manifestLink = document.querySelector('link[rel="manifest"]');
        const manifestURL = manifestLink ? manifestLink.href : '/manifest.json';

        try {
          const manifestData = await fetch(manifestURL).then(response => response.json());
          const iconURL = new URL(manifestData.icons[0].src, window.location.origin).toString();
          const startURL = new URL(manifestData.start_url || '/login', window.location.origin).toString();

          alert(`To add FITBuddy to your home screen:\n\n1. Tap the share button at the bottom of the screen.\n2. Scroll down and tap "Add to Home Screen".\n3. Tap "Add" in the top right corner.\n\nApp Name: ${manifestData.short_name}\nIcon: ${iconURL}\nStart URL: ${startURL}`);
        } catch (error) {
          console.error('Error fetching manifest:', error);
          alert("To add FITBuddy to your home screen:\n\nTap the share button and select 'Add to Home Screen'");
        }
      } else {
        // For other browsers that don't support installation
        alert("To add FITBuddy to your home screen:\n\nOpen this page in Chrome or Safari on your mobile device, then tap the menu button and select 'Add to Home Screen'");
      }
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `https://www.fitbuddy.au/reset-password`,
      });
      if (error) throw error;
      alert('Password reset email sent. Check your inbox.');
    } catch (error) {
      console.error('Error:', error.message);
      alert(error.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-16 w-auto mt-6"
            src="/FITBuddy.png"
            alt="FITBuddy Logo"
          />
          {/* Beta Version tag */}
          <div className="text-center mt-2">
            <span className="inline-block bg-blue-500 text-white text-xs px-2 py-1 rounded-full">
              Beta Version
            </span>
          </div>
          {!isForgotPassword && (
            <p className="mt-6 text-center text-sm text-gray-600">
              {isLogin ? "Don't have an account?" : "Already have an account?"}
              <button
                onClick={() => setIsLogin(!isLogin)}
                className="font-medium text-orange-600 hover:text-orange-500 ml-1"
              >
                {isLogin ? 'Sign up' : 'Log in'}
              </button>
            </p>
          )}
        </div>
        <div className="mt-8 space-y-6">
          <div className="bg-white shadow-md rounded-lg p-8">
            {referralMessage && (
              <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-6" role="alert">
                <p className="font-bold">Invitation</p>
                <p>{referralMessage}</p>
              </div>
            )}
            {isForgotPassword ? (
              <form className="space-y-6" onSubmit={handleForgotPassword}>
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <div className="relative">
                    <Mail className="absolute top-3 left-3 text-gray-400" size={20} />
                    <input
                      id="email-address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                      placeholder="Email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                  >
                    Send Reset Link
                  </button>
                </div>
                <div className="text-center">
                  <button
                    onClick={() => setIsForgotPassword(false)}
                    className="font-medium text-orange-600 hover:text-orange-500"
                  >
                    <ArrowLeft className="inline mr-1" size={16} />
                    Back to login
                  </button>
                </div>
              </form>
            ) : (
              <form className="space-y-6" onSubmit={handleSubmit}>
                {!isLogin && (
                  <>
                    <div>
                      <label htmlFor="name" className="sr-only">
                        Name
                      </label>
                      <div className="relative">
                        <User className="absolute top-3 left-3 text-gray-400" size={20} />
                        <input
                          id="name"
                          name="name"
                          type="text"
                          required
                          className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                          placeholder="Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div>
                    <label htmlFor="userRole" className="block text-sm font-medium text-gray-700 mb-1">
                      I want to join as a:
                    </label>
                    <div className="relative">
                      <UserPlus className="absolute top-3 left-3 text-gray-400 pointer-events-none" size={20} />
                      <select
                        id="userRole"
                        name="userRole"
                        className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm appearance-none"
                        value={userRole}
                        onChange={(e) => setUserRole(e.target.value)}
                      >
                        <option value="buddy">Fitness Buddy</option>
                        <option value="trainer">Personal Trainer</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  </>
                )}
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <div className="relative">
                    <Mail className="absolute top-3 left-3 text-gray-400" size={20} />
                    <input
                      id="email-address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                      placeholder="Email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <div className="relative">
                    <Lock className="absolute top-3 left-3 text-gray-400" size={20} />
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                {isLogin && (
                  <div className="text-sm">
                    <button
                      type="button"
                      onClick={() => setIsForgotPassword(true)}
                      className="font-medium text-orange-600 hover:text-orange-500"
                    >
                      Forgot your password?
                    </button>
                  </div>
                )}
                <div>
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                  >
                    {isLogin ? 'Sign in' : 'Sign up'}
                  </button>
                </div>
              </form>
            )}

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Or continue with</span>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-2 gap-3">
                <div>
                  <button
                    onClick={() => socialLogin('google')}
                    className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <Chrome className="mr-2" size={20} />
                    <span>Google</span>
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => socialLogin('facebook')}
                    className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <Facebook className="mr-2" size={20} />
                    <span>Facebook</span>
                  </button>
                </div>
              </div>
            </div>

            {/* Download FITBuddy button */}
            <div className="mt-6">
              <button
                onClick={handleDownload}
                className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                <Download className="mr-2" size={20} />
                Download FITBuddy
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSignup;