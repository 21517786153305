import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Calendar as CalendarIcon,
  MapPin,
  Users,
  Clock,
  Trophy,
  GlassWater,
  List,
  Grid,
  Plus,
  Edit,
  Trash2,
  XCircle,
  UserPlus,
  UserMinus,
  DollarSign,
} from 'lucide-react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { supabase } from '../utils/supabase';
import CreateActivityModal from './CreateActivityModal';
import ManageActivity from './ManageActivity';

const localizer = momentLocalizer(moment);

const GroupActivities = ({ groupId, currentUserId, refreshTrigger }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('sports');
  const [viewMode, setViewMode] = useState('list');
  const [calendarView, setCalendarView] = useState('week');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activities, setActivities] = useState([]);
  const [activityToEdit, setActivityToEdit] = useState(null);
  const [activityToCancel, setActivityToCancel] = useState(null);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isManageModalOpen, setIsManageModalOpen] = useState(false);
  const [activityToManage, setActivityToManage] = useState(null);

  useEffect(() => {
    checkAdminStatus();
    fetchActivities();
  }, [groupId, currentUserId, refreshTrigger]);

  const checkAdminStatus = async () => {
    try {
      const { data: groupData, error: groupError } = await supabase
        .from('groups')
        .select('created_by')
        .eq('id', groupId)
        .single();

      if (groupError) throw groupError;

      if (groupData.created_by === currentUserId) {
        setIsAdmin(true);
        return;
      }

      const { data: memberData, error: memberError } = await supabase
        .from('group_members')
        .select('role')
        .eq('group_id', groupId)
        .eq('user_id', currentUserId)
        .single();

      if (memberError) throw memberError;

      setIsAdmin(memberData.role === 'admin');
    } catch (error) {
      console.error('Error checking admin status:', error);
      setIsAdmin(false);
    }
  };

  const fetchActivities = async () => {
    try {
      const { data, error } = await supabase
        .from('group_activities')
        .select(
          `
          *,
          activity_photos (
            photo_url
          ),
          activity_participants (
            user_id
          ),
          activity_occurrences (
            id,
            start_time,
            end_time
          )
        `
        )
        .eq('group_id', groupId);

      if (error) {
        throw error;
      }

      const now = new Date();
      const allOccurrences = [];

      data.forEach((activity) => {
        const icon =
          activity.type === 'sport' ? (
            <Users size={20} />
          ) : activity.type === 'social' ? (
            <GlassWater size={20} />
          ) : null;

        if (activity.activity_occurrences && activity.activity_occurrences.length > 0) {
          activity.activity_occurrences.forEach((occurrence) => {
            const occurrenceEnd = new Date(occurrence.end_time);
            if (occurrenceEnd >= now) {
              allOccurrences.push({
                ...activity,
                occurrence_id: occurrence.id,
                start: new Date(occurrence.start_time),
                end: new Date(occurrence.end_time),
                icon,
                photos: activity.activity_photos || [],
                participants: activity.activity_participants || [],
                is_paid: activity.is_paid,
                price: activity.price,
              });
            }
          });
        } else {
          const activityEnd = new Date(activity.end_time);
          if (activityEnd >= now) {
            allOccurrences.push({
              ...activity,
              occurrence_id: null,
              start: new Date(activity.start_time),
              end: new Date(activity.end_time),
              icon,
              photos: activity.activity_photos || [],
              participants: activity.activity_participants || [],
              is_paid: activity.is_paid,
              price: activity.price,
            });
          }
        }
      });

      setActivities(allOccurrences.sort((a, b) => a.start - b.start));
    } catch (error) {
      console.error('Error fetching activities:', error);
    }
  };

  const handleCreateActivity = () => {
    setActivityToEdit(null);
    setIsModalOpen(true);
  };

  const handleEditActivity = (activity) => {
    setActivityToEdit(activity);
    setIsModalOpen(true);
  };

  const handleDeleteActivity = async (activityId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this activity?');
    if (confirmDelete) {
      try {
        const { error } = await supabase
          .from('group_activities')
          .delete()
          .eq('id', activityId);

        if (error) throw error;

        await supabase.from('activity_occurrences').delete().eq('activity_id', activityId);
        fetchActivities();
      } catch (error) {
        console.error('Error deleting activity:', error);
      }
    }
  };

  const handleCancelActivity = (activity) => {
    setActivityToCancel(activity);
    setIsCancelModalOpen(true);
  };

  const handleConfirmCancel = async (activityId, reason) => {
    try {
      const { error } = await supabase
        .from('group_activities')
        .update({ is_canceled: true, cancellation_reason: reason })
        .eq('id', activityId);

      if (error) throw error;

      setIsCancelModalOpen(false);
      setActivityToCancel(null);
      fetchActivities();
    } catch (error) {
      console.error('Error canceling activity:', error);
    }
  };

  const handleActivityCreated = () => {
    setIsModalOpen(false);
    fetchActivities();
  };

  const canManageActivity = (activity) => isAdmin || activity.created_by === currentUserId;

  const hasJoinedActivity = (activity) => activity.participants.some((p) => p.user_id === currentUserId);

  const handleJoinActivity = async (activityId) => {
    try {
      const { error } = await supabase.from('activity_participants').insert({
        activity_id: activityId,
        user_id: currentUserId,
      });

      if (error) throw error;

      fetchActivities();
    } catch (error) {
      console.error('Error joining activity:', error);
    }
  };

  const handleLeaveActivity = async (activityId) => {
    try {
      const { error } = await supabase
        .from('activity_participants')
        .delete()
        .eq('activity_id', activityId)
        .eq('user_id', currentUserId);

      if (error) throw error;

      fetchActivities();
    } catch (error) {
      console.error('Error leaving activity:', error);
    }
  };

  const handleManageActivity = (activity) => {
    setActivityToManage(activity);
    setIsManageModalOpen(true);
  };

  const renderActivityCard = (activity) => (
    <div
      key={activity.occurrence_id || activity.id}
      className="bg-white rounded-lg shadow-md p-4 mb-4 flex flex-col sm:flex-row hover:shadow-lg transition-shadow duration-200 cursor-pointer"
      onClick={() => navigate(`/activity/${activity.id}`)}
    >
      {activity.photos && activity.photos.length > 0 ? (
        <img
          src={activity.photos[0].photo_url}
          alt="Activity Preview"
          className="w-full sm:w-1/3 h-48 object-cover rounded-lg sm:rounded-r-none mb-4 sm:mb-0"
        />
      ) : (
        <div className="w-full sm:w-1/3 h-48 bg-gray-200 rounded-lg sm:rounded-r-none mb-4 sm:mb-0 flex items-center justify-center">
          <span className="text-gray-500">No Image</span>
        </div>
      )}

      <div className="sm:ml-4 flex-1">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-lg font-semibold">{activity.title}</h3>
          <span
            className={`px-2 py-1 rounded-full text-xs font-semibold ${
              activity.type === 'sport' ? 'bg-blue-100 text-blue-800' : 'bg-green-100 text-green-800'
            }`}
          >
            {activity.type === 'sport' ? 'Sports' : 'Social'}
          </span>
        </div>
        {activity.is_canceled && (
          <div className="mt-2 text-sm text-red-500">
            <XCircle size={16} className="inline mr-1" />
            <strong>This activity has been canceled.</strong>
            {activity.cancellation_reason && <p className="mt-1">Reason: {activity.cancellation_reason}</p>}
          </div>
        )}
        <div className="flex items-center text-gray-600 mb-2">
          <CalendarIcon size={16} className="mr-2" />
          <span>{moment(activity.start).format('MMMM D, YYYY')}</span>
          <Clock size={16} className="ml-4 mr-2" />
          <span>{moment(activity.start).format('h:mm A')} - {moment(activity.end).format('h:mm A')}</span>
        </div>
        <div className="flex items-center text-gray-600 mb-2">
          <MapPin size={16} className="mr-2" />
          <span>{activity.location_name || 'Virtual Event'}</span>
        </div>
        {activity.type === 'sport' && activity.level && (
          <div className="flex items-center text-gray-600 mb-2">
            <Trophy size={16} className="mr-2" />
            <span>Level: {activity.level}</span>
          </div>
        )}
        <div className="flex items-center text-gray-600 mb-2">
          <DollarSign size={16} className="mr-2" />
          {activity.is_paid ? <span>Price: ${activity.price.toFixed(2)}</span> : <span>Free</span>}
        </div>
        <div className="flex items-center text-gray-600 mb-2">
          {activity.icon}
          <span className="ml-2">
            {activity.participants.length}{' '}
            {activity.participants.length === 1 ? 'participant' : 'participants'}
          </span>
        </div>
        {activity.is_recurring && activity.recurring_end_date && (
          <div className="mt-2 text-sm text-gray-500">
            Recurs until {moment(activity.recurring_end_date).format('MMMM D, YYYY')}
          </div>
        )}
        <div className="flex flex-wrap space-x-4 mt-4">
          {!activity.is_canceled && (
            <>
              {hasJoinedActivity(activity) ? (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleLeaveActivity(activity.id);
                  }}
                  className="text-red-500 hover:underline flex items-center text-sm mb-2"
                >
                  <UserMinus size={16} className="mr-1" />
                  Leave Activity
                </button>
              ) : (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleJoinActivity(activity.id);
                  }}
                  className="text-green-500 hover:underline flex items-center text-sm mb-2"
                >
                  <UserPlus size={16} className="mr-1" />
                  Join Activity
                </button>
              )}
              {canManageActivity(activity) && (
                <>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleEditActivity(activity);
                    }}
                    className="text-blue-500 hover:underline flex items-center text-sm mb-2"
                  >
                    <Edit size={16} className="mr-1" />
                    Edit
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleDeleteActivity(activity.id);
                    }}
                    className="text-red-500 hover:underline flex items-center text-sm mb-2"
                  >
                    <Trash2 size={16} className="mr-1" />
                    Delete
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleCancelActivity(activity);
                    }}
                    className="text-yellow-500 hover:underline flex items-center text-sm mb-2"
                  >
                    <XCircle size={16} className="mr-1" />
                    Cancel
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleManageActivity(activity);
                    }}
                    className="text-purple-500 hover:underline flex items-center text-sm mb-2"
                  >
                    <Users size={16} className="mr-1" />
                    Manage
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );

  const eventStyleGetter = (event) => {
    const style = {
      backgroundColor: event.type === 'sport' ? '#93C5FD' : '#86EFAC',
      color: '#1F2937',
      border: 'none',
      borderRadius: '4px',
      opacity: event.is_canceled ? 0.5 : 1,
    };
    return { style };
  };

  const handleCalendarView = (newView) => {
    setCalendarView(newView);
  };

  return (
    <div className="bg-gray-100 rounded-lg p-4">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
        <h2 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-0">
          Upcoming Activities
        </h2>
        <div className="flex space-x-2">
          <button
            onClick={() => setViewMode('list')}
            className={`p-2 rounded ${viewMode === 'list' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-700'}`}
            aria-label="List view"
          >
            <List size={20} />
          </button>
          <button
            onClick={() => setViewMode('calendar')}
            className={`p-2 rounded ${viewMode === 'calendar' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-700'}`}
            aria-label="Calendar view"
          >
            <Grid size={20} />
          </button>
        </div>
      </div>

      <div className="flex flex-wrap items-center mb-4">
        <button
          className={`mr-4 mb-2 px-4 py-2 rounded-lg ${activeTab === 'sports' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          onClick={() => setActiveTab('sports')}
        >
          Sports Activities
        </button>
        <button
          className={`mr-4 mb-2 px-4 py-2 rounded-lg ${activeTab === 'social' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          onClick={() => setActiveTab('social')}
        >
          Social Events
        </button>
        {isAdmin && (
          <button
            onClick={handleCreateActivity}
            className="bg-orange-500 text-white p-2 rounded-full hover:bg-orange-600 transition-colors duration-300 mb-2"
            aria-label="Create new activity"
          >
            <Plus size={20} />
          </button>
        )}
      </div>

      {viewMode === 'list' && (
        <div className="space-y-4">
          {activities
            .filter((activity) => activity.type === (activeTab === 'sports' ? 'sport' : 'social'))
            .map(renderActivityCard)}
        </div>
      )}

      {viewMode === 'calendar' && (
        <>
          <div className="flex mb-4">
            <button
              className={`mr-4 px-4 py-2 rounded-lg ${calendarView === 'week' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-700'}`}
              onClick={() => handleCalendarView('week')}
            >
              Week
            </button>
            <button
              className={`px-4 py-2 rounded-lg ${calendarView === 'month' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-700'}`}
              onClick={() => handleCalendarView('month')}
            >
              Month
            </button>
          </div>
          <div className="bg-white rounded-lg shadow-md p-4">
            <Calendar
              localizer={localizer}
              events={activities}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              view={calendarView}
              onView={handleCalendarView}
              views={['week', 'month']}
              eventPropGetter={eventStyleGetter}
            />
          </div>
        </>
      )}

      <CreateActivityModal
        isOpen={isModalOpen}
        onClose={handleActivityCreated}
        groupId={groupId}
        currentUserId={currentUserId}
        activity={activityToEdit}
      />

      <CancelActivityModal
        isOpen={isCancelModalOpen}
        onClose={() => {
          setIsCancelModalOpen(false);
          setActivityToCancel(null);
        }}
        activity={activityToCancel}
        onCancel={handleConfirmCancel}
      />

      {isManageModalOpen && activityToManage && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
          <div className="bg-white rounded-lg w-full max-w-4xl p-6 relative overflow-y-auto max-h-full">
            <ManageActivity
              activityId={activityToManage.id}
              occurrenceId={activityToManage.occurrence_id || null}
              currentUserId={currentUserId}
              onClose={() => {
                setIsManageModalOpen(false);
                setActivityToManage(null);
                fetchActivities();
              }}
            />
            <button
              onClick={() => {
                setIsManageModalOpen(false);
                setActivityToManage(null);
              }}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <XCircle size={24} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

// CancelActivityModal component
const CancelActivityModal = ({ isOpen, onClose, activity, onCancel }) => {
  const [reason, setReason] = useState('');

  const handleCancel = () => {
    if (!reason.trim()) {
      alert('Please provide a cancellation reason.');
      return;
    }

    onCancel(activity.id, reason);
  };

  return (
    isOpen && (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
        <div className="bg-white rounded-lg w-full max-w-md p-6 relative overflow-y-auto max-h-full">
          <h2 className="text-xl font-bold mb-4">Cancel Activity</h2>
          <p>Please provide a reason for cancellation:</p>
          <textarea
            className="w-full border rounded p-2 mt-2"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          ></textarea>
          <div className="mt-6 flex justify-end space-x-2">
            <button className="bg-gray-200 text-gray-700 px-4 py-2 rounded" onClick={onClose}>
              Back
            </button>
            <button className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600" onClick={handleCancel}>
              Confirm Cancellation
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default GroupActivities;