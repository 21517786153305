import React from 'react';
import { MapPin, Phone, Mail, Globe, Clock, Users, Award, Calendar, DollarSign, Camera } from 'lucide-react';

const ClubProfile = () => {
  const clubInfo = {
    name: "Sunset Beach Tennis Club",
    established: 1995,
    location: "123 Oceanfront Drive, Beachville, BV 12345",
    phone: "+1 (555) 123-4567",
    email: "info@sunsetbeachtennis.com",
    website: "www.sunsetbeachtennis.com",
    operatingHours: "Mon-Sun: 6:00 AM - 10:00 PM",
    memberCount: 456,
    tournaments: 12,
    annualRevenue: "$540,000",
    facilities: ["6 Sand Courts", "2 Hard Courts", "Clubhouse", "Pro Shop", "Beachfront Bar"],
    achievements: [
      "Host of National Beach Tennis Championship 2022",
      "Best Club Award - Beach Tennis Association 2021",
      "Community Engagement Excellence Award 2020"
    ]
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="relative h-64 bg-blue-600">
          <img 
            src="/path-to-club-image.jpg" 
            alt="Sunset Beach Tennis Club" 
            className="w-full h-full object-cover"
          />
          <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-6">
            <h1 className="text-4xl font-bold text-white">{clubInfo.name}</h1>
            <p className="text-sm text-gray-300">Established {clubInfo.established}</p>
          </div>
          <button className="absolute top-4 right-4 bg-white p-2 rounded-full shadow-md">
            <Camera size={24} className="text-blue-600" />
          </button>
        </div>
        
        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h2 className="text-2xl font-semibold mb-4">Club Information</h2>
              <ul className="space-y-3">
                <li className="flex items-center">
                  <MapPin className="mr-2 text-blue-600" size={20} />
                  <span>{clubInfo.location}</span>
                </li>
                <li className="flex items-center">
                  <Phone className="mr-2 text-blue-600" size={20} />
                  <span>{clubInfo.phone}</span>
                </li>
                <li className="flex items-center">
                  <Mail className="mr-2 text-blue-600" size={20} />
                  <span>{clubInfo.email}</span>
                </li>
                <li className="flex items-center">
                  <Globe className="mr-2 text-blue-600" size={20} />
                  <span>{clubInfo.website}</span>
                </li>
                <li className="flex items-center">
                  <Clock className="mr-2 text-blue-600" size={20} />
                  <span>{clubInfo.operatingHours}</span>
                </li>
              </ul>
            </div>
            
            <div>
              <h2 className="text-2xl font-semibold mb-4">Club Stats</h2>
              <ul className="space-y-3">
                <li className="flex items-center">
                  <Users className="mr-2 text-blue-600" size={20} />
                  <span>{clubInfo.memberCount} Members</span>
                </li>
                <li className="flex items-center">
                  <Award className="mr-2 text-blue-600" size={20} />
                  <span>{clubInfo.tournaments} Tournaments Hosted Annually</span>
                </li>
                <li className="flex items-center">
                  <DollarSign className="mr-2 text-blue-600" size={20} />
                  <span>{clubInfo.annualRevenue} Annual Revenue</span>
                </li>
              </ul>
            </div>
          </div>
          
          <div className="mt-8">
            <h2 className="text-2xl font-semibold mb-4">Facilities</h2>
            <ul className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {clubInfo.facilities.map((facility, index) => (
                <li key={index} className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm">
                  {facility}
                </li>
              ))}
            </ul>
          </div>
          
          <div className="mt-8">
            <h2 className="text-2xl font-semibold mb-4">Recent Achievements</h2>
            <ul className="space-y-2">
              {clubInfo.achievements.map((achievement, index) => (
                <li key={index} className="flex items-start">
                  <Award className="mr-2 text-blue-600 flex-shrink-0" size={20} />
                  <span>{achievement}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClubProfile;