import React, { useState, useEffect } from 'react';
import { 
    Home, Users, Calendar, Activity, BarChart2, Settings, 
    ChevronDown, Search, Edit, Trash2, PlusCircle, LogOut, Menu, Award, CreditCard, Newspaper
  } from 'lucide-react';
import { supabase } from '../utils/supabase';
import { useNavigate } from 'react-router-dom';
import BuddiesManagement from './BuddiesManagement';
import TrainersManagement from './TrainersManagement';
import BlogManagement from './BlogManagement';
import TrainerCertificationAdmin from './TrainerCertificationAdmin'; 
import MembershipPlanManagement from './MembershipPlanManagement';
import { StripeBalance, StripeTransactions, StripePendingInvoices, StripeSubscriptions } from './StripeReports';
import StripeBalanceDisplay from './StripeBalanceDisplay';
import StripeTransactionsDisplay from './StripeTransactionsDisplay';
import StripePendingInvoicesDisplay from './StripePendingInvoicesDisplay';
import StripeSubscriptionsDisplay from './StripeSubscriptionsDisplay';
import { useSession } from '@supabase/auth-helpers-react';
import OffersManager from './OffersManager';
import FitnessArticlesManager from './FitnessArticlesManager';


const AdminDashboard = () => {
  const session = useSession();
  const [activeMenu, setActiveMenu] = useState('Dashboard');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [metrics, setMetrics] = useState({
    totalUsers: 0,
    totalTrainers: 0,
    totalEvents: 0,
    totalWorkouts: 0,
    newUsersThisMonth: 0,
    activeEventsThisWeek: 0,
    locationData: {},
  });
  const navigate = useNavigate();

  const sideMenuItems = [
    { name: 'Dashboard', icon: <Home size={20} /> },
    { 
      name: 'User Management', 
      icon: <Users size={20} />,
      subItems: ['Buddies', 'Trainers']
    },
    { name: 'Event Management', icon: <Calendar size={20} /> },
    { name: 'Workout Management', icon: <Activity size={20} /> },
    { name: 'Membership Plans', icon: <CreditCard size={20} /> },
    { 
      name: 'Reports', 
      icon: <BarChart2 size={20} />,
      subItems: ['Balance', 'Transactions', 'Pending Invoices', 'Upcoming Invoice', 'Subscriptions']
    },
    { name: 'Settings', icon: <Settings size={20} /> },
    { name: 'Blog Management', icon: <Edit size={20} /> },
    { name: 'Trainer Certification', icon: <Award size={20} /> },
    { name: 'Offers Management', icon: <PlusCircle size={20} /> }, 
    { name: 'Fitness Articles', icon: <Newspaper size={20} /> }, 
  ];

  useEffect(() => {
    fetchMetrics();
  }, []);

  const renderContent = () => {
    switch (activeMenu) {
      case 'Dashboard':
        return renderDashboard();
      case 'Buddies':
        return <BuddiesManagement />;
      case 'Trainers':
        return <TrainersManagement />;
      case 'Blog Management':
        return <BlogManagement />;
      case 'Trainer Certification':
        return <TrainerCertificationAdmin />;
      case 'Membership Plans':
        return <MembershipPlanManagement />;
      case 'Balance':
        return <StripeBalanceDisplay supabaseClient={supabase} />;
      case 'Transactions':
        return <StripeTransactionsDisplay supabaseClient={supabase} />;
      case 'Pending Invoices':
        return <StripePendingInvoicesDisplay supabaseClient={supabase} />;
      case 'Subscriptions':
        return <StripeSubscriptionsDisplay supabaseClient={supabase} />;
      case 'Offers Management': // Add this case
        return <OffersManager />;
      case 'Fitness Articles':
        return <FitnessArticlesManager />;
      default:
        return <div>Select a menu item</div>;
    }
  };

  const fetchMetrics = async () => {
    try {
      const { data: users, error: usersError } = await supabase
        .from('user_profiles')
        .select('id, role, join_date, location');
      
      if (usersError) throw usersError;
  
      const { data: events, error: eventsError } = await supabase
        .from('events')
        .select('id, date');
      
      if (eventsError) throw eventsError;
  
      const { data: workouts, error: workoutsError } = await supabase
        .from('workouts')
        .select('id');
      
      if (workoutsError) throw workoutsError;
  
      const totalUsers = users.filter(user => user.role === 'buddy').length;
      const totalTrainers = users.filter(user => user.role === 'trainer').length;
      const totalEvents = events.length;
      const totalWorkouts = workouts.length;
  
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
      const newUsersThisMonth = users.filter(user => new Date(user.join_date) > oneMonthAgo).length;
  
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
      const activeEventsThisWeek = events.filter(event => new Date(event.date) > oneWeekAgo).length;
  
      // Process location data
      const locationData = users.reduce((acc, user) => {
        if (user.location) {
          acc[user.location] = acc[user.location] || { users: 0, trainers: 0 };
          if (user.role === 'buddy') acc[user.location].users++;
          if (user.role === 'trainer') acc[user.location].trainers++;
        }
        return acc;
      }, {});

      setMetrics({
        totalUsers,
        totalTrainers,
        totalEvents,
        totalWorkouts,
        newUsersThisMonth,
        activeEventsThisWeek,
        locationData,
      });
    } catch (error) {
      console.error('Error fetching metrics:', error);
    }
  };

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  };

  const renderDashboard = () => (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        <MetricCard title="Total Users/Buddies" value={metrics.totalUsers} icon={<Users size={24} />} />
        <MetricCard title="Total Trainers" value={metrics.totalTrainers} icon={<Activity size={24} />} />
        <MetricCard title="Total Events" value={metrics.totalEvents} icon={<Calendar size={24} />} />
        <MetricCard title="Total Workouts" value={metrics.totalWorkouts} icon={<Activity size={24} />} />
        <MetricCard title="New Users This Month" value={metrics.newUsersThisMonth} icon={<PlusCircle size={24} />} />
        <MetricCard title="Active Events This Week" value={metrics.activeEventsThisMonth} icon={<Calendar size={24} />} />
      </div>
      <LocationDistribution locationData={metrics.locationData} />
    </div>
  );

  return (
    <div className="flex flex-col h-screen bg-gray-100 md:flex-row">
      {/* Mobile Header */}
      <div className="md:hidden bg-orange-500 p-4 flex justify-between items-center">
        <h1 className="text-xl font-bold text-white">Admin Dashboard</h1>
        <button onClick={() => setIsSidebarOpen(!isSidebarOpen)} className="text-white">
          <Menu size={24} />
        </button>
      </div>

      {/* Sidebar */}
      <div className={`w-full md:w-64 bg-white shadow-md flex flex-col ${isSidebarOpen ? 'block' : 'hidden'} md:block`}>
        <div className="p-4 bg-orange-500 hidden md:block">
          <h1 className="text-2xl font-bold text-white">Admin Dashboard</h1>
        </div>
        <nav className="mt-4 flex-grow">
          {sideMenuItems.map((item) => (
            <SideMenuItem 
              key={item.name} 
              item={item} 
              isActive={activeMenu === item.name}
              setActiveMenu={setActiveMenu}
              setIsSidebarOpen={setIsSidebarOpen}
            />
          ))}
        </nav>
        <button
          onClick={handleLogout}
          className="w-full flex items-center p-4 hover:bg-red-50 transition-colors duration-200 text-red-600"
        >
          <LogOut size={20} className="mr-2" />
          <span className="font-medium">Logout</span>
        </button>
      </div>

      {/* Main content */}
      <div className="flex-1 p-4 md:p-8 overflow-auto">
        <h1 className="text-2xl md:text-3xl font-bold mb-6 text-gray-800">{activeMenu}</h1>
        {renderContent()}
      </div>
    </div>
  );
};

const SideMenuItem = ({ item, isActive, setActiveMenu, setIsSidebarOpen }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (menuName) => {
    setActiveMenu(menuName);
    setIsSidebarOpen(false);
    if (item.subItems) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div>
      <button
        className={`w-full flex items-center justify-between p-4 hover:bg-orange-50 transition-colors duration-200 ${
          isActive ? 'bg-orange-100 text-orange-600' : 'text-gray-600'
        }`}
        onClick={() => handleClick(item.name)}
      >
        <div className="flex items-center">
          {React.cloneElement(item.icon, { className: isActive ? 'text-orange-500' : 'text-gray-400' })}
          <span className="ml-2 font-medium">{item.name}</span>
        </div>
        {item.subItems && <ChevronDown size={16} className={`transition-transform ${isOpen ? 'rotate-180' : ''}`} />}
      </button>
      {isOpen && item.subItems && (
        <div className="bg-orange-50 pl-8">
          {item.subItems.map((subItem) => (
            <button
              key={subItem}
              className="w-full text-left p-2 hover:bg-orange-100 transition-colors duration-200 text-gray-600"
              onClick={() => handleClick(subItem)}
            >
              {subItem}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

const MetricCard = ({ title, value, icon }) => (
  <div className="bg-white p-4 md:p-6 rounded-lg shadow-md flex items-center">
    <div className="bg-orange-100 p-3 rounded-full mr-4">
      {React.cloneElement(icon, { className: 'text-orange-500' })}
    </div>
    <div>
      <h3 className="text-sm md:text-lg font-semibold text-gray-700 mb-1">{title}</h3>
      <p className="text-xl md:text-3xl font-bold text-orange-500">{value}</p>
    </div>
  </div>
);

const LocationDistribution = ({ locationData }) => (
  <div className="bg-white p-4 md:p-6 rounded-lg shadow-md mt-6">
    <h3 className="text-lg md:text-xl font-semibold text-gray-700 mb-4">User Distribution by Location</h3>
    <div className="overflow-x-auto">
      <table className="min-w-full table-auto">
        <thead>
          <tr className="bg-orange-100">
            <th className="px-4 py-2 text-left">Location</th>
            <th className="px-4 py-2 text-left">Users</th>
            <th className="px-4 py-2 text-left">Trainers</th>
            <th className="px-4 py-2 text-left">Total</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(locationData).map(([location, data]) => (
            <tr key={location} className="border-b">
              <td className="px-4 py-2">{location}</td>
              <td className="px-4 py-2">{data.users}</td>
              <td className="px-4 py-2">{data.trainers}</td>
              <td className="px-4 py-2">{data.users + data.trainers}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

export default AdminDashboard;