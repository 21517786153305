import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Users, Calendar, Activity, MapPin } from 'lucide-react';

const FeatureCard = ({ title, details, icon }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <motion.div
      className="bg-orange-400 rounded-lg p-4 sm:p-6 cursor-pointer transition-all duration-300 ease-in-out"
      whileHover={{ scale: 1.02, boxShadow: "0px 5px 15px rgba(0,0,0,0.1)" }}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div className="flex items-center mb-2">
        <div className="bg-white p-2 rounded-full mr-3">
          {icon}
        </div>
        <h3 className="text-lg sm:text-xl font-semibold text-white">{title}</h3>
      </div>
      <motion.div
        initial={false}
        animate={{ height: isExpanded ? 'auto' : '0' }}
        transition={{ duration: 0.3 }}
        className="overflow-hidden"
      >
        <h4 className="text-base sm:text-lg font-semibold text-white mb-2">How it works:</h4>
        <ul className="list-disc list-inside text-white space-y-1 text-sm sm:text-base">
          {details.map((detail, idx) => (
            <li key={idx}>{detail}</li>
          ))}
        </ul>
      </motion.div>
    </motion.div>
  );
};

const DiscoverSection = () => {
  const features = [
    {
      icon: <Users className="h-6 w-6 text-orange-500" />,
      title: "Find Fitness Buddies",
      details: [
        "Create a detailed fitness profile",
        "Use our matching algorithm to find compatible buddies",
        "Chat and plan workouts together"
      ]
    },
    {
      icon: <Calendar className="h-6 w-6 text-orange-500" />,
      title: "Join Events",
      details: [
        "Browse events by type, date, or location",
        "RSVP and add events to your calendar",
        "Connect with other attendees before the event"
      ]
    },
    {
      icon: <Activity className="h-6 w-6 text-orange-500" />,
      title: "Track Progress",
      details: [
        "Set personalized fitness goals",
        "Log your workouts and track your progress",
        "View detailed analytics and insights"
      ]
    },
    {
      icon: <MapPin className="h-6 w-6 text-orange-500" />,
      title: "Explore Nearby",
      details: [
        "Discover nearby fitness locations",
        "Read and leave reviews for fitness spots",
        "Get directions and facility information"
      ]
    }
  ];

  return (
    <section className="py-12 sm:py-20 bg-gradient-to-b from-orange-400 to-orange-500">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          className="text-center mb-8 sm:mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-3xl sm:text-4xl font-bold text-white mb-2 sm:mb-4">
            Discover <span className="text-black">FitBuddy</span>
          </h2>
          <p className="mt-2 sm:mt-4 text-lg sm:text-xl text-white">
            Explore the features that make FitBuddy the perfect platform for your fitness journey.
          </p>
        </motion.div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
            >
              <FeatureCard {...feature} />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DiscoverSection;