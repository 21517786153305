import React, { useState, useEffect } from 'react';

const StripeTransactionsDisplay = ({ supabaseClient }) => {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        if (supabaseClient && supabaseClient.auth) {
            fetchUserRole();
            fetchData();
        } else {
            setError('Supabase client is not properly initialized');
            setLoading(false);
        }
    }, [supabaseClient]);

    const fetchUserRole = async () => {
        try {
            if (!supabaseClient || !supabaseClient.auth) {
                throw new Error('Supabase client is not properly initialized');
            }
            const { data: { user } } = await supabaseClient.auth.getUser();
            if (user) {
                const { data: profile, error } = await supabaseClient
                    .from('user_profiles')
                    .select('role')
                    .eq('id', user.id)
                    .single();

                if (error) throw error;
                setUserRole(profile.role);
            }
        } catch (err) {
            console.error('Error fetching user role:', err);
            setError('Error fetching user role: ' + err.message);
        }
    };
  
    const fetchData = async () => {
        try {
            setLoading(true);
            
            if (!supabaseClient || !supabaseClient.auth) {
                throw new Error('Supabase client is not properly initialized');
            }

            console.log('Fetching Stripe transactions');
            const { data: { user } } = await supabaseClient.auth.getUser();
            if (!user) {
                throw new Error('No authenticated user found');
            }

            const requestBody = {
                action: 'list-transactions',
                user_id: user.id
            };
            console.log('Sending request with body:', JSON.stringify(requestBody));

            const response = await fetch('https://qyveehocptbroumguiyt.supabase.co/functions/v1/stripe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${(await supabaseClient.auth.getSession()).data.session?.access_token}`
                },
                body: JSON.stringify(requestBody)
            });

            console.log('Response status:', response.status);
            console.log('Response headers:', JSON.stringify(Object.fromEntries(response.headers), null, 2));

            const responseData = await response.json();
            console.log('Response data:', responseData);

            if (!response.ok) {
                throw new Error(responseData.error || 'An error occurred while fetching the transactions');
            }
            
            setTransactions(responseData.data);
        } catch (err) {
            console.error('Fetch error:', err);
            setError(err.message || 'An unexpected error occurred');
        } finally {
            setLoading(false);
        }
    };

    const formatCurrency = (amount, currency) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: currency || 'USD' }).format(amount / 100);
    };

    console.log("Render state:", { loading, error, transactions });

    if (userRole !== 'admin') {
        return <div>Access denied. Admin privileges required.</div>;
    }
    if (loading) return <div>Loading transactions...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <h2 className="text-xl font-bold mb-4">Recent Transactions</h2>
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {transactions.map((transaction) => (
                        <tr key={transaction.id}>
                            <td className="px-6 py-4 whitespace-nowrap">{transaction.id}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{formatCurrency(transaction.amount, transaction.currency)}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{transaction.status}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{new Date(transaction.created * 1000).toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default StripeTransactionsDisplay;