import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Calendar as CalendarIcon,
  Clock,
  MapPin,
  Lock,
  Globe,
  ChevronLeft,
  Users,
  User,
  UserPlus,
  UserMinus,
  DollarSign,
} from 'lucide-react';
import moment from 'moment';
import { supabase } from '../utils/supabase';
import ParticipantModal from './ParticipantModal';
import ActivityPaymentModal from './ActivityPaymentModal'; // Import the new ActivityPaymentModal

const ActivityDetail = ({ currentUserId }) => {
  const { id } = useParams();
  const [activity, setActivity] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isParticipantModalOpen, setIsParticipantModalOpen] = useState(false);
  const [address, setAddress] = useState('');
  const [localParticipantIds, setLocalParticipantIds] = useState(new Set());
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const navigate = useNavigate();

  const fetchActivity = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('group_activities')
        .select(`
          *,
          created_by_user:user_profiles!group_activities_created_by_fkey (
            id,
            name,
            image_url
          ),
          activity_photos (
            photo_url
          ),
          activity_participants (
            user_id,
            user:user_profiles (
              id,
              name,
              image_url
            )
          )
        `)
        .eq('id', id)
        .single();

      if (error) throw error;

      if (data.latitude && data.longitude) {
        const fullAddress = await getFullAddress(data.latitude, data.longitude);
        setAddress(fullAddress);
      }

      const participants = data.activity_participants.map((p) => ({
        ...p.user,
        imageUrl: p.user.image_url
          ? supabase.storage.from('profile_images').getPublicUrl(p.user.image_url).data.publicUrl
          : null,
      }));

      setActivity({
        ...data,
        start: new Date(data.start_time),
        end: new Date(data.end_time),
        participants,
        photo:
          data.activity_photos && data.activity_photos.length > 0
            ? data.activity_photos[0].photo_url
            : null,
        host: {
          ...data.created_by_user,
          imageUrl: data.created_by_user.image_url
            ? supabase.storage.from('profile_images').getPublicUrl(data.created_by_user.image_url).data.publicUrl
            : null,
        },
      });

      setLocalParticipantIds(new Set(participants.map(p => p.id)));
    } catch (error) {
      console.error('Error fetching activity:', error);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchActivity();
  }, [fetchActivity]);

  const getFullAddress = async (lat, lon) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}&addressdetails=1`
      );
      const data = await response.json();
      if (data && data.address) {
        const { road, suburb, city, state, country } = data.address;
        return `${road || ''}, ${suburb || ''}, ${city || ''}, ${state || ''}, ${country || ''}`;
      }
      return 'Location not available';
    } catch (error) {
      console.error('Error fetching address:', error);
      return 'Location not available';
    }
  };

  const handleOpenParticipantsModal = () => {
    setIsParticipantModalOpen(true);
  };

  const handleCloseParticipantsModal = () => {
    setIsParticipantModalOpen(false);
  };

  const handleJoinActivity = async () => {
    try {
      const { error } = await supabase.from('activity_participants').insert({
        activity_id: activity.id,
        user_id: currentUserId,
      });

      if (error) throw error;

      setLocalParticipantIds(prev => new Set([...prev, currentUserId]));
      
      fetchActivity();
    } catch (error) {
      console.error('Error joining activity:', error);
      alert('Failed to join activity. Please try again.');
    }
  };

  const handleLeaveActivity = async () => {
    try {
      const { error } = await supabase
        .from('activity_participants')
        .delete()
        .eq('activity_id', activity.id)
        .eq('user_id', currentUserId);

      if (error) throw error;

      setLocalParticipantIds(prev => {
        const newSet = new Set(prev);
        newSet.delete(currentUserId);
        return newSet;
      });
      
      fetchActivity();
    } catch (error) {
      console.error('Error leaving activity:', error);
      alert('Failed to leave activity. Please try again.');
    }
  };

  const handleOpenPaymentModal = () => {
    setIsPaymentModalOpen(true);
  };

  const handleClosePaymentModal = () => {
    setIsPaymentModalOpen(false);
  };

  const handlePaymentSuccess = (paymentMethod) => {
    console.log(`Payment successful using ${paymentMethod}`);
    fetchActivity(); // Refresh activity data
    setIsPaymentModalOpen(false);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-orange-500"></div>
      </div>
    );
  }

  if (!activity) {
    return <div className="text-center mt-10">Activity not found.</div>;
  }

  const hasJoined = localParticipantIds.has(currentUserId);

  return (
    <div className="max-w-6xl mx-auto p-4 bg-gray-100 min-h-screen">
      <button
        onClick={() => navigate(`/group/${activity.group_id}`)}
        className="flex items-center text-orange-600 hover:text-orange-700 transition-colors duration-200 mb-6"
      >
        <ChevronLeft size={20} />
        <span className="ml-1 font-medium">Back to Group</span>
      </button>

      <div className="bg-white rounded-lg shadow-md overflow-hidden mb-6">
        {activity.photo && (
          <img
            src={activity.photo}
            alt="Activity"
            className="w-full h-64 object-cover"
          />
        )}
        <div className="p-6">
          <h1 className="text-3xl font-bold text-gray-800 mb-4">{activity.title}</h1>
          <div className="flex flex-wrap items-center text-gray-600 mb-4">
            <div className="flex items-center mr-6 mb-2">
              <CalendarIcon size={20} className="mr-2 text-orange-500" />
              <span>{moment(activity.start).format('dddd, MMMM D')}</span>
            </div>
            <div className="flex items-center mr-6 mb-2">
              <Clock size={20} className="mr-2 text-orange-500" />
              <span>
                {moment(activity.start).format('h:mm A')} - {moment(activity.end).format('h:mm A')}
              </span>
            </div>
          </div>
          <div className="flex items-center text-gray-600 mb-4">
            <MapPin size={20} className="mr-2 text-orange-500" />
            <span>{address || 'Location not available'}</span>
          </div>
          <div className="flex items-center text-gray-600 mb-4">
            <Clock size={20} className="mr-2 text-orange-500" />
            <span>Duration: {moment.duration(moment(activity.end).diff(activity.start)).asHours()} hr</span>
          </div>
          <div className="flex items-center text-gray-600 mb-4">
            {activity.is_public ? (
              <>
                <Globe size={20} className="mr-2 text-orange-500" />
                <span>Public</span>
              </>
            ) : (
              <>
                <Lock size={20} className="mr-2 text-orange-500" />
                <span>Private</span>
              </>
            )}
          </div>
          <div className="border-t border-gray-200 pt-4 mt-4">
            <h2 className="text-xl font-semibold mb-2 text-gray-800">Event Description</h2>
            <p className="text-gray-600 whitespace-pre-line">{activity.description}</p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="md:col-span-2">
          <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="p-6">
              <h2 className="text-xl font-semibold mb-4 text-gray-800">Details</h2>
              <div className="flex items-center mb-4">
                <Users size={20} className="mr-4 text-gray-400" />
                <span className="text-gray-700">{activity.participants.length} people responded</span>
              </div>
              <div className="flex items-center mb-4">
                <User size={20} className="mr-4 text-gray-400" />
                <div className="flex items-center">
                  <img
                    src={activity.host.imageUrl || 'https://via.placeholder.com/40'}
                    alt={activity.host.name}
                    className="w-10 h-10 rounded-full object-cover mr-2"
                  />
                  <span className="text-gray-700">
                    Event by {activity.host.name}
                  </span>
                </div>
              </div>
              <div className="flex flex-col mb-4">
                <span className="text-gray-700 mb-2">Guests:</span>
                <div className="flex items-center">
                  <div className="flex -space-x-2 mr-2">
                    {activity.participants.slice(0, 5).map((participant, index) => (
                      <img
                        key={participant.id}
                        src={participant.imageUrl || 'https://via.placeholder.com/40'}
                        alt={participant.name}
                        className="w-8 h-8 rounded-full border-2 border-white object-cover"
                        style={{ zIndex: 5 - index }}
                      />
                    ))}
                  </div>
                  {activity.participants.length > 5 && (
                    <span className="text-sm text-gray-600">+{activity.participants.length - 5} more</span>
                  )}
                </div>
                <button
                  onClick={handleOpenParticipantsModal}
                  className="mt-2 text-orange-500 hover:text-orange-600 transition-colors duration-200"
                >
                  View All Participants
                </button>
              </div>
              
              {!activity.is_canceled && (
                <div className="mt-4">
                  {hasJoined ? (
                    <button
                      onClick={handleLeaveActivity}
                      className="flex items-center justify-center w-full bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition duration-200"
                    >
                      <UserMinus size={20} className="mr-2" />
                      Leave Activity
                    </button>
                  ) : (
                    <button
                      onClick={handleJoinActivity}
                      className="flex items-center justify-center w-full bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-200"
                    >
                      <UserPlus size={20} className="mr-2" />
                      Join Activity
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        {activity.is_paid && (
          <div className="md:col-span-1">
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
              <div className="p-6">
                <h2 className="text-xl font-semibold mb-4 text-gray-800">Payment</h2>
                <p className="text-gray-600 mb-4">This is a paid event. The cost is ${activity.price.toFixed(2)}.</p>
                <button 
                  onClick={handleOpenPaymentModal}
                  className="w-full bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition duration-200 flex items-center justify-center"
                >
                  <DollarSign size={20} className="mr-2" />
                  Proceed to Payment
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {isParticipantModalOpen && (
        <ParticipantModal
          participants={activity.participants}
          onClose={handleCloseParticipantsModal}
        />
      )}

      {isPaymentModalOpen && (
        <ActivityPaymentModal
          activity={activity}
          onSuccess={handlePaymentSuccess}
          onClose={handleClosePaymentModal}
          currentUser={{ id: currentUserId }}
        />
      )}
    </div>
  );
};

export default ActivityDetail;