import React, { useState, useEffect, useRef } from 'react';
import { MessageSquare, Search, PhoneForwarded, Send, ArrowLeft, Paperclip, Smile, MoreVertical } from 'lucide-react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { supabase } from '../utils/supabase';

const TrainerChats = () => {
  const [chats, setChats] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedChat, setSelectedChat] = useState(null);
  const [message, setMessage] = useState('');
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [isMobileChatOpen, setIsMobileChatOpen] = useState(false);
  const messageEndRef = useRef(null);
  const [currentTrainer, setCurrentTrainer] = useState(null);
  const [error, setError] = useState(null);
  const [notifications, setNotifications] = useState({});

  useEffect(() => {
    fetchCurrentTrainer();
  }, []);

  useEffect(() => {
    if (currentTrainer) {
      fetchChats();
      const subscription = supabase
        .channel('messages')
        .on('INSERT', { event: 'INSERT', schema: 'public', table: 'messages' }, handleNewMessage)
        .subscribe();

      return () => {
        supabase.removeChannel(subscription);
      };
    }
  }, [currentTrainer]);

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedChat]);

  const getPublicImageUrl = (path) => {
    if (!path) return "https://via.placeholder.com/150";
    try {
      const { data } = supabase.storage
        .from('profile_images')
        .getPublicUrl(path);
      return data.publicUrl;
    } catch (error) {
      console.error('Error getting public URL:', error);
      return "https://via.placeholder.com/150";
    }
  };

  const fetchCurrentTrainer = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('user_profiles')
          .select('*')
          .eq('id', user.id)
          .single();
        if (error) throw error;
        setCurrentTrainer(data);
      }
    } catch (error) {
      console.error('Error fetching trainer profile:', error);
      setError('Failed to fetch trainer profile. Please try again later.');
    }
  };

  const fetchChats = async () => {
    try {
      const { data, error } = await supabase
        .from('messages')
        .select('*')
        .or(`sender_id.eq.${currentTrainer.id},receiver_id.eq.${currentTrainer.id}`)
        .order('sent_at', { ascending: true });

      if (error) throw error;

      const groupedChats = await groupMessagesByClient(data);
      setChats(groupedChats);

      // Initialize notifications
      const initialNotifications = {};
      groupedChats.forEach(chat => {
        initialNotifications[chat.id] = chat.messages.filter(msg => 
          msg.sender_id !== currentTrainer.id && !msg.read
        ).length;
      });
      setNotifications(initialNotifications);
    } catch (error) {
      console.error('Error fetching chats:', error);
      setError('Failed to fetch chats. Please try again later.');
    }
  };

  const groupMessagesByClient = async (messages) => {
    const groupedChats = {};
    for (const message of messages) {
      const clientId = message.sender_id === currentTrainer.id ? message.receiver_id : message.sender_id;
      if (!groupedChats[clientId]) {
        const { data: userData, error: userError } = await supabase
          .from('user_profiles')
          .select('name, image_url')
          .eq('id', clientId)
          .single();

        if (userError) {
          console.error('Error fetching user profile:', userError);
          continue;
        }

        groupedChats[clientId] = {
          id: clientId,
          client: { 
            name: userData.name, 
            avatar_url: getPublicImageUrl(userData.image_url)
          },
          messages: [],
          last_message: '',
          last_message_time: null,
          unread: 0,
        };
      }
      groupedChats[clientId].messages.push(message);
    }

    // Sort messages and set last message for each chat
    Object.values(groupedChats).forEach(chat => {
      chat.messages.sort((a, b) => new Date(a.sent_at) - new Date(b.sent_at));
      const lastMessage = chat.messages[chat.messages.length - 1];
      chat.last_message = lastMessage.content;
      chat.last_message_time = lastMessage.sent_at;
    });

    return Object.values(groupedChats);
  };

  const handleNewMessage = (payload) => {
    const newMessage = payload.new;
    setChats(prevChats => {
      const updatedChats = [...prevChats];
      const chatIndex = updatedChats.findIndex(chat => 
        chat.id === newMessage.sender_id || chat.id === newMessage.receiver_id
      );
      if (chatIndex !== -1) {
        updatedChats[chatIndex].messages.push(newMessage);
        updatedChats[chatIndex].last_message = newMessage.content;
        updatedChats[chatIndex].last_message_time = newMessage.sent_at;
        if (newMessage.sender_id !== currentTrainer.id) {
          setNotifications(prev => ({
            ...prev,
            [updatedChats[chatIndex].id]: (prev[updatedChats[chatIndex].id] || 0) + 1
          }));
        }
      } else {
        fetchChats();
      }
      return updatedChats;
    });

    if (selectedChat && (selectedChat.id === newMessage.sender_id || selectedChat.id === newMessage.receiver_id)) {
      setSelectedChat(prevChat => ({
        ...prevChat,
        messages: [...prevChat.messages, newMessage]
      }));
      updateReadStatus(selectedChat.id);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredChats = chats.filter(chat => 
    chat.client.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectChat = (chat) => {
    setSelectedChat(chat);
    setIsMobileChatOpen(true);
    updateReadStatus(chat.id);
  };

  const updateReadStatus = async (chatId) => {
    try {
      const { error } = await supabase
        .from('messages')
        .update({ read: true })
        .eq('receiver_id', currentTrainer.id)
        .eq('sender_id', chatId);

      if (error) throw error;

      setNotifications(prev => ({...prev, [chatId]: 0}));

      setChats(prevChats => 
        prevChats.map(chat => 
          chat.id === chatId
            ? {...chat, messages: chat.messages.map(msg => ({...msg, read: true}))}
            : chat
        )
      );
    } catch (error) {
      console.error('Error updating read status:', error);
    }
  };

  const handleSendMessage = async () => {
    if (!message.trim() || !selectedChat) return;

    const newMessage = {
      sender_id: currentTrainer.id,
      receiver_id: selectedChat.id,
      content: message,
      // 'sent_at' will be set automatically by the database
      // 'read' will be set to false by default
    };

    try {
      const { data, error } = await supabase
        .from('messages')
        .insert(newMessage)
        .select()
        .single();

      if (error) throw error;

      setMessage('');
      // Update the local state to show the new message immediately
      setSelectedChat(prevChat => ({
        ...prevChat,
        messages: [...prevChat.messages, data]
      }));
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message. Please try again.');
    }
  };

  const redirectToWhatsApp = (phoneNumber) => {
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="max-w-7xl mx-auto p-4 pb-16 bg-gray-100 min-h-screen">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <strong className="font-bold">Error:</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}
      
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl md:text-3xl font-bold text-gray-800">Trainer Chats</h1>
        <Link to="/dashboard" className="flex items-center text-orange-600 hover:text-orange-800 transition-colors duration-200">
          <ArrowLeft size={20} className="mr-2" />
          <span className="hidden md:inline">Back to Dashboard</span>
        </Link>
      </div>
      
      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="md:grid md:grid-cols-3 md:gap-0">
          <div className={`md:col-span-1 border-r border-gray-200 ${isMobileChatOpen ? 'hidden md:block' : 'block'}`}>
            <div className="p-4">
              <div className="relative mb-4">
                <input
                  type="text"
                  placeholder="Search clients..."
                  value={searchTerm}
                  onChange={handleSearch}
                  className="w-full p-2 pl-10 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-orange-400"
                />
                <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
              </div>
            </div>
            <AnimatePresence>
              {filteredChats.map((chat) => (
                <motion.div
                  key={chat.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.2 }}
                  className={`flex items-center p-4 cursor-pointer hover:bg-gray-50 transition-colors duration-200 ${selectedChat?.id === chat.id ? 'bg-orange-50' : ''}`}
                  onClick={() => handleSelectChat(chat)}
                >
                  <div className="relative">
                    <img src={chat.client.avatar_url} alt={chat.client.name} className="w-12 h-12 rounded-full object-cover" />
                    {notifications[chat.id] > 0 && (
                      <div className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                        {notifications[chat.id]}
                      </div>
                    )}
                  </div>
                  <div className="ml-4 flex-1">
                    <h3 className="font-semibold text-gray-800">{chat.client.name}</h3>
                    <p className="text-sm text-gray-500 truncate">{chat.last_message}</p>
                  </div>
                  <div className="text-right">
                    <p className="text-xs text-gray-400">{format(new Date(chat.last_message_time), 'MMM d')}</p>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
          
          <div className={`md:col-span-2 flex flex-col h-[calc(100vh-150px)] ${isMobileChatOpen ? 'block' : 'hidden md:flex'}`}>
            {selectedChat ? (
              <>
                <div className="flex justify-between items-center p-4 border-b border-gray-200">
                  <div className="flex items-center">
                    <button onClick={() => setIsMobileChatOpen(false)} className="mr-2 md:hidden">
                      <ArrowLeft size={20} />
                    </button>
                    <img src={selectedChat.client.avatar_url} alt={selectedChat.client.name} className="w-10 h-10 rounded-full object-cover mr-3" />
                    <div>
                      <h2 className="font-semibold text-gray-800">{selectedChat.client.name}</h2>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <button
                      className="flex items-center mr-4 px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600 transition-colors duration-200"
                      onClick={() => redirectToWhatsApp(selectedChat.client.phone_number)}
                    >
                      <PhoneForwarded size={16} className="mr-2" />
                      <span className="hidden md:inline">WhatsApp</span>
                    </button>
                    <button className="text-gray-600 hover:text-gray-800">
                      <MoreVertical size={20} />
                    </button>
                  </div>
                </div>
                <div className="flex-1 overflow-y-auto p-4" style={{ backgroundImage: "url('https://www.transparenttextures.com/patterns/cubes.png')" }}>
                  {selectedChat.messages.map((msg, index) => (
                    <div key={msg.id} className={`mb-4 ${msg.sender_id === currentTrainer.id ? 'text-right' : 'text-left'}`}>
                      <div className={`inline-block p-3 rounded-lg ${msg.sender_id === currentTrainer.id ? 'bg-orange-500 text-white' : 'bg-white'} shadow`}>
                        {msg.content}
                      </div>
                      <div className="text-xs text-gray-500 mt-1">
                        {format(new Date(msg.sent_at), 'MMM d, h:mm a')}
                      </div>
                    </div>
                  ))}
                  <div ref={messageEndRef} />
                </div>
                <div className="p-4 border-t border-gray-200">
                  <div className="flex items-center bg-gray-100 rounded-full p-2">
                    <button className="text-gray-500 hover:text-gray-700 mr-2">
                      <Paperclip size={20} />
                    </button>
                    <input
                      type="text"
                      placeholder="Type a message..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      className="flex-1 bg-transparent border-none focus:outline-none"
                    />
                    <button className="text-gray-500 hover:text-gray-700 mx-2" onClick={() => setIsEmojiPickerOpen(!isEmojiPickerOpen)}>
                      <Smile size={20} />
                    </button>
                    <button
                      onClick={handleSendMessage}
                      className="bg-orange-500 text-white rounded-full p-2 hover:bg-orange-600 transition-colors duration-200"
                    >
                      <Send size={20} />
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div className="flex flex-col items-center justify-center h-full text-gray-500">
                <MessageSquare size={48} />
                <p className="mt-4 text-xl">Select a chat to start messaging</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainerChats;