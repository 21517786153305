import React, { useState, useEffect } from 'react';
import { Plus, Edit, Trash } from 'lucide-react';
import { supabase } from '../utils/supabase';

const ManagePackages = ({ clientId, packages, onUpdate }) => {
    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentPackage, setCurrentPackage] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        getUser();
    }, []);

    const getUser = async () => {
        const { data: { user } } = await supabase.auth.getUser();
        setUser(user);
    };

    const handleAdd = async (newPackage) => {
        if (!user) return;

        const { data, error } = await supabase
            .from('client_packages')
            .insert({ 
                ...newPackage, 
                client_id: clientId,
                remaining_sessions: newPackage.sessions_left 
            })
            .select();

        if (error) {
            console.error('Error adding package:', error);
        } else {
            onUpdate();
            setIsAdding(false);
        }
    };

    const handleEdit = async (updatedPackage) => {
        if (!user) return;

        const { data, error } = await supabase
            .from('client_packages')
            .update(updatedPackage)
            .eq('id', updatedPackage.id)
            .select();

        if (error) {
            console.error('Error updating package:', error);
        } else {
            onUpdate();
            setIsEditing(false);
            setCurrentPackage(null);
        }
    };

    const handleDelete = async (packageId) => {
        if (!user) return;

        const { error } = await supabase
            .from('client_packages')
            .delete()
            .eq('id', packageId);

        if (error) {
            console.error('Error deleting package:', error);
        } else {
            onUpdate();
        }
    };

    return (
        <div>
            <h3 className="text-xl font-semibold mb-4">Packages</h3>
            {packages.map((pkg) => (
                <div key={pkg.id} className="mb-4 p-4 border rounded-lg">
                    <h4 className="font-semibold">{pkg.name}</h4>
                    <p>Sessions Left: {pkg.sessions_left}</p>
                    <p>Remaining Sessions: {pkg.remaining_sessions}</p>
                    <p>Price: ${pkg.price}</p>
                    <p>Purchase Date: {pkg.purchase_date}</p>
                    <p>Expiry Date: {pkg.expiry_date}</p>
                    <div className="mt-2">
                        <button 
                            onClick={() => {
                                setCurrentPackage(pkg);
                                setIsEditing(true);
                            }}
                            className="mr-2 text-blue-500 hover:text-blue-700"
                        >
                            <Edit size={18} />
                        </button>
                        <button 
                            onClick={() => handleDelete(pkg.id)}
                            className="text-red-500 hover:text-red-700"
                        >
                            <Trash size={18} />
                        </button>
                    </div>
                </div>
            ))}
            <button
                onClick={() => setIsAdding(true)}
                className="mt-4 bg-green-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-green-600 transition-colors"
            >
                <Plus size={20} className="mr-2" />
                Add New Package
            </button>
            {(isAdding || isEditing) && (
                <PackageForm
                    onSubmit={isAdding ? handleAdd : handleEdit}
                    onCancel={() => {
                        setIsAdding(false);
                        setIsEditing(false);
                        setCurrentPackage(null);
                    }}
                    initialData={currentPackage}
                />
            )}
        </div>
    );
};

const PackageForm = ({ onSubmit, onCancel, initialData }) => {
    const [formData, setFormData] = useState(initialData || {
        name: '',
        purchase_date: new Date().toISOString().split('T')[0],
        expiry_date: '',
        sessions_left: 0,
        price: '',
        remaining_sessions: 0
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit} className="mt-4 p-4 border rounded-lg">
            <div className="mb-4">
                <label className="block mb-2">Name:</label>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Purchase Date:</label>
                <input
                    type="date"
                    name="purchase_date"
                    value={formData.purchase_date}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Expiry Date:</label>
                <input
                    type="date"
                    name="expiry_date"
                    value={formData.expiry_date}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Sessions:</label>
                <input
                    type="number"
                    name="sessions_left"
                    value={formData.sessions_left}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Price:</label>
                <input
                    type="number"
                    name="price"
                    value={formData.price}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    step="0.01"
                    required
                />
            </div>
            <div className="flex justify-end">
                <button
                    type="button"
                    onClick={onCancel}
                    className="mr-2 px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                >
                    {initialData ? 'Update' : 'Add'} Package
                </button>
            </div>
        </form>
    );
};

export default ManagePackages;