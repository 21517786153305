import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { 
    Search, Home, MessageSquare, Users, ThumbsUp, 
    Share2, TrendingUp, Heart, MapPin, Star, Activity, Calendar, 
    Dumbbell, Plus, X, ChevronDown, ChevronUp, Image, Trash2, 
    ChevronLeft, ChevronRight, Edit, Smile, Minus
  } from 'lucide-react';
import { supabase } from '../utils/supabase';
import { motion, AnimatePresence } from 'framer-motion';
import Navigation from './Navigation';
import EmojiPicker from 'emoji-picker-react';

const SocialMediaFeed = ({ currentUser }) => {
  const [posts, setPosts] = useState([]);
  const [trendingTopics, setTrendingTopics] = useState([]);
  const [suggestedBuddies, setSuggestedBuddies] = useState([]);
  const [userProfile, setUserProfile] = useState(null);
  const [favoriteBuddies, setFavoriteBuddies] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [activeTab, setActiveTab] = useState('feed');
  const [isNewPostModalOpen, setIsNewPostModalOpen] = useState(false);
  const [newPostContent, setNewPostContent] = useState('');
  const [newPostImages, setNewPostImages] = useState([]);
  const [editingPost, setEditingPost] = useState(null);
  const [editPostContent, setEditPostContent] = useState('');
  const [editPostImages, setEditPostImages] = useState([]);
  const [expandedComments, setExpandedComments] = useState({});
  const [replyingTo, setReplyingTo] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [selectedPostImages, setSelectedPostImages] = useState(null);
  const [editingComment, setEditingComment] = useState(null);
  const [editCommentContent, setEditCommentContent] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState({});
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [expandedEvents, setExpandedEvents] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    // Implement search functionality here
    console.log('Searching for:', searchQuery);
    // You can add your search logic here, such as filtering posts or navigating to a search results page
  };

  useEffect(() => {
    if (currentUser) {
      fetchUserProfile();
      fetchPosts();
      fetchTrendingTopics();
      fetchSuggestedBuddies();
      fetchFavoriteBuddies();
      fetchNotificationCount();
      fetchUpcomingEvents();
    }
  }, [currentUser]);

  const fetchUserProfile = async () => {
    if (currentUser && currentUser.id) {
      const { data, error } = await supabase
        .from('user_profiles')
        .select('*')
        .eq('id', currentUser.id)
        .single();

      if (error) {
        console.error('Error fetching user profile:', error);
      } else {
        setUserProfile(data);
      }
    }
  };

  const fetchPosts = async () => {
    try {
      const { data, error } = await supabase
        .from('posts')
        .select(`
          *,
          user:user_profiles(id, name, image_url),
          comments:post_comments(id, content, created_at, user:user_profiles(id, name, image_url), parent_comment_id),
          likes:post_likes(id, user_id)
        `)
        .order('created_at', { ascending: false });

      if (error) throw error;

      const postsWithLikeCounts = data.map(post => ({
        ...post,
        likes_count: post.likes.length,
        is_liked: post.likes.some(like => like.user_id === currentUser.id)
      }));

      setPosts(postsWithLikeCounts);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  const fetchTrendingTopics = async () => {
    setTrendingTopics(['#FitnessChallenge', '#HealthyEating', '#WorkoutMotivation', '#RunningCommunity']);
  };

  const fetchSuggestedBuddies = async () => {
    try {
      console.log('Fetching suggested buddies...');

      // First, get all users that the current user is connected to or has pending connections with
      const { data: connections, error: connectionsError } = await supabase
        .from('buddy_connections')
        .select('receiver_id, sender_id')
        .or(`sender_id.eq.${currentUser.id},receiver_id.eq.${currentUser.id}`);

      if (connectionsError) throw connectionsError;

      // Create a set of user IDs to exclude
      const excludeIds = new Set(connections.flatMap(conn => [conn.receiver_id, conn.sender_id]));
      excludeIds.add(currentUser.id); // Exclude the current user

      // Fetch users that are not in the excludeIds set
      const { data, error } = await supabase
        .from('user_profiles')
        .select('*')
        .not('id', 'in', `(${Array.from(excludeIds).join(',')})`)
        .limit(10);

      if (error) throw error;

      console.log('Suggested buddies:', data);
      setSuggestedBuddies(data);
    } catch (error) {
      console.error('Error fetching suggested buddies:', error);
    }
  };

  const fetchFavoriteBuddies = async () => {
    try {
      const { data, error } = await supabase
        .from('favorite_buddies')
        .select('buddy_id')
        .eq('user_id', currentUser.id);

      if (error) throw error;
      setFavoriteBuddies(data.map(item => item.buddy_id));
    } catch (error) {
      console.error('Error fetching favorite buddies:', error);
    }
  };

  const fetchNotificationCount = async () => {
    try {
      const { count, error } = await supabase
        .from('notifications')
        .select('*', { count: 'exact', head: true })
        .eq('user_id', currentUser.id)
        .eq('read', false);

      if (error) throw error;
      setNotificationCount(count);
    } catch (error) {
      console.error('Error fetching notification count:', error);
    }
  };

  const fetchUpcomingEvents = async () => {
    try {
      const { data, error } = await supabase
        .from('events')
        .select('*')
        .gt('date', new Date().toISOString())
        .order('date', { ascending: true })
        .limit(5);

      if (error) throw error;
      setUpcomingEvents(data);
    } catch (error) {
      console.error('Error fetching upcoming events:', error);
    }
  };

  const handleImageUpload = (event, isEditing = false) => {
    const files = Array.from(event.target.files);
    if (isEditing) {
      setEditPostImages([...editPostImages, ...files]);
    } else {
      setNewPostImages([...newPostImages, ...files]);
    }
  };

  const handleRemoveImage = (index, isEditing = false) => {
    if (isEditing) {
      setEditPostImages(editPostImages.filter((_, i) => i !== index));
    } else {
      setNewPostImages(newPostImages.filter((_, i) => i !== index));
    }
  };

  const handleNewPost = async () => {
    if (newPostContent.trim() === '' && newPostImages.length === 0) return;
  
    try {
      let image_urls = [];
      if (newPostImages.length > 0) {
        for (const image of newPostImages) {
          const { data, error } = await supabase.storage
            .from('post_images')
            .upload(`${currentUser.id}/${Date.now()}_${image.name}`, image);
  
          if (error) throw error;
          image_urls.push(supabase.storage.from('post_images').getPublicUrl(data.path).data.publicUrl);
        }
      }
  
      const { data, error } = await supabase
        .from('posts')
        .insert({
          user_id: currentUser.id,
          content: newPostContent,
          image_urls: image_urls,
        })
        .select(`
          *,
          user:user_profiles(id, name, image_url),
          comments:post_comments(id, content, created_at, user:user_profiles(id, name, image_url), parent_comment_id),
          likes:post_likes(id, user_id)
        `)
        .single();
  
      if (error) throw error;
  
      const newPost = {
        ...data,
        likes_count: 0,
        is_liked: false
      };
  
      setPosts([newPost, ...posts]);
      setNewPostContent('');
      setNewPostImages([]);
      setIsNewPostModalOpen(false);
    } catch (error) {
      console.error('Error creating new post:', error);
    }
  };

  const handleComment = async (postId, comment, parentCommentId = null) => {
    try {
      const { data, error } = await supabase
        .from('post_comments')
        .insert({
          post_id: postId,
          user_id: currentUser.id,
          content: comment,
          parent_comment_id: parentCommentId,
        })
        .select(`
          id, content, created_at, parent_comment_id,
          user:user_profiles(id, name, image_url)
        `)
        .single();

      if (error) throw error;

      setPosts(posts.map(post => 
        post.id === postId 
          ? { ...post, comments: [...post.comments, data] } 
          : post
      ));
      setReplyingTo(null);
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleReply = (postId, commentId, userName) => {
    setReplyingTo({ postId, commentId, userName });
    const textarea = document.querySelector(`#comment-textarea-${postId}`);
    if (textarea) {
      textarea.focus();
    }
  };

  const handleEmojiClick = (postId, emojiObject) => {
    const textarea = document.querySelector(`#comment-textarea-${postId}`);
    if (textarea) {
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const text = textarea.value;
      const before = text.substring(0, start);
      const after = text.substring(end, text.length);
      textarea.value = before + emojiObject.emoji + after;
      textarea.selectionStart = textarea.selectionEnd = start + emojiObject.emoji.length;
      textarea.focus();
    }
    setShowEmojiPicker({ ...showEmojiPicker, [postId]: false });
  };

  const handleEditPost = (postId) => {
    const post = posts.find(p => p.id === postId);
    if (post) {
      setEditingPost(post);
      setEditPostContent(post.content);
      setEditPostImages(post.image_urls || []);
    }
  };

  const handleSaveEditPost = async () => {
    if (!editingPost) return;

    try {
      let image_urls = [...editPostImages];
      for (let i = 0; i < image_urls.length; i++) {
        if (image_urls[i] instanceof File) {
          const { data, error } = await supabase.storage
            .from('post_images')
            .upload(`${currentUser.id}/${Date.now()}_${image_urls[i].name}`, image_urls[i]);

          if (error) throw error;
          image_urls[i] = supabase.storage.from('post_images').getPublicUrl(data.path).data.publicUrl;
        }
      }

      const { data, error } = await supabase
        .from('posts')
        .update({ content: editPostContent, image_urls: image_urls })
        .eq('id', editingPost.id)
        .select()
        .single();

      if (error) throw error;

      setPosts(posts.map(post => 
        post.id === editingPost.id ? { ...post, content: editPostContent, image_urls: image_urls } : post
      ));
      setEditingPost(null);
      setEditPostContent('');
      setEditPostImages([]);
    } catch (error) {
      console.error('Error updating post:', error);
    }
  };

  const handleCancelEditPost = () => {
    setEditingPost(null);
    setEditPostContent('');
    setEditPostImages([]);
  };

  const handleDeletePost = async (postId) => {
    try {
      const { error: likesError } = await supabase
        .from('post_likes')
        .delete()
        .eq('post_id', postId);
  
      if (likesError) throw likesError;
  
      const { error: commentsError } = await supabase
        .from('post_comments')
        .delete()
        .eq('post_id', postId);
  
      if (commentsError) throw commentsError;
  
      const { error: postError } = await supabase
        .from('posts')
        .delete()
        .eq('id', postId);
  
      if (postError) throw postError;
  
      setPosts(posts.filter(post => post.id !== postId));
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const handleEditComment = (comment) => {
    setEditingComment(comment);
    setEditCommentContent(comment.content);
  };

  const handleSaveEditComment = async (postId) => {
    if (!editingComment) return;

    try {
      const { data, error } = await supabase
        .from('post_comments')
        .update({ content: editCommentContent })
        .eq('id', editingComment.id)
        .select()
        .single();

      if (error) throw error;

      setPosts(posts.map(post => 
        post.id === postId 
          ? { ...post, comments: post.comments.map(comment => 
              comment.id === editingComment.id ? { ...comment, content: editCommentContent } : comment
            )}
          : post
      ));
      setEditingComment(null);
      setEditCommentContent('');
    } catch (error) {
      console.error('Error updating comment:', error);
    }
  };

  const handleCancelEditComment = () => {
    setEditingComment(null);
    setEditCommentContent('');
  };

  const handleDeleteComment = async (postId, commentId) => {
    try {
      const { error } = await supabase
        .from('post_comments')
        .delete()
        .eq('id', commentId);

      if (error) throw error;

      setPosts(posts.map(post => 
        post.id === postId
          ? { ...post, comments: post.comments.filter(comment => comment.id !== commentId) }
          : post
      ));
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  const toggleComments = (postId) => {
    setExpandedComments(prev => ({
      ...prev,
      [postId]: !prev[postId]
    }));
  };

  const handleLike = async (postId) => {
    try {
      const { data, error } = await supabase
        .from('post_likes')
        .upsert({ user_id: currentUser.id, post_id: postId }, { onConflict: ['user_id', 'post_id'] });

      if (error) throw error;

      setPosts(posts.map(post => 
        post.id === postId 
          ? { ...post, likes_count: post.is_liked ? post.likes_count - 1 : post.likes_count + 1, is_liked: !post.is_liked } 
          : post
      ));
    } catch (error) {
      console.error('Error liking post:', error);
    }
  };

  const handleImageClick = (postImages, index) => {
    setSelectedPostImages(postImages);
    setSelectedImageIndex(index);
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const toggleExpandEvents = () => {
    setExpandedEvents(!expandedEvents);
  };

  const ImageModal = ({ images, currentIndex, onClose, onNext, onPrevious }) => {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={onClose}>
        <div className="max-w-[90vw] max-h-[90vh] relative" onClick={(e) => e.stopPropagation()}>
          <img src={images[currentIndex]} alt="Enlarged post image" className="max-w-full max-h-[90vh] object-contain" />
          {currentIndex > 0 && (
            <button 
              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2"
              onClick={onPrevious}
            >
              <ChevronLeft size={24} />
            </button>
          )}
          {currentIndex < images.length - 1 && (
            <button 
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2"
              onClick={onNext}
            >
              <ChevronRight size={24} />
            </button>
          )}
        </div>
      </div>
    );
  };

  const renderComments = (comments, postId, parentCommentId = null, depth = 0) => {
    const filteredComments = comments.filter(comment => comment.parent_comment_id === parentCommentId);
    
    return filteredComments.map((comment) => (
      <div key={comment.id} className={`flex items-start mb-2 ${depth > 0 ? 'ml-8' : ''}`}>
        <img
          src={comment.user.image_url ? `${supabase.storage.from('profile_images').getPublicUrl(comment.user.image_url).data.publicUrl}` : 'https://via.placeholder.com/30'}
          alt={comment.user.name}
          className="w-8 h-8 rounded-full mr-2"
        />
        <div className="flex-grow">
          {editingComment && editingComment.id === comment.id ? (
            <div>
              <textarea
                value={editCommentContent}
                onChange={(e) => setEditCommentContent(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-orange-500 mb-2"
                rows="3"
              />
              <button onClick={() => handleSaveEditComment(postId)} className="text-blue-500 hover:text-blue-700 mr-2">Save</button>
              <button onClick={handleCancelEditComment} className="text-gray-500 hover:text-gray-700">Cancel</button>
            </div>
          ) : (
            <div className="bg-gray-100 rounded-lg p-2">
              <p className="font-semibold text-sm">{comment.user.name}</p>
              <p className="text-sm whitespace-pre-wrap">{comment.content}</p>
            </div>
          )}
          <div className="mt-1 text-xs text-gray-500 flex items-center">
            <button 
              onClick={() => handleReply(postId, comment.id, comment.user.name)} 
              className="mr-2 hover:text-blue-500"
            >
              Reply
            </button>
            {(comment.user.id === currentUser.id) && (
              <>
                <button 
                  onClick={() => handleEditComment(comment)} 
                  className="mr-2 hover:text-blue-500"
                >
                  Edit
                </button>
                <button 
                  onClick={() => handleDeleteComment(postId, comment.id)} 
                  className="text-red-500 hover:text-red-700"
                >
                  Delete
                </button>
              </>
            )}
          </div>
          {renderComments(comments, postId, comment.id, depth + 1)}
        </div>
      </div>
    ));
  };

  const renderPost = (post) => (
    <div key={post.id} className="bg-white rounded-lg shadow p-4 mb-4">
      <div className="flex items-center mb-4 justify-between">
        <div className="flex items-center">
          <img
            src={post.user.image_url ? `${supabase.storage.from('profile_images').getPublicUrl(post.user.image_url).data.publicUrl}` : 'https://via.placeholder.com/40'}
            alt={post.user.name}
            className="w-10 h-10 rounded-full mr-4"
          />
          <div>
            <h3 className="font-semibold">{post.user.name}</h3>
            <p className="text-gray-500 text-sm">{new Date(post.created_at).toLocaleString()}</p>
          </div>
        </div>
        {(post.user.id === currentUser.id) && (
          <div className="flex space-x-2">
            <button 
              onClick={() => handleEditPost(post.id)} 
              className="text-blue-500 hover:text-blue-700"
            >
              Edit
            </button>
            <button 
              onClick={() => {
                if (window.confirm('Are you sure you want to delete this post? This action cannot be undone.')) {
                  handleDeletePost(post.id);
                }
              }} 
              className="text-red-500 hover:text-red-700"
            >
              Delete
            </button>   
          </div>
        )}
      </div>
      {editingPost && editingPost.id === post.id ? (
        <div>
          <textarea
            value={editPostContent}
            onChange={(e) => setEditPostContent(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-orange-500 mb-2"
            rows="3"
          />
          <div className="flex flex-wrap mb-2">
            {editPostImages.map((image, index) => (
              <div key={index} className="relative mr-2 mb-2">
                <img
                  src={image instanceof File ? URL.createObjectURL(image) : image}
                  alt={`Post image ${index + 1}`}
                  className="w-20 h-20 object-cover rounded-lg"
                />
                <button
                  onClick={() => handleRemoveImage(index, true)}
                  className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
                >
                  <X size={12} />
                </button>
              </div>
            ))}
          </div>
          <div className="flex justify-between">
            <div>
              <label htmlFor={`edit-post-image-${post.id}`} className="cursor-pointer text-blue-500 hover:text-blue-700">
                <Image size={20} className="inline mr-1" />
                Add Image
              </label>
              <input
                type="file"
                id={`edit-post-image-${post.id}`}
                accept="image/*"
                onChange={(e) => handleImageUpload(e, true)}
                className="hidden"
                multiple
              />
            </div>
            <div>
              <button onClick={handleSaveEditPost} className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mr-2">
                Save
              </button>
              <button onClick={handleCancelEditPost} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-400 transition-colors duration-300">
                Cancel
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <p className="mb-4 whitespace-pre-wrap">{post.content}</p>
          {post.image_urls && post.image_urls.length > 0 && (
            <div className={`mb-4 ${post.image_urls.length === 1 ? 'w-full' : 'grid gap-2'} ${
              post.image_urls.length === 2 ? 'grid-cols-2' :
              post.image_urls.length === 3 ? 'grid-cols-2' :
              post.image_urls.length >= 4 ? 'grid-cols-2' : ''
            }`}>
              {post.image_urls.map((url, index) => (
                <div key={index} 
                  className={`${
                    post.image_urls.length === 1 ? 'w-full h-auto max-h-96' :
                    post.image_urls.length === 2 ? 'w-full h-48' :
                    post.image_urls.length === 3 && index === 0 ? 'w-full h-48 col-span-2' :
                    post.image_urls.length === 3 && index > 0 ? 'w-full h-48' :
                    'w-full h-48'
                  } overflow-hidden rounded-lg`}
                >
                  <img 
                    src={url} 
                    alt={`Post image ${index + 1}`} 
                    className="w-full h-full object-cover cursor-pointer" 
                    onClick={() => handleImageClick(post.image_urls, index)}
                  />
                </div>
              ))}
            </div>
          )}
        </>
      )}
      <div className="flex justify-between text-gray-500">
        <button 
          className={`flex items-center ${post.is_liked ? 'text-orange-500' : 'hover:text-orange-500'}`}
          onClick={() => handleLike(post.id)}
        >
          <ThumbsUp size={20} className="mr-1" /> {post.likes_count}
        </button>
        <button 
          className="flex items-center hover:text-orange-500"
          onClick={() => toggleComments(post.id)}
        >
          <MessageSquare size={20} className="mr-1" /> {post.comments.length}
          {expandedComments[post.id] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </button>
        <button className="flex items-center hover:text-orange-500">
          <Share2 size={20} className="mr-1" /> Share
        </button>
      </div>
      {expandedComments[post.id] && (
        <div className="mt-4 border-t pt-4">
          <h4 className="font-semibold mb-2">Comments</h4>
          {renderComments(post.comments, post.id)}
          <div className="mt-4">
            <div className="flex items-start">
              <img
                src={userProfile?.image_url ? `${supabase.storage.from('profile_images').getPublicUrl(userProfile.image_url).data.publicUrl}` : 'https://via.placeholder.com/30'}
                alt={userProfile?.name}
                className="w-8 h-8 rounded-full mr-2"
              />
              <div className="flex-grow relative">
                <textarea
                  id={`comment-textarea-${post.id}`}
                  placeholder={replyingTo && replyingTo.postId === post.id ? `Replying to ${replyingTo.userName}...` : "Write a comment..."}
                  className="w-full p-2 pr-10 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 resize-vertical"
                  rows="2"
                />
                <button
                  onClick={() => setShowEmojiPicker({ ...showEmojiPicker, [post.id]: !showEmojiPicker[post.id] })}
                  className="absolute right-2 top-2 text-gray-500 hover:text-gray-700"
                >
                  <Smile size={20} />
                </button>
                {showEmojiPicker[post.id] && (
                  <div className="absolute right-0 mt-2">
                    <EmojiPicker onEmojiClick={(emojiObject) => handleEmojiClick(post.id, emojiObject)} />
                  </div>
                )}
                <button
                  onClick={() => {
                    const textarea = document.querySelector(`#comment-textarea-${post.id}`);
                    const commentContent = textarea.value.trim();
                    if (commentContent) {
                      handleComment(post.id, commentContent, replyingTo?.commentId);
                      textarea.value = '';
                      setReplyingTo(null);
                    }
                  }}
                  className="mt-2 bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition-colors duration-300"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const renderSuggestedBuddyCard = (buddy) => {
    return (
      <div key={buddy.id} className="flex-shrink-0 w-48 bg-white rounded-lg shadow-md p-4 mr-4 flex flex-col">
        <img 
          src={buddy.image_url ? `${supabase.storage.from('profile_images').getPublicUrl(buddy.image_url).data.publicUrl}` : 'https://via.placeholder.com/100'} 
          alt={buddy.name} 
          className="w-24 h-24 rounded-full mx-auto mb-4 object-cover"
        />
        <h3 className="font-semibold text-center mb-2">{buddy.name}</h3>
        <div className="flex flex-col items-center">
          <p className="text-sm text-gray-500 mb-1">Fitness Level:</p>
          <p className="text-sm font-medium">{buddy.fitness_level || 'Enthusiast'}</p>
        </div>
        <div className="mt-auto text-center">
          <Link 
            to={`/buddy/${buddy.id}`}
            className="inline-block text-orange-500 hover:text-orange-600 transition-colors duration-300 text-sm font-medium"
          >
            View Profile
          </Link>
        </div>
      </div>
    );
  };

  const renderUpcomingEvent = (event) => (
    <div key={event.id} className="bg-white rounded-lg shadow-md p-4 mb-4">
      <h3 className="font-semibold text-lg">{event.name}</h3>
      <p className="text-gray-600">
        <Calendar size={16} className="inline mr-2" />
        {new Date(event.date).toLocaleDateString()}
      </p>
      <p className="text-gray-600">
        <MapPin size={16} className="inline mr-2" />
        {event.location}
      </p>
      <Link 
        to={`/event/${event.id}`} 
        className="mt-2 inline-block text-orange-500 hover:text-orange-600 transition-colors duration-300"
      >
        View Details
      </Link>
    </div>
  );
  
  return (
    <div className="bg-gray-100 flex flex-col min-h-screen">
      <header className="bg-white shadow-md fixed top-0 left-0 right-0 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex flex-col sm:flex-row items-center justify-between space-y-4 sm:space-y-0">
            <div className="flex items-center w-full sm:w-auto justify-between sm:justify-start">
              <div className="flex items-center">
                <img 
                  src={`${process.env.PUBLIC_URL}/fitbuddy-logo.png`}
                  alt="FitBuddy Logo" 
                  className="w-8 h-8 mr-2"
                />
                <h1 className="text-2xl font-bold text-gray-800">FITBuddy</h1>
              </div>
              <div className="sm:hidden">
                <img 
                  src={userProfile?.image_url ? `${supabase.storage.from('profile_images').getPublicUrl(userProfile.image_url).data.publicUrl}` : 'https://via.placeholder.com/40'}
                  alt={userProfile?.name || 'User'} 
                  className="w-10 h-10 rounded-full cursor-pointer"
                  onClick={() => navigate('/profile')}
                />
              </div>
            </div>
            <form onSubmit={handleSearch} className="w-full sm:w-auto sm:flex-grow sm:max-w-md">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
                <button type="submit" className="absolute left-3 top-1/2 transform -translate-y-1/2">
                  <Search className="text-gray-400" size={20} />
                </button>
              </div>
            </form>
            <div className="hidden sm:block">
              <img 
                src={userProfile?.image_url ? `${supabase.storage.from('profile_images').getPublicUrl(userProfile.image_url).data.publicUrl}` : 'https://via.placeholder.com/40'}
                alt={userProfile?.name || 'User'} 
                className="w-10 h-10 rounded-full cursor-pointer"
                onClick={() => navigate('/profile')}
              />
            </div>
          </div>
        </div>
      </header>

      <div className="flex flex-col flex-grow mt-16 mb-16 md:mb-0">
        <div className="flex-grow flex flex-col md:flex-row max-w-7xl mx-auto w-full px-4 sm:px-6 lg:px-8">
          {/* Left Sidebar */}
          <aside className="hidden md:block md:w-1/4 md:pr-4">
            <nav className="bg-white rounded-lg shadow p-4 sticky top-24">
              <ul className="space-y-2">
                <li>
                  <Link to="/dashboard" className="flex items-center text-gray-700 hover:text-orange-500">
                    <Home size={20} className="mr-2" /> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to="/buddies" className="flex items-center text-gray-700 hover:text-orange-500">
                    <Users size={20} className="mr-2" /> Buddies
                  </Link>
                </li>
                <li>
                  <Link to="/groups" className="flex items-center text-gray-700 hover:text-orange-500">
                    <Users size={20} className="mr-2" /> Groups
                  </Link>
                </li>
                <li>
                  <Link to="/events" className="flex items-center text-gray-700 hover:text-orange-500">
                    <Calendar size={20} className="mr-2" /> Events
                  </Link>
                </li>
              </ul>
            </nav>
          </aside>

          {/* Main Feed */}
          <main className="w-full md:w-1/2 lg:w-2/3 overflow-y-auto px-0 md:px-4 flex-grow pt-10 pb-10 md:pb-4">
            <div className="space-y-3">
              {/* Suggested Buddies Section */}
              <div className="mb-4 relative">
                <h2 className="text-lg font-semibold mb-1 px-4 md:px-0">Suggested Buddies</h2>
                <div className="relative">
                  <button 
                    onClick={scrollLeft} 
                    className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-1 shadow-md z-10"
                    style={{ left: '4px' }} // Adjust left position
                  >
                    <ChevronLeft size={20} />
                  </button>
                  <div 
                    ref={scrollContainerRef}
                    className="flex overflow-x-auto scrollbar-hide space-x-3 py-2 px-4 md:px-0"
                    style={{ scrollSnapType: 'x mandatory' }}
                  >
                    {suggestedBuddies.map(renderSuggestedBuddyCard)}
                  </div>
                  <button 
                    onClick={scrollRight} 
                    className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-1 shadow-md z-10"
                    style={{ right: '4px' }} // Adjust right position
                  >
                    <ChevronRight size={20} />
                  </button>
                </div>
              </div>

              {/* What's on your mind? section */}
              <button 
                onClick={() => setIsNewPostModalOpen(true)}
                className="w-full bg-white rounded-lg shadow p-3 text-left text-gray-500 hover:bg-gray-50 transition-colors duration-300"
              >
                What's on your mind?
              </button>

              {/* Posts */}
              {posts.map(renderPost)}
            </div>
          </main>

          {/* Right Sidebar */}
          <aside className="hidden md:block md:w-1/4 md:pl-4">
            <div className="sticky top-24">
              <div className="bg-white rounded-lg shadow p-4 mb-4">
                <h2 className="text-xl font-semibold mb-4">Trending Topics</h2>
                <ul className="space-y-2">
                  {trendingTopics.map((topic, index) => (
                    <li key={index} className="flex items-center text-gray-700 hover:text-orange-500">
                      <TrendingUp size={20} className="mr-2" /> {topic}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="bg-white rounded-lg shadow p-4 mb-4">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold">Upcoming Events</h2>
                  <button 
                    onClick={toggleExpandEvents} 
                    className="text-orange-500 hover:text-orange-600 p-1 rounded-full hover:bg-orange-100 transition-colors duration-300"
                  >
                    {expandedEvents ? <Minus size={20} /> : <Plus size={20} />}
                  </button>
                </div>
                {upcomingEvents.length > 0 ? (
                  <div className="space-y-4">
                    {expandedEvents
                      ? upcomingEvents.map(renderUpcomingEvent)
                      : upcomingEvents.slice(0, 2).map(renderUpcomingEvent)}
                  </div>
                ) : (
                  <p className="text-gray-500 text-sm">No upcoming events at the moment.</p>
                )}
              </div>
            </div>
          </aside>
        </div>
      </div>

      {/* Navigation for mobile screens only */}
      <div className="md:hidden fixed bottom-0 left-0 right-0 bg-white shadow-md z-50">
        <Navigation activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>

      {/* New Post Modal */}
      <AnimatePresence>
        {isNewPostModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          >
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 50, opacity: 0 }}
              className="bg-white rounded-lg shadow-xl p-6 w-full max-w-lg"
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold">Create a New Post</h2>
                <button onClick={() => setIsNewPostModalOpen(false)} className="text-gray-500 hover:text-gray-700">
                  <X size={24} />
                </button>
              </div>
              <textarea
                value={newPostContent}
                onChange={(e) => setNewPostContent(e.target.value)}
                placeholder="What's on your mind?"
                className="w-full h-32 p-2 border border-gray-300 rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
              <div className="mb-4">
                <label htmlFor="post-image" className="block text-sm font-medium text-gray-700 mb-2">
                  Add images to your post
                </label>
                <input
                  type="file"
                  id="post-image"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="block w-full text-sm text-gray-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-orange-50 file:text-orange-700
                    hover:file:bg-orange-100
                  "
                  multiple
                />
              </div>
              {newPostImages.length > 0 && (
                <div className="mb-4 flex flex-wrap">
                  {newPostImages.map((image, index) => (
                    <div key={index} className="relative mr-2 mb-2">
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`New post image ${index + 1}`}
                        className="w-20 h-20 object-cover rounded-lg"
                      />
                      <button
                        onClick={() => handleRemoveImage(index)}
                        className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
                      >
                        <X size={12} />
                      </button>
                    </div>
                  ))}
                </div>
              )}
              <div className="flex justify-end mt-4">
                <button
                  onClick={handleNewPost}
                  className="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition-colors duration-300"
                >
                  Post
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Image Modal */}
      {selectedImageIndex !== null && selectedPostImages && (
        <ImageModal 
          images={selectedPostImages}
          currentIndex={selectedImageIndex}
          onClose={() => {
            setSelectedImageIndex(null);
            setSelectedPostImages(null);
          }}
          onNext={() => setSelectedImageIndex((prev) => Math.min(prev + 1, selectedPostImages.length - 1))}
          onPrevious={() => setSelectedImageIndex((prev) => Math.max(prev - 1, 0))}
        />
      )}
    </div>
  );
};

export default SocialMediaFeed;