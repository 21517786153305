import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, MapPin, Star, Activity, Filter, ChevronLeft, ChevronRight } from 'lucide-react';
import { supabase } from '../utils/supabase';

const AllBuddiesPage = ({ currentUser }) => {
  console.log('AllBuddiesPage rendered. Current user:', currentUser);

  const navigate = useNavigate();
  const [buddies, setBuddies] = useState([]);
  const [filteredBuddies, setFilteredBuddies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    ageRange: [18, 100],
    gender: '',
    fitnessLevel: '',
    interests: [],
    maxDistance: 100,
  });
  const [showFilters, setShowFilters] = useState(false);

  const buddiesPerPage = 12;

  useEffect(() => {
    console.log('Component mounted or currentUser changed. Current user:', currentUser);
    if (currentUser && currentUser.id) {
      console.log('Current user is valid. Calling fetchBuddies');
      fetchBuddies();
    } else {
      console.log('No current user available or user data incomplete');
      setError('User information is not available or incomplete. Please try again later.');
      setIsLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    applyFilters();
  }, [buddies, searchTerm, filters]);

  const fetchBuddies = async () => {
    console.log('fetchBuddies called');
    setIsLoading(true);
    try {
      // First, fetch the connected users
      const { data: connections, error: connectionsError } = await supabase
        .from('buddy_connections')
        .select('sender_id, receiver_id')
        .or(`sender_id.eq.${currentUser.id},receiver_id.eq.${currentUser.id}`)
        .eq('status', 'accepted');

      if (connectionsError) {
        console.error('Error fetching connections:', connectionsError);
        throw connectionsError;
      }

      // Create a set of connected user IDs
      const connectedUserIds = new Set(
        connections.flatMap(conn => [conn.sender_id, conn.receiver_id])
      );

      console.log('Sending request to Supabase');
      const { data, error } = await supabase
        .from('user_profiles')
        .select('*')
        .neq('id', currentUser.id)
        .neq('role', 'trainer')
        .eq('is_profile_private', false)
        .not('id', 'in', `(${Array.from(connectedUserIds).join(',')})`);

      if (error) {
        console.error('Supabase error:', error);
        throw error;
      }

      console.log('Received data from Supabase:', data);

      if (!data || data.length === 0) {
        console.log('No buddies found');
        setError('No buddies found');
        setIsLoading(false);
        return;
      }

      // Use currentUser's stored location as fallback
      const userLat = currentUser.latitude || currentUser.user_latitude;
      const userLon = currentUser.longitude || currentUser.user_longitude;

      if (!userLat || !userLon) {
        console.error('User location not available');
        setError('Unable to determine your location. Please update your profile with your location information.');
        setIsLoading(false);
        return;
      }

      const buddiesWithDistance = data.map(buddy => {
        if (!buddy.latitude || !buddy.longitude || !buddy.dob) {
          console.log('Buddy with missing data:', buddy);
          return null;
        }
        return {
          ...buddy,
          distance: calculateDistance(
            userLat,
            userLon,
            buddy.latitude,
            buddy.longitude
          ),
          age: calculateAge(buddy.dob),
        };
      }).filter(Boolean);

      console.log('Processed buddies:', buddiesWithDistance);

      buddiesWithDistance.sort((a, b) => a.distance - b.distance);
      setBuddies(buddiesWithDistance);
      setFilteredBuddies(buddiesWithDistance);
      setTotalPages(Math.ceil(buddiesWithDistance.length / buddiesPerPage));
    } catch (error) {
      console.error('Error fetching buddies:', error);
      setError('Failed to fetch buddies. Please try again later.');
    }
    setIsLoading(false);
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const applyFilters = () => {
    console.log('Applying filters');
    let filtered = buddies.filter(buddy =>
      buddy.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    filtered = filtered.filter(buddy => 
      buddy.age >= filters.ageRange[0] && 
      buddy.age <= filters.ageRange[1] &&
      (filters.maxDistance === 100 ? true : buddy.distance <= filters.maxDistance)
    );

    if (filters.gender) filtered = filtered.filter(buddy => buddy.gender === filters.gender);
    if (filters.fitnessLevel) filtered = filtered.filter(buddy => buddy.fitness_level === filters.fitnessLevel);
    if (filters.interests.length > 0) {
      filtered = filtered.filter(buddy =>
        buddy.interests.some(interest => filters.interests.includes(interest))
      );
    }

    console.log('Filtered buddies:', filtered.length);
    setFilteredBuddies(filtered);
    setTotalPages(Math.ceil(filtered.length / buddiesPerPage));
    setCurrentPage(1);
  };

  const handleFilterChange = (name, value) => {
    console.log('Filter changed:', name, value);
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleSliderChange = (name, value) => {
    console.log('Slider changed:', name, value);
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleInterestChange = (interest) => {
    console.log('Interest toggled:', interest);
    setFilters(prev => ({
      ...prev,
      interests: prev.interests.includes(interest)
        ? prev.interests.filter(i => i !== interest)
        : [...prev.interests, interest]
    }));
  };

  const renderRating = (rating) => {
    console.log('Rendering rating:', rating);
    return (
      <div className="flex items-center">
        {[...Array(5)].map((_, i) => (
          <Star key={i} size={16} className={`${i < Math.floor(rating) ? 'text-yellow-400' : 'text-gray-300'} fill-current`} />
        ))}
        <span className="ml-2 text-sm font-medium text-gray-700">{rating.toFixed(1)}</span>
      </div>
    );
  };

  const renderBuddyCard = (buddy) => {
    console.log('Rendering buddy card:', buddy);
    return (
      <div className="bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl">
        <div className="relative h-48">
          <img
            src={buddy.image_url ? `${supabase.storage.from('profile_images').getPublicUrl(buddy.image_url).data.publicUrl}` : 'https://via.placeholder.com/150'}
            alt={buddy.name}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="p-4">
          <h3 className="font-bold text-lg text-gray-800 mb-1">{buddy.name}</h3>
          <p className="text-sm text-gray-600 mb-2">{buddy.age} years old, {buddy.gender}</p>
          <div className="flex items-center text-sm text-gray-600 mb-2">
            <MapPin size={14} className="mr-1 text-orange-500" />
            {buddy.distance.toFixed(1)} km away
          </div>
          {renderRating(buddy.rating || 0)}
          <p className="text-sm mt-2">
            Fitness Level: <span className="font-semibold text-orange-500">{buddy.fitness_level}</span>
          </p>
          <div className="mt-2">
            <p className="text-sm font-medium mb-1">Interests:</p>
            <div className="flex flex-wrap gap-1">
              {buddy.interests?.slice(0, 3).map((interest, index) => (
                <span key={index} className="bg-orange-100 text-orange-800 text-xs font-medium px-2 py-1 rounded-full">{interest}</span>
              ))}
              {buddy.interests?.length > 3 && (
                <span className="bg-gray-100 text-gray-800 text-xs font-medium px-2 py-1 rounded-full">
                  +{buddy.interests.length - 3} more
                </span>
              )}
            </div>
          </div>
        </div>
        <button
          onClick={() => navigate(`/buddy/${buddy.id}`)}
          className="w-full bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 transition-colors duration-300"
        >
          View Profile
        </button>
      </div>
    );
  };

  return (
    <div className="max-w-7xl mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">All Buddies</h1>
        <button onClick={() => navigate('/')} className="text-orange-500 font-medium">
          Back to Dashboard
        </button>
      </div>

      <div className="mb-6 flex flex-col sm:flex-row items-center gap-4">
        <div className="relative w-full sm:w-64">
          <input
            type="text"
            placeholder="Search buddies..."
            className="w-full p-2 pl-10 border rounded-lg"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
        </div>
        <button
          onClick={() => setShowFilters(!showFilters)}
          className="flex items-center justify-center px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
        >
          <Filter size={20} className="mr-2" />
          Filters
        </button>
      </div>

      {showFilters && (
        <div className="mb-6 p-4 bg-white rounded-lg shadow-md">
          <h2 className="text-lg font-semibold mb-4">Filters</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Age Range: {filters.ageRange[0]} - {filters.ageRange[1]}</label>
              <input
                type="range"
                min="18"
                max="100"
                value={filters.ageRange[1]}
                onChange={(e) => handleSliderChange('ageRange', [18, parseInt(e.target.value)])}
                className="w-full accent-orange-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Max Distance: {filters.maxDistance < 100 ? `${filters.maxDistance} km` : '100+ km'}
              </label>
              <input
                type="range"
                min="0"
                max="100"
                value={filters.maxDistance}
                onChange={(e) => handleSliderChange('maxDistance', parseInt(e.target.value))}
                className="w-full accent-orange-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Gender</label>
              <select
                name="gender"
                className="w-full p-2 border rounded-lg"
                value={filters.gender}
                onChange={(e) => handleFilterChange('gender', e.target.value)}
              >
                <option value="">All</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Fitness Level</label>
              <select
                name="fitnessLevel"
                className="w-full p-2 border rounded-lg"
                value={filters.fitnessLevel}
                onChange={(e) => handleFilterChange('fitnessLevel', e.target.value)}
              >
                <option value="">All</option>
                <option value="Beginner">Beginner</option>
                <option value="Intermediate">Intermediate</option>
                <option value="Advanced">Advanced</option>
              </select>
            </div>
          </div>
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">Interests</label>
            <div className="flex flex-wrap gap-2">
            {['Running', 'Yoga', 'Weightlifting', 'Cycling', 'Swimming', 'Tennis'].map((interest) => (
                <button
                  key={interest}
                  onClick={() => handleInterestChange(interest)}
                  className={`px-3 py-1 rounded-full text-sm ${
                    filters.interests.includes(interest)
                      ? 'bg-orange-500 text-white'
                      : 'bg-gray-200 text-gray-700'
                  }`}
                >
                  {interest}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-orange-500"></div>
        </div>
      ) : error ? (
        <div className="text-center text-red-500">{error}</div>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {filteredBuddies
              .slice((currentPage - 1) * buddiesPerPage, currentPage * buddiesPerPage)
              .map((buddy) => (
                <div key={buddy.id}>{renderBuddyCard(buddy)}</div>
              ))}
          </div>
          {filteredBuddies.length === 0 && (
            <div className="text-center text-gray-500 mt-8">No buddies found matching your criteria.</div>
          )}
          {totalPages > 1 && (
            <div className="flex justify-center items-center mt-8">
              <button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="mr-4 p-2 rounded-full bg-gray-200 disabled:opacity-50"
              >
                <ChevronLeft size={24} />
              </button>
              <span className="text-gray-700">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
                className="ml-4 p-2 rounded-full bg-gray-200 disabled:opacity-50"
              >
                <ChevronRight size={24} />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AllBuddiesPage;