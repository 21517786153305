import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  MapPin, Calendar, Clock, Users, DollarSign, User, AlertCircle, X, 
  Send, ThumbsUp, Edit, Trash, ChevronDown, ChevronUp, RefreshCcw
} from 'lucide-react';
import { supabase, getCurrentUser } from '../utils/supabase';
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';
import PaymentModal from './PaymentModal';


const EventDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [host, setHost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fullAddress, setFullAddress] = useState('');
  const [attendees, setAttendees] = useState([]);
  const [attendeesDetails, setAttendeesDetails] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [isAttending, setIsAttending] = useState(false);
  const [showAttendeesModal, setShowAttendeesModal] = useState(false);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [showComments, setShowComments] = useState(true);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [refundReason, setRefundReason] = useState('');
  const [attendanceStatus, setAttendanceStatus] = useState(null);
  const [showCancellationModal, setShowCancellationModal] = useState(false);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  useEffect(() => {
    fetchEventDetails();
    fetchCurrentUser();
    if (id) {
      fetchComments();
    }
  }, [id]);

  const fetchEventDetails = async () => {
    setLoading(true);
    try {
      const { data: eventData, error: eventError } = await supabase
        .from('events')
        .select('*')
        .eq('id', id)
        .single();

      if (eventError) throw eventError;

      setEvent(eventData);
      fetchHost(eventData.host_id);
      fetchAttendees(eventData.id);
      fetchFullAddress(eventData.latitude, eventData.longitude);
    } catch (error) {
      console.error('Error fetching event details:', error);
      alert('Failed to fetch event details. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const fetchHost = async (hostId) => {
    try {
      const { data: hostData, error: hostError } = await supabase
        .from('user_profiles')
        .select('id, name, image_url')
        .eq('id', hostId)
        .single();

      if (hostError) throw hostError;

      setHost(hostData);
    } catch (error) {
      console.error('Error fetching host data:', error);
    }
  };

  const fetchAttendees = async (eventId) => {
    try {
      const { data, error } = await supabase
        .from('event_attendees')
        .select('user_id, status, payment_status')
        .eq('event_id', eventId);

      if (error) throw error;

      setAttendees(data || []);
      fetchAttendeesDetails(eventId);
    } catch (error) {
      console.error('Error fetching attendees:', error);
    }
  };

  const fetchAttendeesDetails = async (eventId) => {
    try {
      const { data, error } = await supabase
        .from('event_attendees')
        .select(`
          user_id,
          status,
          payment_status,
          user_profiles!inner(name, image_url, fitness_level)
        `)
        .eq('event_id', eventId);
  
      if (error) throw error;
  
      setAttendeesDetails(data.map(item => ({
        ...item.user_profiles,
        user_id: item.user_id,
        status: item.status,
        payment_status: item.payment_status
      })));
    } catch (error) {
      console.error('Error fetching attendees details:', error);
    }
  };

  const fetchCurrentUser = async () => {
    try {
      const user = await getCurrentUser();
      if (user) {
        setCurrentUser(user);
        checkAttendance(user.id);
      }
    } catch (error) {
      console.error('Error fetching current user:', error);
    }
  };

  const checkAttendance = async (userId) => {
    try {
      const { data, error } = await supabase
        .from('event_attendees')
        .select('*')
        .eq('event_id', id)
        .eq('user_id', userId)
        .single();

      if (error && error.code !== 'PGRST116') throw error;

      setIsAttending(!!data);
      if (data) {
        setAttendanceStatus(data.status);
      }
    } catch (error) {
      console.error('Error checking attendance:', error);
    }
  };

  const fetchFullAddress = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        setFullAddress(data.results[0].formatted_address);
      }
    } catch (error) {
      console.error('Error fetching full address:', error);
    }
  };

  const handleAttendance = async () => {
    if (!currentUser) {
      alert('Please log in to join events.');
      return;
    }

    if (isAttending) {
      setShowCancellationModal(true);
    } else {
      if (!event.is_free) {
        setShowPaymentModal(true);
      } else {
        await joinEvent();
      }
    }
  };

  const joinEvent = async () => {
    try {
      console.log('Joining event:', id);
      
      const { error: attendanceError } = await supabase
        .from('event_attendees')
        .insert({ 
          event_id: id, 
          user_id: currentUser.id,
          profile_id: currentUser.id,
          status: 'confirmed'
        });
  
      if (attendanceError) {
        console.error('Error inserting event attendance:', attendanceError);
        throw attendanceError;
      }
  
      console.log('Successfully added to event_attendees');
  
      const { data: eventData, error: eventError } = await supabase
        .from('events')
        .select('host_id, name, date, time')
        .eq('id', id)
        .single();
  
      if (eventError) {
        console.error('Error fetching event data:', eventError);
        throw eventError;
      }
  
      console.log('Fetched event data:', eventData);
  
      if (eventData) {
        const notificationData = {
          user_id: eventData.host_id,
          type: 'event_join',
          message: JSON.stringify({
            attendee_name: currentUser.name,
            event_name: eventData.name,
            event_date: eventData.date,
            event_time: eventData.time
          }),
          created_at: new Date().toISOString()
        };
  
        console.log('Attempting to insert notification:', notificationData);
  
        const { data: notificationResult, error: notificationError } = await supabase
          .from('notifications')
          .insert([notificationData])
          .select();
  
        if (notificationError) {
          console.error('Error creating notification:', notificationError);
          // Don't throw here, as we still want to update the UI even if notification fails
        } else {
          console.log('Notification created successfully:', notificationResult);
        }
      }
  
      setIsAttending(true);
      setAttendanceStatus('confirmed');
      fetchAttendees(id);
      await sendConfirmationEmail(currentUser.email, 'free');
    } catch (error) {
      console.error('Error in joinEvent:', error);
      alert('Failed to update attendance. Please try again.');
    }
  };

  const cancelAttendance = async (requestRefund = false) => {
    try {
      if (requestRefund) {
        const { error } = await supabase
          .from('event_attendees')
          .update({ 
            status: 'refund_requested',
            refund_requested_at: new Date().toISOString()
          })
          .eq('event_id', id)
          .eq('user_id', currentUser.id);
  
        if (error) throw error;
        setAttendanceStatus('refund_requested');
      } else {
        const { error } = await supabase
          .from('event_attendees')
          .delete()
          .eq('event_id', id)
          .eq('user_id', currentUser.id);
  
        if (error) throw error;
        setIsAttending(false);
        setAttendanceStatus(null);
      }
  
      // Fetch current user's name
      console.log('Fetching current user details...');
      const { data: userData, error: userError } = await supabase
        .from('user_profiles')
        .select('name')
        .eq('id', currentUser.id)
        .single();
  
      if (userError) {
        console.error('Error fetching user details:', userError);
        throw userError;
      }
  
      const userName = userData.name || 'Unknown User';
      console.log('Current user name:', userName);
  
      // Create notification for event host
      console.log('Creating notification for event host...');
      const { data: eventData, error: eventError } = await supabase
        .from('events')
        .select('host_id, name, date, time')
        .eq('id', id)
        .single();
  
      if (eventError) throw eventError;
  
      if (eventData) {
        const notificationData = {
          user_id: eventData.host_id,
          type: requestRefund ? 'event_refund_request' : 'event_cancel',
          message: JSON.stringify({
            attendee_name: userName,
            event_name: eventData.name,
            event_date: eventData.date,
            event_time: eventData.time,
            refund_reason: requestRefund ? refundReason : null
          }),
          created_at: new Date().toISOString()
        };
  
        console.log('Inserting notification:', notificationData);
        const { data: notificationResult, error: notificationError } = await supabase
          .from('notifications')
          .insert([notificationData])
          .select();
  
        if (notificationError) {
          console.error('Error creating notification:', notificationError);
        } else {
          console.log('Notification created successfully:', notificationResult);
        }
  
        // Send cancellation or refund request email
        const emailType = requestRefund ? 'refund_request' : 'attendance_cancellation';
        await supabase.functions.invoke('send-email', {
          body: JSON.stringify({
            type: emailType,
            host: { name: host.name, email: host.email },
            attendee: { name: userName, email: currentUser.email },
            event: { name: eventData.name, date: eventData.date, time: eventData.time },
            reason: requestRefund ? refundReason : null
          })
        });
        console.log(`${emailType} email sent successfully`);
      }
  
      fetchAttendees(id);
      alert(requestRefund ? 'Refund requested. The event host will review your request.' : 'Attendance cancelled successfully.');
    } catch (error) {
      console.error('Error updating attendance:', error);
      alert('Failed to update attendance. Please try again.');
    }
  };

  const handleRefundRequest = async (reason) => {
    try {
      if (!reason.trim()) {
        alert('Please provide a reason for the refund request.');
        return;
      }
  
      console.log('Processing refund request...');
      const { error: updateError } = await supabase
        .from('event_attendees')
        .update({ 
          status: 'refund_requested',
          refund_requested_at: new Date().toISOString()
        })
        .eq('event_id', id)
        .eq('user_id', currentUser.id);
  
      if (updateError) throw updateError;
      
      setAttendanceStatus('refund_requested');
      
      // Fetch current user's name
      console.log('Fetching current user details...');
      const { data: userData, error: userError } = await supabase
        .from('user_profiles')
        .select('name')
        .eq('id', currentUser.id)
        .single();
  
      if (userError) {
        console.error('Error fetching user details:', userError);
        throw userError;
      }
  
      const userName = userData.name || 'Unknown User';
      console.log('Current user name:', userName);
  
      // Create notification for event host
      console.log('Creating notification for event host...');
      const { data: eventData, error: eventError } = await supabase
        .from('events')
        .select('host_id, name, date, time')
        .eq('id', id)
        .single();
  
      if (eventError) throw eventError;
  
      if (eventData) {
        const notificationData = {
          user_id: eventData.host_id,
          type: 'event_refund_request',
          message: JSON.stringify({
            attendee_name: userName,
            event_name: eventData.name,
            event_date: eventData.date,
            event_time: eventData.time,
            refund_reason: reason
          }),
          created_at: new Date().toISOString()
        };
  
        console.log('Inserting notification:', notificationData);
        const { data: notificationResult, error: notificationError } = await supabase
          .from('notifications')
          .insert([notificationData])
          .select();
  
        if (notificationError) {
          console.error('Error creating notification:', notificationError);
        } else {
          console.log('Notification created successfully:', notificationResult);
        }
  
        // Send refund request email
        await supabase.functions.invoke('send-email', {
          body: JSON.stringify({
            type: 'refund_request',
            host: { name: host.name, email: host.email },
            attendee: { name: userName, email: currentUser.email },
            event: { name: eventData.name, date: eventData.date, time: eventData.time },
            reason: reason
          })
        });
        console.log('Refund request email sent successfully');
      }
  
      setShowRefundModal(false);
      alert('Refund requested. The event host will review your request.');
    } catch (error) {
      console.error('Error processing refund request:', error);
      alert('Failed to process refund request. Please try again.');
    }
  };

  const handlePaymentSuccess = async (paymentMethod) => {
    try {
      console.log('Payment successful, updating attendance...');
      const { data, error } = await supabase
        .from('event_attendees')
        .upsert({
          event_id: event.id,
          user_id: currentUser.id,
          profile_id: currentUser.id,
          status: 'confirmed',
          payment_status: 'paid',
          payment_method: paymentMethod
        }, { onConflict: 'event_id,user_id' })
        .select();
  
      if (error) throw error;
  
      console.log('Attendance updated successfully:', data);
      setIsAttending(true);
      setShowPaymentModal(false);
  
      // Fetch current user's name
      console.log('Fetching current user details...');
      const { data: userData, error: userError } = await supabase
        .from('user_profiles')
        .select('name')
        .eq('id', currentUser.id)
        .single();
  
      if (userError) {
        console.error('Error fetching user details:', userError);
        throw userError;
      }
  
      const userName = userData.name || 'Unknown User';
      console.log('Current user name:', userName);
  
      // Create notification for event host
      console.log('Creating notification for event host...');
      const { data: eventData, error: eventError } = await supabase
        .from('events')
        .select('host_id, name, date, time')
        .eq('id', event.id)
        .single();
  
      if (eventError) throw eventError;
  
      if (eventData) {
        const notificationData = {
          user_id: eventData.host_id,
          type: 'event_join',
          message: JSON.stringify({
            attendee_name: userName,
            event_name: eventData.name,
            event_date: eventData.date,
            event_time: eventData.time
          }),
          created_at: new Date().toISOString()
        };
  
        console.log('Inserting notification:', notificationData);
        const { data: notificationResult, error: notificationError } = await supabase
          .from('notifications')
          .insert([notificationData])
          .select();
  
        if (notificationError) {
          console.error('Error creating notification:', notificationError);
        } else {
          console.log('Notification created successfully:', notificationResult);
        }
      }
  
      // Send confirmation email
      await sendConfirmationEmail(currentUser.email, paymentMethod);
    } catch (error) {
      console.error('Error in handlePaymentSuccess:', error);
      alert('An error occurred while updating your attendance. Please try again or contact support.');
    }
  };
  
  const sendConfirmationEmail = async (userEmail, paymentMethod) => {
    try {
      const { error } = await supabase.functions.invoke('send-email', {
        body: JSON.stringify({
          type: 'event_confirmation',
          to: userEmail,
          event: {
            name: event.name,
            date: new Date(event.date).toLocaleDateString(),
            time: formatTime(event.time),
            location: fullAddress,
            description: event.description,
            paymentMethod: paymentMethod,
            paymentStatus: paymentMethod === 'stripe' ? 'Paid' : 'Pending'
          }
        })
      });
  
      if (error) throw error;
      console.log('Confirmation email sent successfully');
    } catch (error) {
      console.error('Error sending confirmation email:', error);
    }
  };

  const formatTime = (timeString) => {
    if (!timeString) return '';
    const [hours, minutes] = timeString.split(':');
    const date = new Date(2000, 0, 1, hours, minutes);
    return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });
  };

  const getHostImageUrl = (imagePath) => {
    if (!imagePath) return null;
    return supabase.storage
      .from('profile_images')
      .getPublicUrl(imagePath)
      .data.publicUrl;
  };

  const renderAttendeeAvatars = () => {
    const displayCount = 4;
    const avatars = attendeesDetails.slice(0, displayCount).map((attendee, index) => (
      <img
        key={index}
        src={getHostImageUrl(attendee.image_url)}
        alt={attendee.name}
        className="w-10 h-10 rounded-full border-2 border-white -ml-2 first:ml-0"
      />
    ));

    if (attendeesDetails.length > displayCount) {
      avatars.push(
        <div key="more" className="w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center -ml-2 border-2 border-white">
          <span className="text-sm font-medium">+{attendeesDetails.length - displayCount}</span>
        </div>
      );
    }

    return avatars;
  };

  const AttendeeModal = () => {
    const isHost = currentUser && currentUser.id === event.host_id;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 max-w-lg w-full max-h-[80vh] overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Attendees</h2>
            <button onClick={() => setShowAttendeesModal(false)}>
              <X size={24} />
            </button>
          </div>
          {attendeesDetails.map((attendee, index) => (
            <div key={index} className="flex items-center mb-4">
              <img
                src={getHostImageUrl(attendee.image_url)}
                alt={attendee.name}
                className="w-12 h-12 rounded-full mr-4"
              />
              <div>
                <p className="font-medium">{attendee.name}</p>
                <p className="text-sm text-gray-600">Fitness Level: {attendee.fitness_level}</p>
                {isHost && (
                  <>
                    <p className="text-sm text-gray-600">Status: {attendee.status}</p>
                    <p className="text-sm text-gray-600">Payment: {attendee.payment_status}</p>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const CancellationModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-lg w-full relative">
        <button 
          onClick={() => setShowCancellationModal(false)}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <X size={24} />
        </button>
        <h2 className="text-xl font-bold mb-4">Cancel Attendance</h2>
        <p className="mb-4">Are you sure you want to cancel your attendance to this event?</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={() => {
              cancelAttendance(false);
              setShowCancellationModal(false);
            }}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            Cancel Attendance
          </button>
          {!event.is_free && (
            <button
              onClick={() => {
                setShowCancellationModal(false);
                setShowRefundModal(true);
              }}
              className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
            >
              Request Refund
            </button>
          )}
          <button
            onClick={() => setShowCancellationModal(false)}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
          >
            Keep Attendance
          </button>
        </div>
      </div>
    </div>
  );

  const RefundModal = () => {
    const [localRefundReason, setLocalRefundReason] = useState('');
  
    const handleSubmit = (e) => {
      e.preventDefault();
      handleRefundRequest(localRefundReason);
    };
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 max-w-lg w-full relative">
          <button 
            onClick={() => setShowRefundModal(false)}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            <X size={24} />
          </button>
          <h2 className="text-xl font-bold mb-4">Request Refund</h2>
          <form onSubmit={handleSubmit}>
            <p className="mb-4">Please provide a reason for your refund request:</p>
            <textarea
              value={localRefundReason}
              onChange={(e) => setLocalRefundReason(e.target.value)}
              className="w-full border rounded-lg px-3 py-2 mb-4"
              rows="4"
              placeholder="Enter your reason here..."
              required
            />
            <div className="flex justify-end space-x-4">
              <button
                type="submit"
                className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
              >
                Submit Refund Request
              </button>
              <button
                type="button"
                onClick={() => setShowRefundModal(false)}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const fetchComments = async () => {
    try {
      console.log('Fetching comments for event:', id);
      const { data, error } = await supabase
        .from('event_comments')
        .select(`
          *,
          user_profiles:user_id (name, image_url),
          likes:comment_likes(count),
          replies:event_comments!parent_id(
            id,
            content,
            created_at,
            user_id,
            user_profiles:user_id (name, image_url),
            likes:comment_likes(count)
          )
        `)
        .eq('event_id', id)
        .is('parent_id', null)
        .order('created_at', { ascending: false });

      if (error) throw error;
      
      const processedComments = data.map(comment => ({
        ...comment,
        likes: comment.likes[0]?.count || 0,
        replies: comment.replies.map(reply => ({
          ...reply,
          likes: reply.likes[0]?.count || 0
        }))
      }));

      console.log('Fetched comments:', processedComments);
      setComments(processedComments);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!currentUser) {
      alert('Please log in to comment.');
      return;
    }
    if (!newComment.trim()) return;

    try {
      const { data, error } = await supabase
        .from('event_comments')
        .insert({
          event_id: id,
          user_id: currentUser.id,
          content: newComment.trim()
        });

      if (error) throw error;
      setNewComment('');
      fetchComments();
    } catch (error) {
      console.error('Error posting comment:', error);
    }
  };

  const handleReply = async (parentId, content) => {
    if (!currentUser) {
      alert('Please log in to reply.');
      return;
    }
    try {
      const { data, error } = await supabase
        .from('event_comments')
        .insert({
          event_id: id,
          user_id: currentUser.id,
          content: content,
          parent_id: parentId
        });

      if (error) throw error;
      fetchComments();
    } catch (error) {
      console.error('Error posting reply:', error);
    }
  };

  const handleEdit = async (commentId, newContent) => {
    try {
      const { data, error } = await supabase
        .from('event_comments')
        .update({ content: newContent })
        .eq('id', commentId)
        .eq('user_id', currentUser.id);

      if (error) throw error;
      fetchComments();
    } catch (error) {
      console.error('Error editing comment:', error);
    }
  };

  const handleDelete = async (commentId) => {
    try {
      const { data, error } = await supabase
        .from('event_comments')
        .delete()
        .eq('id', commentId)
        .eq('user_id', currentUser.id);

      if (error) throw error;
      fetchComments();
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  const handleLike = async (commentId) => {
    if (!currentUser) {
      alert('Please log in to like comments.');
      return;
    }
    try {
      const { data, error } = await supabase
        .from('comment_likes')
        .insert({
          comment_id: commentId,
          user_id: currentUser.id
        });

      if (error) {
        if (error.code === '23505') { // Unique constraint violation
          // User has already liked, so unlike
          const { error: unlikeError } = await supabase
            .from('comment_likes')
            .delete()
            .eq('comment_id', commentId)
            .eq('user_id', currentUser.id);

          if (unlikeError) throw unlikeError;
        } else {
          throw error;
        }
      }
      fetchComments();
    } catch (error) {
      console.error('Error liking/unliking comment:', error);
    }
  };

  const CommentItem = ({ comment, currentUser, onReply, onEdit, onDelete, onLike, depth = 0 }) => {
    const [isReplying, setIsReplying] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editedContent, setEditedContent] = useState(comment.content);
    const [replyContent, setReplyContent] = useState('');

    const handleReplySubmit = (e) => {
      e.preventDefault();
      onReply(comment.id, replyContent);
      setReplyContent('');
      setIsReplying(false);
    };

    return (
      <div className={`bg-orange-50 p-4 rounded-lg ${depth > 0 ? 'ml-8 mt-2' : 'mb-4'}`}>
        <div className="flex items-center mb-2">
          <img
            src={getHostImageUrl(comment.user_profiles?.image_url)}
            alt={comment.user_profiles?.name}
            className="w-8 h-8 rounded-full mr-2"
          />
          <span className="font-medium">{comment.user_profiles?.name}</span>
          <span className="text-gray-500 text-sm ml-2">
            {new Date(comment.created_at).toLocaleString()}
          </span>
        </div>
        {isEditing ? (
          <textarea
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            className="w-full border rounded px-2 py-1 mb-2"
            rows="3"
          />
        ) : (
          <p style={{whiteSpace: 'pre-wrap'}}>{comment.content}</p>
        )}
        <div className="mt-2 flex items-center space-x-4">
          <button onClick={() => onLike(comment.id)} className="text-orange-500 hover:text-orange-600">
            <ThumbsUp size={16} className="inline mr-1" />
            {comment.likes || 0}
          </button>
          <button onClick={() => setIsReplying(!isReplying)} className="text-orange-500 hover:text-orange-600">
            Reply
          </button>
          {currentUser && currentUser.id === comment.user_id && (
            <>
              <button onClick={() => setIsEditing(!isEditing)} className="text-orange-500 hover:text-orange-600">
                {isEditing ? 'Cancel' : 'Edit'}
              </button>
              <button onClick={() => onDelete(comment.id)} className="text-red-500 hover:text-red-600">
                Delete
              </button>
            </>
          )}
        </div>
        {isReplying && (
          <form onSubmit={handleReplySubmit} className="mt-2">
            <div className="relative">
              <textarea
                value={replyContent}
                onChange={(e) => setReplyContent(e.target.value)}
                placeholder="Write a reply..."
                className="w-full border rounded px-2 py-1"
                rows="3"
              />
              <button
                type="submit"
                className="absolute right-2 bottom-2 text-orange-500 hover:text-orange-600"
              >
                <Send size={20} />
              </button>
            </div>
          </form>
        )}
        {isEditing && (
          <div className="mt-2">
            <button onClick={() => {
              onEdit(comment.id, editedContent);
              setIsEditing(false);
            }} className="bg-orange-500 text-white px-3 py-1 rounded mr-2">
              Save
            </button>
            <button onClick={() => setIsEditing(false)} className="bg-gray-300 text-gray-700 px-3 py-1 rounded">
              Cancel
            </button>
          </div>
        )}
        {comment.replies && comment.replies.map((reply) => (
          <CommentItem
            key={reply.id}
            comment={reply}
            currentUser={currentUser}
            onReply={onReply}
            onEdit={onEdit}
            onDelete={onDelete}
            onLike={onLike}
            depth={depth + 1}
          />
        ))}
      </div>
    );
  };

  if (loading) {
    return <div className="max-w-2xl mx-auto p-4">Loading event details...</div>;
  }

  if (!event) {
    return <div className="max-w-2xl mx-auto p-4">Event not found</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-4 pb-20 sm:pb-4">
      <button onClick={() => navigate(-1)} className="mb-4 text-orange-500 font-medium">
        &larr; Back to Events
      </button>
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        {event.image_url && (
          <img src={event.image_url} alt={event.name} className="w-full h-64 object-cover" />
        )}
        <div className="p-6">
          <h1 className="text-3xl font-bold mb-2">{event.name}</h1>
          {event.status === 'canceled' && (
            <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4">
              <div className="flex items-center">
                <AlertCircle className="mr-2" />
                <p className="font-bold">This event has been canceled.</p>
              </div>
              {event.cancel_reason && (
                <p className="mt-2">Reason: {event.cancel_reason}</p>
              )}
            </div>
          )}
          <div className="flex items-center text-gray-600 mb-4">
            <Calendar size={20} className="mr-2" />
            <span>{new Date(event.date).toLocaleDateString()}</span>
            <Clock size={20} className="ml-4 mr-2" />
            <span>{formatTime(event.time)}</span>
          </div>
          <div className="flex items-center text-gray-600 mb-4">
            <MapPin size={20} className="mr-2" />
            <span>{fullAddress}</span>
          </div>
          <div className="flex items-center text-gray-600 mb-4">
            <Users size={20} className="mr-2" />
            <span>{attendees.length} / {event.max_participants} participants</span>
          </div>
          <div className="flex items-center text-gray-600 mb-6">
            <DollarSign size={20} className="mr-2" />
            <span>{event.is_free ? 'Free' : `$${event.price}`}</span>
          </div>
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-2">Event Type</h2>
            <span className="bg-orange-100 text-orange-800 text-sm font-medium px-3 py-1 rounded-full">
              {event.type}
            </span>
          </div>
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-2">Description</h2>
            <p className="text-gray-700">{event.description}</p>
          </div>
          {event.is_recurring && (
            <div className="mb-6">
              <h2 className="text-xl font-semibold mb-2">Recurring Event</h2>
              <p className="text-gray-700">This event repeats {event.recurring_frequency}.</p>
              {event.recurring_end_date && (
                <p className="text-gray-700">Ends on: {new Date(event.recurring_end_date).toLocaleDateString()}</p>
              )}
            </div>
          )}
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <h2 className="text-xl font-semibold mb-2">Host</h2>
              <div className="flex items-center">
                {host && host.image_url ? (
                  <img 
                    src={getHostImageUrl(host.image_url)}
                    alt={host.name} 
                    className="w-12 h-12 rounded-full mr-4 object-cover" 
                    onError={(e) => {
                      e.target.onerror = null; 
                      e.target.src = 'https://via.placeholder.com/48';
                    }}
                  />
                ) : (
                  <div className="w-12 h-12 rounded-full bg-gray-200 flex items-center justify-center mr-4">
                    <User size={24} className="text-gray-500" />
                  </div>
                )}
                <span className="text-lg font-medium">{host ? host.name : 'Unknown Host'}</span>
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between mb-2">
                <h2 className="text-xl font-semibold">Attendees</h2>
                <button
                  onClick={() => setShowAttendeesModal(true)}
                  className="text-orange-500 hover:text-orange-600 text-sm"
                >
                  View All
                </button>
              </div>
              <div className="flex items-center">
                <div className="flex -space-x-2 mr-4">
                  {renderAttendeeAvatars()}
                </div>
                <span className="text-sm text-gray-600">
                  {attendeesDetails.length} attending
                </span>
              </div>
            </div>
          </div>

          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-2">Location</h2>
            {isLoaded && event && event.latitude && event.longitude ? (
              <GoogleMap
                mapContainerStyle={{ width: '100%', height: '300px' }}
                center={{ lat: parseFloat(event.latitude), lng: parseFloat(event.longitude) }}
                zoom={15}
              >
                <MarkerF position={{ lat: parseFloat(event.latitude), lng: parseFloat(event.longitude) }} />
              </GoogleMap>
            ) : (
              <div>Loading map...</div>
            )}
          </div>
          
          {event.status !== 'canceled' && (
            <div className="mb-6">
              <button 
                onClick={handleAttendance}
                className={`w-full py-3 rounded-lg transition-colors text-lg font-medium ${
                  isAttending
                    ? attendanceStatus === 'refund_requested'
                      ? 'bg-yellow-500 text-white cursor-not-allowed'
                      : 'bg-red-500 text-white hover:bg-red-600'
                    : 'bg-orange-500 text-white hover:bg-orange-600'
                }`}
                disabled={attendanceStatus === 'refund_requested'}
              >
                {isAttending
                  ? attendanceStatus === 'refund_requested'
                    ? 'Refund Requested'
                    : 'Cancel Attendance'
                  : 'Join Event'}
              </button>
              {attendanceStatus === 'refund_requested' && (
                <p className="text-sm text-gray-600 mt-2">Your refund request is being processed.</p>
              )}
            </div>
          )}

          <div className="mt-8">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-semibold">Comments</h2>
              <button 
                onClick={() => setShowComments(!showComments)}
                className="text-orange-500 hover:text-orange-600"
              >
                {showComments ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
              </button>
            </div>
            {showComments && (
              <>
                <form onSubmit={handleCommentSubmit} className="mb-6">
                  <div className="relative">
                    <textarea
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      placeholder="Add a comment..."
                      className="w-full border rounded-lg px-4 py-2 pr-10 focus:outline-none focus:ring-2 focus:ring-orange-500 bg-orange-50"
                      rows="3"
                    />
                    <button
                      type="submit"
                      className="absolute right-2 bottom-2 text-orange-500 hover:text-orange-600"
                    >
                      <Send size={20} />
                    </button>
                  </div>
                </form>
                <div className="space-y-4">
                  {comments.map((comment) => (
                    <CommentItem
                      key={comment.id}
                      comment={comment}
                      currentUser={currentUser}
                      onReply={handleReply}
                      onEdit={handleEdit}
                      onDelete={handleDelete}
                      onLike={handleLike}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {showAttendeesModal && <AttendeeModal />}
      {showPaymentModal && (
        <PaymentModal
          event={event}
          onSuccess={handlePaymentSuccess}
          onClose={() => setShowPaymentModal(false)}
          currentUser={currentUser} 
        />
      )}
      {showRefundModal && <RefundModal />}
      {showCancellationModal && <CancellationModal />}
    </div>
  );
};

export default EventDetail;