import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const StripeBalanceDisplay = ({ supabaseClient }) => {
    const [currentBalance, setCurrentBalance] = useState(null);
    const [balanceHistory, setBalanceHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        console.log('supabaseClient:', supabaseClient);
        if (supabaseClient && supabaseClient.auth) {
            fetchUserRole();
            fetchData();
        } else {
            console.error('Invalid supabaseClient:', supabaseClient);
            setError('Supabase client is not properly initialized');
            setLoading(false);
        }
    }, [supabaseClient]);

    const fetchUserRole = async () => {
        try {
            if (!supabaseClient || !supabaseClient.auth) {
                throw new Error('Supabase client is not properly initialized');
            }
            const { data: { user } } = await supabaseClient.auth.getUser();
            if (user) {
                const { data: profile, error } = await supabaseClient
                    .from('user_profiles')
                    .select('role')
                    .eq('id', user.id)
                    .single();

                if (error) throw error;
                setUserRole(profile.role);
            }
        } catch (err) {
            console.error('Error fetching user role:', err);
            setError('Error fetching user role: ' + err.message);
        }
    };

    const fetchData = async () => {
        try {
            setLoading(true);
            
            if (!supabaseClient || !supabaseClient.auth) {
                throw new Error('Supabase client is not properly initialized');
            }
    
            console.log('Fetching Stripe balance');
            const { data: { user } } = await supabaseClient.auth.getUser();
            if (!user) {
                throw new Error('No authenticated user found');
            }
    
            const requestBody = {
                action: 'get-stripe-balance',
                user_id: user.id
            };
            console.log('Sending request with body:', JSON.stringify(requestBody));
    
            const response = await fetch('https://qyveehocptbroumguiyt.supabase.co/functions/v1/stripe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${(await supabaseClient.auth.getSession()).data.session?.access_token}`
                },
                body: JSON.stringify(requestBody)
            });
    
            console.log('Response status:', response.status);
            console.log('Response headers:', JSON.stringify(Object.fromEntries(response.headers), null, 2));
    
            const responseData = await response.json();
            console.log('Response data:', responseData);
    
            if (!response.ok) {
                throw new Error(responseData.error || 'An error occurred while fetching the balance');
            }
            
            setCurrentBalance(responseData);
    
            // Mock history data (as we don't have a real history endpoint)
            const mockHistoryData = [
                { timestamp: '2023-08-01', available: 5000, pending: 1000 },
                { timestamp: '2023-08-02', available: 5500, pending: 800 },
                { timestamp: '2023-08-03', available: 6000, pending: 1200 },
                { timestamp: '2023-08-04', available: 5800, pending: 900 },
                { timestamp: '2023-08-05', available: 6200, pending: 700 },
            ];
            setBalanceHistory(mockHistoryData);
        } catch (err) {
            console.error('Fetch error:', err);
            setError(err.message || 'An unexpected error occurred');
        } finally {
            setLoading(false);
        }
    };

    const formatCurrency = (amount, currency) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: currency || 'USD' }).format(amount / 100);
    };

    const chartData = balanceHistory.map(item => ({
        date: new Date(item.timestamp).toLocaleDateString(),
        available: item.available / 100,
        pending: item.pending / 100,
    }));

    if (userRole !== 'admin') {
        return <div>Access denied. Admin privileges required.</div>;
    }
    if (loading) return <div>Loading... Please check the console for details.</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Stripe Balance</h2>
            
            <div className="mb-6">
                <h3 className="text-xl font-semibold mb-2">Current Balance</h3>
                {currentBalance && (
                    <div>
                        <p>Available: {formatCurrency(currentBalance.available[0].amount, currentBalance.available[0].currency)}</p>
                        <p>Pending: {formatCurrency(currentBalance.pending[0].amount, currentBalance.pending[0].currency)}</p>
                    </div>
                )}
            </div>

            <div>
                <h3 className="text-xl font-semibold mb-2">Balance History</h3>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={chartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip 
                            formatter={(value, name) => [formatCurrency(value * 100), name]}
                        />
                        <Legend />
                        <Line type="monotone" dataKey="available" stroke="#8884d8" name="Available" />
                        <Line type="monotone" dataKey="pending" stroke="#82ca9d" name="Pending" />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default StripeBalanceDisplay;