import React, { useState, useEffect } from 'react';
import { LineChart, Calendar, Trash2, TrendingUp, TrendingDown, Target, Edit2, ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import { supabase } from '../utils/supabase'; // Make sure this path is correct

const WeightTracker = ({ currentUser }) => {
  const [entries, setEntries] = useState([]);
  const [weight, setWeight] = useState('');
  const [bodyFat, setBodyFat] = useState('');
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [goalWeight, setGoalWeight] = useState(null);
  const [isEditingGoal, setIsEditingGoal] = useState(false);

  useEffect(() => {
    fetchEntries();
    fetchGoalWeight();
  }, []);

  const fetchEntries = async () => {
    try {
      const { data, error } = await supabase
        .from('weight_entries')
        .select('*')
        .order('date', { ascending: false })
        .limit(5);

      if (error) throw error;
      setEntries(data);
    } catch (error) {
      console.error('Error fetching entries:', error);
      alert('Failed to load weight entries. Please try again.');
    }
  };

  const fetchGoalWeight = async () => {
    try {
      const { data, error } = await supabase
        .from('goal_weights')
        .select('goal_weight')
        .order('created_at', { ascending: false })
        .limit(1);

      if (error) throw error;
      if (data.length > 0) {
        setGoalWeight(data[0].goal_weight);
      }
    } catch (error) {
      console.error('Error fetching goal weight:', error);
      alert('Failed to load goal weight. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newEntry = {
      user_id: currentUser.id,
      date,
      weight: parseFloat(weight),
      body_fat: bodyFat ? parseFloat(bodyFat) : null,
    };

    try {
      const { error } = await supabase
        .from('weight_entries')
        .insert([newEntry]);

      if (error) throw error;

      setWeight('');
      setBodyFat('');
      setDate(new Date().toISOString().split('T')[0]);
      fetchEntries();
    } catch (error) {
      console.error('Error adding entry:', error);
      alert('Failed to add weight entry. Please try again.');
    }
  };

  const handleDelete = async (id) => {
    try {
      const { error } = await supabase
        .from('weight_entries')
        .delete()
        .match({ id });

      if (error) throw error;
      fetchEntries();
    } catch (error) {
      console.error('Error deleting entry:', error);
      alert('Failed to delete weight entry. Please try again.');
    }
  };

  const handleGoalSubmit = async (e) => {
    e.preventDefault();
    const newGoalWeight = parseFloat(e.target.goalWeight.value);

    try {
      const { error } = await supabase
        .from('goal_weights')
        .insert([{ user_id: currentUser.id, goal_weight: newGoalWeight }]);

      if (error) throw error;
      setGoalWeight(newGoalWeight);
      setIsEditingGoal(false);
    } catch (error) {
      console.error('Error setting goal weight:', error);
      alert('Failed to set goal weight. Please try again.');
    }
  };

  const getWeightChange = (currentWeight, previousWeight) => {
    if (!previousWeight) return null;
    const change = currentWeight - previousWeight;
    return change.toFixed(1);
  };

  const latestWeight = entries.length > 0 ? entries[0].weight : null;
  const weightToGoal = latestWeight && goalWeight ? (latestWeight - goalWeight).toFixed(1) : null;

  return (
    <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl m-4">
      <div className="p-8">
        <Link 
          to="/dashboard" 
          className="flex items-center text-orange-500 hover:text-orange-600 mb-4"
        >
          <ArrowLeft className="mr-2" size={20} />
          Back to Dashboard
        </Link>

        <div className="uppercase tracking-wide text-sm text-orange-500 font-semibold mb-4 flex items-center">
          <LineChart className="mr-2" size={24} />
          Weight Tracker
        </div>

        {/* Goal Weight Section */}
        <div className="mb-6 p-4 bg-orange-100 rounded-lg">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-semibold flex items-center">
              <Target className="mr-2" size={20} />
              Goal Weight
            </h3>
            <button
              onClick={() => setIsEditingGoal(!isEditingGoal)}
              className="text-orange-500 hover:text-orange-600"
            >
              <Edit2 size={18} />
            </button>
          </div>
          {isEditingGoal ? (
            <form onSubmit={handleGoalSubmit} className="mt-2">
              <input
                type="number"
                name="goalWeight"
                defaultValue={goalWeight || ''}
                step="0.1"
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
              <button
                type="submit"
                className="mt-2 bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
              >
                Set Goal
              </button>
            </form>
          ) : (
            <div className="mt-2">
              <p className="text-2xl font-bold">{goalWeight ? `${goalWeight} kg` : 'Not set'}</p>
              {weightToGoal !== null && (
                <p className={`mt-1 ${weightToGoal > 0 ? 'text-red-500' : 'text-green-500'}`}>
                  {weightToGoal > 0
                    ? `${weightToGoal} kg to lose`
                    : weightToGoal < 0
                    ? `${Math.abs(weightToGoal)} kg to gain`
                    : 'Goal achieved!'}
                </p>
              )}
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit} className="mb-8">
          <div className="mb-4">
            <label htmlFor="date" className="block text-gray-700 text-sm font-bold mb-2 flex items-center">
              <Calendar className="mr-2" size={16} />
              Date
            </label>
            <input
              type="date"
              id="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-orange-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="weight" className="block text-gray-700 text-sm font-bold mb-2">Weight (kg)</label>
            <input
              type="number"
              id="weight"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-orange-500"
              step="0.1"
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="bodyFat" className="block text-gray-700 text-sm font-bold mb-2">Body Fat (%) - Optional</label>
            <input
              type="number"
              id="bodyFat"
              value={bodyFat}
              onChange={(e) => setBodyFat(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-orange-500"
              step="0.1"
              min="0"
              max="100"
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
            >
              Add Entry
            </button>
          </div>
        </form>
        <div>
          <h3 className="text-lg font-semibold mb-4">Recent Entries</h3>
          {entries.length === 0 ? (
            <p className="text-gray-500">No entries yet. Start tracking your progress!</p>
          ) : (
            <ul className="space-y-4">
              {entries.map((entry, index) => {
                const previousEntry = entries[index + 1];
                const weightChange = getWeightChange(entry.weight, previousEntry?.weight);
                return (
                  <li key={entry.id} className="bg-gray-50 rounded-lg p-4 shadow-sm">
                    <div className="flex justify-between items-start">
                      <div>
                        <p className="text-sm font-medium text-gray-900">{new Date(entry.date).toLocaleDateString()}</p>
                        <p className="text-lg font-bold text-gray-800 mt-1">{entry.weight} kg</p>
                        {entry.body_fat !== null && (
                          <p className="text-sm text-gray-600 mt-1">Body Fat: {entry.body_fat}%</p>
                        )}
                        {weightChange && (
                          <p className={`text-sm mt-1 flex items-center ${parseFloat(weightChange) < 0 ? 'text-green-600' : 'text-red-600'}`}>
                            {parseFloat(weightChange) < 0 ? (
                              <TrendingDown size={16} className="mr-1" />
                            ) : (
                              <TrendingUp size={16} className="mr-1" />
                            )}
                            {Math.abs(parseFloat(weightChange))} kg
                          </p>
                        )}
                      </div>
                      <button
                        onClick={() => handleDelete(entry.id)}
                        className="text-red-500 hover:text-red-700 transition duration-300 ease-in-out"
                      >
                        <Trash2 size={18} />
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default WeightTracker;