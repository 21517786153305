import React, { useState, useRef, useEffect } from 'react';

const SliderInput = ({ question, value, onChange }) => {
  const sliderRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleStart = (clientX) => {
    setIsDragging(true);
    document.addEventListener('mousemove', handleMove);
    document.addEventListener('mouseup', handleEnd);
    document.addEventListener('touchmove', handleMove);
    document.addEventListener('touchend', handleEnd);
  };

  const handleMove = (event) => {
    if (!isDragging) return;
    const clientX = event.type.includes('mouse') ? event.clientX : event.touches[0].clientX;
    const rect = sliderRef.current.getBoundingClientRect();
    const x = Math.max(0, Math.min(clientX - rect.left, rect.width));
    const percentage = x / rect.width;
    const newValue = question.min + percentage * (question.max - question.min);
    onChange(Number(newValue.toFixed(getDecimalPlaces(question.step))));
  };

  const handleEnd = () => {
    setIsDragging(false);
    document.removeEventListener('mousemove', handleMove);
    document.removeEventListener('mouseup', handleEnd);
    document.removeEventListener('touchmove', handleMove);
    document.removeEventListener('touchend', handleEnd);
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleMove);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchmove', handleMove);
      document.removeEventListener('touchend', handleEnd);
    };
  }, []);

  const getDecimalPlaces = (step) => {
    if (Math.floor(step) === step) return 0;
    return step.toString().split(".")[1].length || 0;
  };

  const getPercentage = () => {
    return ((value - question.min) / (question.max - question.min)) * 100;
  };

  return (
    <div className="w-full py-4">
      <div
        ref={sliderRef}
        className="w-full h-2 bg-orange-200 rounded-lg appearance-none cursor-pointer relative"
        onClick={(e) => {
          const rect = e.currentTarget.getBoundingClientRect();
          const x = e.clientX - rect.left;
          const percentage = x / rect.width;
          const newValue = question.min + percentage * (question.max - question.min);
          onChange(Number(newValue.toFixed(getDecimalPlaces(question.step))));
        }}
      >
        <div
          className="absolute h-full bg-orange-500 rounded-l-lg"
          style={{ width: `${getPercentage()}%` }}
        ></div>
        <div
          className="absolute w-6 h-6 bg-white border-2 border-orange-500 rounded-full top-1/2 transform -translate-y-1/2 -ml-3 cursor-grab active:cursor-grabbing shadow-md"
          style={{ left: `${getPercentage()}%` }}
          onMouseDown={(e) => {
            e.preventDefault();
            handleStart(e.clientX);
          }}
          onTouchStart={(e) => {
            e.preventDefault();
            handleStart(e.touches[0].clientX);
          }}
        ></div>
      </div>
      <div className="flex justify-between mt-2">
        <span className="text-sm text-gray-500">{question.min}</span>
        <span className="text-sm font-medium text-gray-700">{value.toFixed(getDecimalPlaces(question.step))}</span>
        <span className="text-sm text-gray-500">{question.max}</span>
      </div>
    </div>
  );
};

export default SliderInput;