import React, { useState, useEffect, memo, useCallback, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { User, Calendar, Award, MapPin, Zap, Book, ShoppingBag, ChevronRight, Star, ArrowLeft, Clock, ExternalLink } from 'lucide-react';
import placeholderBuddy from '../assets/placeholder-buddy.jpg';
import placeholderEvent from '../assets/placeholder-event.jpg';
import placeholderTrainer from '../assets/placeholder-trainer.jpg';
import placeholderGym from '../assets/placeholder-gym.jpg';
import placeholderFitness from '../assets/placeholder-fitness.jpg';
import { supabase } from '../utils/supabase';
import LoadingSpinner from './LoadingSpinner';
import Navigation from './Navigation'; 
import { fetchAndStoreFitnessArticles, getFitnessArticlesFromSupabase } from './FitnessArticlesFetcher';

const ExplorePage = memo(({ user, communityHighlights }) => {
  const navigate = useNavigate();
  const [trendingTrainers, setTrendingTrainers] = useState([]);
  const [forYouItems, setForYouItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [localSpots, setLocalSpots] = useState([]);
  const [localServices, setLocalServices] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const [fitnessArticles, setFitnessArticles] = useState([]);
  const [activeTab, setActiveTab] = useState('explore');

  const fetchTrendingTrainers = useCallback(async () => {
    try {
      console.log('Fetching trending trainers...');

      const { data: userProfiles, error: userProfilesError } = await supabase
        .from('user_profiles')
        .select('*')
        .eq('role', 'trainer')
        .limit(5);

      if (userProfilesError) throw userProfilesError;

      if (!userProfiles || userProfiles.length === 0) {
        console.log('No trainers found');
        setTrendingTrainers([]);
        return;
      }

      const trainerIds = userProfiles.map(profile => profile.id);

      const { data: trainerData, error: trainerDataError } = await supabase
        .from('trainers')
        .select('*')
        .in('id', trainerIds);

      if (trainerDataError) throw trainerDataError;

      const { data: ratingsData, error: ratingsError } = await supabase
        .from('trainer_reviews')
        .select('trainer_id, rating')
        .in('trainer_id', trainerIds);

      if (ratingsError) throw ratingsError;

      const averageRatings = {};
      ratingsData.forEach(review => {
        if (!averageRatings[review.trainer_id]) {
          averageRatings[review.trainer_id] = { sum: 0, count: 0 };
        }
        averageRatings[review.trainer_id].sum += review.rating;
        averageRatings[review.trainer_id].count += 1;
      });

      const enrichedTrainers = userProfiles.map(profile => {
        const trainerInfo = trainerData.find(t => t.id === profile.id);
        const ratingInfo = averageRatings[profile.id];
        const averageRating = ratingInfo ? ratingInfo.sum / ratingInfo.count : 0;
        const reviewCount = ratingInfo ? ratingInfo.count : 0;

        return {
          id: profile.id,
          name: profile.name,
          image_url: profile.image_url,
          specialty: trainerInfo ? trainerInfo.specialties[0] : 'General Fitness',
          rating: averageRating,
          reviewCount: reviewCount
        };
      });

      console.log('Enriched trainer data:', enrichedTrainers);
      setTrendingTrainers(enrichedTrainers);
    } catch (error) {
      console.error('Error in fetchTrendingTrainers:', error);
      setError('Failed to fetch trending trainers. Please try again later.');
      setTrendingTrainers([]);
    }
  }, []);

  const fetchForYouItems = useCallback(async () => {
    try {
      // Fetch Suggested Buddies
      const { data: buddies, error: buddiesError } = await supabase
        .from('user_profiles')
        .select('*')
        .neq('id', user.id)
        .eq('role', 'buddy')
        .limit(5);

      if (buddiesError) throw buddiesError;

      // Fetch Upcoming Events
      const today = new Date().toISOString().split('T')[0];
      const { data: events, error: eventsError } = await supabase
        .from('events')
        .select(`
          *,
          host:user_profiles!host_id(id, name, image_url)
        `)
        .gte('date', today)
        .order('date', { ascending: true })
        .limit(5);

      if (eventsError) throw eventsError;

      // Fetch Featured Trainers
      const { data: trainers, error: trainersError } = await supabase
        .from('user_profiles')
        .select('*')
        .eq('role', 'trainer')
        .limit(5);

      if (trainersError) throw trainersError;

      // Combine and randomize the items
      const allItems = [
        ...buddies.map(buddy => ({ type: 'buddy', data: buddy })),
        ...events.map(event => ({ type: 'event', data: event })),
        ...trainers.map(trainer => ({ type: 'trainer', data: trainer }))
      ];

      // Shuffle the array
      const shuffledItems = allItems.sort(() => 0.5 - Math.random());

      // Take the first 5 items
      setForYouItems(shuffledItems.slice(0, 5));

    } catch (error) {
      console.error('Error fetching For You items:', error);
      setError('Failed to fetch personalized recommendations. Please try again later.');
      setForYouItems([]);
    }
  }, [user.id]);

  const fetchUserLocation = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('user_profiles')
        .select('latitude, longitude')
        .eq('id', user.id)
        .single();
  
      if (error) throw error;
  
      if (data && data.latitude && data.longitude) {
        setUserLocation({ lat: data.latitude, lng: data.longitude });
      } else {
        console.log('User location not found in profile');
        setError('User location not available. Some features may be limited.');
      }
    } catch (error) {
      console.error('Error fetching user location:', error);
      setError('Failed to fetch user location. Some features may be limited.');
    }
  }, [user.id]);

  const fetchFitnessArticles = useCallback(async () => {
    try {
      setIsLoading(true);
      // Fetch all articles from Supabase
      const { data, error } = await supabase
        .from('fitness_articles')
        .select('*')
        .order('published_at', { ascending: false });

      if (error) throw error;

      // Randomly shuffle the articles
      const shuffledArticles = data.sort(() => 0.5 - Math.random());

      // Take the first 4 articles (or less if there are fewer articles)
      setFitnessArticles(shuffledArticles.slice(0, 4).map(article => ({
        id: article.id,
        title: article.title,
        summary: article.summary,
        image: article.image_url,
        url: article.url,
        published_at: article.published_at
      })));
    } catch (error) {
      console.error('Error fetching fitness articles:', error);
      setError(prev => prev + ' Failed to fetch fitness tips.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchLocalFitnessSpots = useCallback(() => {
    if (!window.google || !window.google.maps || !window.google.maps.places || !userLocation) {
      console.error('Google Maps API not loaded or user location not available');
      return;
    }

    const service = new window.google.maps.places.PlacesService(document.createElement('div'));
    const fitnessTypes = [
      'Gym',
      'Boxing Gym',
      'Fitness Center',
      'Martial Arts',
      'Tennis',
      'Physical Fitness Program',
      'Pilates',
      'Yoga'
    ];

    const requests = fitnessTypes.map(type => ({
      location: new window.google.maps.LatLng(userLocation.lat, userLocation.lng),
      radius: '5000',
      type: ['gym'],
      keyword: type
    }));

    Promise.all(requests.map(request => 
      new Promise((resolve) => {
        service.nearbySearch(request, (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && results.length > 0) {
            resolve(results);
          } else {
            resolve([]);
          }
        });
      })
    )).then(resultsArray => {
      const allResults = resultsArray.flat();
      const uniqueResults = allResults.filter((spot, index, self) =>
        index === self.findIndex((t) => t.place_id === spot.place_id)
      );

      const sortedSpots = uniqueResults
        .sort((a, b) => (b.rating || 0) - (a.rating || 0) || (b.user_ratings_total || 0) - (a.user_ratings_total || 0))
        .slice(0, 20);

      const detailedSpots = sortedSpots.map(spot => {
        return new Promise((resolve) => {
          service.getDetails({ placeId: spot.place_id, fields: ['website', 'types'] }, (placeResult, detailStatus) => {
            if (detailStatus === window.google.maps.places.PlacesServiceStatus.OK) {
              resolve({
                name: spot.name,
                address: spot.vicinity,
                rating: spot.rating,
                reviewCount: spot.user_ratings_total,
                image: spot.photos && spot.photos[0] ? spot.photos[0].getUrl({ maxWidth: 400, maxHeight: 400 }) : getPlaceholderImage('gym'),
                website: placeResult.website,
                mapsUrl: `https://www.google.com/maps/place/?q=place_id:${spot.place_id}`,
                types: placeResult.types
              });
            } else {
              resolve({
                name: spot.name,
                address: spot.vicinity,
                rating: spot.rating,
                reviewCount: spot.user_ratings_total,
                image: spot.photos && spot.photos[0] ? spot.photos[0].getUrl({ maxWidth: 400, maxHeight: 400 }) : getPlaceholderImage('gym'),
                mapsUrl: `https://www.google.com/maps/place/?q=place_id:${spot.place_id}`,
                types: spot.types
              });
            }
          });
        });
      });

      Promise.all(detailedSpots).then(spots => {
        setLocalSpots(spots);
      });
    }).catch(error => {
      console.error('Error fetching local fitness spots:', error);
      setError('Failed to fetch local fitness spots. Please try again later.');
    });
  }, [userLocation]);

  const fetchLocalServices = useCallback(() => {
    if (!window.google || !window.google.maps || !window.google.maps.places || !userLocation) {
      console.error('Google Maps API not loaded or user location not available');
      return;
    }

    const service = new window.google.maps.places.PlacesService(document.createElement('div'));
    const serviceTypes = [
      'nutritionist',
      'sports massage therapy',
      'personal trainer',
      'physical fitness program',
      'osteopath fitness'
    ];
    
    const requests = serviceTypes.map(type => ({
      location: new window.google.maps.LatLng(userLocation.lat, userLocation.lng),
      radius: '10000',
      type: ['health'],
      keyword: type
    }));

    Promise.all(requests.map(request => 
      new Promise((resolve) => {
        service.nearbySearch(request, (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && results.length > 0) {
            resolve(results.map(result => ({ type: request.keyword, result })));
          } else {
            resolve([]);
          }
        });
      })
    )).then(resultsArray => {
      const allResults = resultsArray.flat();
      const uniqueResults = allResults.filter((item, index, self) =>
        index === self.findIndex((t) => t.result.place_id === item.result.place_id)
      );

      const sortedServices = uniqueResults
        .sort((a, b) => (b.result.rating || 0) - (a.result.rating || 0) || (b.result.user_ratings_total || 0) - (a.result.user_ratings_total || 0))
        .slice(0, 20);

      const detailedServices = sortedServices.map(item => {
        return new Promise((resolve) => {
          service.getDetails({ placeId: item.result.place_id, fields: ['website'] }, (placeResult, detailStatus) => {
            if (detailStatus === window.google.maps.places.PlacesServiceStatus.OK) {
              resolve({
                name: item.result.name,
                type: item.type,
                address: item.result.vicinity,
                rating: item.result.rating,
                reviewCount: item.result.user_ratings_total,
                image: item.result.photos && item.result.photos[0] ? item.result.photos[0].getUrl({ maxWidth: 400, maxHeight: 400 }) : getPlaceholderImage('service'),
                website: placeResult.website,
                mapsUrl: `https://www.google.com/maps/place/?q=place_id:${item.result.place_id}`
              });
            } else {
              resolve({
                name: item.result.name,
                type: item.type,
                address: item.result.vicinity,
                rating: item.result.rating,
                reviewCount: item.result.user_ratings_total,
                image: item.result.photos && item.result.photos[0] ? item.result.photos[0].getUrl({ maxWidth: 400, maxHeight: 400 }) : getPlaceholderImage('service'),
                mapsUrl: `https://www.google.com/maps/place/?q=place_id:${item.result.place_id}`
              });
            }
          });
        });
      });

      Promise.all(detailedServices).then(services => {
        setLocalServices(services);
      });
    });
  }, [userLocation]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([
          fetchTrendingTrainers(),
          fetchForYouItems(),
          fetchUserLocation(),
          fetchFitnessArticles(),
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load some data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [fetchTrendingTrainers, fetchForYouItems, fetchUserLocation, fetchFitnessArticles]);

  useEffect(() => {
    if (!window.google) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => setGoogleMapsLoaded(true);
      document.head.appendChild(script);
    } else {
      setGoogleMapsLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (googleMapsLoaded && userLocation) {
      fetchLocalFitnessSpots();
      fetchLocalServices();
    }
  }, [googleMapsLoaded, userLocation, fetchLocalFitnessSpots, fetchLocalServices]);

  const handleBackToDashboard = () => {
    navigate('/dashboard');
  };

  const SectionTitle = memo(({ title }) => (
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-xl font-semibold">{title}</h2>
    </div>
  ));

  const getPlaceholderImage = (type) => {
    switch (type) {
      case 'buddy':
        return placeholderBuddy;
      case 'event':
        return placeholderEvent;
      case 'trainer':
        return placeholderTrainer;
      case 'gym':
        return placeholderGym;
      default:
        return placeholderFitness;
    }
  };

  const ImageWithFallback = memo(({ src, alt, className }) => (
    <img
      src={src}
      alt={alt}
      className={className}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = `https://placehold.co/300x200/png?text=${encodeURIComponent(alt)}`;
      }}
    />
  ));

  const getRandomSpots = useCallback((spots, count) => {
    const categories = ['Gym', 'Boxing', 'Fitness Center', 'Martial Arts', 'Pilates', 'Yoga', 'Tennis', 'Physical Fitness Program'];
    let selectedSpots = [];
    let usedCategories = new Set();

    // Shuffle the spots array
    const shuffledSpots = [...spots].sort(() => 0.5 - Math.random());

    // First pass: try to select one spot from each category
    for (const spot of shuffledSpots) {
      const spotCategory = categories.find(category => 
        spot.name.toLowerCase().includes(category.toLowerCase()) ||
        (spot.types && spot.types.some(type => type.toLowerCase().includes(category.toLowerCase())))
      );

      if (spotCategory && !usedCategories.has(spotCategory)) {
        selectedSpots.push(spot);
        usedCategories.add(spotCategory);
        if (selectedSpots.length >= count) break;
      }
    }

    // Second pass: fill remaining slots with any unused spots
    if (selectedSpots.length < count) {
      for (const spot of shuffledSpots) {
        if (!selectedSpots.includes(spot)) {
          selectedSpots.push(spot);
          if (selectedSpots.length >= count) break;
        }
      }
    }

    // If we still don't have enough spots, pad with null values
    while (selectedSpots.length < count) {
      selectedSpots.push(null);
    }

    return selectedSpots;
  }, []);

  const displayedSpots = useMemo(() => getRandomSpots(localSpots, 4), [localSpots, getRandomSpots]);

  const getRandomServices = useCallback((services, count) => {
    const serviceTypes = ['nutritionist', 'sports massage therapy', 'personal trainer', 'physical fitness program', 'osteopath fitness'];
    let selectedServices = [];
    let usedTypes = new Set();

    // Shuffle the services array
    const shuffledServices = [...services].sort(() => 0.5 - Math.random());

    // First pass: select one service from each type
    for (const type of serviceTypes) {
      const serviceOfType = shuffledServices.find(service => service.type === type && !usedTypes.has(service.type));
      if (serviceOfType) {
        selectedServices.push(serviceOfType);
        usedTypes.add(serviceOfType.type);
      }
    }

    // Second pass: fill remaining slots with any unused services
    for (const service of shuffledServices) {
      if (!usedTypes.has(service.type) && selectedServices.length < count) {
        selectedServices.push(service);
        usedTypes.add(service.type);
      }
      if (selectedServices.length >= count) break;
    }

    // If we still don't have enough services, add more even if types are repeated
    while (selectedServices.length < count && shuffledServices.length > selectedServices.length) {
      const remainingServices = shuffledServices.filter(service => !selectedServices.includes(service));
      if (remainingServices.length > 0) {
        selectedServices.push(remainingServices[0]);
      } else {
        break;
      }
    }

    return selectedServices;
  }, []);

  const displayedServices = useMemo(() => getRandomServices(localServices, 4), [localServices, getRandomServices]);

  const getItemLink = (item) => {
    switch (item.type) {
      case 'buddy':
        return `/buddy/${item.data.id}`;
      case 'event':
        return `/event/${item.data.id}`;
      case 'trainer':
        return `/trainer/${item.data.id}`;
      default:
        return '#';
    }
  };

  const getItemColor = (type) => {
    switch (type) {
      case 'buddy':
        return 'bg-blue-100 text-blue-800';
      case 'event':
        return 'bg-green-100 text-green-800';
      case 'trainer':
        return 'bg-purple-100 text-purple-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const formatEventDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { weekday: 'long', day: 'numeric', month: 'short' });
  };

  const formatEventTime = (timeString) => {
    return timeString.slice(0, 5); // This will return the time without seconds
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="max-w-4xl mx-auto p-4 pb-20 sm:pb-4 bg-gray-100">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Explore</h1>
        <button 
          onClick={handleBackToDashboard} 
          className="text-orange-500 font-medium flex items-center"
          aria-label="Back to Dashboard"
        >
          <ArrowLeft size={20} className="mr-1" /> Back to Dashboard
        </button>
      </div>

      {error && <div className="text-red-500 mb-4">{error}</div>}

      <section className="mb-8">
        <SectionTitle title="For You" />
        <div className="flex overflow-x-auto pb-4 -mx-4 px-4 space-x-4">
          {forYouItems.map((item, index) => (
            <Link 
              key={index} 
              to={getItemLink(item)}
              className="flex-shrink-0 w-72 bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1"
            >
              <div className="relative h-40 overflow-hidden">
                <ImageWithFallback
                  src={
                    item.type === 'event' 
                      ? item.data.image_url
                      : item.data.image_url
                        ? `${supabase.storage.from('profile_images').getPublicUrl(item.data.image_url).data.publicUrl}`
                        : getPlaceholderImage(item.type)
                  }
                  alt={item.data.name}
                  className="w-full h-full object-cover object-center"
                />
              </div>
              <div className="p-4">
                <div className={`inline-block px-2 py-1 rounded-full text-xs font-semibold mb-2 ${getItemColor(item.type)}`}>
                  {item.type.charAt(0).toUpperCase() + item.type.slice(1)}
                </div>
                <h3 className="font-semibold text-lg mb-2 text-gray-800">{item.data.name}</h3>
                {item.type === 'buddy' && (
                  <>
                    <p className="text-sm text-gray-500 flex items-center mb-2">
                      <MapPin size={14} className="mr-1" /> {item.data.location || 'Location not specified'}
                    </p>
                    <p className="text-sm mb-2">
                      <span className="font-bold text-gray-700">
                        {item.data.fitness_level} Fitness Level
                      </span>
                    </p>
                    <p className="text-sm text-gray-600">
                      Interests: 
                      {item.data.interests.slice(0, 2).map((interest, index) => (
                        <span key={index} className="inline-block bg-green-100 text-green-800 px-2 py-1 rounded-full text-xs mr-1 mb-1">
                          {interest}
                        </span>
                      ))}
                    </p>
                  </>
                )}
                {item.type === 'event' && (
                  <>
                    <div className="flex items-center text-sm text-gray-600 mb-1">
                      <Calendar size={14} className="mr-1" />
                      <span>{formatEventDate(item.data.date)}</span>
                    </div>
                    <div className="flex items-center text-sm text-gray-600 mb-1">
                      <Clock size={14} className="mr-1" />
                      <span>{formatEventTime(item.data.time)}</span>
                    </div>
                    <p className="text-sm text-gray-500 flex items-center">
                      <MapPin size={14} className="mr-1" /> {item.data.location || 'Location not specified'}
                    </p>
                  </>
                )}
                {item.type === 'trainer' && (
                  <>
                    <p className="text-sm text-gray-500 flex items-center mb-1">
                      <MapPin size={14} className="mr-1" /> {item.data.location || 'Location not specified'}
                    </p>
                    {item.data.specialties && item.data.specialties.length > 0 && (
                      <p className="text-sm text-gray-500 mb-1">Specialty: {item.data.specialties[0]}</p>
                    )}
                    {item.data.tagline && (
                      <p className="text-sm text-gray-600 italic mb-1">"{item.data.tagline}"</p>
                    )}
                    <div className="flex items-center">
                      <Star className="text-yellow-400 mr-1" size={16} />
                      <span className="text-sm font-medium">{item.data.rating ? item.data.rating.toFixed(1) : 'N/A'}</span>
                      <span className="text-xs text-gray-500 ml-1">
                        ({item.data.review_count || 0} reviews)
                      </span>
                    </div>
                  </>
                )}
              </div>
            </Link>
          ))}
        </div>
      </section>

      <section className="mb-8">
        <SectionTitle title="Trending Trainers" />
        <div className="flex overflow-x-auto pb-4 -mx-4 px-4 space-x-4">
          {trendingTrainers.map((trainer, index) => (
            <Link
              key={index}
              to={`/trainer/${trainer.id}`}
              className="flex-shrink-0 w-64 bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1"
            >
              <div className="relative h-40 overflow-hidden">
                <ImageWithFallback
                  src={trainer.image_url ? `${supabase.storage.from('profile_images').getPublicUrl(trainer.image_url).data.publicUrl}` : placeholderTrainer}
                  alt={trainer.name}
                  className="w-full h-full object-cover object-center"
                />
              </div>
              <div className="p-4">
                <h3 className="font-semibold text-lg mb-1">{trainer.name}</h3>
                <p className="text-sm text-gray-600 mb-2">{trainer.specialty}</p>
                <div className="flex items-center">
                  <Star className="text-yellow-400 mr-1" size={16} />
                  <span className="text-sm font-medium">{trainer.rating.toFixed(1)}</span>
                  <span className="text-xs text-gray-500 ml-1">
                    ({trainer.reviewCount} reviews)
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </section>

      <section className="mb-8">
        <SectionTitle title="Local Fitness Spots" />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {displayedSpots.map((spot, index) => (
            spot ? (
              <a 
                key={index} 
                href={spot.website || spot.mapsUrl} 
                target="_blank" 
                rel="noopener noreferrer"
                className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1"
              >
                <div className="relative h-32 overflow-hidden">
                <ImageWithFallback
                    src={spot.image}
                    alt={spot.name}
                    className="w-full h-full object-cover object-center"
                  />
                </div>
                <div className="p-4">
                  <h3 className="font-semibold text-lg mb-1 flex items-center">
                    {spot.name}
                    <ExternalLink size={16} className="ml-2 text-gray-400" />
                  </h3>
                  <p className="text-sm text-gray-600 flex items-center mb-2">
                    <MapPin size={14} className="mr-1" /> {spot.address}
                  </p>
                  <div className="flex items-center">
                    <Star className="text-yellow-400 mr-1" size={16} />
                    <span className="text-sm font-medium">{spot.rating ? spot.rating.toFixed(1) : 'N/A'}</span>
                    <span className="text-xs text-gray-500 ml-1">
                      ({spot.reviewCount || 0} reviews)
                    </span>
                  </div>
                </div>
              </a>
            ) : (
              <div key={index} className="bg-gray-100 rounded-lg shadow-md overflow-hidden">
                <p className="p-4 text-center text-gray-500">No fitness spot available</p>
              </div>
            )
          ))}
        </div>
      </section>

      <section className="mb-8">
        <SectionTitle title="Fitness Tips" />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {fitnessArticles.map((article) => (
            <a 
              key={article.id}
              href={article.url}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1"
            >
              <div className="relative h-40 overflow-hidden">
                <ImageWithFallback
                  src={article.image || placeholderFitness}
                  alt={article.title}
                  className="w-full h-full object-cover object-center"
                />
              </div>
              <div className="p-4">
                <h3 className="font-semibold text-lg mb-2">{article.title}</h3>
                <p className="text-sm text-gray-600 mb-2">{article.summary}</p>
                <div className="flex justify-between items-center text-sm text-gray-500">
                  <span className="flex items-center">
                    <Clock size={14} className="mr-1" />
                    {new Date(article.published_at).toLocaleDateString()}
                  </span>
                  <span className="flex items-center">
                    Read More
                    <ExternalLink size={14} className="ml-1" />
                  </span>
                </div>
              </div>
            </a>
          ))}
        </div>
      </section>

      <section className="mb-8">
        <SectionTitle title="Local Fitness Services" />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {displayedServices.map((service, index) => (
            service ? (
              <a 
                key={index} 
                href={service.website || service.mapsUrl} 
                target="_blank" 
                rel="noopener noreferrer"
                className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1"
              >
                <div className="relative h-40 overflow-hidden">
                  <ImageWithFallback
                    src={service.image}
                    alt={service.name}
                    className="w-full h-full object-cover object-center"
                  />
                </div>
                <div className="p-4">
                  <h3 className="font-semibold text-lg mb-1 flex items-center">
                    {service.name}
                    <ExternalLink size={16} className="ml-2 text-gray-400" />
                  </h3>
                  <p className="text-sm text-gray-600 mb-2">
                    {service.type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                  </p>
                  <p className="text-sm text-gray-500 flex items-center mb-2">
                    <MapPin size={14} className="mr-1" /> {service.address}
                  </p>
                  <div className="flex items-center">
                    <Star className="text-yellow-400 mr-1" size={16} />
                    <span className="text-sm font-medium">{service.rating ? service.rating.toFixed(1) : 'N/A'}</span>
                    <span className="text-xs text-gray-500 ml-1">
                      ({service.reviewCount || 0} reviews)
                    </span>
                  </div>
                </div>
              </a>
            ) : (
              <div key={index} className="bg-gray-100 rounded-lg shadow-md overflow-hidden">
                <p className="p-4 text-center text-gray-500">No fitness service available</p>
              </div>
            )
          ))}
        </div>
        {displayedServices.length === 0 && (
          <p className="text-center text-gray-500 mt-4">No fitness services available in your area.</p>
        )}
      </section>
      <div className="md:hidden">
        <Navigation setActiveTab={setActiveTab} />
      </div>
    </div>
  );
});

export default ExplorePage;