import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Calendar as CalendarIcon, Filter, MapPin, Clock, Users, Search, Plus, ChevronLeft, ChevronRight } from 'lucide-react';
import { supabase } from '../utils/supabase';
import { format, startOfWeek, endOfWeek, eachDayOfInterval, isSameDay, addWeeks, subWeeks, isWithinInterval } from 'date-fns';
import Navigation from './Navigation'; 
const eventTypes = [
  'Running', 'Yoga', 'HIIT', 'Cycling', 'Meditation', 'Walking', 'Gym', 'Outdoor',
  'Crossfit', 'Beach Tennis', 'Tennis', 'Volleyball', 'Swimming', 'Pilates', 'Zumba',
  'Boxing', 'Martial Arts', 'Dance', 'Basketball', 'Soccer'
];

const EventsPage = () => {
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({ type: '', isFree: null });
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [daysWithEvents, setDaysWithEvents] = useState([]);
  const [activeTab, setActiveTab] = useState('events');
  

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    filterEvents();
    updateDaysWithEvents();
  }, [filters, events, searchTerm, selectedDate]);

  useEffect(() => {
    updateDaysWithEvents();
  }, [events, currentDate]);

  const fetchEvents = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('events')
        .select('*')
        .order('date', { ascending: true });

      if (error) throw error;
      setEvents(data.map(event => ({
        ...event,
        start: new Date(`${event.date}T${event.time}`),
      })));
    } catch (error) {
      console.error('Error fetching events:', error);
      alert('Failed to fetch events. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const filterEvents = () => {
    const filtered = events.filter(event => {
      const eventDate = new Date(event.date);
      const dateMatch = isSameDay(eventDate, selectedDate);
      const typeMatch = !filters.type || event.type === filters.type;
      const freeMatch = filters.isFree === null || event.is_free === filters.isFree;
      const searchMatch = event.name.toLowerCase().includes(searchTerm.toLowerCase());

      return dateMatch && typeMatch && freeMatch && searchMatch;
    });

    setFilteredEvents(filtered);
  };

  const updateDaysWithEvents = () => {
    const start = startOfWeek(currentDate);
    const end = endOfWeek(currentDate);
    const daysWithEventsInWeek = events.filter(event => 
      isWithinInterval(new Date(event.date), { start, end })
    ).map(event => new Date(event.date));
    
    setDaysWithEvents(daysWithEventsInWeek);
  };


  const handleFilterChange = (key, value) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  const weekDays = eachDayOfInterval({
    start: startOfWeek(currentDate),
    end: endOfWeek(currentDate)
  });

  const nextWeek = () => setCurrentDate(addWeeks(currentDate, 1));
  const prevWeek = () => setCurrentDate(subWeeks(currentDate, 1));

  const selectDate = (date) => {
    setSelectedDate(date);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Events</h1>
        <button onClick={() => navigate('/dashboard')} className="text-orange-500 font-medium">
          Back to Dashboard
        </button>
      </div>

      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-4 sm:p-6">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 space-y-4 sm:space-y-0">
            <div className="flex items-center space-x-4">
              <button onClick={prevWeek} className="p-2 rounded-full hover:bg-gray-100">
                <ChevronLeft size={24} />
              </button>
              <span className="text-lg font-semibold">
                {format(weekDays[0], 'MMM d')} - {format(weekDays[6], 'MMM d, yyyy')}
              </span>
              <button onClick={nextWeek} className="p-2 rounded-full hover:bg-gray-100">
                <ChevronRight size={24} />
              </button>
            </div>
          </div>

          <div className="space-y-4 sm:space-y-0 sm:flex sm:items-center sm:space-x-4">
            <div className="relative flex-grow">
              <input
                type="text"
                placeholder="Search events..."
                className="w-full p-2 pl-10 border rounded-lg"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
            </div>
            <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
              <button
                onClick={() => setShowFilters(!showFilters)}
                className="w-full sm:w-auto flex items-center justify-center px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
              >
                <Filter size={20} className="mr-2" />
                Filters
              </button>
              <Link to="/create-event" className="w-full sm:w-auto bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition-colors flex items-center justify-center">
                <Plus size={20} className="mr-2" />
                Create Event
              </Link>
            </div>
          </div>

          {showFilters && (
            <div className="mt-4 p-4 bg-gray-100 rounded-lg">
              <div className="flex flex-col space-y-2">
                <select
                  value={filters.type}
                  onChange={(e) => handleFilterChange('type', e.target.value)}
                  className="p-2 border rounded-lg"
                >
                  <option value="">All Types</option>
                  {eventTypes.map(type => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>
                <select
                  value={filters.isFree === null ? '' : filters.isFree.toString()}
                  onChange={(e) => handleFilterChange('isFree', e.target.value === '' ? null : e.target.value === 'true')}
                  className="p-2 border rounded-lg"
                >
                  <option value="">All Events</option>
                  <option value="true">Free Events</option>
                  <option value="false">Paid Events</option>
                </select>
              </div>
            </div>
          )}

          <div className="grid grid-cols-7 gap-2 mt-6 mb-4">
            {weekDays.map((day, index) => {
              const hasEvent = daysWithEvents.some(eventDay => isSameDay(eventDay, day));
              const isSelected = isSameDay(day, selectedDate);
              
              return (
                <div 
                  key={index} 
                  className={`text-center cursor-pointer p-2 rounded-lg ${
                    isSelected
                      ? 'bg-orange-500 text-white' 
                      : hasEvent
                        ? 'bg-orange-200 hover:bg-orange-300'
                        : 'hover:bg-orange-100'
                  }`}
                  onClick={() => selectDate(day)}
                >
                  <div className={`font-semibold ${isSelected ? 'text-white' : hasEvent ? 'text-orange-800' : ''}`}>
                    {format(day, 'EEE')}
                  </div>
                  <div className={`mt-1 text-xl ${isSelected ? 'text-white' : hasEvent ? 'text-orange-800' : ''}`}>
                    {format(day, 'd')}
                  </div>
                </div>
              );
            })}
          </div>

          {loading ? (
            <p className="text-gray-500">Loading events...</p>
          ) : filteredEvents.length === 0 ? (
            <p className="text-gray-500">No events found for this day.</p>
          ) : (
            <div className="space-y-4">
              {filteredEvents.map(event => (
                <div key={event.id} className={`flex flex-col bg-white rounded-lg shadow-sm overflow-hidden ${event.status === 'canceled' ? 'opacity-60' : ''}`}>
                  <div className="flex items-center p-4 bg-gray-50 relative">
                    {event.status === 'canceled' && (
                      <div className="absolute top-0 right-0 bg-red-500 text-white px-2 py-1 text-xs font-bold uppercase">
                        Canceled
                      </div>
                    )}
                    <div className="flex-shrink-0 w-16 text-center mr-4">
                      <div className="text-3xl font-bold">{format(new Date(event.date), 'dd')}</div>
                      <div className="text-gray-500">{format(new Date(event.date), 'MMM')}</div>
                    </div>
                    <div className="flex-grow">
                      <h3 className="text-lg font-semibold">{event.name}</h3>
                      <div className="flex items-center text-sm text-gray-600 mt-1">
                        <Clock size={14} className="mr-1" /> {format(new Date(`${event.date}T${event.time}`), 'h:mm a')}
                      </div>
                    </div>
                  </div>
                  <div className="p-4">
                    <div className="flex items-center text-sm text-gray-600 mb-2">
                      <MapPin size={14} className="mr-1" /> {event.location}
                    </div>
                    <div className="flex items-center text-sm text-gray-600 mb-2">
                      <Users size={14} className="mr-1" /> {event.max_participants} max participants
                    </div>
                    <div className="flex justify-between items-center mt-2">
                      <span className={`px-2 py-1 rounded-full text-xs font-medium ${event.is_free ? 'bg-green-100 text-green-800' : 'bg-blue-100 text-blue-800'}`}>
                        {event.is_free ? 'Free' : `$${event.price}`}
                      </span>
                      <button 
                        onClick={() => navigate(`/event/${event.id}`)}
                        className={`px-4 py-2 text-white text-sm rounded-lg transition-colors ${
                          event.status === 'canceled' 
                            ? 'bg-gray-400 cursor-not-allowed' 
                            : 'bg-orange-500 hover:bg-orange-600'
                        }`}
                        disabled={event.status === 'canceled'}
                      >
                        {event.status === 'canceled' ? 'Canceled' : 'View Details'}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="md:hidden">
        <Navigation setActiveTab={setActiveTab} />
      </div>
    </div>
  );
};

export default EventsPage;