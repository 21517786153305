import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

const AddPaymentMethod = ({ onClose, onAddPaymentMethod, onEditPaymentMethod, editingMethod }) => {
  const [type, setType] = useState(editingMethod ? editingMethod.type : 'credit');
  const [cardNumber, setCardNumber] = useState(editingMethod ? `**** **** **** ${editingMethod.last_four}` : '');
  const [expiryMonth, setExpiryMonth] = useState(editingMethod ? editingMethod.expiry_month : '');
  const [expiryYear, setExpiryYear] = useState(editingMethod ? editingMethod.expiry_year : '');
  const [paymentProcessor, setPaymentProcessor] = useState(editingMethod ? editingMethod.payment_processor : 'stripe');
  const [cardBrand, setCardBrand] = useState(editingMethod ? editingMethod.card_brand : '');

  useEffect(() => {
    if (editingMethod) {
      setType(editingMethod.type);
      setCardNumber(`**** **** **** ${editingMethod.last_four}`);
      setExpiryMonth(editingMethod.expiry_month);
      setExpiryYear(editingMethod.expiry_year);
      setPaymentProcessor(editingMethod.payment_processor);
      setCardBrand(editingMethod.card_brand);
    }
  }, [editingMethod]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const methodData = {
      type,
      cardNumber,
      expiryMonth,
      expiryYear,
      paymentProcessor,
      cardBrand,
    };
    if (editingMethod) {
      onEditPaymentMethod({ ...methodData, id: editingMethod.id });
    } else {
      onAddPaymentMethod(methodData);
    }
  };

  const detectCardBrand = (number) => {
    const patterns = {
      visa: /^4/,
      mastercard: /^5[1-5]/,
      amex: /^3[47]/,
      discover: /^6(?:011|5)/,
      dinersclub: /^3(?:0[0-5]|[68])/,
      jcb: /^(?:2131|1800|35)/
    };

    for (const [brand, pattern] of Object.entries(patterns)) {
      if (pattern.test(number)) {
        return brand;
      }
    }
    return 'unknown';
  };

  const handleCardNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setCardNumber(value);
    setCardBrand(detectCardBrand(value));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">{editingMethod ? 'Edit' : 'Add'} Payment Method</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="type">
              Card Type
            </label>
            <select
              id="type"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
            >
              <option value="credit">Credit</option>
              <option value="debit">Debit</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cardNumber">
              Card Number
            </label>
            <input
              id="cardNumber"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="1234 5678 9012 3456"
              value={cardNumber}
              onChange={handleCardNumberChange}
              maxLength="16"
              required
            />
          </div>
          <div className="mb-4 flex">
            <div className="w-1/2 mr-2">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="expiryMonth">
                Expiry Month
              </label>
              <input
                id="expiryMonth"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="MM"
                value={expiryMonth}
                onChange={(e) => setExpiryMonth(e.target.value)}
                maxLength="2"
                required
              />
            </div>
            <div className="w-1/2 ml-2">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="expiryYear">
                Expiry Year
              </label>
              <input
                id="expiryYear"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="YYYY"
                value={expiryYear}
                onChange={(e) => setExpiryYear(e.target.value)}
                maxLength="4"
                required
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="paymentProcessor">
              Payment Processor
            </label>
            <select
              id="paymentProcessor"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={paymentProcessor}
              onChange={(e) => setPaymentProcessor(e.target.value)}
              required
            >
              <option value="stripe">Stripe</option>
              <option value="paypal">PayPal</option>
              <option value="square">Square</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Detected Card Brand: {cardBrand}
            </label>
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              {editingMethod ? 'Update' : 'Add'} Payment Method
            </button>
            <button
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPaymentMethod;