import React from 'react';
import { X } from 'lucide-react';

const VideoPlayer = ({ videoId, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
      <div className="w-full max-w-4xl bg-white rounded-lg overflow-hidden shadow-xl">
        <div className="relative pt-[56.25%]">
          <iframe
            className="absolute top-0 left-0 w-full h-full"
            src={`https://www.youtube.com/embed/${videoId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="p-4 bg-gray-100 flex justify-end">
          <button
            onClick={onClose}
            className="bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition-colors duration-200 flex items-center"
          >
            <X size={24} />
            <span className="ml-2">Close</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;