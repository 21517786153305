import React, { useState, useEffect } from 'react';
import { supabase } from '../utils/supabase';
import { User, ChevronDown, ChevronUp, Shield, UserMinus } from 'lucide-react';

const GroupMembers = ({ groupId, refreshTrigger }) => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    fetchMembers();
  }, [groupId, refreshTrigger]);

  const fetchMembers = async () => {
    try {
      setLoading(true);
      
      // Fetch the current user
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;

      // Fetch group members
      const { data: groupMembersData, error: groupMembersError } = await supabase
        .from('group_members')
        .select('user_id, role, joined_at')
        .eq('group_id', groupId);

      if (groupMembersError) throw groupMembersError;

      // Fetch user profiles for those members
      const userIds = groupMembersData.map(member => member.user_id);
      const { data: userProfilesData, error: userProfilesError } = await supabase
        .from('user_profiles')
        .select('id, name, image_url, fitness_level')
        .in('id', userIds);

      if (userProfilesError) throw userProfilesError;

      // Combine the data
      const combinedData = groupMembersData.map(member => {
        const userProfile = userProfilesData.find(profile => profile.id === member.user_id);
        return {
          id: member.user_id,
          role: member.role,
          joinedAt: member.joined_at,
          name: userProfile?.name || 'Unknown',
          imageUrl: userProfile?.image_url,
          fitnessLevel: userProfile?.fitness_level
        };
      });

      setMembers(combinedData);

      // Set the current user
      const currentUserMember = combinedData.find(member => member.id === user.id);
      setCurrentUser(currentUserMember);

      console.log('Fetched members:', combinedData);
      console.log('Current user:', currentUserMember);

    } catch (error) {
      console.error('Error fetching group members:', error);
      setError('Failed to load members. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const sortedMembers = [...members].sort((a, b) => {
    if (sortBy === 'name') {
      return sortOrder === 'asc' 
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    } else if (sortBy === 'role') {
      return sortOrder === 'asc'
        ? a.role.localeCompare(b.role)
        : b.role.localeCompare(a.role);
    } else if (sortBy === 'joinedAt') {
      return sortOrder === 'asc'
        ? new Date(a.joinedAt) - new Date(b.joinedAt)
        : new Date(b.joinedAt) - new Date(a.joinedAt);
    }
    return 0;
  });

  const promoteToAdmin = async (userId) => {
    try {
      const { error } = await supabase
        .from('group_members')
        .update({ role: 'admin' })
        .eq('group_id', groupId)
        .eq('user_id', userId);

      if (error) throw error;

      // Refresh the members list
      fetchMembers();
    } catch (error) {
      console.error('Error promoting user to admin:', error);
      setError('Failed to promote user. Please try again later.');
    }
  };

  const demoteToMember = async (userId) => {
    try {
      const { error } = await supabase
        .from('group_members')
        .update({ role: 'member' })
        .eq('group_id', groupId)
        .eq('user_id', userId);

      if (error) throw error;

      // Refresh the members list
      fetchMembers();
    } catch (error) {
      console.error('Error demoting admin to member:', error);
      setError('Failed to demote admin. Please try again later.');
    }
  };

  const isAdminOrCreator = currentUser?.role === 'admin' || currentUser?.role === 'creator';

  console.log('Is admin or creator:', isAdminOrCreator);

  if (loading) return <div className="text-center py-4">Loading members...</div>;
  if (error) return <div className="text-center text-red-500 py-4">{error}</div>;

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Group Members</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Details and roles of group members.</p>
      </div>
      <div className="border-t border-gray-200">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Member
              </th>
              <th 
                scope="col" 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort('name')}
              >
                <div className="flex items-center">
                  Name
                  {sortBy === 'name' && (
                    sortOrder === 'asc' ? <ChevronUp size={16} /> : <ChevronDown size={16} />
                  )}
                </div>
              </th>
              <th 
                scope="col" 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort('role')}
              >
                <div className="flex items-center">
                  Role
                  {sortBy === 'role' && (
                    sortOrder === 'asc' ? <ChevronUp size={16} /> : <ChevronDown size={16} />
                  )}
                </div>
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Fitness Level
              </th>
              <th 
                scope="col" 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort('joinedAt')}
              >
                <div className="flex items-center">
                  Joined At
                  {sortBy === 'joinedAt' && (
                    sortOrder === 'asc' ? <ChevronUp size={16} /> : <ChevronDown size={16} />
                  )}
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {sortedMembers.map((member) => (
              <tr key={member.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      {member.imageUrl ? (
                        <img 
                          className="h-10 w-10 rounded-full object-cover" 
                          src={`${supabase.storage.from('profile_images').getPublicUrl(member.imageUrl).data.publicUrl}`} 
                          alt={member.name} 
                        />
                      ) : (
                        <div className="h-10 w-10 rounded-full bg-gray-300 flex items-center justify-center">
                          <User size={20} className="text-gray-500" />
                        </div>
                      )}
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">{member.name}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    member.role === 'admin' ? 'bg-green-100 text-green-800' : 'bg-blue-100 text-blue-800'
                  }`}>
                    {member.role}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {member.fitnessLevel || 'N/A'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {new Date(member.joinedAt).toLocaleDateString()}
                </td>
                {isAdminOrCreator && (
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    {member.role !== 'admin' && member.id !== currentUser?.id && (
                      <button
                        onClick={() => promoteToAdmin(member.id)}
                        className="text-indigo-600 hover:text-indigo-900 flex items-center"
                      >
                        <Shield size={20} />
                        <span className="ml-1">Promote to Admin</span>
                      </button>
                    )}
                    {member.role === 'admin' && member.id !== currentUser?.id && (
                      <button
                        onClick={() => demoteToMember(member.id)}
                        className="text-red-600 hover:text-red-900 flex items-center"
                      >
                        <UserMinus size={20} />
                        <span className="ml-1">Demote to Member</span>
                      </button>
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GroupMembers;