import React, { useState, useEffect } from 'react';
import { Check, Edit, RotateCcw, ChevronLeft, ChevronRight, ArrowLeft, Calendar, Save } from 'lucide-react';
import { Link } from 'react-router-dom';
import { supabase } from '../utils/supabase'; // Ensure this path is correct
import DatePicker from 'react-datepicker'; // You'll need to install this package
import 'react-datepicker/dist/react-datepicker.css';

const DailyCaloriesTracker = ({ currentUser }) => {
  const [currentWeek, setCurrentWeek] = useState(1);
  const [weekData, setWeekData] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [dateRange, setDateRange] = useState('');
  const [goals, setGoals] = useState({
    calories: 2000,
    proteins: 150,
    carbs: 225,
    fats: 56
  });
  const [isEditingGoals, setIsEditingGoals] = useState(false);
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  useEffect(() => {
    fetchUserData();
  }, [currentUser]);

  useEffect(() => {
    initializeWeekData(currentWeek);
    updateDateRange(currentWeek);
  }, [currentWeek, startDate]);

  const fetchUserData = async () => {
    if (currentUser && currentUser.id) {
      const { data, error } = await supabase
        .from('calorie_tracker')
        .select('*')
        .eq('user_id', currentUser.id)
        .single();

      if (error) {
        console.error('Error fetching user data:', error);
      } else if (data) {
        setGoals(data.goals || goals);
        setStartDate(new Date(data.start_date) || new Date());
        setWeekData(data.week_data || {});
      }
    }
  };

  const saveUserData = async () => {
    if (currentUser && currentUser.id) {
      const { error } = await supabase
        .from('calorie_tracker')
        .upsert({
          user_id: currentUser.id,
          goals: goals,
          start_date: startDate.toISOString(),
          week_data: weekData
        });

      if (error) {
        console.error('Error saving user data:', error);
      } else {
        alert('Data saved successfully!');
      }
    }
  };

  const initializeWeekData = (week) => {
    if (!weekData[week]) {
      setWeekData(prevData => ({
        ...prevData,
        [week]: days.map(day => ({
          day,
          proteins: '',
          carbs: '',
          fats: '',
          completed: false
        }))
      }));
    }
  };

  const updateDateRange = (week) => {
    const weekStart = new Date(startDate);
    weekStart.setDate(weekStart.getDate() + (week - 1) * 7);
    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekEnd.getDate() + 6);
    
    const formatDate = (date) => {
      return `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
    };

    setDateRange(`${formatDate(weekStart)} - ${formatDate(weekEnd)}, ${weekStart.getFullYear()}`);
  };

  const updateNutrient = (dayIndex, nutrient, value) => {
    setWeekData(prevData => {
      const newWeekData = [...prevData[currentWeek]];
      newWeekData[dayIndex] = {
        ...newWeekData[dayIndex],
        [nutrient]: value
      };
      return { ...prevData, [currentWeek]: newWeekData };
    });
  };

  const calculateCalories = (day) => {
    return (parseInt(day.proteins) || 0) * 4 + (parseInt(day.carbs) || 0) * 4 + (parseInt(day.fats) || 0) * 9;
  };

  const toggleComplete = (dayIndex) => {
    setWeekData(prevData => {
      const newWeekData = [...prevData[currentWeek]];
      newWeekData[dayIndex] = {
        ...newWeekData[dayIndex],
        completed: !newWeekData[dayIndex].completed
      };
      return { ...prevData, [currentWeek]: newWeekData };
    });
  };

  const enableEditing = (dayIndex) => {
    setWeekData(prevData => {
      const newWeekData = [...prevData[currentWeek]];
      newWeekData[dayIndex] = {
        ...newWeekData[dayIndex],
        completed: false
      };
      return { ...prevData, [currentWeek]: newWeekData };
    });
  };

  const resetDay = (dayIndex) => {
    setWeekData(prevData => {
      const newWeekData = [...prevData[currentWeek]];
      newWeekData[dayIndex] = {
        day: days[dayIndex],
        proteins: '',
        carbs: '',
        fats: '',
        completed: false
      };
      return { ...prevData, [currentWeek]: newWeekData };
    });
  };

  const calculateSummary = () => {
    const currentWeekData = weekData[currentWeek] || [];
    const totalCalories = currentWeekData.reduce((sum, day) => sum + calculateCalories(day), 0);
    const averageCalories = Math.round(totalCalories / 7);
    return { totalCalories, averageCalories };
  };

  const handleGoalChange = (nutrient, value) => {
    const newValue = parseInt(value) || 0;
    let newGoals = { ...goals, [nutrient]: newValue };

    if (nutrient === 'calories') {
      // Adjust macros based on calorie change
      const totalMacros = newGoals.proteins + newGoals.carbs + newGoals.fats;
      newGoals.proteins = Math.round((newGoals.proteins / totalMacros) * newValue / 4);
      newGoals.carbs = Math.round((newGoals.carbs / totalMacros) * newValue / 4);
      newGoals.fats = Math.round((newGoals.fats / totalMacros) * newValue / 9);
    } else {
      // Recalculate calories based on macro changes
      newGoals.calories = newGoals.proteins * 4 + newGoals.carbs * 4 + newGoals.fats * 9;
    }

    setGoals(newGoals);
  };

  const saveGoals = () => {
    setIsEditingGoals(false);
    saveUserData();
  };

  return (
    <div className="container mx-auto p-2 sm:p-4 pb-24 sm:pb-6 bg-white rounded-lg shadow-lg">
      <Link to="/dashboard" className="flex items-center text-orange-500 hover:text-orange-600 mb-4">
        <ArrowLeft size={20} className="mr-1" /> Back to Dashboard
      </Link>
      <header className="bg-gradient-to-r from-orange-400 to-orange-600 text-white p-4 rounded-t-lg">
        <h1 className="text-2xl sm:text-3xl font-bold text-center">Calorie Tracker</h1>
        <p className="text-sm text-center mt-2 text-orange-100">Monitor your daily intake</p>
      </header>
      <div className="p-2 sm:p-4">
        <div className="mb-4 sm:mb-6 bg-orange-100 p-3 sm:p-4 rounded-lg">
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-base sm:text-lg font-semibold text-orange-600">Daily Nutrition Targets</h2>
            <button 
              onClick={() => setIsEditingGoals(!isEditingGoals)}
              className="text-orange-500 hover:text-orange-600"
            >
              <Edit size={16} />
            </button>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 sm:gap-4">
            {['calories', 'proteins', 'carbs', 'fats'].map(key => (
              <div key={key} className="bg-white p-2 sm:p-3 rounded-lg text-center shadow">
                <h3 className="text-xs sm:text-sm font-medium text-orange-500">{key.charAt(0).toUpperCase() + key.slice(1)}</h3>
                {isEditingGoals ? (
                  <input
                    type="number"
                    value={goals[key]}
                    onChange={(e) => handleGoalChange(key, e.target.value)}
                    className="w-full text-center border rounded p-1"
                  />
                ) : (
                  <p className="text-lg sm:text-xl font-bold">{goals[key]}</p>
                )}
                <span className="text-xs text-orange-400">
                  {key === 'calories' ? 'kcal' : 'g'}
                </span>
              </div>
            ))}
          </div>
          {isEditingGoals && (
            <button 
              onClick={saveGoals}
              className="mt-2 bg-orange-500 text-white px-4 py-2 rounded-full text-sm"
            >
              Save Targets
            </button>
          )}
        </div>
        <div className="mb-6">
  <div className="flex flex-col items-center mb-4">
    <div className="text-center mb-2">
      <h2 className="text-xl font-semibold text-orange-600">Week {currentWeek}</h2>
      <p className="text-sm text-gray-600">{dateRange}</p>
    </div>
    <div className="flex justify-center space-x-4">
      <button 
        onClick={() => setCurrentWeek(prev => Math.max(1, prev - 1))}
        className="text-orange-500 hover:text-orange-600 transition-colors"
      >
        <ChevronLeft size={20} className="inline mr-1" /> Prev
      </button>
      <button 
        onClick={() => setCurrentWeek(prev => prev + 1)}
        className="text-orange-500 hover:text-orange-600 transition-colors"
      >
        Next <ChevronRight size={20} className="inline ml-1" />
      </button>
    </div>
  </div>
  <div className="mb-4 flex items-center justify-center">
    <Calendar size={20} className="text-orange-500 mr-2" />
    <DatePicker
      selected={startDate}
      onChange={date => {
        setStartDate(date);
        setCurrentWeek(1);
      }}
      dateFormat="MMMM d, yyyy"
      className="border rounded p-2 text-center"
    />
  </div>
</div>
        <div className="overflow-x-auto">
          <table className="w-full min-w-max">
            <thead>
              <tr className="bg-orange-500 text-white text-xs sm:text-sm">
                <th className="p-2 text-left">Day</th>
                <th className="p-2 text-left">Prot (g)</th>
                <th className="p-2 text-left">Carb (g)</th>
                <th className="p-2 text-left">Fat (g)</th>
                <th className="p-2 text-left">Cal</th>
                <th className="p-2 text-left">Var</th>
                <th className="p-2 text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {weekData[currentWeek]?.map((day, index) => {
                const calories = calculateCalories(day);
                const variance = calories - goals.calories;
                return (
                  <tr key={day.day} className="border-b hover:bg-orange-50 transition-colors text-xs sm:text-sm">
                    <td className="p-2">{day.day}</td>
                    <td className="p-2">
                      <input
                        type="number"
                        value={day.proteins}
                        onChange={(e) => updateNutrient(index, 'proteins', e.target.value)}
                        disabled={day.completed}
                        className="w-12 sm:w-16 p-1 border rounded"
                        maxLength="4"
                      />
                    </td>
                    <td className="p-2">
                      <input
                        type="number"
                        value={day.carbs}
                        onChange={(e) => updateNutrient(index, 'carbs', e.target.value)}
                        disabled={day.completed}
                        className="w-12 sm:w-16 p-1 border rounded"
                        maxLength="4"
                      />
                    </td>
                    <td className="p-2">
                      <input
                        type="number"
                        value={day.fats}
                        onChange={(e) => updateNutrient(index, 'fats', e.target.value)}
                        disabled={day.completed}
                        className="w-12 sm:w-16 p-1 border rounded"
                        maxLength="4"
                      />
                    </td>
                    <td className="p-2">{calories}</td>
                    <td className={`p-2 font-semibold ${variance > 0 ? 'text-green-500' : 'text-red-500'}`}>
                      {variance > 0 ? `+${variance}` : variance}
                    </td>
                    <td className="p-2">
                      <div className="flex space-x-1 sm:space-x-2">
                        <Check
                          size={16}
                          onClick={() => toggleComplete(index)}
                          className={`cursor-pointer ${day.completed ? 'text-green-500' : 'text-gray-400'}`}
                        />
                        <Edit
                          size={16}
                          onClick={() => enableEditing(index)}
                          className="cursor-pointer text-blue-500"
                        />
                        <RotateCcw
                          size={16}
                          onClick={() => resetDay(index)}
                          className="cursor-pointer text-red-500"
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="mt-4 sm:mt-6 bg-orange-100 p-3 sm:p-4 rounded-lg">
          <h3 className="text-base sm:text-lg font-semibold text-orange-600 mb-2">Weekly Summary</h3>
          <p className="text-xs sm:text-sm">Average Daily Calories: <span className="font-bold text-orange-500">{calculateSummary().averageCalories}</span> kcal</p>
          <p className="text-xs sm:text-sm">Total Weekly Calories: <span className="font-bold text-orange-500">{calculateSummary().totalCalories}</span> kcal</p>
        </div>
        <div className="mt-4 flex justify-end mb-20 sm:mb-0">
          <button 
            onClick={saveUserData}
            className="bg-orange-500 text-white px-4 py-2 rounded-full flex items-center text-sm"
          >
            <Save size={16} className="mr-2" /> Save Tracker Data
          </button>
        </div>
      </div>
    </div>
  );
};

export default DailyCaloriesTracker;