import React, { useState, useEffect } from 'react';
import { supabase } from '../utils/supabase';
import { 
    Plus, Search, Filter, ChevronDown, ChevronUp, 
    FileText, Image, File, Calendar, User, ArrowLeft,
    Edit, Trash, Upload, Download, FileDown, Camera,
    X, ChevronLeft, ChevronRight
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from 'xlsx';

const Assessments = () => {
  const [assessments, setAssessments] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [newAssessment, setNewAssessment] = useState({
    client_id: '',
    date: '',
    weight: '',
    body_fat: '',
    muscle_mass: '',
    notes: '',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'descending' });
  const [fileUploads, setFileUploads] = useState({});
  const [error, setError] = useState(null);
  const [expandedClients, setExpandedClients] = useState({});
  const [dateFilter, setDateFilter] = useState('all');
  const [notesModal, setNotesModal] = useState({ isOpen: false, assessment: null });
  const [photos, setPhotos] = useState([]);
  const [uploadingPhotos, setUploadingPhotos] = useState(false);
  const [modalPhotos, setModalPhotos] = useState([]);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    fetchAssessments();
    fetchClients();
    fetchCurrentUser();
  }, []);

  const fetchCurrentUser = async () => {
    const { data: { user }, error } = await supabase.auth.getUser();
    if (error) {
      console.error('Error fetching current user:', error);
    } else {
      setCurrentUser(user);
    }
  };

  const fetchAssessments = async () => {
    try {
      const { data, error } = await supabase
        .from('assessments')
        .select(`
          *,
          clients (
            id,
            name,
            user_id
          )
        `)
        .order('date', { ascending: false });
      if (error) throw error;
  
      const clientsWithUserIds = data.filter(assessment => assessment.clients?.user_id);
      const { data: userProfilesData, error: userProfilesError } = await supabase
        .from('user_profiles')
        .select('id, image_url')
        .in('id', clientsWithUserIds.map(assessment => assessment.clients.user_id));
  
      if (userProfilesError) throw userProfilesError;
  
      const assessmentsWithProfiles = data.map(assessment => {
        if (assessment.clients && assessment.clients.user_id) {
          const userProfile = userProfilesData.find(profile => profile.id === assessment.clients.user_id);
          assessment.clients.image_url = userProfile ? getImageUrl(userProfile.image_url) : null;
        }
        return assessment;
      });
  
      setAssessments(assessmentsWithProfiles);
    } catch (error) {
      console.error('Error fetching assessments:', error);
      setError('Failed to fetch assessments. Please try again later.');
    }
  };

  const getImageUrl = (imageUrl) => {
    if (imageUrl) {
      return `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/profile_images/${imageUrl}`;
    }
    return null;
  };

  const fetchClients = async () => {
    try {
      const { data, error } = await supabase
        .from('clients')
        .select('id, name, user_id');
      if (error) throw error;

      const clientsWithUserIds = data.filter(client => client.user_id);
      const { data: userProfilesData, error: userProfilesError } = await supabase
        .from('user_profiles')
        .select('id, image_url')
        .in('id', clientsWithUserIds.map(client => client.user_id));

      if (userProfilesError) throw userProfilesError;

      const clientsWithProfiles = data.map(client => {
        const userProfile = userProfilesData.find(profile => profile.id === client.user_id);
        return {
          ...client,
          image_url: userProfile ? getImageUrl(userProfile.image_url) : null
        };
      });

      setClients(clientsWithProfiles);
    } catch (error) {
      console.error('Error fetching clients:', error);
      setError('Failed to fetch clients. Please try again later.');
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedAssessments = [...assessments].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const filteredAssessments = sortedAssessments.filter(assessment => {
    const nameMatch = (assessment.clients?.name || '').toLowerCase().includes(searchTerm.toLowerCase());
    const dateMatch = assessment.date.includes(searchTerm);
    const dateFilterMatch = () => {
      if (dateFilter === 'all') return true;
      const assessmentDate = new Date(assessment.date);
      const now = new Date();
      if (dateFilter === '7days') {
        return (now - assessmentDate) / (1000 * 60 * 60 * 24) <= 7;
      }
      if (dateFilter === '30days') {
        return (now - assessmentDate) / (1000 * 60 * 60 * 24) <= 30;
      }
      return true;
    };
    return (nameMatch || dateMatch) && dateFilterMatch();
  });

  const groupedAssessments = filteredAssessments.reduce((acc, assessment) => {
    const clientId = assessment.client_id;
    if (!acc[clientId]) {
      acc[clientId] = {
        client: assessment.clients,
        assessments: []
      };
    }
    acc[clientId].assessments.push(assessment);
    return acc;
  }, {});

  const handlePhotoUpload = async (event) => {
    if (!currentUser) {
      console.error('No current user');
      setError('User not authenticated. Please log in.');
      return;
    }

    const files = Array.from(event.target.files);
    setUploadingPhotos(true);
    
    try {
      const uploadPromises = files.map(async (file) => {
        const fileExt = file.name.split('.').pop();
        const fileName = `${Date.now()}_${Math.random()}.${fileExt}`;
        const filePath = `${currentUser.id}/${fileName}`;
  
        const { data, error } = await supabase.storage
          .from('assessment_photos')
          .upload(filePath, file);
        
        if (error) throw error;
        
        const { data: { publicUrl } } = supabase.storage
          .from('assessment_photos')
          .getPublicUrl(filePath);
  
        return publicUrl;
      });
  
      const uploadedPhotos = await Promise.all(uploadPromises);
      setPhotos([...photos, ...uploadedPhotos]);
    } catch (error) {
      console.error('Error uploading photos:', error);
      setError('Failed to upload photos. Please try again.');
    } finally {
      setUploadingPhotos(false);
    }
  };

  const handleCreateAssessment = async () => {
    try {
      const { data, error } = await supabase
        .from('assessments')
        .insert([{
          ...newAssessment,
          photos: photos || []
        }])
        .select();
      if (error) throw error;
      setAssessments([...assessments, data[0]]);
      setIsCreateModalOpen(false);
      setNewAssessment({
        client_id: '',
        date: '',
        weight: '',
        body_fat: '',
        muscle_mass: '',
        notes: '',
      });
      setPhotos([]);
    } catch (error) {
      console.error('Error creating assessment:', error);
      setError('Failed to create assessment. Please try again.');
    }
  };

  const handleFileUpload = async (event, assessmentId, fileType) => {
    const file = event.target.files[0];
    const fileName = `${assessmentId}_${fileType}_${file.name}`;
    try {
      const { data, error } = await supabase.storage
        .from('assessment_files')
        .upload(fileName, file);
      if (error) throw error;
      
      const { data: updateData, error: updateError } = await supabase
        .from('assessments')
        .update({ [`${fileType}_url`]: data.path })
        .eq('id', assessmentId);
      if (updateError) throw updateError;

      fetchAssessments();
    } catch (error) {
      console.error('Error uploading file:', error);
      setError('Failed to upload file. Please try again.');
    }
  };

  const handleDownloadFile = async (path, fileName) => {
    try {
      const { data, error } = await supabase.storage
        .from('assessment_files')
        .download(path);
      if (error) throw error;
      
      const blob = new Blob([data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
      setError('Failed to download file. Please try again.');
    }
  };

  const handleUpdateAssessment = async () => {
    try {
      const { data, error } = await supabase
        .from('assessments')
        .update(selectedAssessment)
        .eq('id', selectedAssessment.id)
        .select();
      if (error) throw error;
      setAssessments(assessments.map(a => a.id === selectedAssessment.id ? data[0] : a));
      setSelectedAssessment(null);
    } catch (error) {
      console.error('Error updating assessment:', error);
      setError('Failed to update assessment. Please try again.');
    }
  };

  const handleDeleteAssessment = async (id) => {
    try {
      const { error } = await supabase
        .from('assessments')
        .delete()
        .eq('id', id);
      if (error) throw error;
      setAssessments(assessments.filter(a => a.id !== id));
    } catch (error) {
      console.error('Error deleting assessment:', error);
      setError('Failed to delete assessment. Please try again.');
    }
  };

  const toggleClientExpand = (clientId) => {
    setExpandedClients(prev => ({
      ...prev,
      [clientId]: !prev[clientId]
    }));
  };

  const generatePDF = (clientId) => {
    const { client, assessments } = groupedAssessments[clientId];
    const clientName = client?.name || 'Unknown Client';

    const doc = new jsPDF();
    doc.text(`Assessment Report for ${clientName}`, 20, 10);

    const tableData = assessments.map(assessment => [
      new Date(assessment.date).toLocaleDateString(),
      assessment.weight,
      assessment.body_fat,
      assessment.muscle_mass
    ]);

    doc.autoTable({
      head: [['Date', 'Weight (kg)', 'Body Fat (%)', 'Muscle Mass (kg)']],
      body: tableData,
      startY: 20,
    });

    doc.save(`${clientName}_assessment_report.pdf`);
  };

  const generateExcel = (clientId) => {
    const { client, assessments } = groupedAssessments[clientId];
    const clientName = client?.name || 'Unknown Client';

    const ws = XLSX.utils.json_to_sheet(assessments.map(assessment => ({
      Date: new Date(assessment.date).toLocaleDateString(),
      'Weight (kg)': assessment.weight,
      'Body Fat (%)': assessment.body_fat,
      'Muscle Mass (kg)': assessment.muscle_mass,
      Notes: assessment.notes
    })));

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Assessments");
    XLSX.writeFile(wb, `${clientName}_assessment_report.xlsx`);
  };

  const renderAssessmentPhotos = (assessment) => {
    if (!assessment.photos || assessment.photos.length === 0) {
      return <span className="text-gray-400">No photos</span>;
    }
  
    return (
      <div className="flex space-x-2">
        {assessment.photos.slice(0, 3).map((photo, index) => (
          <img
            key={index}
            src={photo}
            alt={`Assessment ${index + 1}`}
            className="w-10 h-10 object-cover rounded-md cursor-pointer"
            onClick={() => {
              setModalPhotos(assessment.photos);
              setCurrentPhotoIndex(index);
            }}
          />
        ))}
        {assessment.photos.length > 3 && (
          <div 
            className="w-10 h-10 bg-gray-200 rounded-md flex items-center justify-center text-sm text-gray-600 cursor-pointer"
            onClick={() => {
              setModalPhotos(assessment.photos);
              setCurrentPhotoIndex(0);
            }}
          >
            +{assessment.photos.length - 3}
          </div>
        )}
      </div>
    );
  };

  const renderClientImage = (client) => {
    if (!client) return null;
    const imageUrl = client.image_url || 'https://via.placeholder.com/50';
    return (
      <img 
        src={imageUrl}
        alt={client.name || 'Client'} 
        className="w-12 h-12 rounded-full mr-4" 
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = 'https://via.placeholder.com/50';
        }}
      />
    );
  };

  const handlePhotoNavigation = (direction) => {
    if (direction === 'next' && currentPhotoIndex < modalPhotos.length - 1) {
      setCurrentPhotoIndex(currentPhotoIndex + 1);
    } else if (direction === 'prev' && currentPhotoIndex > 0) {
      setCurrentPhotoIndex(currentPhotoIndex - 1);
    }
  };

  const PhotoModal = ({ photos, currentIndex, onClose, onNavigate }) => {
    if (photos.length === 0) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
        <div className="max-w-3xl max-h-full overflow-auto relative">
          <img src={photos[currentIndex]} alt="Full size" className="max-w-full max-h-full" />
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-white hover:text-gray-300"
          >
            <X size={24} />
          </button>
          {photos.length > 1 && (
            <>
              <button
                onClick={() => onNavigate('prev')}
                className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white hover:text-gray-300"
                disabled={currentIndex === 0}
              >
                <ChevronLeft size={40} />
              </button>
              <button
                onClick={() => onNavigate('next')}
                className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white hover:text-gray-300"
                disabled={currentIndex === photos.length - 1}
              >
                <ChevronRight size={40} />
              </button>
            </>
          )}
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-white">
            {currentIndex + 1} / {photos.length}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Client Assessments</h1>
        <Link to="/dashboard" className="text-orange-500 hover:text-orange-600 flex items-center">
          <ArrowLeft size={20} className="mr-2" />
          Back to Dashboard
        </Link>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-6" role="alert">
          <strong className="font-bold">Error:</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}

      <div className="mb-6 flex flex-wrap items-center justify-between">
        <div className="flex items-center space-x-4 mb-4 md:mb-0">
          <div className="relative">
            <input
              type="text"
              placeholder="Search assessments..."
              className="pl-10 pr-4 py-2 border rounded-lg"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
          </div>
          <button className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg flex items-center">
            <Filter size={20} className="mr-2" />
            Filter
          </button>
        </div>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => setDateFilter('7days')}
            className={`px-4 py-2 rounded-lg ${dateFilter === '7days' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          >
            Last 7 days
          </button>
          <button
            onClick={() => setDateFilter('30days')}
            className={`px-4 py-2 rounded-lg ${dateFilter === '30days' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          >
            Last 30 days
          </button>
          <button
            onClick={() => setDateFilter('all')}
            className={`px-4 py-2 rounded-lg ${dateFilter === 'all' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          >
            All time
          </button>
        </div>
        <button
          onClick={() => setIsCreateModalOpen(true)}
          className="bg-orange-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-orange-600 transition-colors mt-4 md:mt-0"
        >
          <Plus size={20} className="mr-2" />
          New Assessment
        </button>
      </div>

      <div className="space-y-6">
        {Object.entries(groupedAssessments).map(([clientId, { client, assessments }]) => {
          const latestAssessment = assessments[0];
          return (
            <div key={clientId} className="bg-white shadow-md rounded-lg overflow-hidden">
              <div className="p-4 flex items-center justify-between cursor-pointer" onClick={() => toggleClientExpand(clientId)}>
                <div className="flex items-center">
                  {renderClientImage(client)}
                  <div>
                    <h3 className="font-semibold text-lg">{client?.name || 'Unknown Client'}</h3>
                    <p className="text-sm text-gray-500">Latest assessment: {latestAssessment ? new Date(latestAssessment.date).toLocaleDateString() : 'N/A'}</p>
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <button onClick={(e) => { e.stopPropagation(); generatePDF(clientId); }} className="text-blue-500 hover:text-blue-700">
                    <FileDown size={20} />
                  </button>
                  <button onClick={(e) => { e.stopPropagation(); generateExcel(clientId); }} className="text-green-500 hover:text-green-700">
                    <FileText size={20} />
                  </button>
                  {expandedClients[clientId] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </div>
              </div>
              {expandedClients[clientId] && (
                <div className="p-4 border-t">
                  <table className="w-full">
                    <thead>
                      <tr className="bg-gray-100">
                        <th className="py-2 px-4 text-left">Date</th>
                        <th className="py-2 px-4 text-left">Weight (kg)</th>
                        <th className="py-2 px-4 text-left">Body Fat (%)</th>
                        <th className="py-2 px-4 text-left">Muscle Mass (kg)</th>
                        <th className="py-2 px-4 text-left">Photos</th>
                        <th className="py-2 px-4 text-left">Files</th>
                        <th className="py-2 px-4 text-left">Notes</th>
                        <th className="py-2 px-4 text-left">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {assessments.map((assessment) => (
                        <tr key={assessment.id} className="border-b">
                          <td className="py-2 px-4">{new Date(assessment.date).toLocaleDateString()}</td>
                          <td className="py-2 px-4">{assessment.weight}</td>
                          <td className="py-2 px-4">{assessment.body_fat}</td>
                          <td className="py-2 px-4">{assessment.muscle_mass}</td>
                          <td className="py-2 px-4">
                            {renderAssessmentPhotos(assessment)}
                          </td>
                          <td className="py-2 px-4">
                            <div className="flex space-x-2">
                              {['photo', 'document'].map(fileType => (
                                <div key={fileType}>
                                  <input
                                    type="file"
                                    id={`${assessment.id}_${fileType}`}
                                    className="hidden"
                                    onChange={(e) => handleFileUpload(e, assessment.id, fileType)}
                                  />
                                  <label htmlFor={`${assessment.id}_${fileType}`} className="cursor-pointer">
                                    <Upload size={20} className="text-blue-500" />
                                  </label>
                                  {assessment[`${fileType}_url`] && (
                                    <button onClick={() => handleDownloadFile(assessment[`${fileType}_url`], `${assessment.id}_${fileType}`)}>
                                      <Download size={20} className="text-green-500" />
                                    </button>
                                  )}
                                </div>
                              ))}
                            </div>
                          </td>
                          <td className="py-2 px-4">
                            <button onClick={() => setNotesModal({ isOpen: true, assessment })}>
                              <FileText size={20} className="text-gray-500" />
                            </button>
                          </td>
                          <td className="py-2 px-4">
                            <button onClick={() => setSelectedAssessment(assessment)} className="text-blue-500 hover:text-blue-700 mr-2">
                              <Edit size={18} />
                            </button>
                            <button onClick={() => handleDeleteAssessment(assessment.id)} className="text-red-500 hover:text-red-700">
                              <Trash size={18} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          );
        })}
      </div>

      {modalPhotos.length > 0 && (
        <PhotoModal
          photos={modalPhotos}
          currentIndex={currentPhotoIndex}
          onClose={() => {
            setModalPhotos([]);
            setCurrentPhotoIndex(0);
          }}
          onNavigate={handlePhotoNavigation}
        />
      )}

      {isCreateModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-8 w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4">Create New Assessment</h2>
            <select
              className="w-full p-2 mb-4 border rounded-md"
              value={newAssessment.client_id}
              onChange={(e) => setNewAssessment({ ...newAssessment, client_id: e.target.value })}
            >
              <option value="">Select a client</option>
              {clients.map((client) => (
                <option key={client.id} value={client.id}>{client.name}</option>
              ))}
            </select>
            <input
              type="date"
              className="w-full p-2 mb-4 border rounded-md"
              value={newAssessment.date}
              onChange={(e) => setNewAssessment({ ...newAssessment, date: e.target.value })}
            />
            <input
              type="number"
              placeholder="Weight (kg)"
              className="w-full p-2 mb-4 border rounded-md"
              value={newAssessment.weight}
              onChange={(e) => setNewAssessment({ ...newAssessment, weight: e.target.value })}
            />
            <input
              type="number"
              placeholder="Body Fat %"
              className="w-full p-2 mb-4 border rounded-md"
              value={newAssessment.body_fat}
              onChange={(e) => setNewAssessment({ ...newAssessment, body_fat: e.target.value })}
            />
            <input
              type="number"
              placeholder="Muscle Mass (kg)"
              className="w-full p-2 mb-4 border rounded-md"
              value={newAssessment.muscle_mass}
              onChange={(e) => setNewAssessment({ ...newAssessment, muscle_mass: e.target.value })}
            />
            <textarea
              placeholder="Notes"
              className="w-full p-2 mb-4 border rounded-md"
              value={newAssessment.notes}
              onChange={(e) => setNewAssessment({ ...newAssessment, notes: e.target.value })}
            ></textarea>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Progress Photos</label>
              <div className="mt-1 flex items-center">
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handlePhotoUpload}
                  className="sr-only"
                  id="photo-upload"
                />
                <label
                  htmlFor="photo-upload"
                  className="cursor-pointer bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                >
                  <Camera className="h-5 w-5 text-gray-400 mr-2 inline" />
                  Upload Photos
                </label>
              </div>
              {uploadingPhotos && <p className="mt-2 text-sm text-gray-500">Uploading...</p>}
              <div className="mt-2 grid grid-cols-3 gap-2">
                {photos.map((photo, index) => (
                  <img key={index} src={photo} alt={`Progress ${index + 1}`} className="w-full h-24 object-cover rounded-md" />
                ))}
              </div>
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => setIsCreateModalOpen(false)}
                className="mr-2 px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleCreateAssessment}
                className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition-colors"
              >
                Create Assessment
              </button>
            </div>
          </div>
        </div>
      )}

      {selectedAssessment && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-8 w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4">Edit Assessment</h2>
            <input
              type="date"
              className="w-full p-2 mb-4 border rounded-md"
              value={selectedAssessment.date}
              onChange={(e) => setSelectedAssessment({ ...selectedAssessment, date: e.target.value })}
            />
            <input
              type="number"
              placeholder="Weight (kg)"
              className="w-full p-2 mb-4 border rounded-md"
              value={selectedAssessment.weight}
              onChange={(e) => setSelectedAssessment({ ...selectedAssessment, weight: e.target.value })}
            />
            <input
              type="number"
              placeholder="Body Fat %"
              className="w-full p-2 mb-4 border rounded-md"
              value={selectedAssessment.body_fat}
              onChange={(e) => setSelectedAssessment({ ...selectedAssessment, body_fat: e.target.value })}
            />
            <input
              type="number"
              placeholder="Muscle Mass (kg)"
              className="w-full p-2 mb-4 border rounded-md"
              value={selectedAssessment.muscle_mass}
              onChange={(e) => setSelectedAssessment({ ...selectedAssessment, muscle_mass: e.target.value })}
            />
            <textarea
              placeholder="Notes"
              className="w-full p-2 mb-4 border rounded-md"
              value={selectedAssessment.notes}
              onChange={(e) => setSelectedAssessment({ ...selectedAssessment, notes: e.target.value })}
            ></textarea>
            <div className="flex justify-end">
              <button
                onClick={() => setSelectedAssessment(null)}
                className="mr-2 px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleUpdateAssessment}
                className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition-colors"
              >
                Update Assessment
              </button>
            </div>
          </div>
        </div>
      )}

      {notesModal.isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-8 w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4">Notes</h2>
            <textarea
              className="w-full p-2 mb-4 border rounded-md"
              value={notesModal.assessment.notes}
              onChange={(e) => setNotesModal(prev => ({
                ...prev,
                assessment: { ...prev.assessment, notes: e.target.value }
              }))}
            ></textarea>
            <div className="flex justify-end">
              <button
                onClick={() => setNotesModal({ isOpen: false, assessment: null })}
                className="mr-2 px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleUpdateAssessment(notesModal.assessment);
                  setNotesModal({ isOpen: false, assessment: null });
                }}
                className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition-colors"
              >
                Save Notes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Assessments;