import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Users, Calendar, DollarSign, Star, 
  Clock, Award, ChevronRight, Bell, LogOut,
  Activity, Globe, MapPin, Target, TrendingUp,
  Menu, X, Home, Dumbbell, MessageSquare, Settings,
  FileText, Clipboard, Utensils, ClipboardCheck, ChevronDown, ChevronUp, Umbrella
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { supabase } from '../utils/supabase';


const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
const STORAGE_URL = `${SUPABASE_URL}/storage/v1/object/public/profile_images/`;

const getPublicImageUrl = (path) => {
  if (!path) return 'https://via.placeholder.com/150'; // Fallback for no path
  try {
    const { data } = supabase.storage
      .from('profile_images')
      .getPublicUrl(path);
    return data.publicUrl;
  } catch (error) {
    console.error('Error getting public URL:', error);
    return 'https://via.placeholder.com/150'; // Use placeholder on error 
  }
};

const TrainerDashboard = ({ trainer, setIsAuthenticated }) => {
  const navigate = useNavigate();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [upcomingSessions, setUpcomingSessions] = useState([]);
  const [recentReviews, setRecentReviews] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [messageCount, setMessageCount] = useState(0);
  const [trialDaysLeft, setTrialDaysLeft] = useState(null);
  const [isStatsExpanded, setIsStatsExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState('overview');

  useEffect(() => {
    if (trainer && trainer.id) {
      fetchDashboardData();
      fetchNotificationCount();
      fetchMessageCount();
      calculateTrialPeriod();
    }
  }, [trainer]);

  const fetchDashboardData = async () => {
    try {
      // Fetch upcoming sessions
      const { data: sessions, error: sessionsError } = await supabase
        .from('sessions')
        .select('*')
        .eq('trainer_id', trainer.id)
        .gte('date', new Date().toISOString())
        .order('date', { ascending: true })
        .limit(3);

      if (sessionsError) throw sessionsError;

      // Fetch recent reviews
      const { data: reviews, error: reviewsError } = await supabase
        .from('reviews')
        .select('*')
        .eq('trainer_id', trainer.id)
        .order('created_at', { ascending: false })
        .limit(2);

      if (reviewsError) throw reviewsError;

      setUpcomingSessions(sessions);
      setRecentReviews(reviews);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  };

  const fetchNotificationCount = async () => {
    if (trainer) {
      const { count, error } = await supabase
        .from('notifications')
        .select('*', { count: 'exact', head: true })
        .eq('user_id', trainer.id)
        .eq('read', false);

      if (error) {
        console.error('Error fetching notification count:', error);
      } else {
        setNotificationCount(count);
      }
    }
  };

  const fetchMessageCount = async () => {
    if (trainer) {
      const { count, error } = await supabase
        .from('messages')
        .select('*', { count: 'exact', head: true })
        .eq('receiver_id', trainer.id)
        .eq('read', false);

      if (error) {
        console.error('Error fetching message count:', error);
      } else {
        setMessageCount(count);
      }
    }
  };

  if (!trainer || !trainer.id) {
    return <div className="max-w-7xl mx-auto p-4">Error: Trainer data not available. Please log in again.</div>;
  }

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };

  const calculateTrialPeriod = () => {
    if (trainer && trainer.created_at) {
      const registrationDate = new Date(trainer.created_at);
      const today = new Date();
      const trialEndDate = new Date(registrationDate.getTime() + 30 * 24 * 60 * 60 * 1000);
      const daysLeft = Math.ceil((trialEndDate - today) / (1000 * 60 * 60 * 24));
      setTrialDaysLeft(daysLeft > 0 ? daysLeft : 0);
    }
  };

  const renderStatCard = (icon, title, value, suffix = '', isCurrency = false) => (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <div className="bg-gray-50 p-4 rounded-lg">
        <div className="flex items-center mb-2">
          {icon}
          <h3 className="text-sm font-semibold ml-2">{title}</h3>
        </div>
        <p className="text-xl font-bold text-orange-500">
          {value !== undefined 
            ? (isCurrency ? formatCurrency(value) : `${value}${suffix}`)
            : 'N/A'}
        </p>
      </div>
    </div>
  );

  const sidebarItems = [
    { icon: <Home size={20} />, label: 'Dashboard', path: '/trainer-dashboard' },
    { icon: <Calendar size={20} />, label: 'Booking System', path: '/trainer-booking' },
    { icon: <Calendar size={20} />, label: 'Classes', path: '/trainer-classes' },
    { 
      icon: <Users size={20} />, 
      label: 'Clients', 
      path: '/clients',
      subItems: [
        { icon: <Users size={16} />, label: 'Client Management', path: '/clients/management' },
        { icon: <FileText size={16} />, label: 'Assessments', path: '/clients/assessments' },
        { icon: <Clipboard size={16} />, label: 'Workout Planner', path: '/clients/workout-planner' },
        { icon: <Utensils size={16} />, label: 'Nutrition Planner', path: '/clients/nutrition-planner' },
        { icon: <Utensils size={16} />, label: 'AI Meal Planner', path: '/clients/ai-meal-planner' },] },
        
        { icon: <FileText size={20} />, label: 'Forms', path: '/forms',
        subItems: [
          { icon: <ClipboardCheck size={16} />, label: 'Assessment Forms', path: '/assessment-form-manager' },    
          { icon: <FileText size={16} />, label: 'Initial Assessment', path: '/forms/initial-assessment' }] },
    { icon: <FileText size={20} />, label: 'Invoices', path: '/trainer-invoices' },
    { icon: <MessageSquare size={20} />, label: 'Messages', path: '/trainer-chats' },
    { icon: <DollarSign size={20} />, label: 'Financials', path: '/trainer-financials' },
    { icon: <Settings size={20} />, label: 'Settings', path: '/trainer-settings' },
  ];

  const renderTabContent = () => {
    switch(activeTab) {
      case 'overview':
        return (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
              {renderStatCard(<Users className="text-blue-500" size={24} />, "Total Clients", trainer.stats?.totalClients)}
              {renderStatCard(<Calendar className="text-green-500" size={24} />, "Sessions This Week", trainer.stats?.sessionsThisWeek)}
              {renderStatCard(<DollarSign className="text-yellow-500" size={24} />, "Earnings This Month", trainer.stats?.monthlyEarnings, "", true)}
              {renderStatCard(<Star className="text-purple-500" size={24} />, "Average Rating", trainer.rating)}
            </div>
          </>
        );
      case 'stats':
        return (
          <div className="bg-white p-6 rounded-lg shadow-md mb-8">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Trainer Stats</h2>
              <button 
                onClick={() => setIsStatsExpanded(!isStatsExpanded)}
                className="text-orange-500 hover:text-orange-600 transition-colors"
              >
                {isStatsExpanded ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
              </button>
            </div>
            <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 ${isStatsExpanded ? '' : 'max-h-96 overflow-hidden'}`}>
              {renderStatCard(<Users className="text-blue-500" size={24} />, "Total Clients", trainer.stats?.totalClients)}
              {renderStatCard(<Users className="text-green-500" size={24} />, "Active Clients", trainer.stats?.activeClients)}
              {renderStatCard(<Activity className="text-purple-500" size={24} />, "Classes Completed", trainer.stats?.classesCompleted)}
              {renderStatCard(<Globe className="text-indigo-500" size={24} />, "Online Sessions", trainer.stats?.onlineSessionsCompleted)}
              {renderStatCard(<MapPin className="text-red-500" size={24} />, "In-Person Sessions", trainer.stats?.inPersonSessionsCompleted)}
              {renderStatCard(<Clock className="text-yellow-500" size={24} />, "Total Hours Worked", trainer.stats?.totalHoursWorked)}
              {renderStatCard(<Clock className="text-pink-500" size={24} />, "Avg Session Duration", trainer.stats?.avgSessionDuration, " min")}
              {renderStatCard(<Users className="text-teal-500" size={24} />, "Client Retention Rate", trainer.stats?.clientRetentionRate, "%")}
              {renderStatCard(<Target className="text-orange-500" size={24} />, "Client Goals Achieved", trainer.stats?.clientGoalsAchieved, "%")}
              {renderStatCard(<Star className="text-yellow-500" size={24} />, "Top Class", trainer.stats?.topPerformingClass)}
              {renderStatCard(<DollarSign className="text-green-500" size={24} />, "Monthly Earnings", trainer.stats?.monthlyEarnings, "", true)}
              {renderStatCard(<DollarSign className="text-blue-500" size={24} />, "YTD Earnings", trainer.stats?.yearToDateEarnings, "", true)}
              {renderStatCard(<Calendar className="text-purple-500" size={24} />, "Upcoming Sessions", trainer.stats?.upcomingSessions)}
              {renderStatCard(<TrendingUp className="text-red-500" size={24} />, "Last Month Growth", trainer.stats?.lastMonthGrowth, "%")}
            </div>
            {!isStatsExpanded && (
              <button 
                onClick={() => setIsStatsExpanded(true)}
                className="mt-4 text-orange-500 hover:text-orange-600 transition-colors"
              >
                Show More
              </button>
            )}
          </div>
        );
      case 'sessions':
        return (
          <div className="bg-white p-6 rounded-lg shadow-md mb-8">
            <h2 className="text-xl font-semibold mb-4">Upcoming Sessions</h2>
            {upcomingSessions.map((session) => (
              <div key={session.id} className="mb-4 p-4 bg-gray-50 rounded-lg">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="font-semibold">{session.client_name}</h3>
                  <span className="text-sm text-gray-500">{session.type}</span>
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <Clock size={16} className="mr-2" />
                  {new Date(session.date).toLocaleString()}
                </div>
              </div>
            ))}
            <button className="text-orange-500 font-medium flex items-center mt-2" onClick={() => navigate('/trainer-booking')}>
              View All Sessions <ChevronRight size={20} />
            </button>
          </div>
        );
      case 'reviews':
        return (
          <div className="bg-white p-6 rounded-lg shadow-md mb-8">
            <h2 className="text-xl font-semibold mb-4">Recent Reviews</h2>
            {recentReviews.map((review) => (
              <div key={review.id} className="mb-4 p-4 bg-gray-50 rounded-lg">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="font-semibold">{review.client_name}</h3>
                  <div className="flex items-center">
                    {[...Array(5)].map((_, i) => (
                      <Star key={i} size={16} className={i < review.rating ? 'text-yellow-400 fill-current' : 'text-gray-300'} />
                    ))}
                  </div>
                </div>
                <p className="text-sm text-gray-600">{review.comment}</p>
              </div>
            ))}
            <button className="text-orange-500 font-medium flex items-center mt-2" onClick={() => navigate('/reviews')}>
              View All Reviews <ChevronRight size={20} />
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div className={`bg-white fixed inset-y-0 left-0 z-30 w-64 transition-transform duration-300 ease-in-out transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} lg:relative lg:translate-x-0`}>
        <div className="px-6 py-4 flex items-center">
          <img 
            src={`${process.env.PUBLIC_URL}/fitbuddy-logo.png`}
            alt="TrainerHub Logo" 
            className="w-8 h-8 mr-2"
          />
          <span className="font-bold text-xl">TrainerHub</span>
          <button onClick={() => setIsSidebarOpen(false)} className="ml-auto lg:hidden">
            <X size={24} />
          </button>
        </div>
        <nav className="mt-2">
          {sidebarItems.map((item, index) => (
            <div key={index}>
              <button
                onClick={() => navigate(item.path)}
                className="flex items-center w-full px-6 py-2 text-gray-700 hover:bg-orange-100 hover:text-orange-500"
              >
                <span className="w-6 flex items-center justify-center">{item.icon}</span>
                <span className="ml-3">{item.label}</span>
              </button>
              {item.subItems && (
                <div>
                  {item.subItems.map((subItem, subIndex) => (
                    <button
                      key={subIndex}
                      onClick={() => navigate(subItem.path)}
                      className="flex items-center w-full pl-12 pr-4 py-2 text-sm text-gray-600 hover:bg-orange-50 hover:text-orange-500"
                    >
                      <span className="w-6 flex items-center justify-center">{subItem.icon}</span>
                      <span className="ml-2">{subItem.label}</span>
                    </button>
                  ))}
                </div>
              )}
            </div>
          ))}
        </nav>
      </div>

      {/* Main content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        <header className="bg-white p-4 shadow-sm">
          <div className="max-w-7xl mx-auto flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsSidebarOpen(!isSidebarOpen)} className="lg:hidden mr-4">
                <Menu size={24} />
              </button>
              {trialDaysLeft !== null && (
                <div className="bg-orange-100 text-orange-800 px-2 py-1 rounded-full text-xs sm:text-sm font-medium">
                  {trialDaysLeft > 0 ? (
                    <>
                      <span className="hidden sm:inline">{trialDaysLeft} days left in trial.</span>
                      <span className="sm:hidden">{trialDaysLeft}d left.</span>
                      <button
                        onClick={() => navigate('/pricing-plans')}
                        className="ml-2 bg-orange-500 text-white px-2 py-0.5 rounded-full text-xs font-semibold hover:bg-orange-600 transition-colors"
                      >
                        Upgrade
                      </button>
                    </>
                  ) : (
                    <>
                      <span className="hidden sm:inline">Trial expired.</span>
                      <span className="sm:hidden">Expired.</span>
                      <button
                        onClick={() => navigate('/pricing-plans')}
                        className="ml-2 bg-orange-500 text-white px-2 py-0.5 rounded-full text-xs font-semibold hover:bg-orange-600 transition-colors"
                      >
                        Upgrade
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>
            <div className="flex items-center space-x-4">
              <button 
                onClick={() => navigate('/trainer-chats')} 
                className="relative text-gray-600 hover:text-gray-800"
              >
                <MessageSquare size={24} />
                {messageCount > 0 && (
                  <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                    {messageCount}
                  </span>
                )}
              </button>
              <button 
                onClick={() => navigate('/notifications')} 
                className="relative text-gray-600 hover:text-gray-800"
              >
                <Bell size={24} />
                {notificationCount > 0 && (
                  <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                    {notificationCount}
                  </span>
                )}
              </button>
              <button onClick={() => setIsUserMenuOpen(true)} className="w-10 h-10 rounded-full overflow-hidden">
                <img 
                  src={getPublicImageUrl(trainer.image_url)} 
                  alt={trainer.name} 
                  className="w-full h-full object-cover"
                  onError={(e) => {
                    e.target.onerror = null; 
                    e.target.src = 'https://via.placeholder.com/150';
                  }}
                />
              </button>
            </div>
          </div>
        </header>

        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-4 pb-20 sm:pb-4">
          <h1 className="text-2xl font-semibold mb-6">Welcome back, {trainer.name}</h1>

          {/* Tab navigation */}
          <div className="flex mb-4">
            {['Overview', 'Stats', 'Sessions', 'Reviews'].map((tab) => (
              <button
                key={tab}
                className={`mr-4 pb-2 ${activeTab === tab.toLowerCase() ? 'border-b-2 border-orange-500 text-orange-500' : 'text-gray-500'}`}
                onClick={() => setActiveTab(tab.toLowerCase())}
              >
                {tab}
              </button>
            ))}
          </div>

          {/* Tab content */}
          {renderTabContent()}
        </main>
      </div>

      {/* User menu modal */}
      {isUserMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-80">
            <div className="flex items-center mb-4">
              <img 
                src={getPublicImageUrl(trainer.image_url)} 
                alt={trainer.name} 
                className="w-16 h-16 rounded-full mr-4 object-cover"
                onError={(e) => {
                  e.target.onerror = null; 
                  e.target.src = 'https://via.placeholder.com/150'
                }}
              />
              <div>
                <h2 className="text-xl font-semibold">{trainer.name}</h2>
                <p className="text-gray-500">Trainer</p>
              </div>
            </div>
            <ul className="space-y-2">
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded" onClick={() => { navigate('/trainer-profile'); setIsUserMenuOpen(false); }}>View Profile</button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded" onClick={() => { navigate('/trainer-settings'); setIsUserMenuOpen(false); }}>Settings</button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded text-red-500" onClick={() => {
                setIsAuthenticated(false);
                navigate('/login');
              }}>Logout</button></li>
            </ul>
            <button className="mt-4 w-full bg-gray-200 py-2 rounded" onClick={() => setIsUserMenuOpen(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TrainerDashboard;