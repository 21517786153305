import React, { useState, useEffect } from 'react';
import { supabase } from '../utils/supabase';
import { CheckCircle } from 'lucide-react'; // Import the CheckCircle icon

const TrainerBadges = ({ trainerId, showOnlyCertification = false }) => {
  const [badges, setBadges] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTrainerBadges();
  }, [trainerId]);

  const fetchTrainerBadges = async () => {
    try {
      let query = supabase
        .from('trainer_badges')
        .select(`
          badges (
            id,
            name,
            description,
            icon_url
          )
        `)
        .eq('trainer_id', trainerId);

      if (showOnlyCertification) {
        query = query.eq('badges.name', 'Certified Trainer');
      }

      const { data, error } = await query;

      if (error) throw error;
      setBadges(data.map(item => item.badges).filter(Boolean));
    } catch (error) {
      console.error('Error fetching trainer badges:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return null;

  return (
    <div className="inline-flex items-center ml-2">
      {badges.map(badge => (
        <div key={badge.id} className="relative group">
          <svg className="w-5 h-5" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="9" stroke="#F97316" strokeWidth="2"/>
            <path d="M6 10L9 13L14 7" stroke="#F97316" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 hidden group-hover:block whitespace-nowrap z-10">
            Certified Trainer
          </div>
        </div>
      ))}
    </div>
  );
};

export default TrainerBadges;