import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { MessageCircle, Calendar, MapPin, Star, ChevronDown, ChevronUp, X, Slash, RefreshCw, UserPlus } from 'lucide-react';
import { supabase } from '../utils/supabase';
import SharedWorkoutsAndInvitations from './SharedWorkoutsAndInvitations';
import Navigation from './Navigation';

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
const STORAGE_URL = `${SUPABASE_URL}/storage/v1/object/public/profile_images/`;

const BuddiesPage = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedChats, setExpandedChats] = useState({});
  const [connectedBuddies, setConnectedBuddies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [filteredBuddies, setFilteredBuddies] = useState([]);
  const [loadingFailed, setLoadingFailed] = useState(false);
  const [activeTab, setActiveTab] = useState('buddies');

  const fetchCurrentUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('user_profiles')
          .select('*')
          .eq('id', user.id)
          .single();
        if (error) throw error;
        setCurrentUser(data);
      }
    } catch (error) {
      console.error('Error fetching current user profile:', error);
      setLoadingFailed(true);
    }
  };

  const fetchConnectedBuddies = useCallback(async () => {
    if (!currentUser) return;
  
    setRefreshing(true);
    try {
      const { data, error } = await supabase
        .from('buddy_connections')
        .select(`
          *,
          buddy:user_profiles!buddy_connections_sender_id_fkey(*),
          connected_buddy:user_profiles!buddy_connections_receiver_id_fkey(*)
        `)
        .or(`sender_id.eq.${currentUser.id},receiver_id.eq.${currentUser.id}`)
        .eq('status', 'accepted');
  
      if (error) throw error;

      const buddyMap = new Map();
  
      data.forEach((connection) => {
        const buddy = connection.sender_id === currentUser.id ? connection.connected_buddy : connection.buddy;
        
        if (!buddyMap.has(buddy.id)) {
          buddyMap.set(buddy.id, {
            ...buddy,
            connectionId: connection.id,
            distance: calculateDistance(
              currentUser.latitude,
              currentUser.longitude,
              buddy.latitude,
              buddy.longitude
            )
          });
        }
      });
      
      const formattedBuddies = Array.from(buddyMap.values());
      setConnectedBuddies(formattedBuddies);
      setLoadingFailed(false);
    } catch (error) {
      console.error('Error fetching connected buddies:', error);
      setLoadingFailed(true);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  }, [currentUser]);

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    if (currentUser) {
      fetchConnectedBuddies();
    }
  }, [currentUser, fetchConnectedBuddies]);

  useEffect(() => {
    const filtered = connectedBuddies.filter(buddy =>
      buddy.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredBuddies(filtered);
  }, [connectedBuddies, searchTerm]);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      if (!refreshing && !loadingFailed) {
        fetchConnectedBuddies();
      }
    }, 60000); // Refresh every minute

    return () => clearInterval(refreshInterval);
  }, [fetchConnectedBuddies, refreshing, loadingFailed]);

  const toggleChat = (buddyId) => {
    setExpandedChats(prev => ({
      ...prev,
      [buddyId]: !prev[buddyId]
    }));
  };

  const renderRating = (rating) => (
    <div className="flex items-center">
      {[...Array(5)].map((_, i) => (
        <Star 
          key={i} 
          size={12} 
          className={`${i < Math.floor(rating || 0) ? 'text-yellow-400' : 'text-gray-300'} fill-current`} 
        />
      ))}
      <span className="ml-1 text-xs sm:text-sm font-medium text-gray-700">
        {rating ? rating.toFixed(1) : 'N/A'}
      </span>
    </div>
  );

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    const d = R * c; // Distance in km
    return d.toFixed(1);
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI/180);
  };

  const getImageUrl = (imagePath) => {
    if (!imagePath) return 'https://via.placeholder.com/150';
    if (imagePath.startsWith('http')) return imagePath;
    return `${STORAGE_URL}${imagePath}`;
  };

  const handleAcceptInvitation = async (invitationId) => {
    try {
      const { data, error } = await supabase
        .from('workout_participants')
        .update({ status: 'accepted' })
        .eq('id', invitationId)
        .select();
  
      if (error) throw error;
  
      console.log('Accepted invitation:', data);
      await fetchConnectedBuddies();
    } catch (error) {
      console.error('Error accepting invitation:', error);
    }
  };

  const handleDeclineInvitation = async (invitationId) => {
    try {
      const { data, error } = await supabase
        .from('workout_participants')
        .update({ status: 'declined' })
        .eq('id', invitationId)
        .select();
  
      if (error) throw error;
  
      console.log('Declined invitation:', data);
      await fetchConnectedBuddies();
    } catch (error) {
      console.error('Error declining invitation:', error);
    }
  };

  const handleBlockBuddy = async (buddyId) => {
    if (!currentUser) return;

    try {
      const { error } = await supabase
        .from('buddy_connections')
        .update({ status: 'blocked' })
        .or(`and(sender_id.eq.${currentUser.id},receiver_id.eq.${buddyId}),and(sender_id.eq.${buddyId},receiver_id.eq.${currentUser.id})`);

      if (error) throw error;

      setConnectedBuddies(prevBuddies =>
        prevBuddies.map(buddy =>
          buddy.id === buddyId ? { ...buddy, status: 'blocked' } : buddy
        )
      );

      alert('Buddy blocked successfully');
    } catch (error) {
      console.error('Error blocking buddy:', error);
      alert('Failed to block buddy. Please try again.');
    }
  };

  const handleDeleteBuddy = async (buddyId) => {
    if (!currentUser) return;

    try {
      const { error } = await supabase
        .from('buddy_connections')
        .delete()
        .or(`and(sender_id.eq.${currentUser.id},receiver_id.eq.${buddyId}),and(sender_id.eq.${buddyId},receiver_id.eq.${currentUser.id})`);

      if (error) throw error;

      setConnectedBuddies(prevBuddies =>
        prevBuddies.filter(buddy => buddy.id !== buddyId)
      );

      alert('Buddy deleted successfully');
    } catch (error) {
      console.error('Error deleting buddy:', error);
      alert('Failed to delete buddy. Please try again.');
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-2 sm:p-4 pb-20">
      <div className="flex justify-between items-center mb-4 sm:mb-6">
        <h1 className="text-xl sm:text-2xl font-bold">My Buddies</h1>
        <div className="flex items-center">
          <button 
            onClick={() => fetchConnectedBuddies()} 
            className="mr-2 text-orange-500 hover:text-orange-600 transition-colors"
            disabled={refreshing}
          >
            <RefreshCw size={20} className={refreshing ? 'animate-spin' : ''} />
          </button>
          <button onClick={() => navigate('/')} className="text-orange-500 text-sm sm:text-base font-medium">
            Back to Dashboard
          </button>
        </div>
      </div>

      {loadingFailed ? (
        <div className="text-center py-4">
          <p className="text-red-500 mb-2">Failed to load buddies. Please try again.</p>
          <button
            onClick={() => fetchConnectedBuddies()}
            className="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition-colors"
          >
            Retry
          </button>
        </div>
      ) : (
        <>
          <input
            type="text"
            placeholder="Search buddies..."
            className="w-full p-2 mb-4 border rounded-lg text-sm"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          {loading ? (
            <div className="text-center py-4">
              <p>Loading buddies...</p>
            </div>
          ) : filteredBuddies.length === 0 ? (
            <div className="text-center py-8">
              <UserPlus size={64} className="mx-auto text-orange-500 mb-4" />
              <h2 className="text-xl font-semibold mb-2">No Buddies Yet</h2>
              <p className="text-gray-600 mb-4">
                You haven't connected with any buddies yet. Start your fitness journey by finding some workout partners!
              </p>
              <button 
                onClick={() => navigate('/find-buddy')}
                className="bg-orange-500 text-white px-6 py-2 rounded-lg hover:bg-orange-600 transition-colors"
              >
                Find Buddies
              </button>
            </div>
          ) : (
            <div className="space-y-3 sm:space-y-4">
              {filteredBuddies.map(buddy => (
                <div key={buddy.id} className="bg-white rounded-lg shadow-md p-3 sm:p-4">
                  <div className="flex items-start">
                    <img 
                      src={getImageUrl(buddy.image_url)} 
                      alt={buddy.name} 
                      className="w-12 h-12 sm:w-16 sm:h-16 rounded-full object-cover mr-3 sm:mr-4 cursor-pointer"
                      onClick={() => navigate(`/buddy/${buddy.id}`)}
                      onError={(e) => {
                        e.target.onerror = null; 
                        e.target.src = 'https://via.placeholder.com/150'
                      }}
                    />
                    <div className="flex-grow">
                      <h2 className="text-lg sm:text-xl font-semibold">{buddy.name}</h2>
                      <p className="text-xs sm:text-sm text-gray-600">{calculateAge(buddy.dob)} years old, {buddy.gender}</p>
                      <div className="flex items-center text-xs sm:text-sm text-gray-600 mb-1 sm:mb-2">
                        <MapPin size={12} className="mr-1" /> {buddy.distance} km away
                      </div>
                      {renderRating(buddy.rating)}
                    </div>
                    <div className="flex flex-col items-end">
                      <button 
                        onClick={() => navigate(`/message-buddy/${buddy.id}`)}
                        className="mb-1 sm:mb-2 px-3 sm:px-4 py-1 sm:py-2 bg-orange-500 text-white rounded-full hover:bg-orange-600 transition-colors flex items-center text-sm sm:text-base"
                      >
                        <MessageCircle size={14} className="mr-1" /> Message
                      </button>
                      <button 
                        onClick={() => navigate(`/schedule-workout/${buddy.id}`)}
                        className="mb-1 sm:mb-2 px-3 sm:px-4 py-1 sm:py-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition-colors flex items-center text-sm sm:text-base"
                      >
                        <Calendar size={14} className="mr-1" /> Schedule Workout
                      </button>
                      <div className="flex space-x-1 sm:space-x-2">
                        <button 
                          onClick={() => handleBlockBuddy(buddy.id)}
                          className="p-1 sm:p-2 bg-yellow-500 text-white rounded-full hover:bg-yellow-600 transition-colors"
                          title="Block Buddy"
                        >
                          <Slash size={14} />
                        </button>
                        <button 
                          onClick={() => handleDeleteBuddy(buddy.id)}
                          className="p-1 sm:p-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors"
                          title="Delete Buddy"
                        >
                          <X size={14} />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3 sm:mt-4">
                    <div 
                      className="flex justify-between items-center cursor-pointer"
                      onClick={() => toggleChat(buddy.id)}
                    >
                      <h3 className="font-semibold text-sm sm:text-base">Recent Chats</h3>
                      {expandedChats[buddy.id] ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                    </div>
                    {buddy.recentChats && buddy.recentChats.length > 0 && (
                      <>
                        {!expandedChats[buddy.id] && (
                          <div className="bg-gray-100 rounded p-2 mt-2">
                            <p className="text-xs sm:text-sm"><span className="font-semibold">{buddy.recentChats[0].sender}:</span> {buddy.recentChats[0].message}</p>
                            <p className="text-xs text-gray-500">{buddy.recentChats[0].timestamp}</p>
                          </div>
                        )}
                        {expandedChats[buddy.id] && (
                          <div className="mt-2 space-y-2">
                            {buddy.recentChats.slice(0, 4).map((chat, index) => (
                              <div key={index} className="bg-gray-100 rounded p-2">
                                <p className="text-xs sm:text-sm"><span className="font-semibold">{chat.sender}:</span> {chat.message}</p>
                                <p className="text-xs text-gray-500">{chat.timestamp}</p>
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  <SharedWorkoutsAndInvitations 
                    buddy={buddy} 
                    currentUser={currentUser}
                    onAcceptInvitation={handleAcceptInvitation}
                    onDeclineInvitation={handleDeclineInvitation}
                  />
                </div>
              ))}
            </div>
          )}
        </>
      )}

      <div className="md:hidden">
        <Navigation setActiveTab={setActiveTab} />
      </div>
    </div>
  );
};

export default BuddiesPage;