import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabase';
import { ArrowLeft, Calendar, User, Tag, Clock } from 'lucide-react';

const BlogPostFullPage = () => {
  const { postSlug } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        setLoading(true);
        const decodedTitle = decodeURIComponent(postSlug).replace(/-/g, ' ');
        const { data, error } = await supabase
          .from('blog_posts')
          .select('*')
          .ilike('title', decodedTitle)
          .single();

        if (error) throw error;
        if (data) {
          setPost(data);
        } else {
          // If no post is found, navigate to a 404 page or back to the blog list
          navigate('/blog', { replace: true });
        }
      } catch (error) {
        console.error('Error fetching post:', error);
        setError('Failed to load the blog post.');
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [postSlug, navigate]);

  if (loading) return <div className="text-center py-10">Loading...</div>;
  if (error) return <div className="text-center py-10 text-red-500">{error}</div>;
  if (!post) return <div className="text-center py-10">Post not found.</div>;

  return (
    <article className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <Link to="/blog" className="inline-flex items-center text-orange-500 hover:text-orange-600 mb-6">
        <ArrowLeft size={20} className="mr-2" />
        Back to Blog
      </Link>
      <h1 className="text-4xl font-bold mb-4">{post.title}</h1>
      <div className="flex flex-wrap items-center text-sm text-gray-500 mb-6">
        <div className="flex items-center mr-4 mb-2">
          <Calendar size={16} className="mr-2" />
          <span>{new Date(post.date).toLocaleDateString()}</span>
        </div>
        <div className="flex items-center mr-4 mb-2">
          <User size={16} className="mr-2" />
          <span>{post.author}</span>
        </div>
        {post.category && (
          <div className="flex items-center mr-4 mb-2">
            <Tag size={16} className="mr-2" />
            <span>{post.category}</span>
          </div>
        )}
        <div className="flex items-center mb-2">
          <Clock size={16} className="mr-2" />
          <span>{new Date(post.created_at).toLocaleString()}</span>
        </div>
      </div>
      {post.image && (
        <img src={post.image} alt={post.title} className="w-full h-96 object-cover object-center rounded-lg mb-8" />
      )}
      <div className="prose prose-lg max-w-none" dangerouslySetInnerHTML={{ __html: post.content }} />
      {post.meta_description && (
        <div className="mt-8 p-4 bg-gray-100 rounded-lg">
          <h2 className="text-xl font-semibold mb-2">About this post</h2>
          <p>{post.meta_description}</p>
        </div>
      )}
      {post.meta_keywords && (
        <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">Keywords</h3>
          <div className="flex flex-wrap gap-2">
            {post.meta_keywords.split(',').map((keyword, index) => (
              <span key={index} className="bg-orange-100 text-orange-800 px-2 py-1 rounded-full text-sm">
                {keyword.trim()}
              </span>
            ))}
          </div>
        </div>
      )}
    </article>
  );
};

export default BlogPostFullPage;