import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Search, Settings, Plus, Users, Home, Calendar, ChevronLeft } from 'lucide-react';
import { supabase } from '../utils/supabase';

const Groups = ({ currentUser }) => {
  const [managedGroups, setManagedGroups] = useState([]);
  const [joinedGroups, setJoinedGroups] = useState([]);
  const [recentActivity, setRecentActivity] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [userProfile, setUserProfile] = useState(null);
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (currentUser) {
      fetchUserProfile();
      fetchGroups();
      fetchRecentActivity();
    }
  }, [currentUser]);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      const handleWheel = (e) => {
        e.preventDefault();
        container.scrollLeft += e.deltaY;
      };
      container.addEventListener('wheel', handleWheel);
      return () => container.removeEventListener('wheel', handleWheel);
    }
  }, []);

  const fetchUserProfile = async () => {
    if (currentUser && currentUser.id) {
      const { data, error } = await supabase
        .from('user_profiles')
        .select('*')
        .eq('id', currentUser.id)
        .single();

      if (error) {
        console.error('Error fetching user profile:', error);
      } else {
        setUserProfile(data);
      }
    }
  };

  const fetchGroups = async () => {
    // Fetch managed groups
    const { data: managedData, error: managedError } = await supabase
      .from('groups')
      .select('*')
      .eq('admin_id', currentUser.id);

    if (managedError) console.error('Error fetching managed groups:', managedError);
    else setManagedGroups(managedData);

    // Fetch joined groups
    const { data: joinedData, error: joinedError } = await supabase
      .from('group_members')
      .select('group:groups(*)')
      .eq('user_id', currentUser.id);

    if (joinedError) console.error('Error fetching joined groups:', joinedError);
    else setJoinedGroups(joinedData.map(item => item.group));
  };

  const fetchRecentActivity = async () => {
    // This would fetch recent posts from groups the user is a part of
    // For now, we'll use dummy data
    setRecentActivity([
      {
        id: 1,
        group: 'Fitness Enthusiasts',
        author: 'John Doe',
        content: 'Just completed a 5k run! Who wants to join me for the next one?',
        timestamp: '2h ago',
        comments: 5,
        likes: 12
      },
      {
        id: 2,
        group: 'Yoga for Beginners',
        author: 'Jane Smith',
        content: 'New yoga session scheduled for tomorrow at 6 PM. Don\'t forget your mats!',
        timestamp: '4h ago',
        comments: 3,
        likes: 8
      },
    ]);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    // Implement search functionality here
    console.log('Searching for:', searchQuery);
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -100, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 100, behavior: 'smooth' });
    }
  };

  return (
    <div className="bg-gray-100 flex flex-col min-h-screen">
      {/* Header */}
      <header className="bg-white shadow-md fixed top-0 left-0 right-0 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => navigate(-1)} className="mr-4 md:hidden">
                <ChevronLeft size={24} />
              </button>
              <h1 className="text-2xl font-bold text-gray-800">Groups</h1>
            </div>
            <div className="flex items-center space-x-4">
              <button className="p-2 rounded-full bg-gray-200">
                <Plus size={24} />
              </button>
              <button className="p-2 rounded-full bg-gray-200">
                <Settings size={24} />
              </button>
              <button className="p-2 rounded-full bg-gray-200 md:hidden">
                <Search size={24} />
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Mobile horizontal menu */}
      <div className="md:hidden fixed top-16 left-0 right-0 bg-white shadow-md z-40 overflow-x-auto">
        <div ref={scrollContainerRef} className="flex whitespace-nowrap p-2">
          <Link to="/groups/feed" className="inline-block px-4 py-2 text-blue-500 font-medium">For you</Link>
          <Link to="/groups/manage" className="inline-block px-4 py-2 text-gray-700">Manage</Link>
          <Link to="/groups/your-groups" className="inline-block px-4 py-2 text-gray-700">Your groups</Link>
          <Link to="/groups/posts" className="inline-block px-4 py-2 text-gray-700">Posts</Link>
        </div>
      </div>

      <div className="flex flex-col flex-grow mt-24 md:mt-16 mb-16 md:mb-0">
        <div className="flex-grow flex flex-col md:flex-row max-w-7xl mx-auto w-full px-4 sm:px-6 lg:px-8">
          {/* Left Sidebar - only visible on md and larger screens */}
          <aside className="hidden md:block md:w-1/4 md:pr-4">
            <nav className="bg-white rounded-lg shadow p-4 sticky top-24">
              <ul className="space-y-2">
                <li>
                  <Link to="/groups/feed" className="flex items-center text-blue-500 font-medium">
                    <Users className="mr-2" size={20} />
                    Your feed
                  </Link>
                </li>
                <li>
                  <Link to="/groups/discover" className="flex items-center text-gray-700 hover:text-orange-500">
                    <Search className="mr-2" size={20} />
                    Discover
                  </Link>
                </li>
                <li>
                  <Link to="/groups/your-groups" className="flex items-center text-gray-700 hover:text-orange-500">
                    <Users className="mr-2" size={20} />
                    Your groups
                  </Link>
                </li>
              </ul>
              <button className="mt-4 flex items-center text-blue-500 font-medium">
                <Plus className="mr-2" size={20} />
                Create new group
              </button>
              <div className="mt-8">
                <h2 className="font-semibold mb-2">Groups you manage</h2>
                <ul className="space-y-2">
                  {managedGroups.map(group => (
                    <li key={group.id} className="flex items-center">
                      <img src={group.image_url || 'https://via.placeholder.com/40'} alt={group.name} className="w-10 h-10 rounded-full mr-2" />
                      <span>{group.name}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-8">
                <h2 className="font-semibold mb-2">Groups you've joined</h2>
                <ul className="space-y-2">
                  {joinedGroups.map(group => (
                    <li key={group.id} className="flex items-center">
                      <img src={group.image_url || 'https://via.placeholder.com/40'} alt={group.name} className="w-10 h-10 rounded-full mr-2" />
                      <span>{group.name}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </nav>
          </aside>

          {/* Main Content */}
          <main className="w-full md:w-3/4 md:pl-4 pt-4">
            <h2 className="text-xl font-semibold mb-4 md:hidden">For you</h2>
            {recentActivity.map(activity => (
              <div key={activity.id} className="bg-white rounded-lg shadow p-4 mb-4">
                <div className="flex items-center mb-2">
                  <img src="https://via.placeholder.com/40" alt={activity.author} className="w-10 h-10 rounded-full mr-2" />
                  <div>
                    <h3 className="font-semibold">{activity.author}</h3>
                    <p className="text-sm text-gray-500">{activity.timestamp} · {activity.group}</p>
                  </div>
                </div>
                <p className="mb-2">{activity.content}</p>
                <div className="flex items-center text-gray-500 text-sm">
                  <button className="mr-4 hover:text-orange-500">Like</button>
                  <button className="mr-4 hover:text-orange-500">Comment</button>
                  <button className="hover:text-orange-500">Share</button>
                </div>
              </div>
            ))}
          </main>
        </div>
      </div>
    </div>
  );
};

export default Groups;