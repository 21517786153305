import React, { useState } from 'react';
import { Plus, Edit, Trash } from 'lucide-react';
import { supabase } from '../utils/supabase';

const ManageAdHocPurchases = ({ clientId, purchases = [], onUpdate }) => {
    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentPurchase, setCurrentPurchase] = useState(null);

    const handleAdd = async (newPurchase) => {
        const { data, error } = await supabase
            .from('client_ad_hoc_purchases')
            .insert({ ...newPurchase, client_id: clientId })
            .select();

        if (error) {
            console.error('Error adding ad hoc purchase:', error);
        } else {
            onUpdate();
            setIsAdding(false);
        }
    };

    const handleEdit = async (updatedPurchase) => {
        const { data, error } = await supabase
            .from('client_ad_hoc_purchases')
            .update(updatedPurchase)
            .eq('id', updatedPurchase.id)
            .select();

        if (error) {
            console.error('Error updating ad hoc purchase:', error);
        } else {
            onUpdate();
            setIsEditing(false);
            setCurrentPurchase(null);
        }
    };

    const handleDelete = async (purchaseId) => {
        const { error } = await supabase
            .from('client_ad_hoc_purchases')
            .delete()
            .eq('id', purchaseId);

        if (error) {
            console.error('Error deleting ad hoc purchase:', error);
        } else {
            onUpdate();
        }
    };

    return (
        <div>
            <h3 className="text-xl font-semibold mb-4">Ad Hoc Purchases</h3>
            {purchases.length > 0 ? (
                purchases.map((purchase) => (
                    <div key={purchase.id} className="mb-4 p-4 border rounded-lg">
                        <h4 className="font-semibold">{purchase.item}</h4>
                        <p>Purchase Date: {purchase.purchase_date}</p>
                        <p>Quantity: {purchase.quantity}</p>
                        <p>Price: ${purchase.price}</p>
                        <div className="mt-2">
                            <button 
                                onClick={() => {
                                    setCurrentPurchase(purchase);
                                    setIsEditing(true);
                                }}
                                className="mr-2 text-blue-500 hover:text-blue-700"
                            >
                                <Edit size={18} />
                            </button>
                            <button 
                                onClick={() => handleDelete(purchase.id)}
                                className="text-red-500 hover:text-red-700"
                            >
                                <Trash size={18} />
                            </button>
                        </div>
                    </div>
                ))
            ) : (
                <p className="text-gray-500">No ad hoc purchases found.</p>
            )}
            <button
                onClick={() => setIsAdding(true)}
                className="mt-4 bg-green-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-green-600 transition-colors"
            >
                <Plus size={20} className="mr-2" />
                Add New Ad Hoc Purchase
            </button>
            {(isAdding || isEditing) && (
                <AdHocPurchaseForm
                    onSubmit={isAdding ? handleAdd : handleEdit}
                    onCancel={() => {
                        setIsAdding(false);
                        setIsEditing(false);
                        setCurrentPurchase(null);
                    }}
                    initialData={currentPurchase}
                />
            )}
        </div>
    );
};

const AdHocPurchaseForm = ({ onSubmit, onCancel, initialData }) => {
    const [formData, setFormData] = useState(initialData || {
        item: '',
        purchase_date: new Date().toISOString().split('T')[0],
        quantity: 1,
        price: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit} className="mt-4 p-4 border rounded-lg">
            <div className="mb-4">
                <label className="block mb-2">Item:</label>
                <input
                    type="text"
                    name="item"
                    value={formData.item}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Purchase Date:</label>
                <input
                    type="date"
                    name="purchase_date"
                    value={formData.purchase_date}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Quantity:</label>
                <input
                    type="number"
                    name="quantity"
                    value={formData.quantity}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    min="1"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Price:</label>
                <input
                    type="number"
                    name="price"
                    value={formData.price}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    step="0.01"
                    min="0"
                    required
                />
            </div>
            <div className="flex justify-end">
                <button
                    type="button"
                    onClick={onCancel}
                    className="mr-2 px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                >
                    {initialData ? 'Update' : 'Add'} Ad Hoc Purchase
                </button>
            </div>
        </form>
    );
};

export default ManageAdHocPurchases;