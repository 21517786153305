import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import LocationInput from './LocationInput';
import { supabase } from '../utils/supabase';
import { v4 as uuidv4 } from 'uuid';

const CreateActivityModal = ({
  isOpen,
  onClose,
  groupId,
  currentUserId,
  activity, // Activity to edit (optional)
}) => {
  const [activityData, setActivityData] = useState({
    title: '',
    type: 'sport',
    level: '',
    startDateTime: null,
    endDateTime: null,
    eventMode: 'in-person',
    location: null,
    participantsLimit: '',
    photos: null,
    previewPhoto: null,
    isRecurring: false,
    recurrenceFrequency: 'weekly',
    recurringEndDate: null,
    description: '',
    isPaid: false,
    price: '',
  });

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (activity) {
      // Pre-fill the form with activity data for editing
      setActivityData({
        title: activity.title,
        type: activity.type,
        level: activity.level || '',
        startDateTime: new Date(activity.start_time),
        endDateTime: new Date(activity.end_time),
        eventMode: activity.location_name ? 'in-person' : 'virtual',
        location: activity.location_name
          ? {
              suburb: activity.location_name,
              latitude: activity.latitude,
              longitude: activity.longitude,
            }
          : null,
        participantsLimit: activity.participants_limit || '',
        photos: null,
        previewPhoto: null,
        isRecurring: activity.is_recurring,
        recurrenceFrequency: activity.recurrence_frequency || 'weekly',
        recurringEndDate: activity.recurring_end_date
          ? new Date(activity.recurring_end_date)
          : null,
        description: activity.description || '',
        isPaid: activity.is_paid || false,
        price: activity.price ? activity.price.toString() : '',
      });
    } else {
      // Reset form when creating a new activity
      setActivityData({
        title: '',
        type: 'sport',
        level: '',
        startDateTime: null,
        endDateTime: null,
        eventMode: 'in-person',
        location: null,
        participantsLimit: '',
        photos: null,
        previewPhoto: null,
        isRecurring: false,
        recurrenceFrequency: 'weekly',
        recurringEndDate: null,
        description: '',
        isPaid: false,
        price: '',
      });
    }
  }, [activity]);

  const handleInputChange = (field, value) => {
    setActivityData((prevState) => ({ ...prevState, [field]: value }));
  };

  const generateOccurrences = (startDateTime, endDateTime, recurringEndDate, frequency) => {
    const occurrences = [];
    let currentStart = new Date(startDateTime);
    let currentEnd = new Date(endDateTime);

    while (currentStart <= recurringEndDate) {
      occurrences.push({
        start_time: new Date(currentStart),
        end_time: new Date(currentEnd),
      });

      // Increment dates based on frequency
      if (frequency === 'daily') {
        currentStart.setDate(currentStart.getDate() + 1);
        currentEnd.setDate(currentEnd.getDate() + 1);
      } else if (frequency === 'weekly') {
        currentStart.setDate(currentStart.getDate() + 7);
        currentEnd.setDate(currentEnd.getDate() + 7);
      } else if (frequency === 'monthly') {
        currentStart.setMonth(currentStart.getMonth() + 1);
        currentEnd.setMonth(currentEnd.getMonth() + 1);
      }
    }

    return occurrences;
  };

  const handleSave = async () => {
    // Basic validation
    const requiredFields = ['title', 'startDateTime', 'endDateTime', 'eventMode'];
    if (activityData.eventMode === 'in-person') {
      requiredFields.push('location');
    }
    if (activityData.isPaid) {
      requiredFields.push('price');
    }
    if (activityData.isRecurring) {
      requiredFields.push('recurringEndDate', 'recurrenceFrequency');
    }
    for (let field of requiredFields) {
      if (!activityData[field]) {
        alert('Please fill out all required fields.');
        return;
      }
    }

    // Ensure that the recurring end date is after the start date
    if (
      activityData.isRecurring &&
      activityData.recurringEndDate <= activityData.startDateTime
    ) {
      alert('Recurring end date must be after the start date.');
      return;
    }

    setIsSaving(true);

    try {
      // Prepare the activity data (including start and end times)
      const newActivity = {
        group_id: groupId,
        created_by: currentUserId,
        title: activityData.title,
        description: activityData.description || null,
        type: activityData.type,
        level: activityData.type === 'sport' ? activityData.level : null,
        start_time: activityData.startDateTime.toISOString(),
        end_time: activityData.endDateTime.toISOString(),
        is_recurring: activityData.isRecurring,
        recurring_end_date: activityData.isRecurring
          ? activityData.recurringEndDate.toISOString()
          : null,
        recurrence_frequency: activityData.isRecurring ? activityData.recurrenceFrequency : null,
        location_name:
          activityData.eventMode === 'in-person' ? activityData.location.suburb : null,
        latitude:
          activityData.eventMode === 'in-person' ? activityData.location.latitude : null,
        longitude:
          activityData.eventMode === 'in-person' ? activityData.location.longitude : null,
        participants_limit: activityData.participantsLimit
          ? parseInt(activityData.participantsLimit, 10)
          : null,
        is_paid: activityData.isPaid,
        price: activityData.isPaid ? parseFloat(activityData.price) : null,
      };

      let activityId = activity ? activity.id : null;

      if (activity) {
        // Update existing activity
        const { error } = await supabase
          .from('group_activities')
          .update(newActivity)
          .eq('id', activity.id);

        if (error) throw error;

        // Delete existing occurrences (if any) and recreate them
        await supabase.from('activity_occurrences').delete().eq('activity_id', activity.id);
      } else {
        // Insert new activity
        const { data, error } = await supabase
          .from('group_activities')
          .insert(newActivity)
          .select()
          .single();

        if (error) throw error;

        activityId = data.id;
      }

      // Generate occurrences
      let occurrences = [];

      if (activityData.isRecurring) {
        occurrences = generateOccurrences(
          activityData.startDateTime,
          activityData.endDateTime,
          activityData.recurringEndDate,
          activityData.recurrenceFrequency
        );
      } else {
        // Single occurrence
        occurrences.push({
          start_time: activityData.startDateTime,
          end_time: activityData.endDateTime,
        });
      }

      // Prepare occurrence data for insertion
      const occurrenceData = occurrences.map((occurrence) => ({
        activity_id: activityId, // Ensure activityId is a UUID
        start_time: occurrence.start_time.toISOString(),
        end_time: occurrence.end_time.toISOString(),
      }));

      // Insert occurrences into the database
      const { error: occurrencesError } = await supabase
        .from('activity_occurrences')
        .insert(occurrenceData);

      if (occurrencesError) throw occurrencesError;

      // Handle photo uploads if any
      if (activityData.photos && activityData.photos.length > 0) {
        const photoFiles = Array.from(activityData.photos);

        for (let file of photoFiles) {
          const fileExt = file.name.split('.').pop();
          const fileName = `${uuidv4()}.${fileExt}`;
          const filePath = `${activityId}/${fileName}`;

          // Upload to Supabase Storage
          let { error: uploadError } = await supabase.storage
            .from('activity-photos')
            .upload(filePath, file);

          if (uploadError) {
            console.error('Error uploading photo:', uploadError);
            continue;
          }

          // Get the public URL
          const { data: photoURL } = supabase.storage
            .from('activity-photos')
            .getPublicUrl(filePath);

          // Insert the photo URL into the activity_photos table
          const { error: photoInsertError } = await supabase
            .from('activity_photos')
            .insert({
              activity_id: activityId,
              photo_url: photoURL.publicUrl,
            });

          if (photoInsertError) {
            console.error('Error inserting photo URL:', photoInsertError);
          }
        }
      }

      onClose();
    } catch (error) {
      console.error('Error saving activity:', error);
      alert('There was an error saving the activity. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleLocationSelect = (location) => {
    handleInputChange('location', location);
  };

  const handlePhotoUpload = (event) => {
    const files = event.target.files;
    handleInputChange('photos', files);

    // Create a preview URL
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        handleInputChange('previewPhoto', e.target.result);
      };
      reader.readAsDataURL(files[0]);
    } else {
      handleInputChange('previewPhoto', null);
    }
  };

  return (
    isOpen && (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg w-full max-w-md p-6 relative overflow-y-auto max-h-full">
          <button
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            onClick={onClose}
            aria-label="Close"
            disabled={isSaving}
          >
            <X size={24} />
          </button>
          <h2 className="text-xl font-bold mb-4">
            {activity ? 'Edit Activity' : 'Create New Activity'}
          </h2>
          <div className="space-y-4">
            {/* Photo Upload */}
            <div>
              <label className="block text-sm font-medium mb-1">Upload Photos</label>
              <input
                type="file"
                className="w-full border rounded p-2"
                onChange={handlePhotoUpload}
                multiple
                disabled={isSaving}
              />
              {activityData.previewPhoto && (
                <img
                  src={activityData.previewPhoto}
                  alt="Preview"
                  className="mt-2 w-full h-48 object-cover rounded"
                />
              )}
            </div>
            {/* Activity/Event Name */}
            <div>
              <label className="block text-sm font-medium mb-1">Activity/Event Name *</label>
              <input
                type="text"
                className="w-full border rounded p-2"
                value={activityData.title}
                onChange={(e) => handleInputChange('title', e.target.value)}
                disabled={isSaving}
              />
            </div>
            {/* Date and Time */}
            <div className="flex flex-col sm:flex-row sm:space-x-4">
              <div className="flex-1">
                <label className="block text-sm font-medium mb-1">Start Date & Time *</label>
                <DatePicker
                  selected={activityData.startDateTime}
                  onChange={(date) => handleInputChange('startDateTime', date)}
                  showTimeSelect
                  dateFormat="Pp"
                  className="w-full border rounded p-2"
                  disabled={isSaving}
                />
              </div>
              <div className="flex-1">
                <label className="block text-sm font-medium mb-1">End Date & Time *</label>
                <DatePicker
                  selected={activityData.endDateTime}
                  onChange={(date) => handleInputChange('endDateTime', date)}
                  showTimeSelect
                  dateFormat="Pp"
                  className="w-full border rounded p-2"
                  disabled={isSaving}
                />
              </div>
            </div>
            {/* Event Mode */}
            <div>
              <label className="block text-sm font-medium mb-1">Event Mode *</label>
              <div className="flex space-x-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="eventMode"
                    value="in-person"
                    checked={activityData.eventMode === 'in-person'}
                    onChange={(e) => handleInputChange('eventMode', e.target.value)}
                    disabled={isSaving}
                  />
                  <span className="ml-2">In Person</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="eventMode"
                    value="virtual"
                    checked={activityData.eventMode === 'virtual'}
                    onChange={(e) => handleInputChange('eventMode', e.target.value)}
                    disabled={isSaving}
                  />
                  <span className="ml-2">Virtual</span>
                </label>
              </div>
            </div>
            {/* Location */}
            {activityData.eventMode === 'in-person' && (
              <div>
                <label className="block text-sm font-medium mb-1">Location *</label>
                <LocationInput
                  onSelectLocation={handleLocationSelect}
                  initialValue={activityData.location?.suburb || ''}
                  disabled={isSaving}
                />
              </div>
            )}
            {/* Type and Level */}
            <div className="flex flex-col sm:flex-row sm:space-x-4">
              {/* Type */}
              <div className="flex-1">
                <label className="block text-sm font-medium mb-1">Type *</label>
                <select
                  className="w-full border rounded p-2"
                  value={activityData.type}
                  onChange={(e) => handleInputChange('type', e.target.value)}
                  disabled={isSaving}
                >
                  <option value="sport">Sport</option>
                  <option value="social">Social</option>
                </select>
              </div>
              {/* Level (if type is sport) */}
              {activityData.type === 'sport' && (
                <div className="flex-1">
                  <label className="block text-sm font-medium mb-1">Level *</label>
                  <select
                    className="w-full border rounded p-2"
                    value={activityData.level}
                    onChange={(e) => handleInputChange('level', e.target.value)}
                    disabled={isSaving}
                  >
                    <option value="">Select Level</option>
                    <option value="All Levels">All Levels</option>
                    <option value="Beginner">Beginner</option>
                    <option value="Intermediate">Intermediate</option>
                    <option value="Advanced">Advanced</option>
                  </select>
                </div>
              )}
            </div>
            {/* Activity/Event Details */}
            <div>
              <label className="block text-sm font-medium mb-1">Activity/Event Details</label>
              <textarea
                className="w-full border rounded p-2"
                value={activityData.description}
                onChange={(e) => handleInputChange('description', e.target.value)}
                disabled={isSaving}
              />
            </div>
            {/* Participants Limit */}
            <div>
              <label className="block text-sm font-medium mb-1">Participants Limit</label>
              <input
                type="number"
                className="w-full border rounded p-2"
                value={activityData.participantsLimit}
                onChange={(e) => handleInputChange('participantsLimit', e.target.value)}
                min="0"
                disabled={isSaving}
              />
            </div>
            {/* Free or Paid */}
            <div>
              <label className="block text-sm font-medium mb-1">Price *</label>
              <div className="flex space-x-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="isPaid"
                    value={false}
                    checked={!activityData.isPaid}
                    onChange={() => handleInputChange('isPaid', false)}
                    disabled={isSaving}
                  />
                  <span className="ml-2">Free</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="isPaid"
                    value={true}
                    checked={activityData.isPaid}
                    onChange={() => handleInputChange('isPaid', true)}
                    disabled={isSaving}
                  />
                  <span className="ml-2">Paid</span>
                </label>
              </div>
            </div>
            {/* Price Input */}
            {activityData.isPaid && (
              <div>
                <label className="block text-sm font-medium mb-1">Price *</label>
                <input
                  type="number"
                  className="w-full border rounded p-2"
                  value={activityData.price}
                  onChange={(e) => handleInputChange('price', e.target.value)}
                  min="0"
                  step="0.01"
                  disabled={isSaving}
                />
              </div>
            )}
            {/* Repeat Activity */}
            <div className="flex items-center">
              <label className="text-sm font-medium mr-2">Repeat Activity</label>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={activityData.isRecurring}
                  onChange={(e) => handleInputChange('isRecurring', e.target.checked)}
                  className="sr-only"
                  disabled={isSaving}
                />
                <div className="w-11 h-6 bg-gray-200 rounded-full peer-focus:ring-orange-300 peer-checked:bg-orange-600 relative">
                  <span
                    className={`absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform ${
                      activityData.isRecurring ? 'transform translate-x-5' : ''
                    }`}
                  ></span>
                </div>
              </label>
            </div>
            {activityData.isRecurring && (
              <>
                {/* Recurrence Frequency */}
                <div>
                  <label className="block text-sm font-medium mb-1">Recurrence Frequency *</label>
                  <select
                    className="w-full border rounded p-2"
                    value={activityData.recurrenceFrequency}
                    onChange={(e) => handleInputChange('recurrenceFrequency', e.target.value)}
                    disabled={isSaving}
                  >
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                  </select>
                </div>
                {/* Recurring End Date */}
                <div>
                  <label className="block text-sm font-medium mb-1">Recurring End Date *</label>
                  <DatePicker
                    selected={activityData.recurringEndDate}
                    onChange={(date) => handleInputChange('recurringEndDate', date)}
                    dateFormat="Pp"
                    className="w-full border rounded p-2"
                    disabled={isSaving}
                  />
                </div>
              </>
            )}
          </div>
          <div className="mt-6 flex justify-end space-x-2">
            <button
              className="bg-gray-200 text-gray-700 px-4 py-2 rounded"
              onClick={onClose}
              disabled={isSaving}
            >
              Cancel
            </button>
            <button
              className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600"
              onClick={handleSave}
              disabled={isSaving}
            >
              {isSaving ? 'Saving...' : activity ? 'Update Activity' : 'Save Activity'}
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default CreateActivityModal;
