import React, { useState, useEffect } from 'react';
import { supabase } from '../utils/supabase';
import { PlusCircle, Search, Edit2, Trash2, Eye, EyeOff, X, ExternalLink } from 'lucide-react';

const Modal = ({ isOpen, onClose, title, children }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true);
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsAnimating(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  if (!isOpen && !isAnimating) return null;

  return (
    <div className={`fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}>
      <div className={`bg-white rounded-lg shadow-xl w-full max-w-md mx-4 transition-all duration-300 ${isOpen ? 'scale-100 opacity-100' : 'scale-95 opacity-0'}`}>
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-2xl font-semibold text-gray-800">{title}</h2>
          <button onClick={handleClose} className="text-gray-400 hover:text-gray-600 transition-colors">
            <X size={24} />
          </button>
        </div>
        <div className="p-6">
          {children}
        </div>
      </div>
    </div>
  );
};

const OffersManager = () => {
  const [offers, setOffers] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [currentOffer, setCurrentOffer] = useState(null);
  const [newOffer, setNewOffer] = useState({
    title: '',
    brand: '',
    image_url: '',
    redirect_url: '',
    valid_until: '',
    discount: '',
    description: '',
    location: '',
    status: 'active'
  });
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    fetchOffers();
  }, []);

  useEffect(() => {
    const filtered = offers.filter(offer =>
      offer.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      offer.brand.toLowerCase().includes(searchTerm.toLowerCase()) ||
      offer.location.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOffers(filtered);
  }, [searchTerm, offers]);

  const fetchOffers = async () => {
    const { data, error } = await supabase.from('offers').select('*');
    if (error) {
      console.error('Error fetching offers:', error);
    } else {
      setOffers(data);
      setFilteredOffers(data);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewOffer(prevOffer => ({
      ...prevOffer,
      [name]: value
    }));
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImageFile(e.target.files[0]);
    }
  };

  const uploadImage = async () => {
    if (!imageFile) return null;
    
    const fileExt = imageFile.name.split('.').pop();
    const fileName = `${Math.random()}.${fileExt}`;
    const filePath = `${fileName}`;

    const { error: uploadError } = await supabase.storage
      .from('offers_images')
      .upload(filePath, imageFile);

    if (uploadError) {
      console.error('Error uploading image:', uploadError);
      return null;
    }

    const { data } = supabase.storage.from('offers_images').getPublicUrl(filePath);
    return data.publicUrl;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const imageUrl = await uploadImage();
    if (imageUrl) {
      setNewOffer(prevOffer => ({ ...prevOffer, image_url: imageUrl }));
    }
    const { data, error } = await supabase.from('offers').insert([{ ...newOffer, image_url: imageUrl }]);
    if (error) {
      console.error('Error adding offer:', error);
      // You can add user-friendly error handling here, e.g., showing an error message
    } else {
      // Fetch the newly created offer
      const { data: newOfferData, error: fetchError } = await supabase
        .from('offers')
        .select('*')
        .order('created_at', { ascending: false })
        .limit(1)
        .single();
  
      if (fetchError) {
        console.error('Error fetching new offer:', fetchError);
      } else if (newOfferData) {
        setOffers(prevOffers => [...prevOffers, newOfferData]);
        setIsAddModalOpen(false);
        resetNewOffer();
        // You can add success feedback here, e.g., showing a success message
      }
    }
  };

  const handleEdit = (offer) => {
    setCurrentOffer(offer);
    setNewOffer({...offer});
    setIsEditModalOpen(true);
  };

  const handleView = (offer) => {
    setCurrentOffer(offer);
    setIsViewModalOpen(true);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    let imageUrl = newOffer.image_url;
    if (imageFile) {
      imageUrl = await uploadImage();
    }
    const { error } = await supabase
      .from('offers')
      .update({ ...newOffer, image_url: imageUrl })
      .eq('id', currentOffer.id);
    if (error) {
      console.error('Error updating offer:', error);
    } else {
      setOffers(offers.map(offer => offer.id === currentOffer.id ? { ...newOffer, image_url: imageUrl } : offer));
      setIsEditModalOpen(false);
      resetNewOffer();
    }
  };

  const handleDelete = async (id) => {
    const { error } = await supabase.from('offers').delete().eq('id', id);
    if (error) {
      console.error('Error deleting offer:', error);
    } else {
      setOffers(offers.filter(offer => offer.id !== id));
    }
  };

  const handleStatusChange = async (id, status) => {
    const { error } = await supabase
      .from('offers')
      .update({ status })
      .eq('id', id);
    if (error) {
      console.error('Error updating status:', error);
    } else {
      setOffers(offers.map(offer => offer.id === id ? { ...offer, status } : offer));
    }
  };

  const resetNewOffer = () => {
    setNewOffer({
      title: '',
      brand: '',
      image_url: '',
      redirect_url: '',
      valid_until: '',
      discount: '',
      description: '',
      location: '',
      status: 'active'
    });
    setImageFile(null);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">Manage Offers</h1>
        <button 
          className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded"
          onClick={() => setIsAddModalOpen(true)}
        >
          <PlusCircle size={20} className="inline mr-2" /> Add New Offer
        </button>
      </div>

      <div className="mb-6 relative">
        <input
          type="text"
          placeholder="Search offers..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-orange-500"
        />
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Brand</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Discount</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Valid Until</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredOffers.map((offer) => (
              <tr key={offer.id}>
                <td className="px-6 py-4 whitespace-nowrap">{offer.title}</td>
                <td className="px-6 py-4 whitespace-nowrap">{offer.brand}</td>
                <td className="px-6 py-4 whitespace-nowrap">{offer.discount}%</td>
                <td className="px-6 py-4 whitespace-nowrap">{new Date(offer.valid_until).toLocaleDateString()}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${offer.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                    {offer.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button onClick={() => handleView(offer)} className="text-indigo-600 hover:text-indigo-900 mr-2">
                    <Eye size={18} />
                  </button>
                  <button onClick={() => handleEdit(offer)} className="text-blue-600 hover:text-blue-900 mr-2">
                    <Edit2 size={18} />
                  </button>
                  <button onClick={() => handleDelete(offer.id)} className="text-red-600 hover:text-red-900 mr-2">
                    <Trash2 size={18} />
                  </button>
                  <button 
                    onClick={() => handleStatusChange(offer.id, offer.status === 'active' ? 'inactive' : 'active')} 
                    className={`${offer.status === 'active' ? 'text-green-600 hover:text-green-900' : 'text-gray-600 hover:text-gray-900'}`}
                  >
                    {offer.status === 'active' ? <EyeOff size={18} /> : <Eye size={18} />}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal 
        isOpen={isAddModalOpen} 
        onClose={() => { setIsAddModalOpen(false); resetNewOffer(); }} 
        title="Add New Offer"
      >
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
            <input
              type="text"
              id="title"
              name="title"
              value={newOffer.title}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-500 focus:ring-opacity-50"
              required
            />
          </div>
          <div>
            <label htmlFor="brand" className="block text-sm font-medium text-gray-700">Brand</label>
            <input
              type="text"
              id="brand"
              name="brand"
              value={newOffer.brand}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-500 focus:ring-opacity-50"
              required
            />
          </div>
          <div>
            <label htmlFor="image" className="block text-sm font-medium text-gray-700">Offer Image</label>
            <input
              type="file"
              id="image"
              name="image"
              onChange={handleImageChange}
              className="mt-1 block w-full"
              accept="image/*"
              required
            />
          </div>
          <div>
            <label htmlFor="redirect_url" className="block text-sm font-medium text-gray-700">Redirect URL</label>
            <input
              type="url"
              id="redirect_url"
              name="redirect_url"
              value={newOffer.redirect_url}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-500 focus:ring-opacity-50"
              required
            />
          </div>
          <div>
            <label htmlFor="valid_until" className="block text-sm font-medium text-gray-700">Valid Until</label>
            <input
              type="date"
              id="valid_until"
              name="valid_until"
              value={newOffer.valid_until}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-500 focus:ring-opacity-50"
              required
            />
          </div>
          <div>
            <label htmlFor="discount" className="block text-sm font-medium text-gray-700">Discount (%)</label>
            <input
              type="number"
              id="discount"
              name="discount"
              value={newOffer.discount}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-500 focus:ring-opacity-50"
              required
            />
          </div>
          <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              id="description"
              name="description"
              value={newOffer.description}
              onChange={handleInputChange}
              rows="3"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-500 focus:ring-opacity-50"
              required
            ></textarea>
          </div>
          <div>
            <label htmlFor="location" className="block text-sm font-medium text-gray-700">Location</label>
            <input
              type="text"
              id="location"
              name="location"
              value={newOffer.location}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-500 focus:ring-opacity-50"
              required
            />
          </div>
          <div>
            <label htmlFor="status" className="block text-sm font-medium text-gray-700">Status</label>
            <select
              id="status"
              name="status"
              value={newOffer.status}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-500 focus:ring-opacity-50"
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
          <div className="flex justify-end">
            <button 
              type="button" 
              onClick={() => setIsAddModalOpen(false)} 
              className="mr-3 px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            >
              Cancel
            </button>
            <button 
              type="submit" 
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            >
              Add Offer
            </button>
          </div>
        </form>
      </Modal>

      <Modal 
        isOpen={isEditModalOpen} 
        onClose={() => { setIsEditModalOpen(false); resetNewOffer(); }} 
        title="Edit Offer"
      >
        <form onSubmit={handleUpdate} className="space-y-6">
          <div>
            <label htmlFor="edit-title" className="block text-sm font-medium text-gray-700">Title</label>
            <input
              type="text"
              id="edit-title"
              name="title"
              value={newOffer.title}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-500 focus:ring-opacity-50"
              required
            />
          </div>
          <div>
            <label htmlFor="edit-brand" className="block text-sm font-medium text-gray-700">Brand</label>
            <input
              type="text"
              id="edit-brand"
              name="brand"
              value={newOffer.brand}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-500 focus:ring-opacity-50"
              required
            />
          </div>
          <div>
            <label htmlFor="edit-image" className="block text-sm font-medium text-gray-700">Offer Image</label>
            <input
              type="file"
              id="edit-image"
              name="image"
              onChange={handleImageChange}
              className="mt-1 block w-full"
              accept="image/*"
            />
          </div>
          <div>
            <label htmlFor="edit-redirect_url" className="block text-sm font-medium text-gray-700">Redirect URL</label>
            <input
              type="url"
              id="edit-redirect_url"
              name="redirect_url"
              value={newOffer.redirect_url}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-500 focus:ring-opacity-50"
              required
            />
          </div>
          <div>
            <label htmlFor="edit-valid_until" className="block text-sm font-medium text-gray-700">Valid Until</label>
            <input
              type="date"
              id="edit-valid_until"
              name="valid_until"
              value={newOffer.valid_until}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-500 focus:ring-opacity-50"
              required
            />
          </div>
          <div>
            <label htmlFor="edit-discount" className="block text-sm font-medium text-gray-700">Discount (%)</label>
            <input
              type="number"
              id="edit-discount"
              name="discount"
              value={newOffer.discount}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-500 focus:ring-opacity-50"
              required
            />
          </div>
          <div>
            <label htmlFor="edit-description" className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              id="edit-description"
              name="description"
              value={newOffer.description}
              onChange={handleInputChange}
              rows="3"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-500 focus:ring-opacity-50"
              required
            ></textarea>
          </div>
          <div>
            <label htmlFor="edit-location" className="block text-sm font-medium text-gray-700">Location</label>
            <input
              type="text"
              id="edit-location"
              name="location"
              value={newOffer.location}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-500 focus:ring-opacity-50"
              required
            />
          </div>
          <div>
            <label htmlFor="edit-status" className="block text-sm font-medium text-gray-700">Status</label>
            <select
              id="edit-status"
              name="status"
              value={newOffer.status}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-500 focus:ring-opacity-50"
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
          <div className="flex justify-end">
            <button 
              type="button" 
              onClick={() => setIsEditModalOpen(false)} 
              className="mr-3 px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            >
              Cancel
            </button>
            <button 
              type="submit" 
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            >
              Update Offer
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        isOpen={isViewModalOpen}
        onClose={() => setIsViewModalOpen(false)}
        title="View Offer"
      >
        {currentOffer && (
          <div className="space-y-4">
            <img src={currentOffer.image_url} alt={currentOffer.title} className="w-full h-48 object-cover rounded-md" />
            <h3 className="text-lg font-semibold">{currentOffer.title}</h3>
            <p><strong>Brand:</strong> {currentOffer.brand}</p>
            <p><strong>Discount:</strong> {currentOffer.discount}%</p>
            <p><strong>Valid Until:</strong> {new Date(currentOffer.valid_until).toLocaleDateString()}</p>
            <p><strong>Description:</strong> {currentOffer.description}</p>
            <p><strong>Location:</strong> {currentOffer.location}</p>
            <p><strong>Status:</strong> {currentOffer.status}</p>
            <a href={currentOffer.redirect_url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline flex items-center">
              Learn More <ExternalLink size={16} className="ml-1" />
            </a>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default OffersManager;