import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { X, CreditCard, Calendar, DollarSign, Check } from 'lucide-react';
import { supabase } from '../utils/supabase';
import axios from 'axios';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SUPABASE_FUNCTION_URL = `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/stripe`;

const ActivityPaymentForm = ({ activity, onSuccess, onCancel, paymentMethod, currentUser }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    setError(null);

    if (paymentMethod === 'stripe') {
      if (!stripe || !elements) {
        setError('Stripe has not loaded. Please try again later.');
        setProcessing(false);
        return;
      }

      const cardElement = elements.getElement(CardElement);

      try {
        const { error, paymentMethod: stripePaymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });

        if (error) throw error;

        const { data } = await axios.post(SUPABASE_FUNCTION_URL, {
          action: 'create-payment-intent',
          amount: Math.round(activity.price * 100),
          currency: 'aud',
          payment_method_id: stripePaymentMethod.id,
          activity_id: activity.id,
          user_id: currentUser.id
        }, {
          headers: { 'Content-Type': 'application/json' },
        });

        if (data.status === 'succeeded') {
          onSuccess('stripe', data.paymentIntentId);
        } else if (data.status === 'requires_action') {
          const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(data.clientSecret);
          if (confirmError) throw confirmError;
          onSuccess('stripe', paymentIntent.id);
        } else {
          throw new Error(`Unexpected payment status: ${data.status}`);
        }
      } catch (err) {
        console.error('Payment processing error:', err);
        setError(`An error occurred: ${err.message}. Please try again.`);
      }
    } else {
      // Handle other payment methods (pay_later, cash) here
      onSuccess(paymentMethod);
    }

    setProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {paymentMethod === 'stripe' && (
        <div className="bg-gray-50 p-4 rounded-md">
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
              hidePostalCode: true, // This line removes the ZIP code field
            }}
          />
        </div>
      )}
      {error && <div className="text-red-500 text-sm">{error}</div>}
      <div className="flex justify-end space-x-2">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
          disabled={processing}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={processing}
          className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600 transition-colors disabled:bg-orange-300"
        >
          {processing ? 'Processing...' : `Confirm ${paymentMethod === 'stripe' ? 'Payment' : 'Reservation'}`}
        </button>
      </div>
    </form>
  );
};

const ActivityPaymentModal = ({ activity, onSuccess, onClose, currentUser }) => {
  const [paymentMethod, setPaymentMethod] = useState(null);

  const handleSuccess = async (method, stripePaymentIntentId = null) => {
    try {
      const { error } = await supabase.from('activity_payments').insert({
        activity_id: activity.id,
        user_id: currentUser.id,
        amount: activity.price,
        payment_method: method,
        payment_status: method === 'stripe' ? 'paid' : 'pending',
        stripe_payment_intent_id: stripePaymentIntentId
      });

      if (error) throw error;

      onSuccess(method);
      onClose();
    } catch (error) {
      console.error('Error recording payment:', error);
    }
  };

  const paymentOptions = [
    { id: 'stripe', name: 'Pay Now with Card', icon: CreditCard },
    { id: 'pay_later', name: 'Pay at the Event', icon: Calendar },
    { id: 'cash', name: 'Pay in Cash', icon: DollarSign },
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Payment for {activity.title}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <div className="mb-4">
          <p className="text-lg font-semibold">Activity Price: ${activity.price}</p>
        </div>
        {!paymentMethod ? (
          <div className="space-y-4">
            <p className="text-gray-600">Please select a payment method:</p>
            {paymentOptions.map((option) => (
              <button
                key={option.id}
                onClick={() => setPaymentMethod(option.id)}
                className="w-full flex items-center justify-between p-4 border rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="flex items-center">
                  <option.icon className="mr-2" size={20} />
                  {option.name}
                </span>
                <span className="text-orange-500">Select</span>
              </button>
            ))}
          </div>
        ) : (
          <Elements stripe={stripePromise}>
            <ActivityPaymentForm
              activity={activity}
              onSuccess={handleSuccess}
              onCancel={() => setPaymentMethod(null)}
              paymentMethod={paymentMethod}
              currentUser={currentUser}
            />
          </Elements>
        )}
      </div>
    </div>
  );
};

export default ActivityPaymentModal;