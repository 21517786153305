import React, { useState } from 'react';
import { X, Save, Trash2, Edit } from 'lucide-react';
import { format } from 'date-fns';

const EditClassModal = ({ classData, onClose, onSave, onDelete, isEditMode, setIsEditMode }) => {
  const [editedClass, setEditedClass] = useState(classData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedClass({ ...editedClass, [name]: value });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    await onSave(editedClass);
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this class? This action cannot be undone.')) {
      await onDelete(editedClass.id);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-lg max-h-[90vh] overflow-y-auto">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-gray-800">
          {isEditMode ? 'Edit Class' : 'Class Details'}
        </h2>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <X size={24} />
        </button>
      </div>
      
      {isEditMode ? (
        <form onSubmit={handleSave} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700" htmlFor="name">Class Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={editedClass.name}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
              required
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700" htmlFor="date">Date</label>
              <input
                type="date"
                id="date"
                name="date"
                value={editedClass.date}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700" htmlFor="start_time">Start Time</label>
              <input
                type="time"
                id="start_time"
                name="start_time"
                value={editedClass.start_time}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                required
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700" htmlFor="end_time">End Time</label>
            <input
              type="time"
              id="end_time"
              name="end_time"
              value={editedClass.end_time}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700" htmlFor="location">Location</label>
            <input
              type="text"
              id="location"
              name="location"
              value={editedClass.location}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
              required
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700" htmlFor="max_participants">Max Participants</label>
              <input
                type="number"
                id="max_participants"
                name="max_participants"
                value={editedClass.max_participants}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700" htmlFor="price">Price</label>
              <input
                type="number"
                id="price"
                name="price"
                value={editedClass.price}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                required
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700" htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              value={editedClass.description}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
              rows="3"
            ></textarea>
          </div>
        </form>
      ) : (
        <div className="space-y-4">
          <p><strong>Name:</strong> {classData.name}</p>
          <p><strong>Date:</strong> {format(new Date(classData.date), 'MMMM d, yyyy')}</p>
          <p><strong>Time:</strong> {classData.start_time} - {classData.end_time}</p>
          <p><strong>Location:</strong> {classData.location}</p>
          <p><strong>Max Participants:</strong> {classData.max_participants}</p>
          <p><strong>Price:</strong> ${classData.price}</p>
          <p><strong>Description:</strong> {classData.description}</p>
        </div>
      )}
      
      <div className="flex justify-between pt-4">
        {isEditMode ? (
          <>
            <button
              type="button"
              onClick={() => setIsEditMode(false)}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            >
              Cancel
            </button>
            <div className="space-x-2">
              <button
                onClick={handleSave}
                className="inline-flex justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              >
                <Save size={18} className="mr-2" />
                Save Changes
              </button>
              <button
                type="button"
                onClick={handleDelete}
                className="inline-flex justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                <Trash2 size={18} className="mr-2" />
                Delete Class
              </button>
            </div>
          </>
        ) : (
          <button
            onClick={() => setIsEditMode(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
          >
            <Edit size={16} className="mr-2 inline" />
            Edit Class
          </button>
        )}
      </div>
    </div>
  );
};

export default EditClassModal;