import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, Calendar, Clock, MapPin, Users, Filter, ChevronDown, ChevronUp, TrendingUp, ArrowLeft } from 'lucide-react';
import { supabase } from '../utils/supabase';
import Pagination from './Pagination';
import './AllEventsPage.css';

const AllEventsPage = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('upcoming');
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [eventsPerPage] = useState(12);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    type: '',
    isFree: null,
    date: '',
    maxDistance: 50,
  });
  const [userLocation, setUserLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userEventAttendance, setUserEventAttendance] = useState({});

  useEffect(() => {
    fetchUserLocation();
    fetchEvents();
    fetchUserEventAttendance();
  }, [activeTab]);

  useEffect(() => {
    filterAndSortEvents();
  }, [events, searchTerm, filters, activeTab, userLocation]);

  const fetchUserLocation = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data, error } = await supabase
        .from('user_profiles')
        .select('latitude, longitude')
        .eq('id', user.id)
        .single();

      if (error) {
        console.error('Error fetching user location:', error);
        setError('Failed to fetch user location');
      } else if (data) {
        setUserLocation({ latitude: data.latitude, longitude: data.longitude });
      }
    }
  };

  const fetchEvents = async () => {
    setLoading(true);
    setError(null);
  
    try {
      const { data, error } = await supabase
        .from('events')
        .select(`
          *,
          host:user_profiles!host_id(id, name, image_url)
        `)
        .order('date', { ascending: true });
  
      if (error) {
        throw error;
      }
  
      console.log(`Fetched ${data.length} total events`);
      setEvents(data);
    } catch (error) {
      console.error('Error fetching events:', error);
      setError('Failed to fetch events: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserEventAttendance = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      try {
        const { data, error } = await supabase
          .from('event_attendees')
          .select('event_id')
          .eq('user_id', user.id);

        if (error) throw error;

        const attendanceMap = {};
        data.forEach(item => {
          attendanceMap[item.event_id] = true;
        });

        setUserEventAttendance(attendanceMap);
      } catch (error) {
        console.error('Error fetching user event attendance:', error);
      }
    }
  };

  const filterAndSortEvents = () => {
    console.log(`Starting filtering with ${events.length} events`);
    const today = new Date().toISOString().split('T')[0];
    
    let filtered = events.filter(event => {
      // Filter by tab
      if (activeTab === 'upcoming' && event.date < today) return false;
      if (activeTab === 'past' && event.date >= today) return false;
      
      // Filter by search term
      if (!event.name.toLowerCase().includes(searchTerm.toLowerCase())) return false;
      
      // Filter by type
      if (filters.type !== '' && event.type !== filters.type) return false;
      
      // Filter by price
      if (filters.isFree !== null && event.is_free !== filters.isFree) return false;
      
      // Filter by date
      if (filters.date !== '' && event.date !== filters.date) return false;
      
      return true;
    });
  
    console.log(`After initial filtering: ${filtered.length} events`);
  
    // Apply distance filter if user location is available
    if (userLocation && filters.maxDistance) {
      filtered = filtered.map(event => ({
        ...event,
        distance: calculateDistance(userLocation.latitude, userLocation.longitude, event.latitude, event.longitude)
      })).filter(event => event.distance <= filters.maxDistance);
  
      console.log(`After distance filtering: ${filtered.length} events`);
    }
  
    // Apply sorting
    if (activeTab === 'trending') {
      filtered.sort((a, b) => b.current_participants - a.current_participants);
    } else {
      filtered.sort((a, b) => new Date(a.date) - new Date(b.date));
    }
  
    console.log(`Final filtered events: ${filtered.length}`);
    setFilteredEvents(filtered);
    setCurrentPage(1);
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  const handleFilterChange = (key, value) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  const resetFilters = () => {
    setFilters({
      type: '',
      isFree: null,
      date: '',
      maxDistance: 50,
    });
    setSearchTerm('');
  };

  const renderEventCard = (event) => {
    const eventDate = new Date(event.date);
    const formattedDate = eventDate.toLocaleDateString('en-GB', {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    });
  
    const [hours, minutes] = event.time.split(':');
    const formattedTime = `${hours}:${minutes}`;
  
    const isUserAttending = userEventAttendance[event.id];
  
    return (
      <div 
        className={`event-card ${event.status === 'canceled' ? 'opacity-75' : ''}`}
        onClick={() => navigate(`/event/${event.id}`)}
      >
        <div className="relative h-48">
          <img
            src={event.image_url || 'https://via.placeholder.com/300x200'}
            alt={event.name}
            className="event-image"
          />
          <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4">
            <h3 className="text-white font-bold text-xl">{event.name}</h3>
          </div>
          {event.status === 'canceled' && (
            <div className="absolute top-0 right-0 bg-red-500 text-white px-2 py-1 text-xs font-bold">
              CANCELED
            </div>
          )}
        </div>
        <div className="event-details">
          <div className="flex justify-between items-center mb-2">
            <span className="event-badge bg-orange-100 text-orange-800">{event.type}</span>
            <span className={`event-badge ${event.is_free ? 'event-badge-free' : 'event-badge-paid'}`}>
              {event.is_free ? 'Free' : `$${event.price}`}
            </span>
          </div>
          <div className="event-info">
            <Calendar size={14} className="text-orange-500" />
            {formattedDate} at {formattedTime}
          </div>
          <div className="event-info">
            <MapPin size={14} className="text-orange-500" /> {event.location}
          </div>
          <div className="event-info">
            <Users size={14} className="text-orange-500" /> {event.current_participants} / {event.max_participants || 'Unlimited'}
          </div>
          {event.host && (
            <div className="flex items-center mb-2">
              <img
                src={event.host.image_url ? `${supabase.storage.from('profile_images').getPublicUrl(event.host.image_url).data.publicUrl}` : 'https://via.placeholder.com/40'}
                alt={event.host.name}
                className="w-8 h-8 rounded-full object-cover mr-2"
              />
              <span className="text-sm text-gray-600">Hosted by {event.host.name}</span>
            </div>
          )}
          {event.distance && (
            <div className="event-info mt-2">
              <MapPin size={14} className="text-orange-500" />
              {event.distance.toFixed(1)} km away
            </div>
          )}
        </div>
        <div className={`event-button ${
          event.status === 'canceled' 
            ? 'bg-red-500 text-white'
            : isUserAttending 
              ? 'bg-green-500 text-white' 
              : 'bg-orange-500 text-white'
        }`}>
          {event.status === 'canceled' 
            ? 'Event Canceled' 
            : isUserAttending 
              ? "You're Attending" 
              : 'View Event Details'
          }
        </div>
      </div>
    );
  };

  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = filteredEvents.slice(indexOfFirstEvent, indexOfLastEvent);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="max-w-7xl mx-auto p-4">
      <div className="flex flex-col md:flex-row justify-between items-center mb-6">
        <button
          onClick={() => navigate('/dashboard')}
          className="text-orange-500 hover:text-orange-600 font-medium flex items-center mb-4 md:mb-0 transition-colors"
        >
          <ArrowLeft size={20} className="mr-2" />
          Back to Dashboard
        </button>
        <h1 className="text-2xl font-bold mb-4 md:mb-0">All Events</h1>
      </div>

      <div className="flex flex-col md:flex-row justify-between items-center mb-6">
        <div className="flex space-x-4 mb-4 md:mb-0">
          <button
            onClick={() => setActiveTab('upcoming')}
            className={`px-4 py-2 rounded-lg ${activeTab === 'upcoming' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          >
            Upcoming
          </button>
          <button
            onClick={() => setActiveTab('past')}
            className={`px-4 py-2 rounded-lg ${activeTab === 'past' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          >
            Past
          </button>
          <button
            onClick={() => setActiveTab('trending')}
            className={`px-4 py-2 rounded-lg ${activeTab === 'trending' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          >
            Trending
          </button>
        </div>
      </div>

      <div className="mb-6 flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4">
        <div className="relative flex-grow">
          <input
            type="text"
            placeholder="Search events..."
            className="w-full p-2 pl-10 border rounded-lg"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
        </div>
        <button
          onClick={() => setShowFilters(!showFilters)}
          className="flex items-center justify-center px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
        >
          <Filter size={20} className="mr-2" />
          {showFilters ? 'Hide Filters' : 'Show Filters'}
        </button>
      </div>

      {showFilters && (
        <div className="mb-6 p-4 bg-gray-100 rounded-lg">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div>
              <label htmlFor="type" className="block text-sm font-medium text-gray-700 mb-1">Event Type</label>
              <select
                id="type"
                value={filters.type}
                onChange={(e) => handleFilterChange('type', e.target.value)}
                className="w-full p-2 border rounded-lg"
              >
                <option value="">All Types</option>
                <option value="Workout">Workout</option>
                <option value="Yoga">Yoga</option>
                <option value="Running">Running</option>
                <option value="Cycling">Cycling</option>
                <option value="Swimming">Swimming</option>
              </select>
            </div>
            <div>
              <label htmlFor="isFree" className="block text-sm font-medium text-gray-700 mb-1">Price</label>
              <select
                id="isFree"
                value={filters.isFree === null ? '' : filters.isFree.toString()}
                onChange={(e) => handleFilterChange('isFree', e.target.value === '' ? null : e.target.value === 'true')}
                className="w-full p-2 border rounded-lg"
              >
                <option value="">All</option>
                <option value="true">Free</option>
                <option value="false">Paid</option>
              </select>
            </div>
            <div>
              <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">Date</label>
              <input
                type="date"
                id="date"
                value={filters.date}
                onChange={(e) => handleFilterChange('date', e.target.value)}
                className="w-full p-2 border rounded-lg"
              />
            </div>
            <div>
              <label htmlFor="maxDistance" className="block text-sm font-medium text-gray-700 mb-1">
                Max Distance: {filters.maxDistance} km
              </label>
              <input
                type="range"
                id="maxDistance"
                min="0"
                max="100"
                value={filters.maxDistance}
                onChange={(e) => handleFilterChange('maxDistance', parseInt(e.target.value))}
                className="w-full"
              />
            </div>
          </div>
          <button
            onClick={resetFilters}
            className="mt-4 px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transition-colors"
          >
            Reset Filters
          </button>
        </div>
      )}
      
      {loading ? (
        <div className="text-center py-8">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-orange-500 mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading events...</p>
        </div>
      ) : error ? (
        <div className="text-center py-8">
          <p className="text-red-500">{error}</p>
        </div>
      ) : filteredEvents.length === 0 ? (
        <div className="text-center py-8">
          <p className="text-gray-600">No events found.</p>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {currentEvents.map((event) => (
              <div key={event.id} className="flex-shrink-0">
                {renderEventCard(event)}
              </div>
            ))}
          </div>
          <div className="mt-8">
            <Pagination
              eventsPerPage={eventsPerPage}
              totalEvents={filteredEvents.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AllEventsPage;