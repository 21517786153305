import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Calendar, BookOpen, Eye, CreditCard, Settings, HelpCircle, LogOut, Utensils, Gift, Calculator, Home, Users, Rss, User, Menu as MenuIcon, Compass, Award, Dumbbell, ChevronDown, ChevronUp, Activity, Lock, MessageCircle, LineChart } from 'lucide-react';
import { signOut, supabase } from '../utils/supabase';
import Navigation from './Navigation';

const NavMenu = ({ setIsAuthenticated, currentUser }) => {
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState(null);
  const [activeTab, setActiveTab] = useState('menu');
  const [expandedCategories, setExpandedCategories] = useState({});

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (currentUser && currentUser.id) {
        const { data, error } = await supabase
          .from('user_profiles')
          .select('name, role, image_url')
          .eq('id', currentUser.id)
          .single();

        if (error) {
          console.error('Error fetching user profile:', error);
        } else {
          setUserProfile(data);
        }
      }
    };

    fetchUserProfile();
  }, [currentUser]);

  const getPublicImageUrl = (path) => {
    if (!path) return currentUser?.user_metadata?.avatar_url || "https://via.placeholder.com/150";
    try {
      const { data } = supabase.storage
        .from('profile_images')
        .getPublicUrl(path);
      return data.publicUrl;
    } catch (error) {
      console.error('Error getting public URL:', error);
      return currentUser?.user_metadata?.avatar_url || "https://via.placeholder.com/150";
    }
  };

  const handleLogout = async () => {
    try {
      await signOut();
      if (typeof setIsAuthenticated === 'function') {
        setIsAuthenticated(false);
      } else {
        console.warn('setIsAuthenticated is not a function. User state may not be updated correctly.');
      }
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error.message);
      alert('Failed to log out. Please try again.');
    }
  };

  const menuCategories = [
    {
      title: 'My Activities',
      icon: <Calendar size={24} />,
      items: [
        { icon: <Calendar size={24} />, label: 'My Events', path: '/my-events' },
        { icon: <BookOpen size={24} />, label: 'My Bookings', path: '/my-bookings' },
        { icon: <Calendar size={24} />, label: 'My Agenda', path: '/my-agenda' },
      ],
    },
    {
      title: 'Nutrition & Fitness',
      icon: <Utensils size={24} />,
      items: [
        { icon: <Utensils size={24} />, label: 'AI Meal Planner', path: '/meal-planner' },
        { icon: <Utensils size={24} />, label: 'AI Meal PlannerPro', path: '/meal-plannerpro' },
        { icon: <Utensils size={24} />, label: 'Daily Calories Tracker', path: '/daily-calories-tracker' },
        { icon: <Calculator size={24} />, label: 'Macro Calculator', path: '/macro-calculator' },
        { icon: <Dumbbell size={24} />, label: 'AI Workout Planner', path: '/workout-planner' },
        { icon: <MessageCircle size={24} />, label: 'FIT Buddy Coach', path: '/fit-buddy-coach' },
        { icon: <LineChart size={20} />, label: 'Weight Tracker', path: '/weight-tracker' }, 
      ],
    },

    {
        title: 'Health Management',
        icon: <Activity size={24} />,
        items: [
          { 
            icon: <Activity size={24} />, 
            label: 'Blood Test Reports', 
            path: '/blood-test-reports',
            locked: true,
            beta: true
          },
          // ... (you can add other health-related items here)
        ],
      },
    {
      title: 'Explore',
      icon: <Compass size={24} />,
      items: [
        { icon: <Gift size={24} />, label: 'Offers', path: '/offers' },
        { icon: <Compass size={24} />, label: 'Explore', path: '/explore' },
        { icon: <Award size={24} />, label: 'LeaderBoard', path: '/leaderboard' },
      ],
    },
    {
      title: 'Account',
      icon: <User size={24} />,
      items: [
        { icon: <CreditCard size={24} />, label: 'Membership', path: '/membership' },
        { icon: <CreditCard size={24} />, label: 'Payment', path: '/payment' },
        { icon: <Settings size={24} />, label: 'Settings', path: '/settings' },
        { icon: <HelpCircle size={24} />, label: 'Help & Feedback', path: '/help-feedback' },
      ],
    },
  ];

  const toggleCategory = (title) => {
    setExpandedCategories(prev => ({
      ...prev,
      [title]: !prev[title]
    }));
  };

  return (
    <div className="fixed inset-0 bg-gray-100 overflow-y-auto h-full w-full z-50">
      <div className="max-w-2xl mx-auto p-4 pb-24">
        {/* User Info */}
        <div className="flex items-center mb-6 bg-white p-4 rounded-lg shadow-sm">
          <img 
            src={getPublicImageUrl(userProfile?.image_url)} 
            alt={userProfile?.name || 'User'} 
            className="w-16 h-16 rounded-full mr-4 border-2 border-orange-400"
          />
          <div>
            <h2 className="text-xl font-semibold">{userProfile?.name || 'User'}</h2>
            <p className="text-sm text-gray-500">{userProfile?.role || 'Buddy'}</p>
          </div>
        </div>

        {/* Menu Categories */}
        <div className="space-y-4 mb-6">
          {menuCategories.map((category, index) => (
            <div key={index} className="bg-white rounded-lg shadow-sm overflow-hidden">
              <button
                onClick={() => toggleCategory(category.title)}
                className="w-full p-4 flex items-center justify-between text-left hover:bg-gray-50 transition-colors duration-200"
              >
                <div className="flex items-center">
                  <span className="text-orange-500 mr-3">{category.icon}</span>
                  <span className="font-medium text-gray-700">{category.title}</span>
                </div>
                {expandedCategories[category.title] ? <ChevronUp size={20} className="text-gray-400" /> : <ChevronDown size={20} className="text-gray-400" />}
              </button>
              {expandedCategories[category.title] && (
                <div className="bg-gray-50 px-4 py-2">
                  {category.items.map((item, itemIndex) => (
                    item.locked ? (
                      <div
                        key={itemIndex}
                        className="flex items-center p-3 opacity-50 cursor-not-allowed rounded-md transition-colors duration-200"
                      >
                        <span className="text-orange-500 mr-3">{item.icon}</span>
                        <span className="flex-grow">{item.label}</span>
                        <Lock size={16} className="text-gray-400 mr-2" />
                        {item.beta && (
                          <span className="bg-blue-500 text-white text-xs px-2 py-1 rounded-full">Beta</span>
                        )}
                      </div>
                    ) : (
                      <Link
                        key={itemIndex}
                        to={item.path}
                        className="flex items-center p-3 hover:bg-gray-100 rounded-md transition-colors duration-200"
                      >
                        <span className="text-orange-500 mr-3">{item.icon}</span>
                        <span>{item.label}</span>
                      </Link>
                    )
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Logout Button */}
        <button
          onClick={handleLogout}
          className="w-full p-4 bg-white text-orange-500 border border-orange-500 rounded-lg mt-4 font-semibold hover:bg-orange-50 transition-colors duration-200 flex items-center justify-center"
        >
          <LogOut size={24} className="mr-2" />
          Log out
        </button>
      </div>

      {/* Navigation for small screens */}
      <div className="md:hidden">
        <Navigation setActiveTab={setActiveTab} />
      </div>
    </div>
  );
};

export default NavMenu;