import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft, Search, ChevronRight, Calendar, User, Tag, Mail } from 'lucide-react';
import { Link, useNavigate, Routes, Route } from 'react-router-dom';
import { supabase } from '../utils/supabase';
import BlogPostFullPage from './BlogPostFullPage';

const BlogPost = ({ post, featured = false }) => {
  const postSlug = encodeURIComponent(post.title.toLowerCase().replace(/\s+/g, '-'));
  
  return (
    <motion.div
      className={`bg-white rounded-lg shadow-md overflow-hidden ${featured ? 'col-span-full' : ''}`}
      whileHover={{ y: -5, boxShadow: '0 10px 30px rgba(0,0,0,0.1)' }}
    >
      {post.image && (
        <div className={`w-full ${featured ? 'h-96' : 'h-48'} overflow-hidden`}>
          <img 
            src={post.image} 
            alt={post.title} 
            className="w-full h-full object-cover object-center"
          />
        </div>
      )}
      <div className="p-6">
        <div className="flex items-center text-sm text-gray-500 mb-2">
          <Calendar size={16} className="mr-2" />
          <span>{new Date(post.date).toLocaleDateString()}</span>
          <User size={16} className="ml-4 mr-2" />
          <span>{post.author}</span>
        </div>
        <h2 className={`font-bold text-gray-900 mb-2 ${featured ? 'text-3xl' : 'text-xl'}`}>{post.title}</h2>
        <p className="text-gray-600 mb-4">{post.excerpt || 'No excerpt available'}</p>
        <div className="flex justify-between items-center">
          <Link to={`/blog/${postSlug}`} className="text-orange-500 font-semibold flex items-center hover:text-orange-600">
            Read More <ChevronRight size={20} />
          </Link>
          {post.category && (
            <span className="text-sm text-gray-500">
              <Tag size={16} className="inline mr-1" />
              {post.category}
            </span>
          )}
        </div>
      </div>
    </motion.div>
  );
};

const BlogPage = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [email, setEmail] = useState('');
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('blog_posts')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      
      if (data) {
        setBlogPosts(data);
      } else {
        setBlogPosts([]);
      }
    } catch (error) {
      console.error('Error fetching blog posts:', error);
      setError('Failed to fetch blog posts');
    } finally {
      setLoading(false);
    }
  };

  const categories = ["Nutrition", "Workout Tips", "Motivation", "Fitness Tech", "Success Stories"];

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle newsletter signup
    console.log("Signed up with email:", email);
    setEmail('');
  };

  const filteredPosts = blogPosts.filter(post =>
    post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    post.author.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (post.category && post.category.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const featuredPost = blogPosts.find(post => post.featured) || (blogPosts.length > 0 ? blogPosts[0] : null);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header */}
      <header className="bg-white shadow-sm sticky top-0 z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <div className="flex items-center">
            <button onClick={() => navigate(-1)} className="mr-4 text-gray-600 hover:text-gray-900">
              <ArrowLeft size={24} />
            </button>
            <h1 className="text-2xl font-bold text-gray-900">FitBuddy Blog</h1>
          </div>
          <Link to="/" className="text-orange-500 hover:text-orange-600 transition-colors">
            Back to Home
          </Link>
        </div>
      </header>

      <Routes>
        <Route path="/" element={
          <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
            {/* Search Bar */}
            <div className="mb-8">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search articles..."
                  className="w-full p-4 pl-12 pr-4 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              </div>
            </div>

            {/* Featured Post */}
            <section className="mb-12">
              <h2 className="text-2xl font-bold mb-6">Featured Post</h2>
              {featuredPost ? (
                <BlogPost post={featuredPost} featured={true} />
              ) : (
                <p>No featured post available</p>
              )}
            </section>

            {/* Main Content */}
            <div className="flex flex-col lg:flex-row gap-8">
              {/* Blog Posts */}
              <motion.section 
                className="lg:w-2/3"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <h2 className="text-2xl font-bold mb-6">Latest Posts</h2>
                {filteredPosts.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    {filteredPosts
                      .filter(post => post.id !== featuredPost?.id)
                      .map(post => (
                        <BlogPost key={post.id} post={post} />
                      ))}
                  </div>
                ) : (
                  <p>No blog posts available</p>
                )}
              </motion.section>

              {/* Sidebar */}
              <aside className="lg:w-1/3">
                {/* Categories */}
                <motion.section 
                  className="bg-white rounded-lg shadow-md p-6 mb-8"
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                >
                  <h3 className="text-xl font-bold mb-4">Categories</h3>
                  <ul>
                    {categories.map((category, index) => (
                      <li key={index} className="mb-2">
                        <Link to={`/category/${category.toLowerCase()}`} className="flex items-center text-gray-600 hover:text-orange-500">
                          <Tag size={16} className="mr-2" />
                          {category}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </motion.section>

                {/* Newsletter Signup */}
                <motion.section 
                  className="bg-orange-100 rounded-lg shadow-md p-6"
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                >
                  <h3 className="text-xl font-bold mb-4">Subscribe to Our Newsletter</h3>
                  <p className="text-gray-600 mb-4">Stay updated with our latest fitness tips and stories!</p>
                  <form onSubmit={handleSubmit}>
                    <div className="flex">
                      <input
                        type="email"
                        placeholder="Enter your email"
                        className="flex-grow p-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <button type="submit" className="bg-orange-500 text-white px-4 py-2 rounded-r-md hover:bg-orange-600 transition-colors">
                        <Mail size={20} />
                      </button>
                    </div>
                  </form>
                </motion.section>
              </aside>
            </div>
          </main>
        } />
        <Route path="/:postSlug" element={<BlogPostFullPage />} />
      </Routes>

      <footer className="bg-gray-800 text-white py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-4 md:mb-0">
              <h3 className="text-xl font-bold">FitBuddy Blog</h3>
              <p className="text-gray-400">Stay fit, stay connected</p>
            </div>
            <div className="flex space-x-4">
              <Link to="/about" className="hover:text-orange-500 transition-colors">About</Link>
              <Link to="/contact" className="hover:text-orange-500 transition-colors">Contact</Link>
              <Link to="/privacy" className="hover:text-orange-500 transition-colors">Privacy Policy</Link>
            </div>
          </div>
          <div className="mt-8 text-center text-gray-400 text-sm">
            &copy; 2024 FitBuddy. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
};

export default BlogPage;