import React from 'react';
import { supabase } from '../utils/supabase';
import { X } from 'lucide-react';
import { User } from 'lucide-react';

const ParticipantModal = ({ participants, onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg w-full max-w-lg p-6 relative">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}
          aria-label="Close"
        >
          <X size={24} />
        </button>
        <h2 className="text-xl font-bold mb-4">Participants</h2>
        <div className="space-y-4">
          {participants.map((participant) => (
            <div key={participant.id} className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                {participant.imageUrl ? (
                  <img
                    className="h-12 w-12 rounded-full object-cover"
                    src={participant.imageUrl}
                    alt={participant.name}
                  />
                ) : (
                  <div className="h-12 w-12 rounded-full bg-gray-300 flex items-center justify-center">
                    <User size={20} className="text-gray-500" />
                  </div>
                )}
              </div>
              <div>
                <p className="text-lg font-semibold">{participant.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ParticipantModal;
