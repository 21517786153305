import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Calendar, Clock, ArrowLeft, UserPlus, User } from 'lucide-react';
import { supabase } from '../utils/supabase';

const MyBookings = () => {
  const navigate = useNavigate();
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchBookings();
  }, []);

  const fetchBookings = async () => {
    try {
      setLoading(true);
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw new Error(`Authentication error: ${userError.message}`);
      if (!user) throw new Error('No authenticated user found');

      // Fetch bookings
      const { data: bookingsData, error: bookingsError } = await supabase
        .from('bookings')
        .select(`
          id,
          date,
          start_time,
          end_time,
          session_type,
          status,
          trainer_id
        `)
        .eq('client_id', user.id)
        .order('date', { ascending: true });

      if (bookingsError) throw new Error(`Supabase bookings query error: ${bookingsError.message}`);
      if (!bookingsData) throw new Error('No bookings data returned from Supabase');

      // Fetch trainer information
      const trainerIds = [...new Set(bookingsData.map(booking => booking.trainer_id))];
      const { data: trainersData, error: trainersError } = await supabase
        .from('user_profiles')
        .select('id, name, bio, image_url, fitness_level')
        .in('id', trainerIds);

      if (trainersError) throw new Error(`Supabase trainers query error: ${trainersError.message}`);

      // Combine booking data with trainer information
      const processedBookings = bookingsData.map(booking => {
        const trainerInfo = trainersData.find(trainer => trainer.id === booking.trainer_id) || {};
        return {
          ...booking,
          trainer_info: trainerInfo
        };
      });

      console.log('Processed bookings:', processedBookings);
      setBookings(processedBookings);
    } catch (error) {
      console.error('Error fetching bookings:', error);
      setError(`Failed to load bookings: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const renderBooking = (booking) => (
    <div key={booking.id} className="bg-white rounded-lg shadow-md p-4 mb-4">
      <div className="flex items-center mb-4">
        {booking.trainer_info.image_url ? (
          <img src={booking.trainer_info.image_url} alt={booking.trainer_info.name} className="w-12 h-12 rounded-full mr-4" />
        ) : (
          <User className="w-12 h-12 rounded-full mr-4 bg-gray-200 p-2" />
        )}
        <div>
          <h3 className="font-semibold text-lg">{booking.trainer_info.name || 'Unknown Trainer'}</h3>
          <p className="text-sm text-gray-600">Fitness Level: {booking.trainer_info.fitness_level || 'N/A'}</p>
        </div>
      </div>
      <p className="text-sm text-gray-600 mb-2">
        <Calendar className="inline-block mr-2" size={16} />
        {new Date(booking.date).toLocaleDateString(undefined, {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })}
      </p>
      <p className="text-sm text-gray-600 mb-2">
        <Clock className="inline-block mr-2" size={16} />
        {booking.start_time.slice(0, 5)} - {booking.end_time.slice(0, 5)}
      </p>
      <p className="text-sm text-gray-600 mb-2">Type: {booking.session_type}</p>
      <p className="text-sm text-gray-600 mb-2">Status: {booking.status}</p>
      {booking.trainer_info.bio && (
        <p className="text-sm text-gray-600 mt-2">Bio: {booking.trainer_info.bio}</p>
      )}
    </div>
  );

  if (loading) {
    return <div className="max-w-4xl mx-auto p-4">Loading...</div>;
  }

  if (error) {
    return (
      <div className="max-w-4xl mx-auto p-4">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      </div>
    );
  }

  const currentDate = new Date();
  const upcomingBookings = bookings.filter(booking => new Date(booking.date) >= currentDate);
  const pastBookings = bookings.filter(booking => new Date(booking.date) < currentDate);

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-2xl font-bold">My Bookings</h1>
        <button onClick={() => navigate('/')} className="text-orange-500 font-medium flex items-center">
          <ArrowLeft size={20} className="mr-1" /> Back to Dashboard
        </button>
      </div>

      {bookings.length === 0 ? (
        <div className="bg-white rounded-lg shadow-md p-6 text-center">
          <UserPlus size={48} className="mx-auto mb-4 text-orange-500" />
          <h2 className="text-xl font-semibold mb-2">No Bookings Yet</h2>
          <p className="text-gray-600 mb-4">
            You haven't made any bookings with a Personal Trainer. Start your fitness journey today!
          </p>
          <button 
            onClick={() => navigate('/all-trainers')} 
            className="bg-orange-500 text-white py-2 px-4 rounded-lg hover:bg-orange-600 transition-colors"
          >
            Find a Trainer
          </button>
        </div>
      ) : (
        <>
          <div className="mb-8">
            <h2 className="text-xl font-semibold mb-4">Upcoming Bookings</h2>
            {upcomingBookings.length > 0 ? (
              upcomingBookings.map(renderBooking)
            ) : (
              <p className="text-gray-600 italic">No upcoming bookings.</p>
            )}
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-4">Past Bookings</h2>
            {pastBookings.length > 0 ? (
              pastBookings.map(renderBooking)
            ) : (
              <p className="text-gray-600 italic">No past bookings.</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MyBookings;