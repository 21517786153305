import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Home, Users, Calendar, User, Rss, Menu as MenuIcon } from 'lucide-react';
import NavMenu from './NavMenu';

const Navigation = ({ setActiveTab, setIsAuthenticated, currentUser }) => {
  const location = useLocation();

  const navItems = [
    { icon: <Home size={20} />, label: 'Home', path: '/dashboard' },
    { icon: <Users size={20} />, label: 'Buddies', path: '/buddies' },
    { icon: <Calendar size={20} />, label: 'Events', path: '/events' },
    { icon: <Rss size={20} />, label: 'Community', path: '/community' },
    { icon: <User size={20} />, label: 'Profile', path: '/profile' },
    { icon: <MenuIcon size={20} />, label: 'Menu', path: '/menu' },
  ];

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-white border-t flex justify-around py-2 px-4 pb-6">
      {navItems.map((item, index) => (
        <Link
          key={index}
          to={item.path}
          className={`flex flex-col items-center w-16 ${
            location.pathname === item.path ? 'text-orange-500' : 'text-gray-500'
          }`}
          onClick={() => setActiveTab(item.path.substring(1))}
        >
          {item.icon}
          <span className="text-xs mt-1">{item.label}</span>
        </Link>
      ))}
    </nav>
  );
};

export default Navigation;