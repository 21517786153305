import React, { useState, useEffect } from 'react';
import { Plus, Edit, Trash } from 'lucide-react';
import { supabase } from '../utils/supabase';

const ManageInvoices = ({ clientId, invoices, onUpdate }) => {
    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentInvoice, setCurrentInvoice] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        getUser();
    }, []);

    const getUser = async () => {
        const { data: { user } } = await supabase.auth.getUser();
        setUser(user);
    };

    const handleAdd = async (newInvoice) => {
        if (!user) return;

        const { data, error } = await supabase
            .from('client_invoices')
            .insert({ ...newInvoice, client_id: clientId })
            .select();

        if (error) {
            console.error('Error adding invoice:', error);
        } else {
            onUpdate();
            setIsAdding(false);
        }
    };

    const handleEdit = async (updatedInvoice) => {
        if (!user) return;

        const { data, error } = await supabase
            .from('client_invoices')
            .update(updatedInvoice)
            .eq('id', updatedInvoice.id)
            .select();

        if (error) {
            console.error('Error updating invoice:', error);
        } else {
            onUpdate();
            setIsEditing(false);
            setCurrentInvoice(null);
        }
    };

    const handleDelete = async (invoiceId) => {
        if (!user) return;

        const { error } = await supabase
            .from('client_invoices')
            .delete()
            .eq('id', invoiceId);

        if (error) {
            console.error('Error deleting invoice:', error);
        } else {
            onUpdate();
        }
    };

    return (
        <div>
            <h3 className="text-xl font-semibold mb-4">Invoices</h3>
            {invoices.map((invoice) => (
                <div key={invoice.id} className="mb-4 p-4 border rounded-lg">
                    <h4 className="font-semibold">Invoice Number: {invoice.invoice_number}</h4>
                    <p>Date: {invoice.date}</p>
                    <p>Amount: ${invoice.amount}</p>
                    <p>Status: {invoice.status}</p>
                    <div className="mt-2">
                        <button 
                            onClick={() => {
                                setCurrentInvoice(invoice);
                                setIsEditing(true);
                            }}
                            className="mr-2 text-blue-500 hover:text-blue-700"
                        >
                            <Edit size={18} />
                        </button>
                        <button 
                            onClick={() => handleDelete(invoice.id)}
                            className="text-red-500 hover:text-red-700"
                        >
                            <Trash size={18} />
                        </button>
                    </div>
                </div>
            ))}
            <button
                onClick={() => setIsAdding(true)}
                className="mt-4 bg-green-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-green-600 transition-colors"
            >
                <Plus size={20} className="mr-2" />
                Add New Invoice
            </button>
            {(isAdding || isEditing) && (
                <InvoiceForm
                    onSubmit={isAdding ? handleAdd : handleEdit}
                    onCancel={() => {
                        setIsAdding(false);
                        setIsEditing(false);
                        setCurrentInvoice(null);
                    }}
                    initialData={currentInvoice}
                />
            )}
        </div>
    );
};

const InvoiceForm = ({ onSubmit, onCancel, initialData }) => {
    const [formData, setFormData] = useState(initialData || {
        invoice_number: '',
        date: new Date().toISOString().split('T')[0],
        amount: '',
        status: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit} className="mt-4 p-4 border rounded-lg">
            <div className="mb-4">
                <label className="block mb-2">Invoice Number:</label>
                <input
                    type="text"
                    name="invoice_number"
                    value={formData.invoice_number}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Date:</label>
                <input
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Amount:</label>
                <input
                    type="number"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    step="0.01"
                    min="0"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Status:</label>
                <select
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                >
                    <option value="">Select Status</option>
                    <option value="Pending">Pending</option>
                    <option value="Paid">Paid</option>
                    <option value="Overdue">Overdue</option>
                    <option value="Cancelled">Cancelled</option>
                </select>
            </div>
            <div className="flex justify-end">
                <button
                    type="button"
                    onClick={onCancel}
                    className="mr-2 px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                >
                    {initialData ? 'Update' : 'Add'} Invoice
                </button>
            </div>
        </form>
    );
};

export default ManageInvoices;