import React, { useState, useEffect } from 'react';
import { supabase } from '../utils/supabase';
import { Check, X, Search } from 'lucide-react';

const TrainerCertificationAdmin = () => {
  const [trainers, setTrainers] = useState([]);
  const [certificationBadge, setCertificationBadge] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchTrainersAndBadge();
  }, []);

  const fetchTrainersAndBadge = async () => {
    setIsLoading(true);
    try {
      // Fetch trainers
      const { data: trainersData, error: trainersError } = await supabase
        .from('user_profiles')
        .select('*')
        .eq('role', 'trainer');

      if (trainersError) throw trainersError;

      // Fetch certification badge
      const { data: badgeData, error: badgeError } = await supabase
        .from('badges')
        .select('*')
        .eq('name', 'Certified Trainer')
        .single();

      if (badgeError) throw badgeError;

      setCertificationBadge(badgeData);

      // Fetch trainer badges
      const { data: trainerBadgesData, error: trainerBadgesError } = await supabase
        .from('trainer_badges')
        .select('*')
        .eq('badge_id', badgeData.id);

      if (trainerBadgesError) throw trainerBadgesError;

      // Combine data
      const combinedData = trainersData.map(trainer => ({
        ...trainer,
        hasCertificationBadge: trainerBadgesData.some(
          badge => badge.trainer_id === trainer.id
        )
      }));

      setTrainers(combinedData);
      setError(null);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const toggleCertification = async (trainerId, currentStatus) => {
    if (!certificationBadge) {
      setError('Certification badge not found. Please try again.');
      return;
    }
  
    try {
      const newCertificationStatus = !currentStatus;
      console.log('Attempting to set certification status to:', newCertificationStatus);
      console.log('Trainer ID:', trainerId);
  
      // Check Supabase client role
      const { data: { user } } = await supabase.auth.getUser();
      console.log('Current user role:', user?.role);
  
      // Log the update parameters
      console.log('Update parameters:', {
        table: 'user_profiles',
        set: { is_certified: newCertificationStatus },
        where: { id: trainerId }
      });
  
      // Execute the update
      const { data: updatedUserProfiles, error: updateError } = await supabase
        .from('user_profiles')
        .update({ is_certified: newCertificationStatus })
        .eq('id', trainerId)
        .select();
  
      if (updateError) {
        console.error('Error updating user_profiles:', updateError);
        throw updateError;
      }
  
      console.log('Update operation result:', updatedUserProfiles);
  
      if (!updatedUserProfiles || updatedUserProfiles.length === 0) {
        console.error('No rows were updated in user_profiles');
        throw new Error('No rows were updated in user_profiles');
      }
  
      const updatedUserProfile = updatedUserProfiles[0];
  
      // Check if the update was successful
      if (updatedUserProfile.is_certified !== newCertificationStatus) {
        console.error('Update operation did not change the is_certified status as expected');
        throw new Error('Failed to update certification status');
      }
  
      // Handle trainer_badges table
      if (newCertificationStatus) {
        // Add badge if certifying
        const { error: insertError } = await supabase
          .from('trainer_badges')
          .insert({ trainer_id: trainerId, badge_id: certificationBadge.id });
  
        if (insertError) throw insertError;
      } else {
        // Remove badge if revoking certification
        const { error: deleteError } = await supabase
          .from('trainer_badges')
          .delete()
          .eq('trainer_id', trainerId)
          .eq('badge_id', certificationBadge.id);
  
        if (deleteError) throw deleteError;
      }
  
      // Update local state
      setTrainers(trainers.map(trainer => 
        trainer.id === trainerId 
          ? { ...updatedUserProfile, hasCertificationBadge: newCertificationStatus }
          : trainer
      ));
  
      console.log('Certification status updated successfully');
      setSuccess(`Trainer ${newCertificationStatus ? 'certified' : 'decertified'} successfully`);
      setError(null);
    } catch (error) {
      console.error('Error updating certification status:', error);
      if (error.message) console.error('Error message:', error.message);
      if (error.details) console.error('Error details:', error.details);
      setError('Failed to update certification status. Please try again.');
      setSuccess(null);
    }
  };

  const filteredTrainers = trainers.filter(trainer =>
    trainer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isLoading) return <div className="text-center p-4">Loading...</div>;

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6">Manage Trainer Certifications</h2>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      {success && <div className="text-green-500 mb-4">{success}</div>}
      <div className="mb-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Search trainers..."
            className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto">
          <thead className="bg-orange-100">
            <tr>
              <th className="px-4 py-2 text-left">Name</th>
              <th className="px-4 py-2 text-left">Certified</th>
              <th className="px-4 py-2 text-left">Has Badge</th>
              <th className="px-4 py-2 text-left">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredTrainers.map(trainer => (
              <tr key={trainer.id} className="border-b">
                <td className="px-4 py-2">{trainer.name}</td>
                <td className="px-4 py-2">
                  {trainer.is_certified ? 
                    <Check className="text-green-500" /> : 
                    <X className="text-red-500" />}
                </td>
                <td className="px-4 py-2">
                  {trainer.hasCertificationBadge ? 
                    <Check className="text-green-500" /> : 
                    <X className="text-red-500" />}
                </td>
                <td className="px-4 py-2">
                  <button 
                    onClick={() => toggleCertification(trainer.id, trainer.is_certified)}
                    className={`px-4 py-2 rounded-full text-white ${
                      trainer.is_certified ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'
                    } transition-colors duration-200`}
                  >
                    {trainer.is_certified ? 'Revoke' : 'Certify'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {filteredTrainers.length === 0 && (
        <div className="text-center text-gray-500 mt-4">
          No trainers found matching your search.
        </div>
      )}
    </div>
  );
};

export default TrainerCertificationAdmin;