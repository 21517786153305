import React, { useState } from 'react';
import { Card } from './ui/card';
import Button from './ui/Button';
import { Check, ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const clientStops = [2, 5, 15, 30, 50, 75, 100, 200, 500, 1000, 1000];

const CustomSlider = ({ value, onChange }) => {
  const percentage = (clientStops.indexOf(value) / (clientStops.length - 1)) * 100;

  return (
    <div className="relative w-full h-12 mb-8">
      <div className="absolute w-full h-2 bg-gray-200 rounded-full">
        <div 
          className="absolute h-full bg-orange-500 rounded-full" 
          style={{ width: `${percentage}%` }}
        />
      </div>
      <input
        type="range"
        min="0"
        max={clientStops.length - 1}
        value={clientStops.indexOf(value)}
        onChange={(e) => onChange(clientStops[e.target.value])}
        className="absolute w-full h-2 opacity-0 cursor-pointer"
      />
      <div className="absolute w-full flex justify-between px-2 mt-4">
        {clientStops.map((stop, index) => (
          <span 
            key={index} 
            className={`text-xs ${index === clientStops.indexOf(value) ? 'text-orange-500 font-bold' : ''}`}
          >
            {stop}{index === clientStops.length - 1 ? '+' : ''}
          </span>
        ))}
      </div>
    </div>
  );
};

const plans = [
  {
    name: 'GROW',
    description: 'Plans that grow as you do.',
    subDescription: 'Great for entrepreneurs and small businesses',
    basePrice: 10,
    maxClients: 2,
    features: [
      'Workout tracking and performance reports',
      'Goals and habits tracking',
      'In-app messaging',
      'Build digital communities with groups',
      'Automated journeys for upsell',
      'In app meal tracking and PDF meal plans',
      'Standardize programming with master workouts and programs',
      'Unlimited Basic Clients'
    ]
  },
  {
    name: 'PRO',
    description: 'Plans that grow as you do.',
    subDescription: 'Great for entrepreneurs and small businesses',
    basePrice: 70,
    maxClients: 30,
    features: [
      'Workout tracking and performance reports',
      'Goals and habits tracking',
      'In-app messaging',
      'Build digital communities with groups',
      'Automated journeys for upsell',
      'In app meal tracking and PDF meal plans',
      'Standardize programming with master workouts and programs',
      'Unlimited Basic Clients'
    ]
  },
  {
    name: 'STUDIO PLUS',
    description: 'Our most powerful features and add-ons bundled into one plan and one price.',
    basePrice: 250,
    maxClients: 500,
    maxLocations: 4,
    features: [
      'Branded mobile app with Studio level customizations',
      'Separate app store listing',
      '4 week 1-on-1 onboarding',
      'Multi-location programming content control',
      'Video Coaching',
      'Advanced Nutrition Coaching',
      'Stripe integration',
      'MINDBODY integration',
      'API access',
      'Access to our Exclusive Support Community'
    ]
  },
  {
    name: 'STUDIO MAX',
    description: 'Our most powerful features and add-ons bundled into one plan and one price.',
    basePrice: 350,
    maxClients: 1000,
    maxLocations: 4,
    features: [
      'Branded mobile app with Studio level customizations',
      'Separate app store listing',
      '4 week 1-on-1 onboarding',
      'Multi-location programming content control',
      'Video Coaching',
      'Advanced Nutrition Coaching',
      'Stripe integration',
      'MINDBODY integration',
      'API access',
      'Access to our Exclusive Support Community'
    ]
  }
];

const PricingPlans = () => {
  const navigate = useNavigate();
  const [clientCount, setClientCount] = useState(2);
  const [billingCycle, setBillingCycle] = useState('monthly');

  const handleSliderChange = (value) => {
    setClientCount(value);
  };

  const getApplicablePlans = () => {
    return plans.filter(plan => clientCount <= plan.maxClients);
  };

  return (
    <div className="container mx-auto px-4">
      <button 
        onClick={() => navigate('/billing')} 
        className="mb-4 text-orange-500 font-medium flex items-center"
      >
        <ArrowLeft size={20} className="mr-1" /> Back to Billing
      </button>

      <h1 className="text-3xl font-bold text-center mb-6">
        Plans designed for every business size and type.
      </h1>
      <p className="text-center mb-6">
        Start by telling us how many clients you'll be working with.
      </p>
      <div className="mb-8">
        <CustomSlider value={clientCount} onChange={handleSliderChange} />
        <div className="flex justify-between mt-2">
          <span>Up to</span>
          <span className="font-bold">{clientCount}{clientCount === 1000 ? '+' : ''} Clients</span>
        </div>
      </div>
      <div className="flex justify-end mb-8">
        <div className="inline-flex rounded-md shadow-sm" role="group">
          <button
            type="button"
            onClick={() => setBillingCycle('monthly')}
            className={`px-4 py-2 text-sm font-medium rounded-l-lg border ${
              billingCycle === 'monthly'
                ? 'bg-orange-500 text-white'
                : 'bg-white text-gray-700 hover:bg-gray-50'
            }`}
          >
            Monthly
          </button>
          <button
            type="button"
            onClick={() => setBillingCycle('annually')}
            className={`px-4 py-2 text-sm font-medium rounded-r-lg border-t border-b border-r ${
              billingCycle === 'annually'
                ? 'bg-orange-500 text-white'
                : 'bg-white text-gray-700 hover:bg-gray-50'
            }`}
          >
            Annually
            <span className="ml-2 bg-yellow-400 text-yellow-800 text-xs font-semibold px-2 py-0.5 rounded">
              SAVE 10%
            </span>
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {getApplicablePlans().map((plan) => (
         <Card key={plan.name} className="flex flex-col">
           <Card.Header>
             <h2 className="text-2xl font-bold">{plan.name}</h2>
             <p className="text-sm text-gray-600">{plan.description}</p>
             <p className="text-xs text-gray-500">{plan.subDescription}</p>
           </Card.Header>
           <Card.Content className="flex-grow">
             <p className="text-4xl font-bold mb-2">
               ${billingCycle === 'annually' 
                 ? Math.round(plan.basePrice * 0.9) 
                 : plan.basePrice}
               <span className="text-sm font-normal">
                 /mo {plan.maxLocations ? `per location` : ''}
               </span>
             </p>
             <p className="text-sm mb-4">
               Up to {plan.maxClients} clients
               {plan.maxLocations && ` • ${plan.maxLocations} locations`}
             </p>
             <ul className="space-y-2">
               {plan.features.map((feature, index) => (
                 <li key={index} className="flex items-start">
                   <Check className="h-5 w-5 text-orange-500 mr-2 flex-shrink-0" />
                   <span className="text-sm">{feature}</span>
                 </li>
               ))}
             </ul>
           </Card.Content>
           <Card.Content>
             <Button className="w-full bg-orange-500 hover:bg-orange-600 text-white">SELECT</Button>
           </Card.Content>
         </Card>
        ))}
      </div>
    </div>
  );
};

export default PricingPlans;