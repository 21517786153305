import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabase';
import { ArrowLeft, User, Clock, CheckCircle, XCircle } from 'lucide-react';

const ViewAssessmentResponses = () => {
  const { formId } = useParams();
  const navigate = useNavigate();
  const [responses, setResponses] = useState([]);
  const [formTitle, setFormTitle] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchFormAndResponses();
  }, [formId]);

  const fetchFormAndResponses = async () => {
    try {
      setLoading(true);
      
      // Fetch the form title
      const { data: formData, error: formError } = await supabase
        .from('initial_assessment_forms')
        .select('title')
        .eq('id', formId)
        .single();

      if (formError) throw formError;
      setFormTitle(formData.title);

      // Fetch responses with user information
      const { data: responseData, error: responseError } = await supabase
        .from('initial_assessment_responses')
        .select(`
          *,
          user:user_id (
            id,
            email
          )
        `)
        .eq('form_id', formId)
        .order('created_at', { ascending: false });

      if (responseError) throw responseError;
      setResponses(responseData);
    } catch (error) {
      console.error('Error fetching form and responses:', error);
    } finally {
      setLoading(false);
    }
  };

  const getStatusIcon = (status) => {
    switch(status) {
      case 'completed':
        return <CheckCircle className="text-green-500" />;
      case 'pending':
        return <Clock className="text-yellow-500" />;
      default:
        return <XCircle className="text-red-500" />;
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-4">
      <button 
        onClick={() => navigate('/assessment-form-manager')} 
        className="mb-4 flex items-center text-orange-500 hover:text-orange-700"
      >
        <ArrowLeft size={20} className="mr-2" />
        Back to Form Manager
      </button>
      <h1 className="text-2xl font-bold mb-2">{formTitle}</h1>
      <h2 className="text-xl font-semibold mb-6">Assessment Responses</h2>
      
      {loading ? (
        <p>Loading responses...</p>
      ) : responses.length > 0 ? (
        <div>
          {responses.map((response) => (
            <div key={response.id} className="bg-white p-4 rounded-lg shadow mb-4">
              <div className="flex justify-between items-center mb-4">
                <div className="flex items-center">
                  <User className="mr-2 text-gray-500" />
                  <h3 className="text-lg font-semibold">
                    {response.user?.email || 'Anonymous User'}
                  </h3>
                </div>
                <div className="flex items-center">
                  {getStatusIcon(response.status)}
                  <span className="ml-2 capitalize">{response.status || 'Unknown'}</span>
                </div>
              </div>
              <p className="text-sm text-gray-600 mb-4">
                Submitted on: {new Date(response.created_at).toLocaleString()}
              </p>
              {response.status === 'completed' ? (
                <div>
                  {Object.entries(response.responses).map(([question, answer]) => (
                    <div key={question} className="mb-2">
                      <p className="font-medium">{question}</p>
                      <p>{answer}</p>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="italic text-gray-500">
                  This assessment has not been completed yet.
                </p>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p>No responses yet for this assessment form.</p>
      )}
    </div>
  );
};

export default ViewAssessmentResponses;