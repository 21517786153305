import React, { useState, useEffect, useRef } from 'react';
import { Upload, Trash2, Eye, AlertCircle, FileText, Check } from 'lucide-react';
import Button from './ui/Button';
import { Card } from './ui/card';
import Input from './ui/Input';
import { supabase } from '../utils/supabase';
import ClaudeChatbot from './ClaudeChatbot';

const BloodTestReportManager = () => {
  const [reports, setReports] = useState([]);
  const [fileUploading, setFileUploading] = useState(false);
  const [extracting, setExtracting] = useState(false);
  const [collectionDate, setCollectionDate] = useState('');
  const [extractedInfo, setExtractedInfo] = useState(null);
  const [error, setError] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchReports();
  }, []);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 5000); // Clear error after 5 seconds

      return () => clearTimeout(timer);
    }
  }, [error]);

  const fetchReports = async () => {
    try {
      console.log('Fetching reports...');
      const { data, error } = await supabase
        .rpc('get_user_blood_test_reports')
        .order('uploaded_at', { ascending: false });

      if (error) {
        console.error('Error fetching reports:', error);
        setError('Failed to fetch reports. Please try again.');
      } else {
        console.log('Fetched reports:', data);
        setReports(data || []); // Ensure we always set an array, even if data is null
      }
    } catch (error) {
      console.error('Unexpected error fetching reports:', error);
      setError('An unexpected error occurred. Please try again.');
    }
  };

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.log('No file selected');
      return;
    }
    if (!collectionDate) {
      setError('Please select a collection date');
      return;
    }

    setFileUploading(true);
    setError(null);

    try {
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError || !user) {
        throw new Error('No authenticated user found');
      }

      const fileName = `${user.id}/${Date.now()}_${file.name}`;
      console.log('Uploading file:', fileName);

      // Convert file to base64
      const base64pdf = await convertFileToBase64(file);
      console.log('File converted to base64');

      // Upload the base64 string instead of the file
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from('blood_test_reports')
        .upload(fileName, base64pdf, {
          contentType: 'application/pdf;base64'
        });

      if (uploadError) {
        console.error('Error uploading file:', uploadError);
        throw uploadError;
      }

      console.log('File uploaded successfully:', uploadData);

      const { data: insertData, error: insertError } = await supabase
        .from('blood_test_reports')
        .insert({
          user_id: user.id,
          file_name: fileName,
          original_name: file.name,
          collection_date: collectionDate,
          status: 'uploaded',
          base64_content: base64pdf  // Store the base64 content in the database
        })
        .select();

      if (insertError) {
        console.error('Error inserting report data:', insertError);
        throw new Error(`Failed to insert report data: ${insertError.message}`);
      }

      console.log('Insert data:', insertData);

      if (!insertData || insertData.length === 0) {
        console.error('Insert operation did not return any data');
        throw new Error('Failed to insert report data into the database');
      }

      // Trigger the extraction process immediately after upload
      try {
        console.log('Invoking process-blood-test-report function with reportId:', insertData[0].id);
        const { data: extractionData, error: extractionError } = await supabase.functions.invoke('process-blood-test-report', {
          body: JSON.stringify({ reportId: insertData[0].id })
        });

        if (extractionError) {
          console.error('Extraction error details:', extractionError);
          throw extractionError;
        }

        console.log('Extraction completed:', extractionData);
      } catch (extractionError) {
        console.error('Detailed extraction error:', extractionError);
        // Instead of throwing, we'll just log the error and continue
        // This allows the file upload to succeed even if extraction fails
      }

      alert('File uploaded successfully! Processing may take a few moments.');
      fetchReports();
    } catch (error) {
      console.error('Detailed error during file upload:', error);
      setError(`Failed to upload file: ${error.message}`);
      // Optionally, you can add more specific error handling here
      if (error.message.includes('base64_content')) {
        setError('There was an issue with the database schema. Please contact support.');
      }
    } finally {
      setFileUploading(false);
      setCollectionDate('');
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleExtract = async (report) => {
    setExtracting(true);
    setError(null);

    try {
      if (report.extracted_data) {
        // Use the stored extracted data if available
        setExtractedInfo(report.extracted_data);
      } else {
        // If not available, trigger the extraction process
        console.log('Sending extraction request for report:', report);
        console.log('Report ID being sent:', report.id);

        // Check if the report still exists in the database
        const { data: existingReport, error: checkError } = await supabase
          .from('blood_test_reports')
          .select('id')
          .eq('id', report.id)
          .single();

        if (checkError || !existingReport) {
          console.error('Report not found in database:', checkError || 'No data returned');
          throw new Error('Report not found in database. It may have been deleted.');
        }

        const { data: { session } } = await supabase.auth.getSession();
        if (!session) {
          throw new Error('No authenticated session found');
        }

        console.log('Session token:', session.access_token);

        const response = await fetch('https://qyveehocptbroumguiyt.supabase.co/functions/v1/process-blood-test-report', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.access_token}`
          },
          body: JSON.stringify({ reportId: report.id })
        });

        console.log('Response status:', response.status);
        const responseText = await response.text();
        console.log('Raw response:', responseText);

        let responseData;
        try {
          responseData = JSON.parse(responseText);
        } catch (parseError) {
          console.error('Error parsing JSON:', parseError);
          throw new Error('Failed to parse server response');
        }

        console.log('Parsed response data:', responseData);

        if (!response.ok) {
          console.error('Error response:', responseData);
          if (response.status === 404) {
            setError('Report not found. It may have been deleted.');
            await fetchReports();
            return;
          } else {
            throw new Error(`HTTP error! status: ${response.status}, message: ${responseData.error || 'Unknown error'}`);
          }
        }

        if (typeof responseData !== 'object' || responseData === null || !responseData.extractedInfo) {
          console.error('Unexpected response format:', responseData);
          throw new Error('Received invalid data from the server');
        }

        setExtractedInfo(responseData.extractedInfo);

        // Add this check
        if (Object.values(responseData.extractedInfo).every(value => value === 'N/A' || (Array.isArray(value) && value.length === 0))) {
          setError('No information could be extracted from the report. Please check the file and try again.');
        }
      }

      await fetchReports();
    } catch (error) {
      console.error('Error during extraction:', error);
      setError(`Failed to process report: ${error.message}`);
    } finally {
      setExtracting(false);
    }
  };

  const handleDelete = async (id, fileName) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this report?');
    if (!confirmDelete) return;

    const { error: deleteStorageError } = await supabase.storage
      .from('blood_test_reports')
      .remove([fileName]);

    if (deleteStorageError) {
      console.error('Error deleting file from storage:', deleteStorageError);
      setError('Failed to delete file from storage. Please try again.');
      return;
    }

    const { error: deleteRecordError } = await supabase
      .from('blood_test_reports')
      .delete()
      .match({ id });

    if (deleteRecordError) {
      console.error('Error deleting record:', deleteRecordError);
      setError('Failed to delete record. Please try again.');
    } else {
      alert('Report deleted successfully!');
      fetchReports();
    }
  };

  const handleViewFile = async (fileName) => {
    const { data, error } = await supabase.storage
      .from('blood_test_reports')
      .createSignedUrl(fileName, 60); // URL valid for 60 seconds

    if (error) {
      console.error('Error creating signed URL:', error);
      setError('Failed to generate file URL. Please try again.');
    } else {
      window.open(data.signedUrl, '_blank');
    }
  };

  const handleSelectReport = (report) => {
    setSelectedReport(report);
    setExtractedInfo(report.extracted_data);
  };

  const handleSendToClaude = async (message, file) => {
    if (!selectedReport && !file) {
      return "Please select a report or upload a file first.";
    }

    try {
      let fileData = null;
      if (file) {
        const reader = new FileReader();
        fileData = await new Promise((resolve) => {
          reader.onload = (e) => resolve({ 
            content: e.target.result.split(',')[1], 
            type: file.type 
          });
          reader.readAsDataURL(file);
        });
      }

      const { data, error } = await supabase.functions.invoke('claude-chat', {
        body: JSON.stringify({
          message: message,
          reportId: selectedReport ? selectedReport.id : null,
          file: fileData
        })
      });

      if (error) throw error;
      return data.response;
    } catch (error) {
      console.error('Error sending message to Claude:', error);
      return "Sorry, I encountered an error while processing your request. Please try again later.";
    }
  };

  // Add this helper function to convert file to base64
  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = error => reject(error);
    });
  };

  return (
    <div className="p-4 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">Blood Test Report Manager</h1>
      
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      
      {/* File Upload Section */}
      <Card className="mb-4 p-4">
        <h2 className="text-xl font-semibold mb-2">Upload Blood Test Report</h2>
        <div className="flex items-center space-x-2 mb-2">
          <Input
            type="date"
            value={collectionDate}
            onChange={(e) => setCollectionDate(e.target.value)}
            placeholder="Collection Date"
          />
        </div>
        <div className="flex items-center space-x-2">
          <input
            ref={fileInputRef}
            type="file"
            accept=".pdf,.jpg,.jpeg"
            onChange={handleFileUpload}
            className="hidden"
            id="file-upload"
          />
          <Button 
            onClick={handleFileUploadClick}
            disabled={fileUploading || !collectionDate}
          >
            <Upload className="mr-2 h-4 w-4" />
            {fileUploading ? 'Uploading...' : 'Upload Report'}
          </Button>
        </div>
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </Card>

      {/* Reports Table */}
      <Card className="mb-4 p-4">
        <h2 className="text-xl font-semibold mb-2">Your Blood Test Reports</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-2 text-left">File Name</th>
                <th className="px-4 py-2 text-left">Uploaded At</th>
                <th className="px-4 py-2 text-left">Collection Date</th>
                <th className="px-4 py-2 text-left">Status</th>
                <th className="px-4 py-2 text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {reports.map((report) => (
                <tr key={report.id} className={`border-t ${selectedReport && selectedReport.id === report.id ? 'bg-blue-100' : ''}`}>
                  <td className="px-4 py-2">{report.original_name}</td>
                  <td className="px-4 py-2">{new Date(report.uploaded_at).toLocaleString()}</td>
                  <td className="px-4 py-2">{new Date(report.collection_date).toLocaleDateString()}</td>
                  <td className="px-4 py-2">{report.status}</td>
                  <td className="px-4 py-2">
                    <Button
                      onClick={() => handleViewFile(report.file_name)}
                      className="mr-2"
                      variant="outline"
                      size="sm"
                    >
                      <Eye className="h-4 w-4" />
                    </Button>
                    <Button
                      onClick={() => handleExtract(report)}
                      className="mr-2"
                      variant="outline"
                      size="sm"
                      disabled={extracting || report.status === 'processing'}
                      >
                      <FileText className="h-4 w-4" />
                    </Button>
                    <Button
                      onClick={() => handleDelete(report.id, report.file_name)}
                      variant="outline"
                      size="sm"
                      className="text-red-500 hover:text-red-700"
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                    <Button
                      onClick={() => handleSelectReport(report)}
                      className="mr-2"
                      variant={selectedReport && selectedReport.id === report.id ? "default" : "outline"}
                      size="sm"
                    >
                      {selectedReport && selectedReport.id === report.id ? <Check className="h-4 w-4 mr-1" /> : null}
                      {selectedReport && selectedReport.id === report.id ? 'Selected' : 'Select'}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>

      {/* Extracted Information Section */}
      {extractedInfo && (
        <Card className="mb-4 p-4">
          <h2 className="text-xl font-semibold mb-2">Extracted Information</h2>
          <div>
            <p><strong>Patient Name:</strong> {extractedInfo.patientName || 'Not available'}</p>
            <p><strong>Test Date:</strong> {extractedInfo.testDate || 'Not available'}</p>
            <h3 className="font-semibold mt-2">Blood Markers:</h3>
            {extractedInfo.bloodMarkers && extractedInfo.bloodMarkers.length > 0 ? (
              <ul>
                {extractedInfo.bloodMarkers.map((marker, index) => (
                  <li key={index}>
                    {marker.name}: {marker.value} {marker.unit} (Normal Range: {marker.normalRange})
                    {marker.status !== 'normal' && (
                      <span className={`ml-2 ${marker.status === 'high' ? 'text-red-500' : 'text-blue-500'}`}>
                        {marker.status.toUpperCase()}
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No blood markers extracted</p>
            )}
            {extractedInfo.abnormalResults && extractedInfo.abnormalResults.length > 0 ? (
              <>
                <h3 className="font-semibold mt-2">Abnormal Results:</h3>
                <ul>
                  {extractedInfo.abnormalResults.map((result, index) => (
                    <li key={index}>
                      {result.name}: {result.value} {result.unit} - {result.status.toUpperCase()}
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <p>No abnormal results found</p>
            )}
          </div>
        </Card>
      )}

      {/* Claude Chatbot Section */}
      <Card className="mb-4 p-4">
        <h2 className="text-xl font-semibold mb-2">AI Assistant</h2>
        {selectedReport ? (
          <ClaudeChatbot onSendMessage={handleSendToClaude} />
        ) : (
          <p className="text-gray-600">Please select a report from the table above to chat with the AI assistant about that report.</p>
        )}
      </Card>
    </div>
  );
};

export default BloodTestReportManager;
                     