import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { X, CreditCard, Calendar, DollarSign } from 'lucide-react';
import { createClient } from '@supabase/supabase-js';
import axios from 'axios';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const SUPABASE_FUNCTION_URL = `${supabaseUrl}/functions/v1/stripe`;

function isValidUUID(uuid) {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
}

const PaymentForm = ({ plan, onSuccess, onCancel, paymentMethod, currentUser }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [country, setCountry] = useState('AU');
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [detailsLoaded, setDetailsLoaded] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (currentUser) {
        console.log('Fetching user details for ID:', currentUser.id);
        try {
          const { data: userData, error: userError } = await supabase
            .from('user_profiles_with_email')
            .select('email, name')
            .eq('id', currentUser.id)
            .single();

          if (userError) {
            console.error('Error fetching user data:', userError);
            throw new Error(`User data fetch error: ${userError.message}`);
          }
          
          if (!userData || !userData.email || !userData.name) {
            throw new Error('User data not found');
          }
          
          setUserEmail(userData.email);
          setUserName(userData.name);
          console.log('User data retrieved:', userData);

          setDetailsLoaded(true);
        } catch (error) {
          console.error('Error in fetchUserDetails:', error);
          setError(`Unable to retrieve user details: ${error.message}. Please try again or contact support.`);
        }
      } else {
        console.error('No current user provided');
        setError('User not authenticated. Please log in and try again.');
      }
    };

    fetchUserDetails();
  }, [currentUser]);

  const createSubscription = async (payload) => {
    try {
      console.log('Sending request to Supabase function:', SUPABASE_FUNCTION_URL);
      console.log('Payload:', payload);
      const { data: { session } } = await supabase.auth.getSession();
      console.log('Session retrieved, access token length:', session.access_token.length);
      
      const response = await axios.post(SUPABASE_FUNCTION_URL, {
        action: 'create-subscription',
        ...payload,
        customer_email: userEmail,
        customer_name: userName,
        user_id: currentUser.id,
        plan_id: plan.id
      }, {
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`
        },
        timeout: 120000
      });
      
      console.log('Received response from Supabase function:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error in createSubscription:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error response data:', error.response.data);
        throw new Error(error.response.data.error || 'An error occurred while processing your subscription.');
      } else {
        throw error;
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    setError(null);

    console.log('Plan received in PaymentForm:', plan);

    if (!currentUser || !currentUser.id) {
      setError('User information is missing. Please try logging in again.');
      setProcessing(false);
      return;
    }

    if (!detailsLoaded) {
      setError('User details not fully loaded. Please wait or refresh the page.');
      setProcessing(false);
      return;
    }

    if (!userEmail || !userName || !currentUser.id) {
      setError('Unable to retrieve user details. Please try again or contact support.');
      setProcessing(false);
      return;
    }

    if (!isValidUUID(plan.id)) {
      setError('Invalid plan ID. Please try again or contact support.');
      setProcessing(false);
      return;
    }

    if (paymentMethod === 'stripe') {
      if (!stripe || !elements) {
        setError('Stripe has not loaded. Please try again later.');
        setProcessing(false);
        return;
      }

      const cardElement = elements.getElement(CardElement);

      try {
        console.log('Creating payment method...');
        const { error, paymentMethod: stripePaymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            address: {
              country: country,
            },
          },
        });

        if (error) {
          throw error;
        }

        console.log('Payment method created successfully:', stripePaymentMethod.id);

        console.log('Sending request to create subscription...');
        const data = await createSubscription({
          payment_method_id: stripePaymentMethod.id,
          plan_id: plan.id,
        });

        console.log('Received response from create subscription:', data);

        if (data.status === 'active') {
          console.log('Subscription created successfully');
          onSuccess('stripe', data.subscriptionId, stripePaymentMethod.id);
        } else if (data.status === 'incomplete') {
          console.log('Subscription requires additional action');
          const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(data.clientSecret);
          if (confirmError) {
            throw confirmError;
          }
          console.log('Subscription confirmed after additional action');
          onSuccess('stripe', data.subscriptionId, stripePaymentMethod.id);
        } else {
          throw new Error(`Unexpected subscription status: ${data.status}`);
        }
      } catch (err) {
        console.error('Subscription processing error:', err);
        setError(`An error occurred: ${err.message}. Please try again or contact support if the problem persists.`);
      }
    } else {
      try {
        const data = await createSubscription({
          payment_method: paymentMethod,
          plan_id: plan.id,
        });
        onSuccess(paymentMethod, data.subscriptionId);
      } catch (err) {
        console.error('Subscription processing error:', err);
        setError(`An error occurred: ${err.message}. Please try again or contact support if the problem persists.`);
      }
    }

    setProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="mb-4 text-lg font-semibold">
        Total Amount: ${plan.price} / month
      </div>
      {paymentMethod === 'stripe' && (
        <>
          <div className="mb-4">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
              Country
            </label>
            <select
              id="country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
            >
              <option value="AU">Australia</option>
              <option value="US">United States</option>
              {/* Add more countries as needed */}
            </select>
          </div>
          <div className="bg-gray-50 p-4 rounded-md">
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
                hidePostalCode: country === 'AU',
              }}
            />
          </div>
        </>
      )}
      {error && <div className="text-red-500 text-sm">{error}</div>}
      <div className="flex justify-end space-x-2">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
          disabled={processing}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={processing}
          className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600 transition-colors disabled:bg-orange-300"
        >
          {processing ? 'Processing...' : `Confirm ${paymentMethod === 'stripe' ? 'Payment' : 'Upgrade'}`}
        </button>
      </div>
    </form>
  );
};

const MembershipPaymentModal = ({ plan, onSuccess, onClose, currentUser }) => {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log('CurrentUser in MembershipPaymentModal:', currentUser);
    console.log('Plan received in MembershipPaymentModal:', plan);
    setIsLoading(false);
  }, [currentUser, plan]);

  const handleSuccess = (method, subscriptionId, paymentMethodId) => {
    onSuccess(method, subscriptionId, paymentMethodId);
    onClose();
  };

  const paymentOptions = [
    { id: 'stripe', name: 'Pay Now with Card', icon: CreditCard },
    { id: 'pay_later', name: 'Pay Later', icon: Calendar },
    { id: 'bank_transfer', name: 'Bank Transfer', icon: DollarSign },
  ];

  if (isLoading) {
    return <div>Loading user data...</div>;
  }

  if (!currentUser) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 max-w-md w-full">
          <h2 className="text-xl font-bold mb-4">Authentication Required</h2>
          <p>Please log in to proceed with the payment.</p>
          <button onClick={onClose} className="mt-4 px-4 py-2 bg-orange-500 text-white rounded">
            Close
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Upgrade to {plan.name}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <div className="mb-4">
          <p className="text-lg font-semibold">Plan Price: ${plan.price} / month</p>
        </div>
        {!paymentMethod ? (
          <div className="space-y-4">
            <p className="text-gray-600">Please select a payment method:</p>
            {paymentOptions.map((option) => (
              <button
                key={option.id}
                onClick={() => setPaymentMethod(option.id)}
                className="w-full flex items-center justify-between p-4 border rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="flex items-center">
                  <option.icon className="mr-2" size={20} />
                  {option.name}
                </span>
                <span className="text-orange-500">Select</span>
              </button>
            ))}
          </div>
        ) : (
          <Elements stripe={stripePromise}>
            <PaymentForm
              plan={plan}
              onSuccess={handleSuccess}
              onCancel={() => setPaymentMethod(null)}
              paymentMethod={paymentMethod}
              currentUser={currentUser}
            />
          </Elements>
        )}
      </div>
    </div>
  );
};

export default MembershipPaymentModal;