import React, { useState } from 'react';
import { Send, Upload } from 'lucide-react';
import Button from './ui/Button';
import Input from './ui/Input';
import { Card } from './ui/card';

const ClaudeChatbot = ({ onSendMessage }) => {
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [file, setFile] = useState(null);

  const handleSend = async () => {
    if (message.trim() === '' && !file) return;

    // Add user message to chat history
    setChatHistory(prev => [...prev, { role: 'user', content: message, file: file ? file.name : null }]);

    // Send message and file to Claude and get response
    const response = await onSendMessage(message, file);

    // Add Claude's response to chat history
    setChatHistory(prev => [...prev, { role: 'assistant', content: response }]);

    // Clear input and file
    setMessage('');
    setFile(null);
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  return (
    <Card className="p-4">
      <h2 className="text-xl font-semibold mb-4">Claude Assistant</h2>
      <div className="h-64 overflow-y-auto mb-4 border rounded p-2">
        {chatHistory.map((msg, index) => (
          <div key={index} className={`mb-2 ${msg.role === 'user' ? 'text-right' : 'text-left'}`}>
            <span className={`inline-block p-2 rounded ${msg.role === 'user' ? 'bg-blue-100' : 'bg-gray-100'}`}>
              {msg.content}
              {msg.file && <div className="text-xs text-gray-500">Uploaded: {msg.file}</div>}
            </span>
          </div>
        ))}
      </div>
      <div className="flex flex-col space-y-2">
        <Input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Ask Claude about your blood test report..."
          className="flex-grow"
        />
        <div className="flex space-x-2">
          <Input
            type="file"
            onChange={handleFileChange}
            className="flex-grow"
          />
          <Button onClick={handleSend}>
            <Send className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default ClaudeChatbot;