import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Users, ArrowLeft, Settings, Edit, Trash2, X, MessageSquare, Calendar, Info, ThumbsUp, Share2, ChevronDown, ChevronUp, Image, Smile, ChevronLeft, ChevronRight, UserPlus, UserMinus } from 'lucide-react';
import { supabase } from '../utils/supabase';
import EmojiPicker from 'emoji-picker-react';
import { motion, AnimatePresence } from 'framer-motion';
import GroupMembers from './GroupMembers'; 
import GroupActivities from './GroupActivities'; 

const GroupView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('Feed');
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [posts, setPosts] = useState([]);
  const [newPostContent, setNewPostContent] = useState('');
  const [newPostImages, setNewPostImages] = useState([]);
  const [expandedComments, setExpandedComments] = useState({});
  const [showEmojiPicker, setShowEmojiPicker] = useState({});
  const [isNewPostModalOpen, setIsNewPostModalOpen] = useState(false);
  const [editingPost, setEditingPost] = useState(null);
  const [editPostContent, setEditPostContent] = useState('');
  const [editPostImages, setEditPostImages] = useState([]);
  const [replyingTo, setReplyingTo] = useState(null);
  const [editingComment, setEditingComment] = useState(null);
  const [editCommentContent, setEditCommentContent] = useState('');
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [selectedPostImages, setSelectedPostImages] = useState(null);
  const [isMember, setIsMember] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  useEffect(() => {
    fetchGroupDetails();
    fetchCurrentUser();
  }, [id]);

  useEffect(() => {
    if (currentUser) {
      fetchGroupPosts();
      checkMembership();
    }
  }, [currentUser, id]);
  

  const refreshGroupData = async () => {
    await Promise.all([
      fetchGroupDetails(),
      fetchGroupPosts(),
      checkMembership(),
    ]);
  };
  

  const fetchGroupDetails = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('groups')
        .select(`
          *,
          members:group_members(count)
        `)
        .eq('id', id)
        .single();
  
      if (error) throw error;
  
      setGroup({
        ...data,
        memberCount: data.members[0].count
      });
  
      // Check if the current user is a member
      if (currentUser) {
        const { data: memberData, error: memberError } = await supabase
          .from('group_members')
          .select('user_id')
          .eq('group_id', id)
          .eq('user_id', currentUser.id)
          .maybeSingle();
  
        if (memberError) {
          console.error('Error checking membership:', memberError);
        } else {
          console.log('Membership check result:', memberData);
          setIsMember(!!memberData);
        }
      }
    } catch (error) {
      console.error('Error fetching group details:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCurrentUser = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data: profile, error } = await supabase
        .from('user_profiles')
        .select('*')
        .eq('id', user.id)
        .single();
  
      if (error) {
        console.error('Error fetching user profile:', error);
      } else {
        setCurrentUser({ ...user, ...profile });
      }
    }
  };

  const checkMembership = async () => {
    if (!currentUser || !id) return;
  
    try {
      const { data, error } = await supabase
        .from('group_members')
        .select('user_id')  // Select user_id instead of id
        .eq('group_id', id)
        .eq('user_id', currentUser.id)
        .maybeSingle();
  
      if (error) {
        console.error('Error checking membership:', error);
      } else {
        setIsMember(!!data);
      }
    } catch (error) {
      console.error('Error checking membership:', error);
    }
  };

  const handleJoinGroup = async () => {
    if (!currentUser || !id) return;
  
    try {
      const { data, error } = await supabase
        .from('group_members')
        .insert({
          group_id: id,
          user_id: currentUser.id,
          role: 'member',
          joined_at: new Date().toISOString()
        });
  
      if (error) throw error;
  
      await refreshGroupData();
    } catch (error) {
      console.error('Error joining group:', error);
    }
  };
  
  const handleLeaveGroup = async () => {
    if (!currentUser || !id) return;
  
    if (window.confirm('Are you sure you want to leave this group?')) {
      try {
        const { data, error } = await supabase
          .from('group_members')
          .delete()
          .eq('group_id', id)
          .eq('user_id', currentUser.id);
  
        if (error) throw error;
  
        await refreshGroupData();
        alert('You have successfully left the group');
      } catch (error) {
        console.error('Error leaving group:', error);
        alert('An error occurred while leaving the group. Please try again.');
      }
    }
  };

  const fetchGroupPosts = async () => {
    if (!currentUser) return;
  
    try {
      const { data: postsData, error: postsError } = await supabase
        .from('group_feed_posts')
        .select(`
          id,
          content,
          created_at,
          image_urls,
          user_id,
          comments:group_post_comments(id, content, created_at, user_id, parent_comment_id),
          likes:group_post_likes(user_id)
        `)
        .eq('group_id', id)
        .order('created_at', { ascending: false });
  
      if (postsError) throw postsError;
  
      const userIds = new Set([
        ...postsData.map(post => post.user_id),
        ...postsData.flatMap(post => post.comments.map(comment => comment.user_id)),
        ...postsData.flatMap(post => post.likes.map(like => like.user_id))
      ]);
  
      const { data: userData, error: userError } = await supabase
        .from('user_profiles')
        .select('id, name, image_url')
        .in('id', Array.from(userIds));
  
      if (userError) throw userError;
  
      const userMap = Object.fromEntries(userData.map(user => [user.id, user]));
  
      const postsWithDetails = postsData.map(post => ({
        ...post,
        user: userMap[post.user_id] || { name: 'Unknown User', image_url: null },
        likes_count: post.likes.length,
        is_liked: post.likes.some(like => like.user_id === currentUser.id),
        comments: post.comments.map(comment => ({
          ...comment,
          user: userMap[comment.user_id] || { name: 'Unknown User', image_url: null }
        }))
      }));
  
      setPosts(postsWithDetails);
    } catch (error) {
      console.error('Error fetching group posts:', error);
    }
  };

  const handleComment = async (postId, content, parentCommentId = null) => {
    try {
      const newComment = {
        post_id: postId,
        user_id: currentUser.id,
        content,
        parent_comment_id: parentCommentId
      };
  
      const { data, error } = await supabase
        .from('group_post_comments')
        .insert(newComment)
        .select()
        .single();
  
      if (error) throw error;
  
      const commentWithUser = {
        ...data,
        user: {
          id: currentUser.id,
          name: currentUser.user_metadata.full_name,
          image_url: currentUser.user_metadata.avatar_url
        }
      };
  
      setPosts(posts.map(post => 
        post.id === postId 
          ? { ...post, comments: [...post.comments, commentWithUser] } 
          : post
      ));
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const toggleComments = (postId) => {
    setExpandedComments(prev => ({
      ...prev,
      [postId]: !prev[postId]
    }));
  };

  const handleImageUpload = (event, isEditing = false) => {
    const files = Array.from(event.target.files);
    if (isEditing) {
      setEditPostImages([...editPostImages, ...files]);
    } else {
      setNewPostImages([...newPostImages, ...files]);
    }
  };

  const handleRemoveImage = (index, isEditing = false) => {
    if (isEditing) {
      setEditPostImages(editPostImages.filter((_, i) => i !== index));
    } else {
      setNewPostImages(newPostImages.filter((_, i) => i !== index));
    }
  };

  const handleEmojiClick = (postId, emojiObject) => {
    const textarea = document.querySelector(`#comment-textarea-${postId}`);
    if (textarea) {
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const text = textarea.value;
      const before = text.substring(0, start);
      const after = text.substring(end, text.length);
      textarea.value = before + emojiObject.emoji + after;
      textarea.selectionStart = textarea.selectionEnd = start + emojiObject.emoji.length;
      textarea.focus();
    }
    setShowEmojiPicker({ ...showEmojiPicker, [postId]: false });
  };

  const handleEditPost = (postId) => {
    const post = posts.find(p => p.id === postId);
    if (post) {
      setEditingPost(post);
      setEditPostContent(post.content);
      setEditPostImages(post.image_urls || []);
    }
  };

  const handleSaveEditPost = async () => {
    if (!editingPost) return;

    try {
      let image_urls = [...editPostImages];
      for (let i = 0; i < image_urls.length; i++) {
        if (image_urls[i] instanceof File) {
          const fileName = `${Date.now()}_${image_urls[i].name}`;
          const filePath = `${group.id}/${fileName}`;
          const { error: uploadError } = await supabase.storage
            .from('group_post_images')
            .upload(filePath, image_urls[i]);

          if (uploadError) throw uploadError;
          
          const { data: publicUrlData } = supabase.storage
            .from('group_post_images')
            .getPublicUrl(filePath);
          
          if (publicUrlData && publicUrlData.publicUrl) {
            image_urls[i] = publicUrlData.publicUrl;
          } else {
            console.error('Failed to get public URL for uploaded image');
          }
        }
      }

      const { data, error } = await supabase
        .from('group_feed_posts')
        .update({ content: editPostContent, image_urls: image_urls })
        .eq('id', editingPost.id)
        .select()
        .single();

      if (error) throw error;

      setPosts(posts.map(post => 
        post.id === editingPost.id ? { ...post, content: editPostContent, image_urls: image_urls } : post
      ));
      setEditingPost(null);
      setEditPostContent('');
      setEditPostImages([]);
    } catch (error) {
      console.error('Error updating post:', error);
    }
  };

  const handleCancelEditPost = () => {
    setEditingPost(null);
    setEditPostContent('');
    setEditPostImages([]);
  };

  const handleDeletePost = async (postId) => {
    try {
      const { error } = await supabase
        .from('group_feed_posts')
        .delete()
        .eq('id', postId);

      if (error) throw error;

      setPosts(posts.filter(post => post.id !== postId));
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const handleReply = (postId, commentId, userName) => {
    setReplyingTo({ postId, commentId, userName });
    const textarea = document.querySelector(`#comment-textarea-${postId}`);
    if (textarea) {
      textarea.focus();
    }
  };

  const handleEditComment = (comment) => {
    setEditingComment(comment);
    setEditCommentContent(comment.content);
  };

  const handleSaveEditComment = async (postId) => {
    if (!editingComment) return;

    try {
      const { data, error } = await supabase
        .from('group_post_comments')
        .update({ content: editCommentContent })
        .eq('id', editingComment.id)
        .select()
        .single();

      if (error) throw error;

      setPosts(posts.map(post => 
        post.id === postId 
          ? { ...post, comments: post.comments.map(comment => 
              comment.id === editingComment.id ? { ...comment, content: editCommentContent } : comment
            )}
          : post
      ));
      setEditingComment(null);
      setEditCommentContent('');
    } catch (error) {
      console.error('Error updating comment:', error);
    }
  };

  const handleDeleteComment = async (postId, commentId) => {
    try {
      const { error } = await supabase
        .from('group_post_comments')
        .delete()
        .eq('id', commentId);

      if (error) throw error;

      setPosts(posts.map(post => 
        post.id === postId
          ? { ...post, comments: post.comments.filter(comment => comment.id !== commentId) }
          : post
      ));
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  const handleEditGroup = () => {
    console.log('Edit group');
    setIsSettingsModalOpen(false);
  };

  const handleDeleteGroup = async () => {
    if (window.confirm('Are you sure you want to delete this group? This action cannot be undone.')) {
      try {
        setIsSettingsModalOpen(false);
        
        const relatedTables = ['group_members', 'group_feed_posts', 'group_post_comments', 'group_post_likes'];
        
        for (const table of relatedTables) {
          const { error } = await supabase
            .from(table)
            .delete()
            .eq('group_id', id);

          if (error) {
            console.error(`Error deleting from ${table}:`, error);
          }
        }

        const { error: groupError } = await supabase
          .from('groups')
          .delete()
          .eq('id', id);

        if (groupError) throw groupError;

        alert('Group deleted successfully');
        navigate('/community');
      } catch (error) {
        console.error('Error deleting group:', error);
        alert(`Failed to delete group: ${error.message}. Please try again.`);
      }
    }
  };

  const handleImageClick = (postImages, index) => {
    setSelectedPostImages(postImages);
    setSelectedImageIndex(index);
  };

  const handleLike = async (postId) => {
    try {
      const post = posts.find(p => p.id === postId);
      if (post.is_liked) {
        const { error } = await supabase
          .from('group_post_likes')
          .delete()
          .eq('user_id', currentUser.id)
          .eq('post_id', postId);
  
        if (error) throw error;
    } else {
        const { error } = await supabase
          .from('group_post_likes')
          .insert({
            user_id: currentUser.id,
            post_id: postId
          });
  
        if (error) throw error;
      }
  
      setPosts(posts.map(post => 
        post.id === postId 
          ? { 
              ...post, 
              likes_count: post.is_liked ? post.likes_count - 1 : post.likes_count + 1,
              is_liked: !post.is_liked,
              likes: post.is_liked 
                ? post.likes.filter(like => like.user_id !== currentUser.id)
                : [...post.likes, { user_id: currentUser.id }]
            }
          : post
      ));
    } catch (error) {
      console.error('Error liking/unliking post:', error);
    }
  };

  const handleNewPost = async () => {
    if (newPostContent.trim() === '' && newPostImages.length === 0) return;
  
    try {
      let image_urls = [];
      if (newPostImages.length > 0) {
        for (const image of newPostImages) {
          const fileName = `${Date.now()}_${image.name}`;
          const filePath = `${group.id}/${fileName}`;
          
          const { error: uploadError } = await supabase.storage
            .from('post_images')
            .upload(filePath, image);
  
          if (uploadError) throw uploadError;
          
          const { data: publicUrlData } = supabase.storage
            .from('post_images')
            .getPublicUrl(filePath);
          
          if (publicUrlData && publicUrlData.publicUrl) {
            image_urls.push(publicUrlData.publicUrl);
          } else {
            console.error('Failed to get public URL for uploaded image');
          }
        }
      }
  
      const { data, error } = await supabase
        .from('group_feed_posts')
        .insert({
          group_id: group.id,
          user_id: currentUser.id,
          content: newPostContent,
          image_urls: image_urls,
        })
        .select()
        .single();
  
      if (error) throw error;
  
      const newPost = {
        ...data,
        user: {
          id: currentUser.id,
          name: currentUser.user_metadata.full_name,
          image_url: currentUser.user_metadata.avatar_url
        },
        likes_count: 0,
        is_liked: false,
        comments: []
      };
  
      setPosts([newPost, ...posts]);
      setNewPostContent('');
      setNewPostImages([]);
      setIsNewPostModalOpen(false);
    } catch (error) {
      console.error('Error creating new post:', error);
    }
  };

  const renderComments = (comments, postId, parentCommentId = null, depth = 0) => {
    const filteredComments = comments.filter(comment => comment.parent_comment_id === parentCommentId);
    
    return filteredComments.map((comment) => (
      <div key={comment.id} className={`flex items-start mb-2 ${depth > 0 ? 'ml-8' : ''}`}>
        <img
          src={comment.user.image_url 
            ? `${supabase.storage.from('profile_images').getPublicUrl(comment.user.image_url).data.publicUrl}`
            : 'https://via.placeholder.com/30'}
          alt={comment.user.name}
          className="w-8 h-8 rounded-full mr-2"
        />
        <div className="flex-grow">
          <div className="bg-gray-100 rounded-lg p-2">
            <p className="font-semibold text-sm">{comment.user.name}</p>
            <p className="text-sm whitespace-pre-wrap">{comment.content}</p>
          </div>
          <div className="mt-1 text-xs text-gray-500 flex items-center">
            <button 
              onClick={() => handleReply(postId, comment.id, comment.user.name)} 
              className="mr-2 hover:text-blue-500"
            >
              Reply
            </button>
            {comment.user_id === currentUser.id && (
              <>
                <button 
                  onClick={() => handleEditComment(comment)} 
                  className="mr-2 hover:text-blue-500"
                >
                  Edit
                </button>
                <button 
                  onClick={() => handleDeleteComment(postId, comment.id)} 
                  className="text-red-500 hover:text-red-700"
                >
                  Delete
                </button>
              </>
            )}
          </div>
          {renderComments(comments, postId, comment.id, depth + 1)}
        </div>
      </div>
    ));
  };

  const renderPost = (post) => (
    <div key={post.id} className="bg-white rounded-lg shadow p-4 mb-4">
      <div className="flex items-center mb-4 justify-between">
        <div className="flex items-center">
          <img
            src={post.user.image_url ? `${supabase.storage.from('profile_images').getPublicUrl(post.user.image_url).data.publicUrl}` : 'https://via.placeholder.com/40'}
            alt={post.user.name}
            className="w-10 h-10 rounded-full mr-4"
          />
          <div>
            <h3 className="font-semibold">{post.user.name}</h3>
            <p className="text-gray-500 text-sm">{new Date(post.created_at).toLocaleString()}</p>
          </div>
        </div>
        {post.user.id === currentUser.id && (
          <div className="flex space-x-2">
            <button 
              onClick={() => handleEditPost(post.id)} 
              className="text-blue-500 hover:text-blue-700"
            >
              <Edit size={20} />
            </button>
            <button 
              onClick={() => {
                if (window.confirm('Are you sure you want to delete this post?')) {
                  handleDeletePost(post.id);
                }
              }} 
              className="text-red-500 hover:text-red-700"
            >
              <Trash2 size={20} />
            </button>
          </div>
        )}
      </div>
      {editingPost && editingPost.id === post.id ? (
        <div>
          <textarea
            value={editPostContent}
            onChange={(e) => setEditPostContent(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-orange-500 mb-2"
            rows="3"
          />
          <div className="flex flex-wrap mb-2">
            {editPostImages.map((image, index) => (
              <div key={index} className="relative mr-2 mb-2">
                <img
                  src={image instanceof File ? URL.createObjectURL(image) : image}
                  alt={`Post image ${index + 1}`}
                  className="w-20 h-20 object-cover rounded-lg"
                />
                <button
                  onClick={() => handleRemoveImage(index, true)}
                  className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
                >
                  <X size={12} />
                </button>
              </div>
            ))}
          </div>
          <div className="flex justify-between">
            <div>
              <label htmlFor={`edit-post-image-${post.id}`} className="cursor-pointer text-blue-500 hover:text-blue-700">
                <Image size={20} className="inline mr-1" />
                Add Image
              </label>
              <input
                type="file"
                id={`edit-post-image-${post.id}`}
                accept="image/*"
                onChange={(e) => handleImageUpload(e, true)}
                className="hidden"
                multiple
              />
            </div>
            <div>
              <button onClick={handleSaveEditPost} className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mr-2">
                Save
              </button>
              <button onClick={handleCancelEditPost} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-400 transition-colors duration-300">
                Cancel
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <p className="mb-4">{post.content}</p>
          {post.image_urls && post.image_urls.length > 0 && (
            <div className={`mb-4 ${post.image_urls.length === 1 ? 'w-full' : 'grid gap-2'} ${
              post.image_urls.length === 2 ? 'grid-cols-2' :
              post.image_urls.length === 3 ? 'grid-cols-2' :
              post.image_urls.length >= 4 ? 'grid-cols-2' : ''
            }`}>
              {post.image_urls.map((url, index) => (
                <div key={index} 
                  className={`${
                    post.image_urls.length === 1 ? 'w-full h-auto max-h-96' :
                    post.image_urls.length === 2 ? 'w-full h-48' :
                    post.image_urls.length === 3 && index === 0 ? 'w-full h-48 col-span-2' :
                    post.image_urls.length === 3 && index > 0 ? 'w-full h-48' :
                    'w-full h-48'
                  } overflow-hidden rounded-lg`}
                >
                  <img 
                    src={url && typeof url === 'string' ? url : 'https://via.placeholder.com/150'}
                    alt={`Post image ${index + 1}`} 
                    className="w-full h-full object-cover cursor-pointer" 
                    onClick={() => handleImageClick(post.image_urls, index)}
                    onError={(e) => {
                      console.error(`Error loading image: ${url}`);
                      e.target.src = 'https://via.placeholder.com/150';
                      e.target.alt = 'Image load error';
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </>
      )}
      <div className="flex justify-between text-gray-500">
        <button 
          className={`flex items-center ${post.is_liked ? 'text-orange-500' : 'hover:text-orange-500'}`}
          onClick={() => handleLike(post.id)}
        >
          <ThumbsUp size={20} className="mr-1" /> {post.likes_count}
        </button>
        <button 
          className="flex items-center hover:text-orange-500"
          onClick={() => toggleComments(post.id)}
        >
          <MessageSquare size={20} className="mr-1" /> {post.comments.length}
          {expandedComments[post.id] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </button>
        <button className="flex items-center hover:text-orange-500">
          <Share2 size={20} className="mr-1" /> Share
        </button>
      </div>
      {expandedComments[post.id] && (
        <div className="mt-4 border-t pt-4">
          <h4 className="font-semibold mb-2">Comments</h4>
          {renderComments(post.comments, post.id)}
          <div className="mt-4">
            <div className="flex items-start">
              <img
                src={currentUser.image_url 
                  ? `${supabase.storage.from('profile_images').getPublicUrl(currentUser.image_url).data.publicUrl}`
                  : 'https://via.placeholder.com/30'}
                alt={currentUser.name || 'User'}
                className="w-8 h-8 rounded-full mr-2"
              />
              <div className="flex-grow relative">
                <textarea
                  id={`comment-textarea-${post.id}`}
                  placeholder={replyingTo && replyingTo.postId === post.id ? `Replying to ${replyingTo.userName}...` : "Write a comment..."}
                  className="w-full p-2 pr-10 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 resize-vertical"
                  rows="2"
                />
                <button
                  onClick={() => setShowEmojiPicker({ ...showEmojiPicker, [post.id]: !showEmojiPicker[post.id] })}
                  className="absolute right-2 top-2 text-gray-500 hover:text-gray-700"
                >
                  <Smile size={20} />
                </button>
                {showEmojiPicker[post.id] && (
                  <div className="absolute right-0 mt-2 z-10">
                    <EmojiPicker 
                      onEmojiClick={(emojiObject) => handleEmojiClick(post.id, emojiObject)}
                      disableAutoFocus
                      native
                    />
                  </div>
                )}
                <button
                  onClick={() => {
                    const textarea = document.querySelector(`#comment-textarea-${post.id}`);
                    const commentContent = textarea.value.trim();
                    if (commentContent) {
                      handleComment(post.id, commentContent, replyingTo?.commentId);
                      textarea.value = '';
                      setReplyingTo(null);
                    }
                  }}
                  className="mt-2 bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition-colors duration-300"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  if (!currentUser || loading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  if (!group) {
    return <div className="text-center mt-8">Group not found</div>;
  }

  const isGroupCreator = currentUser && group.created_by === currentUser.id;

  return (
    <div className="max-w-4xl mx-auto p-4 pb-24 sm:pb-4">
      <div className="mb-4">
        <button 
          onClick={() => navigate('/community')} 
          className="text-orange-500 hover:text-orange-600 flex items-center"
        >
          <ArrowLeft size={20} className="mr-1" />
          Back to Community
        </button>
      </div>

      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="relative h-48 sm:h-64 overflow-hidden">
          <img 
            src={group.background_image_url || 'https://via.placeholder.com/800x400'}
            alt={group.name} 
            className="w-full h-full object-cover object-center"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>
          <div className="absolute bottom-0 left-0 right-0 p-4 flex justify-between items-end">
            <div>
              <h1 className="text-2xl font-bold text-white">{group.name}</h1>
              <div className="flex items-center text-white mt-1">
                <Users size={16} className="mr-1" />
                <span>{group.memberCount} {group.memberCount === 1 ? 'Member' : 'Members'}</span>
              </div>
            </div>
            {!isGroupCreator && (
                isMember ? (
                    <button 
                    onClick={handleLeaveGroup}
                    className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-full flex items-center transition-colors duration-300"
                    >
                    <UserMinus size={20} className="mr-2" />
                    Leave Group
                    </button>
                ) : (
                    <button 
                    onClick={handleJoinGroup}
                    className="bg-orange-500 hover:bg-orange-600 text-white py-2 px-4 rounded-full flex items-center transition-colors duration-300"
                    >
                    <UserPlus size={20} className="mr-2" />
                    Join Group
                    </button>
                )
                )}
                        </div>
          {isGroupCreator && (
            <button 
              onClick={() => setIsSettingsModalOpen(true)} 
              className="absolute top-4 right-4 bg-white/80 backdrop-blur-sm text-gray-800 p-2 rounded-full"
            >
              <Settings size={20} />
            </button>
          )}
        </div>

        <div className="p-4">
          <p className="text-gray-600 mb-2">Sport: {group.sport}</p>
          <p className="text-gray-700 mb-4">{group.description}</p>
        </div>

        <div className="border-t">
          <div className="flex">
            {['Feed', 'Activities', 'Members'].map((tab) => (
              <button
                key={tab}
                className={`flex-1 py-3 ${activeTab === tab ? 'border-b-2 border-orange-500 font-semibold text-orange-500' : 'text-gray-500'}`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </button>
            ))}
          </div>

          <div className="p-4">
            {activeTab === 'Feed' && (
              <>
                <div className="bg-gray-100 rounded-lg p-4 mb-4">
                  <button
                    onClick={() => setIsNewPostModalOpen(true)}
                    className="w-full bg-white rounded-lg shadow p-3 text-left text-gray-500 hover:bg-gray-50 transition-colors duration-300"
                  >
                    What's on your mind?
                  </button>
                </div>
                {posts.map(renderPost)}
              </>
            )}
            {activeTab === 'Activities' && (
              <div>
                <h2 className="text-xl font-semibold mb-4">Upcoming Activities</h2>
                <GroupActivities 
                    groupId={id} 
                    currentUserId={currentUser.id} 
                    refreshTrigger={refreshTrigger}
                    />
            </div>
            )}
            {activeTab === 'Members' && (
              <div>
                <GroupMembers groupId={id} refreshTrigger={refreshTrigger} />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Add extra padding at the bottom for small screens */}
      <div className="h-20 sm:h-0"></div>

      {/* Settings Modal */}
      <AnimatePresence>
        {isSettingsModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          >
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 50, opacity: 0 }}
              className="bg-white rounded-lg p-6 w-80"
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Group Settings</h2>
                <button onClick={() => setIsSettingsModalOpen(false)}>
                  <X size={24} />
                </button>
              </div>
              <button 
                onClick={handleEditGroup}
                className="w-full bg-orange-500 text-white py-2 px-4 rounded mb-2 flex items-center hover:bg-orange-600 transition-colors"
              >
                <Edit size={20} className="mr-2" />
                Edit Group
              </button>
              <button 
                onClick={handleDeleteGroup}
                className="w-full bg-red-500 text-white py-2 px-4 rounded flex items-center hover:bg-red-600 transition-colors"
              >
                <Trash2 size={20} className="mr-2" />
                Delete Group
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* New Post Modal */}
      <AnimatePresence>
        {isNewPostModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          >
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 50, opacity: 0 }}
              className="bg-white rounded-lg shadow-xl p-6 w-full max-w-lg"
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold">Create a New Post</h2>
                <button onClick={() => setIsNewPostModalOpen(false)} className="text-gray-500 hover:text-gray-700">
                  <X size={24} />
                </button>
              </div>
              <textarea
                value={newPostContent}
                onChange={(e) => setNewPostContent(e.target.value)}
                placeholder="What's on your mind?"
                className="w-full h-32 p-2 border border-gray-300 rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
              <div className="mb-4">
                <label htmlFor="post-image" className="block text-sm font-medium text-gray-700 mb-2">
                  Add images to your post
                </label>
                <input
                  type="file"
                  id="post-image"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="block w-full text-sm text-gray-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-orange-50 file:text-orange-700
                    hover:file:bg-orange-100
                  "
                  multiple
                />
              </div>
              {newPostImages.length > 0 && (
                <div className="mb-4 flex flex-wrap">
                  {newPostImages.map((image, index) => (
                    <div key={index} className="relative mr-2 mb-2">
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`New post image ${index + 1}`}
                        className="w-20 h-20 object-cover rounded-lg"
                      />
                      <button
                        onClick={() => handleRemoveImage(index)}
                        className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
                      >
                        <X size={12} />
                      </button>
                    </div>
                  ))}
                </div>
              )}
              <div className="flex justify-end mt-4">
                <button
                  onClick={handleNewPost}
                  className="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition-colors duration-300"
                >
                  Post
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Image Modal */}
      {selectedImageIndex !== null && selectedPostImages && (
        <ImageModal 
          images={selectedPostImages}
          currentIndex={selectedImageIndex}
          onClose={() => {
            setSelectedImageIndex(null);
            setSelectedPostImages(null);
          }}
          onNext={() => setSelectedImageIndex((prev) => Math.min(prev + 1, selectedPostImages.length - 1))}
          onPrevious={() => setSelectedImageIndex((prev) => Math.max(prev - 1, 0))}
        />
      )}
    </div>
  );
};

const ImageModal = ({ images, currentIndex, onClose, onNext, onPrevious }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={onClose}>
      <div className="max-w-[90vw] max-h-[90vh] relative" onClick={(e) => e.stopPropagation()}>
        <img src={images[currentIndex].publicURL || images[currentIndex]} alt="Enlarged post image" className="max-w-full max-h-[90vh] object-contain" />
        {currentIndex > 0 && (
          <button 
            className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2"
            onClick={onPrevious}
          >
            <ChevronLeft size={24} />
          </button>
        )}
        {currentIndex < images.length - 1 && (
          <button 
            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2"
            onClick={onNext}
          >
            <ChevronRight size={24} />
          </button>
        )}
      </div>
    </div>
  );
};

export default GroupView;