import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Users, Calendar, DollarSign, Star, 
  Clock, Award, ChevronRight, Bell, LogOut,
  Activity, Globe, MapPin, Target, TrendingUp,
  Menu, X, Home, Dumbbell, MessageSquare, Settings,
  FileText, Clipboard, Utensils, Plus, Minus, Save,
  Edit, Trash, Coffee, Check, ChevronLeft
} from 'lucide-react';
import { format, startOfWeek, addDays, setHours, setMinutes, isWithinInterval, parseISO, addMinutes } from 'date-fns';
import { supabase } from '../utils/supabase';
import Toast from './Toast';
import UpcomingBookings from './UpcomingBookings';

const getPublicImageUrl = (path) => {
  if (!path) return 'https://via.placeholder.com/150';
  try {
    const { data } = supabase.storage
      .from('profile_images')
      .getPublicUrl(path);
    return data.publicUrl;
  } catch (error) {
    console.error('Error getting public URL:', error);
    return 'https://via.placeholder.com/150';
  }
};

const TrainerBookingSystem = ({ trainer }) => {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [availability, setAvailability] = useState({});
  const [bookings, setBookings] = useState([]);
  const [breaks, setBreaks] = useState([]);
  const [sessionTypes, setSessionTypes] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isAddBreakModalOpen, setIsAddBreakModalOpen] = useState(false);
  const [isAddSessionTypeModalOpen, setIsAddSessionTypeModalOpen] = useState(false);
  const [isEditSessionTypeModalOpen, setIsEditSessionTypeModalOpen] = useState(false);
  const [newBreak, setNewBreak] = useState({ startTime: '', endTime: '', recurring: false, recurrenceType: 'daily' });
  const [newSessionType, setNewSessionType] = useState({ name: '', duration: 60 });
  const [editingSessionType, setEditingSessionType] = useState(null);
  const [toast, setToast] = useState(null);
  const [isSetAvailabilityModalOpen, setIsSetAvailabilityModalOpen] = useState(false);
  const [newAvailability, setNewAvailability] = useState({ startTime: '', endTime: '', recurring: false, recurrenceType: 'daily' });
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectionType, setSelectionType] = useState(null);
  const [isSelectionModalOpen, setIsSelectionModalOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);

  const timeSlots = [];
  for (let i = 5.5; i <= 20.5; i += 0.75) {
    const hour = Math.floor(i);
    const minute = i % 1 === 0 ? '00' : i % 1 === 0.25 ? '15' : i % 1 === 0.5 ? '30' : '45';
    timeSlots.push(`${hour.toString().padStart(2, '0')}:${minute}`);
  }
  useEffect(() => {
    if (trainer) {
      fetchAvailability();
      fetchBookings();
      fetchBreaks();
      fetchSessionTypes();
    }
  }, [selectedDate, trainer]);

  const showToast = (message, type) => {
    setToast({ message, type });
  };

  const fetchAvailability = async () => {
    if (!trainer) return;
    try {
      const startOfWeekDate = startOfWeek(selectedDate);
      const endOfWeekDate = addDays(startOfWeekDate, 6);
  
      const { data, error } = await supabase
        .from('availability')
        .select('*')
        .eq('trainer_id', trainer.id)
        .gte('date', format(startOfWeekDate, 'yyyy-MM-dd'))
        .lte('date', format(endOfWeekDate, 'yyyy-MM-dd'));
  
      if (error) throw error;
  
      const availabilityMap = {};
      
      // Initialize all slots as unavailable
      for (let i = 0; i < 7; i++) {
        const currentDate = addDays(startOfWeekDate, i);
        const dateKey = format(currentDate, 'yyyy-MM-dd');
        availabilityMap[dateKey] = {};
        timeSlots.forEach(time => {
          availabilityMap[dateKey][time] = 'unavailable';
        });
      }
  
      // Update with fetched availability
      data.forEach(item => {
        const dateKey = format(new Date(item.date), 'yyyy-MM-dd');
        const timeKey = item.time.substring(0, 5); // Ensure HH:mm format
        availabilityMap[dateKey][timeKey] = item.status;
      });
  
      console.log('Fetched availability:', availabilityMap);
      setAvailability(availabilityMap);
    } catch (error) {
      console.error('Error fetching availability:', error);
      showToast('Failed to fetch availability', 'error');
    }
  };

  const fetchBookings = async () => {
    if (!trainer) return;
    try {
      const startOfWeekDate = startOfWeek(selectedDate);
      const endOfWeekDate = addDays(startOfWeekDate, 6);
  
      // Fetch bookings
      const { data: bookingsData, error: bookingsError } = await supabase
        .from('bookings')
        .select('*')
        .eq('trainer_id', trainer.id)
        .gte('date', format(startOfWeekDate, 'yyyy-MM-dd'))
        .lte('date', format(endOfWeekDate, 'yyyy-MM-dd'));
  
      if (bookingsError) throw bookingsError;
  
      // Fetch user profiles for each booking
      const bookingsWithClientInfo = await Promise.all(
        bookingsData.map(async (booking) => {
          const { data: userData, error: userError } = await supabase
            .from('user_profiles')
            .select('name, image_url')
            .eq('id', booking.client_id)
            .single();
  
          if (userError) {
            console.error('Error fetching user data:', userError);
            return booking;
          }
  
          return {
            ...booking,
            client_name: userData?.name || 'Unknown',
            client_image_url: userData?.image_url || null,
          };
        })
      );
  
      const formattedBookings = bookingsWithClientInfo.map(booking => ({
        ...booking,
        session_type: booking.session_type || 'Unknown Session'
      }));
  
      setBookings(formattedBookings);
      console.log('Fetched bookings:', formattedBookings);
    } catch (error) {
      console.error('Error fetching bookings:', error);
      showToast('Failed to fetch bookings', 'error');
    }
  };
  const fetchBreaks = async () => {
    if (!trainer) return;
    try {
      const startOfWeekDate = startOfWeek(selectedDate);
      const endOfWeekDate = addDays(startOfWeekDate, 6);
  
      const { data, error } = await supabase
        .from('breaks')
        .select('*')
        .eq('trainer_id', trainer.id)
        .gte('date', format(startOfWeekDate, 'yyyy-MM-dd'))
        .lte('date', format(endOfWeekDate, 'yyyy-MM-dd'));
  
      if (error) throw error;
  
      const formattedBreaks = data.map(breakItem => ({
        ...breakItem,
        date: format(new Date(breakItem.date), 'yyyy-MM-dd'),
        start_time: breakItem.start_time.substring(0, 5),
        end_time: breakItem.end_time.substring(0, 5),
      }));
  
      setBreaks(formattedBreaks);
      console.log('Fetched breaks:', formattedBreaks);
    } catch (error) {
      console.error('Error fetching breaks:', error);
      showToast('Failed to fetch breaks', 'error');
    }
  };

  const fetchSessionTypes = async () => {
    try {
      const { data, error } = await supabase
        .from('session_types')
        .select('*')
        .eq('trainer_id', trainer.id);

      if (error) throw error;

      setSessionTypes(data);
    } catch (error) {
      console.error('Error fetching session types:', error);
      showToast('Failed to fetch session types', 'error');
    }
  };

  const handleAvailabilityChange = async (date, time, status) => {
    if (!trainer) return;
    const formattedDate = format(date, 'yyyy-MM-dd');
  
    try {
      if (status === 'break') {
        // Add a break
        await supabase
          .from('breaks')
          .upsert({
            trainer_id: trainer.id,
            date: formattedDate,
            start_time: time,
            end_time: addMinutes(parseISO(`${formattedDate}T${time}`), 45).toTimeString().slice(0, 5),
          });
      } else {
        // Remove any existing break
        await supabase
          .from('breaks')
          .delete()
          .eq('trainer_id', trainer.id)
          .eq('date', formattedDate)
          .eq('start_time', time);
      }
  
      // Update availability
      const { data, error } = await supabase
        .from('availability')
        .upsert({
          trainer_id: trainer.id,
          date: formattedDate,
          time,
          status: status === 'break' ? 'unavailable' : status
        }, { onConflict: ['trainer_id', 'date', 'time'] });
  
      if (error) throw error;
  
      setAvailability(prev => ({
        ...prev,
        [formattedDate]: {
          ...prev[formattedDate],
          [time]: status
        }
      }));
  
      console.log('Updated availability:', {
        date: formattedDate,
        time,
        status
      });
  
      showToast('Availability updated successfully', 'success');
      fetchBreaks();  // Refresh breaks after update
    } catch (error) {
      console.error('Error updating availability:', error);
      showToast('Failed to update availability', 'error');
    }
  };

  const handleAddBreak = async () => {
    if (!trainer) return;
    try {
      const startDate = selectedDate;
      const endDate = newBreak.recurring
        ? addDays(startDate, newBreak.recurrenceType === 'daily' ? 6 : 28)
        : startDate;
  
      let currentDate = startDate;
      const breakData = [];
  
      while (currentDate <= endDate) {
        if (!newBreak.recurring || 
            (newBreak.recurrenceType === 'daily') ||
            (newBreak.recurrenceType === 'weekly' && format(currentDate, 'eeee') === format(startDate, 'eeee'))) {
          breakData.push({
            trainer_id: trainer.id,
            date: format(currentDate, 'yyyy-MM-dd'),
            start_time: newBreak.startTime,
            end_time: newBreak.endTime,
            recurring: newBreak.recurring,
            recurrence_type: newBreak.recurring ? newBreak.recurrenceType : null
          });
        }
        currentDate = addDays(currentDate, 1);
      }
  
      const { data, error } = await supabase
        .from('breaks')
        .insert(breakData);
  
      if (error) throw error;
  
      await fetchBreaks();
      setIsAddBreakModalOpen(false);
      setNewBreak({ startTime: '', endTime: '', recurring: false, recurrenceType: 'daily' });
      showToast('Break added successfully', 'success');
    } catch (error) {
      console.error('Error adding break:', error);
      showToast('Failed to add break', 'error');
    }
  };
  const handleDeleteBreak = async (breakId) => {
    if (!trainer) return;
    try {
      const { data, error } = await supabase
        .from('breaks')
        .delete()
        .eq('id', breakId);

      if (error) throw error;

      fetchBreaks();
      showToast('Break deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting break:', error);
      showToast('Failed to delete break', 'error');
    }
  };

  const handleAddSessionType = async () => {
    try {
      const { data, error } = await supabase
        .from('session_types')
        .insert({
          trainer_id: trainer.id,
          name: newSessionType.name,
          duration: newSessionType.duration
        });

      if (error) throw error;

      await fetchSessionTypes();
      setIsAddSessionTypeModalOpen(false);
      setNewSessionType({ name: '', duration: 60 });
      showToast('Session type added successfully', 'success');
    } catch (error) {
      console.error('Error adding session type:', error);
      showToast('Failed to add session type', 'error');
    }
  };

  const handleEditSessionType = async () => {
    try {
      const { data, error } = await supabase
        .from('session_types')
        .update({ name: editingSessionType.name, duration: editingSessionType.duration })
        .eq('id', editingSessionType.id);

      if (error) throw error;

      await fetchSessionTypes();
      setIsEditSessionTypeModalOpen(false);
      setEditingSessionType(null);
      showToast('Session type updated successfully', 'success');
    } catch (error) {
      console.error('Error updating session type:', error);
      showToast('Failed to update session type', 'error');
    }
  };

  const handleDeleteSessionType = async (id) => {
    try {
      const { data, error } = await supabase
        .from('session_types')
        .delete()
        .eq('id', id);

      if (error) throw error;

      await fetchSessionTypes();
      showToast('Session type deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting session type:', error);
      showToast('Failed to delete session type', 'error');
    }
  };
 
  const handleSetAvailability = async () => {
    try {
      const startDate = selectedDate;
      const endDate = newAvailability.recurring
        ? addDays(startDate, newAvailability.recurrenceType === 'daily' ? 6 : 28)
        : startDate;

      let currentDate = startDate;
      const availabilityData = [];

      while (currentDate <= endDate) {
        if (!newAvailability.recurring || 
            (newAvailability.recurrenceType === 'daily') ||
            (newAvailability.recurrenceType === 'weekly' && format(currentDate, 'eeee') === format(startDate, 'eeee'))) {
          timeSlots.forEach(timeSlot => {
            if (timeSlot >= newAvailability.startTime && timeSlot < newAvailability.endTime) {
              availabilityData.push({
                trainer_id: trainer.id,
                date: format(currentDate, 'yyyy-MM-dd'),
                time: timeSlot,
                status: 'available'
              });
            }
          });
        }
        currentDate = addDays(currentDate, 1);
      }

      const { data, error } = await supabase
        .from('availability')
        .upsert(availabilityData, { onConflict: ['trainer_id', 'date', 'time'] });

      if (error) throw error;

      await fetchAvailability();
      setIsSetAvailabilityModalOpen(false);
      setNewAvailability({ startTime: '', endTime: '', recurring: false, recurrenceType: 'daily' });
      showToast('Availability set successfully', 'success');
    } catch (error) {
      console.error('Error setting availability:', error);
      showToast('Failed to set availability', 'error');
    }
  };

  const handleTimeSlotClick = (date, time) => {
    const dateKey = format(date, 'yyyy-MM-dd');
    const currentStatus = availability[dateKey] && availability[dateKey][time];
    
    if (isSelecting) {
      const newSelectedTimeSlots = [...selectedTimeSlots];
      const existingIndex = newSelectedTimeSlots.findIndex(
        slot => format(slot.date, 'yyyy-MM-dd') === dateKey && slot.time === time
      );

      if (existingIndex !== -1) {
        newSelectedTimeSlots.splice(existingIndex, 1);
      } else {
        newSelectedTimeSlots.push({ date, time });
      }

      setSelectedTimeSlots(newSelectedTimeSlots);
    } else {
      setSelectedSlot({ date, time, currentStatus });
      setIsSelectionModalOpen(true);
    }
  };

  const handleSelectionConfirm = (type) => {
    if (selectedSlot) {
      let status;
      switch (type) {
        case 'free':
          status = 'available';
          break;
        case 'break':
          status = 'break';
          break;
        case 'unavailable':
          status = 'unavailable';
          break;
        default:
          status = 'unavailable';
      }
      handleAvailabilityChange(selectedSlot.date, selectedSlot.time, status);
    }
    setIsSelectionModalOpen(false);
    setSelectedSlot(null);
  };

  const handleRemoveBreak = async (date, time) => {
    const dateKey = format(date, 'yyyy-MM-dd');
    try {
      await supabase
        .from('breaks')
        .delete()
        .eq('trainer_id', trainer.id)
        .eq('date', dateKey)
        .eq('start_time', time);

      // Update availability to 'available'
      await handleAvailabilityChange(date, time, 'available');
      
      showToast('Break removed successfully', 'success');
      fetchBreaks();
    } catch (error) {
      console.error('Error removing break:', error);
      showToast('Failed to remove break', 'error');
    }
  };
  const handleSelectionComplete = () => {
    setIsSelecting(false);
    const status = selectionType === 'free' ? 'available' : 'unavailable';
    selectedTimeSlots.forEach(slot => {
      handleAvailabilityChange(slot.date, slot.time, status);
    });
    setSelectedTimeSlots([]);
    setSelectionType(null);
  };

  const isTimeSlotAvailable = (date, time) => {
    const dateKey = format(date, 'yyyy-MM-dd');
    if (availability[dateKey] && availability[dateKey][time] === 'available') {
      const isBreak = breaks.some(breakItem => 
        breakItem.date === dateKey && 
        breakItem.start_time <= time && 
        breakItem.end_time > time
      );
      const isBooked = bookings.some(booking => 
        booking.date === dateKey && 
        booking.start_time === time
      );
      return !isBreak && !isBooked;
    }
    return false;
  };

  const renderWeekView = () => {
    const startDate = startOfWeek(selectedDate);
    return (
      <div className="overflow-x-auto">
        <div className="min-w-max">
          <div className="grid grid-cols-8 gap-1 bg-gray-100 rounded-lg p-2 md:p-4">
            <div className="col-span-1"></div>
            {[0, 1, 2, 3, 4, 5, 6].map(dayOffset => (
              <div key={dayOffset} className="text-center font-semibold bg-white p-2 rounded-t-lg text-xs md:text-sm">
                {format(addDays(startDate, dayOffset), 'EEE dd/MM')}
              </div>
            ))}
            {timeSlots.map(time => (
              <React.Fragment key={time}>
                <div className="text-right pr-2 font-medium text-gray-600 text-xs md:text-sm">
                  {format(parseISO(`2000-01-01T${time}`), 'h:mm a')}
                </div>
                {[0, 1, 2, 3, 4, 5, 6].map(dayOffset => {
                  const currentDate = addDays(startDate, dayOffset);
                  const dateKey = format(currentDate, 'yyyy-MM-dd');
                  const currentStatus = availability[dateKey]?.[time] || 'unavailable';
                  const booking = bookings.find(b => b.date === dateKey && b.start_time === `${time}:00`);
                  const isBooked = !!booking;
                  const isBreak = breaks.some(breakItem => 
                    breakItem.date === dateKey && 
                    breakItem.start_time <= time && 
                    breakItem.end_time > time
                  );

                  let cellContent = '';
                  let cellClass = '';

                  if (isBooked) {
                    cellContent = `Booked`;
                    cellClass = 'bg-red-200 hover:bg-red-300';
                  } else if (isBreak) {
                    cellContent = 'Break';
                    cellClass = 'bg-yellow-200 hover:bg-yellow-300';
                  } else if (currentStatus === 'available') {
                    cellContent = 'Free';
                    cellClass = 'bg-green-200 hover:bg-green-300';
                  } else {
                    cellClass = 'bg-gray-200 hover:bg-gray-300';
                  }

                  return (
                    <div
                      key={`${dateKey}-${time}`}
                      className={`cursor-pointer border p-1 md:p-2 text-center transition-all duration-200 ${cellClass} text-xs md:text-sm`}
                      onClick={() => handleTimeSlotClick(currentDate, time)}
                    >
                      {cellContent}
                    </div>
                  );
                })}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const sidebarItems = [
    { icon: <Home size={20} />, label: 'Dashboard', path: '/trainer-dashboard' },
    { icon: <Calendar size={20} />, label: 'Booking System', path: '/trainer-booking' },
    { icon: <Calendar size={20} />, label: 'Classes', path: '/trainer-classes' },
    { 
      icon: <Users size={20} />, 
      label: 'Clients', 
      path: '/clients',
      subItems: [
        { icon: <Users size={16} />, label: 'Client Management', path: '/clients/management' },
        { icon: <FileText size={16} />, label: 'Assessments', path: '/clients/assessments' },
        { icon: <Clipboard size={16} />, label: 'Workout Planner', path: '/clients/workout-planner' },
        { icon: <Utensils size={16} />, label: 'Nutrition Planner', path: '/clients/nutrition-planner' },
      ]
    },
    { icon: <MessageSquare size={20} />, label: 'Messages', path: '/trainer-chats' },
    { icon: <DollarSign size={20} />, label: 'Financials', path: '/trainer-financials' },
    { icon: <Settings size={20} />, label: 'Settings', path: '/trainer-settings' },
  ];

  if (!trainer) {
    return <div className="flex justify-center items-center h-screen">Loading trainer data...</div>;
  }
  return (
    <div className="flex flex-col h-screen bg-gray-100">
      {/* Header */}
      <header className="bg-white p-4 flex justify-between items-center shadow-sm">
        <button onClick={() => setIsSidebarOpen(!isSidebarOpen)} className="text-gray-500 hover:text-gray-700">
          <Menu size={24} />
        </button>
        <h1 className="text-xl md:text-2xl font-semibold text-gray-800">Booking System</h1>
        <div className="flex items-center">
          
          <button 
            onClick={() => {}} // Add user menu functionality here
            className="w-8 h-8 md:w-10 md:h-10 rounded-full overflow-hidden"
            aria-label="User menu"
          >
            <img 
              src={getPublicImageUrl(trainer.image_url)} 
              alt={trainer.name} 
              className="w-full h-full object-cover"
              onError={(e) => {
                e.target.onerror = null; 
                e.target.src = 'https://via.placeholder.com/150'
              }}
            />
          </button>
        </div>
      </header>

      {/* Main content */}
      <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-4 pb-20 sm:pb-4">
        <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
          <div className="mb-6 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <button
              className="w-full md:w-auto bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition-colors duration-200 shadow-md"
              onClick={() => setSelectedDate(addDays(selectedDate, -7))}
            >
              <ChevronLeft size={20} className="inline mr-2" />
              Previous Week
            </button>
            <span className="font-semibold text-lg text-gray-700">
              Week of {format(startOfWeek(selectedDate), 'MMMM d, yyyy')}
            </span>
            <button
              className="w-full md:w-auto bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition-colors duration-200 shadow-md"
              onClick={() => setSelectedDate(addDays(selectedDate, 7))}
            >
              Next Week
              <ChevronRight size={20} className="inline ml-2" />
            </button>
          </div>
          <button
            className="w-full mb-6 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-200 shadow-md"
            onClick={() => setIsSetAvailabilityModalOpen(true)}
          >
            Set Availability
          </button>
          {renderWeekView()}
          
          <div className="mt-6">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Manage Breaks</h3>
            <button
              className="w-full md:w-auto bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-200 shadow-md flex items-center justify-center"
              onClick={() => setIsAddBreakModalOpen(true)}
            >
              <Plus size={20} className="mr-2" />
              Add Break
            </button>
            <div className="mt-4 space-y-2">
              {breaks.map((breakItem, index) => (
                <div key={index} className="flex items-center justify-between bg-gray-100 p-3 rounded-lg">
                  <div>
                    <span className="font-semibold text-gray-700">{format(parseISO(breakItem.date), 'EEE')} {format(parseISO(`2000-01-01T${breakItem.start_time}`), 'h:mm a')} - {format(parseISO(`2000-01-01T${breakItem.end_time}`), 'h:mm a')}</span>
                    {breakItem.recurring && <span className="ml-2 text-sm text-gray-600">({breakItem.recurrence_type})</span>}
                  </div>
                  <button
                    className="text-red-500 hover:text-red-700 transition-colors duration-200"
                    onClick={() => handleDeleteBreak(breakItem.id)}
                  >
                    <Trash size={20} />
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-6">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Session Types</h3>
            <button
              className="w-full md:w-auto bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition-colors duration-200 shadow-md flex items-center justify-center mb-4"
              onClick={() => setIsAddSessionTypeModalOpen(true)}
            >
              <Plus size={16} className="mr-2" /> Add Session Type
            </button>
            <div className="space-y-2">
              {sessionTypes.map((type) => (
                <div key={type.id} className="flex justify-between items-center bg-gray-100 p-3 rounded-lg">
                  <span className="font-medium text-gray-700">{type.name} ({type.duration} minutes)</span>
                  <div>
                    <button className="bg-blue-500 text-white p-2 rounded-lg mr-2 hover:bg-blue-600 transition-colors duration-200" onClick={() => {
                      setEditingSessionType(type);
                      setIsEditSessionTypeModalOpen(true);
                    }}>
                      <Edit size={16} />
                    </button>
                    <button className="bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 transition-colors duration-200" onClick={() => handleDeleteSessionType(type.id)}>
                      <Trash size={16} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-6">
          <UpcomingBookings bookings={bookings} />
        </div>
      </main>

      {/* Sidebar */}
      <div className={`fixed inset-y-0 left-0 z-30 w-64 bg-white shadow-lg transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}>
        <div className="flex items-center justify-between p-4 border-b">
          <div className="flex items-center space-x-2">
            <div className="w-10 h-10 bg-orange-500 rounded-full flex items-center justify-center">
              <span className="text-white font-bold text-xl">PT</span>
            </div>
            <span className="font-bold text-xl text-gray-800">TrainerHub</span>
          </div>
          <button onClick={() => setIsSidebarOpen(false)} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <nav className="mt-4">
          {sidebarItems.map((item, index) => (
            <div key={index}>
              <button
                onClick={() => navigate(item.path)}
                className="flex items-center w-full px-4 py-2 text-gray-700 hover:bg-orange-100 hover:text-orange-500 transition-colors duration-200"
              >
                {item.icon}
                <span className="ml-2">{item.label}</span>
              </button>
              {item.subItems && (
                <div className="ml-6">
                  {item.subItems.map((subItem, subIndex) => (
                    <button
                      key={subIndex}
                      onClick={() => navigate(subItem.path)}
                      className="flex items-center w-full px-4 py-2 text-sm text-gray-600 hover:bg-orange-50 hover:text-orange-500 transition-colors duration-200"
                    >
                      {subItem.icon}
                      <span className="ml-2">{subItem.label}</span>
                    </button>
                  ))}
                </div>
              )}
            </div>
          ))}
        </nav>
      </div>

      {/* Add Break Modal */}
      {isAddBreakModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Add Break</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Start Time</label>
                <select
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500"
                  value={newBreak.startTime}
                  onChange={(e) => setNewBreak({...newBreak, startTime: e.target.value})}
                >
                  {timeSlots.map(slot => (
                    <option key={slot} value={slot}>{format(parseISO(`2000-01-01T${slot}`), 'h:mm a')}</option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">End Time</label>
                <select
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500"
                  value={newBreak.endTime}
                  onChange={(e) => setNewBreak({...newBreak, endTime: e.target.value})}
                >
                  {timeSlots.map(slot => (
                    <option key={slot} value={slot}>{format(parseISO(`2000-01-01T${slot}`), 'h:mm a')}</option>
                  ))}
                </select>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="recurring"
                  className="mr-2"
                  checked={newBreak.recurring}
                  onChange={(e) => setNewBreak({...newBreak, recurring: e.target.checked})}
                />
                <label htmlFor="recurring" className="text-sm font-medium text-gray-700">Recurring</label>
              </div>
              {newBreak.recurring && (
                <div>
                  <label className="block text-sm font-medium text-gray-700">Recurrence Type</label>
                  <select
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500"
                    value={newBreak.recurrenceType}
                    onChange={(e) => setNewBreak({...newBreak, recurrenceType: e.target.value})}
                  >
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                  </select>
                </div>
              )}
            </div>
            <div className="mt-6 flex justify-end space-x-2">
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition-colors duration-200"
                onClick={() => setIsAddBreakModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
                onClick={handleAddBreak}
              >
                Add Break
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Add Session Type Modal */}
      {isAddSessionTypeModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Add Session Type</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Name</label>
                <input
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500"
                  value={newSessionType.name}
                  onChange={(e) => setNewSessionType({...newSessionType, name: e.target.value})}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Duration (minutes)</label>
                <select
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500"
                  value={newSessionType.duration}
                  onChange={(e) => setNewSessionType({...newSessionType, duration: parseInt(e.target.value)})}
                >
                  {[15, 30, 45, 60, 75, 90, 105, 120].map(duration => (
                    <option key={duration} value={duration}>{duration}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-6 flex justify-end space-x-2">
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition-colors duration-200"
                onClick={() => setIsAddSessionTypeModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
                onClick={handleAddSessionType}
              >
                Add Session Type
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Session Type Modal */}
      {isEditSessionTypeModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Edit Session Type</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Name</label>
                <input
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500"
                  value={editingSessionType.name}
                  onChange={(e) => setEditingSessionType({...editingSessionType, name: e.target.value})}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Duration (minutes)</label>
                <select
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500"
                  value={editingSessionType.duration}
                  onChange={(e) => setEditingSessionType({...editingSessionType, duration: parseInt(e.target.value)})}
                >
                  {[15, 30, 45, 60, 75, 90, 105, 120].map(duration => (
                    <option key={duration} value={duration}>{duration}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-6 flex justify-end space-x-2">
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition-colors duration-200"
                onClick={() => setIsEditSessionTypeModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
                onClick={handleEditSessionType}
              >
                Update Session Type
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Selection Modal */}
      {isSelectionModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Set Availability</h3>
            <div className="space-y-4">
              {selectedSlot.currentStatus !== 'available' && (
                <button
                  className="w-full px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors duration-200"
                  onClick={() => handleSelectionConfirm('free')}
                >
                  Set as Free
                </button>
              )}
              {selectedSlot.currentStatus !== 'break' && (
                <button
                  className="w-full px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition-colors duration-200"
                  onClick={() => handleSelectionConfirm('break')}
                >
                  Set as Break
                </button>
              )}
              {selectedSlot.currentStatus !== 'unavailable' && (
                <button
                  className="w-full px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition-colors duration-200"
                  onClick={() => handleSelectionConfirm('unavailable')}
                >
                  Set as Unavailable
                </button>
              )}
            </div>
            <div className="mt-6 flex justify-end">
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition-colors duration-200"
                onClick={() => setIsSelectionModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Set Availability Modal */}
      {isSetAvailabilityModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Set Availability</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Start Time</label>
                <select
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500"
                  value={newAvailability.startTime}
                  onChange={(e) => setNewAvailability({...newAvailability, startTime: e.target.value})}
                >
                  {timeSlots.map(slot => (
                    <option key={slot} value={slot}>{format(parseISO(`2000-01-01T${slot}`), 'h:mm a')}</option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">End Time</label>
                <select
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500"
                  value={newAvailability.endTime}
                  onChange={(e) => setNewAvailability({...newAvailability, endTime: e.target.value})}
                >
                  {timeSlots.map(slot => (
                    <option key={slot} value={slot}>{format(parseISO(`2000-01-01T${slot}`), 'h:mm a')}</option>
                  ))}
                </select>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="recurring-availability"
                  className="mr-2"
                  checked={newAvailability.recurring}
                  onChange={(e) => setNewAvailability({...newAvailability, recurring: e.target.checked})}
                />
                <label htmlFor="recurring-availability" className="text-sm font-medium text-gray-700">Recurring</label>
              </div>
              {newAvailability.recurring && (
                <div>
                  <label className="block text-sm font-medium text-gray-700">Recurrence Type</label>
                  <select
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500"
                    value={newAvailability.recurrenceType}
                    onChange={(e) => setNewAvailability({...newAvailability, recurrenceType: e.target.value})}
                  >
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                  </select>
                </div>
              )}
            </div>
            <div className="mt-6 flex justify-end space-x-2">
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition-colors duration-200"
                onClick={() => setIsSetAvailabilityModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
                onClick={handleSetAvailability}
              >
                Set Availability
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Toast Notification */}
      {toast && (
        <div className="fixed bottom-4 right-4 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg">
          <p>{toast.message}</p>
        </div>
      )}
    </div>
  );
};

export default TrainerBookingSystem;