import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Calendar as CalendarIcon, Clock, Users, Plus, ChevronLeft, ChevronRight, MapPin, DollarSign, Edit, ArrowLeft } from 'lucide-react';
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import TrainerNavigation from './TrainerNavigation';
import { supabase } from '../utils/supabase';
import EditClassModal from './EditClassModal';

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const TrainerClasses = ({ currentUser }) => {
  const navigate = useNavigate();
  const [classes, setClasses] = useState([]);
  const [view, setView] = useState('week');
  const [date, setDate] = useState(new Date());
  const [selectedClass, setSelectedClass] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    if (currentUser && currentUser.id) {
      fetchClasses();
    } else {
      setIsLoading(false);
    }

    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Adjust this breakpoint as needed
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, [currentUser]);
  

  const fetchClasses = async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from('trainer_classes')
        .select('*')
        .eq('trainer_id', currentUser.id);

      if (error) throw error;

      setClasses(data.map(cls => ({
        ...cls,
        title: cls.name,
        start: new Date(`${cls.date}T${cls.start_time}`),
        end: new Date(`${cls.date}T${cls.end_time}`),
      })));
    } catch (error) {
      console.error('Error fetching classes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateClass = () => {
    navigate('/create-class');
  };

  const handleAttendance = (classId) => {
    navigate(`/class-attendance/${classId}`);
  };

  const handleSelectEvent = useCallback((event) => {
    setSelectedClass(event);
    setIsEditMode(false);
    setIsModalOpen(true);
  }, []);

  const handleEditClass = (cls) => {
    setSelectedClass(cls);
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedClass(null);
    setIsEditMode(false);
  };

  const handleSaveClass = async (editedClass) => {
    try {
      const { error } = await supabase
        .from('trainer_classes')
        .update(editedClass)
        .eq('id', editedClass.id);

      if (error) throw error;

      await fetchClasses();
      setSelectedClass(editedClass);
      handleCloseModal();
    } catch (error) {
      console.error('Error updating class:', error);
      alert('Failed to update class. Please try again.');
    }
  };

  const handleDeleteClass = async (classId) => {
    try {
      const { error } = await supabase
        .from('trainer_classes')
        .delete()
        .eq('id', classId);

      if (error) throw error;

      await fetchClasses();
      handleCloseModal();
    } catch (error) {
      console.error('Error deleting class:', error);
      alert('Failed to delete class. Please try again.');
    }
  };

  const handleNavigate = (newDate) => {
    setDate(newDate);
  };

  const formatDate = (date, viewType) => {
    try {
      if (viewType === 'week') {
        return `${format(date, 'MMMM yyyy')}, Week ${format(date, 'w')}`;
      }
      return format(date, 'MMMM yyyy');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!currentUser || !currentUser.id) {
    return <div>Error: User information not available. Please try logging in again.</div>;
  }

  return (
    <div className="max-w-7xl mx-auto p-4 pb-16 relative">
      <div className="mb-4">
        <Link to="/trainer-dashboard" className="flex items-center text-orange-500 hover:text-orange-600">
          <ArrowLeft size={20} className="mr-2" />
          Back to Dashboard
        </Link>
      </div>
      <h1 className="text-2xl font-semibold mb-6">Your Classes</h1>
      
      <div className="mb-6 flex justify-between items-center">
        <div>
          <button
            className={`mr-2 px-4 py-2 rounded-lg ${view === 'week' ? 'bg-orange-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setView('week')}
          >
            Week
          </button>
          <button
            className={`px-4 py-2 rounded-lg ${view === 'month' ? 'bg-orange-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setView('month')}
          >
            Month
          </button>
        </div>
        <button 
          onClick={handleCreateClass}
          className="bg-orange-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-orange-600 transition-colors"
        >
          <Plus size={20} className="mr-2" />
          Create New Class
        </button>
      </div>

      <div className="bg-white p-4 rounded-lg shadow-md mb-8">
        <div className="flex justify-between items-center mb-4">
          <button onClick={() => handleNavigate(new Date(date.getFullYear(), date.getMonth() - 1, 1))}>
            <ChevronLeft size={24} />
          </button>
          <h2 className="text-xl font-semibold">
            {formatDate(date, view)}
          </h2>
          <button onClick={() => handleNavigate(new Date(date.getFullYear(), date.getMonth() + 1, 1))}>
            <ChevronRight size={24} />
          </button>
        </div>
        <BigCalendar
          localizer={localizer}
          events={classes}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          view={view}
          onView={setView}
          onSelectEvent={handleSelectEvent}
          onNavigate={handleNavigate}
          date={date}
        />
      </div>
      <div className="grid gap-4">
        {classes.map((cls) => (
          <div key={cls.id} className="bg-white p-6 rounded-lg shadow-md mb-4">
            <div className="flex justify-between items-start mb-3">
              <h2 className="text-2xl font-semibold">{cls.name}</h2>
              <div>
                <button 
                  onClick={() => handleEditClass(cls)}
                  className="bg-blue-500 text-white px-3 py-1 rounded-lg hover:bg-blue-600 transition-colors mr-2"
                >
                  <Edit size={16} />
                </button>
                <button 
                  onClick={() => handleAttendance(cls.id)}
                  className="bg-green-500 text-white px-3 py-1 rounded-lg hover:bg-green-600 transition-colors"
                >
                  Attendance
                </button>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center text-gray-600 mb-2">
                <CalendarIcon size={18} className="mr-2 text-orange-500" />
                {format(cls.start, 'MMMM d, yyyy')}
              </div>
              <div className="flex items-center text-gray-600 mb-2">
                <Clock size={18} className="mr-2 text-orange-500" />
                {format(cls.start, 'h:mm a')} - {format(cls.end, 'h:mm a')}
              </div>
              <div className="flex items-center text-gray-600 mb-2">
                <MapPin size={18} className="mr-2 text-orange-500" />
                {cls.location || 'Location TBA'}
              </div>
              <div className="flex items-center text-gray-600 mb-2">
                <Users size={18} className="mr-2 text-orange-500" />
                {cls.max_participants} max participants
              </div>
              <div className="flex items-center text-gray-600">
                <DollarSign size={18} className="mr-2 text-orange-500" />
                ${cls.price}
              </div>
            </div>
            <p className="mt-3 text-gray-700">{cls.description || 'No description available.'}</p>
          </div>
        ))}
      </div>

      {isModalOpen && selectedClass && (
        <div className="fixed inset-0 flex items-center justify-center z-50 p-4 bg-black bg-opacity-50">
          <EditClassModal
            classData={selectedClass}
            onClose={handleCloseModal}
            onSave={handleSaveClass}
            onDelete={handleDeleteClass}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
          />
        </div>
      )}

          {isSmallScreen && (
        <div className="fixed bottom-0 left-0 right-0 bg-white border-t">
          <TrainerNavigation activeTab="classes" />
        </div>
      )}
    </div>
  );
};

export default TrainerClasses;