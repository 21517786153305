import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { X, CreditCard, Calendar, DollarSign, Check } from 'lucide-react';
import { createClient } from '@supabase/supabase-js';
import axios from 'axios';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const SUPABASE_FUNCTION_URL = `${supabaseUrl}/functions/v1/stripe`;

const PaymentForm = ({ event, onSuccess, onCancel, paymentMethod, currentUser }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [country, setCountry] = useState('AU');
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [detailsLoaded, setDetailsLoaded] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (currentUser) {
        console.log('Fetching user details for ID:', currentUser.id);
        try {
          // Fetch email from user_profiles_with_email
          const { data: emailData, error: emailError } = await supabase
            .from('user_profiles_with_email')
            .select('email')
            .eq('id', currentUser.id)
            .single();

          if (emailError) {
            console.error('Error fetching user email:', emailError);
            throw new Error(`Email fetch error: ${emailError.message}`);
          }
          
          if (!emailData || !emailData.email) {
            throw new Error('Email not found for user');
          }
          
          setUserEmail(emailData.email);
          console.log('User email retrieved:', emailData.email);

          // Fetch name from user_profiles
          const { data: profileData, error: profileError } = await supabase
            .from('user_profiles')
            .select('name')
            .eq('id', currentUser.id)
            .single();

          if (profileError) {
            console.error('Error fetching user profile:', profileError);
            throw new Error(`Profile fetch error: ${profileError.message}`);
          }
          
          if (!profileData || !profileData.name) {
            throw new Error('Name not found for user');
          }
          
          setUserName(profileData.name);
          console.log('User name retrieved:', profileData.name);

          setDetailsLoaded(true);
        } catch (error) {
          console.error('Error in fetchUserDetails:', error);
          setError(`Unable to retrieve user details: ${error.message}. Please try again or contact support.`);
        }
      } else {
        console.error('No current user provided');
        setError('User not authenticated. Please log in and try again.');
      }
    };

    fetchUserDetails();
  }, [currentUser]);

  const createPaymentIntent = async (payload) => {
    try {
      console.log('Sending request to Supabase function:', SUPABASE_FUNCTION_URL);
      console.log('Payload:', payload);
      const { data: { session } } = await supabase.auth.getSession();
      console.log('Session retrieved, access token length:', session.access_token.length);
      
      const response = await axios.post(SUPABASE_FUNCTION_URL, {
        action: 'create-payment-intent',
        ...payload,
        customer_email: userEmail,
        customer_name: userName,
        user_id: currentUser.id
      }, {
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`
        },
        timeout: 120000 // 120 seconds
      });
      
      console.log('Received response from Supabase function:', response.data);
      
      if (!response.data) {
        throw new Error('Empty response received from server');
      }
      
      if (!response.data.success) {
        throw new Error(response.data.error || 'Unknown error occurred');
      }
      
      return response.data;
    } catch (error) {
      console.error('Error in createPaymentIntent:', error);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error('Error response data:', error.response.data);
          throw new Error(error.response.data.error || 'An error occurred while processing your payment.');
        } else if (error.request) {
          console.error('No response received:', error.request);
          throw new Error('No response received from the server. Please try again.');
        } else {
          console.error('Error setting up the request:', error.message);
          throw new Error('There was a problem setting up the payment request. Please try again.');
        }
      } else {
        console.error('Non-Axios error:', error);
        throw error;
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    setError(null);
  
    if (!currentUser || !currentUser.id) {
      setError('User information is missing. Please try logging in again.');
      setProcessing(false);
      return;
    }
  
    if (!detailsLoaded) {
      setError('User details not fully loaded. Please wait or refresh the page.');
      setProcessing(false);
      return;
    }
  
    if (!userEmail || !userName || !currentUser.id) {
      setError('Unable to retrieve user details. Please try again or contact support.');
      setProcessing(false);
      return;
    }
  
    if (paymentMethod === 'stripe') {
      if (!stripe || !elements) {
        setError('Stripe has not loaded. Please try again later.');
        setProcessing(false);
        return;
      }
  
      const cardElement = elements.getElement(CardElement);
  
      try {
        console.log('Creating payment method...');
        const { error, paymentMethod: stripePaymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            address: {
              country: country,
            },
          },
        });
  
        if (error) {
          throw error;
        }
  
        console.log('Payment method created successfully:', stripePaymentMethod.id);
  
        console.log('Sending request to create payment intent...');
        const data = await createPaymentIntent({
          amount: Math.round(event.price * 100),
          currency: 'aud',
          payment_method_id: stripePaymentMethod.id,
          event_id: event.id,
        });
  
        console.log('Received response from create payment intent:', data);
  
        if (data.status === 'succeeded') {
          console.log('Payment processed successfully');
          onSuccess('stripe', data.paymentIntentId);
        } else if (data.status === 'requires_action' || data.status === 'requires_confirmation') {
          console.log('3D Secure authentication or confirmation required');
          const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(data.clientSecret);
          if (confirmError) {
            throw confirmError;
          }
          console.log('Payment confirmed after additional action');
          onSuccess('stripe', paymentIntent.id);
        } else {
          throw new Error(`Unexpected payment status: ${data.status}`);
        }
      } catch (err) {
        console.error('Payment processing error:', err);
        setError(`An error occurred: ${err.message}. Please try again or contact support if the problem persists.`);
      }
    } else if (paymentMethod === 'external_subscription') {
      try {
        // Handle external subscription logic here
        // This might involve verifying the subscription status with your backend
        console.log('Processing external subscription payment');
        onSuccess('external_subscription');
      } catch (err) {
        console.error('External subscription processing error:', err);
        setError(`An error occurred while processing your subscription: ${err.message}. Please try again or contact support.`);
      }
    } else if (paymentMethod === 'pay_later') {
      try {
        // Handle pay later logic here
        // This might involve creating a pending payment record in your backend
        console.log('Processing pay later request');
        onSuccess('pay_later');
      } catch (err) {
        console.error('Pay later processing error:', err);
        setError(`An error occurred while setting up your pay later request: ${err.message}. Please try again or contact support.`);
      }
    } else if (paymentMethod === 'cash') {
      try {
        // Handle cash payment logic here
        // This might involve creating a cash payment record in your backend
        console.log('Processing cash payment request');
        onSuccess('cash');
      } catch (err) {
        console.error('Cash payment processing error:', err);
        setError(`An error occurred while processing your cash payment request: ${err.message}. Please try again or contact support.`);
      }
    } else {
      setError('Invalid payment method selected. Please choose a valid payment option.');
    }
  
    setProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="mb-4 text-lg font-semibold">
        Total Amount: ${event.price}
      </div>
      {paymentMethod === 'stripe' && (
        <>
          <div className="mb-4">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
              Country
            </label>
            <select
              id="country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
            >
              <option value="AU">Australia</option>
              <option value="US">United States</option>
              {/* Add more countries as needed */}
            </select>
          </div>
          <div className="bg-gray-50 p-4 rounded-md">
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
                hidePostalCode: country === 'AU',
              }}
            />
          </div>
        </>
      )}
      {error && <div className="text-red-500 text-sm">{error}</div>}
      <div className="flex justify-end space-x-2">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
          disabled={processing}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={processing}
          className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600 transition-colors disabled:bg-orange-300"
        >
          {processing ? 'Processing...' : `Confirm ${paymentMethod === 'stripe' ? 'Payment' : 'Reservation'}`}
        </button>
      </div>
    </form>
  );
};

const PaymentModal = ({ event, onSuccess, onClose, currentUser }) => {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log('CurrentUser in PaymentModal:', currentUser);
    setIsLoading(false);
  }, [currentUser]);

  const recordTransaction = async (paymentMethod, paymentStatus, stripePaymentIntentId = null) => {
    try {
      const { data, error } = await supabase
        .from('event_transactions')
        .insert({
          event_id: event.id,
          user_id: currentUser.id,
          amount: event.price,
          payment_method: paymentMethod,
          payment_status: paymentStatus,
          stripe_payment_intent_id: stripePaymentIntentId
        });

      if (error) throw error;
      console.log('Transaction recorded successfully:', data);
    } catch (error) {
      console.error('Error recording transaction:', error);
    }
  };

  const handleSuccess = async (method, stripePaymentIntentId = null) => {
    let paymentStatus = 'pending';

    if (method === 'stripe') {
      paymentStatus = 'paid';
    } else if (method === 'external_subscription') {
      paymentStatus = 'paid';
    }

    await recordTransaction(method, paymentStatus, stripePaymentIntentId);
    onSuccess(method);
    onClose();
  };

  const paymentOptions = [
    { id: 'external_subscription', name: 'Use External Subscription', icon: Check },
    { id: 'stripe', name: 'Pay Now with Card', icon: CreditCard },
    { id: 'pay_later', name: 'Pay at the Event', icon: Calendar },
    { id: 'cash', name: 'Pay in Cash', icon: DollarSign },
  ];

  if (isLoading) {
    return <div>Loading user data...</div>;
  }

  if (!currentUser) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 max-w-md w-full">
          <h2 className="text-xl font-bold mb-4">Authentication Required</h2>
          <p>Please log in to proceed with the payment.</p>
          <button onClick={onClose} className="mt-4 px-4 py-2 bg-orange-500 text-white rounded">
            Close
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Payment for {event.name}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <div className="mb-4">
          <p className="text-lg font-semibold">Event Price: ${event.price}</p>
          <p className="text-sm text-gray-600 mt-2">
            If you have an external subscription, select "Use External Subscription" below.
          </p>
        </div>
        {!paymentMethod ? (
          <div className="space-y-4">
            <p className="text-gray-600">Please select a payment method:</p>
            {paymentOptions.map((option) => (
              <button
                key={option.id}
                onClick={() => {
                  if (option.id === 'external_subscription') {
                    handleSuccess('external_subscription');
                  } else {
                    setPaymentMethod(option.id);
                  }
                }}
                className="w-full flex items-center justify-between p-4 border rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="flex items-center">
                  <option.icon className="mr-2" size={20} />
                  {option.name}
                </span>
                <span className="text-orange-500">Select</span>
              </button>
            ))}
          </div>
        ) : (
          <Elements stripe={stripePromise}>
            <PaymentForm
              event={event}
              onSuccess={handleSuccess}
              onCancel={() => setPaymentMethod(null)}
              paymentMethod={paymentMethod}
              currentUser={currentUser}
            />
          </Elements>
        )}
      </div>
      </div>
  );
};

export default PaymentModal;