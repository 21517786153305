import React, { useState, useEffect } from 'react';
import { Plus, Edit, Trash } from 'lucide-react';
import { supabase } from '../utils/supabase';

const ManageSessions = ({ clientId, sessions = [], packages = [], adHocPurchases = [], onUpdate }) => {
    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentSession, setCurrentSession] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        getUser();
    }, []);

    const getUser = async () => {
        const { data: { user } } = await supabase.auth.getUser();
        setUser(user);
    };

    const handleAdd = async (newSession) => {
        if (!user) return;

        const sessionData = {
            ...newSession,
            client_id: clientId,
            status: 'Pending',
            package_id: newSession.package_id || null,
            ad_hoc_purchase_id: newSession.ad_hoc_purchase_id || null
        };

        const { data, error } = await supabase
            .from('client_sessions')
            .insert(sessionData)
            .select();

        if (error) {
            console.error('Error adding session:', error);
        } else {
            onUpdate();
            setIsAdding(false);
        }
    };

    const handleEdit = async (updatedSession) => {
        if (!user) return;

        const sessionData = {
            ...updatedSession,
            package_id: updatedSession.package_id || null,
            ad_hoc_purchase_id: updatedSession.ad_hoc_purchase_id || null
        };

        const { data, error } = await supabase
            .from('client_sessions')
            .update(sessionData)
            .eq('id', updatedSession.id)
            .select();

        if (error) {
            console.error('Error updating session:', error);
        } else {
            onUpdate();
            setIsEditing(false);
            setCurrentSession(null);
        }
    };
    const handleDelete = async (sessionId) => {
        if (!user) return;

        const { error } = await supabase
            .from('client_sessions')
            .delete()
            .eq('id', sessionId);

        if (error) {
            console.error('Error deleting session:', error);
        } else {
            onUpdate();
        }
    };

    const handleStatusChange = async (sessionId, newStatus) => {
        if (!user) return;

        const { data: sessionData, error: sessionError } = await supabase
            .from('client_sessions')
            .update({ status: newStatus })
            .eq('id', sessionId)
            .select();

        if (sessionError) {
            console.error('Error updating session status:', sessionError);
            return;
        }

        const session = sessionData[0];

        if (newStatus === 'Completed' || newStatus === 'Cancelled' || newStatus === 'No-show') {
            if (session.package_id) {
                const { data: packageData, error: packageError } = await supabase
                    .from('client_packages')
                    .select('remaining_sessions')
                    .eq('id', session.package_id)
                    .single();

                if (packageError) {
                    console.error('Error fetching package:', packageError);
                    return;
                }

                const newRemainingSession = packageData.remaining_sessions - 1;

                const { error: updateError } = await supabase
                    .from('client_packages')
                    .update({ remaining_sessions: newRemainingSession })
                    .eq('id', session.package_id);

                if (updateError) {
                    console.error('Error updating package:', updateError);
                }
            } else if (session.ad_hoc_purchase_id) {
                const { data: purchaseData, error: purchaseError } = await supabase
                    .from('client_ad_hoc_purchases')
                    .select('remaining_quantity')
                    .eq('id', session.ad_hoc_purchase_id)
                    .single();

                if (purchaseError) {
                    console.error('Error fetching ad hoc purchase:', purchaseError);
                    return;
                }

                const newRemainingQuantity = purchaseData.remaining_quantity - 1;

                const { error: updateError } = await supabase
                    .from('client_ad_hoc_purchases')
                    .update({ remaining_quantity: newRemainingQuantity })
                    .eq('id', session.ad_hoc_purchase_id);

                if (updateError) {
                    console.error('Error updating ad hoc purchase:', updateError);
                }
            }
        }

        onUpdate();
    };

    return (
        <div>
            <h3 className="text-xl font-semibold mb-4">Sessions</h3>
            {sessions.map((session) => (
                <div key={session.id} className="mb-4 p-4 border rounded-lg">
                    <h4 className="font-semibold">Date: {session.date}</h4>
                    <p>Type: {session.type}</p>
                    <p>Status: {session.status}</p>
                    <p>Package: {packages.find(pkg => pkg.id === session.package_id)?.name || 'N/A'}</p>
                    <p>Ad Hoc Purchase: {adHocPurchases.find(purchase => purchase.id === session.ad_hoc_purchase_id)?.item || 'N/A'}</p>
                    <p>Notes: {session.notes || 'N/A'}</p>
                    <div className="mt-2">
                        <button 
                            onClick={() => {
                                setCurrentSession(session);
                                setIsEditing(true);
                            }}
                            className="mr-2 text-blue-500 hover:text-blue-700"
                        >
                            <Edit size={18} />
                        </button>
                        <button 
                            onClick={() => handleDelete(session.id)}
                            className="mr-2 text-red-500 hover:text-red-700"
                        >
                            <Trash size={18} />
                        </button>
                        <select
                            value={session.status}
                            onChange={(e) => handleStatusChange(session.id, e.target.value)}
                            className="p-1 border rounded"
                        >
                            <option value="Pending">Pending</option>
                            <option value="Completed">Completed</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="No-show">No-show</option>
                        </select>
                    </div>
                </div>
            ))}
            <button
                onClick={() => setIsAdding(true)}
                className="mt-4 bg-green-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-green-600 transition-colors"
            >
                <Plus size={20} className="mr-2" />
                Add New Session
            </button>
            {(isAdding || isEditing) && (
                <SessionForm
                    onSubmit={isAdding ? handleAdd : handleEdit}
                    onCancel={() => {
                        setIsAdding(false);
                        setIsEditing(false);
                        setCurrentSession(null);
                    }}
                    initialData={currentSession}
                    packages={packages}
                    adHocPurchases={adHocPurchases}
                />
            )}
        </div>
    );
};
const SessionForm = ({ onSubmit, onCancel, initialData, packages = [], adHocPurchases = [] }) => {
    const [formData, setFormData] = useState(initialData || {
        date: new Date().toISOString().split('T')[0],
        type: '',
        package_id: '',
        ad_hoc_purchase_id: '',
        notes: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit} className="mt-4 p-4 border rounded-lg">
            <div className="mb-4">
                <label className="block mb-2">Date:</label>
                <input
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Type:</label>
                <input
                    type="text"
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Package:</label>
                <select
                    name="package_id"
                    value={formData.package_id}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                >
                    <option value="">Select a package</option>
                    {packages.map(pkg => (
                        <option key={pkg.id} value={pkg.id}>{pkg.name}</option>
                    ))}
                </select>
            </div>
            <div className="mb-4">
                <label className="block mb-2">Ad Hoc Purchase:</label>
                <select
                    name="ad_hoc_purchase_id"
                    value={formData.ad_hoc_purchase_id}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                >
                    <option value="">Select an ad hoc purchase</option>
                    {adHocPurchases.map(purchase => (
                        <option key={purchase.id} value={purchase.id}>{purchase.item}</option>
                    ))}
                </select>
            </div>
            <div className="mb-4">
                <label className="block mb-2">Notes:</label>
                <textarea
                    name="notes"
                    value={formData.notes}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    rows="3"
                />
            </div>
            <div className="flex justify-end">
                <button
                    type="button"
                    onClick={onCancel}
                    className="mr-2 px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                >
                    {initialData ? 'Update' : 'Add'} Session
                </button>
            </div>
        </form>
    );
};

export default ManageSessions;