import React, { useState, useEffect } from 'react';
import { supabase } from '../utils/supabase';
import { useSession } from '@supabase/auth-helpers-react';

export const StripeBalance = () => {
    const [balance, setBalance] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const session = useSession();
  
    useEffect(() => {
      const checkSession = async () => {
        const { data: { session: supabaseSession } } = await supabase.auth.getSession();
        console.log("Current session from Supabase:", supabaseSession);
      };
      checkSession();
    }, []);
  
    useEffect(() => {
      console.log("Session state from useSession hook:", session);
      if (session) {
        fetchBalance();
      } else {
        setLoading(false);
      }
    }, [session]);
  
    const fetchBalance = async () => {
      console.log("Fetching balance...");
      try {
        const { data, error } = await supabase.functions.invoke('stripe', {
          body: { action: 'get-stripe-balance', user_id: session.user.id }
        });
        console.log("Balance response:", data, error);
        if (error) throw error;
        setBalance(data);
      } catch (error) {
        console.error("Error fetching balance:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    console.log("Render state:", { session, loading, error, balance });
  
    if (!session) return <div>Please log in to view balance.</div>;
    if (loading) return <div>Loading balance...</div>;
    if (error) return <div>Error: {error}</div>;
  
    return (
      <div>
        <h2 className="text-xl font-bold mb-4">Stripe Balance</h2>
        {balance && balance.available && balance.available.map((currency) => (
          <div key={currency.currency} className="mb-2">
            <p>
              <span className="font-semibold">{currency.currency.toUpperCase()}:</span> 
              {(currency.amount / 100).toFixed(2)}
            </p>
          </div>
        ))}
      </div>
    );
  };

  export const StripeTransactions = () => {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const session = useSession();
  
    useEffect(() => {
      if (session) {
        fetchTransactions();
      } else {
        setLoading(false);
      }
    }, [session]);
  
    const fetchTransactions = async () => {
      try {
        const { data, error } = await supabase.functions.invoke('stripe', {
          body: { action: 'list-transactions', user_id: session.user.id, limit: 20 }
        });
        if (error) throw error;
        setTransactions(data.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    if (!session) return <div>Please log in to view transactions.</div>;
    if (loading) return <div>Loading transactions...</div>;
    if (error) return <div>Error: {error}</div>;
  
    return (
      <div>
        <h2 className="text-xl font-bold mb-4">Recent Transactions</h2>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {transactions.map((transaction) => (
              <tr key={transaction.id}>
                <td className="px-6 py-4 whitespace-nowrap">{transaction.id}</td>
                <td className="px-6 py-4 whitespace-nowrap">{(transaction.amount / 100).toFixed(2)} {transaction.currency.toUpperCase()}</td>
                <td className="px-6 py-4 whitespace-nowrap">{transaction.status}</td>
                <td className="px-6 py-4 whitespace-nowrap">{new Date(transaction.created * 1000).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

export const StripePendingInvoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPendingInvoices();
  }, []);

  const fetchPendingInvoices = async () => {
    try {
      const { data, error } = await supabase.functions.invoke('stripe', {
        body: { action: 'list-pending-invoices', user_id: supabase.auth.user().id, limit: 10 }
      });
      if (error) throw error;
      setInvoices(data.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading pending invoices...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Pending Invoices</h2>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount Due</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Due Date</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {invoices.map((invoice) => (
            <tr key={invoice.id}>
              <td className="px-6 py-4 whitespace-nowrap">{invoice.id}</td>
              <td className="px-6 py-4 whitespace-nowrap">{(invoice.amount_due / 100).toFixed(2)} {invoice.currency.toUpperCase()}</td>
              <td className="px-6 py-4 whitespace-nowrap">{invoice.customer_email}</td>
              <td className="px-6 py-4 whitespace-nowrap">{new Date(invoice.due_date * 1000).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const StripeSubscriptions = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const { data, error } = await supabase.functions.invoke('stripe', {
        body: { action: 'list-subscriptions', user_id: supabase.auth.user().id, limit: 20 }
      });
      if (error) throw error;
      setSubscriptions(data.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading subscriptions...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Active Subscriptions</h2>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Current Period End</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {subscriptions.map((subscription) => (
            <tr key={subscription.id}>
              <td className="px-6 py-4 whitespace-nowrap">{subscription.id}</td>
              <td className="px-6 py-4 whitespace-nowrap">{subscription.customer}</td>
              <td className="px-6 py-4 whitespace-nowrap">{subscription.status}</td>
              <td className="px-6 py-4 whitespace-nowrap">{new Date(subscription.current_period_end * 1000).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};