import React, { useState, useEffect } from 'react';
import { fetchAndStoreFitnessArticles, getFitnessArticlesFromSupabase, isArticlesOutdated, updateFitnessArticle } from './FitnessArticlesFetcher';
import { supabase } from '../utils/supabase';
import { Trash2, RefreshCw, Save, CheckSquare, Square, Edit, ExternalLink } from 'lucide-react';

const FitnessArticlesManager = () => {
  const [articles, setArticles] = useState([]);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editingArticle, setEditingArticle] = useState(null);

  useEffect(() => {
    loadArticles();
  }, []);

  const loadArticles = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let fetchedArticles = await getFitnessArticlesFromSupabase();
      if (fetchedArticles.length === 0 || isArticlesOutdated(fetchedArticles[0].published_at)) {
        await fetchAndStoreFitnessArticles();
        fetchedArticles = await getFitnessArticlesFromSupabase();
      }
      setArticles(fetchedArticles);
    } catch (err) {
      setError('Failed to load articles. Please try again.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRefresh = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await fetchAndStoreFitnessArticles();
      const refreshedArticles = await getFitnessArticlesFromSupabase();
      setArticles(refreshedArticles);
    } catch (err) {
      setError('Failed to refresh articles. Please try again.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelect = (articleId) => {
    setSelectedArticles(prev => 
      prev.includes(articleId) 
        ? prev.filter(id => id !== articleId)
        : [...prev, articleId]
    );
  };

  const handleSelectAll = () => {
    if (selectedArticles.length === articles.length) {
      setSelectedArticles([]);
    } else {
      setSelectedArticles(articles.map(article => article.id));
    }
  };

  const handleDelete = async () => {
    if (selectedArticles.length === 0) return;

    setIsLoading(true);
    setError(null);
    try {
      const { error } = await supabase
        .from('fitness_articles')
        .delete()
        .in('id', selectedArticles);

      if (error) throw error;

      setArticles(prev => prev.filter(article => !selectedArticles.includes(article.id)));
      setSelectedArticles([]);
    } catch (err) {
      setError('Failed to delete selected articles. Please try again.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (article) => {
    setEditingArticle({ ...article });
  };

  const handleSave = async () => {
    if (!editingArticle) return;

    setIsLoading(true);
    setError(null);
    try {
      const { data, error } = await updateFitnessArticle(editingArticle.id, editingArticle);
      if (error) throw error;

      setArticles(prev => prev.map(article => 
        article.id === editingArticle.id ? editingArticle : article
      ));
      setEditingArticle(null);
    } catch (err) {
      setError('Failed to save article. Please try again.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditingArticle(prev => ({ ...prev, [name]: value }));
  };

  const handleSaveSelected = async () => {
    if (selectedArticles.length === 0) return;

    setIsLoading(true);
    setError(null);
    try {
      const selectedArticlesData = articles.filter(article => selectedArticles.includes(article.id));
      const { data, error } = await supabase
        .from('fitness_articles')
        .upsert(selectedArticlesData, { onConflict: 'id' });

      if (error) throw error;

      setError('Selected articles saved successfully.');
    } catch (err) {
      setError('Failed to save selected articles. Please try again.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Fitness Articles Manager</h1>
      
      <div className="mb-4 flex justify-between items-center">
        <button 
          onClick={handleRefresh}
          className="bg-blue-500 text-white px-4 py-2 rounded flex items-center"
          disabled={isLoading}
        >
          <RefreshCw size={20} className="mr-2" />
          Refresh Articles
        </button>
        <div>
          <button 
            onClick={handleSelectAll}
            className="bg-gray-200 text-gray-800 px-4 py-2 rounded mr-2 flex items-center"
          >
            {selectedArticles.length === articles.length ? <CheckSquare size={20} className="mr-2" /> : <Square size={20} className="mr-2" />}
            {selectedArticles.length === articles.length ? 'Deselect All' : 'Select All'}
          </button>
          <button 
            onClick={handleSaveSelected}
            className="bg-green-500 text-white px-4 py-2 rounded flex items-center mr-2"
            disabled={selectedArticles.length === 0 || isLoading}
          >
            <Save size={20} className="mr-2" />
            Save Selected
          </button>
          <button 
            onClick={handleDelete}
            className="bg-red-500 text-white px-4 py-2 rounded flex items-center"
            disabled={selectedArticles.length === 0 || isLoading}
          >
            <Trash2 size={20} className="mr-2" />
            Delete Selected
          </button>
        </div>
      </div>

      {error && <p className="text-red-500 mb-4">{error}</p>}

      {isLoading ? (
        <p>Loading articles...</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {articles.map(article => (
            <div key={article.id} className="border p-4 rounded shadow-md">
              {editingArticle && editingArticle.id === article.id ? (
                <div>
                  <input 
                    type="text"
                    name="title"
                    value={editingArticle.title}
                    onChange={handleChange}
                    className="w-full mb-2 p-2 border rounded"
                  />
                  <textarea
                    name="summary"
                    value={editingArticle.summary}
                    onChange={handleChange}
                    className="w-full mb-2 p-2 border rounded"
                    rows="3"
                  />
                  <input 
                    type="text"
                    name="image_url"
                    value={editingArticle.image_url}
                    onChange={handleChange}
                    className="w-full mb-2 p-2 border rounded"
                    placeholder="Image URL"
                  />
                  <div className="flex justify-between">
                    <button
                      onClick={handleSave}
                      className="bg-green-500 text-white px-4 py-2 rounded flex items-center mr-2"
                    >
                      <Save size={20} className="mr-2" />
                      Save
                    </button>
                    <button
                      onClick={() => setEditingArticle(null)}
                      className="bg-gray-500 text-white px-4 py-2 rounded"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="flex items-start mb-2">
                    <input 
                      type="checkbox"
                      checked={selectedArticles.includes(article.id)}
                      onChange={() => handleSelect(article.id)}
                      className="mr-2 mt-1"
                    />
                    <h2 className="text-lg font-semibold">{article.title}</h2>
                  </div>
                  {article.image_url && (
                    <img 
                      src={article.image_url} 
                      alt={article.title} 
                      className="w-full h-40 object-cover mb-2 rounded"
                    />
                  )}
                  <p className="text-sm text-gray-600 mb-2">{article.summary}</p>
                  <p className="text-xs text-gray-500 mb-2">Source: {article.source}</p>
                  <p className="text-xs text-gray-500 mb-2">Published: {new Date(article.published_at).toLocaleString()}</p>
                  <div className="flex justify-between items-center">
                    <a 
                      href={article.url} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="text-blue-500 hover:underline flex items-center"
                    >
                      Read More
                      <ExternalLink size={14} className="ml-1" />
                    </a>
                    <button
                      onClick={() => handleEdit(article)}
                      className="bg-yellow-500 text-white px-2 py-1 rounded flex items-center"
                    >
                      <Edit size={16} className="mr-1" />
                      Edit
                    </button>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FitnessArticlesManager;