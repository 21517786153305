import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { supabase, getCurrentSession } from '../utils/supabase';

const ProtectedAdminRoute = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAdminStatus = async () => {
      const session = await getCurrentSession();
      if (session) {
        const { data: profile, error } = await supabase
          .from('user_profiles')
          .select('role')
          .eq('id', session.user.id)
          .single();

        if (error) {
          console.error('Error checking admin status:', error);
        } else {
          setIsAdmin(profile.role === 'admin');
        }
      }
      setIsLoading(false);
    };

    checkAdminStatus();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAdmin ? children : <Navigate to="/login" replace />;
};

export default ProtectedAdminRoute;