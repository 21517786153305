import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { User, MapPin, Calendar, Star, Heart, Target, Clock, ArrowLeft } from 'lucide-react';
import { supabase } from '../utils/supabase';

const PublicProfile = ({ buddies, currentUser }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState(null);

  useEffect(() => {
    const foundUser = buddies.find(buddy => buddy.id === parseInt(id));
    if (foundUser) {
      setUserData(foundUser);
      checkConnectionStatus(foundUser.id);
    }
  }, [id, buddies, currentUser]);

  const checkConnectionStatus = async (buddyId) => {
    if (!currentUser) return;

    const { data, error } = await supabase
      .from('buddy_connections')
      .select('status')
      .or(`sender_id.eq.${currentUser.id},receiver_id.eq.${currentUser.id}`)
      .or(`sender_id.eq.${buddyId},receiver_id.eq.${buddyId}`)
      .single();

    if (error) {
      console.error('Error checking connection status:', error);
    } else if (data) {
      setConnectionStatus(data.status);
    }
  };

  const handleConnect = async () => {
    if (!currentUser || !userData) return;

    const { data, error } = await supabase
      .from('buddy_connections')
      .insert([
        { sender_id: currentUser.id, receiver_id: userData.id, status: 'pending' }
      ]);

    if (error) {
      console.error('Error sending connection request:', error);
    } else {
      setConnectionStatus('pending');
    }
  };

  if (!userData) {
    return <div>Loading user data...</div>;
  }

  const renderRating = (rating) => {
    return (
      <div className="flex items-center">
        {[...Array(5)].map((_, i) => (
          <Star key={i} size={16} className={`${i < Math.floor(rating) ? 'text-yellow-400' : 'text-gray-300'} fill-current`} />
        ))}
        <span className="ml-2 text-sm font-medium text-gray-700">{rating.toFixed(1)}</span>
      </div>
    );
  };

  const renderConnectionButton = () => {
    if (connectionStatus === 'accepted') {
      return <button className="w-full bg-green-500 text-white py-3 rounded-lg text-lg font-semibold" disabled>Connected</button>;
    } else if (connectionStatus === 'pending') {
      return <button className="w-full bg-yellow-500 text-white py-3 rounded-lg text-lg font-semibold" disabled>Connection Pending</button>;
    } else {
      return (
        <button 
          className="w-full bg-orange-500 text-white py-3 rounded-lg hover:bg-orange-600 transition-colors text-lg font-semibold"
          onClick={handleConnect}
        >
          Connect with {userData.name}
        </button>
      );
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <button onClick={() => navigate('/find-buddy')} className="text-orange-500 font-medium mb-4 flex items-center">
        <ArrowLeft size={20} className="mr-1" /> Back to Find Buddy
      </button>
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-6">
          <div className="text-center mb-4">
            <span className="text-sm font-medium text-orange-500 uppercase">{userData.fitnessLevel} Level</span>
          </div>
          <div className="flex flex-col md:flex-row items-center mb-6">
            <img src={userData.image} alt={userData.name} className="w-48 h-48 rounded-lg object-cover mr-6 mb-4 md:mb-0" />
            <div className="text-center md:text-left">
              <h2 className="text-3xl font-semibold">{userData.name}</h2>
              <p className="text-xl text-gray-600 mt-2">{userData.age} years old</p>
              <div className="flex items-center justify-center md:justify-start mt-2">
                <User className="mr-2" size={20} />
                <span>{userData.gender}</span>
                <MapPin className="ml-4 mr-2" size={20} />
                <span>{userData.distance} km away</span>
              </div>
              <div className="flex items-center justify-center md:justify-start mt-2">
                {renderRating(userData.rating)}
                <span className="ml-2">({userData.reviews || 0} reviews)</span>
              </div>
              <div className="flex items-center justify-center md:justify-start mt-2">
                <Heart className="mr-2 text-red-500" size={20} />
                <span>{userData.likes || 0} likes</span>
              </div>
            </div>
          </div>
          <div className="space-y-4">
            <div>
              <h3 className="font-semibold mb-2">Interests</h3>
              <div className="flex flex-wrap">
                {userData.interests.map((interest, index) => (
                  <span key={index} className="bg-orange-100 text-orange-800 text-sm font-medium mr-2 mb-2 px-2.5 py-0.5 rounded-full">
                    {interest}
                  </span>
                ))}
              </div>
            </div>
            <div>
              <h3 className="font-semibold mb-2">Availability</h3>
              <div className="flex flex-wrap">
                {userData.availability.map((time, index) => (
                  <span key={index} className="flex items-center bg-green-100 text-green-800 text-sm font-medium mr-2 mb-2 px-2.5 py-0.5 rounded-full">
                    <Clock size={14} className="mr-1" />
                    {time}
                  </span>
                ))}
              </div>
            </div>
            <div>
              <h3 className="font-semibold mb-2">Fitness Goals</h3>
              <ul className="list-disc list-inside">
                {userData.fitnessGoals.map((goal, index) => (
                  <li key={index} className="text-gray-600">{goal}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-200 p-6">
          {renderConnectionButton()}
        </div>
      </div>
    </div>
  );
};

export default PublicProfile;