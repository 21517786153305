import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreditCard, DollarSign, ArrowLeft, Trash2, Edit, Calendar, RotateCcw, ChevronDown, ChevronUp, ChevronLeft, ChevronRight } from 'lucide-react';
import { supabase } from '../utils/supabase';
import AddPaymentMethod from './AddPaymentMethod';

const Payment = () => {
  const navigate = useNavigate();
  const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [allTransactions, setAllTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingMethod, setEditingMethod] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const transactionsPerPage = 10;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      await Promise.all([fetchPaymentMethods(), fetchAllTransactions()]);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchPaymentMethods = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('No authenticated user found');

    const { data, error } = await supabase
      .from('payment_methods')
      .select('*')
      .eq('user_id', user.id);

    if (error) throw error;

    setPaymentMethods(data);
  };

  const fetchAllTransactions = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('No authenticated user found');

    const [regularTransactions, eventTransactions] = await Promise.all([
      supabase
        .from('transactions')
        .select('*, membership_plans:plan_id (name)')
        .eq('user_id', user.id),
      supabase
        .from('event_transactions')
        .select('*, events:event_id (name)')
        .eq('user_id', user.id)
    ]);

    if (regularTransactions.error) throw regularTransactions.error;
    if (eventTransactions.error) throw eventTransactions.error;

    const allTransactions = [
      ...regularTransactions.data.map(t => ({ ...t, transactionType: 'regular' })),
      ...eventTransactions.data.map(t => ({ ...t, transactionType: 'event' }))
    ].sort((a, b) => new Date(b.created_at || b.transaction_date) - new Date(a.created_at || a.transaction_date));

    setAllTransactions(allTransactions);
  };

  const handleAddPaymentMethod = async (newPaymentMethod) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user found');

      const { data, error } = await supabase
        .from('payment_methods')
        .insert([
          {
            user_id: user.id,
            type: newPaymentMethod.type,
            last_four: newPaymentMethod.cardNumber.slice(-4),
            expiry_month: newPaymentMethod.expiryMonth,
            expiry_year: newPaymentMethod.expiryYear,
            payment_processor: newPaymentMethod.paymentProcessor,
            payment_method_token: newPaymentMethod.paymentMethodToken || 'dummy_token',
            card_brand: newPaymentMethod.cardBrand || 'Unknown'
          }
        ])
        .select();

      if (error) throw error;

      setPaymentMethods([...paymentMethods, ...data]);
      setShowAddPaymentMethod(false);
    } catch (error) {
      console.error('Error adding payment method:', error);
      alert('Failed to add payment method. Please try again.');
    }
  };

  const handleEditPaymentMethod = async (updatedMethod) => {
    try {
      const { data, error } = await supabase
        .from('payment_methods')
        .update({
          type: updatedMethod.type,
          last_four: updatedMethod.cardNumber.slice(-4),
          expiry_month: updatedMethod.expiryMonth,
          expiry_year: updatedMethod.expiryYear,
          payment_processor: updatedMethod.paymentProcessor,
          card_brand: updatedMethod.cardBrand || 'Unknown'
        })
        .eq('id', updatedMethod.id)
        .select();

      if (error) throw error;

      setPaymentMethods(paymentMethods.map(method => 
        method.id === updatedMethod.id ? data[0] : method
      ));
      setEditingMethod(null);
    } catch (error) {
      console.error('Error updating payment method:', error);
      alert('Failed to update payment method. Please try again.');
    }
  };

  const handleDeletePaymentMethod = async (methodId) => {
    if (window.confirm('Are you sure you want to delete this payment method?')) {
      try {
        const { error } = await supabase
          .from('payment_methods')
          .delete()
          .eq('id', methodId);

        if (error) throw error;

        setPaymentMethods(paymentMethods.filter(method => method.id !== methodId));
      } catch (error) {
        console.error('Error deleting payment method:', error);
        alert('Failed to delete payment method. Please try again.');
      }
    }
  };

  const formatDate = (dateString) => {
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  const getTransactionDescription = (transaction) => {
    if (transaction.transactionType === 'event') {
      return `Event payment: ${transaction.events?.name || 'Unknown event'}`;
    }
    if (transaction.membership_plans && transaction.membership_plans.name) {
      return `Subscription payment for ${transaction.membership_plans.name}`;
    }
    return transaction.description || 'Transaction';
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    setCurrentPage(1);  // Reset to first page when toggling
  };

  const renderTransaction = (transaction) => {
    const isRefund = transaction.refund_status === 'refunded' || 
                     transaction.payment_status === 'refunded' ||
                     transaction.payment_method === 'refund';

    return (
      <div key={transaction.id} className="bg-white rounded-lg shadow-md p-4 mb-4">
        <div className="flex items-center justify-between">
          <div className="flex-1">
            <p className="font-semibold">{getTransactionDescription(transaction)}</p>
            <p className="text-sm text-gray-600">{formatDate(transaction.created_at || transaction.transaction_date)}</p>
            <p className="text-sm text-gray-600">Payment Method: {transaction.payment_method || 'N/A'}</p>
            {transaction.transactionType === 'event' && (
              <p className="text-sm text-gray-600">Status: {transaction.payment_status}</p>
            )}
          </div>
          <div className="flex items-center">
            {isRefund ? (
              <RotateCcw size={16} className="text-red-500 mr-1" />
            ) : transaction.transactionType === 'event' ? (
              <Calendar size={16} className="text-gray-500 mr-1" />
            ) : (
              <DollarSign size={16} className="text-gray-500 mr-1" />
            )}
            <span className={isRefund ? 'text-red-500' : 'text-green-500'}>
              {isRefund ? '-' : ''}${transaction.amount.toFixed(2)}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = isExpanded 
    ? allTransactions.slice(indexOfFirstTransaction, indexOfLastTransaction)
    : allTransactions.slice(0, 3);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return <div className="max-w-4xl mx-auto p-4">Loading...</div>;
  }

  if (error) {
    return <div className="max-w-4xl mx-auto p-4 text-red-500">{error}</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-4 pb-20 sm:pb-4">
      <div className="mb-6">
        <button onClick={() => navigate('/')} className="text-orange-500 font-medium flex items-center mb-4">
          <ArrowLeft size={20} className="mr-1" /> Back to Dashboard
        </button>
        <h1 className="text-2xl font-bold">Payment</h1>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Payment Methods</h2>
        {paymentMethods.length === 0 ? (
          <p className="text-gray-600 italic">No payment method configured. Add a payment method to make purchases.</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {paymentMethods.map((method) => (
              <div key={method.id} className="bg-white rounded-lg shadow-md p-4 flex flex-col sm:flex-row items-center justify-between">
                <div className="flex items-center mb-2 sm:mb-0">
                  <CreditCard size={24} className="text-gray-500 mr-4" />
                  <div>
                    <p className="font-semibold">{method.type} ending in {method.last_four}</p>
                    <p className="text-sm text-gray-600">Expires {method.expiry_month}/{method.expiry_year}</p>
                  </div>
                </div>
                <div className="flex">
                  <button
                    onClick={() => setEditingMethod(method)}
                    className="text-blue-500 hover:text-blue-700 mr-2"
                  >
                    <Edit size={20} />
                  </button>
                  <button
                    onClick={() => handleDeletePaymentMethod(method.id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <Trash2 size={20} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
        <button 
          className="mt-4 bg-orange-500 text-white py-2 px-4 rounded-lg hover:bg-orange-600 transition-colors"
          onClick={() => setShowAddPaymentMethod(true)}
        >
          Add Payment Method
        </button>
      </div>

      <div>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Transaction History</h2>
          <button 
            onClick={toggleExpand} 
            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors"
          >
            {isExpanded ? 'Merge Transactions' : 'Expand Transactions'}
          </button>
        </div>
        {allTransactions.length === 0 ? (
          <p className="text-gray-600 italic">No transactions found. Your transaction history will appear here once you make a purchase or receive a refund.</p>
        ) : (
          <>
            {currentTransactions.map(renderTransaction)}
            {isExpanded && (
              <div className="flex justify-center mt-4">
                <button 
                  onClick={() => paginate(currentPage - 1)} 
                  disabled={currentPage === 1}
                  className="mr-2 px-3 py-1 bg-gray-200 rounded-md disabled:opacity-50"
                >
                  <ChevronLeft size={20} />
                </button>
                <span className="mx-2">Page {currentPage} of {Math.ceil(allTransactions.length / transactionsPerPage)}</span>
                <button 
                  onClick={() => paginate(currentPage + 1)} 
                  disabled={currentPage === Math.ceil(allTransactions.length / transactionsPerPage)}
                  className="ml-2 px-3 py-1 bg-gray-200 rounded-md disabled:opacity-50"
                >
                  <ChevronRight size={20} />
                </button>
              </div>
            )}
          </>
        )}
      </div>

      {(showAddPaymentMethod || editingMethod) && (
        <AddPaymentMethod
          onClose={() => {
            setShowAddPaymentMethod(false);
            setEditingMethod(null);
          }}
          onAddPaymentMethod={handleAddPaymentMethod}
          onEditPaymentMethod={handleEditPaymentMethod}
          editingMethod={editingMethod}
        />
      )}
    </div>
  );
};

export default Payment;