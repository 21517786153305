import React, { useState, useEffect } from 'react';
import AIMealPlannerPro from './AIMealPlannerPro';
import { supabase } from '../utils/supabase';

const TrainerAIMealPlanner = ({ trainer }) => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchClients();
  }, [trainer]);

  const fetchClients = async () => {
    setIsLoading(true);
    setError(null);
    const { data, error } = await supabase
      .from('clients')
      .select('*')
      .eq('trainer_id', trainer.id);

    if (error) {
      console.error('Error fetching clients:', error);
      setError('Failed to fetch clients. Please try again.');
    } else {
      setClients(data);
    }
    setIsLoading(false);
  };

  const handleClientSelect = (clientId) => {
    const client = clients.find(c => c.id === clientId);
    setSelectedClient(client);
  };

  const handleAssignMealPlan = async (mealPlan) => {
    if (!selectedClient) {
      alert('Please select a client first');
      return;
    }

    const { data, error } = await supabase
      .from('meal_plans')
      .insert({
        client_id: selectedClient.id,
        trainer_id: trainer.id,
        plan: mealPlan,
        assigned_at: new Date().toISOString(),
      });

    if (error) {
      console.error('Error assigning meal plan:', error);
      alert('Failed to assign meal plan. Please try again.');
    } else {
      alert('Meal plan assigned successfully!');
    }
  };

  if (isLoading) {
    return <div>Loading clients...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4">AI Meal Planner for Clients</h2>
      <div className="mb-4">
        <label htmlFor="client-select" className="block text-sm font-medium text-gray-700">Select Client</label>
        <select
          id="client-select"
          className="block w-80 px-4 py-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm rounded-md"
          onChange={(e) => handleClientSelect(e.target.value)}
          value={selectedClient?.id || ''}
          disabled={clients.length === 0}
        >
          <option value="">Select a client</option>
          {clients.map((client) => (
            <option key={client.id} value={client.id}>{client.name}</option>
          ))}
        </select>
        {clients.length === 0 && <p className="mt-2 text-sm text-gray-500">No clients available</p>}
      </div>
      {selectedClient && (
        <AIMealPlannerPro
          currentUser={selectedClient}
          onSaveMealPlan={handleAssignMealPlan}
          trainer={trainer}
        />
      )}
    </div>
  );
};

export default TrainerAIMealPlanner;