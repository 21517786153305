import { supabase } from './supabase';

export const fetchUserProgressData = async (userId) => {
  const [
    buddiesCount,
    eventsAttended,
    upcomingEvents,
    workoutsCompleted,
    reviewsReceived,
    favoriteBuddies,
    daysConnected,
    loginCount,
  ] = await Promise.all([
    fetchBuddiesCount(userId),
    fetchEventsAttended(userId),
    fetchUpcomingEvents(userId),
    fetchWorkoutsCompleted(userId),
    fetchReviewsReceived(userId),
    fetchFavoriteBuddiesCount(userId),
    fetchDaysConnected(userId),
    fetchLoginCount(userId),
  ]);

  return {
    buddies: buddiesCount,
    eventsAttended,
    upcomingEvents,
    workoutsCompleted,
    reviewsReceived,
    favoriteBuddies,
    streakDays: daysConnected,
    loginCount,
    trophiesEarned: 0, // Placeholder, implement if you have this data
  };
};

const fetchBuddiesCount = async (userId) => {
  const { count, error } = await supabase
    .from('buddy_connections')
    .select('*', { count: 'exact' })
    .or(`sender_id.eq.${userId},receiver_id.eq.${userId}`)
    .eq('status', 'accepted');

  if (error) throw error;
  return count;
};

const fetchEventsAttended = async (userId) => {
  const { count, error } = await supabase
    .from('event_attendees')
    .select('*', { count: 'exact' })
    .eq('user_id', userId)
    .eq('status', 'confirmed');

  if (error) throw error;
  return count;
};

const fetchUpcomingEvents = async (userId) => {
  const { count, error } = await supabase
    .from('event_attendees')
    .select('*', { count: 'exact' })
    .eq('user_id', userId)
    .eq('status', 'confirmed')
    .gte('created_at', new Date().toISOString());

  if (error) throw error;
  return count;
};

const fetchWorkoutsCompleted = async (userId) => {
  const { count, error } = await supabase
    .from('workout_participants')
    .select('*', { count: 'exact' })
    .eq('user_id', userId)
    .eq('status', 'completed');

  if (error) throw error;
  return count;
};

const fetchReviewsReceived = async (userId) => {
  const { count, error } = await supabase
    .from('buddy_reviews')
    .select('*', { count: 'exact' })
    .eq('buddy_id', userId);

  if (error) throw error;
  return count;
};

const fetchFavoriteBuddiesCount = async (userId) => {
  const { count, error } = await supabase
    .from('favorite_buddies')
    .select('*', { count: 'exact' })
    .eq('user_id', userId);

  if (error) throw error;
  return count;
};

const fetchDaysConnected = async (userId) => {
  const { data, error } = await supabase
    .from('user_profiles')
    .select('created_at')
    .eq('id', userId)
    .single();

  if (error) {
    console.error('Error fetching user creation date:', error);
    return 0;
  }

  if (data && data.created_at) {
    const createdDate = new Date(data.created_at);
    const today = new Date();
    const diffTime = Math.abs(today - createdDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  return 0;
};

const fetchLoginCount = async (userId) => {
  const { count, error } = await supabase
    .from('user_logins')
    .select('*', { count: 'exact' })
    .eq('user_id', userId);

  if (error) throw error;
  return count;
};