import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MapPin, Star, User, Clock, Activity, Send, Heart } from 'lucide-react';
import { supabase } from '../utils/supabase';
import BuddyReviewsSection from './BuddyReviewsSection';

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
const STORAGE_URL = `${SUPABASE_URL}/storage/v1/object/public/profile_images/`;

const BuddyProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [buddy, setBuddy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState(null);
  const [averageRating, setAverageRating] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);
  const [userRating, setUserRating] = useState(0);
  const [userReview, setUserReview] = useState('');
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  const [showReportModal, setShowReportModal] = useState(false);
  const [reportReason, setReportReason] = useState('');
  const [connectionPreference, setConnectionPreference] = useState('everyone');
  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {
    fetchCurrentUser();
    fetchBuddyData();
    fetchRatingsAndReviews();
    fetchLikesCount();
    fetchBuddyConnectionPreference();
    checkIfLiked();
  }, [id]);

  useEffect(() => {
    if (currentUser) {
      checkConnectionStatus();
    }
  }, [currentUser, id]);

  const fetchCurrentUser = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data, error } = await supabase
        .from('user_profiles')
        .select('*')
        .eq('id', user.id)
        .single();
      if (error) {
        console.error('Error fetching current user profile:', error);
      } else {
        setCurrentUser(data);
      }
    }
  };

  const fetchBuddyData = async () => {
    try {
      const { data, error } = await supabase
        .from('user_profiles')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;
      setBuddy(data);
    } catch (error) {
      console.error('Error fetching buddy data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchBuddyConnectionPreference = async () => {
    const { data, error } = await supabase
      .from('user_profiles')
      .select('connection_preference')
      .eq('id', id)
      .single();

    if (error) {
      console.error('Error fetching buddy connection preference:', error);
    } else {
      setConnectionPreference(data.connection_preference);
    }
  };

  const fetchRatingsAndReviews = async () => {
    const { data, error } = await supabase
      .from('buddy_reviews')
      .select('rating')
      .eq('buddy_id', id);

    if (error) {
      console.error('Error fetching ratings:', error);
    } else {
      const totalRating = data.reduce((sum, review) => sum + review.rating, 0);
      setAverageRating(totalRating / data.length || 0);
      setTotalReviews(data.length);
    }

    // Check if the current user has already reviewed this buddy
    if (currentUser) {
      const { data: userReview, error: userReviewError } = await supabase
        .from('buddy_reviews')
        .select('rating, review_text')
        .eq('buddy_id', id)
        .eq('reviewer_id', currentUser.id)
        .single();

      if (userReview) {
        setUserRating(userReview.rating);
        setUserReview(userReview.review_text || '');
      }
    }
  };

  const fetchLikesCount = async () => {
    const { count, error } = await supabase
      .from('favorite_buddies')
      .select('*', { count: 'exact' })
      .eq('buddy_id', id);

    if (error) {
      console.error('Error fetching likes count:', error);
    } else {
      setLikesCount(count);
    }
  };

  const handleRatingSubmit = async () => {
    if (!currentUser) {
      alert('You must be logged in to submit a review.');
      return;
    }

    const { data, error } = await supabase
      .from('buddy_reviews')
      .upsert({
        reviewer_id: currentUser.id,
        buddy_id: id,
        rating: userRating,
        review_text: userReview
      }, { onConflict: ['reviewer_id', 'buddy_id'] });

    if (error) {
      console.error('Error submitting review:', error);
      alert('Failed to submit review. Please try again.');
    } else {
      alert('Review submitted successfully!');
      fetchRatingsAndReviews();
      setShowReviewForm(false);
    }
  };

  const renderStars = (rating, interactive = false) => (
    <div className="flex items-center">
      {[1, 2, 3, 4, 5].map((star) => (
        <Star
          key={star}
          size={interactive ? 32 : 24}
          className={`${
            star <= rating ? 'text-yellow-400 fill-current' : 'text-gray-300'
          } ${interactive ? 'cursor-pointer' : ''}`}
          onClick={() => interactive && setUserRating(star)}
        />
      ))}
    </div>
  );
  
  const checkIfLiked = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      try {
        const { data, error } = await supabase
          .from('favorite_buddies')
          .select('*')
          .eq('user_id', user.id)
          .eq('buddy_id', id);
  
        if (error) throw error;
  
        setIsLiked(data && data.length > 0);
      } catch (error) {
        console.error('Error checking if liked:', error);
        // Set isLiked to false in case of error
        setIsLiked(false);
      }
    }
  };

  const handleLikeToggle = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      try {
        if (isLiked) {
          // Unlike
          const { error } = await supabase
            .from('favorite_buddies')
            .delete()
            .eq('user_id', user.id)
            .eq('buddy_id', id);
  
          if (error) throw error;
  
          setIsLiked(false);
          setLikesCount(prevCount => prevCount - 1);
        } else {
          // Like
          const { error } = await supabase
            .from('favorite_buddies')
            .insert({ user_id: user.id, buddy_id: id });
  
          if (error) throw error;
  
          setIsLiked(true);
          setLikesCount(prevCount => prevCount + 1);
        }
      } catch (error) {
        console.error('Error toggling like:', error);
        alert('Failed to update like status. Please try again.');
      }
    } else {
      alert('You must be logged in to like a buddy.');
    }
  };

  const checkConnectionStatus = async () => {
    if (!currentUser) return;
  
    try {
      const { data, error } = await supabase
        .from('buddy_connections')
        .select('*')
        .or(`sender_id.eq.${currentUser.id},receiver_id.eq.${currentUser.id}`)
        .eq('receiver_id', id);
  
      if (error) {
        console.error('Error checking connection status:', error);
        return;
      }
  
      if (data && data.length > 0) {
        const connection = data[0];
        if (connection.status === 'accepted') {
          setConnectionStatus('accepted');
        } else if (connection.status === 'pending') {
          setConnectionStatus(connection.sender_id === currentUser.id ? 'pending_sent' : 'pending_received');
        }
      } else {
        setConnectionStatus('not_connected');
      }
    } catch (error) {
      console.error('Error in checkConnectionStatus:', error);
    }
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    const d = R * c; // Distance in km
    return d.toFixed(1);
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI/180);
  };

  const getImageUrl = (imagePath) => {
    if (!imagePath) return 'https://via.placeholder.com/150';
    if (imagePath.startsWith('http')) return imagePath;
    return `${STORAGE_URL}${imagePath}`;
  };

  const handleConnect = async () => {
    if (!currentUser) {
      alert('You must be logged in to send a connection request.');
      return;
    }

    // Check connection preference
    switch (connectionPreference) {
      case 'everyone':
        // Proceed with connection
        break;
      case 'males_only':
        if (currentUser.gender !== 'Male') {
          alert('This user only accepts connections from males.');
          return;
        }
        break;
      case 'females_only':
        if (currentUser.gender !== 'Female') {
          alert('This user only accepts connections from females.');
          return;
        }
        break;
      case 'no_one':
        alert('This user is not accepting new connections at this time.');
        return;
      default:
        console.error('Unknown connection preference:', connectionPreference);
        return;
    }

    try {
      const { data, error } = await supabase
        .from('buddy_connections')
        .insert({
          sender_id: currentUser.id,
          receiver_id: id,
          status: 'pending'
        });

      if (error) throw error;

      setConnectionStatus('pending_sent');
      alert('Connection request sent!');
    } catch (error) {
      console.error('Error sending connection request:', error);
      alert('Failed to send connection request. Please try again.');
    }
  };

  const handleOpenReportModal = () => setShowReportModal(true);
  const handleCloseReportModal = () => {
    setShowReportModal(false);
    setReportReason('');
  };

  const handleReportSubmit = async (reason) => {
    if (!reason) {
      alert('Please select a reason for reporting.');
      return;
    }
  
    try {
      const { error } = await supabase.functions.invoke('send-email', {
        body: JSON.stringify({
          type: 'report_profile',
          to: 'support@fitbuddyweb.com',
          reportedProfile: {
            name: buddy.name,
            id: buddy.id,
          },
          reportedBy: {
            name: currentUser?.name || 'Anonymous',
            id: currentUser?.id || 'Unknown',
          },
          reason: reason,
        })
      });
  
      if (error) throw error;
  
      alert('Report submitted successfully. Thank you for helping keep our community safe.');
      handleCloseReportModal();
    } catch (error) {
      console.error('Error submitting report:', error);
      alert('Failed to submit report. Please try again later.');
    }
  };

  const ReportModal = ({ isOpen, onClose, onSubmit }) => {
    const [selectedReason, setSelectedReason] = useState('');
    const reportReasons = [
      "I just don't like it",
      "It's spam",
      "Nudity or sexual activity",
      "Hate speech or symbols",
      "False information",
      "Bullying or harassment",
      "Violence or dangerous organisations",
      "Scam or fraud",
      "Intellectual property violation"
    ];
  
    const handleSubmit = () => {
      if (selectedReason) {
        onSubmit(selectedReason);
      } else {
        alert('Please select a reason for reporting.');
      }
    };
  
    return (
      isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">Report</h2>
            <p className="text-gray-600 mb-4">
              Why are you reporting this profile? Your report is anonymous, except if you're reporting an
              intellectual property infringement. If someone is in immediate danger, call the local emergency services –
              don't wait.
            </p>
            {reportReasons.map((reason) => (
              <button
                key={reason}
                onClick={() => setSelectedReason(reason)}
                className={`w-full text-left py-2 px-4 hover:bg-gray-100 transition-colors ${
                  selectedReason === reason ? 'bg-orange-100' : ''
                }`}
              >
                {reason}
              </button>
            ))}
            <div className="mt-4 flex justify-end">
              <button
                onClick={onClose}
                className="mr-2 px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition-colors"
              >
                Cancel
                </button>
              <button
                onClick={handleSubmit}
                className="px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
              >
                Submit Report
              </button>
            </div>
          </div>
        </div>
      )
    );
  };

  const renderConnectionButton = () => {
    switch (connectionStatus) {
      case 'accepted':
        return (
          <button className="w-full bg-green-500 text-white py-3 rounded-lg cursor-not-allowed">
            Connected with {buddy.name}
          </button>
        );
      case 'pending_sent':
        return (
          <button className="w-full bg-yellow-500 text-white py-3 rounded-lg cursor-not-allowed">
            Connection Request Pending
          </button>
        );
      case 'pending_received':
        return (
          <div className="w-full bg-blue-100 text-blue-800 py-3 px-4 rounded-lg text-center">
            {buddy.name} has sent you a connection request. Check your notifications to respond.
          </div>
        );
      case 'not_connected':
        if (connectionPreference === 'no_one') {
          return (
            <button className="w-full bg-gray-300 text-gray-600 py-3 rounded-lg cursor-not-allowed">
              {buddy.name} is not accepting new connections
            </button>
          );
        }
        return (
          <button 
            onClick={handleConnect} 
            className="w-full bg-orange-500 text-white py-3 rounded-lg hover:bg-orange-600 transition-colors"
          >
            Connect with {buddy.name}
          </button>
        );
      default:
        return null;
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!buddy) {
    return <div>Buddy not found</div>;
  }

  const age = calculateAge(buddy.dob);
  const distance = currentUser ? calculateDistance(
    currentUser.latitude,
    currentUser.longitude,
    buddy.latitude,
    buddy.longitude
  ) : 'N/A';

  return (
    <div className="max-w-4xl mx-auto p-4">
      <button onClick={() => navigate(-1)} className="mb-4 text-orange-500 font-medium">
        ← Back to Find Buddy
      </button>
      <div className="bg-white rounded-lg shadow-md overflow-hidden relative">
        <button
          onClick={handleOpenReportModal}
          className="absolute top-4 right-4 text-red-500 hover:text-red-600 transition-colors text-sm"
        >
          Report Profile
        </button>
        <div className="md:flex">
          <div className="md:w-1/3">
            <img 
              className="h-full w-full object-cover" 
              src={getImageUrl(buddy.image_url)} 
              alt={buddy.name}
              onError={(e) => {
                e.target.onerror = null; 
                e.target.src = 'https://via.placeholder.com/150'
              }}
            />
          </div>
          
          <div className="md:w-2/3 p-6">
            <div className="uppercase tracking-wide text-sm text-orange-500 font-semibold mb-2">
              {buddy.fitness_level || 'N/A'} LEVEL
            </div>
            <h1 className="text-3xl font-bold text-gray-900 mb-2">{buddy.name}</h1>
            <p className="text-xl text-gray-600 mb-4">{age} years old</p>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div className="flex items-center">
                <User className="text-gray-400 mr-2" size={20} />
                <span className="text-gray-700">{buddy.gender || 'N/A'}</span>
              </div>
              <div className="flex items-center">
                <MapPin className="text-gray-400 mr-2" size={20} />
                <span className="text-gray-700">{distance} km away</span>
              </div>
              <div className="flex items-center">
                {renderStars(averageRating)}
                <span className="ml-2 text-sm text-gray-500">({totalReviews} reviews)</span>
              </div>
              <div className="flex items-center">
                <Heart
                  className={`mr-2 cursor-pointer ${isLiked ? 'text-red-500 fill-current' : 'text-gray-400'}`}
                  size={20}
                  onClick={handleLikeToggle}
                />
                <span className="text-gray-700">{likesCount} likes</span>
              </div>
            </div>
            <div className="mb-4">
              <h2 className="text-lg font-semibold text-gray-900 mb-2">Interests</h2>
              <div className="flex flex-wrap">
                {buddy.interests && buddy.interests.map((interest, index) => (
                  <span key={index} className="bg-orange-100 text-orange-800 text-sm font-medium mr-2 mb-2 px-3 py-1 rounded-full">
                    {interest}
                  </span>
                ))}
              </div>
            </div>
            
            <div className="mb-4">
              <h2 className="text-lg font-semibold text-gray-900 mb-2">Availability</h2>
              <div className="flex items-center">
                <Clock className="text-gray-400 mr-2" size={20} />
                <span className="text-gray-700">{buddy.availability ? buddy.availability.join(', ') : 'N/A'}</span>
              </div>
            </div>
            
            <div className="mb-4">
              <h2 className="text-lg font-semibold text-gray-900 mb-2">Fitness Goals</h2>
              <ul className="list-disc list-inside text-gray-700">
                {buddy.fitness_goals && buddy.fitness_goals.map((goal, index) => (
                  <li key={index}>{goal}</li>
                ))}
              </ul>
            </div>
            {renderConnectionButton()}
          </div>
        </div>
      </div>
      <div className="mt-8">
        <h2 className="text-2xl font-semibold mb-4">Ratings and Reviews</h2>
        <div className="flex items-center mb-4">
          {renderStars(averageRating)}
          <span className="ml-2 text-lg font-medium">{averageRating.toFixed(1)}</span>
          <span className="ml-2 text-sm text-gray-500">({totalReviews} reviews)</span>
        </div>
        
        {!showReviewForm && (
          <button
            onClick={() => setShowReviewForm(true)}
            className="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition-colors"
          >
            Write a Review
          </button>
        )}
  
        {showReviewForm && (
          <div className="bg-white rounded-lg shadow-md p-6 mt-4">
            <h3 className="text-xl font-semibold mb-4">Your Review</h3>
            {renderStars(userRating, true)}
            <textarea
              className="w-full mt-4 p-2 border rounded-md"
              rows="4"
              placeholder="Write your review here (optional)"
              value={userReview}
              onChange={(e) => setUserReview(e.target.value)}
            ></textarea>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowReviewForm(false)}
                className="mr-4 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handleRatingSubmit}
                className="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition-colors flex items-center"
              >
                <Send size={20} className="mr-2" />
                Submit Review
              </button>
            </div>
          </div>
        )}
      </div>
  
      <BuddyReviewsSection buddyId={id} />
  
      <ReportModal
        isOpen={showReportModal}
        onClose={handleCloseReportModal}
        onSubmit={handleReportSubmit}
      />
    </div>
  );
};

export default BuddyProfile;