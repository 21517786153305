import React, { useState, useEffect } from 'react';
import { ArrowLeft, ChevronRight, MapPin, Camera, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabase';
import LocationInput from './LocationInput';

const TopicButton = ({ topic, isSelected, onToggle }) => (
  <button
    className={`px-4 py-2 rounded-full border ${
      isSelected ? 'bg-black text-white' : 'bg-white text-black'
    }`}
    onClick={onToggle}
  >
    {topic}
  </button>
);

const CreateGroupComponent = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [selectedSport, setSelectedSport] = useState('');
  const [groupName, setGroupName] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [groupLocation, setGroupLocation] = useState({});
  const [selectedClub, setSelectedClub] = useState('Does not matter');
  const [isPrivate, setIsPrivate] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [showTopicsModal, setShowTopicsModal] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentUser, setCurrentUser] = useState(null);

  const sports = [
    'Padel', 'Tennis', 'Badminton', 'Pickleball', 'Squash', 
    'Running', 'Cycling', 'Beach Tennis', 'Beach Volley', 
    'Walking', 'Gym', 'Boxing', 'Martial Arts'
  ];
  const topics = ['Matches', 'Tournaments', 'Trainings', 'Gadget talk', 'Tips & tricks', 'Technique tips', 'Buy', 'Discussion forum'];

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setCurrentUser(user);
    };
    fetchCurrentUser();
  }, []);

  const handleSportSelection = (sport) => {
    setSelectedSport(sport);
    setStep(2);
  };

  const handleCreateGroup = async () => {
    if (!currentUser) {
      setError('You must be logged in to create a group');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      let backgroundImageUrl = null;
      if (backgroundImage) {
        const fileName = `${Date.now()}_${backgroundImage.name}`;
        const { data, error } = await supabase.storage
          .from('group_images')
          .upload(fileName, backgroundImage);

        if (error) throw error;
        
        const { data: { publicUrl } } = supabase.storage
          .from('group_images')
          .getPublicUrl(fileName);
        
        backgroundImageUrl = publicUrl;
      }

      const { data: group, error: groupError } = await supabase
        .from('groups')
        .insert({
          name: groupName,
          description: groupDescription,
          sport: selectedSport,
          location: groupLocation.suburb,
          latitude: groupLocation.latitude,
          longitude: groupLocation.longitude,
          club: selectedClub,
          is_private: isPrivate,
          is_hidden: isHidden,
          background_image_url: backgroundImageUrl,
          created_by: currentUser.id
        })
        .select()
        .single();

      if (groupError) throw groupError;

      if (selectedTopics.length > 0) {
        const { error: topicsError } = await supabase
          .from('group_topics')
          .insert(selectedTopics.map(topic => ({
            group_id: group.id,
            topic: topic
          })));

        if (topicsError) throw topicsError;
      }

      const { error: memberError } = await supabase
        .from('group_members')
        .insert({
          group_id: group.id,
          user_id: currentUser.id,
          role: 'admin'
        });

      if (memberError) throw memberError;

      navigate('/community');
    } catch (error) {
      console.error('Error creating group:', error);
      setError('Failed to create group. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLocationSelect = (location) => {
    setGroupLocation({
      suburb: location.suburb,
      latitude: location.latitude,
      longitude: location.longitude
    });
  };

  const toggleTopic = (topic) => {
    setSelectedTopics(prev =>
      prev.includes(topic)
        ? prev.filter(t => t !== topic)
        : [...prev, topic]
    );
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setBackgroundImage(file);
    }
  };

  const ToggleSwitch = ({ isOn, onToggle, label, description }) => (
    <div className="flex justify-between items-center py-3 border-b">
      <div>
        <p className="font-semibold">{label}</p>
        <p className="text-sm text-gray-500">{description}</p>
      </div>
      <label className="flex items-center cursor-pointer">
        <div className="relative">
          <input type="checkbox" className="sr-only" checked={isOn} onChange={onToggle} />
          <div className="w-10 h-6 bg-gray-300 rounded-full shadow-inner"></div>
          <div className={`absolute w-4 h-4 bg-white rounded-full shadow inset-y-1 left-1 transition-transform duration-300 ease-in-out ${isOn ? 'transform translate-x-full bg-orange-500' : ''}`}></div>
        </div>
      </label>
    </div>
  );

  const TopicsModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-md p-6">
        <h2 className="text-2xl font-bold mb-4">Group Topics</h2>
        <div className="flex flex-wrap gap-2 mb-4">
          {topics.map((topic) => (
            <TopicButton
              key={topic}
              topic={topic}
              isSelected={selectedTopics.includes(topic)}
              onToggle={() => toggleTopic(topic)}
            />
          ))}
        </div>
        <button 
          className="w-full bg-orange-500 text-white py-3 rounded-lg mt-4"
          onClick={() => setShowTopicsModal(false)}
        >
          Confirm
        </button>
      </div>
    </div>
  );

  if (step === 1) {
    return (
      <div className="font-sans bg-white min-h-screen p-4">
        <header className="flex items-center mb-6">
          <button onClick={() => navigate('/community')} className="mr-4">
            <ArrowLeft size={24} />
          </button>
          <h1 className="text-2xl font-bold">Choose sport</h1>
        </header>
        <div className="grid grid-cols-2 gap-4">
          {sports.map((sport) => (
            <div 
              key={sport}
              className="flex justify-between items-center p-4 border rounded-lg cursor-pointer hover:bg-gray-100"
              onClick={() => handleSportSelection(sport)}
            >
              <span className="text-lg">{sport}</span>
              {selectedSport === sport && <span className="text-green-500">✓</span>}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="font-sans bg-white min-h-screen p-4">
      <header className="flex items-center mb-6">
        <button onClick={() => setStep(1)} className="mr-4">
          <ArrowLeft size={24} />
        </button>
        <h1 className="text-2xl font-bold">Create a group</h1>
        <div className="ml-auto flex items-center">
          <span>{selectedSport}</span>
          <ChevronRight size={20} />
        </div>
      </header>

      <div className="space-y-4">
        <input
          className="w-full p-3 bg-gray-100 rounded-lg"
          placeholder="Group name"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
        />
        <textarea
          className="w-full p-3 bg-gray-100 rounded-lg"
          placeholder="Group description"
          value={groupDescription}
          onChange={(e) => setGroupDescription(e.target.value)}
          rows={4}
        />
        <div className="p-3 border-b">
          <div className="flex items-center mb-2">
            <MapPin size={20} className="mr-2" />
            <span>Group Location</span>
          </div>
          <LocationInput onSelectLocation={handleLocationSelect} />
        </div>
        <div className="flex justify-between items-center p-3 border-b">
          <div className="flex items-center">
            <MapPin size={20} className="mr-2" />
            <span>Select Club</span>
          </div>
          <div className="flex items-center">
            <span className="text-gray-400 mr-2">{selectedClub}</span>
            <ChevronRight size={20} />
          </div>
        </div>
        <ToggleSwitch
          isOn={isPrivate}
          onToggle={() => setIsPrivate(!isPrivate)}
          label="Private Group"
          description={isPrivate ? "Players can only join the group by invitation" : "Everyone can see and join the group"}
        />
        <ToggleSwitch
          isOn={isHidden}
          onToggle={() => setIsHidden(!isHidden)}
          label="Hidden In Feed"
          description="Does not appear in the feed"
        />
        <div 
          className="flex justify-between items-center p-3 border-b cursor-pointer"
          onClick={() => setShowTopicsModal(true)}
        >
          <span>Group Topic</span>
          <ChevronRight size={20} />
        </div>
        <div className="flex flex-col items-center justify-center p-8 border-2 border-dashed rounded-lg">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="hidden"
            id="background-image-upload"
          />
          <label htmlFor="background-image-upload" className="cursor-pointer">
            <Camera size={48} className="text-gray-400 mb-2" />
            <span className="text-lg font-semibold">
              {backgroundImage ? backgroundImage.name : 'Upload background image'}
            </span>
          </label>
        </div>
        {backgroundImage && (
          <div className="mt-2 relative">
            <img
              src={URL.createObjectURL(backgroundImage)}
              alt="Background Preview"
              className="w-full h-40 object-cover rounded-lg"
            />
            <button
              onClick={() => setBackgroundImage(null)}
              className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1"
            >
              <X size={20} />
            </button>
          </div>
        )}
        {error && <p className="text-red-500 mt-2">{error}</p>}
        <div className="flex justify-center mt-4">
          <button 
            className={`bg-orange-500 text-white py-3 px-8 rounded-lg ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-orange-600'}`}
            onClick={handleCreateGroup}
            disabled={isLoading}
          >
            {isLoading ? 'Creating...' : 'CREATE GROUP'}
          </button>
        </div>
      </div>

      {showTopicsModal && <TopicsModal />}
    </div>
  );
};

export default CreateGroupComponent;