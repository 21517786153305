import React, { useState, useEffect } from 'react';
import { Clock, MapPin, Users, ChevronDown, ChevronUp, Check, X, ChevronLeft, ChevronRight } from 'lucide-react';
import { format, parseISO, compareAsc, isFuture } from 'date-fns';
import { supabase } from '../utils/supabase';

const SharedWorkoutsAndInvitations = ({ buddy, currentUser, onAcceptInvitation, onDeclineInvitation }) => {
  const [activeTab, setActiveTab] = useState('shared');
  const [sharedWorkouts, setSharedWorkouts] = useState([]);
  const [workoutInvitations, setWorkoutInvitations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedWorkout, setExpandedWorkout] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sharedWorkoutsTab, setSharedWorkoutsTab] = useState('upcoming');
  const itemsPerPage = 5;

  useEffect(() => {
    fetchWorkouts();
  }, [buddy, currentUser]);

  const fetchWorkouts = async () => {
    if (!buddy || !currentUser) return;
  
    setIsLoading(true);
  
    try {
      console.log('Fetching workouts for currentUser:', currentUser.id, 'and buddy:', buddy.id);
  
      // Fetch shared workouts
      const { data: sharedData, error: sharedError } = await supabase
        .from('workout_participants')
        .select(`
          *,
          workout:workouts(*)
        `)
        .in('user_id', [currentUser.id, buddy.id])
        .eq('status', 'accepted');
  
      if (sharedError) throw sharedError;
  
      // Filter workouts to only include those shared between the current user and the buddy
      const filteredSharedData = sharedData.filter(participant => {
        const otherParticipant = sharedData.find(p => 
          p.workout_id === participant.workout_id && p.user_id !== participant.user_id
        );
        return otherParticipant && 
          ((participant.user_id === currentUser.id && otherParticipant.user_id === buddy.id) ||
           (participant.user_id === buddy.id && otherParticipant.user_id === currentUser.id));
      });
  
      // Fetch user profiles for participants
      const participantIds = [...new Set(filteredSharedData.map(p => p.user_id))];
      const { data: userProfiles, error: userProfilesError } = await supabase
        .from('user_profiles')
        .select('*')
        .in('id', participantIds);
  
      if (userProfilesError) throw userProfilesError;
  
      // Create a map of user profiles
      const userProfileMap = Object.fromEntries(userProfiles.map(profile => [profile.id, profile]));
  
      // Group participants by workout and add user profile information
      const groupedWorkouts = filteredSharedData.reduce((acc, participant) => {
        const workoutId = participant.workout_id;
        if (!acc[workoutId]) {
          acc[workoutId] = { ...participant.workout, participants: [] };
        }
        // Only add the participant if they're not already in the list
        if (!acc[workoutId].participants.some(p => p.user_id === participant.user_id)) {
          acc[workoutId].participants.push({
            ...participant,
            user: userProfileMap[participant.user_id]
          });
        }
        return acc;
      }, {});
  
      // Convert to array and sort by date
      const uniqueWorkouts = Object.values(groupedWorkouts)
        .sort((a, b) => compareAsc(parseISO(b.date), parseISO(a.date)));
  
      console.log('Shared workouts:', uniqueWorkouts);
      setSharedWorkouts(uniqueWorkouts);
  
      // Fetch invitations (only those sent by the buddy to the current user)
      const { data: invitationsData, error: invitationsError } = await supabase
        .from('workout_participants')
        .select(`
          *,
          workout:workouts(*)
        `)
        .eq('user_id', currentUser.id)
        .eq('status', 'pending')
        .eq('invited_by', buddy.id);
  
      if (invitationsError) throw invitationsError;
  
      // Add user profile information to invitations and sort by date
      const invitationsWithProfiles = invitationsData
        .map(invitation => ({
          ...invitation,
          user: userProfileMap[invitation.invited_by]
        }))
        .sort((a, b) => compareAsc(parseISO(a.workout.date), parseISO(b.workout.date)));
  
      console.log('Workout invitations:', invitationsWithProfiles);
      setWorkoutInvitations(invitationsWithProfiles);
  
    } catch (error) {
      console.error('Error fetching workouts:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getWorkoutTypeColor = (type) => {
    const colors = {
      weightlifting: 'bg-blue-100 text-blue-800',
      cycling: 'bg-green-100 text-green-800',
      walking: 'bg-yellow-100 text-yellow-800',
      running: 'bg-red-100 text-red-800',
      yoga: 'bg-purple-100 text-purple-800',
      // Add more types and colors as needed
    };
    return colors[type.toLowerCase()] || 'bg-gray-100 text-gray-800';
  };

  const formatDate = (dateString) => {
    return format(parseISO(dateString), 'EEE, d MMM yyyy');
  };

  const formatTime = (timeString) => {
    if (!timeString) return 'Time not set';
    const [hours, minutes] = timeString.split(':');
    const date = new Date(2000, 0, 1, hours, minutes);
    return format(date, 'h:mm a');
  };

  const renderWorkout = (workout) => (
    <div key={workout.id} className="bg-white rounded-lg shadow-md p-4 mb-4 transition-all duration-300 hover:shadow-lg">
      <div className="flex justify-between items-center mb-2">
        <span className={`font-semibold px-3 py-1 rounded-full text-sm ${getWorkoutTypeColor(workout.workout_type)}`}>
          {capitalizeFirstLetter(workout.workout_type)}
        </span>
        <span className="text-sm text-gray-600">{formatDate(workout.date)}</span>
      </div>
      <div className="flex items-center text-sm text-gray-700 mb-2">
        <Clock size={14} className="mr-2" /> {formatTime(workout.time)}
        <MapPin size={14} className="ml-4 mr-2" /> {workout.location || 'Location not set'}
      </div>
      <div className="flex items-center mb-2">
        <Users size={14} className="mr-2" />
        <div className="flex -space-x-2">
          {workout.participants.map((participant) => (
            <img
              key={participant.id}
              src={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/profile_images/${participant.user?.image_url}`}
              alt={participant.user?.name}
              className="w-8 h-8 rounded-full border-2 border-white"
              title={participant.user?.name}
            />
          ))}
        </div>
      </div>
      <button
        onClick={() => setExpandedWorkout(expandedWorkout === workout.id ? null : workout.id)}
        className="text-sm text-orange-500 font-medium flex items-center mt-2"
      >
        {expandedWorkout === workout.id ? 'Hide Details' : 'Show Details'}
        {expandedWorkout === workout.id ? <ChevronUp size={16} className="ml-1" /> : <ChevronDown size={16} className="ml-1" />}
      </button>
      {expandedWorkout === workout.id && (
        <div className="mt-4 text-sm text-gray-700">
          <p><strong>Description:</strong> {workout.description || 'No description provided'}</p>
          <p><strong>Duration:</strong> {workout.duration || 'Not specified'}</p>
          {/* Add more details as needed */}
        </div>
      )}
    </div>
  );

  const renderInvitation = (invitation) => (
    <div key={invitation.id} className="bg-yellow-50 rounded-lg p-4 mb-4 shadow-md">
      <div className="flex justify-between items-center mb-2">
        <span className={`font-semibold px-3 py-1 rounded-full text-sm ${getWorkoutTypeColor(invitation.workout.workout_type)}`}>
          {capitalizeFirstLetter(invitation.workout.workout_type)}
        </span>
        <span className="text-sm text-gray-600">{formatDate(invitation.workout.date)}</span>
      </div>
      <div className="flex items-center text-sm text-gray-700 mb-2">
        <Clock size={14} className="mr-2" /> {formatTime(invitation.workout.time)}
        <MapPin size={14} className="ml-4 mr-2" /> {invitation.workout.location || 'Location not set'}
      </div>
      <div className="flex items-center mb-4">
        <Users size={14} className="mr-2" />
        <img
          src={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/profile_images/${invitation.user?.image_url}`}
          alt={invitation.user?.name}
          className="w-8 h-8 rounded-full border-2 border-white"
          title={invitation.user?.name}
        />
        <span className="ml-2 text-sm text-gray-700">Invited by {invitation.user?.name}</span>
      </div>
      <div className="flex justify-end space-x-2">
        <button 
          className="bg-green-500 text-white px-4 py-2 rounded-full flex items-center text-sm hover:bg-green-600 transition-colors"
          onClick={() => onAcceptInvitation(invitation.id)}
        >
          <Check size={14} className="mr-2" /> Accept
        </button>
        <button 
          className="bg-red-500 text-white px-4 py-2 rounded-full flex items-center text-sm hover:bg-red-600 transition-colors"
          onClick={() => onDeclineInvitation(invitation.id)}
        >
          <X size={14} className="mr-2" /> Decline
        </button>
      </div>
    </div>
  );

  const paginatedItems = (items) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return items.slice(startIndex, endIndex);
  };

  const totalPages = (items) => Math.ceil(items.length / itemsPerPage);

  const renderPagination = (items) => {
    const pages = totalPages(items);
    return (
      <div className="flex justify-center items-center mt-4">
        <button
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="mx-1 px-3 py-1 rounded bg-gray-200 text-gray-700 disabled:opacity-50"
        >
          <ChevronLeft size={20} />
        </button>
        <span className="mx-2">
          Page {currentPage} of {pages}
        </span>
        <button
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, pages))}
          disabled={currentPage === pages}
          className="mx-1 px-3 py-1 rounded bg-gray-200 text-gray-700 disabled:opacity-50"
        >
          <ChevronRight size={20} />
        </button>
      </div>
    );
  };

  const sortedSharedWorkouts = sharedWorkouts.sort((a, b) => compareAsc(parseISO(a.date), parseISO(b.date)));
  const upcomingWorkouts = sortedSharedWorkouts.filter(workout => isFuture(parseISO(workout.date)));
  const pastWorkouts = sortedSharedWorkouts.filter(workout => !isFuture(parseISO(workout.date)));

  if (isLoading) {
    return <div className="mt-4 text-center text-gray-600">Loading workouts and invitations...</div>;
  }

  return (
    <div className="mt-4">
      <div className="flex mb-6 border-b border-gray-200">
        <button
          className={`py-2 px-4 text-center ${activeTab === 'shared' ? 'text-orange-500 border-b-2 border-orange-500 font-medium' : 'text-gray-500 hover:text-gray-700'} transition-colors`}
          onClick={() => setActiveTab('shared')}
        >
          Shared Workouts
        </button>
        <button
          className={`py-2 px-4 text-center ${activeTab === 'invitations' ? 'text-orange-500 border-b-2 border-orange-500 font-medium' : 'text-gray-500 hover:text-gray-700'} transition-colors`}
          onClick={() => setActiveTab('invitations')}
        >
          Invitations
        </button>
      </div>

      {activeTab === 'shared' && (
        <div>
          <div className="flex mb-4 border-b border-gray-200">
            <button
              className={`py-2 px-4 text-center ${sharedWorkoutsTab === 'upcoming' ? 'text-blue-500 border-b-2 border-blue-500 font-medium' : 'text-gray-500 hover:text-gray-700'} transition-colors`}
              onClick={() => setSharedWorkoutsTab('upcoming')}
            >
              Upcoming
            </button>
            <button
              className={`py-2 px-4 text-center ${sharedWorkoutsTab === 'past' ? 'text-blue-500 border-b-2 border-blue-500 font-medium' : 'text-gray-500 hover:text-gray-700'} transition-colors`}
              onClick={() => setSharedWorkoutsTab('past')}
            >
              Past
            </button>
          </div>
          
          {sharedWorkoutsTab === 'upcoming' && (
            <div>
              <h3 className="font-semibold mb-4 text-lg text-gray-800">Upcoming Shared Workouts</h3>
              {upcomingWorkouts.length > 0 ? (
                <>
                  {paginatedItems(upcomingWorkouts).map(renderWorkout)}
                  {renderPagination(upcomingWorkouts)}
                </>
              ) : (
                <p className="text-gray-600 text-center py-8 bg-gray-50 rounded-lg">
                  No upcoming shared workouts. Schedule one with your buddy!
                </p>
              )}
            </div>
          )}

          {sharedWorkoutsTab === 'past' && (
            <div>
              <h3 className="font-semibold mb-4 text-lg text-gray-800">Past Shared Workouts</h3>
              {pastWorkouts.length > 0 ? (
                <>
                  {paginatedItems(pastWorkouts).map(renderWorkout)}
                  {renderPagination(pastWorkouts)}
                </>
              ) : (
                <p className="text-gray-600 text-center py-8 bg-gray-50 rounded-lg">
                  No past shared workouts. Start your fitness journey together!
                </p>
              )}
            </div>
          )}
        </div>
      )}

      {activeTab === 'invitations' && (
        <div>
          <h3 className="font-semibold mb-4 text-lg text-gray-800">Workout Invitations</h3>
          {workoutInvitations.length > 0 ? (
            <>
              {paginatedItems(workoutInvitations).map(renderInvitation)}
              {renderPagination(workoutInvitations)}
            </>
          ) : (
            <p className="text-gray-600 text-center py-8 bg-gray-50 rounded-lg">
              No pending invitations. Why not invite your buddy to a workout?
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default SharedWorkoutsAndInvitations;