import React, { useState, useRef, useEffect } from 'react';
import { Send, User, Bot, ArrowLeft, Loader, Paperclip, X } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabase';

const FitBuddyCoach = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const [error, setError] = useState(null);
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const initialQuestions = [
    "How can I improve my workout routine?",
    "What's a good diet plan for weight loss?",
    "How often should I exercise?",
    "Can you suggest some exercises for back pain?",
  ];

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    createThread();
  }, []);

  const createThread = async () => {
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    if (!apiKey) {
      setError('API key is not set. Please check your configuration.');
      return;
    }

    try {
      const response = await fetch('https://api.openai.com/v1/threads', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
          'OpenAI-Beta': 'assistants=v1'
        }
      });

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      const data = await response.json();
      setThreadId(data.id);
    } catch (error) {
      console.error('Error creating thread:', error);
      setError('Failed to initialize the chat. Please try again later.');
    }
  };

  const fetchAssistantResponse = async (message, fileInfo = null) => {
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    if (!apiKey || !threadId) {
      setError('Configuration issue. Please try again later.');
      return null;
    }
    
    try {
      // Add the user's message to the thread
      const messageResponse = await fetch(`https://api.openai.com/v1/threads/${threadId}/messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
          'OpenAI-Beta': 'assistants=v1'
        },
        body: JSON.stringify({
          role: "user",
          content: message
        })
      });

      if (!messageResponse.ok) {
        throw new Error(`Failed to add message to thread: ${messageResponse.status}`);
      }

      // If there's file information, add it as a separate message
      if (fileInfo) {
        const fileMessageResponse = await fetch(`https://api.openai.com/v1/threads/${threadId}/messages`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`,
            'OpenAI-Beta': 'assistants=v1'
          },
          body: JSON.stringify({
            role: "user",
            content: fileInfo
          })
        });

        if (!fileMessageResponse.ok) {
          throw new Error(`Failed to add file information to thread: ${fileMessageResponse.status}`);
        }
      }

      // Run the assistant
      const runResponse = await fetch(`https://api.openai.com/v1/threads/${threadId}/runs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
          'OpenAI-Beta': 'assistants=v1'
        },
        body: JSON.stringify({
          assistant_id: "asst_8YCOUAvt6f0WgZ7md0eg9gF8",
          model: "gpt-4-1106-preview"  // Changed to GPT-4
        })
      });

      if (!runResponse.ok) {
        throw new Error(`Run request failed with status ${runResponse.status}`);
      }

      const runData = await runResponse.json();
      let runStatus = runData.status;

      // Poll for completion
      while (runStatus === 'queued' || runStatus === 'in_progress') {
        await new Promise(resolve => setTimeout(resolve, 1000));
        const statusResponse = await fetch(`https://api.openai.com/v1/threads/${threadId}/runs/${runData.id}`, {
          headers: {
            'Authorization': `Bearer ${apiKey}`,
            'OpenAI-Beta': 'assistants=v1'
          }
        });

        if (!statusResponse.ok) {
          throw new Error(`Failed to check run status: ${statusResponse.status}`);
        }

        const statusData = await statusResponse.json();
        runStatus = statusData.status;
      }

      // Fetch messages
      const messagesResponse = await fetch(`https://api.openai.com/v1/threads/${threadId}/messages`, {
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'OpenAI-Beta': 'assistants=v1'
        }
      });

      if (!messagesResponse.ok) {
        throw new Error(`Failed to fetch messages: ${messagesResponse.status}`);
      }

      const messagesData = await messagesResponse.json();
      const lastMessage = messagesData.data[0];

      return lastMessage.content[0].text.value;
    } catch (error) {
      console.error('Error fetching assistant response:', error);
      setError(`Error: ${error.message}. Please try again.`);
      return null;
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const uploadFile = async () => {
    if (!file) return null;

    const fileExt = file.name.split('.').pop();
    const fileName = `${Math.random()}.${fileExt}`;
    const filePath = `uploads/${fileName}`;

    try {
      const { data, error } = await supabase.storage
        .from('chat-files')
        .upload(filePath, file);

      if (error) throw error;

      const { data: publicUrlData, error: publicUrlError } = supabase.storage
        .from('chat-files')
        .getPublicUrl(filePath);

      if (publicUrlError) throw publicUrlError;

      console.log('File uploaded successfully:', publicUrlData.publicUrl);
      return publicUrlData.publicUrl;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw new Error(`Failed to upload file: ${error.message}`);
    }
  };

  const parsePDF = async (fileUrl) => {
    try {
      console.log('Sending request to parse-pdf function with URL:', fileUrl);
      const { data, error } = await supabase.functions.invoke('parse-pdf', {
        body: { fileUrl },
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (error) {
        console.error('Error from parse-pdf function:', error);
        throw error;
      }
  
      if (!data || !data.text) {
        console.error('Invalid response from parse-pdf function:', data);
        throw new Error('Invalid response from PDF parsing function');
      }
  
      console.log('PDF parsed successfully');
      return data.text;
    } catch (error) {
      console.error('Error parsing PDF:', error);
      return `[Unable to parse PDF content. Error: ${error.message}. The file is available at ${fileUrl}]`;
    }
  };

  const handleSendMessage = async (message = input) => {
    if ((message.trim() === '' && !file) || isLoading) return;

    setIsLoading(true);
    let fileContent = null;
    let fileUrl = null;

    if (file) {
      try {
        fileUrl = await uploadFile();
        if (!fileUrl) {
          throw new Error('Failed to upload file.');
        }

        if (file.type === 'application/pdf') {
          fileContent = await parsePDF(fileUrl);
          if (!fileContent) {
            fileContent = `[A PDF file "${file.name}" was uploaded, but content extraction failed. The file is available at ${fileUrl}]`;
          }
        } else if (file.type.startsWith('text/')) {
          fileContent = await file.text();
        } else if (file.type.startsWith('image/')) {
          fileContent = `[An image file "${file.name}" was uploaded. The image is available at ${fileUrl}]`;
        } else {
          fileContent = `[A file of type "${file.type}" named "${file.name}" was uploaded. The file is available at ${fileUrl}]`;
        }

        console.log('File uploaded successfully:', fileUrl);
        console.log('File content description:', fileContent);
      } catch (error) {
        console.error('Error handling file:', error);
        setError(`Failed to process file: ${error.message}`);
        setIsLoading(false);
        return;
      }
    }

    const newMessage = {
      type: 'user',
      content: message,
      file: fileUrl ? { url: fileUrl, name: file.name, content: fileContent } : null
    };

    setMessages(prevMessages => [...prevMessages, newMessage]);
    setInput('');
    setFile(null);

    try {
      let messageToAI = message;
      if (fileContent) {
        messageToAI += `\n\nAttached file content:\n${fileContent}`;
      }

      console.log('Sending message to AI:', messageToAI);

      const response = await fetchAssistantResponse(messageToAI);
      if (response) {
        setMessages(prevMessages => [...prevMessages, { type: 'bot', content: response }]);
      }
    } catch (error) {
      console.error('Error sending message to AI:', error);
      setError(`Failed to get response: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gradient-to-br from-orange-100 to-orange-50">
      <div className="bg-orange-500 text-white p-4 flex items-center justify-between">
        <button
          onClick={() => navigate('/dashboard')}
          className="flex items-center hover:bg-orange-600 rounded-full p-2 transition-colors duration-300"
        >
          <ArrowLeft size={24} />
          <span className="ml-2 font-medium sm:inline hidden">Back</span>
        </button>
        <h2 className="text-xl sm:text-2xl font-bold">FIT Buddy Coach</h2>
        <div className="w-10 sm:w-24"></div>
      </div>
      
      <div className="flex-grow overflow-y-auto p-4 pb-40 md:pb-4">
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
            <strong className="font-bold">Error:</strong>
            <span className="block sm:inline"> {error}</span>
          </div>
        )}
        {messages.length === 0 && !error && (
          <div className="mb-6">
            <p className="text-gray-600 mb-3 font-medium">Choose a question to get started:</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
              {initialQuestions.map((question, index) => (
                <button
                  key={index}
                  onClick={() => handleSendMessage(question)}
                  className="text-left p-3 bg-orange-100 hover:bg-orange-200 rounded-lg transition-colors duration-300 shadow-sm"
                >
                  {question}
                </button>
              ))}
            </div>
          </div>
        )}
        
        {messages.map((message, index) => (
          <div key={index} className={`flex items-start mb-4 ${message.type === 'user' ? 'justify-end' : 'justify-start'}`}>
            <div className={`rounded-lg p-3 max-w-[85%] sm:max-w-[75%] ${message.type === 'user' ? 'bg-orange-200 text-gray-800' : 'bg-white border border-gray-200 shadow-sm'}`}>
              <div className="flex items-center mb-2">
                {message.type === 'user' ? <User size={16} className="mr-2 text-orange-500" /> : <Bot size={16} className="mr-2 text-blue-500" />}
                <span className="font-semibold">{message.type === 'user' ? 'You' : 'FIT Buddy Coach'}</span>
              </div>
              <ReactMarkdown 
                className="text-sm prose max-w-none"
                components={{
                  h1: ({node, ...props}) => <h1 className="text-xl sm:text-2xl font-bold my-3 sm:my-4" {...props} />,
                  h2: ({node, ...props}) => <h2 className="text-lg sm:text-xl font-semibold my-2 sm:my-3" {...props} />,
                  h3: ({node, ...props}) => <h3 className="text-base sm:text-lg font-medium my-2" {...props} />,
                  p: ({node, ...props}) => <p className="my-2" {...props} />,
                  ul: ({node, ...props}) => <ul className="list-disc list-inside my-2" {...props} />,
                  ol: ({node, ...props}) => <ol className="list-decimal list-outside my-2 pl-6" {...props} />,
                  li: ({node, ...props}) => <li className="my-1" {...props} />,
                  a: ({node, ...props}) => <a className="text-blue-500 hover:underline" {...props} />,
                  code: ({node, inline, ...props}) => 
                    inline 
                      ? <code className="bg-gray-100 rounded px-1 py-0.5" {...props} />
                      : <code className="block bg-gray-100 rounded p-2 my-2 overflow-x-auto text-sm" {...props} />,
                }}
              >
                {message.content}
              </ReactMarkdown>
              {message.file && (
                <div className="mt-2">
                  <a href={message.file.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                    {message.file.name}
                  </a>
                </div>
              )}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      
      <div className="p-4 bg-white border-t border-gray-200 fixed bottom-16 left-0 right-0 md:relative md:bottom-auto">
        <div className="flex items-center max-w-4xl mx-auto">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Ask your fitness question..."
            className="flex-grow border rounded-l-lg p-3 focus:outline-none focus:ring-2 focus:ring-orange-300"
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          />
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            className="hidden"
          />
          <button
            onClick={() => fileInputRef.current.click()}
            className="bg-gray-200 text-gray-600 p-3 hover:bg-gray-300 transition-colors duration-300"
          >
            <Paperclip size={24} />
          </button>
          <button
            onClick={() => handleSendMessage()}
            className={`bg-orange-500 text-white p-3 rounded-r-lg hover:bg-orange-600 transition-colors duration-300 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={isLoading}
          >
            {isLoading ? <Loader size={24} className="animate-spin" /> : <Send size={24} />}
          </button>
        </div>
        {file && (
          <div className="mt-2 flex items-center">
            <span className="text-sm text-gray-600">{file.name}</span>
            <button
              onClick={() => setFile(null)}
              className="ml-2 text-red-500 hover:text-red-700"
            >
              <X size={16} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FitBuddyCoach;