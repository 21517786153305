import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, ChevronDown, ChevronUp, ArrowLeft } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div
      className="border-b border-gray-200 py-4"
      initial={false}
      animate={{ backgroundColor: isOpen ? "rgba(249, 250, 251, 0.5)" : "transparent" }}
    >
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-medium text-gray-900">{question}</span>
        {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 }
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div className="mt-2 text-gray-600">{answer}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const HelpCenter = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [activeCategory, setActiveCategory] = useState('General');

  const faqCategories = ['General', 'Account', 'Buddies', 'Events', 'Trainers'];

  const faqs = [
    { category: 'General', question: "What is FitBuddy?", answer: "FitBuddy is a platform that connects fitness enthusiasts, allowing them to find workout partners, join local events, and achieve their fitness goals together." },
    { category: 'General', question: "How do I get started with FitBuddy?", answer: "To get started, simply sign up for an account, complete your profile, and start exploring buddies, events, and trainers in your area." },
    { category: 'Account', question: "How do I change my password?", answer: "To change your password, go to your account settings, select 'Security', and click on 'Change Password'. Follow the prompts to set a new password." },
    { category: 'Account', question: "Can I delete my account?", answer: "Yes, you can delete your account. Go to your account settings, select 'Privacy', and click on 'Delete Account'. Please note that this action is irreversible." },
    { category: 'Buddies', question: "How does buddy matching work?", answer: "Our buddy matching algorithm considers factors like fitness goals, experience level, location, and availability to suggest compatible workout partners." },
    { category: 'Buddies', question: "What if I don't get along with my buddy?", answer: "If you're not compatible with a buddy, you can unmatch and look for other partners. We encourage open communication to resolve any issues." },
    { category: 'Events', question: "How do I create an event?", answer: "To create an event, go to the 'Events' tab, click 'Create Event', and fill out the event details. You can make it public or invite specific buddies." },
    { category: 'Events', question: "Can I cancel my RSVP to an event?", answer: "Yes, you can cancel your RSVP up to 24 hours before the event starts. Go to the event page and click 'Cancel RSVP'." },
    { category: 'Trainers', question: "How are trainers verified?", answer: "All trainers on FitBuddy go through a verification process, including background checks and certification validation, to ensure quality and safety." },
    { category: 'Trainers', question: "Can I leave a review for a trainer?", answer: "Yes, after completing a session with a trainer, you'll have the option to leave a review and rating based on your experience." },
  ];

  const filteredFaqs = faqs.filter(faq => 
    faq.category === activeCategory && 
    (faq.question.toLowerCase().includes(searchTerm.toLowerCase()) || 
     faq.answer.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <div className="flex items-center">
            <button onClick={() => navigate(-1)} className="mr-4">
              <ArrowLeft size={24} className="text-gray-600" />
            </button>
            <h1 className="text-2xl font-bold text-gray-900">Help Center</h1>
          </div>
          <Link to="/" className="text-orange-500 hover:text-orange-600 transition-colors">
            Back to Home
          </Link>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Search Bar */}
        <div className="max-w-3xl mx-auto mb-12">
          <div className="relative">
            <input
              type="text"
              placeholder="Search for help..."
              className="w-full p-4 pl-12 pr-4 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          </div>
        </div>

        {/* FAQ Categories */}
        <div className="mb-8 flex flex-wrap justify-center gap-4">
          {faqCategories.map((category) => (
            <button
              key={category}
              className={`px-4 py-2 rounded-full ${
                activeCategory === category
                  ? 'bg-orange-500 text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              } transition-colors`}
              onClick={() => setActiveCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>

        {/* FAQ List */}
        <div className="max-w-3xl mx-auto">
          <AnimatePresence>
            {filteredFaqs.map((faq, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <FAQItem question={faq.question} answer={faq.answer} />
              </motion.div>
            ))}
          </AnimatePresence>
          {filteredFaqs.length === 0 && (
            <p className="text-center text-gray-600 mt-8">
              No results found. Try adjusting your search or category.
            </p>
          )}
        </div>

        {/* Contact Support */}
        <div className="mt-16 text-center">
          <h2 className="text-2xl font-semibold mb-4">Still need help?</h2>
          <p className="text-gray-600 mb-6">Our support team is here to assist you.</p>
          <Link
            to="/contact"
            className="inline-block bg-orange-500 text-white px-6 py-3 rounded-full font-semibold hover:bg-orange-600 transition-colors"
          >
            Contact Support
          </Link>
        </div>
      </main>
    </div>
  );
};

export default HelpCenter;