import React from 'react';
import { Card } from '../components/ui/card';
import { Package, InfinityIcon, ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const BillingComponent = ({ currentUser }) => {
  const navigate = useNavigate();

  return (
    <div className="container mx-auto px-4">
      <button onClick={() => navigate('/trainer-settings')} className="mb-4 text-orange-500 font-medium flex items-center">
        <ArrowLeft size={20} className="mr-1" /> Back to Trainer Settings
      </button>

      <h1 className="text-3xl font-bold mb-6">Billing</h1>
      
      {/* Base subscription section */}
      <div className="mb-8">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-2xl font-semibold">Base subscription</h2>
          <button 
            onClick={() => navigate('/pricing-plans')} 
            className="text-orange-500 hover:underline"
          >
            Compare all pricing plans
          </button>
        </div>
        <Card>
          <Card.Header>
            <h3 className="text-xl font-semibold">Free Trial</h3>
          </Card.Header>
          <Card.Content>
            <div className="flex justify-between mb-4">
              <div>
                <p className="font-semibold">Coaching Clients</p>
                <p>0/<InfinityIcon className="inline h-4 w-4" /></p>
              </div>
              <div>
                <p className="font-semibold">Basic Clients</p>
                <p>0/200</p>
              </div>
            </div>
            <div className="bg-gray-200 h-6 w-full rounded">
              <div className="bg-orange-500 h-full rounded" style={{width: '0%'}}>
                <span className="px-2 text-white">0%</span>
              </div>
            </div>
          </Card.Content>
        </Card>
      </div>
      
      {/* Add-on subscriptions section */}
      <div>
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-2xl font-semibold">Add-on subscriptions</h2>
          <a href="#" className="text-orange-500 hover:underline">Browse all add-ons</a>
        </div>
        <Card className="text-center py-8">
          <Package className="mx-auto h-16 w-16 text-gray-300 mb-4" />
          <h3 className="text-xl font-semibold mb-2">You aren't subscribed to any add-on</h3>
          <p className="text-gray-600 max-w-md mx-auto">
            Purchase powerful add-names to access additional features that will help you grow
            your coaching business. Add-ons are available at an additional cost and can be
            purchased anytime.
          </p>
        </Card>
      </div>
    </div>
  );
};

export default BillingComponent;