import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HelpCircle, MessageSquare, ChevronDown, ChevronUp, ArrowLeft } from 'lucide-react';
import { supabase } from '../utils/supabase';

const HelpFeedback = () => {
  const navigate = useNavigate();
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const faqs = [
    {
      question: "How do I connect with a fitness buddy?",
      answer: "To connect with a fitness buddy, go to the 'Find a Buddy' section, browse through potential matches, and click 'Connect' on their profile. You can also set your preferences to receive buddy suggestions."
    },
    {
      question: "How can I book a session with a trainer?",
      answer: "To book a trainer, navigate to the 'Trainers' section, select a trainer you're interested in, and click on 'Book Session'. You can then choose an available time slot that works for you."
    },
    {
      question: "How do I change my membership plan?",
      answer: "To change your membership plan, go to the 'Membership' section in your profile. You'll see your current plan and available options. Select the plan you want to switch to and follow the prompts."
    },
    {
      question: "What should I do if I can't attend a scheduled workout?",
      answer: "If you can't attend a scheduled workout, please cancel or reschedule it as soon as possible. You can do this by going to 'My Events' in your dashboard and selecting the workout you need to change."
    },
    {
      question: "How can I track my fitness progress?",
      answer: "You can track your fitness progress in the 'My Progress' section of your profile. Here, you can log workouts, view your activity history, and see visualizations of your improvements over time."
    }
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError('');
    setSubmitSuccess(false);

    try {
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      
      if (userError) throw new Error('Failed to get user information');
      if (!user) throw new Error('User not authenticated');

      // Instead of using fetch, let's use Supabase's function invocation
      const { data, error } = await supabase.functions.invoke('send-email', {
        body: JSON.stringify({
          type: 'contact',
          name: user.user_metadata.name || 'Unknown User',
          email: user.email,
          subject: subject,
          message: message
        }),
      });

      if (error) throw error;

      setSubmitSuccess(true);
      setSubject('');
      setMessage('');
    } catch (error) {
      console.error('Error sending feedback:', error);
      setSubmitError(`Failed to send feedback: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4 pb-20 sm:pb-4"> {/* Added pb-20 and sm:pb-4 */}
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-2xl font-bold">Help & Feedback</h1>
        <button onClick={() => navigate('/')} className="text-orange-500 font-medium flex items-center">
          <ArrowLeft size={20} className="mr-1" /> Back to Dashboard
        </button>
      </div>

      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4 flex items-center">
          <HelpCircle className="mr-2" /> Frequently Asked Questions
        </h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div key={index} className="border-b border-gray-200 pb-4">
              <button
                className="flex justify-between items-center w-full text-left"
                onClick={() => setActiveQuestion(activeQuestion === index ? null : index)}
              >
                <span className="font-medium">{faq.question}</span>
                {activeQuestion === index ? <ChevronUp /> : <ChevronDown />}
              </button>
              {activeQuestion === index && (
                <p className="mt-2 text-gray-600">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-xl font-semibold mb-4 flex items-center">
          <MessageSquare className="mr-2" /> Send Feedback
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Subject</label>
            <input 
              type="text" 
              className="w-full border-gray-300 rounded-md shadow-sm" 
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Message</label>
            <textarea 
              rows="4" 
              className="w-full border-gray-300 rounded-md shadow-sm"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </div>
          {submitError && <p className="text-red-500 mb-4">{submitError}</p>}
          {submitSuccess && <p className="text-green-500 mb-4">Feedback sent successfully!</p>}
          <button 
            type="submit" 
            className="bg-orange-500 text-white py-2 px-4 rounded-lg hover:bg-orange-600 transition-colors disabled:bg-gray-400"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Sending...' : 'Submit Feedback'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default HelpFeedback;