import React, { useState } from 'react';
import { Star, MapPin, ChevronRight, Heart } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const GymStudioCardsComponent = () => {
  const [favorites, setFavorites] = useState([]);
  const navigate = useNavigate();

  const toggleFavorite = (id) => {
    setFavorites(prev => 
      prev.includes(id) ? prev.filter(fav => fav !== id) : [...prev, id]
    );
  };

  const renderRating = (rating, reviews) => (
    <div className="flex items-center">
      {[...Array(5)].map((_, i) => (
        <Star
          key={i}
          size={16}
          className={`${i < Math.floor(rating) ? 'text-yellow-400' : 'text-gray-300'} fill-current`}
        />
      ))}
      <span className="ml-2 text-sm font-medium text-gray-700">
        {rating.toFixed(1)} ({reviews})
      </span>
    </div>
  );

  const hardcodedGyms = [
    {
      id: 1,
      name: "Push! Fitness",
      coverImage: "https://via.placeholder.com/800x300?text=Push!+Fitness",
      logo: "https://via.placeholder.com/100?text=PF",
      rating: 4.9,
      reviews: 51,
      distance: 1345.06,
      address: "2P/833 Collins Street Docklands, VIC 3008",
      classTypes: "Boxing / Kickboxing | Circuit Training | Cycling | Dance | Massage | Other | Personal Training | Pilates | Weight Training | Yoga",
      introOffers: [
        { name: "3 Reformer Class Trial Pack (1 time only)", description: "PILATES REFORMER PACKAGES", price: 60 },
        { name: "5 Class Pack", description: "ANY CLASS", price: 100 },
        { name: "Unlimited Month", description: "30 DAYS UNLIMITED", price: 199 }
      ],
      promotion: "New Year Offer"
    },
    {
      id: 2,
      name: "Flex Gym",
      coverImage: "https://via.placeholder.com/800x300?text=Flex+Gym",
      rating: 4.7,
      reviews: 38,
      distance: 2.3,
      classTypes: ["Strength", "Yoga"],
      introOffers: [{ price: 50 }, { price: 90 }, { price: 150 }],
      promotion: "Summer Special"
    },
    {
      id: 3,
      name: "Zen Yoga Studio",
      coverImage: "https://via.placeholder.com/800x300?text=Zen+Yoga+Studio",
      rating: 4.8,
      reviews: 62,
      distance: 0.8,
      classTypes: ["Yoga", "Pilates", "Meditation"],
      introOffers: [{ price: 40 }, { price: 75 }, { price: 120 }]
    },
    {
      id: 4,
      name: "PowerLift Center",
      coverImage: "https://via.placeholder.com/800x300?text=PowerLift+Center",
      rating: 4.6,
      reviews: 45,
      distance: 3.5,
      classTypes: ["Strength", "Powerlifting", "Olympic Weightlifting"],
      introOffers: [{ price: 55 }, { price: 95 }, { price: 180 }]
    }
  ];

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-gray-800">Featured Gyms & Studios</h2>
        <button className="text-orange-500 font-medium flex items-center hover:text-orange-600 transition-colors">
          View All <ChevronRight size={20} />
        </button>
      </div>
      <div className="flex overflow-x-auto space-x-4 pb-4">
        {hardcodedGyms.map((gym) => (
          <div key={gym.id} className="flex-none w-80 bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
            <div className="relative">
              <img 
                src={gym.coverImage} 
                alt={gym.name} 
                className="w-full h-48 object-cover"
              />
              <button 
                onClick={() => toggleFavorite(gym.id)}
                className="absolute top-2 right-2 bg-white rounded-full p-1.5 shadow-md hover:bg-gray-100 transition-colors"
              >
                <Heart 
                  size={20} 
                  className={favorites.includes(gym.id) ? "text-red-500 fill-current" : "text-gray-400"}
                />
              </button>
              {gym.promotion && (
                <div className="absolute top-2 left-2 bg-orange-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                  {gym.promotion}
                </div>
              )}
            </div>
            <div className="p-4">
              <h3 className="font-semibold text-lg mb-2 truncate">{gym.name}</h3>
              {renderRating(gym.rating, gym.reviews)}
              <div className="flex items-center text-sm text-gray-600 mt-2">
                <MapPin size={14} className="mr-1" /> 
                {gym.distance < 1 ? `${(gym.distance * 1000).toFixed(0)}m away` : `${gym.distance.toFixed(1)}km away`}
              </div>
              <div className="mt-2">
                <p className="text-sm font-medium text-gray-700">Class Types:</p>
                <div className="flex flex-wrap mt-1">
                  {Array.isArray(gym.classTypes) 
                    ? gym.classTypes.map((type, index) => (
                        <span key={index} className="bg-gray-100 text-gray-800 text-xs font-medium mr-1 mb-1 px-2 py-0.5 rounded-full">
                          {type}
                        </span>
                      ))
                    : gym.classTypes.split('|').map((type, index) => (
                        <span key={index} className="bg-gray-100 text-gray-800 text-xs font-medium mr-1 mb-1 px-2 py-0.5 rounded-full">
                          {type.trim()}
                        </span>
                      ))
                  }
                </div>
              </div>
              <div className="mt-4 flex justify-between items-center">
                <span className="text-sm font-medium text-gray-700">
                  From ${Math.min(...gym.introOffers.map(offer => offer.price))}
                </span>
                <button 
                  className="bg-orange-500 text-white px-4 py-2 rounded-lg text-sm hover:bg-orange-600 transition-colors"
                  onClick={() => navigate('/gym-profile')}
                >
                  View Profile
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GymStudioCardsComponent;
