import React, { useState, useEffect } from 'react';
import { supabase } from '../utils/supabase';
import {
  CheckCircle,
  XCircle,
  UserX,
  DollarSign,
  RefreshCcw,
  Edit,
} from 'lucide-react';
import UpdateParticipantModal from './UpdateParticipantModal';

const ManageActivity = ({ activityId, occurrenceId, currentUserId }) => {
  const [error, setError] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [activity, setActivity] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [summary, setSummary] = useState({
    totalParticipants: 0,
    attended: 0,
    noShows: 0,
    canceled: 0,
    totalExpectedRevenue: 0,
    totalPaymentsReceived: 0,
    outstandingPayments: 0,
  });
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState(null);

  useEffect(() => {
    if (activityId) {
      fetchData();
    }
  }, [activityId]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      // Fetch activity details
      const { data: activityData, error: activityError } = await supabase
        .from('group_activities')
        .select('*')
        .eq('id', activityId)
        .single();
  
      if (activityError) throw activityError;
      setActivity(activityData);
  
      // Fetch participants with new columns
      let participantsQuery = supabase
        .from('activity_participants')
        .select(`
          id,
          user_id,
          status,
          check_in_time,
          cancellation_reason,
          created_at
        `)
        .eq('activity_id', activityId);
  
      // Only add occurrence_id filter if it's provided and not null
      if (occurrenceId) {
        participantsQuery = participantsQuery.eq('occurrence_id', occurrenceId);
      }
  
      const { data: participantsData, error: participantsError } = await participantsQuery;
  
      if (participantsError) throw participantsError;
  
      // Fetch user profiles with email for participants
      const userIds = participantsData.map(p => p.user_id);
      const { data: userProfilesData, error: userProfilesError } = await supabase
        .from('user_profiles_with_email')
        .select('id, name, email')
        .in('id', userIds);
  
      if (userProfilesError) throw userProfilesError;
  
      // Fetch payment data (if the table exists)
      let paymentsData = [];
      try {
        const { data, error } = await supabase
          .from('activity_payments')
          .select('*')
          .eq('activity_id', activityId);
  
        if (error) throw error;
        paymentsData = data;
      } catch (paymentError) {
        console.warn('Error fetching payment data:', paymentError);
        // If the table doesn't exist or there's another error, we'll just use an empty array
      }
  
      // Combine all data
      const combinedData = participantsData.map(participant => {
        const userProfile = userProfilesData.find(profile => profile.id === participant.user_id) || {};
        const payment = paymentsData.find(p => p.user_id === participant.user_id);
  
        return {
          ...participant,
          user_profiles: {
            name: userProfile.name || 'N/A',
            email: userProfile.email || 'N/A',
            id: userProfile.id || participant.user_id,
          },
          activity_payments: payment || null,
        };
      });
  
      setParticipants(combinedData);
      calculateSummary(combinedData, activityData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load activity data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const calculateSummary = (participantsData, activityData) => {
    const totalParticipants = participantsData.length;
    const attended = participantsData.filter(p => p.status === 'attended').length;
    const noShows = participantsData.filter(p => p.status === 'no-show').length;
    const canceled = participantsData.filter(p => p.status === 'canceled').length;
  
    const totalExpectedRevenue = totalParticipants * (activityData.price || 0);
  
    const totalPaymentsReceived = participantsData.reduce((sum, p) => {
      if (p.activity_payments && p.activity_payments.is_confirmed) {
        return sum + (p.activity_payments.amount || 0);
      }
      return sum;
    }, 0);
  
    const outstandingPayments = totalExpectedRevenue - totalPaymentsReceived;
  
    setSummary({
      totalParticipants,
      attended,
      noShows,
      canceled,
      totalExpectedRevenue,
      totalPaymentsReceived,
      outstandingPayments,
    });
  };

  const handleCheckIn = async (participantId) => {
    try {
      const { data, error } = await supabase
        .from('activity_participants')
        .update({
          status: 'attended',
          check_in_time: new Date().toISOString(),
        })
        .eq('id', participantId)
        .select();
  
      if (error) throw error;
  
      if (data) {
        console.log('Check-in successful:', data);
        alert('Check-in successful.');
      }
  
      fetchData(); // Refresh data
    } catch (error) {
      console.error('Error checking in participant:', error);
      alert('Failed to check in participant. Please try again.');
    }
  };

  const handleMarkNoShow = async (participantId) => {
    try {
      const { error } = await supabase
        .from('activity_participants')
        .update({
          status: 'no-show',
        })
        .eq('id', participantId);

      if (error) throw error;

      fetchData(); // Refresh data
    } catch (error) {
      console.error('Error marking no-show:', error);
    }
  };

  const handleCancelParticipation = async (participantId) => {
    const reason = prompt('Enter cancellation reason:');

    if (!reason) {
      alert('Cancellation reason is required.');
      return;
    }

    try {
      const { error } = await supabase
        .from('activity_participants')
        .update({
          status: 'canceled',
          cancellation_reason: reason,
        })
        .eq('id', participantId);

      if (error) throw error;

      fetchData(); // Refresh data
    } catch (error) {
      console.error('Error canceling participation:', error);
    }
  };

  const handleRecordPayment = async (userId) => {
    const amountInput = prompt('Enter payment amount:');
    const paymentMethod = prompt(
      'Enter payment method (cash, bank_transfer, stripe):'
    );
  
    if (!amountInput || !paymentMethod) {
      alert('Amount and payment method are required.');
      return;
    }
  
    const amount = parseFloat(amountInput);
    if (isNaN(amount)) {
      alert('Invalid amount entered.');
      return;
    }
  
    try {
      const paymentData = {
        activity_id: activityId,
        user_id: userId,
        amount: amount,
        payment_method: paymentMethod,
        is_confirmed: true,
      };
  
      const { data, error } = await supabase
        .from('activity_payments')
        .upsert(paymentData)
        .select();
  
      if (error) throw error;
  
      if (data) {
        console.log('Payment recorded successfully:', data);
        alert('Payment recorded successfully.');
      }
  
      fetchData(); // Refresh data
    } catch (error) {
      console.error('Error recording payment:', error);
      alert('Failed to record payment. Please try again.');
    }
  };

  const handleUpdateParticipant = async (participantId) => {
    // First, check if the current user is an admin for this activity's group
    const { data: adminCheck, error: adminCheckError } = await supabase
      .from('group_members')
      .select('role')
      .eq('user_id', currentUserId)
      .eq('group_id', activity.group_id)
      .single();
  
    if (adminCheckError) {
      console.error('Error checking admin status:', adminCheckError);
      alert('Failed to verify admin status. Please try again.');
      return;
    }
  
    if (!adminCheck || adminCheck.role !== 'admin') {
      alert('You do not have permission to update this participant.');
      return;
    }
  
    const participant = participants.find(p => p.id === participantId);
    if (!participant) {
      alert('Participant not found.');
      return;
    }
  
    setSelectedParticipant(participant);
    setIsUpdateModalOpen(true);
  };

  const handleUpdateSubmission = async (updateData) => {
    try {
      const { data, error } = await supabase.rpc('update_participant_and_payment', {
        p_participant_id: selectedParticipant.id,
        p_activity_id: activityId,
        p_user_id: selectedParticipant.user_id,
        p_new_status: updateData.newStatus,
        p_new_payment_status: updateData.newPaymentStatus,
        p_payment_amount: updateData.paymentAmount,
        p_payment_method: updateData.paymentMethod,
        p_check_in_time: updateData.newStatus === 'attended' ? new Date().toISOString() : null,
        p_cancellation_reason: updateData.cancellationReason
      });

      if (error) {
        console.error('Error updating participant:', error);
        throw new Error(error.message || 'An error occurred while updating the participant.');
      }

      alert('Participant updated successfully.');
      setIsUpdateModalOpen(false);
      fetchData(); // Refresh data
    } catch (error) {
      console.error('Error updating participant:', error);
      alert(`Failed to update participant: ${error.message}`);
    }
  };

  const handleRefresh = () => {
    fetchData();
  };

  return (
    <div className="manage-activity bg-gray-100 rounded-lg p-4">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-bold">Manage Activity</h2>
        <button
          onClick={handleRefresh}
          className="bg-blue-500 text-white px-3 py-2 rounded hover:bg-blue-600 flex items-center"
        >
          <RefreshCcw size={16} className="mr-1" />
          Refresh
        </button>
      </div>
  
      {isLoading ? (
        <p>Loading...</p>
      ) : error ? (
        <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
          {error}
        </div>
      ) : (
        <>
          {/* Activity Details */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold">{activity.title}</h3>
            <p className="text-gray-600">
              Date:{' '}
              {new Date(activity.start_time).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </p>
            <p className="text-gray-600">
              Time:{' '}
              {`${new Date(activity.start_time).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })} - ${new Date(activity.end_time).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })}`}
            </p>
          </div>
  
          {/* Summary Statistics */}
          <div className="mb-6 grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-white rounded-lg p-4 shadow">
              <p className="text-sm text-gray-500">Total Participants</p>
              <p className="text-xl font-bold">{summary.totalParticipants}</p>
            </div>
            <div className="bg-white rounded-lg p-4 shadow">
              <p className="text-sm text-gray-500">Attended</p>
              <p className="text-xl font-bold">{summary.attended}</p>
            </div>
            <div className="bg-white rounded-lg p-4 shadow">
              <p className="text-sm text-gray-500">No-Shows</p>
              <p className="text-xl font-bold">{summary.noShows}</p>
            </div>
            <div className="bg-white rounded-lg p-4 shadow">
              <p className="text-sm text-gray-500">Canceled</p>
              <p className="text-xl font-bold">{summary.canceled}</p>
            </div>
            <div className="bg-white rounded-lg p-4 shadow">
              <p className="text-sm text-gray-500">Total Expected Revenue</p>
              <p className="text-xl font-bold">
                ${summary.totalExpectedRevenue.toFixed(2)}
              </p>
            </div>
            <div className="bg-white rounded-lg p-4 shadow">
              <p className="text-sm text-gray-500">Total Payments Received</p>
              <p className="text-xl font-bold">
                ${summary.totalPaymentsReceived.toFixed(2)}
              </p>
            </div>
            <div className="bg-white rounded-lg p-4 shadow">
              <p className="text-sm text-gray-500">Outstanding Payments</p>
              <p className="text-xl font-bold">
                ${summary.outstandingPayments.toFixed(2)}
              </p>
            </div>
          </div>
  
          {/* Participants Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white rounded-lg overflow-hidden shadow">
              <thead className="bg-gray-200">
                <tr>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                    Name
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                    Email
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                    Attendance Status
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  Check-In Time
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                    Payment Status
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                    Payment Amount
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                    Payment Method
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {participants.map((participant) => (
                  <tr
                    key={participant.id}
                    className={participant.id % 2 === 0 ? 'bg-gray-50' : 'bg-white'}
                  >
                    <td className="px-4 py-2 text-sm text-gray-700">
                      {participant.user_profiles.name}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-700">
                      {participant.user_profiles.email}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-700">
                      {participant.status}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-700">
                      {participant.check_in_time
                        ? new Date(participant.check_in_time).toLocaleString()
                        : 'N/A'}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-700">
                      {participant.activity_payments
                        ? participant.activity_payments.is_confirmed
                          ? 'Paid'
                          : 'Pending'
                        : 'Unpaid'}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-700">
                      {participant.activity_payments
                        ? `$${participant.activity_payments.amount.toFixed(2)}`
                        : 'N/A'}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-700">
                      {participant.activity_payments?.payment_method || 'N/A'}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-700">
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleCheckIn(participant.id)}
                          className="text-green-500 hover:text-green-600"
                          title="Check-In"
                        >
                          <CheckCircle size={18} />
                        </button>
                        <button
                          onClick={() => handleMarkNoShow(participant.id)}
                          className="text-yellow-500 hover:text-yellow-600"
                          title="Mark No-Show"
                        >
                          <UserX size={18} />
                        </button>
                        <button
                          onClick={() => handleCancelParticipation(participant.id)}
                          className="text-red-500 hover:text-red-600"
                          title="Cancel Participation"
                        >
                          <XCircle size={18} />
                        </button>
                        <button
                          onClick={() => handleRecordPayment(participant.user_id)}
                          className="text-blue-500 hover:text-blue-600"
                          title="Record Payment"
                        >
                          <DollarSign size={18} />
                        </button>
                        <button
                          onClick={() => handleUpdateParticipant(participant.id)}
                          className="text-purple-500 hover:text-purple-600"
                          title="Update Participant"
                        >
                          <Edit size={18} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      {isUpdateModalOpen && selectedParticipant && (
        <UpdateParticipantModal
          participant={selectedParticipant}
          onUpdate={handleUpdateSubmission}
          onClose={() => setIsUpdateModalOpen(false)}
        />
      )}
    </div>
  );
};

export default ManageActivity;