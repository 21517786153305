import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Loader2, ChevronDown, ChevronUp, Info, ArrowLeft, Save, Trash, Dumbbell, Clock, Calendar, Play, Pause, X, UserCheck, Activity, Edit } from 'lucide-react';
import { supabase } from '../utils/supabase';
import { motion, AnimatePresence } from 'framer-motion';
import VideoPlayer from './VideoPlayer'; 

const Tab = ({ label, active, onClick }) => (
  <button
    className={`px-4 py-2 font-medium text-sm rounded-t-lg ${
      active
        ? 'bg-white text-orange-600 border-t border-x border-gray-200'
        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
    }`}
    onClick={onClick}
  >
    {label}
  </button>
);

const WorkoutTable = ({ workouts, onView, onEdit, onAssign, onDelete }) => (
  <div className="overflow-x-auto">
    <table className="min-w-full bg-white">
      <thead className="bg-gray-50">
        <tr>
          <th className="py-2 px-4 border-b text-left">Name</th>
          <th className="py-2 px-4 border-b text-left">Created</th>
          <th className="py-2 px-4 border-b text-left">Actions</th>
        </tr>
      </thead>
      <tbody>
        {workouts.map((workout) => (
          <tr key={workout.id} className="hover:bg-gray-50">
            <td className="py-2 px-4 border-b">{workout.name}</td>
            <td className="py-2 px-4 border-b">{new Date(workout.created_at).toLocaleDateString()}</td>
            <td className="py-2 px-4 border-b">
              <button
                onClick={() => onView(workout)}
                className="mr-2 text-orange-500 hover:text-orange-700"
              >
                <Activity size={18} />
              </button>
              <button
                onClick={() => onEdit(workout)}
                className="mr-2 text-blue-500 hover:text-blue-700"
              >
                <Edit size={18} />
              </button>
              <button
                onClick={() => onAssign(workout.id)}
                className="mr-2 text-green-500 hover:text-green-700"
              >
                <UserCheck size={18} />
              </button>
              <button
                onClick={() => onDelete(workout.id)}
                className="text-red-500 hover:text-red-700"
              >
                <Trash size={18} />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const ClientSelector = ({ onSelect, onClose }) => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    const { data, error } = await supabase
      .from('clients')
      .select(`
        id,
        name,
        user_profiles!inner (
          id
        )
      `)
      .order('name');
    
    if (error) {
      console.error('Error fetching clients:', error);
    } else {
      setClients(data);
    }
  };

  const handleSubmit = () => {
    onSelect(selectedClient);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg">
        <h3 className="text-lg font-semibold mb-4">Select a Client</h3>
        <select
          value={selectedClient}
          onChange={(e) => setSelectedClient(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md mb-4"
        >
          <option value="">Select a client</option>
          {clients.map((client) => (
            <option key={client.id} value={client.user_profiles.id}>{client.name}</option>
          ))}
        </select>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="mr-2 px-4 py-2 bg-gray-200 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={!selectedClient}
            className="px-4 py-2 bg-orange-500 text-white rounded-md disabled:bg-gray-400"
          >
            Assign
          </button>
        </div>
      </div>
    </div>
  );
};

const WorkoutPlanner = () => {
  const [activeTab, setActiveTab] = useState('library');
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userPreferences, setUserPreferences] = useState({
    goal: '',
    fitnessLevel: '',
    workoutDuration: '',
    daysPerWeek: '',
    equipment: [],
    focus: [],
  });
  const [workoutPlan, setWorkoutPlan] = useState(null);
  const [savedWorkoutPlans, setSavedWorkoutPlans] = useState([]);
  const [isGeneratingPlan, setIsGeneratingPlan] = useState(false);
  const [error, setError] = useState(null);
  const [expandedDay, setExpandedDay] = useState(null);
  const [playingVideo, setPlayingVideo] = useState(null);
  const [showClientSelector, setShowClientSelector] = useState(false);
  const [selectedWorkoutId, setSelectedWorkoutId] = useState(null);
  const [workoutName, setWorkoutName] = useState('Untitled Workout');
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      setIsLoading(true);
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error) {
        console.error('Error fetching user:', error);
        setCurrentUser(null);
      } else {
        setCurrentUser(user);
        if (user) {
          fetchSavedWorkoutPlans(user.id);
        }
      }
      setIsLoading(false);
    };

    fetchCurrentUser();
  }, []);

  const fetchSavedWorkoutPlans = async (userId) => {
    try {
      const { data, error } = await supabase
        .from('workout_plans')
        .select('*')
        .eq('trainer_id', userId)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setSavedWorkoutPlans(data || []);
    } catch (error) {
      console.error('Error fetching saved workout plans:', error);
      setError('Failed to fetch saved workout plans. Please try again later.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserPreferences(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleEquipmentToggle = (equipment) => {
    setUserPreferences(prev => ({
      ...prev,
      equipment: prev.equipment.includes(equipment)
        ? prev.equipment.filter(item => item !== equipment)
        : [...prev.equipment, equipment]
    }));
  };

  const handleFocusToggle = (focus) => {
    setUserPreferences(prev => ({
      ...prev,
      focus: prev.focus.includes(focus)
        ? prev.focus.filter(item => item !== focus)
        : [...prev.focus, focus]
    }));
  };

  const validateInputs = () => {
    const requiredFields = ['goal', 'fitnessLevel', 'workoutDuration', 'daysPerWeek'];
    return requiredFields.every(field => userPreferences[field] !== '') &&
           userPreferences.equipment.length > 0 &&
           userPreferences.focus.length > 0;
  };

  const generateWorkoutPlan = async () => {
    if (!validateInputs()) {
      setError('Please fill in all required fields and select at least one equipment and focus area.');
      return;
    }
  
    setIsGeneratingPlan(true);
    setError(null);
    setWorkoutPlan(null);
  
    try {
      console.log('Sending request to generate workout plan...');
      const response = await supabase.functions.invoke('generate-workout-plan', {
        body: { userPreferences },
      });
  
      console.log('Received response:', response);
  
      if (response.error) {
        throw new Error(response.error.message || 'An error occurred while generating the workout plan');
      }
  
      let workoutPlanData = response.data;
      console.log('Workout plan data:', JSON.stringify(workoutPlanData, null, 2));
  
      if (workoutPlanData.workoutPlan) {
        workoutPlanData = workoutPlanData.workoutPlan;
      }
  
      if (isValidWorkoutPlan(workoutPlanData, userPreferences)) {
        console.log('Setting workout plan:', workoutPlanData);
        setWorkoutPlan({ ...workoutPlanData, preferences: userPreferences });
        setWorkoutName('Untitled Workout');
        setActiveTab('view');
        setIsEditMode(false);
      } else {
        throw new Error('Generated workout plan does not meet requirements');
      }
    } catch (error) {
      console.error('Error generating workout plan:', error);
      setError(`Failed to generate workout plan: ${error.message}. Please try again.`);
    } finally {
      setIsGeneratingPlan(false);
    }
  };

  const isValidWorkoutPlan = (workoutPlan, userPreferences) => {
    console.log('Validating workout plan:', JSON.stringify(workoutPlan, null, 2));
    console.log('User preferences:', JSON.stringify(userPreferences, null, 2));

    const expectedDays = parseInt(userPreferences.daysPerWeek);

    if (Object.keys(workoutPlan).length !== expectedDays) {
      console.error(`Invalid number of workout days. Expected: ${expectedDays}, Actual: ${Object.keys(workoutPlan).length}`);
      return false;
    }

    for (let i = 1; i <= expectedDays; i++) {
      const day = workoutPlan[`day${i}`];
      if (!day || !Array.isArray(day.focus) || !Array.isArray(day.warmUp) || !Array.isArray(day.exercises) || !Array.isArray(day.coolDown)) {
        console.error(`Day ${i} is missing required fields or has incorrect data types`);
        return false;
      }

      if (day.exercises.length < 3 || day.exercises.length > 5) {
        console.error(`Day ${i} has an invalid number of exercises. Expected: 3-5, Actual: ${day.exercises.length}`);
        return false;
      }

      for (const exercise of day.exercises) {
        if (!exercise.name || !exercise.equipment || !exercise.sets || !exercise.reps || !exercise.rest || !exercise.videoUrl) {
          console.error(`Invalid exercise in day ${i}:`, exercise);
          return false;
        }
        if (!exercise.videoUrl.startsWith('https://www.youtube.com/')) {
          console.error(`Invalid video URL for exercise ${exercise.name}:`, exercise.videoUrl);
          return false;
        }
      }
    }

    console.log('Workout plan is valid');
    return true;
  };

  const saveWorkoutPlan = async () => {
    if (!currentUser) {
      setError('You must be logged in to save a workout plan. Please log in and try again.');
      return;
    }

    try {
      let data, error;
      if (isEditMode) {
        ({ data, error } = await supabase
          .from('workout_plans')
          .update({
            name: workoutName,
            preferences: workoutPlan.preferences,
            plan: workoutPlan,
            updated_at: new Date().toISOString()
          })
          .eq('id', workoutPlan.id));
      } else {
        ({ data, error } = await supabase
          .from('workout_plans')
          .insert({
            trainer_id: currentUser.id,
            name: workoutName,
            preferences: workoutPlan.preferences,
            plan: workoutPlan,
            created_at: new Date().toISOString()
          }));
      }

      if (error) throw error;

      alert(isEditMode ? 'Workout plan updated successfully!' : 'Workout plan saved successfully!');
      fetchSavedWorkoutPlans(currentUser.id);
      setActiveTab('library');
      setIsEditMode(false);
    } catch (error) {
      console.error('Error saving workout plan:', error);
      setError('Failed to save workout plan. Please try again.');
    }
  };

  const deleteWorkoutPlan = async (planId) => {
    try {
      const { error } = await supabase
        .from('workout_plans')
        .delete()
        .eq('id', planId);

      if (error) throw error;

      alert('Workout plan deleted successfully!');
      fetchSavedWorkoutPlans(currentUser.id);
    } catch (error) {
      console.error('Error deleting workout plan:', error);
      setError('Failed to delete workout plan. Please try again.');
    }
  };

  const handleAssignWorkout = (workoutId) => {
    setSelectedWorkoutId(workoutId);
    setShowClientSelector(true);
  };

  const assignWorkoutToClient = async (clientId) => {
    if (!selectedWorkoutId) return;

    try {
      const { data, error } = await supabase
        .from('client_workouts')
        .insert({
          workout_id: selectedWorkoutId,
          client_id: clientId,
          trainer_id: currentUser.id,
        });

      if (error) throw error;

      alert('Workout assigned to client successfully!');
      setShowClientSelector(false);
    } catch (error) {
      console.error('Error assigning workout to client:', error);
      setError(`Failed to assign workout to client: ${error.message}. Please try again.`);
    }
  };

  const getYouTubeId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  const fallbackVideos = {
    'Upper Body': 'dZgVxmf6jkA',
    'Lower Body': 'RjexvOAsVtI',
    'Core': 'DHD1-2PKHv8',
    'Cardio': '5qap5aO4i9A',
    'Full Body': 'oAPCPjnU1wA'
  };

  const handleEditWorkout = (workout) => {
    setWorkoutPlan(workout.plan);
    setWorkoutName(workout.name);
    setUserPreferences(workout.preferences);
    setActiveTab('view');
    setIsEditMode(true);
  };

  const renderVideoEmbed = (videoUrl, exerciseName, focusArea) => {
    let videoId = getYouTubeId(videoUrl);
    
    if (!videoId) {
      console.warn(`Invalid video URL for ${exerciseName}. Using fallback video.`);
      videoId = fallbackVideos[focusArea] || fallbackVideos['Full Body'];
    }
    
    return (
      <div className="mt-2">
        <button 
          onClick={() => setPlayingVideo(videoId)}
          className="w-full bg-gray-100 hover:bg-gray-200 transition-colors duration-200 rounded-lg overflow-hidden shadow-md flex items-center"
        >
          <img 
            src={`https://img.youtube.com/vi/${videoId}/default.jpg`}
            alt="Video thumbnail"
            className="w-20 h-20 object-cover"
          />
          <div className="p-2 text-left flex-grow">
            <p className="text-sm font-medium text-gray-800 truncate">Watch Exercise Demo</p>
            <p className="text-xs text-gray-500">Click to play video</p>
          </div>
          <Play size={20} className="text-gray-600 mr-2" />
        </button>
      </div>
    );
  };

  const GeneratePlanForm = () => (
    <div className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Fitness Goal</label>
          <select
            name="goal"
            value={userPreferences.goal}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
          >
            <option value="">Select a goal</option>
            <option value="weightLoss">Weight Loss</option>
            <option value="muscleGain">Muscle Gain</option>
            <option value="endurance">Endurance</option>
            <option value="strength">Strength</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Fitness Level</label>
          <select
            name="fitnessLevel"
            value={userPreferences.fitnessLevel}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
          >
            <option value="">Select fitness level</option>
            <option value="beginner">Beginner</option>
            <option value="intermediate">Intermediate</option>
            <option value="advanced">Advanced</option>
          </select>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Workout Duration (minutes)</label>
          <input
            type="number"
            name="workoutDuration"
            value={userPreferences.workoutDuration}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Days per Week</label>
          <input
            type="number"
            name="daysPerWeek"
            value={userPreferences.daysPerWeek}
            onChange={handleInputChange}
            min="1"
            max="7"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
          />
        </div>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Available Equipment</label>
        <div className="mt-2 flex flex-wrap gap-2">
          {['Dumbbells', 'Barbell', 'Resistance Bands', 'Pull-up Bar', 'Bench', 'Treadmill', 'None'].map(equipment => (
            <button
              key={equipment}
              onClick={() => handleEquipmentToggle(equipment)}
              className={`px-3 py-1 rounded-full text-sm font-medium transition-colors duration-200 ${
                userPreferences.equipment.includes(equipment)
                  ? 'bg-orange-500 text-white'
                  : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
              }`}
            >
              {equipment}
            </button>
          ))}
        </div>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Focus Areas</label>
        <div className="mt-2 flex flex-wrap gap-2">
          {['Upper Body', 'Lower Body', 'Core', 'Cardio', 'Full Body'].map(focus => (
            <button
              key={focus}
              onClick={() => handleFocusToggle(focus)}
              className={`px-3 py-1 rounded-full text-sm font-medium transition-colors duration-200 ${
                userPreferences.focus.includes(focus)
                  ? 'bg-orange-500 text-white'
                  : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
              }`}
            >
              {focus}
            </button>
          ))}
        </div>
      </div>
      <motion.button
        onClick={generateWorkoutPlan}
        disabled={isGeneratingPlan || !validateInputs()}
        className="w-full bg-orange-500 text-white py-2 px-4 rounded-md hover:bg-orange-600 transition-colors duration-200 disabled:bg-gray-400 disabled:cursor-not-allowed font-medium text-lg shadow-md flex items-center justify-center"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        animate={isGeneratingPlan ? { scale: [1, 1.05, 1] } : {}}
        transition={isGeneratingPlan ? { repeat: Infinity, duration: 1 } : {}}
      >
        {isGeneratingPlan ? <Loader2 className="animate-spin mr-2" size={24} /> : <Dumbbell className="mr-2" size={24} />}
        {isGeneratingPlan ? 'Generating...' : 'Generate Workout Plan'}
      </motion.button>
    </div>
  );

  const ViewPlan = ({ plan, onClose }) => {
    const [localPlan, setLocalPlan] = useState(plan);
    const [localWorkoutName, setLocalWorkoutName] = useState(workoutName);

    useEffect(() => {
      setLocalPlan(plan);
      setLocalWorkoutName(workoutName);
    }, [plan, workoutName]);

    const handleNameChange = (e) => {
      setLocalWorkoutName(e.target.value);
    };

    const handlePreferenceChange = (key, value) => {
      setLocalPlan(prev => ({
        ...prev,
        preferences: {
          ...prev.preferences,
          [key]: value
        }
      }));
    };

    const handleExerciseChange = (day, exerciseIndex, field, value) => {
      setLocalPlan(prev => ({
        ...prev,
        [day]: {
          ...prev[day],
          exercises: prev[day].exercises.map((exercise, index) => 
            index === exerciseIndex ? { ...exercise, [field]: value } : exercise
          )
        }
      }));
    };

    const handleSave = () => {
      setWorkoutName(localWorkoutName);
      setWorkoutPlan(localPlan);
      saveWorkoutPlan();
      setIsEditMode(false);
    };

    const renderExercise = (exercise, day, index) => (
      <div key={index} className="bg-gray-50 rounded-lg p-4">
        {isEditMode ? (
          <>
            <input
              type="text"
              value={exercise.name}
              onChange={(e) => handleExerciseChange(day, index, 'name', e.target.value)}
              className="font-medium text-gray-800 w-full mb-2"
            />
            <input
              type="text"
              value={exercise.equipment}
              onChange={(e) => handleExerciseChange(day, index, 'equipment', e.target.value)}
              className="text-sm text-gray-600 w-full mb-2"
            />
            <div className="flex space-x-2 mb-2">
              <input
                type="number"
                value={exercise.sets}
                onChange={(e) => handleExerciseChange(day, index, 'sets', e.target.value)}
                className="text-sm text-gray-600 w-1/3"
              />
              <input
                type="number"
                value={exercise.reps}
                onChange={(e) => handleExerciseChange(day, index, 'reps', e.target.value)}
                className="text-sm text-gray-600 w-1/3"
              />
              <input
                type="text"
                value={exercise.rest}
                onChange={(e) => handleExerciseChange(day, index, 'rest', e.target.value)}
                className="text-sm text-gray-600 w-1/3"
              />
            </div>
            <input
              type="text"
              value={exercise.videoUrl}
              onChange={(e) => handleExerciseChange(day, index, 'videoUrl', e.target.value)}
              className="text-sm text-gray-600 w-full mb-2"
            />
          </>
        ) : (
          <>
            <p className="font-medium text-gray-800">{exercise.name}</p>
            <p className="text-sm text-gray-600">Equipment: {exercise.equipment}</p>
            <p className="text-sm text-gray-600">Sets: {exercise.sets}, Reps: {exercise.reps}, Rest: {exercise.rest}</p>
          </>
        )}
        {renderVideoEmbed(exercise.videoUrl, exercise.name, localPlan[day].focus[0])}
      </div>
    );

    return (
      <div className="space-y-6">
        <div className="flex justify-between items-center border-b pb-4">
          {isEditMode ? (
            <input
              type="text"
              value={localWorkoutName}
              onChange={handleNameChange}
              className="text-2xl font-semibold text-gray-800 border-b border-orange-300 focus:outline-none focus:border-orange-500"
            />
          ) : (
            <h3 className="text-2xl font-semibold text-gray-800">{localWorkoutName}</h3>
          )}
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>

        <div className="grid grid-cols-2 gap-4 text-sm bg-gray-50 p-4 rounded-lg">
          <div>
            <label className="font-medium">Goal:</label>
            {isEditMode ? (
              <select
                value={localPlan.preferences.goal}
                onChange={(e) => handlePreferenceChange('goal', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
              >
                <option value="">Select a goal</option>
                <option value="weightLoss">Weight Loss</option>
                <option value="muscleGain">Muscle Gain</option>
                <option value="endurance">Endurance</option>
                <option value="strength">Strength</option>
              </select>
            ) : (
              <p>{localPlan.preferences.goal}</p>
            )}
          </div>
          <div>
            <label className="font-medium">Fitness Level:</label>
            {isEditMode ? (
              <select
                value={localPlan.preferences.fitnessLevel}
                onChange={(e) => handlePreferenceChange('fitnessLevel', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
              >
                <option value="">Select fitness level</option>
                <option value="beginner">Beginner</option>
                <option value="intermediate">Intermediate</option>
                <option value="advanced">Advanced</option>
              </select>
            ) : (
              <p>{localPlan.preferences.fitnessLevel}</p>
            )}
          </div>
          <div>
            <label className="font-medium">Duration:</label>
            {isEditMode ? (
              <input
                type="number"
                value={localPlan.preferences.workoutDuration}
                onChange={(e) => handlePreferenceChange('workoutDuration', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
              />
            ) : (
              <p>{localPlan.preferences.workoutDuration} minutes</p>
            )}
          </div>
          <div>
            <label className="font-medium">Days per Week:</label>
            {isEditMode ? (
              <input
                type="number"
                value={localPlan.preferences.daysPerWeek}
                onChange={(e) => handlePreferenceChange('daysPerWeek', e.target.value)}
                min="1"
                max="7"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
              />
            ) : (
              <p>{localPlan.preferences.daysPerWeek}</p>
            )}
          </div>
        </div>

        {localPlan && typeof localPlan === 'object' && (
          <div className="space-y-6">
            <h4 className="text-xl font-semibold text-gray-800 border-b pb-2">Workout Plan</h4>
            {Object.entries(localPlan).filter(([key]) => !['preferences', 'id', 'trainer_id', 'created_at', 'updated_at'].includes(key)).map(([day, dayPlan]) => (
              <div key={day} className="space-y-4 bg-white p-6 rounded-lg shadow-md">
                <h5 className="text-lg font-medium text-gray-800 border-b pb-2">{day.replace(/day/i, 'Day ')}</h5>
                {dayPlan && typeof dayPlan === 'object' && (
                  <div className="space-y-4">
                    <p className="font-medium"><strong>Focus:</strong> {Array.isArray(dayPlan.focus) ? dayPlan.focus.join(', ') : 'Not specified'}</p>
                    
                    <div>
                      <h6 className="font-medium text-gray-700 mb-2">Warm-up:</h6>
                      <ul className="list-disc list-inside pl-4 space-y-1">
                        {Array.isArray(dayPlan.warmUp) ? dayPlan.warmUp.map((item, index) => (
                          <li key={index}>{item}</li>
                        )) : <li>No warm-up specified</li>}
                      </ul>
                    </div>
                    
                    <div>
                      <h6 className="font-medium text-gray-700 mb-2">Main Exercises:</h6>
                      <div className="space-y-4">
                        {Array.isArray(dayPlan.exercises) ? dayPlan.exercises.map((exercise, index) => renderExercise(exercise, day, index)) : <p>No exercises specified</p>}
                      </div>
                    </div>
                    
                    <div>
                      <h6 className="font-medium text-gray-700 mb-2">Cool-down:</h6>
                      <ul className="list-disc list-inside pl-4 space-y-1">
                        {Array.isArray(dayPlan.coolDown) ? dayPlan.coolDown.map((item, index) => (
                          <li key={index}>{item}</li>
                        )) : <li>No cool-down specified</li>}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {isEditMode && (
          <motion.button
            onClick={handleSave}
            className="w-full bg-orange-500 text-white py-2 px-4 rounded-md hover:bg-orange-600 transition-colors duration-200 flex items-center justify-center font-medium text-lg shadow-md"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Save size={24} className="mr-2" />
            Save Changes
          </motion.button>
        )}
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="animate-spin" size={48} />
      </div>
    );
  }

  if (!currentUser) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-red-100 border-l-4 border-red-500 text-red-700 p-6 rounded-lg shadow-md"
        role="alert"
      >
        <p className="font-bold text-lg mb-2">Authentication Required</p>
        <p>You must be logged in to use the Workout Planner. Please log in and try again.</p>
      </motion.div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto p-6 pb-24 sm:pb-6 space-y-8">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold text-gray-800 sm:text-2xl md:text-3xl">
          AI Workout Planner
        </h1>
        <Link to="/dashboard" className="text-orange-500 hover:text-orange-600 flex items-center transition-colors duration-200">
          <ArrowLeft size={20} className="mr-2" />
          <span className="hidden sm:inline">Back to Dashboard</span>
          <span className="sm:hidden">Back</span>
        </Link>
      </div>
      
      <div className="bg-gray-100 p-4 rounded-lg">
        <div className="flex space-x-2 mb-4">
          <Tab 
            label="Workout Library" 
            active={activeTab === 'library'} 
            onClick={() => setActiveTab('library')} 
          />
          <Tab 
            label="Generate Plan" 
            active={activeTab === 'generate'} 
            onClick={() => setActiveTab('generate')} 
          />
          {workoutPlan && (
            <Tab 
              label="View Plan" 
              active={activeTab === 'view'} 
              onClick={() => setActiveTab('view')} 
            />
          )}
        </div>
        
        <div className="bg-white p-6 rounded-lg shadow-md">
          {activeTab === 'library' && (
              <WorkoutTable 
                workouts={savedWorkoutPlans}
                onView={(workout) => {
                  setWorkoutPlan(workout.plan);
                  setWorkoutName(workout.name);
                  setUserPreferences(workout.preferences);
                  setActiveTab('view');
                  setIsEditMode(false);
                }}
                onEdit={handleEditWorkout}
                onAssign={handleAssignWorkout}
                onDelete={deleteWorkoutPlan}
              />
            )}
          
          {activeTab === 'generate' && (
            <GeneratePlanForm />
          )}
          
          {activeTab === 'view' && workoutPlan && (
            <ViewPlan 
              plan={workoutPlan} 
              onClose={() => {
                setWorkoutPlan(null);
                setActiveTab('library');
                setIsEditMode(false);
              }}
            />
          )}
        </div>
      </div>

      <AnimatePresence>
        {error && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="bg-red-100 border-l-4 border-red-500 text-red-700 p-6 rounded-lg shadow-md"
            role="alert"
          >
            <p className="font-bold text-lg mb-2">Error</p>
            <p>{error}</p>
          </motion.div>
        )}
      </AnimatePresence>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.6 }}
        className="bg-orange-50 border-l-4 border-orange-500 p-4 rounded-lg shadow-md mb-20 sm:mb-0"
      >
        <div className="flex items-start">
          <Info className="flex-shrink-0 text-orange-500 mr-3" />
          <p className="text-sm text-orange-700">
            This workout plan is generated by AI based on your preferences and general fitness guidelines. Always consult with a healthcare professional or certified fitness trainer before starting any new exercise program.
          </p>
        </div>
      </motion.div>

      {playingVideo && (
        <VideoPlayer
          videoId={playingVideo}
          onClose={() => setPlayingVideo(null)}
        />
      )}

      {showClientSelector && (
        <ClientSelector
          onSelect={(clientId) => {
            assignWorkoutToClient(clientId);
            setShowClientSelector(false);
          }}
          onClose={() => setShowClientSelector(false)}
        />
      )}
    </div>
  );
};

export default WorkoutPlanner;