import React, { useState, useEffect, useRef } from 'react';
import { supabase } from '../utils/supabase';
import { Edit, Trash2, PlusCircle, Search, Image, Bold, Italic, List, Link as LinkIcon, AlignLeft, AlignCenter, AlignRight, Eye } from 'lucide-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { v4 as uuidv4 } from 'uuid';

const BlogManagement = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [editingPost, setEditingPost] = useState(null);
  const [categories, setCategories] = useState([]);
  const [previewMode, setPreviewMode] = useState(false);

  useEffect(() => {
    fetchPosts();
    fetchCategories();
  }, []);

  const fetchPosts = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('blog_posts')
        .select('*')
        .order('date', { ascending: false });

      if (error) throw error;
      setPosts(data);
    } catch (error) {
      setError('Failed to fetch blog posts');
      console.error('Error fetching blog posts:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const { data, error } = await supabase
        .from('categories')
        .select('*')
        .order('name');

      if (error) throw error;
      setCategories(data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleCreatePost = () => {
    setEditingPost({
      title: '',
      author: 'FIT Buddy',
      date: new Date().toISOString().split('T')[0],
      image: '',
      excerpt: '',
      content: '',
      category_ids: [],
      featured: false,
      meta_description: '',
      meta_keywords: ''
    });
    setPreviewMode(false);
  };

  const handleEditPost = (post) => {
    setEditingPost(post);
    setPreviewMode(false);
  };

  const handleDeletePost = async (id) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        const { error } = await supabase
          .from('blog_posts')
          .delete()
          .eq('id', id);

        if (error) throw error;
        fetchPosts();
      } catch (error) {
        setError('Failed to delete the post');
        console.error('Error deleting post:', error);
      }
    }
  };

  const handleSavePost = async (post) => {
    try {
      let result;
      if (post.id) {
        result = await supabase
          .from('blog_posts')
          .update(post)
          .eq('id', post.id);
      } else {
        result = await supabase
          .from('blog_posts')
          .insert([post]);
      }

      if (result.error) throw result.error;
      setEditingPost(null);
      fetchPosts();
    } catch (error) {
      setError('Failed to save the post');
      console.error('Error saving post:', error);
    }
  };

  const handlePreviewToggle = () => {
    setPreviewMode(!previewMode);
  };

  const filteredPosts = posts.filter(post =>
    post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    post.author.toLowerCase().includes(searchTerm.toLowerCase()) ||
    post.category_ids.some(id => categories.find(c => c.id === id)?.name.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="space-y-6 p-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Blog Posts</h2>
        <button
          onClick={handleCreatePost}
          className="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition-colors flex items-center"
        >
          <PlusCircle size={20} className="mr-2" />
          Create New Post
        </button>
      </div>

      <div className="relative">
        <input
          type="text"
          placeholder="Search posts..."
          className="w-full p-2 pl-10 border rounded-lg"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
      </div>

      {editingPost && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
          <div className="relative top-20 mx-auto p-5 border w-4/5 shadow-lg rounded-md bg-white">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold">{editingPost.id ? 'Edit Post' : 'Create New Post'}</h3>
              <button onClick={() => setEditingPost(null)} className="text-gray-500 hover:text-gray-700">
                &times;
              </button>
            </div>
            <PostForm 
              post={editingPost} 
              onSave={handleSavePost} 
              onCancel={() => setEditingPost(null)} 
              categories={categories}
              previewMode={previewMode}
              onPreviewToggle={handlePreviewToggle}
            />
          </div>
        </div>
      )}

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left">Title</th>
              <th className="py-3 px-6 text-left">Author</th>
              <th className="py-3 px-6 text-left">Date</th>
              <th className="py-3 px-6 text-left">Category</th>
              <th className="py-3 px-6 text-left">Status</th>
              <th className="py-3 px-6 text-left">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {filteredPosts.map((post) => (
              <tr key={post.id} className="border-b border-gray-200 hover:bg-gray-100">
                <td className="py-3 px-6 text-left whitespace-nowrap">{post.title}</td>
                <td className="py-3 px-6 text-left">{post.author}</td>
                <td className="py-3 px-6 text-left">{post.date}</td>
                <td className="py-3 px-6 text-left">{post.category_ids.map(id => categories.find(c => c.id === id)?.name).join(', ')}</td>
                <td className="py-3 px-6 text-left">{post.featured ? 'Published' : 'Draft'}</td>
                <td className="py-3 px-6 text-left">
                  <button
                    onClick={() => handleEditPost(post)}
                    className="text-blue-500 hover:text-blue-700 mr-2"
                  >
                    <Edit size={18} />
                  </button>
                  <button
                    onClick={() => handleDeletePost(post.id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <Trash2 size={18} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const PostForm = ({ post, onSave, onCancel, categories, previewMode, onPreviewToggle }) => {
  const [formData, setFormData] = useState(post);
  const [wordCount, setWordCount] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const quillRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const words = formData.content.replace(/<[^>]*>/g, '').trim().split(/\s+/).length;
    setWordCount(words);
  }, [formData.content]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleContentChange = (content) => {
    setFormData(prev => ({ ...prev, content }));
  };

  const handleCategoryChange = (e) => {
    const categoryId = parseInt(e.target.value);
    setFormData(prev => ({
      ...prev,
      category_ids: e.target.checked
        ? [...prev.category_ids, categoryId]
        : prev.category_ids.filter(id => id !== categoryId)
    }));
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileExt = file.name.split('.').pop();
      const fileName = `${uuidv4()}.${fileExt}`;
      const filePath = `blog_images/${fileName}`;

      try {
        const { error: uploadError } = await supabase.storage
          .from('blog-images')
          .upload(filePath, file);

        if (uploadError) throw uploadError;

        const { data } = supabase.storage.from('blog-images').getPublicUrl(filePath);

        setFormData(prev => ({ ...prev, image: data.publicUrl }));
      } catch (error) {
        console.error('Error uploading image:', error);
        alert('Failed to upload image. Please try again.');
      }
    }
  };

  const handleSaveDraft = async () => {
    setIsSaving(true);
    try {
      const draftData = { ...formData, featured: false };
      await onSave(draftData);
      alert('Draft saved successfully!');
    } catch (error) {
      console.error('Error saving draft:', error);
      alert('Failed to save draft. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    try {
      const dataToPublish = { ...formData, featured: true };
      await onSave(dataToPublish);
    } catch (error) {
      console.error('Error publishing post:', error);
      alert('Failed to publish post. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleFormatText = (format) => {
    const quill = quillRef.current.getEditor();
    quill.format(format, !quill.getFormat()[format]);
  };

  const handleSetFeaturedImage = () => {
    fileInputRef.current.click();
  };

  if (previewMode) {
    return (
      <div className="preview-mode">
        <button onClick={onPreviewToggle} className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
          Exit Preview
        </button>
        <h1 className="text-4xl font-bold mb-4">{formData.title}</h1>
        <div className="mb-4 text-gray-600">
          <span>{formData.author}</span> | <span>{formData.date}</span>
        </div>
        {formData.image && <img src={formData.image} alt={formData.title} className="mb-4 rounded-lg" />}
        <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: formData.content }} />
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="flex justify-between mb-4">
        <button type="button" onClick={handleSaveDraft} disabled={isSaving} className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600">
          {isSaving ? 'Saving...' : 'Save Draft'}
        </button>
        <button type="button" onClick={onPreviewToggle} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
          Preview <Eye size={18} className="inline ml-2" />
        </button>
      </div>

      <input
        type="text"
        name="title"
        value={formData.title}
        onChange={handleChange}
        placeholder="Enter title"
        className="w-full p-2 border rounded"
        required
      />

      <input
        type="text"
        name="author"
        value={formData.author}
        onChange={handleChange}
        placeholder="Author"
        className="w-full p-2 border rounded"
      />

      <input
        type="date"
        name="date"
        value={formData.date}
        onChange={handleChange}
        className="w-full p-2 border rounded"
      />

      <div>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleImageUpload}
          accept="image/*"
          className="hidden"
        />
        <button 
          type="button"
          onClick={handleSetFeaturedImage}
          className="w-full p-2 border rounded bg-gray-100 hover:bg-gray-200"
        >
          Set Featured Image
        </button>
        {formData.image && (
          <img src={formData.image} alt="Featured" className="mt-2 max-h-40 rounded" />
        )}
      </div>

      <div>
        <label className="block mb-2">Categories:</label>
        {categories.map(category => (
          <label key={category.id} className="inline-flex items-center mr-4">
            <input
              type="checkbox"
              value={category.id}
              checked={formData.category_ids.includes(category.id)}
              onChange={handleCategoryChange}
              className="form-checkbox h-5 w-5 text-orange-600"
            />
            <span className="ml-2">{category.name}</span>
          </label>
          ))}
          </div>
    
          <div className="flex items-center space-x-2 my-4">
            <button type="button" onClick={() => handleFormatText('bold')} className="p-2 hover:bg-gray-100 rounded"><Bold size={20} /></button>
            <button type="button" onClick={() => handleFormatText('italic')} className="p-2 hover:bg-gray-100 rounded"><Italic size={20} /></button>
            <button type="button" onClick={() => handleFormatText('list')} className="p-2 hover:bg-gray-100 rounded"><List size={20} /></button>
            <button type="button" onClick={() => handleFormatText('link')} className="p-2 hover:bg-gray-100 rounded"><LinkIcon size={20} /></button>
            <button type="button" onClick={() => handleFormatText('align')} className="p-2 hover:bg-gray-100 rounded"><AlignLeft size={20} /></button>
          </div>
    
          <ReactQuill
            ref={quillRef}
            value={formData.content}
            onChange={handleContentChange}
            className="h-64 mb-12"
          />
    
          <div className="text-sm text-gray-500">Word count: {wordCount}</div>
    
          <textarea
            name="excerpt"
            value={formData.excerpt}
            onChange={handleChange}
            placeholder="Enter excerpt (for SEO and previews)"
            className="w-full p-2 border rounded h-24"
          />
    
          <input
            type="text"
            name="meta_description"
            value={formData.meta_description}
            onChange={handleChange}
            placeholder="Meta description for SEO"
            className="w-full p-2 border rounded"
          />
    
          <input
            type="text"
            name="meta_keywords"
            value={formData.meta_keywords}
            onChange={handleChange}
            placeholder="Meta keywords for SEO (comma-separated)"
            className="w-full p-2 border rounded"
          />
    
          <div className="flex items-center">
            <input
              type="checkbox"
              name="featured"
              checked={formData.featured}
              onChange={handleChange}
              className="form-checkbox h-5 w-5 text-orange-600"
            />
            <label className="ml-2">Featured (Published)</label>
          </div>
    
          <div className="flex justify-end space-x-4">
            <button type="button" onClick={onCancel} className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400">
              Cancel
            </button>
            <button type="submit" disabled={isSaving} className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600">
              {isSaving ? 'Saving...' : (formData.featured ? 'Publish' : 'Save')}
            </button>
          </div>
        </form>
      );
    };
    
    export default BlogManagement;