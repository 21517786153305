import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Check, X } from 'lucide-react';
import { supabase } from '../utils/supabase'; // Adjust this import based on your project structure
import MembershipPaymentModal from './MembershipPaymentModal';

// Import the Supabase URL from your environment or configuration
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;

const Membership = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [availablePlans, setAvailablePlans] = useState([]);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      console.log('Fetching user data...');
      const { data: { user }, error: authError } = await supabase.auth.getUser();
      if (authError) {
        console.error('Auth error:', authError);
        throw authError;
      }
      console.log('User data:', user);

      if (user) {
        console.log('Fetching user profile...');
        const { data: userData, error: userError } = await supabase
          .from('user_profiles')
          .select('*, membership_plans(*)')
          .eq('id', user.id)
          .single();
        
        if (userError) {
          console.error('User profile error:', userError);
          throw userError;
        }
        console.log('User profile:', userData);
        setCurrentUser(userData);
        setCurrentPlan(userData.membership_plans);
      }

      console.log('Fetching available plans...');
      const { data: plansData, error: plansError } = await supabase
        .from('membership_plans')
        .select('*')
        .order('price', { ascending: true });
      
      if (plansError) {
        console.error('Plans fetch error:', plansError);
        throw plansError;
      }
      console.log('Available plans:', plansData);
      setAvailablePlans(plansData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpgrade = (plan) => {
    console.log('Selected plan:', plan);
    setSelectedPlan(plan);
    setShowPaymentModal(true);
  };

  const handlePaymentSuccess = async (paymentMethod, subscriptionId, stripeTransactionId) => {
    try {
      const startDate = new Date();
      const nextPaymentDate = new Date(startDate);
      nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1);

      // Call the Supabase RPC function
      const { data, error } = await supabase.rpc('handle_subscription_update', {
        p_user_id: currentUser.id,
        p_plan_id: selectedPlan.id,
        p_membership_status: 'active',
        p_start_date: startDate.toISOString(),
        p_next_payment_date: nextPaymentDate.toISOString(),
        p_subscription_id: subscriptionId,
        p_amount: selectedPlan.price,
        p_currency: 'USD',
        p_payment_method: paymentMethod,
        p_stripe_transaction_id: stripeTransactionId
      });

      if (error) {
        console.error('Error updating subscription:', error);
        if (error.code === '23514') {
          // This is the constraint violation error
          alert('There was an issue recording your payment. Please contact support.');
        } else {
          alert('An unexpected error occurred. Please try again or contact support.');
        }
        // You might want to implement some rollback logic here
        return;
      }

      fetchData();
      setShowPaymentModal(false);
      alert('Membership upgraded successfully!');
    } catch (error) {
      console.error('Error updating membership:', error);
      alert('Failed to upgrade membership. Please try again or contact support.');
    }
  };

  const handleCancelMembership = async () => {
    if (window.confirm('Are you sure you want to cancel your membership?')) {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        
        const response = await fetch(`${supabaseUrl}/functions/v1/stripe`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.access_token}`
          },
          body: JSON.stringify({
            action: 'cancel-subscription',
            subscription_id: currentUser.subscription_id
          })
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || 'An error occurred while canceling the subscription');
        }

        if (data.status === 'canceled') {
          console.log('Subscription canceled successfully');
          
          // Update local state
          setCurrentPlan(null);
          setCurrentUser({
            ...currentUser,
            subscription_status: 'canceled',
            membership_status: 'inactive',
            current_period_end: null,
            subscription_id: null
          });

          alert('Membership canceled successfully.');
          // Optionally, refresh the page or update the UI as needed
        } else {
          throw new Error('Unexpected response from server');
        }
      } catch (error) {
        console.error('Error canceling membership:', error);
        alert('Failed to cancel membership. Please try again or contact support.');
      }
    }
  };

  if (loading) {
    return <div className="max-w-4xl mx-auto p-4">Loading membership details...</div>;
  }

  if (error) {
    return (
      <div className="max-w-4xl mx-auto p-4">
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
          <p className="font-bold">Error</p>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-4 pb-20 sm:pb-4"> {/* Added pb-20 and sm:pb-4 */}
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-2xl font-bold">Membership</h1>
        <button onClick={() => navigate('/')} className="text-orange-500 font-medium flex items-center">
          <ArrowLeft size={20} className="mr-1" /> Back to Dashboard
        </button>
      </div>

      {currentPlan && (
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="text-xl font-semibold mb-4">Current Plan</h2>
          <div className="bg-orange-100 p-4 rounded-lg">
            <h3 className="text-lg font-semibold text-orange-800">{currentPlan.name}</h3>
            <p className="text-orange-800">${currentPlan.price} / month</p>
            <p className="text-sm text-orange-700 mt-2">
              Started on: {currentUser.membership_start_date ? new Date(currentUser.membership_start_date).toLocaleDateString() : 'N/A'}
            </p>
            <p className="text-sm text-orange-700">
              Next payment: {currentUser.next_payment_date ? new Date(currentUser.next_payment_date).toLocaleDateString() : 'N/A'}
            </p>
          </div>
          <button 
            onClick={handleCancelMembership}
            className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
          >
            Cancel Membership
          </button>
        </div>
      )}

      <h2 className="text-xl font-semibold mb-4">Available Plans</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {availablePlans.map((plan) => (
          <div key={plan.id} className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-lg font-semibold mb-2">{plan.name}</h3>
            <p className="text-2xl font-bold mb-4">${plan.price} / month</p>
            <ul className="mb-4">
              {plan.features && Array.isArray(plan.features) && plan.features.map((feature, index) => (
                <li key={index} className="flex items-center mb-2">
                  <Check size={16} className="text-green-500 mr-2" />
                  <span>{feature}</span>
                </li>
              ))}
            </ul>
            <button 
              onClick={() => handleUpgrade(plan)}
              className={`w-full py-2 rounded-lg transition-colors ${
                currentPlan && currentPlan.id === plan.id
                  ? 'bg-gray-300 text-gray-600 cursor-not-allowed'
                  : 'bg-orange-500 text-white hover:bg-orange-600'
              }`}
              disabled={currentPlan && currentPlan.id === plan.id}
            >
              {currentPlan && currentPlan.id === plan.id ? 'Current Plan' : 'Upgrade'}
            </button>
          </div>
        ))}
      </div>

      {showPaymentModal && (
        <MembershipPaymentModal
          plan={selectedPlan}
          onClose={() => setShowPaymentModal(false)}
          onSuccess={handlePaymentSuccess}
          currentUser={currentUser}
        />
      )}
    </div>
  );
};

export default Membership;