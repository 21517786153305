import React, { useState, useEffect } from 'react';
import { Search, Edit, Trash2 } from 'lucide-react';
import { supabase } from '../utils/supabase';

const BuddiesManagement = () => {
  const [buddies, setBuddies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBuddies();
  }, []);

  const fetchBuddies = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('user_profiles_with_email')
        .select('*')
        .eq('role', 'buddy');

      if (error) throw error;
      setBuddies(data);
    } catch (error) {
      console.error('Error fetching buddies:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this buddy?')) {
      try {
        const { error } = await supabase
          .from('user_profiles')
          .delete()
          .eq('id', id);

        if (error) throw error;
        fetchBuddies(); // Refresh the list
      } catch (error) {
        console.error('Error deleting buddy:', error);
      }
    }
  };

  const filteredBuddies = buddies.filter(buddy =>
    buddy.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="mb-6 flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800">Buddies Management</h2>
      </div>
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-4 flex justify-between items-center border-b">
          <div className="relative">
            <input 
              type="text" 
              placeholder="Search buddies..." 
              className="pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          </div>
        </div>
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Registration Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredBuddies.map((buddy) => (
              <tr key={buddy.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">{buddy.name}</td>
                <td className="px-6 py-4 whitespace-nowrap">{buddy.email}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {buddy.join_date ? new Date(buddy.join_date).toLocaleDateString() : 'N/A'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button className="text-orange-600 hover:text-orange-900 mr-3"><Edit size={18} /></button>
                  <button onClick={() => handleDelete(buddy.id)} className="text-red-600 hover:text-red-900"><Trash2 size={18} /></button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BuddiesManagement;