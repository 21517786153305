import React from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft, CheckCircle, AlertTriangle } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';

const TermsSection = ({ title, children }) => (
  <motion.section
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="mb-8"
  >
    <h2 className="text-2xl font-bold mb-4 text-gray-800">{title}</h2>
    {children}
  </motion.section>
);

const TermsPage = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="bg-white shadow-sm sticky top-0 z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <div className="flex items-center">
            <button onClick={() => navigate(-1)} className="mr-4 text-gray-600 hover:text-gray-900">
              <ArrowLeft size={24} />
            </button>
            <h1 className="text-2xl font-bold text-gray-900">Terms of Service</h1>
          </div>
          <Link to="/" className="text-orange-500 hover:text-orange-600 transition-colors">
            Back to Home
          </Link>
        </div>
      </header>

      <main className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="bg-orange-100 border-l-4 border-orange-500 p-4 mb-8 rounded-r-lg">
            <div className="flex">
              <div className="flex-shrink-0">
                <AlertTriangle className="h-5 w-5 text-orange-500" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-orange-700">
                  By using FitBuddy, you agree to these Terms of Service. Please read carefully.
                </p>
              </div>
            </div>
          </div>

          <TermsSection title="1. Acceptance of Terms">
            <p className="text-gray-600 mb-4">
              By accessing or using the FitBuddy platform, you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our services.
            </p>
          </TermsSection>

          <TermsSection title="2. User Conduct and Responsibility">
            <p className="text-gray-600 mb-4">
              FitBuddy is a platform that connects fitness enthusiasts. While we strive to create a safe and positive environment, we cannot control the behavior of individual users. Therefore:
            </p>
            <ul className="list-disc list-inside text-gray-600 mb-4">
              <li>FitBuddy is not responsible for the actions, content, information, or data of third parties.</li>
              <li>We do not control or direct users' actions on the platform and are not responsible for user-generated content or conduct.</li>
              <li>Users are solely responsible for their interactions with other users, both online and offline.</li>
            </ul>
          </TermsSection>

          <TermsSection title="3. Safety and Security">
            <p className="text-gray-600 mb-4">
              While we implement measures to enhance user safety, we cannot guarantee the conduct of any users. We strongly advise:
            </p>
            <ul className="list-disc list-inside text-gray-600 mb-4">
              <li>Exercise caution when interacting with other users.</li>
              <li>Meet in public places for in-person activities.</li>
              <li>Report any suspicious or inappropriate behavior to us immediately.</li>
            </ul>
          </TermsSection>

          <TermsSection title="4. Content Guidelines">
            <p className="text-gray-600 mb-4">
              Users are prohibited from posting content that is illegal, abusive, harassing, or violates the rights of others. FitBuddy reserves the right to remove any content that violates these guidelines.
            </p>
          </TermsSection>

          <TermsSection title="5. Limitation of Liability">
            <p className="text-gray-600 mb-4">
              To the fullest extent permitted by law, FitBuddy shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.
            </p>
          </TermsSection>

          <TermsSection title="6. Changes to Terms">
            <p className="text-gray-600 mb-4">
              FitBuddy reserves the right to modify these terms at any time. We will provide notice of significant changes. Continued use of the platform after changes constitutes acceptance of the new terms.
            </p>
          </TermsSection>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="bg-green-100 border-l-4 border-green-500 p-4 mt-8 rounded-r-lg"
          >
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircle className="h-5 w-5 text-green-500" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-green-700">
                  By signing up for FitBuddy, you acknowledge that you have read and agree to these Terms of Service.
                </p>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </main>

      <footer className="bg-gray-100 mt-12">
        <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8 text-center text-gray-500 text-sm">
          <p>&copy; 2024 FitBuddy. All rights reserved.</p>
          <p className="mt-2">Last updated: February 1, 2024</p>
        </div>
      </footer>
    </div>
  );
};

export default TermsPage;