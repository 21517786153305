import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Calendar, BookOpen, Eye, CreditCard, Settings, HelpCircle, LogOut, Utensils, Gift, Calculator, ChevronDown, ChevronRight, Dumbbell, User as UserIcon, Bot, LineChart } from 'lucide-react';
import { signOut, supabase } from '../utils/supabase';

const ExpandableMenuItem = ({ icon, label, items, onClose }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div>
      <div 
        className="flex items-center p-3 hover:bg-gray-100 cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span className="text-orange-500 mr-3">{icon}</span>
        <span>{label}</span>
        <span className="ml-auto">
          {isExpanded ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
        </span>
      </div>
      {isExpanded && (
        <div className="ml-6">
          {items.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
              onClick={onClose}
            >
              <span className="text-orange-500 mr-3">{item.icon}</span>
              <span>{item.label}</span>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

const UserMenu = ({ isOpen, onClose, setIsAuthenticated, currentUser, anchorEl }) => {
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (currentUser && currentUser.id) {
        const { data, error } = await supabase
          .from('user_profiles')
          .select('name, role, image_url')
          .eq('id', currentUser.id)
          .single();

        if (error) {
          console.error('Error fetching user profile:', error);
        } else {
          setUserProfile(data);
        }
      }
    };

    fetchUserProfile();
  }, [currentUser]);

  const getPublicImageUrl = (path) => {
    if (!path) return currentUser?.user_metadata?.avatar_url || "https://via.placeholder.com/150";
    try {
      const { data } = supabase.storage
        .from('profile_images')
        .getPublicUrl(path);
      return data.publicUrl;
    } catch (error) {
      console.error('Error getting public URL:', error);
      return currentUser?.user_metadata?.avatar_url || "https://via.placeholder.com/150";
    }
  };

  const menuItems = [
    {
      icon: <Calendar size={20} />,
      label: 'My Schedule',
      items: [
        { icon: <Calendar size={20} />, label: 'My Events', path: '/my-events' },
        { icon: <BookOpen size={20} />, label: 'My Bookings', path: '/my-bookings' },
        { icon: <Calendar size={20} />, label: 'My Agenda', path: '/my-agenda' },
      ]
    },
    {
      icon: <Utensils size={20} />,
      label: 'Nutrition Tools',
      items: [
        { icon: <Utensils size={20} />, label: 'AI Meal Planner', path: '/meal-planner' },
        { icon: <Utensils size={20} />, label: 'AI Meal PlannerPro', path: '/meal-plannerpro' },
        { icon: <Utensils size={20} />, label: 'Daily Calories Tracker', path: '/daily-calories-tracker' },
        { icon: <Calculator size={20} />, label: 'Macro Calculator', path: '/macro-calculator' },
        { icon: <LineChart size={20} />, label: 'Weight Tracker', path: '/weight-tracker' }, // Added Weight Tracker
      ]
    },
    {
      icon: <Dumbbell size={20} />,
      label: 'Fitness Tools',
      items: [
        { icon: <Dumbbell size={20} />, label: 'AI Workout Planner', path: '/workout-planner' },
        { icon: <Bot size={20} />, label: 'FIT Buddy Coach', path: '/fit-buddy-coach' },
      ]
    },
    { icon: <Gift size={20} />, label: 'Offers', path: '/offers' },
    { icon: <CreditCard size={20} />, label: 'Membership', path: '/membership' },
    { icon: <CreditCard size={20} />, label: 'Payment', path: '/payment' },
    { icon: <Settings size={20} />, label: 'Settings', path: '/settings' },
    { icon: <HelpCircle size={20} />, label: 'Help & Feedback', path: '/help-feedback' },
  ];

  const handleLogout = async () => {
    try {
      await signOut();
      setIsAuthenticated(false);
      onClose();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error.message);
      alert('Failed to log out. Please try again.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 hidden md:block" onClick={onClose}>
      <div 
        className="absolute right-0 w-80 max-h-[calc(100vh-80px)] overflow-y-auto bg-white shadow-lg rounded-md"
        style={{
          top: anchorEl ? `${anchorEl.getBoundingClientRect().bottom + window.scrollY}px` : '80px',
          right: '20px'
        }}
        onClick={e => e.stopPropagation()}
      >
        <div className="p-5">
          <div className="flex items-center mb-4">
            <img 
              src={getPublicImageUrl(userProfile?.image_url)} 
              alt={userProfile?.name || 'User'} 
              className="h-16 w-16 rounded-full object-cover mr-4"
            />
            <div>
              <h3 className="text-lg font-medium text-gray-900">{userProfile?.name || 'User'}</h3>
              <p className="text-sm text-gray-500">{userProfile?.role || 'Buddy'}</p>
            </div>
          </div>
          
          {/* Profile Link */}
          <Link
            to="/profile"
            className="flex items-center p-3 mb-2 bg-orange-100 text-orange-700 rounded-md hover:bg-orange-200"
            onClick={onClose}
          >
            <UserIcon size={20} className="mr-3" />
            <span>View Full Profile</span>
          </Link>

          {menuItems.map((item, index) => (
            item.items ? (
              <ExpandableMenuItem key={index} {...item} onClose={onClose} />
            ) : (
              <Link
                key={index}
                to={item.path}
                className="flex items-center p-3 hover:bg-gray-100 cursor-pointer"
                onClick={onClose}
              >
                <span className="text-orange-500 mr-3">{item.icon}</span>
                <span>{item.label}</span>
              </Link>
            )
          ))}
          <div
            className="flex items-center p-3 hover:bg-gray-100 cursor-pointer text-red-500"
            onClick={handleLogout}
          >
            <LogOut size={20} className="mr-3" />
            <span>Logout</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMenu;