import React from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft, Lock, Shield, Eye, Database, Bell, UserPlus } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';

const PolicySection = ({ title, icon, children }) => (
  <motion.section
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="mb-8"
  >
    <h2 className="text-2xl font-bold mb-4 text-gray-800 flex items-center">
      {icon}
      <span className="ml-2">{title}</span>
    </h2>
    {children}
  </motion.section>
);

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="bg-white shadow-sm sticky top-0 z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <div className="flex items-center">
            <button onClick={() => navigate(-1)} className="mr-4 text-gray-600 hover:text-gray-900">
              <ArrowLeft size={24} />
            </button>
            <h1 className="text-2xl font-bold text-gray-900">Privacy Policy</h1>
          </div>
          <Link to="/" className="text-orange-500 hover:text-orange-600 transition-colors">
            Back to Home
          </Link>
        </div>
      </header>

      <main className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="bg-blue-100 border-l-4 border-blue-500 p-4 mb-8 rounded-r-lg">
            <div className="flex">
              <div className="flex-shrink-0">
                <Lock className="h-5 w-5 text-blue-500" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-blue-700">
                  Your privacy is important to us. This policy outlines how we collect, use, and protect your personal information.
                </p>
              </div>
            </div>
          </div>

          <PolicySection title="Information We Collect" icon={<Database className="h-6 w-6 text-orange-500" />}>
            <p className="text-gray-600 mb-4">
              We collect information you provide directly to us, such as:
            </p>
            <ul className="list-disc list-inside text-gray-600 mb-4">
              <li>Personal details (name, email, date of birth)</li>
              <li>Profile information (fitness goals, interests)</li>
              <li>Content you post on the platform</li>
              <li>Communication data with other users</li>
            </ul>
            <p className="text-gray-600">
              We also automatically collect certain information about your device and how you interact with our platform.
            </p>
          </PolicySection>

          <PolicySection title="How We Use Your Information" icon={<Eye className="h-6 w-6 text-orange-500" />}>
            <p className="text-gray-600 mb-4">
              We use the collected information to:
            </p>
            <ul className="list-disc list-inside text-gray-600 mb-4">
              <li>Provide, maintain, and improve our services</li>
              <li>Match you with compatible fitness buddies</li>
              <li>Personalize your experience and content</li>
              <li>Communicate with you about services and updates</li>
              <li>Ensure platform safety and security</li>
            </ul>
          </PolicySection>

          <PolicySection title="Data Sharing and Disclosure" icon={<UserPlus className="h-6 w-6 text-orange-500" />}>
            <p className="text-gray-600 mb-4">
              We may share your information in the following circumstances:
            </p>
            <ul className="list-disc list-inside text-gray-600 mb-4">
              <li>With other users as part of the platform's functionality</li>
              <li>With service providers who perform services on our behalf</li>
              <li>In response to legal requests or to prevent harm</li>
              <li>With your consent or at your direction</li>
            </ul>
          </PolicySection>

          <PolicySection title="Data Security" icon={<Shield className="h-6 w-6 text-orange-500" />}>
            <p className="text-gray-600 mb-4">
              We implement appropriate technical and organizational measures to protect your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure.
            </p>
          </PolicySection>

          <PolicySection title="Your Choices and Rights" icon={<Bell className="h-6 w-6 text-orange-500" />}>
            <p className="text-gray-600 mb-4">
              You have certain rights regarding your personal information:
            </p>
            <ul className="list-disc list-inside text-gray-600 mb-4">
              <li>Access and update your information</li>
              <li>Request deletion of your data</li>
              <li>Object to processing of your information</li>
              <li>Opt-out of marketing communications</li>
            </ul>
          </PolicySection>

          <PolicySection title="Changes to This Policy" icon={<ArrowLeft className="h-6 w-6 text-orange-500" />}>
            <p className="text-gray-600 mb-4">
              We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page and updating the "Last Updated" date.
            </p>
          </PolicySection>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="bg-green-100 border-l-4 border-green-500 p-4 mt-8 rounded-r-lg"
          >
            <div className="flex">
              <div className="flex-shrink-0">
                <Lock className="h-5 w-5 text-green-500" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-green-700">
                  By using FitBuddy, you consent to our privacy practices as described in this policy.
                </p>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </main>

      <footer className="bg-gray-100 mt-12">
        <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8 text-center text-gray-500 text-sm">
          <p>&copy; 2024 FitBuddy. All rights reserved.</p>
          <p className="mt-2">Last updated: February 1, 2024</p>
        </div>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;