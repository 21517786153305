import React from 'react';
import { Calendar, Package, ShoppingBag, Clock, FileText, DollarSign, Percent } from 'lucide-react';

export const GeneralInfo = ({ client }) => (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <ClientInfoItem icon={Calendar} label="Join Date" value={client.join_date} />
        <ClientInfoItem icon={Clock} label="Last Session" value={client.last_session || 'N/A'} />
        <ClientInfoItem icon={Calendar} label="Total Sessions" value={client.total_sessions || '0'} />
        <ClientInfoItem icon={Calendar} label="Status" value={client.status} />
    </div>
);

export const Packages = ({ packages }) => (
    <div>
        <h3 className="text-xl font-semibold mb-4">Packages</h3>
        {packages.length === 0 ? (
            <p>No packages found.</p>
        ) : (
            <ul className="space-y-4">
                {packages.map((pkg, index) => (
                    <li key={index} className="border p-4 rounded-lg">
                        <p className="font-semibold">{pkg.name}</p>
                        <p>Sessions: {pkg.sessions}</p>
                        <p>Price: ${pkg.price}</p>
                        <p>Purchase Date: {pkg.purchase_date}</p>
                    </li>
                ))}
            </ul>
        )}
    </div>
);

export const AdHocPurchases = ({ purchases }) => (
    <div>
        <h3 className="text-xl font-semibold mb-4">Ad Hoc Purchases</h3>
        {purchases.length === 0 ? (
            <p>No ad hoc purchases found.</p>
        ) : (
            <ul className="space-y-4">
                {purchases.map((purchase, index) => (
                    <li key={index} className="border p-4 rounded-lg">
                        <p className="font-semibold">{purchase.item}</p>
                        <p>Quantity: {purchase.quantity}</p>
                        <p>Price: ${purchase.price}</p>
                        <p>Purchase Date: {purchase.purchase_date}</p>
                    </li>
                ))}
            </ul>
        )}
    </div>
);

export const Sessions = ({ sessions }) => (
    <div>
        <h3 className="text-xl font-semibold mb-4">Sessions</h3>
        {sessions.length === 0 ? (
            <p>No sessions found.</p>
        ) : (
            <ul className="space-y-4">
                {sessions.map((session, index) => (
                    <li key={index} className="border p-4 rounded-lg">
                        <p className="font-semibold">Date: {session.date}</p>
                        <p>Duration: {session.duration} minutes</p>
                        <p>Type: {session.type}</p>
                        <p>Notes: {session.notes}</p>
                    </li>
                ))}
            </ul>
        )}
    </div>
);

export const Invoices = ({ invoices }) => (
    <div>
        <h3 className="text-xl font-semibold mb-4">Invoices</h3>
        {invoices.length === 0 ? (
            <p>No invoices found.</p>
        ) : (
            <ul className="space-y-4">
                {invoices.map((invoice, index) => (
                    <li key={index} className="border p-4 rounded-lg">
                        <p className="font-semibold">Invoice #{invoice.invoice_number}</p>
                        <p>Date: {invoice.date}</p>
                        <p>Amount: ${invoice.amount}</p>
                        <p>Status: {invoice.status}</p>
                    </li>
                ))}
            </ul>
        )}
    </div>
);

export const Payments = ({ payments }) => (
    <div>
        <h3 className="text-xl font-semibold mb-4">Payments</h3>
        {payments.length === 0 ? (
            <p>No payments found.</p>
        ) : (
            <ul className="space-y-4">
                {payments.map((payment, index) => (
                    <li key={index} className="border p-4 rounded-lg">
                        <p className="font-semibold">Date: {payment.date}</p>
                        <p>Amount: ${payment.amount}</p>
                        <p>Method: {payment.method}</p>
                    </li>
                ))}
            </ul>
        )}
    </div>
);

export const Discounts = ({ discounts }) => (
    <div>
        <h3 className="text-xl font-semibold mb-4">Discounts</h3>
        {discounts.length === 0 ? (
            <p>No discounts found.</p>
        ) : (
            <ul className="space-y-4">
                {discounts.map((discount, index) => (
                    <li key={index} className="border p-4 rounded-lg">
                        <p className="font-semibold">{discount.name}</p>
                        <p>Amount: {discount.percentage}%</p>
                        <p>Valid From: {discount.valid_from}</p>
                        <p>Valid To: {discount.valid_to}</p>
                    </li>
                ))}
            </ul>
        )}
    </div>
);

const ClientInfoItem = ({ icon: Icon, label, value }) => (
    <div>
        <p className="font-semibold flex items-center mb-1"><Icon size={16} className="mr-2" /> {label}:</p>
        <p>{value}</p>
    </div>
);