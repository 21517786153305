import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabase';
import { 
  Plus, Edit, Trash2, Eye, ChevronRight, Users, Clock, ArrowLeft
} from 'lucide-react';

const AssessmentFormManager = () => {
  const navigate = useNavigate();
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchForms();
  }, []);

  const fetchForms = async () => {
    try {
      setLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { data, error } = await supabase
        .from('initial_assessment_forms')
        .select(`
          *,
          responses:initial_assessment_responses(count),
          pending_responses:initial_assessment_responses(count)
        `)
        .eq('trainer_id', user.id)
        .eq('pending_responses.status', 'pending');

      if (error) throw error;
      setForms(data);
    } catch (error) {
      console.error('Error fetching forms:', error);
      // Handle error (e.g., show error message to user)
    } finally {
      setLoading(false);
    }
  };

  const deleteForm = async (formId) => {
    if (window.confirm('Are you sure you want to delete this form?')) {
      try {
        const { error } = await supabase
          .from('initial_assessment_forms')
          .delete()
          .eq('id', formId);

        if (error) throw error;
        fetchForms(); // Refresh the list
      } catch (error) {
        console.error('Error deleting form:', error);
        // Handle error (e.g., show error message to user)
      }
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-4">
      <button 
        onClick={() => navigate('/trainer-dashboard')} 
        className="mb-4 flex items-center text-orange-500 hover:text-orange-700"
      >
        <ArrowLeft size={20} className="mr-2" />
        Back to Dashboard
      </button>

      <h1 className="text-2xl font-bold mb-6">Assessment Form Manager</h1>
      <button 
        onClick={() => navigate('/create-assessment-form')} 
        className="mb-4 bg-orange-500 text-white px-4 py-2 rounded-md flex items-center"
      >
        <Plus size={20} className="mr-2" />
        Create New Form
      </button>

      {loading ? (
        <p>Loading forms...</p>
      ) : (
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          {forms.map(form => (
            <div key={form.id} className="bg-white p-4 rounded-lg shadow">
              <h2 className="text-xl font-semibold mb-2">{form.title}</h2>
              <div className="flex justify-between text-sm text-gray-600 mb-4">
                <span className="flex items-center">
                  <Users size={16} className="mr-1" />
                  {form.responses.length} responses
                </span>
                <span className="flex items-center">
                  <Clock size={16} className="mr-1" />
                  {form.pending_responses.length} pending
                </span>
              </div>
              <div className="flex justify-between">
                <button 
                  onClick={() => navigate(`/edit-assessment-form/${form.id}`)}
                  className="text-blue-500 hover:text-blue-700"
                >
                  <Edit size={20} />
                </button>
                <button 
                  onClick={() => deleteForm(form.id)}
                  className="text-red-500 hover:text-red-700"
                >
                  <Trash2 size={20} />
                </button>
                <button 
                  onClick={() => navigate(`/view-assessment-responses/${form.id}`)}
                  className="text-green-500 hover:text-green-700"
                >
                  <Eye size={20} />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AssessmentFormManager;