import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { format, parseISO, addMinutes, startOfMonth, endOfMonth, eachDayOfInterval, addMonths, getDay, subDays, addDays } from 'date-fns';
import { supabase } from '../utils/supabase';
import { Calendar, Clock, DollarSign, Info, ChevronLeft, ChevronRight } from 'lucide-react';

const BookingForm = ({ trainerId }) => {
  const navigate = useNavigate();
  const [date, setDate] = useState(null);
  const [sessionType, setSessionType] = useState('');
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [sessionTypes, setSessionTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchSessionTypes();
  }, [trainerId]);

  useEffect(() => {
    if (date && sessionType) {
      fetchAvailableTimeSlots();
    }
  }, [date, sessionType, trainerId]);

  const fetchSessionTypes = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('session_types')
        .select('*')
        .eq('trainer_id', trainerId);
  
      if (error) throw error;
      setSessionTypes(data || []);
    } catch (error) {
      console.error('Error fetching session types:', error);
      setError('Failed to load session types. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const fetchAvailableTimeSlots = async () => {
    try {
      setLoading(true);
      const selectedSessionType = sessionTypes.find(type => type.id === parseInt(sessionType));
      if (!selectedSessionType) return;

      const { data: availabilityData, error: availabilityError } = await supabase
        .from('availability')
        .select('*')
        .eq('trainer_id', trainerId)
        .eq('date', format(date, 'yyyy-MM-dd'))
        .eq('status', 'available');

      if (availabilityError) throw availabilityError;

      const { data: existingBookings, error: bookingsError } = await supabase
        .from('bookings')
        .select('start_time, end_time')
        .eq('trainer_id', trainerId)
        .eq('date', format(date, 'yyyy-MM-dd'));

      if (bookingsError) throw bookingsError;

      const availableSlots = availabilityData.filter(slot => {
        const slotStart = parseISO(`${format(date, 'yyyy-MM-dd')}T${slot.time}`);
        const slotEnd = addMinutes(slotStart, selectedSessionType.duration);

        return !existingBookings.some(booking => {
          const bookingStart = parseISO(`${format(date, 'yyyy-MM-dd')}T${booking.start_time}`);
          const bookingEnd = parseISO(`${format(date, 'yyyy-MM-dd')}T${booking.end_time}`);
          return (
            (slotStart >= bookingStart && slotStart < bookingEnd) ||
            (slotEnd > bookingStart && slotEnd <= bookingEnd) ||
            (slotStart <= bookingStart && slotEnd >= bookingEnd)
          );
        });
      });

      setAvailableTimeSlots(availableSlots);
    } catch (error) {
      console.error('Error fetching available time slots:', error);
      setError('Failed to load available time slots. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (newDate) => {
    setDate(newDate);
    setSessionType('');
    setSelectedTimeSlot('');
    setError('');
  };

  const handleSessionTypeSelect = (type) => {
    setSessionType(type);
    setSelectedTimeSlot('');
    setError('');
  };

  const handleTimeSlotSelect = (time) => {
    setSelectedTimeSlot(time);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!date || !sessionType || !selectedTimeSlot) {
      setError('Please select a date, session type, and time slot.');
      return;
    }

    setLoading(true);
    setError('');
  
    try {
      const { data: { user }, error: userError } = await supabase.auth.getUser();
  
      if (userError) throw userError;
  
      if (!user) {
        navigate('/login');
        return;
      }
  
      const selectedSessionType = sessionTypes.find(type => type.id.toString() === sessionType);
      const startTime = parseISO(`${format(date, 'yyyy-MM-dd')}T${selectedTimeSlot}`);
      const endTime = addMinutes(startTime, selectedSessionType.duration);
  
      const { data, error } = await supabase.rpc('book_session', {
        p_trainer_id: trainerId,
        p_client_id: user.id,
        p_date: format(date, 'yyyy-MM-dd'),
        p_start_time: selectedTimeSlot,
        p_end_time: format(endTime, 'HH:mm'),
        p_session_type: selectedSessionType.name
      });
  
      if (error) throw error;
  
      await sendConfirmationEmails(user, {
        date: format(date, 'yyyy-MM-dd'),
        start_time: selectedTimeSlot,
        session_type: selectedSessionType.name
      });
  
      alert('Booking successful!');
      navigate('/dashboard');
    } catch (error) {
      console.error('Error creating booking:', error);
      setError('Failed to create booking. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const sendConfirmationEmails = async (user, bookingData) => {
    try {
      const { data: trainerData, error: trainerError } = await supabase
        .from('user_profiles_with_email')
        .select('name, email')
        .eq('id', trainerId)
        .single();
  
      if (trainerError) throw trainerError;
  
      const { data: userData, error: userError } = await supabase
        .from('user_profiles_with_email')
        .select('email, name')
        .eq('id', user.id)
        .single();
  
      if (userError) throw userError;
  
      const { error: emailError } = await supabase.functions.invoke('send-email', {
        body: JSON.stringify({
          type: 'session_booking',
          userEmail: userData.email,
          trainerEmail: trainerData.email,
          sessionDetails: {
            trainerName: trainerData.name,
            userName: userData.name,
            date: bookingData.date,
            time: bookingData.start_time,
            type: bookingData.session_type
          }
        })
      });
  
      if (emailError) throw emailError;
  
      const { error: notificationError } = await supabase
        .from('notifications')
        .insert({
          user_id: trainerId,
          type: 'session_booked',
          message: JSON.stringify({
            client_name: userData.name,
            session_type: bookingData.session_type,
            date: bookingData.date,
            time: bookingData.start_time
          })
        });
  
      if (notificationError) throw notificationError;
  
    } catch (error) {
      console.error('Error in sendConfirmationEmails:', error);
      // We don't throw the error here to prevent booking from failing if email sending fails
    }
  };

  const renderCalendar = () => {
    const monthStart = startOfMonth(date || new Date());
    const monthEnd = endOfMonth(monthStart);
    const startDate = subDays(monthStart, getDay(monthStart));
    const endDate = addDays(monthEnd, 6 - getDay(monthEnd));
    const calendarDays = eachDayOfInterval({ start: startDate, end: endDate });

    return (
      <div className="mb-4">
        <div className="flex justify-between items-center mb-2">
          <button 
            onClick={() => setDate(prevDate => addMonths(prevDate || new Date(), -1))}
            className="p-2 bg-orange-100 text-orange-600 rounded-full hover:bg-orange-200"
          >
            <ChevronLeft size={20} />
          </button>
          <span className="font-medium">
            {format(date || new Date(), 'MMMM yyyy')}
          </span>
          <button 
            onClick={() => setDate(prevDate => addMonths(prevDate || new Date(), 1))}
            className="p-2 bg-orange-100 text-orange-600 rounded-full hover:bg-orange-200"
          >
            <ChevronRight size={20} />
          </button>
        </div>
        <div className="grid grid-cols-7 gap-1">
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
            <div key={day} className="text-center font-medium text-sm">{day}</div>
          ))}
          {calendarDays.map((day, index) => (
            <button
              key={day.toISOString()}
              onClick={() => handleDateChange(day)}
              className={`p-2 text-center text-sm ${
                date && format(date, 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd')
                  ? 'bg-orange-500 text-white'
                  : format(day, 'MM') !== format(monthStart, 'MM')
                    ? 'text-gray-400'
                    : 'hover:bg-orange-100'
              }`}
              disabled={format(day, 'MM') !== format(monthStart, 'MM')}
            >
              {format(day, 'd')}
            </button>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-6">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        {renderCalendar()}
        
        {date && (
          <div>
            <h2 className="text-lg font-medium mb-2">Select Session Type</h2>
            <div className="space-y-2">
              {sessionTypes.map((type) => (
                <button
                  key={type.id}
                  type="button"
                  onClick={() => handleSessionTypeSelect(type.id.toString())}
                  className={`w-full p-3 text-left rounded-lg ${
                    sessionType === type.id.toString()
                      ? 'bg-orange-500 text-white'
                      : 'bg-gray-100 hover:bg-orange-100'
                  }`}
                >
                  <div className="font-medium">{type.name}</div>
                  <div className="text-sm">{type.duration} minutes - ${type.price}</div>
                </button>
              ))}
            </div>
          </div>
        )}

        {date && sessionType && (
          availableTimeSlots.length > 0 ? (
            <div>
              <h2 className="text-lg font-medium mb-2">Select Time Slot</h2>
              <div className="grid grid-cols-3 gap-2">
                {availableTimeSlots.map((slot) => (
                  <button
                    key={slot.id}
                    type="button"
                    onClick={() => handleTimeSlotSelect(slot.time)}
                    className={`p-2 rounded ${
                      selectedTimeSlot === slot.time
                        ? 'bg-orange-500 text-white'
                        : 'bg-gray-100 hover:bg-orange-100'
                    }`}
                  >
                    {format(parseISO(`2000-01-01T${slot.time}`), 'h:mm a')}
                  </button>
                ))}
              </div>
            </div>
          ) : (
            <p className="text-red-500">No slots available for the selected date and session type.</p>
          )
        )}

        {date && sessionType && selectedTimeSlot && (
          <div className="bg-gray-50 p-4 rounded-md">
            <h3 className="text-lg font-medium text-gray-900 mb-2">Booking Summary</h3>
            <p className="text-sm text-gray-600">
              <Calendar className="inline-block mr-2" size={16} />
              Date: {format(date, 'MMMM d, yyyy')}
            </p>
            <p className="text-sm text-gray-600">
              <Clock className="inline-block mr-2" size={16} />
              Time: {format(parseISO(`2000-01-01T${selectedTimeSlot}`), 'h:mm a')}
            </p>
            <p className="text-sm text-gray-600">
              <Info className="inline-block mr-2" size={16} />
              Session: {sessionTypes.find(t => t.id.toString() === sessionType)?.name}
            </p>
            <p className="text-sm text-gray-600">
              <DollarSign className="inline-block mr-2" size={16} />
              Price: ${sessionTypes.find(t => t.id.toString() === sessionType)?.price}
            </p>
          </div>
        )}

        <button
          type="submit"
          disabled={loading || !date || !sessionType || !selectedTimeSlot}
          className="w-full mt-4 bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
          {loading ? 'Booking...' : 'Confirm Booking'}
        </button>
      </form>
    </div>
  );
};

export default BookingForm;