import React from 'react';
import { Clock, Edit, Trash } from 'lucide-react';
import { format, parseISO } from 'date-fns';
import { getPublicImageUrl } from '../utils/supabase';

const UpcomingBookings = ({ bookings }) => {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <h3 className="text-xl font-semibold p-6 border-b border-gray-200">Upcoming Bookings</h3>
      {bookings.length > 0 ? (
        <div className="divide-y divide-gray-200">
          {bookings.map((booking, index) => (
            <div key={index} className="p-6 hover:bg-gray-50 transition-colors duration-150 ease-in-out">
              <div className="flex items-center">
                <img 
                  src={getPublicImageUrl(booking.client_image_url)}
                  alt={booking.client_name} 
                  className="w-12 h-12 rounded-full mr-4 object-cover border-2 border-gray-200"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = 'https://via.placeholder.com/48';
                  }}
                />
                <div className="flex-grow">
                  <h4 className="text-lg font-semibold text-gray-800">{booking.client_name}</h4>
                  <div className="flex items-center text-sm text-gray-600 mt-1">
                    <Clock size={14} className="mr-2" />
                    {format(parseISO(`2000-01-01T${booking.start_time}`), 'h:mm a')} - {format(parseISO(`2000-01-01T${booking.end_time}`), 'h:mm a')}
                  </div>
                  <div className="text-sm text-gray-600 mt-1">
                    Session: <span className="font-medium">{booking.session_type}</span>
                  </div>
                </div>
                <div className="text-right">
                  <p className="text-sm font-medium text-gray-600">{format(parseISO(booking.date), 'MMMM d, yyyy')}</p>
                  <div className="mt-2 space-x-2">
                    <button className="text-blue-500 hover:text-blue-600 transition-colors duration-150 ease-in-out">
                      <Edit size={18} />
                    </button>
                    <button className="text-red-500 hover:text-red-600 transition-colors duration-150 ease-in-out">
                      <Trash size={18} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-600 p-6">No upcoming bookings.</p>
      )}
    </div>
  );
};

export default UpcomingBookings;