import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Loader2, ChevronDown, ChevronUp, Info, ArrowLeft, Save, Trash, Utensils, Clock, Calendar, X } from 'lucide-react';
import { supabase } from '../utils/supabase'; // Adjust the import path as needed

const AIMealPlanner = ({ currentUser }) => {
  const [userPreferences, setUserPreferences] = useState({
    goal: '',
    dietaryRestrictions: [],
    mealsPerDay: 3,
    planDuration: 1,
    age: '',
    gender: '',
    weight: '',
    height: '',
    activityLevel: '',
    calorieTarget: '',
    isPro: false, // Add this line to differentiate from the Pro version
  });
  const [mealPlan, setMealPlan] = useState(null);
  const [savedMealPlans, setSavedMealPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedMeal, setExpandedMeal] = useState(null);
  const [error, setError] = useState(null);
  const [isLoadedPlan, setIsLoadedPlan] = useState(false);
  const [currentDay, setCurrentDay] = useState(0);

  const dietaryOptions = [
    'Vegetarian', 'Vegan', 'Gluten-Free', 'Dairy-Free', 'Keto', 'Paleo'
  ];

  const planDurationOptions = [
    { value: 1, label: '1 Week' },
    { value: 2, label: '2 Weeks' },
    { value: 3, label: '3 Weeks' },
    { value: 4, label: '4 Weeks' },
  ];

  useEffect(() => {
    if (currentUser) {
      fetchSavedMealPlans();
    }
  }, [currentUser]);

  const fetchSavedMealPlans = async () => {
    const { data, error } = await supabase
      .from('meal_plans')
      .select('*')
      .eq('user_id', currentUser.id);

    if (error) {
      console.error('Error fetching saved meal plans:', error);
    } else {
      setSavedMealPlans(data);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserPreferences(prev => ({ ...prev, [name]: value }));
  };

  const handleDietaryToggle = (option) => {
    setUserPreferences(prev => ({
      ...prev,
      dietaryRestrictions: prev.dietaryRestrictions.includes(option)
        ? prev.dietaryRestrictions.filter(item => item !== option)
        : [...prev.dietaryRestrictions, option]
    }));
  };

  const calculateBMR = () => {
    const { age, gender, weight, height } = userPreferences;
    if (gender === 'male') {
      return 88.362 + (13.397 * weight) + (4.799 * height) - (5.677 * age);
    } else {
      return 447.593 + (9.247 * weight) + (3.098 * height) - (4.330 * age);
    }
  };

  const calculateTDEE = (bmr) => {
    const activityLevels = {
      sedentary: 1.2,
      lightlyActive: 1.375,
      moderatelyActive: 1.55,
      veryActive: 1.725,
      extraActive: 1.9,
    };
    return bmr * activityLevels[userPreferences.activityLevel];
  };

  const calculateCalorieTarget = () => {
    const bmr = calculateBMR();
    const tdee = calculateTDEE(bmr);
    switch (userPreferences.goal) {
      case 'weightLoss':
        return Math.round(tdee - 500);
      case 'muscleGain':
        return Math.round(tdee + 300);
      case 'maintenance':
      default:
        return Math.round(tdee);
    }
  };

  const validateInputs = () => {
    const requiredFields = ['age', 'gender', 'weight', 'height', 'activityLevel', 'goal', 'mealsPerDay', 'planDuration'];
    return requiredFields.every(field => userPreferences[field] !== '');
  };

  const generateMealPlan = async () => {
    if (!validateInputs()) {
      setError('Please fill in all required fields before generating a meal plan.');
      return;
    }

    setIsLoading(true);
    setError(null);
    setIsLoadedPlan(false);
    setMealPlan(null);

    const calculatedCalorieTarget = calculateCalorieTarget();
    const updatedPreferences = { ...userPreferences, calorieTarget: calculatedCalorieTarget };

    try {
      console.log('Sending userPreferences:', updatedPreferences);

      const { data, error } = await supabase.functions.invoke('generate-meal-plan', {
        body: { userPreferences: updatedPreferences },
      });

      if (error) {
        console.error('Supabase function error:', error);
        console.error('Error details:', error.message, error.stack);
        throw error;
      }

      console.log('Received data from function:', data);

      if (!data || !data.mealPlan) {
        console.error('Invalid response:', data);
        throw new Error('Invalid response from meal plan generator');
      }

      console.log('Received meal plan:', data.mealPlan);
      setMealPlan(data.mealPlan);
    } catch (error) {
      console.error('Error generating meal plan:', error);
      console.error('Error details:', error.message, error.stack);
      if (error.message.includes('temporarily unavailable') || error.status === 503) {
        setError('The meal plan service is currently unavailable due to high demand. Please try again in a few minutes.');
      } else {
        setError(`Failed to generate meal plan: ${error.message}. Please try again or contact support if the problem persists.`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const saveMealPlan = async () => {
    if (!currentUser) {
      alert('Please log in to save meal plans.');
      return;
    }
  
    const totalCalories = calculateTotalCalories(mealPlan[0].meals);
    const planWithTotalCalories = {
      ...mealPlan,
      totalCalories: totalCalories
    };
  
    const { data, error } = await supabase
      .from('meal_plans')
      .insert({
        user_id: currentUser.id,
        preferences: userPreferences,
        plan: planWithTotalCalories,
      });
  
    if (error) {
      console.error('Error saving meal plan:', error);
      alert('Failed to save meal plan. Please try again.');
    } else {
      alert('Meal plan saved successfully!');
      fetchSavedMealPlans();
    }
  };

  const deleteMealPlan = async (planId) => {
    const { error } = await supabase
      .from('meal_plans')
      .delete()
      .eq('id', planId);

    if (error) {
      console.error('Error deleting meal plan:', error);
      alert('Failed to delete meal plan. Please try again.');
    } else {
      alert('Meal plan deleted successfully!');
      fetchSavedMealPlans();
    }
  };

  const loadMealPlan = (plan) => {
    setMealPlan(plan.plan);
    setUserPreferences(plan.preferences);
    setIsLoadedPlan(true);
  };

  const toggleMealExpansion = (index) => {
    setExpandedMeal(expandedMeal === index ? null : index);
  };

  const closeMealPlan = () => {
    setMealPlan(null);
    setIsLoadedPlan(false);
    setCurrentDay(0);
  };

  const calculateTotalCalories = (meals) => {
    return meals.reduce((total, meal) => total + (meal.calories || 0), 0);
  };

  return (
    <div className="max-w-4xl mx-auto p-6 pb-24 sm:pb-6 space-y-8">
      <div className="flex justify-between items-center">
        <h2 className="text-3xl font-bold text-gray-800">AI Meal Planner</h2>
        <Link to="/dashboard" className="text-orange-500 hover:text-orange-600 flex items-center transition-colors duration-200">
          <ArrowLeft size={20} className="mr-2" />
          Back to Dashboard
        </Link>
      </div>

      {savedMealPlans.length > 0 && (
        <div className="bg-white shadow-lg rounded-xl p-6">
          <h3 className="text-xl font-semibold text-gray-700 mb-4">Saved Meal Plans</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {savedMealPlans.map((plan) => (
              <div key={plan.id} className="bg-gray-50 rounded-lg p-4 hover:bg-gray-100 transition-colors duration-200">
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm text-gray-600 flex items-center">
                    <Calendar size={16} className="mr-1" />
                    {new Date(plan.created_at).toLocaleDateString()}
                  </span>
                  <div className="space-x-2">
                    <button
                      onClick={() => loadMealPlan(plan)}
                      className="text-blue-500 hover:text-blue-600 transition-colors duration-200 font-medium text-sm"
                    >
                      Load
                    </button>
                    <button
                      onClick={() => deleteMealPlan(plan.id)}
                      className="text-red-500 hover:text-red-600 transition-colors duration-200"
                    >
                      <Trash size={16} />
                    </button>
                  </div>
                </div>
                <div className="text-sm text-gray-700">
                  <p><strong>Goal:</strong> {plan.preferences.goal}</p>
                  <p><strong>Calories:</strong> {plan.plan.totalCalories || 'N/A'}</p>
                  <p><strong>Duration:</strong> {plan.preferences.planDuration} week(s)</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      
      <div className="bg-white shadow-lg rounded-xl p-6 space-y-6">
        <h3 className="text-xl font-semibold text-gray-700 mb-4">Generate New Meal Plan</h3>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Age</label>
            <input
              type="number"
              name="age"
              value={userPreferences.age}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Gender</label>
            <select
              name="gender"
              value={userPreferences.gender}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
              required
            >
              <option value="">Select gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Weight (kg)</label>
            <input
              type="number"
              name="weight"
              value={userPreferences.weight}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Height (cm)</label>
            <input
              type="number"
              name="height"
              value={userPreferences.height}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Activity Level</label>
            <select
              name="activityLevel"
              value={userPreferences.activityLevel}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
              required
            >
              <option value="">Select activity level</option>
              <option value="sedentary">Sedentary</option>
              <option value="lightlyActive">Lightly Active</option>
              <option value="moderatelyActive">Moderately Active</option>
              <option value="veryActive">Very Active</option>
              <option value="extraActive">Extra Active</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Fitness Goal</label>
            <select
              name="goal"
              value={userPreferences.goal}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
              required
            >
              <option value="">Select a goal</option>
              <option value="weightLoss">Weight Loss</option>
              <option value="muscleGain">Muscle Gain</option>
              <option value="maintenance">Maintenance</option>
            </select>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Dietary Restrictions</label>
          <div className="flex flex-wrap gap-2">
            {dietaryOptions.map(option => (
              <button
                key={option}
                onClick={() => handleDietaryToggle(option)}
                className={`px-3 py-1 rounded-full text-sm font-medium transition-colors duration-200 ${
                  userPreferences.dietaryRestrictions.includes(option)
                    ? 'bg-orange-500 text-white'
                    : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Meals per Day</label>
            <input
              type="number"
              name="mealsPerDay"
              value={userPreferences.mealsPerDay}
              onChange={handleInputChange}
              min="1"
              max="6"
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Plan Duration</label>
            <select
              name="planDuration"
              value={userPreferences.planDuration}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
              required
            >
              {planDurationOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <button
          onClick={generateMealPlan}
          disabled={isLoading || !validateInputs()}
          className="w-full bg-orange-500 text-white py-2 px-4 rounded-md hover:bg-orange-600 transition-colors duration-200 disabled:bg-gray-400 disabled:cursor-not-allowed font-medium text-lg shadow-md flex items-center justify-center"
        >
          {isLoading ? (
            <Loader2 className="animate-spin mr-2" />
          ) : (
            <Utensils size={20} className="mr-2" />
          )}
          {isLoading ? 'Generating...' : 'Generate Meal Plan'}
        </button>
      </div>

      {error && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-6 rounded-lg shadow-md" role="alert">
          <p className="font-bold text-lg mb-2">Error</p>
          <p>{error}</p>
          <p className="mt-4 font-medium">If this error persists, please try the following:</p>
          <ul className="list-disc ml-6 mt-2 space-y-1">
            <li>Refresh the page and try again</li>
            <li>Check your internet connection</li>
            <li>If the problem continues, please contact our support team</li>
          </ul>
        </div>
      )}

      {mealPlan && (
        <div className="bg-white shadow-lg rounded-xl p-6 space-y-6 relative">
          <button 
            onClick={closeMealPlan}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            aria-label="Close meal plan"
          >
            <X size={24} />
          </button>
          <h3 className="text-xl font-semibold text-gray-700 mb-4">Your Personalized Meal Plan</h3>
          
          <div className="flex justify-between items-center mb-4">
            <button 
              onClick={() => setCurrentDay(prev => (prev > 0 ? prev - 1 : mealPlan.length - 1))}
              className="bg-orange-500 text-white py-2 px-4 rounded-md hover:bg-orange-600 transition-colors"
            >
              Previous Day
            </button>
            <h4 className="text-lg font-medium">{mealPlan[currentDay].day}</h4>
            <button 
              onClick={() => setCurrentDay(prev => (prev < mealPlan.length - 1 ? prev + 1 : 0))}
              className="bg-orange-500 text-white py-2 px-4 rounded-md hover:bg-orange-600 transition-colors"
            >
              Next Day
            </button>
          </div>

          <div className="text-center mb-4">
            <p className="text-lg font-semibold text-gray-700">
              Total Daily Calories: {calculateTotalCalories(mealPlan[currentDay].meals)}
            </p>
          </div>

          {mealPlan[currentDay].meals.map((meal, index) => (
            <div key={index} className="mb-4 border-b pb-4 last:border-b-0 last:pb-0">
              <div 
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleMealExpansion(index)}
              >
                <h4 className="text-lg font-medium text-gray-800">
                  {meal.mealName} - {meal.recipeName}
                </h4>
                {expandedMeal === index ? <ChevronUp className="text-gray-500" /> : <ChevronDown className="text-gray-500" />}
              </div>
              <p className="text-sm text-gray-600 mt-1 flex items-center">
                <Clock size={16} className="mr-1" />
                {meal.calories ? `${meal.calories} calories` : 'Calorie information not available'}
              </p>
              
              {expandedMeal === index && (
                <div className="mt-4 space-y-4 animate-fadeIn">
                  <div>
                    <h5 className="font-medium text-md mb-2 text-gray-700">Ingredients:</h5>
                    {meal.ingredients && meal.ingredients.length > 0 ? (
                      <ul className="list-disc pl-5 space-y-1">
                        {meal.ingredients.map((ingredient, i) => (
                          <li key={i} className="text-sm text-gray-600">{ingredient}</li>
                        ))}
                      </ul>
                    ) : (
                      <p className="text-sm text-gray-500">Ingredients not available</p>
                    )}
                  </div>
                  
                  <div>
                    <h5 className="font-medium text-md mb-2 text-gray-700">Instructions:</h5>
                    {meal.instructions ? (
                      <p className="text-sm text-gray-600">{meal.instructions}</p>
                    ) : (
                      <p className="text-sm text-gray-500">Instructions not available</p>
                    )}
                  </div>
                  
                  <div>
                    <h5 className="font-medium text-md mb-2 text-gray-700">Nutritional Information:</h5>
                    <div className="flex flex-wrap gap-2">
                      {meal.nutrition ? (
                        Object.entries(meal.nutrition).map(([key, value]) => (
                          <div key={key} className="text-xs bg-white px-2 py-1 rounded-full border border-gray-200">
                            <span className="font-medium text-gray-700">{key}:</span> {value}g
                          </div>
                        ))
                      ) : (
                        <p className="text-sm text-gray-500">Nutritional information not available</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
          
          {!isLoadedPlan && (
            <button
              onClick={saveMealPlan}
              className="mt-6 bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 transition-colors duration-200 flex items-center justify-center font-medium text-lg shadow-md"
            >
              <Save size={20} className="mr-2" />
              Save This Meal Plan
            </button>
          )}
        </div>
      )}

      <div className="bg-blue-50 border-l-4 border-blue-500 p-4 rounded-lg shadow-md mb-20 sm:mb-0">
        <div className="flex items-start">
          <Info className="flex-shrink-0 text-blue-500 mr-3" />
          <p className="text-sm text-blue-700">
            This meal plan is generated by AI based on your preferences and general nutritional guidelines. Always consult with a healthcare professional or registered dietitian before making significant changes to your diet.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AIMealPlanner;