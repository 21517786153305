import React, { useState, useRef } from 'react';
import { Star, MapPin, Heart, ArrowLeft, Share2, Clock, Users, Phone } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const GymProfile = () => {
  const navigate = useNavigate();
  const [isFavorite, setIsFavorite] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(0);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const scrollRef = useRef(null);

  // Use hardcoded data
  const gymData = {
    name: "Push! Fitness",
    classTypes: "Boxing / Kickboxing | Circuit Training | Cycling | Dance | Massage | Other | Personal Training | Pilates | Weight Training | Yoga",
    description: "Push! Fitness is a premium Health and Fitness Centre overlooking the Yarra River in Docklands Victoria. We have 3 fitness studios, Group Fitness classes, a fully fitted Life Fitness and Hammer Strength gym and separate extensive functional training zone. We also have on-site Physiotherapy, Remedial Massage and Personal Training services.",
    coverImage: "https://via.placeholder.com/800x300?text=Push!+Fitness",
    logo: "https://via.placeholder.com/100?text=PF",
    rating: 4.9,
    reviews: 51,
    address: "2P/833 Collins Street Docklands, VIC 3008",
    distance: "1,345.06km away",
    contact: "86218300",
    introOffers: [
      {
        name: "3 Reformer Class Trial Pack (1 time only)",
        description: "PILATES REFORMER PACKAGES",
        price: 60
      },
      {
        name: "5 Class Pack",
        description: "ANY CLASS",
        price: 100
      },
      {
        name: "Unlimited Month",
        description: "30 DAYS UNLIMITED",
        price: 199
      }
    ],
    upcomingClasses: [
      {
        name: "Pilates Reformer",
        date: "Oct 14th",
        time: "5:30 pm",
        duration: "45 min",
        instructor: "Cindy Leung",
        price: 35,
        reviews: 3
      },
      {
        name: "BodyPump",
        date: "Oct 14th",
        time: "5:40 pm",
        duration: "60 min",
        instructor: "Stephen Borg",
        price: 30,
        reviews: 20
      },
      {
        name: "Yoga",
        date: "Oct 14th",
        time: "6:45 pm",
        duration: "45 min",
        instructor: "Ugne Adomeniate",
        price: 30,
        reviews: null
      }
    ],
    reviews: {
      average: 4.9,
      total: 48,
      breakdown: [
        { stars: 5, percentage: 92 },
        { stars: 4, percentage: 8 },
        { stars: 3, percentage: 0 },
        { stars: 2, percentage: 0 },
        { stars: 1, percentage: 0 },
      ],
      items: [
        {
          id: 1,
          className: "Pilates Reformer",
          instructor: "Ugne Adomeniate",
          date: "Feb 18, 2024",
          rating: 5,
          reviewer: "John S",
          comment: "First visit and I loved it!",
          difficulty: 5,
          intensity: 5,
          recovery: 0,
        },
        {
          id: 2,
          className: "Pilates Reformer",
          instructor: "Mark Hamilton",
          date: "Jan 22, 2024",
          rating: 5,
          reviewer: "Tania G",
          comment: "",
          difficulty: 4,
          intensity: 0,
          recovery: 0,
        },
      ],
    },
  };

  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };

  const renderRating = (rating, reviews) => (
    <div className="flex items-center">
      {[...Array(5)].map((_, i) => (
        <Star
          key={i}
          size={16}
          className={`${i < Math.floor(rating) ? 'text-yellow-400' : 'text-gray-300'} fill-current`}
        />
      ))}
      <span className="ml-2 text-sm font-medium text-gray-700">
        {rating.toFixed(1)} {reviews && `(${reviews} reviews)`}
      </span>
    </div>
  );

  const handleScroll = () => {
    if (scrollRef.current) {
      const scrollPosition = scrollRef.current.scrollLeft;
      const cardWidth = scrollRef.current.offsetWidth;
      const newSelectedOffer = Math.round(scrollPosition / cardWidth);
      setSelectedOffer(newSelectedOffer);
    }
  };

  const renderReviewBars = (value, max, label) => (
    <div className="mb-2">
      <div className="flex justify-between items-center mb-1">
        <span className="text-sm text-gray-600">{label}</span>
        <span className="text-sm font-medium">{value > 0 ? label : 'Not Rated'}</span>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2.5">
        <div 
          className="bg-orange-300 h-2.5 rounded-full" 
          style={{ width: `${(value / max) * 100}%` }}
        ></div>
      </div>
    </div>
  );

  return (
    <div className="max-w-4xl mx-auto p-4 bg-gray-100 pb-24 sm:pb-6"> {/* Added more bottom padding for mobile */}
      <div className="flex items-center justify-between mb-4">
        <button className="text-gray-600" onClick={() => navigate('/')}>
          <ArrowLeft size={24} />
        </button>
        <h1 className="text-xl font-bold">{gymData.name.toUpperCase()}</h1>
        <button className="text-gray-600">
          <Share2 size={24} />
        </button>
      </div>

      <img
        src={gymData.coverImage}
        alt={gymData.name}
        className="w-full h-64 object-cover rounded-lg mb-4"
      />

      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex items-center mb-4">
          <img src={gymData.logo} alt={`${gymData.name} logo`} className="w-16 h-16 rounded-full mr-4" />
          <div>
            <h2 className="text-2xl font-bold">{gymData.name}</h2>
            <p className="text-sm text-gray-600">{gymData.classTypes}</p>
          </div>
          <button onClick={toggleFavorite} className="ml-auto">
            <Heart size={24} className={isFavorite ? "text-red-500 fill-current" : "text-gray-400"} />
          </button>
        </div>

        <div className="mb-4">
          {renderRating(gymData.rating, gymData.reviews)}
        </div>

        <div className="flex items-center text-gray-600 mb-4">
          <MapPin size={20} className="mr-2" />
          <span>{gymData.address}</span>
        </div>
        <p className="text-sm text-gray-600 mb-6">{gymData.distance}</p>

        <div className="mb-6">
          <h3 className="font-semibold mb-2">Intro Offers</h3>
          <div 
            className="flex overflow-x-auto snap-x snap-mandatory scrollbar-hide"
            ref={scrollRef}
            onScroll={handleScroll}
          >
            {gymData.introOffers.map((offer, index) => (
              <div key={index} className="flex-shrink-0 w-full snap-center">
                <div className="bg-gray-100 p-4 rounded-lg mr-4 relative">
                  <div className="pr-16">
                    <h4 className="font-medium">{offer.name}</h4>
                    <p className="text-sm text-gray-600">{offer.description}</p>
                  </div>
                  <div className="absolute top-4 right-4 bg-orange-500 text-white rounded-full w-14 h-14 flex items-center justify-center">
                    <span className="text-lg font-bold">${offer.price}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center mt-2">
            {gymData.introOffers.map((_, index) => (
              <div
                key={index}
                className={`w-2 h-2 rounded-full mx-1 ${
                  index === selectedOffer ? 'bg-orange-500' : 'bg-gray-300'
                }`}
              />
            ))}
          </div>
        </div>

        <div className="mb-6">
          <h3 className="font-semibold mb-2">About this Studio</h3>
          <p className="text-sm text-gray-600">
            {showFullDescription
              ? gymData.description
              : `${gymData.description.substring(0, 150)}...`}
          </p>
          <button
            className="text-orange-500 text-sm font-medium mt-2"
            onClick={() => setShowFullDescription(!showFullDescription)}
          >
            {showFullDescription ? 'READ LESS' : 'READ MORE'}
          </button>
        </div>

        <div className="mb-6">
          <h3 className="font-semibold mb-2">Contact</h3>
          <div className="flex items-center text-gray-600">
            <Phone size={20} className="mr-2" />
            <span>{gymData.contact}</span>
          </div>
        </div>

        <div className="mb-6">
          <div className="flex justify-between items-center mb-2">
            <h3 className="font-semibold">Upcoming Classes</h3>
            <button className="text-orange-500 text-sm font-medium">SEE ALL</button>
          </div>
          {gymData.upcomingClasses.map((cls, index) => (
            <div key={index} className="flex justify-between items-center mb-4 pb-4 border-b border-gray-200 last:border-b-0">
              <div>
                <div className="flex items-center mb-1">
                  <p className="font-medium mr-2">{cls.name}</p>
                  {cls.reviews && renderRating(5, cls.reviews)}
                </div>
                <p className="text-sm text-gray-600">{cls.date} {cls.time} AEDT</p>
                <p className="text-sm text-gray-600">({cls.duration})</p>
                <p className="text-sm text-gray-600">w/ {cls.instructor}</p>
              </div>
              <div className="text-right">
                <p className="font-bold mb-1">${cls.price}.00</p>
                <p className="text-sm text-gray-600 mb-1">DROP-IN</p>
                <button className="text-orange-500 text-sm font-medium">Book</button>
              </div>
            </div>
          ))}
        </div>

        {gymData.reviews && (
          <div className="mb-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">Reviews</h3>
              <button className="text-orange-500 font-medium">SEE ALL</button>
            </div>

            <div className="flex items-center mb-4">
              <span className="text-4xl font-bold mr-4">{gymData.reviews.average}</span>
              <div>
                {renderRating(gymData.reviews.average, gymData.reviews.total)}
                <p className="text-sm text-gray-600">{gymData.reviews.total} reviews</p>
              </div>
            </div>

            {gymData.reviews.breakdown && (
              <div className="mb-6">
                {gymData.reviews.breakdown.map((item) => (
                  <div key={item.stars} className="flex items-center mb-1">
                    <span className="w-16 text-sm">{item.stars} stars</span>
                    <div className="flex-grow mx-2 bg-gray-200 rounded-full h-2">
                      <div 
                        className="bg-orange-500 h-2 rounded-full" 
                        style={{ width: `${item.percentage}%` }}
                      ></div>
                    </div>
                    <span className="w-12 text-right text-sm">{item.percentage}%</span>
                  </div>
                ))}
              </div>
            )}

            {gymData.reviews.items && gymData.reviews.items.map((review) => (
              <div key={review.id} className="border-t border-gray-200 py-4">
                <div className="flex justify-between items-start mb-2">
                  <div>
                    <h4 className="font-medium">{review.className} with {review.instructor}</h4>
                    <p className="text-sm text-gray-600">{review.date}</p>
                  </div>
                  <div className="flex items-center">
                    {renderRating(review.rating)}
                    <span className="ml-2 text-sm text-gray-600">by {review.reviewer}</span>
                  </div>
                </div>
                {review.comment && <p className="mb-2">{review.comment}</p>}
                {renderReviewBars(review.difficulty, 5, 'Difficulty')}
                {renderReviewBars(review.intensity, 5, 'Intensity')}
                {renderReviewBars(review.recovery, 5, 'Recovery')}
              </div>
            ))}
          </div>
        )}

        {/* Updated bottom buttons */}
        <div className="mt-6 pb-safe">
          <div className="flex flex-row space-x-2">
            <button 
              className="w-1/2 bg-orange-500 text-white py-2 px-3 rounded-md hover:bg-orange-600 transition-colors duration-300 font-medium shadow-sm text-xs sm:text-sm"
              onClick={() => navigate('/schedule')}
            >
              View Schedule
            </button>
            <button className="w-1/2 bg-white text-orange-500 border border-orange-500 py-2 px-3 rounded-md hover:bg-orange-50 transition-colors duration-300 font-medium shadow-sm text-xs sm:text-sm">
              View Pricing
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GymProfile;
