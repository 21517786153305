import React, { useState, useEffect } from 'react';
import { Search, Calendar, MapPin, ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import { supabase } from '../utils/supabase'; // Make sure this path is correct

const OffersComponent = () => {
  const [activeTab, setActiveTab] = useState('Discover');
  const [selectedCategory, setSelectedCategory] = useState('All categories');
  const [offers, setOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const categories = [
    { name: 'All categories', icon: '□□' },
    { name: 'Gyms', icon: '🏋️' },
    { name: 'Clinics', icon: '🩺' },
    { name: 'Nutrition', icon: '🥗' },
    { name: 'Equipment', icon: '⚾' },
    { name: 'Classes', icon: '🧘' }
  ];

  useEffect(() => {
    fetchOffers();
  }, []);

  const fetchOffers = async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from('offers')
        .select('*')
        .eq('status', 'active'); // Assuming you want to fetch only active offers

      if (error) throw error;

      setOffers(data);
    } catch (error) {
      setError('Failed to fetch offers. Please try again later.');
      console.error('Error fetching offers:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderOfferCard = (offer) => (
    <div className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 hover:shadow-lg">
      <div className="relative h-48">
        <img
          src={offer.image_url}
          alt={offer.title}
          className="w-full h-full object-cover"
        />
        <div className="absolute top-3 left-3 bg-orange-500 text-white px-2 py-1 rounded text-sm font-semibold">
          {offer.discount}% OFF
        </div>
      </div>
      <div className="p-4">
        <h3 className="font-bold text-lg text-gray-800 mb-1">{offer.title}</h3>
        <p className="text-orange-500 text-sm font-semibold mb-2">{offer.brand}</p>
        <p className="text-gray-600 text-sm mb-3">{offer.description}</p>
        <div className="flex items-center text-xs text-gray-500 mb-1">
          <MapPin size={14} className="mr-1 text-orange-400" /> 
          {offer.location}
        </div>
        <div className="flex items-center text-xs text-gray-500">
          <Calendar size={14} className="mr-1 text-orange-400" /> 
          Valid until {new Date(offer.valid_until).toLocaleDateString()}
        </div>
      </div>
      <a 
        href={offer.redirect_url}
        target="_blank"
        rel="noopener noreferrer"
        className="block w-full bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 text-sm text-center transition-colors duration-300"
      >
        View Offer
      </a>
    </div>
  );

  return (
    <div className="font-sans max-w-7xl mx-auto bg-white min-h-screen pb-20 sm:pb-0">
      <header className="bg-orange-500 p-4 md:p-6">
        <div className="flex justify-between items-center mb-4 md:mb-6">
          <h1 className="text-2xl md:text-3xl font-bold text-white">Offers</h1>
          <Link 
            to="/dashboard" 
            className="inline-flex items-center text-white hover:text-orange-200 transition-colors duration-300"
          >
            <ArrowLeft size={20} className="mr-2" />
            <span className="font-medium">Back to Dashboard</span>
          </Link>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4 md:mb-6">
          <div className="relative w-full md:w-64 mb-4 md:mb-0">
            <input
              type="text"
              placeholder="Search offers..."
              className="w-full pl-10 pr-4 py-2 rounded-full bg-orange-600 text-white placeholder-orange-200 focus:outline-none focus:ring-2 focus:ring-white text-sm"
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-orange-200" size={18} />
          </div>
        </div>
        <nav className="flex space-x-6 text-base">
          <button 
            className={`pb-1 ${activeTab === 'Discover' ? 'text-white border-b-2 border-white' : 'text-orange-200'}`}
            onClick={() => setActiveTab('Discover')}
          >
            Discover
          </button>
          <button 
            className={`pb-1 ${activeTab === 'Activated' ? 'text-white border-b-2 border-white' : 'text-orange-200'}`}
            onClick={() => setActiveTab('Activated')}
          >
            Activated
          </button>
        </nav>
      </header>

      <main className="p-4 md:p-6">
        {activeTab === 'Discover' && (
          <>
            <div className="mb-6">
              <h2 className="text-xl font-bold text-gray-800 mb-2">Latest Fitness Offers</h2>
              <p className="text-gray-600 text-sm mb-4">Exclusive deals from top fitness brands and services</p>
              <div className="flex flex-wrap gap-2">
                {categories.map((category) => (
                  <button
                    key={category.name}
                    className={`flex items-center space-x-1 px-3 py-1 rounded-full text-sm ${
                      selectedCategory === category.name 
                        ? 'bg-orange-500 text-white' 
                        : 'bg-white text-gray-700 border border-gray-300 hover:bg-gray-50'
                    }`}
                    onClick={() => setSelectedCategory(category.name)}
                  >
                    <span>{category.icon}</span>
                    <span>{category.name}</span>
                  </button>
                ))}
              </div>
            </div>

            {isLoading ? (
              <div className="text-center py-8">
                <p className="text-gray-600">Loading offers...</p>
              </div>
            ) : error ? (
              <div className="text-center py-8">
                <p className="text-red-500">{error}</p>
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {offers.map((offer) => (
                  <div key={offer.id}>
                    {renderOfferCard(offer)}
                  </div>
                ))}
              </div>
            )}

            {!isLoading && !error && (
              <button className="w-full md:w-auto md:px-6 bg-orange-500 text-white py-2 rounded-lg mt-6 hover:bg-orange-600 transition-colors duration-300 font-semibold text-sm">
                View all ({offers.length})
              </button>
            )}
          </>
        )}

        {activeTab === 'Activated' && (
          <div className="text-center py-8">
            <p className="text-gray-600">No activated offers to display.</p>
          </div>
        )}
      </main>
    </div>
  );
};

export default OffersComponent;