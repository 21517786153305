import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabase';
import SliderInput from './SliderInput';
import { 
  PlusCircle, MinusCircle, Weight, Ruler, Calendar, 
  UtensilsCrossed, AlertTriangle, Pill, Dumbbell, 
  Target, Stethoscope, HelpCircle, Image, Edit3, 
  Save, Send, ChevronLeft
} from 'lucide-react';

const InitialAssessmentForm = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState('Initial Client Assessment');
  const [questions, setQuestions] = useState([
    { id: 1, type: 'slider', label: 'Weight (kg)', required: true, icon: Weight, min: 40, max: 200, step: 0.1, value: 70 },
    { id: 2, type: 'slider', label: 'Waist (cm)', required: true, icon: Ruler, min: 50, max: 150, step: 0.5, value: 80 },
    { id: 3, type: 'slider', label: 'Height (cm)', required: true, icon: Ruler, min: 140, max: 220, step: 0.5, value: 170 },
    { id: 4, type: 'date', label: 'Date of Birth', required: true, icon: Calendar },
    { id: 5, type: 'textarea', label: 'Detail your current diet', required: true, icon: UtensilsCrossed },
    { id: 6, type: 'textarea', label: 'Allergies/dislikes', required: false, icon: AlertTriangle },
    { id: 7, type: 'textarea', label: 'Supplements you take', required: false, icon: Pill },
    { id: 8, type: 'slider', label: 'Years of training', required: true, icon: Dumbbell, min: 0, max: 50, step: 1, value: 5 },
    { id: 9, type: 'textarea', label: 'Current workouts', required: true, icon: Dumbbell },
    { id: 10, type: 'textarea', label: 'Goal physique', required: true, icon: Target },
    { id: 11, type: 'textarea', label: 'Any medical issues', required: false, icon: Stethoscope },
    { id: 12, type: 'textarea', label: 'Anything else I should know', required: false, icon: HelpCircle },
    { id: 13, type: 'file', label: 'Current photos', required: true, icon: Image },
  ]);

  const [dob, setDob] = useState('');
  const [age, setAge] = useState(null);

  useEffect(() => {
    if (dob) {
      const today = new Date();
      const birthDate = new Date(dob);
      let calculatedAge = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        calculatedAge--;
      }
      setAge(calculatedAge);
    }
  }, [dob]);

  const addQuestion = () => {
    const newId = questions.length + 1;
    setQuestions([...questions, { id: newId, type: 'text', label: 'New Question', required: false, icon: HelpCircle }]);
  };

  const removeQuestion = (id) => {
    setQuestions(questions.filter(q => q.id !== id));
  };

  const updateQuestion = (id, field, value) => {
    setQuestions(questions.map(q => q.id === id ? { ...q, [field]: value } : q));
  };

  const saveForm = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        throw new Error('No authenticated user found');
      }

      const { data, error } = await supabase
        .from('initial_assessment_forms')
        .insert({
          trainer_id: user.id,
          title: title,
          questions: questions
        });

      if (error) throw error;
      console.log('Form saved successfully', data);
      alert('Form saved successfully!');
      navigate('/trainer-dashboard');
    } catch (error) {
      console.error('Error saving form:', error);
      alert('Error saving form. Please try again.');
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4 bg-white shadow-md rounded-lg">
      <button 
        onClick={() => navigate('/trainer-dashboard')} 
        className="mb-4 flex items-center text-orange-500 hover:text-orange-700"
      >
        <ChevronLeft size={20} className="mr-1" />
        Back to Dashboard
      </button>

      <div className="flex items-center mb-4">
        <Edit3 className="mr-2 text-orange-500" />
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="text-2xl font-bold w-full p-2 border-b-2 border-gray-300 focus:border-orange-500 outline-none"
        />
      </div>

      <form onSubmit={(e) => { e.preventDefault(); saveForm(); }}>
        {questions.map((question) => (
          <div key={question.id} className="mb-4 p-4 bg-orange-50 rounded-md">
            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center w-full mr-2">
                {React.createElement(question.icon, { size: 20, className: "mr-2 text-orange-500" })}
                <input
                  type="text"
                  value={question.label}
                  onChange={(e) => updateQuestion(question.id, 'label', e.target.value)}
                  className="text-lg font-semibold w-full p-1 border-b border-gray-300 focus:border-orange-500 outline-none bg-transparent"
                />
              </div>
              <button
                type="button"
                onClick={() => removeQuestion(question.id)}
                className="text-red-500 hover:text-red-700"
              >
                <MinusCircle size={20} />
              </button>
            </div>
            {question.type === 'textarea' && (
              <textarea
                className="w-full p-2 border border-gray-300 rounded-md focus:border-orange-500 focus:ring focus:ring-orange-200"
                rows="3"
                placeholder={`Enter ${question.label.toLowerCase()}`}
              ></textarea>
            )}
            {question.type === 'slider' && (
              <SliderInput
                question={question}
                value={question.value}
                onChange={(newValue) => updateQuestion(question.id, 'value', newValue)}
              />
            )}
            {question.type === 'date' && (
              <div>
                <input
                  type="date"
                  className="w-full p-2 border border-gray-300 rounded-md focus:border-orange-500 focus:ring focus:ring-orange-200"
                  onChange={(e) => setDob(e.target.value)}
                />
                {age !== null && (
                  <p className="mt-2 text-sm text-gray-600">Age: {age} years</p>
                )}
              </div>
            )}
            {question.type === 'file' && (
              <input
                type="file"
                className="w-full p-2 border border-gray-300 rounded-md focus:border-orange-500 focus:ring focus:ring-orange-200"
                accept="image/*"
              />
            )}
            <div className="mt-2 flex items-center">
              <input
                type="checkbox"
                checked={question.required}
                onChange={(e) => updateQuestion(question.id, 'required', e.target.checked)}
                className="mr-2 text-orange-500 focus:ring-orange-500"
              />
              <label className="text-sm text-gray-600">Required</label>
            </div>
          </div>
        ))}
        <button
          type="button"
          onClick={addQuestion}
          className="mt-4 flex items-center text-orange-500 hover:text-orange-700"
        >
          <PlusCircle size={20} className="mr-2" />
          Add Question
        </button>
        <div className="mt-6 flex justify-between">
          <button type="button" onClick={() => navigate('/trainer-dashboard')} className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 flex items-center">
            <ChevronLeft size={20} className="mr-2" />
            Cancel
          </button>
          <button type="submit" className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 flex items-center">
            <Save size={20} className="mr-2" />
            Save Form
          </button>
        </div>
      </form>
    </div>
  );
};

export default InitialAssessmentForm;