import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { User, Bell, Lock, ArrowLeft, Save, Edit, X, Eye, EyeOff, Shield, Users, Trash2 } from 'lucide-react';
import { supabase } from '../utils/supabase';

const countryCodes = [
  { code: '+1', country: 'US/Canada' },
  { code: '+44', country: 'UK' },
  { code: '+61', country: 'Australia' },
  { code: '+33', country: 'France' },
  { code: '+49', country: 'Germany' },
  { code: '+81', country: 'Japan' },
  { code: '+86', country: 'China' },
  { code: '+91', country: 'India' },
  // Add more country codes as needed
];

const Settings = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [editMode, setEditMode] = useState({
    profile: false,
    password: false,
    notifications: false,
    privacy: false
  });
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    countryCode: '+1',
    phoneNumber: '',
  });
  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false
  });
  const [notifications, setNotifications] = useState({
    email: false,
    push: false,
    sms: false
  });
  const [privacySettings, setPrivacySettings] = useState({
    isProfilePrivate: false,
    hideActivityStatus: false,
    connectionPreference: 'everyone'
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: '', content: '' });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const [deletePassword, setDeletePassword] = useState('');

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    const checkEmailChange = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user && user.new_email) {
        showMessageAlert('info', `You have a pending email change to ${user.new_email}. Please check your new email for confirmation.`);
      }
    };

    checkEmailChange();
  }, [user]);

  const fetchUser = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      setUser(user);
      
      // Fetch user profile to get the name
      const { data: profile, error: profileError } = await supabase
        .from('user_profiles')
        .select('name, is_profile_private, hide_activity_status, connection_preference')
        .eq('id', user.id)
        .single();
      
      if (profileError) {
        console.error('Error fetching user profile:', profileError);
        showMessageAlert('error', 'Failed to fetch user profile.');
      } else {
        let countryCode = '+1';
        let phoneNumber = '';
        
        if (user.phone) {
          if (user.phone.startsWith('+')) {
            const phoneMatch = user.phone.match(/^(\+\d+)(.*)$/);
            if (phoneMatch) {
              countryCode = phoneMatch[1];
              phoneNumber = phoneMatch[2].trim();
            }
          } else {
            countryCode = '+' + user.phone.substring(0, 2);
            phoneNumber = user.phone.substring(2);
          }
        }
        
        setFormData({
          name: profile.name || '',
          email: user.email || '',
          countryCode: countryCode,
          phoneNumber: phoneNumber,
        });
        setPrivacySettings({
          isProfilePrivate: profile.is_profile_private || false,
          hideActivityStatus: profile.hide_activity_status || false,
          connectionPreference: profile.connection_preference || 'everyone'
        });
      }
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };

  const toggleEditMode = (section) => {
    setEditMode({ ...editMode, [section]: !editMode[section] });
    if (section === 'password' && editMode[section]) {
      setPasswords({ currentPassword: '', newPassword: '', confirmNewPassword: '' });
    }
  };

  const showMessageAlert = (type, content) => {
    setMessage({ type, content });
    setTimeout(() => setMessage({ type: '', content: '' }), 5000);
  };

  const formatPhoneNumber = (countryCode, phoneNumber) => {
    const digits = phoneNumber.replace(/\D/g, '');
    return `${countryCode}${digits}`;
  };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formattedPhone = formatPhoneNumber(formData.countryCode, formData.phoneNumber);

      if (formData.email !== user.email) {
        const { data: emailData, error: emailError } = await supabase.auth.updateUser({
          email: formData.email,
        });
        if (emailError) throw emailError;
        showMessageAlert('success', 'A confirmation email has been sent to your new email address. Please verify to complete the change.');
      }

      const { data, error } = await supabase.auth.updateUser({
        phone: formattedPhone.startsWith('+') ? formattedPhone : '+' + formattedPhone,
        data: { name: formData.name }
      });

      if (error) throw error;
      showMessageAlert('success', 'Your profile has been updated.');
      setUser(data.user);
      setFormData({
        ...formData,
        countryCode: formData.countryCode,
        phoneNumber: formData.phoneNumber,
      });
      toggleEditMode('profile');
    } catch (error) {
      console.error('Profile update error:', error);
      showMessageAlert('error', 'Failed to update profile. ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (passwords.newPassword !== passwords.confirmNewPassword) {
      showMessageAlert('error', 'New passwords do not match.');
      return;
    }
    if (passwords.newPassword.length < 6) {
      showMessageAlert('error', 'New password must be at least 6 characters long.');
      return;
    }
    setLoading(true);
    try {
      const { data, error } = await supabase.auth.updateUser({ 
        password: passwords.newPassword 
      });
      if (error) throw error;
      showMessageAlert('success', 'Your password has been updated.');
      setPasswords({ currentPassword: '', newPassword: '', confirmNewPassword: '' });
      toggleEditMode('password');
    } catch (error) {
      console.error('Password update error:', error);
      showMessageAlert('error', 'Failed to update password. ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleNotificationsSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('user_profiles')
        .update({
          email_notifications: notifications.email,
          push_notifications: notifications.push,
          sms_notifications: notifications.sms
        })
        .eq('user_id', user.id);

      if (error) throw error;
      showMessageAlert('success', 'Notification preferences updated.');
      toggleEditMode('notifications');
    } catch (error) {
      console.error('Notification preferences update error:', error);
      showMessageAlert('error', 'Failed to update notification preferences. ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePrivacyToggle = (setting, value) => {
    setPrivacySettings(prev => ({ ...prev, [setting]: value }));
  };

  const handlePrivacySubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('user_profiles')
        .update({
          is_profile_private: privacySettings.isProfilePrivate,
          hide_activity_status: privacySettings.hideActivityStatus,
          connection_preference: privacySettings.connectionPreference
        })
        .eq('id', user.id);

      if (error) throw error;
      showMessageAlert('success', 'Privacy settings updated successfully.');
      toggleEditMode('privacy');
    } catch (error) {
      console.error('Privacy settings update error:', error);
      showMessageAlert('error', 'Failed to update privacy settings. ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const resendEmailConfirmation = async () => {
    try {
      const { error } = await supabase.auth.resend({
        type: 'email_change',
        email: user.new_email,
      });
      if (error) throw error;
      showMessageAlert('success', 'Confirmation email resent. Please check your new email.');
    } catch (error) {
      showMessageAlert('error', 'Failed to resend confirmation email. ' + error.message);
    }
  };

  const handleDeleteAccount = async () => {
    if (deleteConfirmation !== user.email) {
      showMessageAlert('error', 'Email confirmation does not match. Account not deleted.');
      return;
    }
  
    if (!deletePassword) {
      showMessageAlert('error', 'Please enter your password to confirm deletion.');
      return;
    }
  
    setLoading(true);
    try {
      // First, verify the user's password by signing in
      const { error: signInError } = await supabase.auth.signInWithPassword({
        email: user.email,
        password: deletePassword,
      });
  
      if (signInError) throw signInError;
  
      // If password is correct, proceed with account deletion
      const { error: deleteError } = await supabase.rpc('delete_user');
      
      if (deleteError) throw deleteError;
  
      // Log out the user
      const { error: signOutError } = await supabase.auth.signOut();
      if (signOutError) throw signOutError;
  
      showMessageAlert('success', 'Your account has been deleted successfully.');
      
      // Redirect to index page after a short delay
      setTimeout(() => {
        window.location.href = '/'; // This will force a full page reload to the index page
      }, 2000);
  
    } catch (error) {
      console.error('Account deletion error:', error);
      if (error.message.includes('Invalid login credentials')) {
        showMessageAlert('error', 'Incorrect password. Please try again.');
      } else {
        showMessageAlert('error', 'Failed to delete account. ' + error.message);
      }
    } finally {
      setLoading(false);
      setShowDeleteConfirmation(false);
      setDeletePassword('');
    }
  };

  if (!user) return <div className="flex justify-center items-center h-screen">Loading...</div>;

  return (
    <div className="max-w-4xl mx-auto p-4 sm:p-6 pb-20 sm:pb-10 bg-gray-100">
      <div className="flex items-center justify-between mb-8">
        <h1 className="text-2xl sm:text-3xl font-bold text-gray-800">Settings</h1>
        <button 
          onClick={() => navigate('/')} 
          className="text-orange-500 font-medium flex items-center hover:text-orange-600 transition-colors"
        >
          <ArrowLeft size={20} className="mr-2" />
          <span className="hidden sm:inline">Back to Dashboard</span>
          <span className="sm:inline md:hidden">Back</span>
        </button>
      </div>

      {message.content && (
        <div className={`p-4 mb-6 rounded-md ${message.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
          {message.content}
        </div>
      )}

      <div className="space-y-6">
        {/* Profile Information */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold flex items-center text-gray-700">
              <User className="mr-2" /> Profile Information
            </h2>
            <button
              onClick={() => toggleEditMode('profile')}
              className="text-orange-500 hover:text-orange-600 transition-colors"
            >
              {editMode.profile ? <X size={20} /> : <Edit size={20} />}
            </button>
          </div>
          <form onSubmit={handleProfileSubmit}>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Name</label>
                <input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  disabled={!editMode.profile}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 disabled:bg-gray-100"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  disabled={!editMode.profile}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 disabled:bg-gray-100"
                />
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">Phone</label>
                <div className="flex">
                  <select
                    name="countryCode"
                    value={formData.countryCode}
                    onChange={handleInputChange}
                    disabled={!editMode.profile}
                    className="w-1/3 px-3 py-2 border border-gray-300 rounded-l-md shadow-sm focus:outlinenone focus:ring-2 focus:ring-orange-500 focus:border-orange-500 disabled:bg-gray-100"
                  >
                    {countryCodes.map((country) => (
                      <option key={country.code} value={country.code}>
                        {country.code} ({country.country})
                      </option>
                    ))}
                  </select>
                  <input
                    id="phoneNumber"
                    name="phoneNumber"
                    type="tel"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    disabled={!editMode.profile}
                    placeholder="1234567890"
                    className="w-2/3 px-3 py-2 border border-gray-300 rounded-r-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 disabled:bg-gray-100"
                  />
                </div>
                {editMode.profile && <p className="mt-1 text-sm text-gray-500">Enter your phone number without any formatting (e.g., 1234567890)</p>}
              </div>
            </div>
            {editMode.profile && (
              <button 
                type="submit" 
                className="mt-6 w-full sm:w-auto px-6 py-3 bg-orange-500 text-white rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 transition-colors flex items-center justify-center"
                disabled={loading}
              >
                {loading ? 'Saving...' : (
                  <>
                    <Save size={20} className="mr-2" />
                    Save Changes
                  </>
                )}
              </button>
            )}
          </form>
        </div>

        {/* Password Change */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold flex items-center text-gray-700">
              <Lock className="mr-2" /> Change Password
            </h2>
            <button
              onClick={() => toggleEditMode('password')}
              className="text-orange-500 hover:text-orange-600 transition-colors"
            >
              {editMode.password ? <X size={20} /> : <Edit size={20} />}
            </button>
          </div>
          {editMode.password && (
            <form onSubmit={handlePasswordSubmit}>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                {['currentPassword', 'newPassword', 'confirmNewPassword'].map((type) => (
                  <div key={type}>
                    <label htmlFor={type} className="block text-sm font-medium text-gray-700 mb-1">
                      {type.split(/(?=[A-Z])/).join(' ')}
                    </label>
                    <div className="relative">
                      <input
                        id={type}
                        name={type}
                        type={showPassword[type] ? "text" : "password"}
                        value={passwords[type]}
                        onChange={handlePasswordChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword({...showPassword, [type]: !showPassword[type]})}
                        className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-500"
                      >
                        {showPassword[type] ? <EyeOff size={20} /> : <Eye size={20} />}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <button 
                type="submit" 
                className="mt-6 w-full sm:w-auto px-6 py-3 bg-orange-500 text-white rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 transition-colors flex items-center justify-center"
                disabled={loading}
              >
                {loading ? 'Changing Password...' : (
                  <>
                    <Save size={20} className="mr-2" />
                    Change Password
                  </>
                )}
              </button>
            </form>
          )}
        </div>

        {/* Notifications */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold flex items-center text-gray-700">
              <Bell className="mr-2" /> Notifications
            </h2>
            <button
              onClick={() => toggleEditMode('notifications')}
              className="text-orange-500 hover:text-orange-600 transition-colors"
            >
              {editMode.notifications ? <X size={20} /> : <Edit size={20} />}
            </button>
          </div>
          <form onSubmit={handleNotificationsSubmit}>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {['email', 'push', 'sms'].map((type) => (
                <label key={type} className="flex items-center">
                  <input
                    type="checkbox"
                    checked={notifications[type]}
                    onChange={() => setNotifications({...notifications, [type]: !notifications[type]})}
                    disabled={!editMode.notifications}
                    className="form-checkbox h-5 w-5 text-orange-600 transition duration-150 ease-in-out"
                  />
                  <span className="ml-2 text-gray-700">{type.charAt(0).toUpperCase() + type.slice(1)} notifications</span>
                </label>
              ))}
            </div>
            {editMode.notifications && (
              <button 
                type="submit" 
                className="mt-6 w-full sm:w-auto px-6 py-3 bg-orange-500 text-white rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 transition-colors flex items-center justify-center"
              >
                <Save size={20} className="mr-2" />
                Save Preferences
              </button>
            )}
          </form>
        </div>

        {/* Privacy Settings */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold flex items-center text-gray-700">
              <Shield className="mr-2" /> Privacy Settings
            </h2>
            <button
              onClick={() => toggleEditMode('privacy')}
              className="text-orange-500 hover:text-orange-600 transition-colors"
            >
              {editMode.privacy ? <X size={20} /> : <Edit size={20} />}
            </button>
          </div>
          <form onSubmit={handlePrivacySubmit}>
            <div className="space-y-6">
              <div className="flex items-center justify-between">
                <span className="text-gray-700">Make profile private</span>
                <button
                  type="button"
                  className={`${
                    privacySettings.isProfilePrivate ? 'bg-orange-600' : 'bg-gray-200'
                  } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 ${
                    !editMode.privacy && 'opacity-50 cursor-not-allowed'
                  }`}
                  disabled={!editMode.privacy}
                  onClick={() => handlePrivacyToggle('isProfilePrivate', !privacySettings.isProfilePrivate)}
                >
                  <span
                    className={`${
                      privacySettings.isProfilePrivate ? 'translate-x-5' : 'translate-x-0'
                    } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                  />
                </button>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-gray-700">Hide activity status</span>
                <button
                  type="button"
                  className={`${
                    privacySettings.hideActivityStatus ? 'bg-orange-600' : 'bg-gray-200'
                  } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 ${
                    !editMode.privacy && 'opacity-50 cursor-not-allowed'
                  }`}
                  disabled={!editMode.privacy}
                  onClick={() => handlePrivacyToggle('hideActivityStatus', !privacySettings.hideActivityStatus)}
                >
                  <span
                    className={`${
                      privacySettings.hideActivityStatus ? 'translate-x-5' : 'translate-x-0'
                    } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                  />
                </button>
              </div>
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                <label className="flex items-center text-sm font-medium text-gray-700 mb-2 sm:mb-0">
                  <Users className="mr-2" />
                  Receive Buddy connections from:
                </label>
                <select
                  value={privacySettings.connectionPreference}
                  onChange={(e) => handlePrivacyToggle('connectionPreference', e.target.value)}
                  disabled={!editMode.privacy}
                  className="mt-1 sm:mt-0 block w-full sm:w-auto pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm rounded-md"
                >
                  <option value="everyone">Everyone</option>
                  <option value="males_only">Males only</option>
                  <option value="females_only">Females only</option>
                  <option value="no_one">No one (Disable new connections)</option>
                </select>
              </div>
            </div>
            {editMode.privacy && (
              <button 
                type="submit" 
                className="mt-6 w-full sm:w-auto px-6 py-3 bg-orange-500 text-white rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 transition-colors flex items-center justify-center"
                disabled={loading}
              >
                {loading ? 'Saving...' : (
                  <>
                    <Save size={20} className="mr-2" />
                    Save Privacy Settings
                  </>
                )}
              </button>
            )}
          </form>
        </div>

        {/* Delete Account */}
        <div className="bg-white rounded-lg shadow-md p-6 mt-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold flex items-center text-red-600">
              <Trash2 className="mr-2" /> Delete Account
            </h2>
          </div>
          <p className="text-gray-600 mb-4">
            Warning: This action is irreversible. All your data will be permanently deleted.
          </p>
          {!showDeleteConfirmation ? (
            <button
              onClick={() => setShowDeleteConfirmation(true)}
              className="w-full sm:w-auto px-6 py-3 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-colors flex items-center justify-center"
            >
              <Trash2 size={20} className="mr-2" />
              Delete My Account
            </button>
          ) : (
            <div>
              <p className="text-gray-700 mb-2">Please type your email to confirm deletion:</p>
              <input
                type="email"
                value={deleteConfirmation}
                onChange={(e) => setDeleteConfirmation(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500 mb-4"
                placeholder="Enter your email"
              />
              <p className="text-gray-700 mb-2">Please enter your password to confirm deletion:</p>
              <input
                type="password"
                value={deletePassword}
                onChange={(e) => setDeletePassword(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500 mb-4"
                placeholder="Enter your password"
              />
              <div className="flex space-x-4">
                <button
                  onClick={handleDeleteAccount}
                  disabled={deleteConfirmation !== user.email || !deletePassword || loading}
                  className="w-full sm:w-auto px-6 py-3 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-colors flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? 'Deleting...' : 'Confirm Deletion'}
                </button>
                <button
                  onClick={() => {
                    setShowDeleteConfirmation(false);
                    setDeleteConfirmation('');
                    setDeletePassword('');
                  }}
                  className="w-full sm:w-auto px-6 py-3 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-colors flex items-center justify-center"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Settings;