import React, { useState } from 'react';
import { MessageCircle, Send, X } from 'lucide-react';
import { supabase } from '../utils/supabase';

const TrainerMessaging = ({ trainerId, currentUserId }) => {
  const [showMessageForm, setShowMessageForm] = useState(false);
  const [message, setMessage] = useState('');

  const handleSendMessage = async () => {
    if (!message.trim()) return;

    try {
      const { data, error } = await supabase
        .from('messages')
        .insert({
          sender_id: currentUserId,
          receiver_id: trainerId,
          content: message,
        });

      if (error) throw error;

      setMessage('');
      setShowMessageForm(false);
      alert('Message sent successfully!');
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again.');
    }
  };

  return (
    <div className="relative">
      <button
        onClick={() => setShowMessageForm(!showMessageForm)}
        className="flex items-center text-blue-500 hover:text-blue-600 ml-4"
      >
        <MessageCircle size={20} className="mr-2" />
        <span>Message Trainer</span>
      </button>

      {showMessageForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-md p-6 w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">Message Trainer</h3>
              <button
                onClick={() => setShowMessageForm(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X size={20} />
              </button>
            </div>
            <textarea
              className="w-full p-2 border rounded-md text-sm mb-4"
              rows="4"
              placeholder="Type your message here..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            <div className="flex justify-end">
              <button
                onClick={() => setShowMessageForm(false)}
                className="mr-2 px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handleSendMessage}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors flex items-center text-sm"
              >
                <Send size={16} className="mr-2" />
                Send Message
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TrainerMessaging;