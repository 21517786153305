import React, { useState, useEffect, useRef } from 'react';
import { ArrowLeft, ChevronDown, ChevronUp, Star, Calendar, Clock, Users } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const ScheduleComponent = () => {
  const [activeTab, setActiveTab] = useState('Classes');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [expandedSections, setExpandedSections] = useState({});
  const [visibleDates, setVisibleDates] = useState([]);
  const [classes, setClasses] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const navigate = useNavigate();
  const scrollRef = useRef(null);

  const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  useEffect(() => {
    const today = new Date();
    setSelectedDate(today);
    const dates = getVisibleDates(today);
    setVisibleDates(dates);
    fetchClasses(today);
    fetchAppointments(today);
  }, []);

  useEffect(() => {
    fetchClasses(selectedDate);
    fetchAppointments(selectedDate);
  }, [selectedDate]);

  const getVisibleDates = (date) => {
    const dates = [];
    for (let i = -3; i <= 10; i++) {
      const newDate = new Date(date);
      newDate.setDate(date.getDate() + i);
      dates.push(newDate);
    }
    return dates;
  };

  const fetchClasses = (date) => {
    // This is where you'd normally fetch classes from an API
    // For now, we'll use dummy data
    const dummyClasses = [
      { time: '6:30 am', type: 'CYCLING', name: 'Spin and Boxing', instructor: 'Toby Martin', duration: 45, price: 30 },
      { time: '7:00 am', type: 'PILATES', name: 'Pilates Reformer', instructor: 'Jean Ward', duration: 45, price: 35, reviews: 3 },
      { time: '10:00 am', type: 'PILATES', name: 'Pilates Reformer', instructor: 'Jean Ward', duration: 45, price: 35, reviews: 3 },
      { time: '12:15 pm', type: 'PILATES', name: 'Pilates Reformer', instructor: 'Jean Ward', duration: 45, price: 35, reviews: 3, waitlist: true },
      { time: '12:45 pm', type: 'CYCLING', name: 'Spin and Core', instructor: 'Andrew Ward', duration: 30, price: 30 },
    ];
    setClasses(dummyClasses);
  };

  const fetchAppointments = (date) => {
    // This is where you'd normally fetch appointments from an API
    // For now, we'll use dummy data
    const dummyAppointments = [
      { category: 'BODY SCANS and ASSESSMENTS', items: [] },
      { category: 'GYM PROGRAMS', items: ['New Program Set Up (2 X 45mins)'] },
      { category: 'IRS - INFRA RED SAUNA', items: [] },
      { category: 'PP - PRIVATE PILATES', items: [] },
      { 
        category: 'PT - PERSONAL TRAINING', 
        items: [
          'Personal Trainer (30 Min)',
          'Personal Trainer (45min)',
          'Personal Trainer (60 min)',
          'Personal Training Promotion (30min)',
          'Personal Training Promotion (45min)',
          'Personal Training Promotion (60 Min)',
        ]
      },
    ];
    setAppointments(dummyAppointments);
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const isToday = (date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
  };

  const scrollDates = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = direction === 'left' ? -280 : 280;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const toggleSection = (category) => {
    setExpandedSections(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  return (
    <div className="flex flex-col h-screen bg-white">
      <header className="fixed top-0 left-0 right-0 bg-white shadow-md z-10">
        <div className="flex justify-between items-center p-4">
          <button 
            className="text-gray-600 hover:text-gray-800 transition-colors"
            onClick={() => navigate(-1)}
          >
            <ArrowLeft size={24} />
          </button>
          <h1 className="text-xl font-semibold text-gray-800">Schedule</h1>
          <div className="flex space-x-2">
            <button className="text-gray-600 hover:text-gray-800 transition-colors">
              <Calendar size={24} />
            </button>
          </div>
        </div>
        <div className="flex border-b">
          {['Classes', 'Appointments'].map((tab) => (
            <button
              key={tab}
              className={`flex-1 py-3 font-medium transition-colors ${
                activeTab === tab
                  ? 'text-orange-500 border-b-2 border-orange-500'
                  : 'text-gray-600 hover:text-gray-800'
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
      </header>

      <div className="flex-1 overflow-y-auto mt-24 pb-24">
        <div className="p-4">
          <p className="text-center mb-3 text-sm font-medium text-gray-600">
            {isToday(selectedDate) ? 'Today' : selectedDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
          </p>
          <div className="flex justify-center space-x-2 py-2 overflow-x-auto hide-scrollbar">
            {visibleDates.map((date, index) => (
              <div key={index} className="flex flex-col items-center flex-shrink-0">
                <p className="text-xs text-gray-500 mb-1">{daysOfWeek[date.getDay()]}</p>
                <button
                  className={`w-10 h-10 rounded-full transition-all ${
                    date.toDateString() === selectedDate.toDateString()
                      ? 'bg-orange-500 text-white shadow-md'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                  onClick={() => handleDateSelect(date)}
                >
                  {date.getDate()}
                </button>
              </div>
            ))}
          </div>

          {activeTab === 'Classes' && (
            <div className="space-y-6 mt-6">
              {classes.map((cls, index) => (
                <div key={index} className="bg-white rounded-lg shadow-sm p-4 transition-shadow hover:shadow-md">
                  <div className="flex justify-between items-start mb-2">
                    <div>
                      <p className="font-semibold text-gray-800">{cls.time} AEDT</p>
                      <div className="flex items-center text-sm text-gray-500">
                        <Clock size={14} className="mr-1" />
                        <span>{cls.duration} min</span>
                      </div>
                    </div>
                    <p className="font-semibold text-gray-800">${cls.price}.00</p>
                  </div>
                  <p className="text-xs font-medium text-orange-500 mb-1">{cls.type}</p>
                  <p className="font-medium text-gray-800 mb-1">{cls.name}</p>
                  <p className="text-sm text-gray-600 mb-2">w/ {cls.instructor}</p>
                  {cls.reviews && (
                    <div className="flex items-center mb-3">
                      {[...Array(5)].map((_, i) => (
                        <Star key={i} size={14} className="text-yellow-400 fill-current" />
                      ))}
                      <span className="text-xs text-gray-600 ml-1">{cls.reviews} reviews</span>
                    </div>
                  )}
                  <div className="flex justify-end mt-3">
                    <button 
                      className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${
                        cls.waitlist
                          ? 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                          : 'bg-orange-500 text-white hover:bg-orange-600'
                      }`}
                    >
                      {cls.waitlist ? 'Join Waitlist' : 'Book Now'}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}

          {activeTab === 'Appointments' && (
            <div className="space-y-4 mt-6">
              {appointments.map((category, index) => (
                <div key={index} className="bg-white rounded-lg shadow-sm overflow-hidden">
                  <button
                    className="flex justify-between items-center w-full p-4 text-left hover:bg-gray-50 transition-colors"
                    onClick={() => toggleSection(category.category)}
                  >
                    <span className="font-medium text-gray-800">{category.category}</span>
                    {expandedSections[category.category] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                  </button>
                  {expandedSections[category.category] && (
                    <div className="p-4 bg-gray-50 border-t">
                      {category.items.length > 0 ? (
                        category.items.map((item, itemIndex) => (
                          <div key={itemIndex} className="bg-white p-3 rounded-md shadow-sm mb-2 last:mb-0">
                            <p className="text-sm text-gray-800">{item}</p>
                          </div>
                        ))
                      ) : (
                        <p className="text-sm text-gray-500 italic">No appointments available</p>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScheduleComponent;
