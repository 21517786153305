import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Users, Calendar, Activity, MapPin, Clock, Target, Dumbbell, Zap } from 'lucide-react';

const FeatureCard = ({ feature, isExpanded, onExpand, index }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      className={`bg-white rounded-lg shadow-md p-4 sm:p-6 transition-all duration-300 cursor-pointer ${
        isExpanded ? 'col-span-full md:col-span-2 row-span-2' : ''
      }`}
      whileHover={{ scale: 1.03, boxShadow: "0px 10px 30px rgba(0,0,0,0.1)" }}
      onClick={() => onExpand(feature.id)}
      layout
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      <div className="flex items-center mb-3">
        <motion.div
          className="mr-3 sm:mr-4"
          animate={{ rotate: isHovered ? 360 : 0 }}
          transition={{ duration: 0.5 }}
        >
          {React.cloneElement(feature.icon, { className: "h-6 w-6 sm:h-8 sm:w-8 text-orange-500" })}
        </motion.div>
        <h3 className="text-lg sm:text-xl font-semibold text-gray-900">{feature.title}</h3>
      </div>
      <p className="text-sm sm:text-base text-gray-600 mb-3">{feature.description}</p>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <h4 className="font-semibold text-gray-800 mb-2 text-sm sm:text-base">Key Benefits:</h4>
            <ul className="list-disc list-inside text-gray-600 text-sm sm:text-base">
              {feature.benefits.map((benefit, index) => (
                <motion.li
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                >
                  {benefit}
                </motion.li>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const WhyChooseFitBuddy = () => {
  const [expandedFeature, setExpandedFeature] = useState(null);
  const [activeFeatureIndex, setActiveFeatureIndex] = useState(0);

  const features = [
    { 
      id: 1,
      icon: <Users />, 
      title: 'Find Fitness Buddies', 
      description: 'Connect with people who share your fitness interests and goals.',
      benefits: [
        'Personalized matching algorithm',
        'In-app messaging and scheduling',
        'Accountability partnerships'
      ]
    },
    { 
      id: 2,
      icon: <Calendar />, 
      title: 'Join Events', 
      description: 'Discover and participate in local fitness events and classes.',
      benefits: [
        'Wide variety of event types',
        'Easy RSVP and reminders',
        'Meet like-minded fitness enthusiasts'
      ]
    },
    { 
      id: 3,
      icon: <Activity />, 
      title: 'Track Progress', 
      description: 'Set goals, log workouts, and monitor your fitness journey.',
      benefits: [
        'Customizable goal setting',
        'Detailed workout logging',
        'Visual progress tracking'
      ]
    },
    { 
      id: 4,
      icon: <MapPin />, 
      title: 'Explore Nearby', 
      description: 'Find fitness spots, parks, and gyms in your local area.',
      benefits: [
        'Geo-location based recommendations',
        'User reviews and ratings',
        'Discover new workout locations'
      ]
    },
    { 
      id: 5,
      icon: <Clock />, 
      title: 'Flexible Scheduling', 
      description: 'Plan workouts and events that fit your busy lifestyle.',
      benefits: [
        'Sync with your calendar',
        'Smart scheduling suggestions',
        'Last-minute workout matching'
      ]
    },
    { 
      id: 6,
      icon: <Target />, 
      title: 'Community Challenges', 
      description: 'Participate in motivating fitness challenges with the community.',
      benefits: [
        'Regular themed challenges',
        'Leaderboards and achievements',
        'Group and individual challenges'
      ]
    },
    { 
      id: 7,
      icon: <Dumbbell />, 
      title: 'Personalized Workouts', 
      description: 'Get custom workout plans tailored to your fitness level and goals.',
      benefits: [
        'AI-powered workout generation',
        'Progressive difficulty adjustment',
        'Diverse exercise library'
      ]
    },
    { 
      id: 8,
      icon: <Zap />, 
      title: 'Instant Motivation', 
      description: 'Receive daily motivation and tips to keep you on track.',
      benefits: [
        'Personalized motivational quotes',
        'Daily fitness tips and tricks',
        'Community success stories'
      ]
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveFeatureIndex((prev) => (prev + 1) % features.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [features.length]);

  return (
    <section className="py-12 sm:py-20 bg-gradient-to-b from-gray-50 to-white overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-center mb-12 sm:mb-16"
        >
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-extrabold text-gray-900 mb-4">
            Why Choose <span className="text-orange-500">FitBuddy</span>?
          </h2>
          <p className="mt-4 text-base sm:text-lg md:text-xl text-gray-600 max-w-3xl mx-auto">
            Discover the features that make FitBuddy the perfect platform for your fitness journey.
            Tap on each feature to learn more!
          </p>
        </motion.div>
        <motion.div 
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 md:gap-8"
          layout
        >
          {features.map((feature, index) => (
            <FeatureCard
              key={feature.id}
              feature={feature}
              isExpanded={expandedFeature === feature.id}
              onExpand={(id) => setExpandedFeature(id === expandedFeature ? null : id)}
              index={index}
            />
          ))}
        </motion.div>
        <motion.div
          className="mt-12 sm:mt-16 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <h3 className="text-xl sm:text-2xl font-semibold mb-4">Featured Highlight</h3>
          <AnimatePresence mode="wait">
            <motion.div
              key={activeFeatureIndex}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
              className="p-4 sm:p-6 rounded-lg inline-block max-w-md mx-auto"
            >
              <div className="flex items-center justify-center mb-3 sm:mb-4">
                {React.cloneElement(features[activeFeatureIndex].icon, { className: "h-8 w-8 sm:h-10 sm:w-10 text-orange-500" })}
              </div>
              <h4 className="text-lg sm:text-xl font-semibold mb-2">{features[activeFeatureIndex].title}</h4>
              <p className="text-sm sm:text-base text-gray-700">{features[activeFeatureIndex].description}</p>
            </motion.div>
          </AnimatePresence>
        </motion.div>
      </div>
    </section>
  );
};

export default WhyChooseFitBuddy;