import React, { useState, useEffect } from 'react';
import { Search, Users, MapPin, ArrowLeft, Loader, AlertCircle } from 'lucide-react';
import { useNavigate, Link } from 'react-router-dom';
import { supabase } from '../utils/supabase';
import { motion, AnimatePresence } from 'framer-motion';

const CommunityComponent = () => {
  const navigate = useNavigate();
  const [localGroups, setLocalGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSports, setSelectedSports] = useState([]);

  const sportOptions = [
    "Beach Tennis", "Beach Volley", "Boxing/KickBoxing", "BootCamp", 
    "Circuit Training", "Cycling", "Gym Classes", "HIIT", "Martial Arts", 
    "Meditation", "Outdoor", "Padel", "Personal Training", "Pickleball", 
    "Pilates", "Rock Climbing", "Running", "Sports", "Strength Training", 
    "Swimming", "Tennis", "Volleyball", "Walking", "Weight Training", "Yoga"
  ].sort((a, b) => a.localeCompare(b)); // Sort alphabetically

  useEffect(() => {
    fetchLocalGroups();
  }, []);

  const fetchLocalGroups = async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from('groups')
        .select(`
          *,
          members:group_members(count)
        `)
        .limit(10);

      if (error) throw error;

      const groupsWithMemberCount = data.map(group => ({
        ...group,
        memberCount: group.members[0].count
      }));

      setLocalGroups(groupsWithMemberCount);
    } catch (error) {
      console.error('Error fetching local groups:', error);
      setError('Failed to load local groups. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const renderGroupCard = (group) => {
    const imageUrl = group.background_image_url || `https://source.unsplash.com/300x200/?${group.sport.toLowerCase()}`;

    return (
      <motion.div
        key={group.id}
        whileHover={{ scale: 1.05 }}
        className="flex-shrink-0 w-full sm:w-72 bg-white rounded-lg shadow-md overflow-hidden mr-4"
      >
        <div className="relative h-40">
          <img 
            src={imageUrl} 
            alt={group.name} 
            className="w-full h-full object-cover"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = 'https://via.placeholder.com/300x200';
            }}
          />
          <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4">
            <h3 className="font-semibold text-lg text-white mb-1">{group.name}</h3>
          </div>
        </div>
        <div className="p-4">
          <div className="flex items-center text-sm text-gray-600 mb-2">
            <Users size={14} className="mr-1" /> 
            {group.memberCount} members
          </div>
          <div className="flex items-center text-sm text-gray-600 mb-2">
            <MapPin size={14} className="mr-1" /> {group.location || 'Location not specified'}
          </div>
          <div className="text-sm text-gray-600 mb-3">
            Sport: {group.sport}
          </div>
          <button 
            onClick={() => navigate(`/group/${group.id}`)}
            className="w-full bg-orange-500 text-white py-2 rounded-lg hover:bg-orange-600 transition-colors"
          >
            View Group
          </button>
        </div>
      </motion.div>
    );
  };

  const toggleSport = (sport) => {
    setSelectedSports(prev => 
      prev.includes(sport)
        ? prev.filter(s => s !== sport)
        : [...prev, sport]
    );
  };

  const renderSportToggle = (sport) => (
    <button
      key={sport}
      onClick={() => toggleSport(sport)}
      className={`px-4 py-2 rounded-lg mr-2 mb-2 text-sm font-medium transition-colors border ${
        selectedSports.includes(sport)
          ? 'bg-orange-500 text-white border-orange-500'
          : 'bg-transparent text-orange-500 border-orange-500 hover:bg-orange-100'
      }`}
    >
      {sport}
    </button>
  );

  const filteredLocalGroups = localGroups.filter(group =>
    group.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    group.sport.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="font-sans max-w-7xl mx-auto bg-gray-100 min-h-screen p-4 pb-24 sm:pb-4">
      <header className="flex justify-between items-center mb-6">
        <div className="flex items-center">
          <Link to="/dashboard" className="mr-4 text-gray-600 hover:text-gray-800 transition-colors">
            <ArrowLeft size={24} />
          </Link>
          <h1 className="text-2xl font-bold text-gray-800">Community</h1>
        </div>
        <button 
          onClick={() => navigate('/create-group')}
          className="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition-colors"
        >
          Create new group +
        </button>
      </header>

      <div className="relative mb-6">
        <input
          type="text"
          placeholder="Search groups or sports"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-3 pl-10 bg-white border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
        />
        <Search className="absolute left-3 top-3.5 text-gray-400" size={20} />
      </div>

      <AnimatePresence>
        {isLoading ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex justify-center items-center h-64"
          >
            <Loader className="animate-spin text-orange-500" size={40} />
          </motion.div>
        ) : error ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6"
          >
            <div className="flex items-center">
              <AlertCircle className="mr-2" size={20} />
              <p>{error}</p>
            </div>
          </motion.div>
        ) : (
          <>
            <section className="mb-8">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Local Groups</h2>
                <button 
                  onClick={() => navigate('/explore-groups')}
                  className="text-orange-500 font-medium hover:underline"
                >
                  View All
                </button>
              </div>
              <div className="flex flex-nowrap overflow-x-auto pb-4 -mx-4 px-4 scrollbar-hide">
                {filteredLocalGroups.map(renderGroupCard)}
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">Find Local Sports Groups</h2>
              <div className="flex flex-wrap">
                {sportOptions.map(renderSportToggle)}
              </div>
            </section>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CommunityComponent;