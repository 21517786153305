import React, { useState, useEffect } from 'react';
import { supabase } from '../utils/supabase';
import { PlusCircle, Edit, Trash2 } from 'lucide-react';

const MembershipPlanManagement = () => {
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [currentPlan, setCurrentPlan] = useState({ 
    name: '', 
    price: '', 
    features: [],
    stripe_price_id: '',
    stripe_product_id: ''
  });

  useEffect(() => {
    fetchMembershipPlans();
  }, []);

  const fetchMembershipPlans = async () => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from('membership_plans')
        .select('*')
        .order('created_at', { ascending: false });
      
      if (error) throw error;
      setPlans(data);
    } catch (error) {
      setError('Error fetching membership plans');
      console.error('Error fetching membership plans:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentPlan({ ...currentPlan, [name]: value });
  };

  const handleFeaturesChange = (e) => {
    const features = e.target.value.split(',').map(feature => feature.trim());
    setCurrentPlan({ ...currentPlan, features });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const planData = {
        name: currentPlan.name,
        price: currentPlan.price,
        features: currentPlan.features,
        stripe_price_id: currentPlan.stripe_price_id,
        stripe_product_id: currentPlan.stripe_product_id
      };

      if (isEditing) {
        const { error } = await supabase
          .from('membership_plans')
          .update(planData)
          .eq('id', currentPlan.id);
        if (error) throw error;
      } else {
        const { error } = await supabase
          .from('membership_plans')
          .insert([planData]);
        if (error) throw error;
      }
      fetchMembershipPlans();
      setCurrentPlan({ name: '', price: '', features: [], stripe_price_id: '', stripe_product_id: '' });
      setIsEditing(false);
    } catch (error) {
      setError('Error saving membership plan');
      console.error('Error saving membership plan:', error);
    }
  };

  const handleEdit = (plan) => {
    setCurrentPlan({
      ...plan,
      features: Array.isArray(plan.features) ? plan.features : []
    });
    setIsEditing(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this membership plan?')) {
      try {
        const { error } = await supabase
          .from('membership_plans')
          .delete()
          .eq('id', id);
        if (error) throw error;
        fetchMembershipPlans();
      } catch (error) {
        setError('Error deleting membership plan');
        console.error('Error deleting membership plan:', error);
      }
    }
  };

  if (isLoading) return <div>Loading membership plans...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Membership Plans</h2>
      
      <form onSubmit={handleSubmit} className="mb-8 bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold mb-4">{isEditing ? 'Edit' : 'Add'} Membership Plan</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <input
            type="text"
            name="name"
            value={currentPlan.name}
            onChange={handleInputChange}
            placeholder="Plan Name"
            className="w-full p-2 border rounded"
            required
          />
          <input
            type="number"
            name="price"
            value={currentPlan.price}
            onChange={handleInputChange}
            placeholder="Price"
            className="w-full p-2 border rounded"
            required
          />
          <input
            type="text"
            name="features"
            value={currentPlan.features.join(', ')}
            onChange={handleFeaturesChange}
            placeholder="Features (comma-separated)"
            className="w-full p-2 border rounded"
          />
          <input
            type="text"
            name="stripe_price_id"
            value={currentPlan.stripe_price_id}
            onChange={handleInputChange}
            placeholder="Stripe Price ID"
            className="w-full p-2 border rounded"
          />
          <input
            type="text"
            name="stripe_product_id"
            value={currentPlan.stripe_product_id}
            onChange={handleInputChange}
            placeholder="Stripe Product ID"
            className="w-full p-2 border rounded"
          />
        </div>
        <button type="submit" className="mt-4 bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600">
          {isEditing ? 'Update' : 'Add'} Plan
        </button>
      </form>

      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <table className="min-w-full">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 text-left">Name</th>
              <th className="py-2 px-4 text-left">Price</th>
              <th className="py-2 px-4 text-left">Features</th>
              <th className="py-2 px-4 text-left">Stripe Price ID</th>
              <th className="py-2 px-4 text-left">Stripe Product ID</th>
              <th className="py-2 px-4 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {plans.map((plan) => (
              <tr key={plan.id} className="border-t">
                <td className="py-2 px-4">{plan.name}</td>
                <td className="py-2 px-4">${plan.price}</td>
                <td className="py-2 px-4">{Array.isArray(plan.features) ? plan.features.join(', ') : ''}</td>
                <td className="py-2 px-4">{plan.stripe_price_id}</td>
                <td className="py-2 px-4">{plan.stripe_product_id}</td>
                <td className="py-2 px-4">
                  <button
                    onClick={() => handleEdit(plan)}
                    className="text-blue-500 hover:text-blue-700 mr-2"
                  >
                    <Edit size={18} />
                  </button>
                  <button
                    onClick={() => handleDelete(plan.id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <Trash2 size={18} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MembershipPlanManagement;