import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabase';
import LocationInput from './LocationInput';
import { User, Mail, MapPin, Calendar, Activity, Award, Edit, LogOut, Clock, Star, Heart, Target, Save, X, Dumbbell, Bike, Mountain, Snowflake, Footprints, Zap, Shirt, Share2, Droplet, Flame, Sunrise, Sun, Sunset, Moon, CheckCircle } from 'lucide-react';


const uploadProfileImage = async (file, userId) => {
  const fileExt = file.name.split('.').pop();
  const fileName = `${userId}/${Math.random()}.${fileExt}`;

  const { data, error } = await supabase.storage
    .from('profile_images')
    .upload(fileName, file);

  if (error) {
    console.error('Error uploading file:', error);
    return null;
  }

  return fileName;
};

const getPublicImageUrl = (path) => {
  if (!path) return 'https://via.placeholder.com/150';
  if (path.startsWith('blob:') || path.startsWith('data:')) {
    return path; // Return the local URL for preview
  }
  const { data: { publicUrl } } = supabase.storage
    .from('profile_images')
    .getPublicUrl(path);
  return publicUrl;
};

const Onboarding = ({ user, updateUser }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState({
    name: user.user_metadata?.name || '',
    role: user.role,
    gender: '',
    dob: '',
    location: '',
    latitude: null,
    longitude: null,
    fitnessLevel: '',
    interests: [],
    availability: [],
    fitnessGoals: '',
    image_url: user.user_metadata?.avatar_url || null
  });
  const [selectedImage, setSelectedImage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData(prev => ({ ...prev, [name]: value }));
  };

  const handleLocationSelect = (locationData) => {
    setUserData(prev => ({
      ...prev,
      latitude: locationData.latitude,
      longitude: locationData.longitude,
      location: locationData.suburb
    }));
  };

  const handleInterestToggle = (interest) => {
    setUserData(prev => ({
      ...prev,
      interests: prev.interests.includes(interest)
        ? prev.interests.filter(i => i !== interest)
        : [...prev.interests, interest]
    }));
  };

  const handleAvailabilityToggle = (time) => {
    setUserData(prev => ({
      ...prev,
      availability: prev.availability.includes(time)
        ? prev.availability.filter(t => t !== time)
        : [...prev.availability, time]
    }));
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedImage(file);
      const localImageUrl = URL.createObjectURL(file);
      setUserData(prev => ({
        ...prev,
        image_url: localImageUrl
      }));
    }
  };

  const handleFitnessGoalsChange = (e) => {
    setUserData(prev => ({ ...prev, fitnessGoals: e.target.value }));
  };

  const validateStep = () => {
    switch(step) {
      case 1:
        return userData.name && userData.gender && userData.dob;
      case 2:
        return userData.location && userData.latitude && userData.longitude;
      case 3:
        return userData.fitnessLevel && userData.interests.length > 0;
      case 4:
        return userData.availability.length > 0 && userData.fitnessGoals.length > 0;
      default:
        return true;
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setError(null);

    try {
      let imagePath = userData.image_url;

      if (selectedImage) {
        const uploadedImagePath = await uploadProfileImage(selectedImage, user.id);
        if (uploadedImagePath) {
          imagePath = uploadedImagePath;
        }
      }

      const { data, error } = await supabase
        .from('user_profiles')
        .upsert({
          id: user.id,
          role: user.role,
          name: userData.name,
          gender: userData.gender,
          dob: userData.dob,
          location: userData.location,
          latitude: userData.latitude,
          longitude: userData.longitude,
          fitness_level: userData.fitnessLevel,
          interests: userData.interests,
          availability: userData.availability,
          fitness_goals: userData.fitnessGoals.split('\n').filter(goal => goal.trim() !== ''),
          image_url: imagePath,
          has_completed_onboarding: true
        });

      if (error) throw error;

      updateUser({
        ...userData,
        role: user.role,
        has_completed_onboarding: true,
        image_url: imagePath
      });
      navigate('/dashboard');
    } catch (error) {
      console.error('Error updating user profile:', error);
      setError('Failed to save profile. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const steps = [
    { title: 'Basic Info', icon: <User size={20} /> },
    { title: 'Location', icon: <MapPin size={20} /> },
    { title: 'Fitness Profile', icon: <Activity size={20} /> },
    { title: 'Goals & Availability', icon: <Calendar size={20} /> }
  ];

  const interestOptions = [
    { value: 'running', label: 'Running', icon: <Activity size={20} /> },
    { value: 'cycling', label: 'Cycling', icon: <Bike size={20} /> },
    { value: 'swimming', label: 'Swimming', icon: <Droplet size={20} /> },
    { value: 'yoga', label: 'Yoga', icon: <Flame size={20} /> }, 
    { value: 'tennis', label: 'Tennis', icon: <Activity size={20} /> },
    { value: 'hiking', label: 'Hiking', icon: <Mountain size={20} /> },
    { value: 'walking', label: 'Walking', icon: <Footprints size={20} /> },
    { value: 'beach tennis', label: 'Beach Tennis', icon: <Activity size={20} /> },
    { value: 'surfing', label: 'Surfing', icon: <Activity size={20} /> },
    { value: 'hiit', label: 'HIIT', icon: <Zap size={20} /> },
    { value: 'crossfit', label: 'CrossFit', icon: <Shirt size={20} /> },
  ];

  const availabilityOptions = [
    { value: 'morning', label: 'Morning', icon: <Sunrise size={20} /> },
    { value: 'afternoon', label: 'Afternoon', icon: <Sun size={20} /> },
    { value: 'evening', label: 'Evening', icon: <Sunset size={20} /> },
    { value: 'night', label: 'Night', icon: <Moon size={20} /> },
    { value: 'weekends', label: 'Weekends', icon: <Calendar size={20} /> },
  ];

  const renderStepContent = () => {
    switch(step) {
      case 1:
        return (
          <div className="space-y-4">
            <input
              type="text"
              name="name"
              value={userData.name}
              onChange={handleChange}
              placeholder="Name"
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
              required
            />
            <select
              name="gender"
              value={userData.gender}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
              required
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
            <div className="relative">
              <input
                type="text"
                id="dob"
                name="dob"
                value={userData.dob}
                onChange={handleChange}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => {
                  if (!e.target.value) e.target.type = "text";
                }}
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                placeholder="Select your Birthday"
                required
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div className="space-y-4">
            <LocationInput
              onSelectLocation={handleLocationSelect}
              defaultValue={userData.location}
              className="w-full"
            />
          </div>
        );
      case 3:
        return (
          <div className="space-y-4">
            <select
              name="fitnessLevel"
              value={userData.fitnessLevel}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
              required
            >
              <option value="">Select Fitness Level</option>
              <option value="Beginner">Beginner</option>
              <option value="Intermediate">Intermediate</option>
              <option value="Advanced">Advanced</option>
            </select>
            <div>
              <h3 className="font-bold mb-2">Interests</h3>
              <div className="flex flex-wrap">
                {interestOptions.map(interest => (
                  <button
                    key={interest.value}
                    onClick={() => handleInterestToggle(interest.value)}
                    className={`m-1 p-2 rounded transition-colors flex items-center ${
                      userData.interests.includes(interest.value)
                        ? 'bg-orange-500 text-white'
                        : 'bg-gray-200 hover:bg-gray-300'
                    }`}
                  >
                    {interest.icon}
                    <span className="ml-2">{interest.label}</span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="space-y-4">
            <div>
              <h3 className="font-bold mb-2">Availability</h3>
              <div className="flex flex-wrap">
                {availabilityOptions.map(time => (
                  <button
                    key={time.value}
                    onClick={() => handleAvailabilityToggle(time.value)}
                    className={`m-1 p-2 rounded transition-colors flex items-center ${
                      userData.availability.includes(time.value)
                        ? 'bg-green-500 text-white'
                        : 'bg-gray-200 hover:bg-gray-300'
                    }`}
                  >
                    {time.icon}
                    <span className="ml-2">{time.label}</span>
                  </button>
                ))}
              </div>
            </div>
            <div>
              <h3 className="font-bold mb-2">Fitness Goals</h3>
              <textarea
                name="fitnessGoals"
                value={userData.fitnessGoals}
                onChange={handleFitnessGoalsChange}
                placeholder="Enter your fitness goals (one per line)"
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                rows="4"
                required
              />
            </div>
            <div className="flex flex-col items-center">
              <div className="relative w-32 h-32 mb-4">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer z-10"
                />
                <div className="absolute inset-0 bg-gray-200 rounded-full flex items-center justify-center">
                  {userData.image_url ? (
                    <img
                      src={getPublicImageUrl(userData.image_url)}
                      alt="Profile"
                      className="w-full h-full object-cover rounded-full"
                    />
                  ) : (
                    <p className="text-center text-gray-500">Click to upload profile picture</p>
                  )}
                </div>
              </div>
              <p className="text-sm text-gray-500">Click to change profile picture</p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-10 p-4 sm:p-6 bg-white rounded-lg shadow-xl">
      <h1 className="text-3xl font-bold mb-2 text-center">Welcome to FITBuddy!</h1>
      <p className="text-xl text-center mb-6">Let's create your profile</p>
      
      {/* Stepper */}
      <div className="mb-8">
        <div className="flex justify-between">
          {steps.map((s, index) => (
            <div key={index} className="flex flex-col items-center w-full">
              <div className={`w-10 h-10 rounded-full flex items-center justify-center ${
                step > index + 1 ? 'bg-green-500 text-white' : 
                step === index + 1 ? 'bg-orange-500 text-white' : 
                'bg-gray-300 text-gray-600'
              }`}>
                {step > index + 1 ? <CheckCircle size={20} /> : s.icon}
              </div>
              <div className={`mt-2 text-xs sm:text-sm ${
                step === index + 1 ? 'text-orange-500 font-semibold' : 'text-gray-500'
              }`}>
                {s.title}
              </div>
              {index < steps.length - 1 && (
                <div className={`h-1 w-full ${
                  step > index + 1 ? 'bg-green-500' : 'bg-gray-300'
                }`} />
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Step Content */}
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4">{steps[step - 1].title}</h2>
        {renderStepContent()}
      </div>

      {error && <p className="text-red-500 mt-4">{error}</p>}

      {/* Navigation Buttons */}
      <div className="flex justify-between mt-6">
        {step > 1 && (
          <button
            onClick={() => setStep(step - 1)}
            className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 transition-colors"
            disabled={isLoading}
          >
            Previous
          </button>
        )}
        {step < steps.length ? (
          <button
            onClick={() => setStep(step + 1)}
            className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600 transition-colors"
            disabled={isLoading || !validateStep()}
          >
            Next
          </button>
        ) : (
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
            disabled={isLoading || !validateStep()}
          >
            {isLoading ? 'Saving...' : 'Complete Profile'}
          </button>
        )}
      </div>
    </div>
  );
};

export default Onboarding;