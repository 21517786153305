import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Search, Calendar, Users, Filter, LayoutGrid, List, Download, Plus, ChevronDown, ArrowLeft } from 'lucide-react';

const TrainerInvoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('All status');
  const [dateFilter, setDateFilter] = useState('All dates');
  const [ownerFilter, setOwnerFilter] = useState('All owners');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    fetchInvoices();
  }, []);

  const fetchInvoices = () => {
    // Dummy data for demonstration
    const dummyInvoices = [
      { id: 1, name: 'Personal Training', invoice_number: 'INV-001', status: 'Paid', total: 200.00, client: { name: 'John Doe', avatar_url: 'https://i.pravatar.cc/150?img=1' }, due_date: '2024-02-15' },
      { id: 2, name: 'Group Class', invoice_number: 'INV-002', status: 'Pending', total: 150.00, client: { name: 'Jane Smith', avatar_url: 'https://i.pravatar.cc/150?img=2' }, due_date: '2024-02-20' },
      { id: 3, name: 'Nutrition Plan', invoice_number: 'INV-003', status: 'Canceled', total: 100.00, client: { name: 'Bob Johnson', avatar_url: 'https://i.pravatar.cc/150?img=3' }, due_date: '2024-02-10' },
      { id: 4, name: 'Personal Training', invoice_number: 'INV-004', status: 'Paid', total: 250.00, client: { name: 'Alice Brown', avatar_url: 'https://i.pravatar.cc/150?img=4' }, due_date: '2024-02-18' },
      { id: 5, name: 'Fitness Assessment', invoice_number: 'INV-005', status: 'Pending', total: 80.00, client: { name: 'Charlie Davis', avatar_url: 'https://i.pravatar.cc/150?img=5' }, due_date: '2024-02-22' },
    ];
    setInvoices(dummyInvoices);
    setFilteredInvoices(dummyInvoices);
  };

  useEffect(() => {
    const filtered = invoices.filter(invoice => 
      invoice.client.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (statusFilter === 'All status' || invoice.status === statusFilter) &&
      (dateFilter === 'All dates' || true) // Implement actual date filtering logic
    );
    setFilteredInvoices(filtered);
  }, [searchTerm, statusFilter, dateFilter, ownerFilter, invoices]);

  const handleCreateInvoice = () => {
    console.log('Create invoice');
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Paid': return 'bg-green-100 text-green-800';
      case 'Pending': return 'bg-yellow-100 text-yellow-800';
      case 'Canceled': return 'bg-red-100 text-red-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-4 sm:p-6 bg-gray-50 rounded-lg shadow-lg">
      <Link to="/trainer-dashboard" className="flex items-center text-orange-500 hover:text-orange-600 mb-4 sm:mb-6">
        <ArrowLeft size={20} className="mr-2" />
        <span className="text-sm sm:text-base">Back to Dashboard</span>
      </Link>

      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 sm:mb-8">
        <h1 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-4 sm:mb-0">Invoices ({filteredInvoices.length})</h1>
        <button 
          className="bg-orange-500 text-white rounded-md px-3 py-2 sm:px-4 sm:py-2 flex items-center hover:bg-orange-600 transition-colors duration-300 text-sm sm:text-base"
          onClick={handleCreateInvoice}
        >
          <Plus size={18} className="mr-2" />
          Create Invoice
        </button>
      </div>
      
      <div className="bg-white rounded-lg shadow p-4 sm:p-6 mb-6 sm:mb-8">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 sm:mb-6">
          <div className="flex flex-wrap gap-2 mb-4 sm:mb-0 w-full sm:w-auto">
            <select 
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              className="border rounded-md p-2 bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 text-sm w-full sm:w-auto"
            >
              <option>All status</option>
              <option>Paid</option>
              <option>Pending</option>
              <option>Canceled</option>
            </select>
            <select 
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
              className="border rounded-md p-2 bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 text-sm w-full sm:w-auto"
            >
              <option>All dates</option>
              <option>This month</option>
              <option>Last month</option>
              <option>Custom range</option>
            </select>
            <div className="relative w-full sm:w-auto">
              <button 
                className="border rounded-md p-2 bg-gray-50 flex items-center justify-between focus:outline-none focus:ring-2 focus:ring-orange-500 text-sm w-full sm:w-auto"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                More filters
                <ChevronDown size={16} className="ml-2" />
              </button>
              {isDropdownOpen && (
                <div className="absolute z-10 mt-2 w-full sm:w-48 bg-white rounded-md shadow-lg">
                  <div className="py-1">
                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Option 1</a>
                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Option 2</a>
                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Option 3</a>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-2 w-full sm:w-auto">
            <button className="border rounded-md p-2 hover:bg-gray-100 transition-colors duration-300 flex-1 sm:flex-none">
              <LayoutGrid size={20} />
            </button>
            <button className="border rounded-md p-2 hover:bg-gray-100 transition-colors duration-300 flex-1 sm:flex-none">
              <List size={20} />
            </button>
            <button className="border rounded-md p-2 flex items-center justify-center hover:bg-gray-100 transition-colors duration-300 flex-1 sm:flex-none">
              <Download size={20} className="mr-2" />
              <span className="hidden sm:inline">Export</span>
            </button>
          </div>
        </div>

        <div className="relative">
          <input
            type="text"
            placeholder="Search invoices..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 sm:p-3 pl-10 sm:pl-12 border rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 text-sm"
          />
          <Search className="absolute left-3 sm:left-4 top-2.5 sm:top-3.5 text-gray-400" size={20} />
        </div>
      </div>

      <div className="bg-white rounded-lg shadow overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="text-left p-3 sm:p-4 font-semibold text-gray-600 text-xs sm:text-sm">Invoice Name</th>
              <th className="text-left p-3 sm:p-4 font-semibold text-gray-600 text-xs sm:text-sm">Status</th>
              <th className="text-left p-3 sm:p-4 font-semibold text-gray-600 text-xs sm:text-sm">Total</th>
              <th className="text-left p-3 sm:p-4 font-semibold text-gray-600 text-xs sm:text-sm">Associated Client</th>
              <th className="text-left p-3 sm:p-4 font-semibold text-gray-600 text-xs sm:text-sm">Due Date</th>
            </tr>
          </thead>
          <tbody>
            {filteredInvoices.map((invoice) => (
              <tr key={invoice.id} className="border-b hover:bg-gray-50 transition-colors duration-200">
                <td className="p-3 sm:p-4">
                  <div className="font-medium text-gray-800 text-xs sm:text-sm">{invoice.name}</div>
                  <div className="text-xs text-gray-500">{invoice.invoice_number}</div>
                </td>
                <td className="p-3 sm:p-4">
                  <span className={`px-2 sm:px-3 py-1 rounded-full text-xs font-medium ${getStatusColor(invoice.status)}`}>
                    {invoice.status}
                  </span>
                </td>
                <td className="p-3 sm:p-4 font-medium text-xs sm:text-sm">${invoice.total.toFixed(2)}</td>
                <td className="p-3 sm:p-4">
                  <div className="flex items-center">
                    <img src={invoice.client.avatar_url} alt={invoice.client.name} className="w-6 h-6 sm:w-8 sm:h-8 rounded-full mr-2 sm:mr-3 object-cover" />
                    <span className="font-medium text-gray-800 text-xs sm:text-sm">{invoice.client.name}</span>
                  </div>
                </td>
                <td className="p-3 sm:p-4">
                  <div className="flex items-center text-gray-600 text-xs sm:text-sm">
                    <Calendar size={14} className="mr-1 sm:mr-2" />
                    {formatDate(invoice.due_date)}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TrainerInvoices;