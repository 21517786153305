import React, { useState, useEffect } from 'react';
import { supabase } from '../utils/supabase';
import { Plus, Edit, Trash, ChevronDown, ChevronUp, Search, Filter, ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

const NutritionPlanner = () => {
  const [nutritionPlans, setNutritionPlans] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [newPlan, setNewPlan] = useState({
    client_id: '',
    plan_name: '',
    start_date: '',
    end_date: '',
    daily_calories: '',
    protein: '',
    carbs: '',
    fats: '',
    notes: '',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedPlans, setExpandedPlans] = useState({});
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setCurrentUser(user);
    };
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    if (currentUser) {
      fetchNutritionPlans();
      fetchClients();
    }
  }, [currentUser]);

  const fetchNutritionPlans = async () => {
    try {
      const { data, error } = await supabase
        .from('nutrition_plans')
        .select(`
          *,
          clients (
            id,
            name,
            user_id
          )
        `)
        .eq('trainer_id', currentUser.id)
        .order('start_date', { ascending: false });
      if (error) throw error;
      setNutritionPlans(data);
    } catch (error) {
      console.error('Error fetching nutrition plans:', error);
      setError('Failed to fetch nutrition plans. Please try again later.');
    }
  };

  const fetchClients = async () => {
    try {
      const { data, error } = await supabase
        .from('clients')
        .select('id, name')
        .eq('trainer_id', currentUser.id);
      if (error) throw error;
      setClients(data);
    } catch (error) {
      console.error('Error fetching clients:', error);
      setError('Failed to fetch clients. Please try again later.');
    }
  };

  const handleCreatePlan = async () => {
    try {
      const { data, error } = await supabase
        .from('nutrition_plans')
        .insert([{ ...newPlan, trainer_id: currentUser.id }])
        .select();
      if (error) throw error;
      setNutritionPlans([...nutritionPlans, data[0]]);
      setIsCreateModalOpen(false);
      setNewPlan({
        client_id: '',
        plan_name: '',
        start_date: '',
        end_date: '',
        daily_calories: '',
        protein: '',
        carbs: '',
        fats: '',
        notes: '',
      });
    } catch (error) {
      console.error('Error creating nutrition plan:', error);
      setError('Failed to create nutrition plan. Please try again.');
    }
  };

  const handleUpdatePlan = async (updatedPlan) => {
    try {
      const { data, error } = await supabase
        .from('nutrition_plans')
        .update(updatedPlan)
        .eq('id', updatedPlan.id)
        .select();
      if (error) throw error;
      setNutritionPlans(nutritionPlans.map(plan => plan.id === updatedPlan.id ? data[0] : plan));
    } catch (error) {
      console.error('Error updating nutrition plan:', error);
      setError('Failed to update nutrition plan. Please try again.');
    }
  };

  const handleDeletePlan = async (id) => {
    try {
      const { error } = await supabase
        .from('nutrition_plans')
        .delete()
        .eq('id', id);
      if (error) throw error;
      setNutritionPlans(nutritionPlans.filter(plan => plan.id !== id));
    } catch (error) {
      console.error('Error deleting nutrition plan:', error);
      setError('Failed to delete nutrition plan. Please try again.');
    }
  };

  const togglePlanExpand = (planId) => {
    setExpandedPlans(prev => ({
      ...prev,
      [planId]: !prev[planId]
    }));
  };

  const filteredPlans = nutritionPlans.filter(plan =>
    (plan.clients?.name || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
    (plan.plan_name || '').toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Nutrition Planner</h1>
        <Link to="/dashboard" className="text-orange-500 hover:text-orange-600 flex items-center">
          <ArrowLeft size={20} className="mr-2" />
          Back to Dashboard
        </Link>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-6" role="alert">
          <strong className="font-bold">Error:</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}

      <div className="mb-6 flex flex-wrap items-center justify-between">
        <div className="flex items-center space-x-4 mb-4 md:mb-0">
          <div className="relative">
            <input
              type="text"
              placeholder="Search plans..."
              className="pl-10 pr-4 py-2 border rounded-lg"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
          </div>
          <button className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg flex items-center">
            <Filter size={20} className="mr-2" />
            Filter
          </button>
        </div>
        <button
          onClick={() => setIsCreateModalOpen(true)}
          className="bg-orange-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-orange-600 transition-colors"
        >
          <Plus size={20} className="mr-2" />
          New Nutrition Plan
        </button>
      </div>

      <div className="space-y-6">
        {filteredPlans.map((plan) => (
          <div key={plan.id} className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="p-4 flex items-center justify-between cursor-pointer" onClick={() => togglePlanExpand(plan.id)}>
              <div>
                <h3 className="font-semibold text-lg">{plan.plan_name}</h3>
                <p className="text-sm text-gray-500">Client: {plan.clients?.name || 'No client assigned'}</p>
              </div>
              <div className="flex items-center space-x-2">
                <span className="text-sm text-gray-500">{plan.start_date} - {plan.end_date}</span>
                {expandedPlans[plan.id] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
              </div>
            </div>
            {expandedPlans[plan.id] && (
              <div className="p-4 border-t">
                <p><strong>Daily Calories:</strong> {plan.daily_calories}</p>
                <p><strong>Protein:</strong> {plan.protein}g</p>
                <p><strong>Carbs:</strong> {plan.carbs}g</p>
                <p><strong>Fats:</strong> {plan.fats}g</p>
                <p><strong>Notes:</strong> {plan.notes}</p>
                <div className="mt-4 flex justify-end space-x-2">
                  <button
                    onClick={() => handleUpdatePlan(plan)}
                    className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 transition-colors"
                  >
                    <Edit size={16} />
                  </button>
                  <button
                    onClick={() => handleDeletePlan(plan.id)}
                    className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition-colors"
                  >
                    <Trash size={16} />
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      {isCreateModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-8 w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4">Create New Nutrition Plan</h2>
            <select
              className="w-full p-2 mb-4 border rounded-md"
              value={newPlan.client_id}
              onChange={(e) => setNewPlan({ ...newPlan, client_id: e.target.value })}
            >
              <option value="">Select a client</option>
              {clients.map((client) => (
                <option key={client.id} value={client.id}>{client.name}</option>
              ))}
            </select>
            <input
              type="text"
              placeholder="Plan Name"
              className="w-full p-2 mb-4 border rounded-md"
              value={newPlan.plan_name}
              onChange={(e) => setNewPlan({ ...newPlan, plan_name: e.target.value })}
            />
            <input
              type="date"
              className="w-full p-2 mb-4 border rounded-md"
              value={newPlan.start_date}
              onChange={(e) => setNewPlan({ ...newPlan, start_date: e.target.value })}
            />
            <input
              type="date"
              className="w-full p-2 mb-4 border rounded-md"
              value={newPlan.end_date}
              onChange={(e) => setNewPlan({ ...newPlan, end_date: e.target.value })}
            />
            <input
              type="number"
              placeholder="Daily Calories"
              className="w-full p-2 mb-4 border rounded-md"
              value={newPlan.daily_calories}
              onChange={(e) => setNewPlan({ ...newPlan, daily_calories: e.target.value })}
            />
            <input
              type="number"
              placeholder="Protein (g)"
              className="w-full p-2 mb-4 border rounded-md"
              value={newPlan.protein}
              onChange={(e) => setNewPlan({ ...newPlan, protein: e.target.value })}
            />
            <input
              type="number"
              placeholder="Carbs (g)"
              className="w-full p-2 mb-4 border rounded-md"
              value={newPlan.carbs}
              onChange={(e) => setNewPlan({ ...newPlan, carbs: e.target.value })}
            />
            <input
              type="number"
              placeholder="Fats (g)"
              className="w-full p-2 mb-4 border rounded-md"
              value={newPlan.fats}
              onChange={(e) => setNewPlan({ ...newPlan, fats: e.target.value })}
            />
            <textarea
              placeholder="Notes"
              className="w-full p-2 mb-4 border rounded-md"
              value={newPlan.notes}
              onChange={(e) => setNewPlan({ ...newPlan, notes: e.target.value })}
            ></textarea>
            <div className="flex justify-end">
              <button
                onClick={() => setIsCreateModalOpen(false)}
                className="mr-2 px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleCreatePlan}
                className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition-colors"
              >
                Create Plan
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NutritionPlanner;