import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { User, MapPin, Calendar, Star } from 'lucide-react';
import { supabase } from '../utils/supabase';

const SharedProfile = () => {
  const { linkId } = useParams();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSharedProfile = async () => {
      try {
        const { data: linkData, error: linkError } = await supabase
          .from('shareable_links')
          .select('user_id')
          .eq('id', linkId)
          .single();

        if (linkError) throw new Error('Invalid or expired link');

        const { data: userData, error: userError } = await supabase
          .from('user_profiles')
          .select('*')
          .eq('id', linkData.user_id)
          .single();

        if (userError) throw new Error('Error fetching user data');

        setProfileData(userData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSharedProfile();
  }, [linkId]);

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const renderRating = (rating) => {
    if (rating === undefined || rating === null) {
      return <span>No rating available</span>;
    }
    return (
      <div className="flex items-center">
        {[...Array(5)].map((_, i) => (
          <Star key={i} size={16} className={`${i < Math.floor(rating) ? 'text-yellow-400' : 'text-gray-300'} fill-current`} />
        ))}
        <span className="ml-2 text-sm font-medium text-gray-700">{rating.toFixed(1)}</span>
      </div>
    );
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!profileData) return <div>No profile data available</div>;

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-6">
          <h1 className="text-2xl font-bold mb-6">{profileData.name}'s Profile</h1>
          <div className="flex items-center mb-6">
            <img
              src={profileData.image_url ? supabase.storage.from('profile_images').getPublicUrl(profileData.image_url).data.publicUrl : 'https://via.placeholder.com/150'}
              alt={profileData.name}
              className="w-24 h-24 rounded-full object-cover mr-6"
            />
            <div>
              <h2 className="text-2xl font-semibold">{profileData.name}</h2>
              <p className="text-gray-600">{profileData.fitness_level} Fitness Enthusiast</p>
              {renderRating(profileData.rating)}
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
            <div className="flex items-center text-gray-600">
              <User className="mr-2" size={20} />
              <span>{profileData.gender}, {calculateAge(profileData.dob)} years old</span>
            </div>
            <div className="flex items-center text-gray-600">
              <MapPin className="mr-2" size={20} />
              <span>{profileData.location}</span>
            </div>
            <div className="flex items-center text-gray-600">
              <Calendar className="mr-2" size={20} />
              <span>Joined {new Date(profileData.created_at).toLocaleDateString()}</span>
            </div>
          </div>
          <div className="mb-6">
            <h3 className="font-semibold mb-2">Interests</h3>
            <div className="flex flex-wrap">
              {profileData.interests && profileData.interests.map((interest, index) => (
                <span key={index} className="bg-orange-100 text-orange-800 text-sm font-medium mr-2 mb-2 px-2.5 py-0.5 rounded-full">
                  {interest}
                </span>
              ))}
            </div>
          </div>
          <div className="mb-6">
            <h3 className="font-semibold mb-2">Availability</h3>
            <div className="flex flex-wrap">
              {profileData.availability && profileData.availability.map((time, index) => (
                <span key={index} className="bg-green-100 text-green-800 text-sm font-medium mr-2 mb-2 px-2.5 py-0.5 rounded-full">
                  {time}
                </span>
              ))}
            </div>
          </div>
          <div>
            <h3 className="font-semibold mb-2">Fitness Goals</h3>
            {profileData.fitness_goals && profileData.fitness_goals.length > 0 ? (
              <ul className="list-disc list-inside">
                {profileData.fitness_goals.map((goal, index) => (
                  <li key={index} className="text-gray-600">{goal}</li>
                ))}
              </ul>
            ) : (
              <span>No fitness goals specified</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharedProfile;