import React, { useState, useEffect } from 'react';
import { Medal, Trophy, Star, Activity, MessageSquare, ChevronUp, ChevronDown, User, ArrowLeft, Users } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import Navigation from './Navigation';
import { Link } from 'react-router-dom';
import { supabase } from '../utils/supabase';

const LeaderboardPage = () => {
  const [buddies, setBuddies] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [sortBy, setSortBy] = useState('events');
  const [isAscending, setIsAscending] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [activeTab, setActiveTab] = useState('buddies');

  useEffect(() => {
    fetchBuddies();
    fetchTrainers();
  }, []);

  useEffect(() => {
    sortData();
  }, [sortBy, isAscending, activeTab]);

  const getPublicImageUrl = (path) => {
    if (!path) return "https://via.placeholder.com/150";
    try {
      const { data } = supabase.storage
        .from('profile_images')
        .getPublicUrl(path);
      return data.publicUrl;
    } catch (error) {
      console.error('Error getting public URL:', error);
      return "https://via.placeholder.com/150";
    }
  };

  const fetchBuddies = async () => {
    try {
      const { data: buddiesData, error } = await supabase
        .from('user_profiles')
        .select('id, name, image_url')
        .eq('role', 'buddy');

      if (error) throw error;

      const buddiesWithMetrics = await Promise.all(buddiesData.map(async (buddy) => {
        const [events, connections, workouts, reviews, avgRating] = await Promise.all([
          getCount('event_attendees', 'user_id', buddy.id),
          getConnectionsCount(buddy.id),
          getCount('workout_participants', 'user_id', buddy.id),
          getCount('buddy_reviews', 'buddy_id', buddy.id),
          getAverageRating('buddy_reviews', 'buddy_id', buddy.id),
        ]);

        return {
          ...buddy,
          events,
          connections,
          workouts,
          reviews,
          rating: avgRating,
          image_url: getPublicImageUrl(buddy.image_url),
        };
      }));

      setBuddies(buddiesWithMetrics);
    } catch (error) {
      console.error('Error fetching buddies:', error);
    }
  };

  const fetchTrainers = async () => {
    try {
      const { data: trainersData, error } = await supabase
        .from('user_profiles')
        .select('id, name, image_url')
        .eq('role', 'trainer');

      if (error) throw error;

      const trainersWithMetrics = await Promise.all(trainersData.map(async (trainer) => {
        const [events, clients, reviews, avgRating] = await Promise.all([
          getCount('trainer_classes', 'trainer_id', trainer.id),
          getCount('clients', 'trainer_id', trainer.id),
          getCount('trainer_reviews', 'trainer_id', trainer.id),
          getAverageRating('trainer_reviews', 'trainer_id', trainer.id),
        ]);

        return {
          ...trainer,
          events,
          clients,
          reviews,
          rating: avgRating,
          image_url: getPublicImageUrl(trainer.image_url),
        };
      }));

      setTrainers(trainersWithMetrics);
    } catch (error) {
      console.error('Error fetching trainers:', error);
    }
  };

  const getCount = async (table, column, id) => {
    const { count, error } = await supabase
      .from(table)
      .select(column, { count: 'exact', head: true })
      .eq(column, id);

    if (error) {
      console.error(`Error getting count for ${table}:`, error);
      return 0;
    }
    return count;
  };

  const getConnectionsCount = async (userId) => {
    const { count: sentCount, error: sentError } = await supabase
      .from('buddy_connections')
      .select('sender_id', { count: 'exact', head: true })
      .eq('sender_id', userId);

    const { count: receivedCount, error: receivedError } = await supabase
      .from('buddy_connections')
      .select('receiver_id', { count: 'exact', head: true })
      .eq('receiver_id', userId);

    if (sentError || receivedError) {
      console.error('Error getting connections count:', sentError || receivedError);
      return 0;
    }

    return sentCount + receivedCount;
  };

  const getAverageRating = async (table, column, id) => {
    const { data, error } = await supabase
      .from(table)
      .select('rating')
      .eq(column, id);

    if (error) {
      console.error(`Error getting ratings for ${table}:`, error);
      return 0;
    }

    if (data.length === 0) return 0;

    const sum = data.reduce((acc, curr) => acc + curr.rating, 0);
    return (sum / data.length).toFixed(1);
  };

  const sortData = () => {
    const dataToSort = activeTab === 'buddies' ? [...buddies] : [...trainers];
    const sortedData = dataToSort.sort((a, b) => {
      const aValue = a[sortBy] || 0;
      const bValue = b[sortBy] || 0;
      if (aValue < bValue) return isAscending ? -1 : 1;
      if (aValue > bValue) return isAscending ? 1 : -1;
      return 0;
    });

    if (activeTab === 'buddies') {
      setBuddies(sortedData);
    } else {
      setTrainers(sortedData);
    }
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setIsAscending(!isAscending);
    } else {
      setSortBy(column);
      setIsAscending(false);
    }
  };

  const SortIcon = ({ column }) => {
    if (sortBy !== column) return null;
    return isAscending ? <ChevronUp size={14} /> : <ChevronDown size={14} />;
  };

  const getRankColor = (rank) => {
    switch (rank) {
      case 1: return 'text-yellow-500';
      case 2: return 'text-gray-400';
      case 3: return 'text-orange-500';
      default: return 'text-gray-500';
    }
  };

  const PersonRow = ({ person, rank }) => (
    <motion.tr
      onClick={() => setSelectedPerson(person)}
      className={`cursor-pointer transition-colors ${rank % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-orange-50`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <td className="py-2 px-1 sm:px-2 text-center">
        {rank <= 3 ? (
          <Medal className={`inline-block ${getRankColor(rank)}`} size={16} />
        ) : (
          <span className="font-bold text-gray-500 text-xs sm:text-sm">{rank}</span>
        )}
      </td>
      <td className="py-2 px-1 sm:px-2">
        <div className="flex items-center">
          <div className="flex-shrink-0 w-6 h-6 sm:w-8 sm:h-8">
            <img className="w-full h-full rounded-full object-cover" src={person.image_url} alt={person.name} />
          </div>
          <div className="ml-2">
            <div className="text-xs sm:text-sm font-medium text-gray-900 truncate max-w-[100px] sm:max-w-[150px]">{person.name}</div>
          </div>
        </div>
      </td>
      <td className="py-2 px-1 sm:px-2 text-center">
        <div className="flex items-center justify-center">
          <Trophy className="text-yellow-500 mr-1" size={12} />
          <span className="font-medium text-xs">{person.events}</span>
        </div>
      </td>
      <td className="py-2 px-1 sm:px-2 text-center hidden sm:table-cell">
        <div className="flex items-center justify-center">
          <Activity className="text-green-500 mr-1" size={12} />
          <span className="font-medium text-xs">
            {activeTab === 'buddies' ? person.workouts : person.clients}
          </span>
        </div>
      </td>
      <td className="py-2 px-1 sm:px-2 text-center hidden md:table-cell">
        <div className="flex items-center justify-center">
          <MessageSquare className="text-blue-500 mr-1" size={12} />
          <span className="font-medium text-xs">{person.reviews}</span>
        </div>
      </td>
      <td className="py-2 px-1 sm:px-2 text-center">
        <div className="flex items-center justify-center">
          <Star className="text-purple-500 mr-1" size={12} />
          <span className="font-medium text-xs">{person.rating}</span>
        </div>
      </td>
    </motion.tr>
  );

  const PersonDetails = ({ person, onClose }) => (
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
    >
      <motion.div
        className="bg-white p-4 sm:p-6 rounded-lg w-full max-w-sm sm:max-w-md shadow-xl"
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        transition={{ type: 'spring', damping: 20, stiffness: 300 }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-col items-center mb-4 sm:mb-6">
          <img
            src={person.image_url}
            alt={person.name}
            className="w-20 h-20 sm:w-24 sm:h-24 rounded-full mb-2 sm:mb-4 border-4 border-orange-500 shadow-md object-cover"
          />
          <h2 className="text-xl sm:text-2xl font-bold text-gray-800">{person.name}</h2>
          <p className="text-sm text-gray-600 mt-1">
            {activeTab === 'buddies' ? 'Fitness Buddy' : 'Fitness Trainer'}
          </p>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-orange-100 p-3 rounded-lg text-center">
            <Trophy className="w-6 h-6 mx-auto text-yellow-500 mb-1" />
            <p className="text-lg font-bold text-gray-700">{person.events}</p>
            <p className="text-xs text-gray-600">
              {activeTab === 'buddies' ? 'Events' : 'Classes'}
            </p>
          </div>
          <div className="bg-green-100 p-3 rounded-lg text-center">
            {activeTab === 'buddies' ? (
              <>
                <Activity className="w-6 h-6 mx-auto text-green-500 mb-1" />
                <p className="text-lg font-bold text-gray-700">{person.workouts}</p>
                <p className="text-xs text-gray-600">Workouts</p>
              </>
            ) : (
              <>
                <Users className="w-6 h-6 mx-auto text-green-500 mb-1" />
                <p className="text-lg font-bold text-gray-700">{person.clients}</p>
                <p className="text-xs text-gray-600">Clients</p>
              </>
            )}
          </div>
          <div className="bg-blue-100 p-3 rounded-lg text-center">
            <MessageSquare className="w-6 h-6 mx-auto text-blue-500 mb-1" />
            <p className="text-lg font-bold text-gray-700">{person.reviews}</p>
            <p className="text-xs text-gray-600">Reviews</p>
          </div>
          <div className="bg-purple-100 p-3 rounded-lg text-center">
            <Star className="w-6 h-6 mx-auto text-purple-500 mb-1" />
            <p className="text-lg font-bold text-gray-700">{person.rating}</p>
            <p className="text-xs text-gray-600">Rating</p>
          </div>
        </div>
        <button
          onClick={onClose}
          className="mt-6 w-full bg-orange-500 text-white py-2 rounded-lg hover:bg-orange-600 transition-colors font-bold text-sm sm:text-base shadow-md"
        >
          Close
        </button>
      </motion.div>
    </motion.div>
  );

  return (
    <div className="min-h-screen bg-gray-100 pb-20">
      <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-6">
        <Link 
          to="/dashboard" 
          className="inline-flex items-center text-orange-500 hover:text-orange-600 transition-colors mb-4"
        >
          <ArrowLeft size={16} className="mr-1" />
          <span className="text-sm">Back to Dashboard</span>
        </Link>
        
        <h1 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-center text-gray-800">
          FIT Buddy Leaderboard
        </h1>
        
        <div className="bg-white shadow-md rounded-lg overflow-hidden mb-6">
          <div className="flex border-b">
            <button
              className={`flex-1 py-2 px-2 sm:px-4 text-xs sm:text-sm font-medium transition-colors ${
                activeTab === 'buddies' 
                  ? 'bg-orange-500 text-white' 
                  : 'bg-white text-gray-600 hover:bg-orange-100'
              }`}
              onClick={() => setActiveTab('buddies')}
            >
              Buddies
            </button>
            <button
              className={`flex-1 py-2 px-2 sm:px-4 text-xs sm:text-sm font-medium transition-colors ${
                activeTab === 'trainers' 
                  ? 'bg-orange-500 text-white' 
                  : 'bg-white text-gray-600 hover:bg-orange-100'
              }`}
              onClick={() => setActiveTab('trainers')}
            >
              Trainers
            </button>
          </div>
          
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  {[
                    { label: 'Rank', sortKey: null },
                    { label: 'Name', sortKey: null },
                    { label: activeTab === 'buddies' ? 'Events' : 'Classes', sortKey: 'events' },
                    { label: activeTab === 'buddies' ? 'Workouts' : 'Clients', sortKey: activeTab === 'buddies' ? 'workouts' : 'clients' },
                    { label: 'Reviews', sortKey: 'reviews' },
                    { label: 'Rating', sortKey: 'rating' }
                  ].map((column, index) => (
                    <th 
                      key={index}
                      className={`py-2 px-1 sm:px-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                        column.sortKey ? 'cursor-pointer hover:bg-gray-100' : ''
                      } ${index === 3 ? 'hidden sm:table-cell' : ''} ${index === 4 ? 'hidden md:table-cell' : ''}`}
                      onClick={() => column.sortKey && handleSort(column.sortKey)}
                    >
                      <div className="flex items-center">
                        {column.label}
                        {column.sortKey && (
                          <span className="ml-1">
                            <SortIcon column={column.sortKey} />
                          </span>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <AnimatePresence>
                  {(activeTab === 'buddies' ? buddies : trainers).map((person, index) => (
                    <PersonRow key={person.id} person={person} rank={index + 1} />
                  ))}
                </AnimatePresence>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {selectedPerson && (
          <PersonDetails key="person-details" person={selectedPerson} onClose={() => setSelectedPerson(null)} />
        )}
      </AnimatePresence>
      <Navigation setActiveTab={() => {}} />
    </div>
  );
};

export default LeaderboardPage;