import { supabase } from '../utils/supabase';

const ARTICLES_TABLE = 'fitness_articles';

export const fetchAndStoreFitnessArticles = async () => {
  try {
    const topics = [
      'fitness tips',
      'workout routines',
      'nutrition for athletes',
      'health and wellness',
      'strength training',
      'cardio exercises',
      'weight loss strategies',
      'muscle building'
    ];
    const randomTopics = topics.sort(() => 0.5 - Math.random()).slice(0, 3).join(' OR ');
    
    const response = await fetch(`https://newsapi.org/v2/everything?q=(${randomTopics})&sortBy=publishedAt&language=en&pageSize=20&apiKey=${process.env.REACT_APP_NEWS_API_KEY}`);
    const data = await response.json();
    
    if (data.status === 'ok') {
      const filteredArticles = data.articles.filter(article => 
        article.title.toLowerCase().match(/fitness|health|nutrition|workout|training|exercise|diet|wellness/)
      );

      const articles = filteredArticles.map(article => ({
        title: article.title,
        summary: article.description,
        image_url: article.urlToImage,
        url: article.url,
        published_at: article.publishedAt,
        source: article.source.name
      }));

      // Store articles in Supabase
      const { data: insertedData, error } = await supabase
        .from(ARTICLES_TABLE)
        .upsert(articles, { 
          onConflict: 'url',
          update: ['title', 'summary', 'image_url', 'published_at', 'source']
        });

      if (error) throw error;

      console.log('Articles stored successfully:', insertedData);
      return insertedData;
    } else {
      throw new Error('Failed to fetch fitness articles: ' + data.message);
    }
  } catch (error) {
    console.error('Error in fetchAndStoreFitnessArticles:', error);
    throw error;
  }
};

export const getFitnessArticlesFromSupabase = async (limit = 20) => {
  try {
    const { data, error } = await supabase
      .from(ARTICLES_TABLE)
      .select('*')
      .order('published_at', { ascending: false })
      .limit(limit);

    if (error) throw error;

    return data;
  } catch (error) {
    console.error('Error in getFitnessArticlesFromSupabase:', error);
    throw error;
  }
};

export const isArticlesOutdated = (latestArticleDate) => {
  const oneDayAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);
  return new Date(latestArticleDate) < oneDayAgo;
};

export const deleteFitnessArticles = async (articleIds) => {
  try {
    const { data, error } = await supabase
      .from(ARTICLES_TABLE)
      .delete()
      .in('id', articleIds);

    if (error) throw error;

    console.log('Articles deleted successfully:', data);
    return data;
  } catch (error) {
    console.error('Error in deleteFitnessArticles:', error);
    throw error;
  }
};

export const updateFitnessArticle = async (articleId, updateData) => {
  try {
    const { data, error } = await supabase
      .from(ARTICLES_TABLE)
      .update(updateData)
      .eq('id', articleId);

    if (error) throw error;

    console.log('Article updated successfully:', data);
    return data;
  } catch (error) {
    console.error('Error in updateFitnessArticle:', error);
    throw error;
  }
};