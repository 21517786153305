import React from 'react';

const LoadingSpinner = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-orange-500 mb-4"></div>
      <p className="text-xl font-semibold text-gray-700">FIT Buddy is looking for results</p>
      <p className="text-sm text-gray-500 mt-2 animate-pulse">Please wait...</p>
    </div>
  );
};

export default LoadingSpinner;