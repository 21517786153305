import React from 'react';
import FitBuddyCoach from '../components/FitBuddyCoach';

const FitBuddyCoachPage = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">FIT Buddy Coach</h1>
      <FitBuddyCoach />
    </div>
  );
};

export default FitBuddyCoachPage;