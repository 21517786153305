import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Loader2, ChevronDown, ChevronUp, Info, ArrowLeft, Save, Trash, Utensils, Clock, Calendar, X, Copy, Check, Printer, Search } from 'lucide-react';
import { supabase } from '../utils/supabase';
import { motion, AnimatePresence } from 'framer-motion';
import { debounce } from 'lodash';
import ReactToPrint from 'react-to-print';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const MacroChart = ({ macro, value, color }) => {
  const data = [
    { name: macro, value: value },
    { name: 'Remaining', value: 100 - value }
  ];

  return (
    <ResponsiveContainer width="100%" height={100}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={25}
          outerRadius={40}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          <Cell key={`cell-0`} fill={color} />
          <Cell key={`cell-1`} fill="#E5E7EB" />
        </Pie>
        <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" className="text-sm font-medium">
          {value}%
        </text>
      </PieChart>
    </ResponsiveContainer>
  );
};



const AIMealPlannerPro = ({ currentUser }) => {
  const [userPreferences, setUserPreferences] = useState({
    goal: '',
    dietaryRestrictions: [],
    mealsPerDay: 3,
    planDuration: 1,
    age: '',
    gender: '',
    weight: '',
    height: '',
    activityLevel: '',
    proteinSources: [],
    carbSources: [],
    fatSources: [],
    calorieTarget: '',
    macroRatio: { protein: 30, carbs: 40, fat: 30 },
    macroGrams: { protein: 0, carbs: 0, fat: 0 },
    isPro: true, // Set this based on user's subscription status
  });
  const [mealPlan, setMealPlan] = useState(null);
  const [savedMealPlans, setSavedMealPlans] = useState([]);
  const [filteredMealPlans, setFilteredMealPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedMeal, setExpandedMeal] = useState(null);
  const [error, setError] = useState(null);
  const [isLoadedPlan, setIsLoadedPlan] = useState(false);
  const [currentDay, setCurrentDay] = useState(0);
  const [isCopied, setIsCopied] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const printRef = React.useRef();

  const dietaryOptions = [
    'Vegetarian', 'Vegan', 'Gluten-Free', 'Dairy-Free', 'Keto', 'Paleo'
  ];

  const planDurationOptions = [
    { value: 1, label: '1 Week' },
    { value: 2, label: '2 Weeks' },
    { value: 3, label: '3 Weeks' },
    { value: 4, label: '4 Weeks' },
  ];

  const proteinOptions = [
    { value: 'chicken', label: 'Chicken', icon: '🍗' },
    { value: 'beef', label: 'Beef', icon: '🥩' },
    { value: 'fish', label: 'Fish', icon: '🐟' },
    { value: 'eggs', label: 'Eggs', icon: '🥚' },
    { value: 'tofu', label: 'Tofu', icon: '🧊' },
    { value: 'beans', label: 'Beans', icon: '🫘' },
  ];

  const carbOptions = [
    { value: 'rice', label: 'Rice', icon: '🍚' },
    { value: 'pasta', label: 'Pasta', icon: '🍝' },
    { value: 'bread', label: 'Bread', icon: '🍞' },
    { value: 'potato', label: 'Potato', icon: '🥔' },
    { value: 'quinoa', label: 'Quinoa', icon: '🌾' },
    { value: 'oats', label: 'Oats', icon: '🥣' },
  ];

  const fatOptions = [
    { value: 'avocado', label: 'Avocado', icon: '🥑' },
    { value: 'nuts', label: 'Nuts', icon: '🥜' },
    { value: 'olive_oil', label: 'Olive Oil', icon: '🫒' },
    { value: 'coconut', label: 'Coconut', icon: '🥥' },
    { value: 'cheese', label: 'Cheese', icon: '🧀' },
    { value: 'seeds', label: 'Seeds', icon: '🌰' },
    { value: 'butter', label: 'Butter', icon: '🧈' },
    { value: 'dark_chocolate', label: 'Dark Chocolate', icon: '🍫' },
    { value: 'full_fat_yogurt', label: 'Full-fat Yogurt', icon: '🥛' },
    { value: 'egg_yolks', label: 'Egg Yolks', icon: '🍳' },
  ];

  useEffect(() => {
    if (currentUser) {
      fetchSavedMealPlans();
    }
  }, [currentUser]);

  useEffect(() => {
    calculateCalorieTarget();
  }, [userPreferences.age, userPreferences.gender, userPreferences.weight, userPreferences.height, userPreferences.activityLevel, userPreferences.goal]);

  useEffect(() => {
    const filtered = savedMealPlans.filter(plan => 
      plan.preferences.goal.toLowerCase().includes(searchTerm.toLowerCase()) ||
      plan.preferences.calorieTarget.toString().includes(searchTerm)
    );
    setFilteredMealPlans(filtered);
  }, [savedMealPlans, searchTerm]);

  const fetchSavedMealPlans = async () => {
    const { data, error } = await supabase
      .from('meal_plans')
      .select('*')
      .eq('user_id', currentUser.id);

    if (error) {
      console.error('Error fetching saved meal plans:', error);
    } else {
      setSavedMealPlans(data);
      setFilteredMealPlans(data);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserPreferences(prev => {
      const updated = { ...prev, [name]: value };
      if (['age', 'weight', 'height', 'mealsPerDay', 'planDuration'].includes(name)) {
        updated[name] = value === '' ? '' : Math.max(0, parseInt(value) || 0);
      }
      return updated;
    });
  };

  const handleDietaryToggle = (option) => {
    setUserPreferences(prev => ({
      ...prev,
      dietaryRestrictions: prev.dietaryRestrictions.includes(option)
        ? prev.dietaryRestrictions.filter(item => item !== option)
        : [...prev.dietaryRestrictions, option]
    }));
  };

  const handleSourceToggle = (sourceType, option) => {
    setUserPreferences(prev => ({
      ...prev,
      [sourceType]: prev[sourceType].includes(option)
        ? prev[sourceType].filter(item => item !== option)
        : [...prev[sourceType], option]
    }));
  };

  const handleMacroRatioChange = (macro, value) => {
    const newValue = Math.max(0, Math.min(100, parseInt(value) || 0));
    setUserPreferences(prev => {
      const newRatio = { ...prev.macroRatio, [macro]: newValue };
      const total = Object.values(newRatio).reduce((sum, val) => sum + val, 0);
      
      if (total > 100) {
        const excess = total - 100;
        const otherMacros = Object.keys(newRatio).filter(key => key !== macro);
        otherMacros.forEach(key => {
          newRatio[key] = Math.max(0, newRatio[key] - (excess * newRatio[key] / (total - newRatio[macro])));
        });
      }
      
      const normalizedTotal = Object.values(newRatio).reduce((sum, val) => sum + val, 0);
      Object.keys(newRatio).forEach(key => {
        newRatio[key] = Math.round((newRatio[key] / normalizedTotal) * 100);
      });
      
      const newMacroGrams = calculateMacroGrams(prev.calorieTarget, newRatio);
      
      return { ...prev, macroRatio: newRatio, macroGrams: newMacroGrams };
    });
  };

  const calculateMacroGrams = (calories, ratio) => {
    const caloriesNum = parseInt(calories) || 0;
    return {
      protein: Math.round((caloriesNum * (ratio.protein / 100)) / 4),
      carbs: Math.round((caloriesNum * (ratio.carbs / 100)) / 4),
      fat: Math.round((caloriesNum * (ratio.fat / 100)) / 9)
    };
  };

  const calculateCalorieTarget = () => {
    const { age, gender, weight, height, activityLevel, goal } = userPreferences;
    
    if (!age || !gender || !weight || !height || !activityLevel || !goal) {
      return; // Don't calculate if any required field is missing
    }

    let bmr;
    if (gender === 'male') {
      bmr = 88.362 + (13.397 * weight) + (4.799 * height) - (5.677 * age);
    } else {
      bmr = 447.593 + (9.247 * weight) + (3.098 * height) - (4.330 * age);
    }

    let activityMultiplier;
    switch (activityLevel) {
      case 'sedentary': activityMultiplier = 1.2; break;
      case 'lightlyActive': activityMultiplier = 1.375; break;
      case 'moderatelyActive': activityMultiplier = 1.55; break;
      case 'veryActive': activityMultiplier = 1.725; break;
      case 'extraActive': activityMultiplier = 1.9; break;
      default: activityMultiplier = 1.2;
    }

    let tdee = Math.round(bmr * activityMultiplier);

    switch (goal) {
      case 'weightLoss': tdee -= 500; break;
      case 'muscleGain': tdee += 300; break;
      // For 'maintenance', we don't adjust the TDEE
    }

    setUserPreferences(prev => ({
      ...prev,
      calorieTarget: tdee,
      macroGrams: calculateMacroGrams(tdee, prev.macroRatio)
    }));
  };

  const validateInputs = () => {
    const requiredFields = ['age', 'gender', 'weight', 'height', 'activityLevel', 'goal', 'mealsPerDay', 'planDuration', 'calorieTarget'];
    return requiredFields.every(field => userPreferences[field] !== '') &&
           userPreferences.proteinSources.length > 0 &&
           userPreferences.carbSources.length > 0 &&
           userPreferences.fatSources.length > 0;
  };

  const generateMealPlan = async () => {
    if (!validateInputs()) {
      setError('Please fill in all required fields and select at least one source for each macronutrient.');
      return;
    }
  
    setIsLoading(true);
    setError(null);
    setIsLoadedPlan(false);
    setMealPlan(null);
  
    try {
      // Check cache first
      const cachedPlan = localStorage.getItem(`mealPlan_${JSON.stringify(userPreferences)}`);
      if (cachedPlan) {
        setMealPlan(JSON.parse(cachedPlan));
        setIsLoading(false);
        return;
      }

      const response = await supabase.functions.invoke('generate-meal-plan', {
        body: { userPreferences },
      });
  
      if (response.error) {
        throw new Error(response.error.message);
      }
  
      let mealPlanData = response.data;
      console.log('Raw response data:', JSON.stringify(mealPlanData, null, 2));
  
      // Check if mealPlanData is wrapped in a mealPlan key
      if (mealPlanData.mealPlan) {
        mealPlanData = mealPlanData.mealPlan;
      }
  
      const validationResult = validateMealPlan(mealPlanData, userPreferences);
      
      if (validationResult.isValid) {
        let fullMealPlan;
        if (Array.isArray(mealPlanData)) {
          // It's a single day plan, expand it to 7 days
          fullMealPlan = Array(7).fill().map((_, i) => ({
            day: `Day ${i + 1}`,
            meals: mealPlanData
          }));
        } else {
          // It's a multi-day plan
          fullMealPlan = Object.entries(mealPlanData).map(([day, meals]) => ({
            day,
            meals
          }));
        }
        console.log('Processed meal plan:', JSON.stringify(fullMealPlan, null, 2));
        setMealPlan(fullMealPlan);

        // Cache the meal plan
        localStorage.setItem(`mealPlan_${JSON.stringify(userPreferences)}`, JSON.stringify(fullMealPlan));
      } else {
        throw new Error(`Failed to generate a valid meal plan: ${validationResult.error}`);
      }
    } catch (error) {
      console.error('Error generating meal plan:', error);
      setError(`Failed to generate meal plan: ${error.message}. Please try again.`);
    } finally {
      setIsLoading(false);
    }
  };
  
  const validateMealPlan = (mealPlan, userPreferences) => {
    console.log('Raw meal plan data:', JSON.stringify(mealPlan, null, 2));
  
    if (typeof mealPlan !== 'object' || mealPlan === null) {
      console.log('Meal plan is not an object');
      return { isValid: false, error: `Meal plan is not an object: ${typeof mealPlan}` };
    }
  
    // Check if mealPlan is the entire response object
    if (mealPlan.mealPlan) {
      console.log('Meal plan is nested under mealPlan key');
      mealPlan = mealPlan.mealPlan;
    }
  
    const days = Object.keys(mealPlan);
    console.log('Day keys:', days);
  
    if (days.length === 0) {
      return { isValid: false, error: 'Meal plan is empty' };
    }
  
    // Check if it's a 7-day plan
    if (days.length === 7 && days.every(day => day.startsWith('day'))) {
      console.log('Detected 7-day meal plan');
      for (const day of days) {
        if (!Array.isArray(mealPlan[day])) {
          return { isValid: false, error: `Meals for ${day} is not an array` };
        }
        if (mealPlan[day].length !== userPreferences.mealsPerDay) {
          return { isValid: false, error: `Incorrect number of meals for ${day}. Expected ${userPreferences.mealsPerDay}, got ${mealPlan[day].length}` };
        }
      }
    } else {
      console.log('Not a 7-day plan, attempting to interpret structure');
      // If it's not a 7-day plan, check if it's a single day plan
      if (Array.isArray(mealPlan)) {
        if (mealPlan.length !== userPreferences.mealsPerDay) {
          return { isValid: false, error: `Incorrect number of meals. Expected ${userPreferences.mealsPerDay}, got ${mealPlan.length}` };
        }
      } else {
        return { isValid: false, error: 'Unexpected meal plan structure' };
      }
    }
  
    console.log('Meal plan structure is valid');
    return { isValid: true };
  };
  
  const isValidMeal = (meal) => {
    const isValid = meal.mealName && meal.recipeName && meal.calories && 
                    Array.isArray(meal.ingredients) && meal.instructions && meal.nutrition;
    if (!isValid) {
      console.log('Invalid meal:', meal);
    }
    return isValid;
  };
  
  const saveMealPlan = async () => {
    if (!currentUser) {
      alert('Please log in to save meal plans.');
      return;
    }
  
    const { data, error } = await supabase
      .from('meal_plans')
      .insert({
        user_id: currentUser.id,
        preferences: userPreferences,
        plan: mealPlan,
      });
  
    if (error) {
      console.error('Error saving meal plan:', error);
      alert('Failed to save meal plan. Please try again.');
    } else {
      alert('Meal plan saved successfully!');
      fetchSavedMealPlans();
    }
  };

  const deleteMealPlan = async (planId) => {
    const { error } = await supabase
      .from('meal_plans')
      .delete()
      .eq('id', planId);

    if (error) {
      console.error('Error deleting meal plan:', error);
      alert('Failed to delete meal plan. Please try again.');
    } else {
      alert('Meal plan deleted successfully!');
      fetchSavedMealPlans();
    }
  };

  const loadMealPlan = (plan) => {
    setMealPlan(plan.plan);
    setUserPreferences(plan.preferences);
    setIsLoadedPlan(true);
    setCurrentDay(0);
  };

  const toggleMealExpansion = (index) => {
    setExpandedMeal(expandedMeal === index ? null : index);
  };

  const closeMealPlan = () => {
    setMealPlan(null);
    setIsLoadedPlan(false);
    setCurrentDay(0);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };

  const handleSearch = useCallback(
    debounce((term) => {
      setSearchTerm(term);
    }, 300),
    []
  );

  return (
    <div className="max-w-4xl mx-auto p-6 pb-24 sm:pb-6 space-y-8">
      <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="flex justify-between items-center"
      >
        <h2 className="text-3xl font-bold text-gray-800">AI Meal Planner Pro</h2>
        <Link to="/dashboard" className="text-orange-500 hover:text-orange-600 flex items-center transition-colors duration-200">
          <ArrowLeft size={20} className="mr-2" />
          Back to Dashboard
        </Link>
      </motion.div>

      {savedMealPlans.length > 0 && (
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="bg-white shadow-lg rounded-xl p-6"
        >
          <h3 className="text-xl font-semibold text-gray-700 mb-4">Saved Meal Plans</h3>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search saved meal plans..."
              onChange={(e) => handleSearch(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {filteredMealPlans.map((plan) => (
              <motion.div 
                key={plan.id} 
                className="bg-gray-50 rounded-lg p-4 hover:bg-gray-100 transition-colors duration-200"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm text-gray-600 flex items-center">
                    <Calendar size={16} className="mr-1" />
                    {new Date(plan.created_at).toLocaleDateString()}
                  </span>
                  <div className="space-x-2">
                    <button
                      onClick={() => loadMealPlan(plan)}
                      className="text-blue-500 hover:text-blue-600 transition-colors duration-200 font-medium text-sm"
                    >
                      Load
                    </button>
                    <button
                      onClick={() => deleteMealPlan(plan.id)}
                      className="text-red-500 hover:text-red-600 transition-colors duration-200"
                    >
                      <Trash size={16} />
                    </button>
                  </div>
                </div>
                <div className="text-sm text-gray-700">
                  <p><strong>Goal:</strong> {plan.preferences.goal}</p>
                  <p><strong>Calories:</strong> {plan.preferences.calorieTarget}</p>
                  <p><strong>Duration:</strong> {plan.preferences.planDuration} week(s)</p>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      )}
      
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className="bg-white shadow-lg rounded-xl p-6 space-y-6"
      >
        <h3 className="text-xl font-semibold text-gray-700 mb-4">Generate New Meal Plan</h3>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Age</label>
            <input
              type="number"
              name="age"
              value={userPreferences.age}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Gender</label>
            <select
              name="gender"
              value={userPreferences.gender}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
              required
            >
              <option value="">Select gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Weight (kg)</label>
            <input
              type="number"
              name="weight"
              value={userPreferences.weight}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Height (cm)</label>
            <input
              type="number"
              name="height"
              value={userPreferences.height}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Activity Level</label>
            <select
              name="activityLevel"
              value={userPreferences.activityLevel}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
              required
            >
              <option value="">Select activity level</option>
              <option value="sedentary">Sedentary</option>
              <option value="lightlyActive">Lightly Active</option>
              <option value="moderatelyActive">Moderately Active</option>
              <option value="veryActive">Very Active</option>
              <option value="extraActive">Extra Active</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Fitness Goal</label>
            <select
              name="goal"
              value={userPreferences.goal}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
              required
            >
              <option value="">Select a goal</option>
              <option value="weightLoss">Weight Loss</option>
              <option value="muscleGain">Muscle Gain</option>
              <option value="maintenance">Maintenance</option>
            </select>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Protein Sources</label>
          <div className="flex flex-wrap gap-2">
            {proteinOptions.map(option => (
              <motion.button
                key={option.value}
                onClick={() => handleSourceToggle('proteinSources', option.value)}
                className={`px-3 py-1 rounded-full text-sm font-medium transition-colors duration-200 flex items-center ${
                  userPreferences.proteinSources.includes(option.value)
                    ? 'bg-orange-500 text-white'
                    : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                }`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <span className="mr-1">{option.icon}</span>
                {option.label}
              </motion.button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Carb Sources</label>
          <div className="flex flex-wrap gap-2">
            {carbOptions.map(option => (
              <motion.button
                key={option.value}
                onClick={() => handleSourceToggle('carbSources', option.value)}
                className={`px-3 py-1 rounded-full text-sm font-medium transition-colors duration-200 flex items-center ${
                  userPreferences.carbSources.includes(option.value)
                    ? 'bg-orange-500 text-white'
                    : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                }`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <span className="mr-1">{option.icon}</span>
                {option.label}
              </motion.button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Fat Sources</label>
          <div className="flex flex-wrap gap-2">
            {fatOptions.map(option => (
              <motion.button
                key={option.value}
                onClick={() => handleSourceToggle('fatSources', option.value)}
                className={`px-3 py-1 rounded-full text-sm font-medium transition-colors duration-200 flex items-center ${
                  userPreferences.fatSources.includes(option.value)
                    ? 'bg-orange-500 text-white'
                    : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                }`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <span className="mr-1">{option.icon}</span>
                {option.label}
              </motion.button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Daily Calorie Target</label>
          <input
            type="number"
            name="calorieTarget"
            value={userPreferences.calorieTarget}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
            required
            readOnly
          />
        </div>

        <div>
  <label className="block text-sm font-medium text-gray-700 mb-2">Macronutrient Ratio</label>
  <div className="grid grid-cols-3 gap-4">
    {Object.entries(userPreferences.macroRatio).map(([macro, value]) => (
      <div key={macro} className="flex flex-col items-center">
        <label className="block text-xs text-gray-500 mb-1">
          {macro.charAt(0).toUpperCase() + macro.slice(1)}
        </label>
        <MacroChart 
          macro={macro} 
          value={value} 
          color={
            macro === 'protein' ? '#FF6B6B' : 
            macro === 'carbs' ? '#4ECDC4' : 
            '#45B7D1'
          } 
        />
        <input
          type="number"
          value={value}
          onChange={(e) => handleMacroRatioChange(macro, e.target.value)}
          className="mt-2 w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-orange-500 focus:border-orange-500 text-center"
          min="0"
          max="100"
        />
      </div>
    ))}
  </div>
  <div className="mt-4 text-sm text-gray-700">
    <p className="font-medium">Target macros:</p>
    <div className="flex justify-between mt-2">
      {Object.entries(userPreferences.macroGrams).map(([macro, grams]) => (
        <p key={macro} className="flex items-center">
          <span className={`w-3 h-3 rounded-full mr-2 ${
            macro === 'protein' ? 'bg-[#FF6B6B]' : 
            macro === 'carbs' ? 'bg-[#4ECDC4]' : 
            'bg-[#45B7D1]'
          }`}></span>
          {macro.charAt(0).toUpperCase() + macro.slice(1)}: {grams}g
        </p>
      ))}
    </div>
  </div>
</div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Dietary Restrictions</label>
          <div className="flex flex-wrap gap-2">
            {dietaryOptions.map(option => (
              <motion.button
                key={option}
                onClick={() => handleDietaryToggle(option)}
                className={`px-3 py-1 rounded-full text-sm font-medium transition-colors duration-200 ${
                  userPreferences.dietaryRestrictions.includes(option)
                    ? 'bg-orange-500 text-white'
                    : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                }`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {option}
              </motion.button>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Meals per Day</label>
            <input
              type="number"
              name="mealsPerDay"
              value={userPreferences.mealsPerDay}
              onChange={handleInputChange}
              min="1"
              max="6"
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Plan Duration</label>
            <select
              name="planDuration"
              value={userPreferences.planDuration}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
              required
            >
              {planDurationOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <motion.button
          onClick={generateMealPlan}
          disabled={isLoading || !validateInputs()}
          className="w-full bg-orange-500 text-white py-2 px-4 rounded-md hover:bg-orange-600 transition-colors duration-200 disabled:bg-gray-400 disabled:cursor-not-allowed font-medium text-lg shadow-md flex items-center justify-center"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {isLoading ? (
            <Loader2 className="animate-spin mr-2" />
          ) : (
            <Utensils size={20} className="mr-2" />
          )}
          {isLoading ? 'Generating...' : 'Generate Meal Plan'}
        </motion.button>
      </motion.div>

      <AnimatePresence>
        {error && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="bg-red-100 border-l-4 border-red-500 text-red-700 p-6 rounded-lg shadow-md"
            role="alert"
          >
            <p className="font-bold text-lg mb-2">Error</p>
            <p>{error}</p>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
  {mealPlan && (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="bg-white shadow-lg rounded-xl p-6 space-y-6 relative"
      ref={printRef}
    >
      <button 
        onClick={closeMealPlan}
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        aria-label="Close meal plan"
      >
        <X size={24} />
      </button>
      <h3 className="text-xl font-semibold text-gray-700 mb-4">Your Personalized Meal Plan</h3>
      
      <div className="flex justify-between items-center mb-4">
        <motion.button 
          onClick={() => setCurrentDay(prev => (prev > 0 ? prev - 1 : mealPlan.length - 1))}
          className="bg-orange-500 text-white py-2 px-4 rounded-md hover:bg-orange-600 transition-colors"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Previous Day
        </motion.button>
        <h4 className="text-lg font-medium">{mealPlan[currentDay].day}</h4>
        <motion.button 
          onClick={() => setCurrentDay(prev => (prev < mealPlan.length - 1 ? prev + 1 : 0))}
          className="bg-orange-500 text-white py-2 px-4 rounded-md hover:bg-orange-600 transition-colors"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Next Day
        </motion.button>
      </div>

      <div className="text-center mb-4">
  <p className="text-xl font-semibold text-gray-700 mb-2">
    Target Daily Calories: {userPreferences.calorieTarget}
  </p>
  <p className="text-lg font-semibold text-gray-700 mb-4">Target Macros:</p>
  <div className="flex justify-center items-center space-x-6">
    {Object.entries(userPreferences.macroGrams).map(([macro, grams], index) => (
      <div key={macro} className="flex items-center">
        <span className={`w-4 h-4 rounded-full mr-2 ${
          macro === 'protein' ? 'bg-[#FF6B6B]' : 
          macro === 'carbs' ? 'bg-[#4ECDC4]' : 
          'bg-[#45B7D1]'
        }`}></span>
        <span className="text-sm font-medium text-gray-700">
          {macro.charAt(0).toUpperCase() + macro.slice(1)}: {grams}g ({userPreferences.macroRatio[macro]}%)
        </span>
      </div>
    ))}
  </div>
</div>

            {mealPlan[currentDay].meals.map((meal, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="mb-4 border-b pb-4 last:border-b-0 last:pb-0"
              >
                <div 
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleMealExpansion(index)}
                >
                  <h4 className="text-lg font-medium text-gray-800">
                    {meal.mealName} - {meal.recipeName}
                  </h4>
                  <div className="flex items-center">
                    <motion.button
                      onClick={(e) => {
                        e.stopPropagation();
                        copyToClipboard(JSON.stringify(meal, null, 2));
                      }}
                      className="text-gray-500 hover:text-gray-700 mr-2"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      {isCopied ? <Check size={20} /> : <Copy size={20} />}
                    </motion.button>
                    {expandedMeal === index ? <ChevronUp className="text-gray-500" /> : <ChevronDown className="text-gray-500" />}
                  </div>
                </div>
                <p className="text-sm text-gray-600 mt-1 flex items-center">
                  <Clock size={16} className="mr-1" />
                  {meal.calories} calories
                </p>
                
                <AnimatePresence>
                  {expandedMeal === index && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                      className="mt-4 space-y-4"
                    >
                      <div>
                        <h5 className="font-medium text-md mb-2 text-gray-700">Ingredients:</h5>
                        {meal.ingredients && meal.ingredients.length > 0 ? (
                          <ul className="list-disc pl-5 space-y-1">
                            {meal.ingredients.map((ingredient, i) => (
                              <li key={i} className="text-sm text-gray-600">{ingredient.quantity} {ingredient.unit} {ingredient.name}</li>
                            ))}
                          </ul>
                        ) : (
                          <p className="text-sm text-gray-500">Ingredients not available</p>
                        )}
                      </div>
                      
                      <div>
                        <h5 className="font-medium text-md mb-2 text-gray-700">Instructions:</h5>
                        {meal.instructions ? (
                          <p className="text-sm text-gray-600">{meal.instructions}</p>
                        ) : (
                          <p className="text-sm text-gray-500">Instructions not available</p>
                        )}
                      </div>
                      
                      <div>
                        <h5 className="font-medium text-md mb-2 text-gray-700">Nutritional Information:</h5>
                        <div className="flex flex-wrap gap-2">
                          {meal.nutrition ? (
                            Object.entries(meal.nutrition).map(([key, value]) => {
                              if (typeof value === 'object') {
                                return (
                                  <div key={key} className="w-full">
                                    <h6 className="font-medium text-sm text-gray-600 mt-2">{key}:</h6>
                                    <div className="flex flex-wrap gap-2">
                                      {Object.entries(value).map(([subKey, subValue]) => (
                                        <div key={`${key}-${subKey}`} className="text-xs bg-white px-2 py-1 rounded-full border border-gray-200">
                                          <span className="font-medium text-gray-700">{subKey}:</span> {subValue}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                );
                              } else {
                                return (
                                  <div key={key} className="text-xs bg-white px-2 py-1 rounded-full border border-gray-200">
                                    <span className="font-medium text-gray-700">{key}:</span> {value}g
                                  </div>
                                );
                              }
                            })
                          ) : (
                            <p className="text-sm text-gray-500">Nutritional information not available</p>
                          )}
                        </div>
                      </div>

                      {userPreferences.isPro && (
                        <>
                          <div>
                            <h5 className="font-medium text-md mb-2 text-gray-700">Preparation Time:</h5>
                            <p className="text-sm text-gray-600">{meal.prepTime} minutes</p>
                          </div>
                          <div>
                            <h5 className="font-medium text-md mb-2 text-gray-700">Allergens:</h5>
                            <p className="text-sm text-gray-600">{meal.allergens.join(', ')}</p>
                          </div>
                        </>
                      )}
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ))}
            
            {!isLoadedPlan && (
              <motion.button
                onClick={saveMealPlan}
                className="mt-6 bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 transition-colors duration-200 flex items-center justify-center font-medium text-lg shadow-md"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Save size={20} className="mr-2" />
                Save This Meal Plan
              </motion.button>
            )}

            <ReactToPrint
              trigger={() => (
                <motion.button
                  className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center font-medium text-lg shadow-md"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Printer size={20} className="mr-2" />
                  Print Meal Plan
                </motion.button>
              )}
              content={() => printRef.current}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.6 }}
        className="bg-blue-50 border-l-4 border-blue-500 p-4 rounded-lg shadow-md mb-20 sm:mb-0"
      >
        <div className="flex items-start">
          <Info className="flex-shrink-0 text-blue-500 mr-3" />
          <p className="text-sm text-blue-700">
            This meal plan is generated by AI based on your preferences and general nutritional guidelines. Always consult with a healthcare professional or registered dietitian before making significant changes to your diet.
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default AIMealPlannerPro;