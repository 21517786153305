import React, { useState, useEffect } from 'react';
import { Plus, Edit, Trash } from 'lucide-react';
import { supabase } from '../utils/supabase';

const ManagePayments = ({ clientId, payments, onUpdate }) => {
    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentPayment, setCurrentPayment] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        getUser();
    }, []);

    const getUser = async () => {
        const { data: { user } } = await supabase.auth.getUser();
        setUser(user);
    };

    const updateTrainerBalance = async (amount, transactionType, paymentId) => {
        if (!user) {
          console.log('No user found');
          return;
        }
      
        try {
          const { data: updateResult, error: updateError } = await supabase.rpc('update_trainer_balance', {
            p_trainer_id: user.id,
            p_amount: amount,
            p_transaction_type: transactionType,
            p_payment_id: paymentId
          });
      
          if (updateError) throw updateError;
      
          console.log('Balance update result:', updateResult);
      
          // Fetch the updated balance
          const { data: balanceData, error: balanceError } = await supabase.rpc('get_trainer_balance', {
            p_trainer_id: user.id
          });
      
          if (balanceError) throw balanceError;
      
          console.log('Current balance:', balanceData);
      
          // Update your UI with the new balance
          // setTrainerBalance(balanceData);
      
        } catch (error) {
          console.error('Error in balance operations:', error);
        }
      };

    const handleAdd = async (newPayment) => {
        if (!user) return;

        const { data, error } = await supabase.rpc('add_payment_and_update_balance', {
            p_client_id: clientId,
            p_date: newPayment.date,
            p_amount: newPayment.amount,
            p_method: newPayment.method,
            p_status: newPayment.status,
            p_trainer_id: user.id
        });

        if (error) {
            console.error('Error adding payment:', error);
        } else {
            onUpdate();
            setIsAdding(false);
        }
    };

    const handleEdit = async (updatedPayment) => {
        if (!user) return;

        const { data, error } = await supabase
            .from('client_payments')
            .update(updatedPayment)
            .eq('id', updatedPayment.id)
            .select();

        if (error) {
            console.error('Error updating payment:', error);
        } else {
            onUpdate();
            setIsEditing(false);
            setCurrentPayment(null);
        }
    };

    const handleDelete = async (paymentId) => {
        if (!user) return;

        const { error } = await supabase
            .from('client_payments')
            .delete()
            .eq('id', paymentId);

        if (error) {
            console.error('Error deleting payment:', error);
        } else {
            onUpdate();
        }
    };

    const handleRefund = async (paymentId) => {
        // Start a Supabase transaction for refund
        const { data, error } = await supabase.rpc('refund_payment', {
            p_payment_id: paymentId,
            p_trainer_id: user.id
        });

        if (error) {
            console.error('Error refunding payment:', error);
        } else {
            onUpdate();
        }
    };

    const handleAddPayment = async (newPayment) => {
        try {
          const { data, error } = await supabase
            .from('client_payments')
            .insert(newPayment)
            .select();
      
          if (error) throw error;
      
          // Only update the trainer balance if the new payment status is 'Completed'
          if (data[0].status === 'Completed') {
            await updateTrainerBalance(data[0].amount, 'Earning', data[0].id); // Call the prop function
          }
      
          onUpdate();
        } catch (error) {
          console.error('Error adding payment:', error);
        }
      };
    
      const handleUpdatePaymentStatus = async (paymentId, newStatus) => {
        try {
          console.log('Updating payment status:', { paymentId, newStatus });
      
          const { data, error } = await supabase
            .from('client_payments')
            .update({ status: newStatus })
            .eq('id', paymentId)
            .select();
      
          if (error) throw error;
      
          console.log('Payment status updated:', data[0]);
      
          // Calculate the amount to update the balance
          let balanceUpdateAmount = data[0].amount;
          if (newStatus === 'Refunded' || newStatus === 'Failed') {
            balanceUpdateAmount = -balanceUpdateAmount; // Subtract the amount for refunds or failed payments
          } else if (newStatus !== 'Completed') {
            balanceUpdateAmount = 0; // No balance change for other statuses
          }
      
          // Update the trainer balance
          await updateTrainerBalance(balanceUpdateAmount, newStatus, paymentId);
      
          onUpdate();
        } catch (error) {
          console.error('Error in handleUpdatePaymentStatus:', error);
        }
      };

    return (
        <div>
            <h3 className="text-xl font-semibold mb-4">Payments</h3>
            {payments.map((payment) => (
                <div key={payment.id} className="mb-4 p-4 border rounded-lg">
                    <h4 className="font-semibold">Date: {payment.date}</h4>
                    <p>Amount: ${payment.amount}</p>
                    <p>Method: {payment.method}</p>
                    <p>Status: {payment.status}</p>
                    <div className="mt-2">
                        <button 
                            onClick={() => {
                                setCurrentPayment(payment);
                                setIsEditing(true);
                            }}
                            className="mr-2 text-blue-500 hover:text-blue-700"
                        >
                            <Edit size={18} />
                        </button>
                        <button 
                            onClick={() => handleDelete(payment.id)}
                            className="text-red-500 hover:text-red-700"
                        >
                            <Trash size={18} />
                        </button>
                    </div>
                </div>
            ))}
            <button
                onClick={() => setIsAdding(true)}
                className="mt-4 bg-green-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-green-600 transition-colors"
            >
                <Plus size={20} className="mr-2" />
                Add New Payment
            </button>
            {(isAdding || isEditing) && (
                <PaymentForm
                    onSubmit={isAdding ? handleAdd : handleEdit}
                    onCancel={() => {
                        setIsAdding(false);
                        setIsEditing(false);
                        setCurrentPayment(null);
                    }}
                    initialData={currentPayment}
                />
            )}
        </div>
    );
};

const PaymentForm = ({ onSubmit, onCancel, initialData }) => {
    const [formData, setFormData] = useState(initialData || {
        date: new Date().toISOString().split('T')[0],
        amount: '',
        method: '',
        status: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit} className="mt-4 p-4 border rounded-lg">
            <div className="mb-4">
                <label className="block mb-2">Date:</label>
                <input
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Amount:</label>
                <input
                    type="number"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    step="0.01"
                    min="0"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Method:</label>
                <select
                    name="method"
                    value={formData.method}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                >
                    <option value="">Select Payment Method</option>
                    <option value="Credit Card">Credit Card</option>
                    <option value="Debit Card">Debit Card</option>
                    <option value="Cash">Cash</option>
                    <option value="Bank Transfer">Bank Transfer</option>
                    <option value="PayPal">PayPal</option>
                </select>
            </div>
            <div className="mb-4">
                <label className="block mb-2">Status:</label>
                <select
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                >
                    <option value="">Select Status</option>
                    <option value="Completed">Completed</option>
                    <option value="Pending">Pending</option>
                    <option value="Failed">Failed</option>
                    <option value="Refunded">Refunded</option>
                </select>
            </div>
            <div className="flex justify-end">
                <button
                    type="button"
                    onClick={onCancel}
                    className="mr-2 px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                >
                    {initialData ? 'Update' : 'Add'} Payment
                </button>
            </div>
        </form>
    );
};

export default ManagePayments;