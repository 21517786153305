import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabase';
import { ArrowLeft, Check, X, DollarSign, Clock, RefreshCcw, Download, Send, Calendar, MapPin, Users, Search, Edit, Trash2 } from 'lucide-react';

const EventManagement = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [attendees, setAttendees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAttendees, setSelectedAttendees] = useState([]);
  const [stats, setStats] = useState({ total: 0, checkedIn: 0, paid: 0, refundRequested: 0 });

  useEffect(() => {
    fetchEventDetails();
    fetchAttendees();
  }, [eventId]);

  useEffect(() => {
    calculateStats();
  }, [attendees]);

  const fetchEventDetails = async () => {
    const { data, error } = await supabase
      .from('events')
      .select(`
        *,
        host:host_id (
          id,
          name
        )
      `)
      .eq('id', eventId)
      .single();
  
    if (error) {
      console.error('Error fetching event details:', error);
    } else {
      setEvent(data);
    }
  };

  const fetchAttendees = async () => {
    try {
      const { data: attendeesData, error: attendeesError } = await supabase
        .from('event_attendees')
        .select(`
          *,
          user_profiles_with_email!inner(name, email)
        `)
        .eq('event_id', eventId);
  
      if (attendeesError) throw attendeesError;
  
      const { data: transactionsData, error: transactionsError } = await supabase
        .from('event_transactions')
        .select('*')
        .eq('event_id', eventId);
  
      if (transactionsError) throw transactionsError;
  
      const combinedData = attendeesData.map(attendee => ({
        ...attendee,
        name: attendee.user_profiles_with_email.name,
        email: attendee.user_profiles_with_email.email,
        transactions: transactionsData.filter(t => t.user_id === attendee.user_id)
      }));
  
      setAttendees(combinedData);
    } catch (error) {
      console.error('Error fetching attendees:', error);
    } finally {
      setLoading(false);
    }
  };

  const calculateStats = () => {
    const total = attendees.length;
    const checkedIn = attendees.filter(a => a.status === 'attended').length;
    const paid = attendees.filter(a => a.payment_status === 'paid').length;
    const refundRequested = attendees.filter(a => a.status === 'refund_requested').length;
    setStats({ total, checkedIn, paid, refundRequested });
  };

  const createHostNotification = async (type, attendee, event) => {
    console.log('Creating host notification:', { type, attendee, event });
    if (!event.host_id) {
      console.error('Unable to determine the host user ID for notification');
      return;
    }
  
    const notificationData = {
      user_id: event.host_id,
      type: type,
      message: JSON.stringify({
        attendee_name: attendee.name,
        event_name: event.name,
        event_date: event.date,
        event_time: event.time
      })
    };
  
    console.log('Notification data:', notificationData);
  
    const { data, error } = await supabase
      .from('notifications')
      .insert(notificationData);
  
    if (error) {
      console.error('Error creating host notification:', error);
    } else {
      console.log('Host notification created successfully:', data);
    }
  };

  const updateAttendeeStatus = async (attendeeId, newStatus) => {
    console.log('Updating attendee status:', { attendeeId, newStatus });
    const { data, error } = await supabase
      .from('event_attendees')
      .update({ status: newStatus })
      .eq('id', attendeeId)
      .select('*, user_profiles_with_email!inner(name, email)')
      .single();
  
    if (error) {
      console.error('Error updating attendee status:', error);
    } else {
      console.log('Attendee status updated:', data);
      if (newStatus === 'confirmed') {
        await createHostNotification('event_join', data.user_profiles_with_email, event);
      } else if (newStatus === 'cancelled') {
        await createHostNotification('event_cancel', data.user_profiles_with_email, event);
      } else if (newStatus === 'refund_requested') {
        await createHostNotification('refund_request', data.user_profiles_with_email, event);
      }
      fetchAttendees();
    }
  };

  const updatePaymentStatus = async (attendeeId, newStatus) => {
    const { error } = await supabase
      .from('event_attendees')
      .update({ payment_status: newStatus })
      .eq('id', attendeeId);

    if (error) {
      console.error('Error updating payment status:', error);
    } else {
      fetchAttendees();
    }
  };

  const handleCheckIn = async (attendeeId) => {
    const { error } = await supabase
      .from('event_attendees')
      .update({ status: 'attended', check_in_time: new Date().toISOString() })
      .eq('id', attendeeId);

    if (error) {
      console.error('Error checking in attendee:', error);
    } else {
      fetchAttendees();
    }
  };

  const exportToCSV = () => {
    // Implement CSV export logic
    console.log('Exporting to CSV...');
  };

  const sendReminders = async () => {
    // Implement reminder sending logic
    console.log('Sending reminders...');
  };

  const handleBulkAction = async (action) => {
    if (selectedAttendees.length === 0) {
      alert('Please select attendees first');
      return;
    }

    switch (action) {
      case 'checkIn':
        await Promise.all(selectedAttendees.map(id => handleCheckIn(id)));
        break;
      case 'sendReminder':
        // Implement send reminder logic for selected attendees
        alert(`Reminders sent to ${selectedAttendees.length} attendees`);
        break;
      default:
        console.error('Unknown bulk action:', action);
    }

    setSelectedAttendees([]);
  };

  const toggleAttendeeSelection = (attendeeId) => {
    setSelectedAttendees(prev => 
      prev.includes(attendeeId)
        ? prev.filter(id => id !== attendeeId)
        : [...prev, attendeeId]
    );
  };

  const filteredAttendees = attendees.filter(attendee => 
    attendee.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    attendee.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getCheckInData = () => {
    const checkInCounts = attendees.reduce((acc, attendee) => {
      if (attendee.check_in_time) {
        const hour = new Date(attendee.check_in_time).getHours();
        acc[hour] = (acc[hour] || 0) + 1;
      }
      return acc;
    }, {});

    return Object.entries(checkInCounts).map(([hour, count]) => ({
      hour: `${hour}:00`,
      count
    })).sort((a, b) => parseInt(a.hour) - parseInt(b.hour));
  };

  const handleRefund = async (attendeeId, approve) => {
    try {
      const { data: attendee, error: attendeeError } = await supabase
        .from('event_attendees')
        .select('*, user_profiles_with_email!inner(name, email)')
        .eq('id', attendeeId)
        .single();
  
      if (attendeeError) {
        throw new Error(`Failed to fetch attendee details: ${attendeeError.message}`);
      }
  
    
  
      if (approve) {
        console.log('Attempting to process refund for attendeeId:', attendeeId);
        
        const { data, error } = await supabase.functions.invoke('stripe', {
          body: { 
            action: 'process-refund',
            attendeeId 
          },
        });
  
        console.log('Response from Edge Function:', { data, error });
  
        if (error) throw new Error(`Refund processing failed: ${error.message}`);
        if (!data || !data.success) throw new Error(`Refund processing failed: ${data ? data.error : 'Unknown error'}`);
  
        console.log('Refund processed successfully:', data);
  
        const { error: updateError } = await supabase
          .from('event_attendees')
          .update({ 
            status: 'refunded',
            payment_status: 'refunded',
            refund_processed_at: new Date().toISOString(),
            refund_id: data.refundId
          })
          .eq('id', attendeeId);
  
        if (updateError) throw new Error(`Failed to update attendee status: ${updateError.message}`);
  
        const { error: transactionError } = await supabase
          .from('event_transactions')
          .insert({
            event_id: event.id,
            user_id: attendee.user_id,
            amount: -event.price,
            payment_method: 'refund',
            payment_status: 'completed',
            stripe_payment_intent_id: data.refundId
          });
  
        if (transactionError) throw new Error(`Failed to record refund transaction: ${transactionError.message}`);
  
        try {
          const { data: emailData, error: emailError } = await supabase.functions.invoke('send-email', {
            body: {
              type: 'refund_decision',
              attendee: { 
                name: attendee.user_profiles_with_email.name, 
                email: attendee.user_profiles_with_email.email 
              },
              event: { name: event.name, date: event.date, time: event.time },
              approved: approve
            }
          });
  
          if (emailError) {
            console.error('Failed to send email:', emailError);
            throw new Error(`Email sending failed: ${emailError.message}`);
          }
  
          console.log('Email sent successfully:', emailData);
        } catch (emailError) {
          console.error('Error in email sending process:', emailError);
          alert(`Refund approved and processed successfully, but there was an error sending the confirmation email. Please contact the attendee manually.`);
        }
  
        const { error: deleteError } = await supabase
          .from('event_attendees')
          .delete()
          .eq('id', attendeeId);
  
        if (deleteError) throw new Error(`Failed to remove attendee from event: ${deleteError.message}`);
  
      } else {
        const { error: updateError } = await supabase
          .from('event_attendees')
          .update({ 
            status: 'refund_rejected',
            refund_processed_at: new Date().toISOString()
          })
          .eq('id', attendeeId);
  
        if (updateError) throw new Error(`Failed to update attendee status: ${updateError.message}`);
  
        try {
          const { data: emailData, error: emailError } = await supabase.functions.invoke('send-email', {
            body: {
              type: 'refund_decision',
              attendee: { 
                name: attendee.user_profiles_with_email.name, 
                email: attendee.user_profiles_with_email.email 
              },
              event: { name: event.name, date: event.date, time: event.time },
              approved: approve
            }
          });
  
          if (emailError) {
            console.error('Failed to send email:', emailError);
            throw new Error(`Email sending failed: ${emailError.message}`);
          }
  
          console.log('Email sent successfully:', emailData);
        } catch (emailError) {
          console.error('Error in email sending process:', emailError);
          alert(`Refund rejected successfully, but there was an error sending the confirmation email. Please contact the attendee manually.`);
        }
      }
  
      await fetchAttendees();
  
      alert(`Refund ${approve ? 'approved and processed' : 'rejected'} successfully.`);
    } catch (error) {
      console.error('Error processing refund:', error);
      alert(`Failed to process refund: ${error.message}`);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-orange-500"></div>
      </div>
    );
  }

  if (!event) {
    return <div className="text-center mt-8 text-xl text-gray-600">Event not found</div>;
  }

  return (
    <div className="max-w-7xl mx-auto p-4 pb-20 sm:pb-4 bg-gray-50 min-h-screen">
      <div className="flex items-center justify-between mb-8">
        <h1 className="text-xl sm:text-2xl font-bold text-gray-800">Manage Event</h1>
        <button 
          onClick={() => navigate('/my-events')} 
          className="text-orange-500 font-medium flex items-center hover:text-orange-600 transition-colors"
        >
          <ArrowLeft size={20} className="mr-2" /> Back to My Events
        </button>
      </div>

      <div className="bg-white rounded-lg shadow-md p-4 sm:p-6 mb-6">
        <h2 className="text-xl sm:text-2xl font-semibold mb-4 text-gray-700">{event?.name}</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="flex items-center">
            <Calendar size={20} className="mr-2 text-gray-500" />
            <span>{new Date(event.date).toLocaleDateString()}</span>
          </div>
          <div className="flex items-center">
            <Clock size={20} className="mr-2 text-gray-500" />
            <span>{event.time}</span>
          </div>
          <div className="flex items-center">
            <MapPin size={20} className="mr-2 text-gray-500" />
            <span>{event.location}</span>
          </div>
          <div className="flex items-center">
            <Users size={20} className="mr-2 text-gray-500" />
            <span>{event.max_participants} max participants</span>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md p-4 sm:p-6 mb-6">
        <h3 className="text-lg sm:text-xl font-semibold mb-4 text-gray-700">Attendee Statistics</h3>
        <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
          <div className="bg-blue-100 p-4 rounded-lg">
            <p className="text-blue-800 font-semibold">Total Attendees</p>
            <p className="text-2xl sm:text-3xl font-bold text-blue-600">{stats.total}</p>
          </div>
          <div className="bg-green-100 p-4 rounded-lg">
            <p className="text-green-800 font-semibold">Checked In</p>
            <p className="text-2xl sm:text-3xl font-bold text-green-600">{stats.checkedIn}</p>
          </div>
          <div className="bg-yellow-100 p-4 rounded-lg">
            <p className="text-yellow-800 font-semibold">Paid</p>
            <p className="text-2xl sm:text-3xl font-bold text-yellow-600">{stats.paid}</p>
          </div>
          <div className="bg-red-100 p-4 rounded-lg">
            <p className="text-red-800 font-semibold">Refund Requested</p>
            <p className="text-2xl sm:text-3xl font-bold text-red-600">{stats.refundRequested}</p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
        <div className="flex flex-col mb-6">
          <h2 className="text-xl sm:text-2xl font-semibold text-gray-700 mb-4">Attendees</h2>
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 mb-4">
            <button
              onClick={exportToCSV}
              className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition-colors flex items-center justify-center"
            >
              <Download size={16} className="mr-2" /> Export CSV
            </button>
            <button
              onClick={sendReminders}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors flex items-center justify-center"
            >
              <Send size={16} className="mr-2" /> Send Reminders
            </button>
          </div>
        </div>
        
        <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
          <div className="w-full sm:w-64 mb-4 sm:mb-0">
            <div className="relative">
            <input
                type="text"
                placeholder="Search attendees..."
                className="w-full p-3 pl-10 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
            <button
              onClick={() => handleBulkAction('checkIn')}
              className="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition-colors"
            >
              Bulk Check-in
            </button>
            <button
              onClick={() => handleBulkAction('sendReminder')}
              className="bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-purple-600 transition-colors"
            >
              Bulk Send Reminder
            </button>
          </div>
        </div>

        <div className="overflow-x-auto">
          {filteredAttendees.map((attendee) => (
            <div key={attendee.id} className="border-b py-4">
              <div className="flex justify-between items-center mb-2">
                <div>
                  <p className="font-semibold">{attendee.name}</p>
                  <p className="text-sm text-gray-600">{attendee.email}</p>
                </div>
                <input
                  type="checkbox"
                  checked={selectedAttendees.includes(attendee.id)}
                  onChange={() => toggleAttendeeSelection(attendee.id)}
                  className="h-5 w-5"
                />
              </div>
              <div className="grid grid-cols-2 gap-2 mb-2">
                <div>
                <label className="text-sm text-gray-600">Status</label>
                  <select
                    value={attendee.status}
                    onChange={(e) => updateAttendeeStatus(attendee.id, e.target.value)}
                    className="w-full border rounded p-2 focus:outline-none focus:ring-2 focus:ring-orange-500"
                  >
                    <option value="registered">Registered</option>
                    <option value="confirmed">Confirmed</option>
                    <option value="attended">Attended</option>
                    <option value="no_show">No Show</option>
                    <option value="refund_requested">Refund Requested</option>
                    <option value="refunded">Refunded</option>
                  </select>
                </div>
                <div>
                  <label className="text-sm text-gray-600">Payment</label>
                  <select
                    value={attendee.payment_status || 'not_applicable'}
                    onChange={(e) => updatePaymentStatus(attendee.id, e.target.value)}
                    className="w-full border rounded p-2 focus:outline-none focus:ring-2 focus:ring-orange-500"
                  >
                    <option value="paid">Paid</option>
                    <option value="unpaid">Unpaid</option>
                    <option value="refunded">Refunded</option>
                    <option value="not_applicable">N/A</option>
                  </select>
                </div>
              </div>
              <div className="text-sm text-gray-600 mb-2">
                Check-in Time: {attendee.check_in_time ? new Date(attendee.check_in_time).toLocaleString() : 'Not checked in'}
              </div>
              <div className="flex space-x-2">
              {attendee.status !== 'attended' && attendee.status !== 'refunded' && (
                <button
                    onClick={() => handleCheckIn(attendee.id)}
                    className="bg-orange-500 text-white p-2 rounded-full hover:bg-orange-600 transition-colors"
                    title="Check In"
                  >
                    <Check size={16} />
                  </button>
                )}
                <button
                  onClick={() => {/* Implement edit attendee logic */}}
                  className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 transition-colors"
                  title="Edit Attendee"
                >
                  <Edit size={16} />
                </button>
                <button
                  onClick={() => {/* Implement delete attendee logic */}}
                  className="bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition-colors"
                  title="Delete Attendee"
                >
                  <Trash2 size={16} />
                </button>
                {attendee.status === 'refund_requested' && (
                  <>
                    <button
                      onClick={() => handleRefund(attendee.id, true)}
                      className="bg-green-500 text-white p-2 rounded-full hover:bg-green-600 transition-colors"
                      title="Approve Refund"
                    >
                      <Check size={16} />
                    </button>
                    <button
                      onClick={() => handleRefund(attendee.id, false)}
                      className="bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition-colors"
                      title="Reject Refund"
                    >
                      <X size={16} />
                    </button>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EventManagement;